import React from "react";
import { useTranslation } from "react-i18next";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { usePaymentShipmentsContext } from "./PaymentShipmentsProvider";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";


export function PaymentAmountTableCell(props) {

  const { shipmentDetails, } = props;
  const {
    paymentShipmentsContext: { updateSelectedShipmentByForm },
  } = usePaymentShipmentsContext();


  const { t } = useTranslation(["translation", "validation"]);

  const {
    handleSubmit,
    control,
    errors,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      amount: parseFloat(shipmentDetails?.amount),
    },
  });

  const CanWrite = shipmentDetails?.amountEditable;
  const updateShipment = (name, value) => {
    /************* Validation Funcs *******************/
    let allField = watch();
    for (const key in allField) {
      if (key.startsWith(name)) {
        delete allField[key];
      }
    }
    const checkIsEmpty = value !== "";

    /*************************************************/
    updateSelectedShipmentByForm({ id: shipmentDetails?.shipment.id, checkIsEmpty, name, value });
  }
  const onChangeFees = (e) => {
    const value = parseFloat(e.target.value);
    updateShipment("amount", (value || value === 0) ? value : "");
  };

  const onSubmit = (data) => {
  }


  return (
    <FixedTableCell>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ControlMUItextField
          control={control}
          errors={errors}
          readOnly={!CanWrite}
          variant="outlined"
          name={`amount`}
          type="number"
          label={t("deservedAmount")}
          onChange={onChangeFees}
          rules={{
            min: {
              value: 0,
              message: t("validation:min", {
                field: t("deservedAmount"),
                number: "0",
              }),
            },
            required: t("fieldIsRequired"),
          }}
        />
      </form>
    </FixedTableCell>
  );
}
