import React from "react";
import { styled } from '@mui/material/styles';
import { Grid } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import GlobalTrackView from "./GlobalTrackView";

const PREFIX = 'LandingPageTrackShipment';

const classes = {
  mainTracking: `${PREFIX}-mainTracking`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(0),
    width: "100%",
    padding: theme.spacing(2),
  }
}));

export const TRACKING = gqlb.query({
  operation: "shipment",
  fields: [
    "id",
    "code",
    "date",
    "weight",
    "description",
    "piecesCount",
    "recipientName",
    "recipientAddress",
    {
      customer: [
        "id",
        "name",
        {
          zone: ["id", "name"],
        },
        {
          subzone: ["id", "name"],
        },
      ],
    },
    {
      status: ["code", "name"],
    },
    {
      recipientZone: ["id", "name"],
    },
    {
      recipientSubzone: ["id", "name"],
    },
    {
      transactions: [
        "id",
        "createdAt",
        {
          cancellationReason: ["name"],
        },
        {
          shipmentStatus: ["code", "name"],
        },
      ],
    },
  ],
  variables: {
    code: {
      type: "String",
    },
  },
});

const LandingPageTrackShipment = (props) => {


  const { data, loading } = useQuery(
    gql`
      ${TRACKING.query}
    `,
    {
      variables: { code: props.match.params.code },
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );

  const trackingData = data?.shipment !== null ? data?.shipment : null;

  return (
    <Root>
      {props.children}

      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classes.mainTracking}
      >
        <GlobalTrackView loading={loading} data={trackingData} />
      </Grid>
    </Root>
  );
};

export default LandingPageTrackShipment;
