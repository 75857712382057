import { Alert, Box, IconButton, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { DELETE_PAYMENT_ENTRIES_MUTATION } from "./PaymentListQuary";
import Grid from "@mui/material/Unstable_Grid2";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import { getShipmentsTableHeader, initialPaymentData } from "./listPaymentFields";
import GenerateShipmentSumCell from "./GenerateShipmentSumCell";
import GenerateShipmentCell from "./GenerateShipmentCell";
import { Globals } from "../HOC/Classes/Globals";
const PREFIX = "usePaymentDetails";
const classes = {
  barcode: `${PREFIX}-barcode`,

};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 32,
  },

}));
export const CustomerPaymentMethod = (props) => {
  const { classes, customerData } = props
  const paymentMethodCode = customerData?.customer?.paymentMethod?.code
  const { t } = useTranslation();
  return <Alert
    severity="info"
    color="warning"
    variant="outlined"
    classes={{
      message: classes.alertMessage,
      root: classes.alertMessage,
    }}
  >
    <Grid container width={"100%"} p={0}>
      <Grid xs={12} md={4} display={"flex"}>
        <Box>
          {t("paymentMethod") + " :"}
        </Box>
        <Box mx={1}>
          {customerData?.customer?.paymentMethod?.name ?? "-"}
        </Box>
      </Grid>
      {paymentMethodCode === "BNK" && <Grid xs={12} md={4} display={"flex"}>
        <Box>
          {t("bank") + " :"}
        </Box>
        <Box mx={1}>
          {customerData.customer?.bank?.name ?? "-"}
        </Box>
      </Grid>}
      {(paymentMethodCode === "BNK" || paymentMethodCode === "INSTPY") && <Grid xs={12} md={4} display={"flex"}>
        <Box>
          {t("accountNumber") + " :"}
        </Box>
        <Box mx={1}>
          {customerData.customer?.accountNumber ?? "-"}
        </Box>
      </Grid>}
      {paymentMethodCode === "VCSH" && <Grid xs={12} md={4} display={"flex"}>
        <Box>
          {t("walletNumber") + " :"}
        </Box>
        <Box mx={1}>
          {customerData.customer?.vodafoneCash ?? "-"}
        </Box>
      </Grid>}
    </Grid>
  </Alert>
}

CustomerPaymentMethod.propTypes = {
  classes: PropTypes.any,
  customerData: PropTypes.any.isRequired,
}


export const tableSumPayment = (localDataKey, TotalData, removeShipment) => {
  const sumTableData = [
    {
      key: "collectedAmount",
      value: TotalData?.sumEntriesShipment?.collectedAmount,

    },
    {
      key: "weight",
      value: TotalData?.sumEntriesShipment?.weight,

    },
    {
      key: "piecesCount",
      value: TotalData?.sumEntriesShipment?.piecesCount,

    },
    {
      key: "totalAmount",
      value: TotalData?.sumEntriesShipment?.totalAmount,

    },
    {
      key: "collectedFees",
      value: TotalData?.sumEntriesShipment?.collectedFees,

    },
    {
      key: "returningDueFees",
      value: TotalData?.sumEntriesShipment?.dueFees,
    },
    {
      key: "paidAmount",
      value: TotalData?.sumEntriesShipment?.paymentAmount,
      // clientSum: true
    },
    {
      key: "customerDueAmount",
      value: TotalData?.sumEntriesShipment?.paymentAmount,
    },
    {
      key: "deliveredAmount",
      value: TotalData?.sumEntriesShipment?.deliveredAmount,
    },
  ]
  let selected = []
  let ifSum = false
  localDataKey.map((e, index) => {
    const selectBody = sumTableData.filter((ele, i) => ele.key === e)
    if (selectBody[0]) {
      ifSum = true
      selected.push(selectBody[0])
    } else {
      selected.push({
        key: e,
        value: "no",
        noSum: true
      })
    }
    return ""
  })
  removeShipment && selected.push({
    key: "removeShipment",
    value: "no",
    noSum: true
  })
  return ifSum ? selected : []
}

const usePaymentsDetails = ({
  type,
  approved,
  setShipmentData: removeShipment,
  refetchShipmentDataQuery,
  typeData,
  print
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deletePaymentEntries, { loading: deletePaymentEntriesLoading }] = useMutation(
    gql`
      ${DELETE_PAYMENT_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const deleteShipment = (shipment) => (
    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={!approved || shipment.shipment?.pendingDelete}
        onClick={() => {
          removeShipment(prev => {
            const shipments = prev.pickedShipment.map(e => ({
              ...e,
              shipment: {
                ...e.shipment,
                pendingDelete: e.shipment.id === shipment.shipment.id ? true : e.shipment.pendingDelete
              }
            }));
            return {
              ...prev,
              pickedShipment: shipments
            }
          })
          deletePaymentEntries({
            variables: {
              input: {
                paymentId: typeData.id,
                shipmentIds: [shipment.shipment.id]
              }
            }
          }).then(() => {
            refetchShipmentDataQuery()
            enqueueSnackbar(t("successfullyDeletedRecord"), {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          }).catch((error) => {
            enqueueSnackbar(error.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          })
        }}
      >
        {deletePaymentEntriesLoading && shipment.shipment?.pendingDelete ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );

  const localDataKey = localStorage.getItem(`shipmentPayment${type}`) ? JSON.parse(localStorage.getItem(`shipmentPayment${type}`)) : initialPaymentData[type]


  const adminNotesPermission = Globals?.user?.hasPermission(
    "shipping.shipment.view_admin_note"
  );
  let permission = adminNotesPermission ?? localDataKey?.includes("adminNotes")
  if (!permission && localDataKey) {

    let updateStorage = localDataKey.filter(element => !["adminNotes"].includes(element));
    localStorage.setItem(`shipmentPayment${type}`, JSON.stringify(updateStorage));
  }

  const tableBodyCell = (paymentShipment, index, shipmentNumber) => {
    return (
      <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{shipmentNumber ? shipmentNumber(index) : (index + 1)}</FixedTableCell>
        {localDataKey && localDataKey.map((i, index) =>
          <Fragment key={index}>
            <GenerateShipmentCell shipment={paymentShipment} elementKey={i} classes={classes} type={type} print={print} Permission={permission} />
          </Fragment>
        )}
        {removeShipment && deleteShipment(paymentShipment)}
      </StyledTableRow>
    );
  };

  console.log(permission);
  const tableHeadCell = () => {
    if (localDataKey) {
      let table = getShipmentsTableHeader(localDataKey, type, permission)
      const removed = removeShipment ? "" : null;
      return ["#", ...table, removed]
    }
  }
  const TableCell = tableHeadCell()
  const tableSum = localDataKey && <TableRow>
    <FixedTableCell> </FixedTableCell>

    {localDataKey && localDataKey.map((i, index) =>
      <Fragment key={index}>
        {typeData && <GenerateShipmentSumCell
          shipment={typeData} elementKey={i} type={type} Permission={permission}
        />}
      </Fragment>
    )}
    {removeShipment && <FixedTableCell> </FixedTableCell>}

  </TableRow >

  /* --------------------------------- Manifests Object --------------------------------- */

  const paymentsObject = {
    CUSTM: {
      id: "1",
      customer: true,
      label: t("manifestDetails"),
      tableHead: TableCell,
      tableBody: tableBodyCell,
      tableTotal: tableSum,
    },
    DLVBY: {
      id: "2",
      customer: true,
      label: t("manifestDetails"),
      tableHead: TableCell,
      tableBody: tableBodyCell,
      tableTotal: tableSum,
    },
  };

  return {
    details: paymentsObject[type],
  };
};

export default usePaymentsDetails;
