import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, ListItemIcon, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useWidth from '../Hooks/useWidth';
import { Globals } from '../Component/HOC/Classes/Globals';

// const options = [
//     'None',
//     'Atria',
//     'Callisto',

// ];

// const ITEM_HEIGHT = 48;
// const PREFIX = "LongMenu";

// const classes = {
//     link: `${PREFIX}-link`,

// };

// const Link = styled("div")(({ theme }) => ({
//     [`& .${classes.link}`]: {
//         textDecoration: "none",
//         color: "#313131"
//     },
// }));

export default function LongMenu(props) {
    const { icons } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const screenWidth = useWidth();
    const handleCloseMenu = () => {

        setAnchorEl(null);
    };
    const { t } = useTranslation();

    const iconsShow = icons.filter((ele) => ((typeof ele.permission === "string" || Array.isArray(ele.permission)) &&
        Globals.user.hasPermission(ele.permission)) ||
        (typeof ele.permission === "boolean" && ele.permission) ||
        ele.permission === undefined)

    const iconsShowLength = iconsShow.length > 3
    const iconsDefaultArr = iconsShowLength ? iconsShow.slice(0, 2) : iconsShow
    const iconsMenuArrInLarge = iconsShowLength ? iconsShow.slice(2) : iconsShow

    const iconsMenuArr = ["sm", "xs"].includes(screenWidth) ? iconsShow : iconsMenuArrInLarge

    const iconsDefault = (
        iconsDefaultArr.map((option, i) => (
            <Tooltip title={t(option.title)} key={option.id}>
                <Box component="span">
                    <IconButton
                        disabled={option.disabled}
                        onClick={option.action}
                    >
                        <option.icon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Tooltip>
        ))
    )

    // const iconsMenu = (
    //     iconsMenuArr.map((option) => (
    //         <MenuItem key={option.id} onClick={() => {
    //             if (!option.disabled) {
    //                 option.action && option.action()
    //                 handleCloseMenu()
    //             }
    //         }}
    //             disabled={option.disabled}>
    //             <ListItemIcon>
    //                 {option.icon && <IconButton
    //                     disabled={option.disabled}
    //                 >
    //                     <option.icon fontSize="small" />
    //                 </IconButton>}
    //             </ListItemIcon>
    //             <Typography variant="inherit">{t(option.title)}</Typography>
    //         </MenuItem>
    //     ))
    // )
    const menuList = (
        iconsMenuArr.map((option) => (
            <MenuItem key={option.id} onClick={() => {
                if (!option.disabled) {
                    option.action && option.action()
                    handleCloseMenu()
                }
            }}
                disabled={option.disabled}>
                <ListItemIcon>
                    {option.icon &&
                        <option.icon fontSize="small" />}
                </ListItemIcon>
                <Typography variant="inherit">{t(option.title)}</Typography>
            </MenuItem>
        ))
    )

    return (
        iconsShow.length > 0 ? <div>
            {!["sm", "xs"].includes(screenWidth) ?
                <Box>
                    {iconsDefault}
                    {iconsShowLength && <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>}
                </Box> :
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
            }
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                PaperProps={{
                    style: {
                    },
                }}
            >
                {menuList}
            </Menu>
        </div> : ""
    );
}
