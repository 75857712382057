import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  AttachMoney,
  Done,
  DoneAll,
  Print,
} from "@mui/icons-material";
import { Alert } from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import {
  APPROVE_COLLECTION_MUTATION,
  COLLECTION_VIEW_CONCIERGE_DATA,
  COLLECTION_VIEW_SHIPMENT_DATA,
  GL_APPROVE_COLLECTION_MUTATION,
  SAVE_COLLECTION_MUTATION,
} from "./CollectionListQuary";
// import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router";
import NotFound from "../../Error/NotFound";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_SAFES_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CustomButton from "../HOC/MUI/CustomButton";
// import MUIDate from "../HOC/MUI/MUIDate";
import useCollectionsDetails from "./CollectionDetails";
import { RootCollectionForm, RootCollectionLoadingStyle, classesCollectionForm } from "./CollectionStyle";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import useConciergeRequestsCollectionDetails from "./ConciergeRequestsDetails";
import { ShipmentTableForm } from "./Component/FormTables";
import FormTabs from "./Component/FormTaps";
import formatMoney from "../../helpers/numbersDot";
import { Globals } from "../HOC/Classes/Globals";
import config from "../../config.json";
import { defaultQueryFieldsCollection, initialData } from "./listCollectionFields";

//*********Table Function*********

/////////////////DIALOG///////////////
const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

const CollectionsFormBody = (props) => {
  const { setWatchId,collectionRefetch, collectionByIdLoad, collectionByIdQuery, collectionData, collectionId,
    collectionType,
    setCollectionData,
   
  } = props
  const clearanceCollection = props.clearanceCollection
  const formType = props.match.params.type?.toUpperCase();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();


  const [pathURL, setPathURL] = useState(props.match.path)

  const ALLOWED_CONCIERGE = Globals.settings.allowedCustomerTypes.includes("CONCIERGE")
  const ALLOWED_SHIPMENT = Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") || Globals.settings.allowedCustomerTypes.includes("MERCHANT")

  const {
    formState: { errors },
    handleSubmit,
    setError,
    control,
    setValue,
    watch,
    clearErrors,
  } = useForm();

  const theme = useTheme();
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    safe: null,
    deliveryAgent: null,
    transactionType: null,
  });

  const [disableForm, setDisableForm] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    open: false,
    edit: false,
    approve: false,
    glApprove: false,
    data: null
  });

  const [shipmentData, setShipmentData] = useState({
    page: 0,
    total: 0,
    rowsPerPage: config.app.pageSize,
    pickedShipment: [],
    noData: true
  });

  const [conciergeRequestsData, setConciergeRequestsData] = useState({
    page: 0,
    total: 0,
    rowsPerPage: config.app.pageSize,
    pickedConciergeRequests: [],
    noData: true
  });
  const [fieldsState, setFieldsState] = useState({
    createdAt: new Date(),
  });

  const [approveCollectionMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_COLLECTION_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setCollectionData(data["approveCollection"]);
      },
    }
  );

  const [glApproveCollectionMutation, { loading: glApproveLoad }] = useMutation(
    gql`
      ${GL_APPROVE_COLLECTION_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setCollectionData(data["glApproveCollection"]);
      },
    }
  );

  const [saveCollectionMutation, { loading: saveCollectionLoad }] = useMutation(
    gql`
      ${SAVE_COLLECTION_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setCollectionData(data["saveCollection"]);
        setWatchId(data?.saveCollection?.id)
      },
    }
  );
  useEffect(() => {
    if (collectionData) {
      if (collectionByIdQuery?.collection === null) return;
      const collection = collectionData;
      console.log(collectionData);
      setValue("id", parseInt(collection?.id));
      setValue("code", collection?.code);
      setValue("date", collection?.date);
      if (clearanceCollection) {
        setValue("branchId", collection.branch.id)
        setValue("transactionTypeId", collection.transactionType.id)
        setValue("deliveryAgentId", collection.deliveryAgent.id)
        setValue("safeId", collection.safe.id)
      }
      collection?.notes && setValue("notes", collection?.notes);
      setFieldsState((prev) => ({
        ...prev,
        createdAt: collection?.date,
      }));
      setAutocompleteValues({
        branch: collection?.branch,
        ...(collection?.customer && {
          customer: collection?.customer,
        }),
        ...(collection?.deliveryAgent && {
          deliveryAgent: collection?.deliveryAgent,
        }),
        safe: collection?.safe,
        transactionType: collection?.transactionType,
      });

      if (ALLOWED_SHIPMENT) {
        setShipmentData(prev => ({
          ...prev,
          total: collection?.entriesShipment?.paginatorInfo.total
        }))
        shipmentData.noData && getShipmentData()
      }
      if (ALLOWED_CONCIERGE) {
        setConciergeRequestsData(prev => ({
          ...prev,
          total: collection?.entriesConcierge?.paginatorInfo.total
        }))
      }

      setDisableForm(true);

    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionRefetch]);
  const localDataKey = localStorage.getItem(`shipmentcollection${collectionType}`) ? JSON.parse(localStorage.getItem(`shipmentcollection${collectionType}`)) : initialData[collectionType]
  const MANIFEST_SHIPMENT_DATA_VIEW_BUILDER = COLLECTION_VIEW_SHIPMENT_DATA(localDataKey ? defaultQueryFieldsCollection(localDataKey,true) : [])
  const [getShipmentData, { loading: loadingShipmentData, refetch: refetchShipmentDataQuery }] = useLazyQuery(
    gql`
        ${MANIFEST_SHIPMENT_DATA_VIEW_BUILDER.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(watch("id")),
        typeCode: "SHIPMENT",
        page: shipmentData.page + 1,
        first: shipmentData.rowsPerPage
      },
      onCompleted: (data) => {
        if (data?.collection === null) return;
        const collection = data?.collection;
        const shipments = collection.entries.data.map(e => {
          return {
            ...e,
            shipment: {
              ...e.shipment,
              pendingDelete: false
            }
          };
        });
        setShipmentData(prev => {
          return {
            ...prev,
            pickedShipment: shipments,
            noData: false,
            total: collection.entries.paginatorInfo.total
          }
        })
        collectionRefetch()
      },
    }
  );

  const [getConciergeData, { loading: loadingConciergeData, refetch: refetchConciergeDataQuery }] = useLazyQuery(
    gql`
        ${COLLECTION_VIEW_CONCIERGE_DATA.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(watch("id")),
        typeCode: "CONCIERGE_REQUEST",
        page: conciergeRequestsData.page + 1,
        first: conciergeRequestsData.rowsPerPage
      },
      onCompleted: (data) => {
        if (data?.collection === null) return;
        const collection = data?.collection;
        const conciergeRequests = collection.entries.data.map(e => {
          return {
            ...e,
            conciergeRequest: {
              ...e.conciergeRequest,
              pendingDelete: false
            }
          };
        });
        setConciergeRequestsData(prev => {
          return {
            ...prev,
            total: collection.entries.paginatorInfo.total,
            noData: false,
            pickedConciergeRequests: conciergeRequests
          }
        })
        collectionRefetch()
      },
    }
  );

  const { details: collectionDetails } = useCollectionsDetails({
    typeData: collectionData,
    type: collectionType,
    approved: !collectionData?.approved,
    setShipmentData,
    refetchShipmentDataQuery,
  });

  const { details: conciergeRequestsDetails } = useConciergeRequestsCollectionDetails({
    type: collectionType,
    approved: !collectionData?.approved,
    typeData: collectionData,
    setConciergeRequestsData,
    refetchConciergeDataQuery,
  });

  const shipLength = shipmentData.pickedShipment.length === 0;
  const conciergeReqLength = conciergeRequestsData.pickedConciergeRequests.length === 0;
  const entriesData = shipmentData.total > 0 || conciergeRequestsData.total > 0;
  ////////////////////END DRAWER////////////////
  const history = useHistory();

  const onSubmit = (data) => {
    const formChange =
      collectionData &&
      ((data?.deliveryAgentId &&
        data?.deliveryAgentId !==
        parseInt(collectionData?.deliveryAgent?.id)) ||
        (data?.customerId &&
          data?.customerId !== parseInt(collectionData?.customer?.id)) ||
        (data?.branchId &&
          data?.branchId !== parseInt(collectionData?.branch?.id)));

    data["date"] = dateFormat(data["date"]);
    if ((shipmentData.pickedShipment.length !== 0 || conciergeRequestsData.pickedConciergeRequests.length !== 0) && formChange) {
      setOpenDialog((prev) => ({ ...prev, open: true, edit: true, data: data }));
    } else {
      saveCollectionMutationFun(data)
    };
  }

  const saveCollectionMutationFun = (data, collectionRefetch) => {
    saveCollectionMutation({
      variables: {
        input: {
          ...data,
          type: collectionType,
        },
      },
    })
      .then((data) => {
        setDisableForm(true);
        setValue("code", data?.data?.saveCollection?.code);
        setValue("id", parseInt(data?.data?.saveCollection?.id));
        const url = history.createHref({
          pathname: `/admin/collections/${data?.data?.saveCollection?.id}/edit`,
        });
        setPathURL(url)
        windowReplaceUrl(url);
        collectionRefetch && collectionRefetch()
        collectionRefetch && refetchShipmentDataQuery()
        collectionRefetch && !conciergeRequestsData.noData && refetchConciergeDataQuery()
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log({ graphQLErrors });
      });
  }

  const handleDialogClose = () => {
    setOpenDialog({
      edit: false,
      open: false,
      approve: false,
      glApprove: false,
    });
  };

  const approveCollection = () => {
    approveCollectionMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then(() => {
        handleDialogClose();
        enqueueSnackbar(t("successfullyApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        pushUrl(props, `/admin/collections/${watch("id")}`);
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const glApproveCollection = () => {
    glApproveCollectionMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("successfullyGlApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        handleDialogClose();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const onApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, approve: true }));
  };
  const onGlApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, glApprove: true }));
  };
  // const disabledAddIcon = () => {
  //   const value = manifestType === "PKM" ? ["customerId", "branchId"] : ["deliveryAgentId", "branchId"]
  //   return !value.every(val => Object.keys(queryVariables.collection).includes(val))
  // }

  const parseData = (data) => {
    return data;
  };

  const customerField = (type) => {
    if (type === "CUSTM") {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            name={"customerId"}
            label={t("customer")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            disabled={!watch("branchId")}
            query={LIST_CUSTOMERS_DROPDOWN.query}
            variables={{
              input: {
                withDue: "COLLECT",
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            skip={collectionType !== "CUSTM" || !watch("branchId")}
            defaultValue={autocompleteValues.customer}
          />
        </Grid>
      );
    } else if (type === "DLVBY") {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"deliveryAgentId"}
            label={t("shippingAgent")}
            rules={{ required: t("fieldIsRequired") }}
            disabled={!watch("branchId")}
            onChangeValue={(e) => {
              if (e.id !== "") {
                clearErrors("deliveryAgentId");
              }
            }}
            parseData={(data) => parseData(data)}
            variables={{
              input: {
                withDue: "COLLECT",
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
            skip={!watch("branchId") || collectionType !== "DLVBY"}
            defaultValue={autocompleteValues.deliveryAgent}
          />
        </Grid>
      );
    }
  };

  let formBody = null;

  const formCondition = !formType
    ? Boolean(watch("id")) && !collectionByIdLoad
    : !collectionByIdLoad;

  formBody = (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      justifyContent={"center"}
      className={classesCollectionForm.formBody}
      sx={{ width: "100%" }}
    >
      {formCondition ? (
        <Paper component={Stack} spacing={1} p={1}>
          {watch("id") && disableForm && (
            <Grid sm={12} xs={12}>
              <Alert
                severity="warning"
                action={
                  !collectionData?.approved && (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setDisableForm(false);
                        // fireMutaion([])
                      }}
                    >
                      {t("update")}
                    </Button>
                  )
                }
              >
                {collectionData?.approved
                  ? t("updateRecordsForbidden")
                  : t("updateRecord")}
              </Alert>
            </Grid>
          )}

          <Grid
            spacing={2}
            container
            sx={{ position: "relative", flexGrow: 1 }}
          >
            {(collectionData?.approved || disableForm) && (
              <Box
                className={classesCollectionForm.overlay}
                sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
              ></Box>
            )}
            <Grid sm={4} xs={12}>
              <ControlMUItextField
                name="code"
                control={control}
                label={t("recordCode")}
                rules={{ required: watch("id") ? t("fieldIsRequired") : false }}

              // defaultValue={queryVariables?.collection?.code}
              // disabled={collectionByIdQuery?.collection?.approved || disableForm}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <MUIDateTime
                name="date"
                label={t("theDate")}
                control={control}
                defaultValue={collectionByIdQuery?.collection?.date}
                value={fieldsState.createdAt}
                onChange={(e) =>
                  setFieldsState((prev) => ({ ...prev, createdAt: e }))
                }
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <ListBranches
                control={control}
                errors={errors}
                name={"branchId"}
                rules={{ required: t("fieldIsRequired") }}
                skip={collectionType === null}
                onChangeValue={(e) => {
                  collectionType === "DLVBY" && setValue("deliveryAgentId", "");
                  collectionType === "CUSTM" && setValue("customerId", "");
                  setValue("safeId", "");
                  setValue("transactionTypeId", "");
                  // setPickedShipment({ shipments: [] })
                }}
                defaultValue={autocompleteValues.branch}
                skipDefaultBranch={collectionId}
              />
            </Grid>
            {customerField(collectionType)}
            <Grid sm={4} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"transactionTypeId"}
                label={t("transactionType")}
                selectFirst={true}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                skip={collectionType === null || !watch("branchId")}
                variables={{
                  input: {
                    type: "CSHC",
                    ...(watch("branchId") && {
                      branchId: {
                        value: watch("branchId"),
                        includeNull: true,
                      },
                    }),
                  },
                }}
                defaultValue={autocompleteValues.transactionType}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"safeId"}
                disabled={!watch("branchId")}
                label={t("safe")}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_SAFES_DROPDOWN.query}
                skip={!watch("branchId")}
                variables={{
                  input: {
                    branchId: watch("branchId"),
                  },
                }}
                defaultValue={autocompleteValues.safe}
              />
            </Grid>
            <Grid sm={12} xs={12}>
              <ControlMUItextField
                name="notes"
                control={control}
                label={t("notes")}
                rows={2}
              />
            </Grid>
          </Grid>

          <Grid sm={12} xs={12} container justifyContent="flex-end">
            {!disableForm && (
              <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                className={classesCollectionForm.button}
                disabled={
                  collectionData?.approved || disableForm || saveCollectionLoad
                }
                variant="contained"
                size="medium"
                // className={classes.button}
                loading={saveCollectionLoad}
                startIcon={!saveCollectionLoad && <Done />}
              >
                {!saveCollectionLoad && t("save")}
              </CustomButton>
            )}
            {disableForm && (
              <Button
                disabled={
                  (shipLength && conciergeReqLength) || collectionData?.approved || saveCollectionLoad
                }
                className={classesCollectionForm.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={onApproved}
                name="approved"
                startIcon={<DoneAll />}
              >
                {t("approve")}
              </Button>
            )}
            {collectionData?.approved && (
              <Button
                disabled={collectionData?.glApproved}
                className={classesCollectionForm.glApprovedButton}
                variant="contained"
                size="medium"
                onClick={onGlApproved}
                name="glApproved"
                startIcon={<AttachMoney />}
              >
                {t("glApprove")}
              </Button>
            )}
          </Grid>
        </Paper>
      ) : (
        <FullScreenLoading />
      )}
    </Box>
  );

  const icons = [
    {
      id: "print",
      title: "print",
      action: () =>
        window.open(
          `${window.location.origin}/report/print/collection/${watch(
            "id"
          )}/${collectionType}`
        ),
      icon: Print,
      disabled: collectionByIdLoad,
      permission: entriesData,
    },
  ]

  return (
    collectionByIdLoad ?
      <RootCollectionLoadingStyle container item justifyContent="center" className={clsx(classesCollectionForm.main, { [classesCollectionForm.customHeight]: clearanceCollection })}>
        <FullScreenLoading height={"100%"} />
      </RootCollectionLoadingStyle>
      : !collectionByIdQuery?.collection && collectionId ? (
        <NotFound />
      ) :
        <RootCollectionForm>
          {!clearanceCollection &&
            <TitleAppBar path={pathURL} type={collectionType}>
              <LongMenu icons={icons} />
            </TitleAppBar>
          }

          <Dialog
            open={openDialog.open}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"xs"}
          >
            <DialogTitle id="alert-dialog-title" color={"text.primary"}>
              {openDialog.edit && t("updateRecord")}
              {openDialog.approve && t("approve")}
              {openDialog.glApprove && t("glApprove")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {openDialog.edit && t("updateRecordMessage")}
                {openDialog.approve && t("approveRecordConfirmationMessage")}
                {openDialog.glApprove && t("glApproveRecordMessage")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                {t("cancel")}
              </Button>
              <Button
                disabled={approveLoad || glApproveLoad}
                onClick={() => {
                  if (openDialog.edit) {
                    handleDialogClose();
                    setDisableForm(true);
                    saveCollectionMutationFun(openDialog.data, collectionRefetch)
                  }
                  openDialog.approve && approveCollection();
                  openDialog.glApprove && glApproveCollection();
                }}
                color="primary"
                autoFocus
              >
                {openDialog.edit && t("update")}
                {openDialog.approve && !approveLoad && t("approve")}
                {openDialog.glApprove && !glApproveLoad && t("glApprove")}
                {(approveLoad || glApproveLoad) && <ButtonLoading />}
              </Button>
            </DialogActions>
          </Dialog>

          {formCondition && clearanceCollection &&
            <ShipmentTableForm
              watch={watch}
              disableForm={disableForm}
              collectionType={collectionType}
              collectionDetails={collectionDetails}
              clearanceCollection={clearanceCollection}
              collectionData={collectionData}

              shipmentData={shipmentData}
              setShipmentData={setShipmentData}
              loadingShipmentData={loadingShipmentData}
              refetchShipmentDataQuery={refetchShipmentDataQuery}
            />
          }
          {formCondition && !clearanceCollection &&
            <Stack spacing={2} margin={2}>
              {props.children}
              {formBody}
              {collectionDetails && collectionType === "DLVBY" && <Paper sx={{ py: 2 }}>
                <Stack direction={"row"} spacing={1} flexWrap={"wrap"} justifyContent={"center"}>
                  <Typography
                    color="primary.main"
                    variant="subtitle1"
                    component="div"
                    sx={{ textAlign: "end" }}
                  >
                    {t("total")}
                  </Typography>
                  {collectionData && <Typography

                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    sx={{ textAlign: "start" }}
                  >
                    {formatMoney(collectionData?.sumEntries?.collectedAmount - collectionData?.sumEntries?.commissionAmount)}
                  </Typography>}
                </Stack>
              </Paper>}
              <FormTabs
                watch={watch}
                disableForm={disableForm}
                collectionType={collectionType}
                ALLOWED_CONCIERGE={ALLOWED_CONCIERGE}
                ALLOWED_SHIPMENT={ALLOWED_SHIPMENT}
                collectionData={collectionData}
                clearanceCollection={clearanceCollection}

                collectionDetails={collectionDetails}
                shipmentData={shipmentData}
                setShipmentData={setShipmentData}
                loadingShipmentData={loadingShipmentData}
                refetchShipmentDataQuery={refetchShipmentDataQuery}

                conciergeRequestsDetails={conciergeRequestsDetails}
                conciergeRequestsData={conciergeRequestsData}
                getConciergeData={getConciergeData}
                setConciergeRequestsData={setConciergeRequestsData}
                loadingConciergeData={loadingConciergeData}
                refetchConciergeDataQuery={refetchConciergeDataQuery}

              />
            </Stack>
          }

        </RootCollectionForm>
  );
};

export default withRouter(CollectionsFormBody);
