import {
  Checkbox,
  Paper,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";

import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import PropTypes from "prop-types";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import { getShipmentsSumQuery, getShipmentsTableHeader } from "./ListShipmentTableCells";
import GenerateShipmentCell from "./GenerateShipmentCell";
import { Globals } from "../HOC/Classes/Globals";
import GenerateShipmentSumCell from "./GenerateShipmentSumCell";
import ShipmentsTableBody from "../HOC/CustomComponents/ShipmentsTableBody";
import ShipmentsTableHeader from "../HOC/CustomComponents/ShipmentsTableHeader";
import FiltersForm from "../../Hooks/shipmentListFilters";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import config from "../../config.json";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { SavedFilterContext } from "../Shipments/Shipments";

const PREFIX = "SearchTable";

const classes = {
  table: `${PREFIX}-table`,
  checkbox: `${PREFIX}-checkbox`,
  shipmentTable_code: `shipmentTable_code`,
  bodyCheckbox: `bodyCheckbox`,
  shipmentTable_bodyCode: `shipmentTable_bodyCode`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
  message: `${PREFIX}-message`,
  tablePaper: `${PREFIX}-tablePaper`,
  pagination: `${PREFIX}-pagination`,
  tableHead: `${PREFIX}-tableHead`,
  mainTableClass: `${PREFIX}-mainTableClass`,
  tableHeadFixed: `${PREFIX}-tableHeadFixed`,
  update: `${PREFIX}-update`,
  iconColor: `${PREFIX}-iconColor`,
  shipmentTable_description: `${PREFIX}-shipmentTable_description`,
  barcode: `${PREFIX}-barcode`,

};

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100%",
  [`& .${classes.table}`]: {
    display: "grid",
    minHeight: "250px",
  },

  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 32,
  },
  [`& .${classes.checkbox}`]: {
    minWidth: 60,
    zIndex: "101",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: "0",
    [theme.breakpoints.up("sm")]: {
      left: "0",
    },
  },
  [`& .${classes.update}`]: {
    // minWidth: 60,
    zIndex: "101",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: "0",
    [theme.breakpoints.up("sm")]: {
      right: "0",
    },
  },

  [`& .${classes.shipmentTable_code}`]: {
    zIndex: "102",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: "0",
    [theme.breakpoints.up("sm")]: {
      left: "60px",
    },
  },

  [`& .${classes.bodyCheckbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    minWidth: 60,
    [theme.breakpoints.up("sm")]: {
      left: 0,
    },
  },

  [`& .${classes.shipmentTable_bodyCode}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    [theme.breakpoints.up("sm")]: {
      left: "60px",
    },
  },

  [`& .${classes.tableBodyRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: theme.palette.background.hover,
      },
    },
  },

  [`& .${classes.message}`]: {
    display: "inline-block",
    maxWidth: 150,
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
  },

  [`&.${classes.tablePaper}`]: {
    position: "relative",
    borderRadius: 0,
    width: "100%",
  },

  [`& .${classes.pagination}`]: {
    flexShrink: 0,
  },

  [`& .${classes.tableHead}`]: {
    "& .MuiTableCell-head": { fontWeight: 600 },
    "& th": { background: theme.palette.background.paper },
  },

  [`& .${classes.mainTableClass}`]: {
    "& td": {
      maxWidth: 250,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },

  [`& .${classes.tableHeadFixed}`]: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.shipmentTable_description}`]: {
    [`& div`]: {
      position: "relative",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    },
  },

}));
export const SUM_SHIPMENT = (dataFields) => {
  return gqlb.query({
    operation: "sumShipments",
    fields: dataFields ?? [
      "price",
      "allDueFees",
      "deliveredAmount",
      "collectedFees",
      "pendingCollectionAmount",
      "collectedAmount",
      "amount",
      "totalAmount",
      "customerDueCredit",
      "customerDue",
      "customerDueDebit",
      "returningDueFees",
    ],
    variables: {
      input: {
        type: "ListShipmentsFilterInput",
      },
    },
  });
};

export const SEARCH = (dataFields) => {
  return gqlb.query({
    operation: "listShipments",
    fields: [
      {
        operation: "data",
        fields: dataFields,
        variables: {},
      },
      {
        operation: "paginatorInfo",
        fields: ["total"],
        variables: {},
      },
    ],
    variables: {
      input: {
        type: "ListShipmentsFilterInput",
      },
      first: {
        type: "Int",
      },
      page: {
        type: "Int",
      },
    },
  });
};

const SearchTable = (props) => {
  const {
    tableBody,
    tableHeadCell,
    className,
    notifyOnNetworkStatusChange,
    updateDeliveryBody,
    notSumShipments,

    setCheckedIds,
    setCheckedShipments,
    pathname,
    checkedIds,
    queryFields,
    keys,
    withCheckAll,
    setPrintListDisabled,
    icons,
    path,
    drawerState,
    onSubmitFunc,
    defaultPageInput,
    clearCheckBox,
    setPageFilter,
    filtersAllowed,
    custody,
    statusDataHandler,
    customerType,
    customFilters,
    listFilterLoading,
  } = props;

  const mergedClasses = Object.assign(classes, { ...className });
  const [noData, setNoData] = useState(true)
  // ?<///////////////////////////////// * Filters Components * ///////////////////////////////////
  const urlQuery = urlParameters(window.location.search);
  function isValidObject(obj) {
    if (obj.filterId) {
      delete obj.filterId
    }
    // Check if the object has the 'page' and 'rowsPerPage' properties
    if (!obj.hasOwnProperty('page') || !obj.hasOwnProperty('rowsPerPage')) {
      return false;
    }
    // Check if the object has any other properties
    for (let key in obj) {
      if (key !== 'page' && key !== 'rowsPerPage') {
        return false;
      }
    }
    return true;
  }

  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const [queryVariables, setQueryVariables] = useState({
    refetch: true
  });

  const contextValue = useContext(SavedFilterContext)

  const { refetch, ...restVariables } = queryVariables;
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);

  for (const key in restVariables) {
    if (restVariables[key] === "" || restVariables[key] === null) {
      delete restVariables[key];
    }
  }

  const input = {
    ...defaultPageInput,
    ...(contextValue ? Object.keys(contextValue.filterData).length !== 0 ? contextValue.filterData : restVariables : restVariables),
    // ...restVariables,
    ...(queryVariables?.toDate && { toDate: queryVariables?.toDate }),
    ...(queryVariables?.inWarehouse !== null &&
      queryVariables?.inWarehouse !== undefined && {
      inWarehouse: queryVariables?.inWarehouse,
    }),
  };

  let filterId
  if (input.filterId) {
    filterId = {
      filterId: input.filterId
    }
    delete input.filterId
  }

  useEffect(() => {
    if (restVariables.filterId) {
      delete restVariables.filterId
    }
    setPageFilter(restVariables)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVariables])

  const history = useHistory();
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/shipments",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const resetPage = () => setPage(0);

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      // ...dateValue,
      page: newPage,
      rowsPerPage: rowsPerPage,
      ...(filterId && filterId),
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      // ...dateValue,
      page: 0,
      rowsPerPage: +event.target.value,
      ...(filterId && filterId),
    });

    setRowsPerPage(+event.target.value);
    resetPage();
  };
  // ?<///////////////////////////////// * End Filters Components * ///////////////////////////////////

  const { data, loading: listLoading } = useQuery(
    gql`
      ${SEARCH(queryFields).query}
    `,
    {
      skip: (validUrlParameters && !isValidObject(urlQuery) && Object.keys(restVariables).length === 0) || listFilterLoading,
      partialRefetch: refetch,
      notifyOnNetworkStatusChange: notifyOnNetworkStatusChange ?? true,
      variables: {
        input: {
          ...input,
        },
        first: rowsPerPage,
        page: page + 1,
      },

      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setNoData(false)
        const shipments = data.listShipments.data;
        if (shipments.length === 0 && setPrintListDisabled) {
          setPrintListDisabled(true)
        } else {
          setPrintListDisabled && setPrintListDisabled(false)
        }
        setCheckedShipments && setCheckedShipments(shipments);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const shipmentsSumQuery = keys && getShipmentsSumQuery(keys)
  const sumShipmentsSkip = shipmentsSumQuery && shipmentsSumQuery.queryFields.length === 0

  const { data: sumShipments } = useQuery(
    gql`
      ${SUM_SHIPMENT(shipmentsSumQuery?.queryFields).query}
    `,
    {
      skip: !data || sumShipmentsSkip || notSumShipments,
      partialRefetch: refetch,
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          ...input,
        },
      },

      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const listShipments = data?.listShipments?.data;
  const dataEmpty = listShipments?.length === 0;

  //?<///////////////////////////////// * CheckBox Function * ///////////////////////////////////
  const isChecked = (id) => {
    return checkedIds.includes(id);
  };
  const currentShipmentIds = listShipments?.map((i) => i.id);
  const shipmentsCount = currentShipmentIds?.length;

  const fieldSelected = currentShipmentIds?.filter((i) =>
    checkedIds.includes(i)
  )?.length;

  const indeterminate = () =>
    fieldSelected > 0 && fieldSelected < shipmentsCount;
  const selectAllChecked = () =>
    shipmentsCount > 0 && fieldSelected === shipmentsCount;

  const onCheckAll = (e) => {
    let ids = [];
    if (e.target.checked) {
      const checkedAll = new Set([...checkedIds, ...currentShipmentIds]);
      ids = [...checkedAll];
    } else {
      ids = checkedIds.filter((i) => !currentShipmentIds.includes(i));
    }
    setCheckedIds(ids);
  };
  const toggleCheck = (e, id) => {
    const checked = e.target.checked;
    let updateCheckedIds = [...checkedIds];
    if (checked) {
      updateCheckedIds.push(id);
    } else {
      updateCheckedIds = updateCheckedIds.filter((i) => i !== id);
    }
    setCheckedIds(updateCheckedIds);
  };

  const checkAll = (
    <FixedTableCell className={classes.checkbox}>
      <Checkbox
        edge="start"
        id="check-all-shipment"
        indeterminate={indeterminate()}
        checked={selectAllChecked()}
        inputProps={{ "aria-label": "select all desserts" }}
        tabIndex={-1}
        onChange={(e) => onCheckAll(e)}
        disableRipple
      />
    </FixedTableCell>
  );
  const user = Globals.user;
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );

  //?<///////////////////////////////// * End CheckBox Function * /////////////////////////////////// 
  const ifCode = keys?.find((e) => e === "code")
  const ifStatus = keys?.find((e) => e === "status")
  const defaultTableBody = tableBody
    ? (shipment, index) => tableBody(shipment, index, isChecked, toggleCheck)
    : (shipment, index) => (
      <TableRow key={index} className={classes.tableBodyRow}>
        <FixedTableCell key={`${index}-che`} className={classes.bodyCheckbox}>
          <Checkbox
            edge="start"
            checked={isChecked(shipment?.id)}
            onChange={(e) => toggleCheck(e, shipment?.id)}
            disableRipple
          />
        </FixedTableCell>
        {keys.map((ele, i) => {
          return <Fragment key={i}>
            <GenerateShipmentCell shipment={shipment} elementKey={ele} classes={classes} ifCode={ifCode} adminNotesPermission={adminNotesPermission} />
          </Fragment>
        })}
        {updateDeliveryBody && updateDeliveryBody(shipment, index)}
      </TableRow>
    );

  const memorizedTableBody = useMemo(
    () => defaultTableBody,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isChecked, toggleCheck]
  );

  const sumRow = (
    <TableRow>
      {<FixedTableCell className={classes.bodyCheckbox}> </FixedTableCell>}
      {ifCode && <FixedTableCell className={classes.shipmentTable_bodyCode}> </FixedTableCell>}
      {ifStatus && <FixedTableCell className={!ifCode ? classes.shipmentTable_bodyCode : classes.shipmentTable_bodyStatus}> </FixedTableCell>}
      {
        sumShipments?.sumShipments && shipmentsSumQuery.selected.map((i, index) =>
          <Fragment key={index}>
            <GenerateShipmentSumCell
              shipmentSum={sumShipments?.sumShipments}
              elementKey={i}
            />
          </Fragment>
        )
      }
    </TableRow >
  );

  return (
    <StyledPaper className={classes.tablePaper}>
      <ListWrapper
        drawerState={drawerState ?? false}
        icons={icons ?? false}
        path={path ?? false}
        empty={dataEmpty}
        loading={listLoading || !listShipments}
        customFilters={customFilters}
        filters={
          <FiltersForm
            {...{ onSubmitFunc, resetPage, rowsPerPage }}
            queryVariables={(variables) => setQueryVariables({ ...variables })}
            noData={noData}
            pathname={pathname}
            clearCheckBox={clearCheckBox}
            filtersAllowed={filtersAllowed ?? []}
            statusDataHandler={statusDataHandler}
            customerType={customerType}
            custody={custody}
          />
        }
        tableHeaders={
          <ShipmentsTableHeader
            classes={mergedClasses}
            headCells={tableHeadCell ?? getShipmentsTableHeader(keys, adminNotesPermission, Boolean(updateDeliveryBody))}
            checkAll={withCheckAll ? checkAll : null}
          />
        }
        tableBody={
          <ShipmentsTableBody
            data={data?.listShipments?.data ?? []}
            parseBodyCell={memorizedTableBody}
            sumShipments={sumShipments && sumRow}
          />
        }
        pagination={
          <MUITablePagination
            count={data?.listShipments?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listShipments ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </StyledPaper>

  );
};
SearchTable.propTypes = {
  // skipListShipmentsQuery: PropTypes.bool,
  // // sum: PropTypes.bool,
  // queryVariables: PropTypes.object,
  // onPageChange: PropTypes.func,
  // onChangeRows: PropTypes.func,
  // loading: PropTypes.func,
  // setCheckedIds: PropTypes.func,

  tableBody: PropTypes.any,
  tableHeadCell: PropTypes.any,
  className: PropTypes.any,
  notifyOnNetworkStatusChange: PropTypes.any,
  updateDeliveryBody: PropTypes.any,
  notSumShipments: PropTypes.any,

  setCheckedIds: PropTypes.any,
  setCheckedShipments: PropTypes.any,
  pathname: PropTypes.any,
  checkedIds: PropTypes.any,
  queryFields: PropTypes.any,
  keys: PropTypes.any,
  withCheckAll: PropTypes.any,
  setPrintListDisabled: PropTypes.any,
  icons: PropTypes.any,
  path: PropTypes.any,
  drawerState: PropTypes.any,
  onSubmitFunc: PropTypes.any,
  defaultPageInput: PropTypes.any,
  clearCheckBox: PropTypes.any,
  setPageFilter: PropTypes.any,
  filtersAllowed: PropTypes.any,
};

export default SearchTable;
