import { Checkbox, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Fragment } from "react";
import CellLink from "../../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { PaymentShipmentsCells } from "./ShipmentListDetails";
const PREFIX = "CustomTableRow";

const classes = {
  shipmentTable_checkbox: `${PREFIX}-shipmentTable_checkbox`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(TableRow)(({ theme }) => ({
  [`& .${classes.shipmentTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    left: 0,
  },
}));

const CustomTableRow = (props) => {
  const { shipment, onChangeCheck, labelId, type } = props;
  const shipmentDetails = shipment?.shipment;
 
  // useEffect(() => {
  //   const initialCheck = selectedObject.some((i) => i?.id === shipment.id);
  //   setCheck(initialCheck);
  //   return () => {};
  // }, [selectedObject]);

  // const handleChecked = (e) => {
  //   const checked = e.target.checked;
  //   setCheck(checked);
  //   setSelectedShipment(checked, shipment);
  // };
  return (
    <Fragment>
      <Root
        hover
        role="checkbox"
        aria-checked={shipment.select}
        tabIndex={-1}
        selected={shipment.select}
      >
        <FixedTableCell
          className={classes.shipmentTable_checkbox}
          padding="checkbox"
        >
          <Checkbox
            onChange={(event) => onChangeCheck(event)}
            checked={shipment.select}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </FixedTableCell>

        <CellLink
          component="th"
          scope="shipment"
          pathname={`/admin/shipments/${shipmentDetails?.shipment.id}`}
        >
          {shipmentDetails?.shipment?.code}
        </CellLink>
        <PaymentShipmentsCells shipmentDetails={shipmentDetails} type={type} />
      </Root>
    </Fragment>
  );
};

export default CustomTableRow;
