/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { ROUTE_ID } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import StopsTable from "./StopsTable";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";

const PREFIX = 'RouteView';

const classes = {
  loading: `${PREFIX}-loading`,
  box: `${PREFIX}-box`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  main: `${PREFIX}-main`,
  field: `${PREFIX}-field`,
  iconColor: `${PREFIX}-iconColor`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.loading}`]: {},

  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.main}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  }
}));

const RouteView = (props) => {

  const { t } = useTranslation();
  const routeId = props.match.params.id?.trim();
  const { loading, data } = useQuery(
    gql`
      ${ROUTE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(routeId) },
    }
  );
  const makeName = data?.route?.vehicle?.series?.model?.make?.name;
  const modelName = data?.route?.vehicle?.series?.model?.name;
  const seriesName = data?.route?.vehicle?.series?.name;
  const plateNumber = data?.route?.vehicle?.plateNumber;

  const editURL = () => {
    pushUrl(props, `/admin/routes/${routeId}/edit`);
  }

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: editURL,
      icon: Edit,
      permission: "shipping.route.update",
    },
  ]
  return (
    <StyledGrid
      container
      justifyContent="center"
      className={classes.mainTracking}
      sx={{ margin: "0", width: "100%" }}
    >
      <Fragment>
        <TitleAppBar path={props.match.path}>
          <LongMenu icons={icons} />
        </TitleAppBar>
      </Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", p: 2, gap: 2 }}
      >
        {loading ? (
          <FullScreenLoading minHeight="25%" />
        ) : (
          <Paper container className={classes.box} component={Grid}>
            <KeyValuePair title={t("name")} value={data?.route?.name} />
            <KeyValuePair title={t("code")} value={data?.route?.code} />
            <KeyValuePair
              title={t("car")}
              value={
                (makeName ? makeName + " - " : null) +
                (modelName ? modelName + " - " : null) +
                (seriesName ? seriesName : null) +
                (plateNumber ? " (" + plateNumber + ")" : null)
              }
            />
            <KeyValuePair
              title={t("driver")}
              value={data?.route?.driver?.name}
            />
            <KeyValuePair
              md={6}
              title={t("active")}
              value={
                data?.route?.active ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
          </Paper>
        )}
      </Grid>

      {data?.route?.stops && <StopsTable stops={data.route?.stops} />}
    </StyledGrid>
  );
};

export default RouteView;
