import React, { useState } from "react";


import { gql, useQuery } from "@apollo/client";

import {
  COLLECTION_BY_ID_QUERY,
} from "./CollectionListQuary";
// import ControlMUItextField from '../HOC/MUI/ControlMUItextField';

import CollectionsFormBody from "./CollectionFormBody"
import { withRouter } from "react-router";

import { Globals } from "../HOC/Classes/Globals";

//*********Table Function*********

/////////////////DIALOG///////////////

const CollectionsForm = (props) => {
  const clearanceCollection = props.clearanceCollection
  const formType = props.match.params.type?.toUpperCase();
  const [watchId, setWatchId] = useState();
  const [collectionType, setCollectionsType] = useState(
    formType ? formType : null
  );
  const collectionId = clearanceCollection ? clearanceCollection.id : props.match.params.id;
  const ALLOWED_CONCIERGE = Globals.settings.allowedCustomerTypes.includes("CONCIERGE")
  const ALLOWED_SHIPMENT = Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") || Globals.settings.allowedCustomerTypes.includes("MERCHANT")

  const [collectionData, setCollectionData] = useState();

  const collectionByIdQueryBuilder = COLLECTION_BY_ID_QUERY(ALLOWED_CONCIERGE, ALLOWED_SHIPMENT)

  const { data: collectionByIdQuery, loading: collectionByIdLoad, refetch: collectionRefetch } = useQuery(
    gql`
      ${collectionByIdQueryBuilder.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !collectionId,
      variables: {
        id: collectionId ? parseInt(collectionId) : watchId,
      },
      onCompleted: (data) => {
        if (data?.collection === null) return;
        const collection = data?.collection;
        setCollectionsType(collection?.type?.code);

        setCollectionData(collection);
      },
    }
  );
  return (
    collectionType&&<CollectionsFormBody
      {...props}
      setWatchId={setWatchId}
      collectionRefetch={collectionRefetch}
      collectionByIdLoad={collectionByIdLoad}
      collectionByIdQuery={collectionByIdQuery}
      collectionData={collectionData}
      collectionId={collectionId}
      collectionType={collectionType}
      setCollectionData={setCollectionData}

    />
  )


};

export default withRouter(CollectionsForm);
