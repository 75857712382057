import * as gqlb from "gql-query-builder";

export const LIST_ZONES = gqlb.query({
  operation: "listZones",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "lastPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "name",
        "active",
        { parent: ["id", "name"] },

        {
          branch: ["id", "name"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListZonesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const ZONE_ID = gqlb.query({
  operation: "zone",
  fields: [
    "id",
    "code",
    "name",
    "active",
    {
      branch: ["id", "name"],
    },
    {
      children: [
        "id",
        "code",
        "name",
        "active",
        {
          branch: ["id", "name"],
        },
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_ZONE = gqlb.mutation({
  operation: "saveZone",
  fields: ["id", "code", "name", "active", { branch: ["id", "name"] }],
  variables: {
    input: {
      type: "ZoneInput",
      required: true,
    },
  },
});
