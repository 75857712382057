import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import { Reorder } from "framer-motion";
import { useTranslation } from "react-i18next";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import MUItextField from "../HOC/MUI/MUItextField";
import { useForm } from "react-hook-form";
import SearchIcon from '@mui/icons-material/Search';
export default function ShipmentColumnView(props) {
  const {
    disabled,
    openViewColum,
    setOpenViewColum,
    localStorageName,
    initialData,
    setKeys,
    shipmentFields,
  } = props;
  const { handleSubmit, register,reset} = useForm();
  const [shipmentFieldsState, setShipmentFieldsState] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const isIcon = !Boolean(setOpenViewColum);

  useEffect(() => {
    const queryShipmentFields = [...shipmentFields];
    queryShipmentFields.forEach((ele, index) => {
      ele.id = ele.id || index + 1;
      ele.checked = ele.checked ?? true;
    });

    const localStorageViewShipment = localStorage.getItem(localStorageName);
    const parsedViewShipment = localStorageViewShipment
      ? JSON.parse(localStorageViewShipment)
      : initialData;

    const notCheckedFields = queryShipmentFields.filter((i) => {
      if (!parsedViewShipment?.includes(i.key)) {
        i.checked = false;
        return true;
      }
      return false;
    });

    const checkedFields = queryShipmentFields.filter((i) => parsedViewShipment?.includes(i.key));

    const sortedFields = checkedFields.sort(
      (a, b) => parsedViewShipment?.indexOf(a.key) - parsedViewShipment?.indexOf(b.key)
    );

    const prioritizeFields = (fields, keys) => {
      keys.forEach((key) => {
        const index = fields.findIndex((item) => item.key === key);
        if (index > -1) {
          const [element] = fields.splice(index, 1);
          sortedFields.unshift(element);
        }
      });
    };

    prioritizeFields(notCheckedFields, ["status", "code"]);

    setShipmentFieldsState([...sortedFields, ...notCheckedFields]);
    
  }, [openViewColum,initialData,localStorageName,shipmentFields]);

  const [dialogState, setDialogState] = useState(false);
  const fieldCount = shipmentFieldsState.length;
  const fieldSelected = shipmentFieldsState.filter((i) => i.checked).length;

  const onSelectAllClick = (e) => {
    const checked = e.target.checked;
    setShipmentFieldsState((prevState) =>
      prevState.map((field) => ({ ...field, checked }))
    );
    sortFieldsState();
  };

  const onCloseDialog = () => {
    isIcon ? setDialogState(false) : setOpenViewColum(false);
    setSearchQuery("")
    reset({ keyword: '' });
  };

  const onOpenDialog = () => {
    setDialogState(true);
  };

  const handleReorder = (newOrder) => {
    setShipmentFieldsState((prevState) => {
      const fixedFields = prevState.filter((item) => item.key === "code" || item.key === "status");
      newOrder = newOrder.filter((item) => item.key !== "code" && item.key !== "status");
      return [...fixedFields, ...newOrder];
    });
  };

  const handleViewShipment = () => {
    const updatedFields = shipmentFieldsState
      .filter((field) => field.checked)
      .map((field) => field.key);

    if (updatedFields.includes("status") && updatedFields.includes("code")) {
      [updatedFields[0], updatedFields[1]] = ["code", "status"];
    }

    setKeys(updatedFields);
    localStorage.setItem(localStorageName, JSON.stringify(updatedFields)); 
    setSearchQuery("")
    onCloseDialog();
    reset({ keyword: '' });
   

  };

  const sortFieldsState = () => {
    setShipmentFieldsState((prevState) => {
      const fixedFields = prevState.filter((field) => field.key === "code" || field.key === "status");
      const checkedFields = prevState.filter(
        (field) => field.checked && field.key !== "code" && field.key !== "status"
      );
      const uncheckedFields = prevState.filter(
        (field) => !field.checked && field.key !== "code" && field.key !== "status"
      );
      return [...fixedFields, ...checkedFields, ...uncheckedFields];
    });
  };

  const filteredFieldsState = shipmentFieldsState.filter((field) =>
    t(field.label).toLowerCase().includes(searchQuery.toLowerCase())
  );

  const submit = () => {
    return {}
};
  return (
    <>
      {isIcon && (
        <Tooltip title={t("exportExcel")}>
          <Box component="span">
            <IconButton disabled={disabled} onClick={onOpenDialog}>
              <Icon>sim_card_download</Icon>
            </IconButton>
          </Box>
        </Tooltip>
      )}
      <CustomDialog
        fullWidth
        maxWidth="xs"
        open={isIcon ? dialogState : openViewColum}
        onClose={onCloseDialog}
        title={
          <Fragment>
            <Checkbox
              edge="start"
              indeterminate={fieldSelected > 0 && fieldSelected < fieldCount}
              checked={fieldCount > 0 && fieldSelected === fieldCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all fields" }}
            />
            {t("viewColumns")}
            <form onSubmit={handleSubmit(submit)}>
                    <MUItextField
                        label={t("keyword")}
                         margin="normal"
                        autoFocus
                        name={"keyword"}
                        variant={'outlined'}
                        register={register}
                        InputProps={{
                            startAdornment: (
                                <SearchIcon sx={{ marginRight: 1 }} fontSize="medium" />
                            )
                        }}
                        defaultValue=""
                        onChange={(e) => setSearchQuery(e.target.value)}
                        
                    />
                </form>
          </Fragment>
        }
        actions={
          <>
            <Button color="primary" onClick={onCloseDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={fieldSelected === 0}
              onClick={handleViewShipment}
            >
              {<ButtonLoading /> && t("save")}
            </Button>
          </>
        }
        content={
          <Fragment>
            <Reorder.Group axis="y" values={filteredFieldsState} onReorder={handleReorder}  style={{padding:"0px"}} >
              <List>
                {filteredFieldsState.map((field) => (
                  <Reorder.Item key={field.key} value={field}>
                    <CustomItemList
                      itemDetails={field}
                      state={shipmentFieldsState}
                      setState={setShipmentFieldsState}
                      sortFieldsState={sortFieldsState}
                    />
                  </Reorder.Item>
                ))}
              </List>
            </Reorder.Group>
          </Fragment>
        }
      />
    </>
  );
}

const CustomItemList = ({ itemDetails, state, setState, sortFieldsState }) => {
  const { label, checked } = itemDetails;
  const { t } = useTranslation();

  const handleToggle = () => {
    setState((prevState) =>
      prevState.map((item) =>
        item.key === itemDetails.key ? { ...item, checked: !item.checked } : item
      )
    );
    sortFieldsState();
  };

  return (
    <ListItem dense button onClick={handleToggle}  sx={{padding:"0px"}}>
      <ListItemIcon>
        <Checkbox edge="start" checked={checked} tabIndex={-1} disableRipple />
      </ListItemIcon>
      <ListItemText primary={t(label)} />
    </ListItem>
  );
};
