import { Clear } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomComponents/CustomDialog";
import MUIStaticDateRangePicker from "./MUIStaticDateRangePicker";
import { Button } from "@mui/material";
import CustomTab from "../FunctionComponents/CustomTab";
import SelectPeriodRadio from "./SelectPeriodRadio";

const options = [
  {
    label: "threeDays",
    value: 3
  },
  {
    label: "sevenDay",
    value: 7
  },
  {
    label: "thirtyDays",
    value: 30
  },
]

const dateConverter = (data) => moment(data).locale("en").format("YYYY/MM/DD");
const MUIDateRangeDialog = (props) => {
  const {
    value: initialValue,
    variant,
    onChangeValue,
    resetDate,
    setRangeState,
    rangeState,
    startText
  } = props;

  const { t } = useTranslation();
  const [dateRangeDialog, setDateRangeDialog] = useState(false);
  const [newValue, setNewValue] = useState();

  const onChangeNewValue = (val) => {
    setNewValue(val);
  };

  const closeDateRangeDialog = () => {
    setDateRangeDialog(false);
  };


  const onDateRangeAccept = (date, closeDateRangeDialog) => {
  };

  const submitValue = (date, closeDateRangeDialog) => {
    onChangeValue(newValue ? newValue : initialValue)
    setNewValue()
    setDateRangeDialog(false);
  };

  const tapsArray = [
    {
      tabHead: t("date"),
      panel: <MUIStaticDateRangePicker
        value={Array.isArray(initialValue) ? initialValue : [null, null]}
        onChangeValue={onChangeNewValue}
        onAccept={(e) => onDateRangeAccept(e, closeDateRangeDialog)}
      />,
    },
    {
      tabHead: t("period"),
      panel:
        <SelectPeriodRadio
          setRangeState={setRangeState}
          rangeState={rangeState}
          value={Array.isArray(initialValue) ? "" : initialValue}
          onChangeValue={onChangeNewValue}
          options={options}
        />,
    },
  ];

  const ifInitialValueInOptions = options.find(option => option.value === initialValue)

  return (
    <>
      <CustomDialog
        open={dateRangeDialog}
        onClose={closeDateRangeDialog}
        content={
          <CustomTab tapDetails={tapsArray} valueTab={Array.isArray(initialValue) ? 0 : 1} />
        }
        actions={
          <Stack direction={"row"} spacing={0.5}>
            <Button onClick={submitValue} >{t("confirm")}</Button>
            <Button onClick={closeDateRangeDialog} color="error">{t("cancel")}</Button>
          </Stack>
        }
      />

      <TextField
        fullWidth
        variant={variant ?? "filled"}
        size="small"
        helperText={null}
        onClick={() => setDateRangeDialog(true)}
        label={startText || t("theDate")}
        InputProps={{
          ...(resetDate &&
            (Array.isArray(initialValue) ? initialValue[0] : initialValue) && {
            endAdornment: (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();

                  resetDate(e);
                }}
              >
                <Clear size="small" />
              </IconButton>
            ),
          }),
        }}
        inputProps={{
          readOnly: true,
          value: Array.isArray(initialValue) ? initialValue[0]
            ? `${dateConverter(initialValue[0])} – ${dateConverter(initialValue[1])}`
            : "-" : !!ifInitialValueInOptions ? t(rangeState === "UNTIL" ? "until" : "since") + " " + t("days", { count: ifInitialValueInOptions.value }) : initialValue ? t(rangeState === "UNTIL" ? "until" : "since") + " " + t("days", { count: initialValue }) : "-",
        }}
      />
    </>
  );
};
MUIDateRangeDialog.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
};

export default MUIDateRangeDialog;
