import * as gqlb from "gql-query-builder";
export const SAVE_BANK = gqlb.mutation({
  operation: "saveBank",
  fields: ["id", "name"],
  variables: {
    input: {
      type: "BankInput",
      required: true,
    },
  },
});

export const LIST_BANKS = gqlb.query({
  operation: "listBanks",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: ["id", "name", "code", "active"],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListBanksFilterInput",
    },
  },
});

export const BANK = gqlb.query({
  operation: "bank",
  fields: ["code", "name", "active"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
