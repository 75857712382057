import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { AddCircleOutline, Delete, Edit } from "@mui/icons-material";
import clsx from "clsx";
// import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import {
  DELETE_FINANCIAL_SHEET_DETAIL,
  DELETE_FINANCIAL_SHEET_LINE,
} from "./Graphql";
import { gql, useMutation } from "@apollo/client";

const PREFIX = "FinancialSheetTables";

const classes = {
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  tableRow: `${PREFIX}-tableRow`,
  lineDetails: `${PREFIX}-lineDetails`,
  addIcon: `${PREFIX}-addIcon`,
  tableTitle: `${PREFIX}-tableTitle`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.table}`]: {
    display: "grid",
  },
  [`& .${classes.tableTitle}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  [`& .${classes.lineDetails}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  [`& .${classes.addIcon}`]: {
    color: theme.palette.success.main,
  },
}));

export default function FinancialSheetTables(props) {
  const {
    LineList = [],
    addLines,
    addDetail,
    financialSheetId,
    setLineList,
    setDisabled,
  } = props;
  const { t } = useTranslation();

  //////////// Lines Functions ////////////

  const [linePage, setLinePage] = useState(0);
  const [lineRowsPerPage] = useState(20);
  const [lineIndex, setLineIndex] = useState(0);

  const handleChangePage = (event, newPage) => {
    setLinePage(newPage);
  };

  const [deleteFinacialLineMutation, { loading: deleteLoad }] = useMutation(
    gql`
      ${DELETE_FINANCIAL_SHEET_LINE.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  //////////// Detail Functions ////////////

  const [detailPage, setDetailPage] = useState(0);
  const [detailRowsPerPage, setDetailRowsPerPage] = useState(20);

  const handleDetailPage = (event, newPage) => {
    setDetailPage(newPage);
  };

  const handleDetailRowsPerPage = (event) => {
    setDetailRowsPerPage(parseInt(event.target.value));
    setDetailPage(0);
  };
  const [deleteDetailMutation, { loading: deleteDetail }] = useMutation(
    gql`
      ${DELETE_FINANCIAL_SHEET_DETAIL.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );
  const lineDetails = LineList?.[lineIndex]?.details;

  // row.services.map(ele => ' ' + ele.name + ' , ')

  const detailTable = (
    <Grid
      container
      item
      sm={12}
      md={12}
      className={classes.lineDetails}
      component={Paper}
    >
      <Toolbar variant="dense" className={classes.toolbar}>
        <Typography
          className={classes.title}
          variant="h6"
          color={"text.primary"}
        >
          {t("lineDetail")}
        </Typography>

        {addDetail ? (
          <IconButton
            aria-label="Add"
            disabled={
              Boolean(LineList.length === 0) ||
              Boolean(LineList?.[lineIndex]?.type?.code === "ACCOUNT")
            }
            onClick={() => addDetail(lineIndex)}
            size="large"
            className={classes.addIcon}
          >
            <AddCircleOutline />
          </IconButton>
        ) : null}
      </Toolbar>

      <Grid container className={classes.table}>
        <TableFixedHeaderWraper className={classes.tableContainer}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <FixedTableCell>{t("numberLine")}</FixedTableCell>
                <FixedTableCell>{t("accountName")}</FixedTableCell>
                {addDetail ? <FixedTableCell></FixedTableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {lineDetails &&
                (detailRowsPerPage > 0
                  ? lineDetails.slice(
                      detailPage * detailRowsPerPage,
                      detailPage * detailRowsPerPage + detailRowsPerPage
                    )
                  : lineDetails
                )?.map((row, index) => (
                  <TableRow hover className={classes.tableRow} key={index}>
                    <FixedTableCell>{row.number}</FixedTableCell>
                    <FixedTableCell>{row.glAccount?.name}</FixedTableCell>
                    {addDetail ? (
                      <FixedTableCell>
                        <IconButton
                          size="small"
                          onClick={() =>
                            addDetail(
                              lineIndex,
                              detailPage * detailRowsPerPage + index
                            )
                          }
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          size="small"
                          disabled={deleteDetail}
                          onClick={() => {
                            setLineList((prev) => {
                              const filterd = prev[lineIndex]["details"].filter(
                                (i, deleteIndex) =>
                                  deleteIndex !==
                                  detailPage * detailRowsPerPage + index
                              );
                              const updated = [...prev];

                              updated[lineIndex]["details"] = filterd;
                              Number.isInteger(
                                prev[lineIndex]["details"].length /
                                  detailRowsPerPage
                              ) && setDetailPage(detailPage);
                              return updated;
                            });
                            deleteDetailMutation({
                              variables: {
                                id: parseInt(row.id),
                              },
                            });
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </FixedTableCell>
                    ) : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableFixedHeaderWraper>
        <MUITablePagination
          count={lineDetails?.length ?? 0}
          rowsPerPage={detailRowsPerPage}
          page={detailPage}
          onPageChange={handleDetailPage}
          onRowsPerPageChange={handleDetailRowsPerPage}
        />
      </Grid>
    </Grid>
  );
  const LinesTable = (
    <Grid
      container
      item
      sm={12}
      md={12}
      className={classes.lineDetails}
      component={Paper}
    >
      <Toolbar variant="dense" className={classes.toolbar}>
        <Typography
          className={classes.title}
          variant="h6"
          color={"text.primary"}
        >
          {" "}
          {t("lines")}
        </Typography>

        {addLines ? (
          <IconButton
            aria-label="Add"
            disabled={financialSheetId !== 0 && !Boolean(financialSheetId)}
            onClick={() => {
              addLines();
              setDetailPage(0);
              setLineIndex(0);
            }}
            size="large"
            className={classes.addIcon}
          >
            <AddCircleOutline />
          </IconButton>
        ) : null}
      </Toolbar>

      <Grid container item className={classes.table}>
        <TableFixedHeaderWraper className={classes.tableContainer}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <FixedTableCell>{t("numberLine")}</FixedTableCell>
                <FixedTableCell>{t("typeLine")}</FixedTableCell>
                <FixedTableCell>{t("descriptionLine")}</FixedTableCell>
                <FixedTableCell>{t("displayLine")}</FixedTableCell>
                {addLines ? <FixedTableCell></FixedTableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {LineList.length > 0 &&
                (lineRowsPerPage > 0
                  ? LineList.slice(
                      linePage * lineRowsPerPage,
                      linePage * lineRowsPerPage + lineRowsPerPage
                    )
                  : LineList
                ).map((row, index) => (
                  <TableRow
                    className={clsx(classes.tableRow, {
                      [classes.focus]:
                        linePage * lineRowsPerPage + index === lineIndex,
                    })}
                    hover
                    key={index}
                    onClick={() => {
                      setDetailPage(0);
                      setLineIndex(linePage * lineRowsPerPage + index);
                    }}
                  >
                    <FixedTableCell>{row?.number}</FixedTableCell>
                    <FixedTableCell>{row?.type?.name}</FixedTableCell>
                    <FixedTableCell>
                      {row?.type?.code === "DESCRIPTION"
                        ? row?.description
                        : row?.glAccount?.name}
                    </FixedTableCell>
                    <FixedTableCell>{row?.display?.name}</FixedTableCell>
                    {addLines ? (
                      <FixedTableCell>
                        <IconButton
                          size="small"
                          onClick={() =>
                            addLines(linePage * lineRowsPerPage + index)
                          }
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          size="small"
                          disabled={deleteLoad}
                          onClick={() => {
                            setLineList((prev) => {
                              const filterd = prev.filter(
                                (i, deleteIndex) =>
                                  deleteIndex !==
                                  linePage * lineRowsPerPage + index
                              );
                              return filterd;
                            });
                            linePage * lineRowsPerPage + index === 0 &&
                              setDisabled((prev) => ({
                                ...prev,
                                branch: false,
                              }));

                            deleteFinacialLineMutation({
                              variables: {
                                id: parseInt(row.id),
                              },
                            });
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </FixedTableCell>
                    ) : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableFixedHeaderWraper>
        <MUITablePagination
          count={LineList?.length ?? 0}
          rowsPerPage={lineRowsPerPage}
          page={linePage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[20]}
          disableLastPage
        />
      </Grid>
    </Grid>
  );

  return (
    <StyledGrid container alignItems="flex-start" sx={{ margin: "16px" }}>
      <Grid
        container
        item
        sm={12}
        md={6}
        className={classes.lineDetails}
        component={Paper}
      >
        {" "}
        {LinesTable}
      </Grid>

      <Grid
        container
        item
        sm={12}
        md={6}
        className={classes.lineDetails}
        component={Paper}
      >
        {" "}
        {detailTable}
      </Grid>
    </StyledGrid>
  );
}
