import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect } from "react";

import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import * as gqlb from "gql-query-builder";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
// import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import "../ManifestPrint/print.css";
import {
    RootPrintTotalsPerZones,
    classesPrintTotalsPerZones,
} from "./Styles";
import formatMoney from "../../helpers/numbersDot";
import LogoImg from "../../Layout/LogoImg";

export { };

const org = {
    operation: "organization",
    fields: [
        "name",
        "phone",
        "email",
        "website",
        "reportHeader",
        "reportFooter",
        "registrationNumber",
        "taxNumber",
    ],
    variables: {},
};

const TotalsPerZonesQuery = {
    operation: "shipmentsByZoneReport",
    fields: [
        {
            operation: "sum",
            fields: [
                "shipmentsCount",
                "fees"
            ],
            variables: {
            },
        }
    ],
    variables: {
        input: {
            type: "ListShipmentsFilterInput",
        },
    },
};

const PAGE_COUNT = gqlb.query({
    operation: "shipmentsByZoneReport",
    fields: [
        {
            operation: "records",
            fields: [
                {
                    operation: "paginatorInfo",
                    fields: ["lastPage"],
                    variables: {},
                },
            ],
            variables: {
                first: {
                    type: "Int",
                },
            },
        },
    ],
    variables: {
        input: {
            type: "ListShipmentsFilterInput",
        },
    },
});

const PrintTotalsPerZones = (props) => {
    const urlQuery = urlParameters(window.location.search);

    const stringValues = ["code", "fromCode", "toCode"];
    stringValues.forEach((i) => {
        if (urlQuery[i]) urlQuery[i] = String(urlQuery[i]);
    });
    if (urlQuery["fromDate"] || urlQuery["toDate"]) {
        const date = {
            ...(urlQuery["fromDate"] && { fromDate: urlQuery["fromDate"] }),
            ...(urlQuery["toDate"] && { toDate: urlQuery["toDate"] }),
        }
        delete urlQuery["fromDate"]
        delete urlQuery["toDate"]
        urlQuery["date"] = date
    }
    if (urlQuery["lastTransactionToDate"] || urlQuery["lastTransactionFromDate"]) {
        const lastTransactionDate = {
            ...(urlQuery["lastTransactionFromDate"] && { fromDate: urlQuery["lastTransactionFromDate"] }),
            ...(urlQuery["lastTransactionToDate"] && { toDate: urlQuery["lastTransactionToDate"] }),
        }
        delete urlQuery["lastTransactionToDate"]
        delete urlQuery["lastTransactionFromDate"]
        urlQuery["lastTransactionDate"] = lastTransactionDate
    }
    const { data: pageCount, loading: pageLoad } = useQuery(
        gql`
      ${PAGE_COUNT.query}
    `,
        {
            first: 100,
            variables: {
                input: urlQuery,
                first: 100,
            },
        }
    );
    const pages = pageCount?.shipmentsByZoneReport?.records?.paginatorInfo?.lastPage;

    const queryBuilder = () => {
        const queryNumber = Math.ceil(pages / 100);
        const queryArray = [org, TotalsPerZonesQuery];
        for (let index = 0; index < queryNumber; index++) {
            const initialQuery = {
                operation: `shipmentsByZoneReports${index}:shipmentsByZoneReport`,
                fields: [
                    {
                        operation: "records",
                        fields: [
                            {
                                operation: "data",
                                fields: [
                                    "zoneId",
                                    "zoneName",
                                    "shipmentsCount",
                                    "fees",
                                ],
                                variables: {},
                            },
                        ],
                        variables: {
                            first: {
                                type: "Int",
                            },
                            ["page" + index]: {
                                type: "Int",
                                name: "page",
                            },
                        },
                    },
                ],
                variables: {
                    input: {
                        type: "ListShipmentsFilterInput",
                        required: true,
                    },

                },
            };
            queryArray.push(initialQuery);
        }
        return queryArray;
    };

    const queryBody = queryBuilder();
    const TotalsPerZones_PRINT = gqlb.query(queryBody);

    const { t } = useTranslation();

    let variables = {
        input: urlQuery,
        first: 100,
    };
    for (let index = 0; index < pages; index++) {
        variables["page" + index] = index + 1;
    }

    const { data: queryData, loading } = useQuery(
        gql`
      ${TotalsPerZones_PRINT.query}
    `,
        {
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            skip: !pages,
            variables,
            onError: () => {
                return;
            },
        }
    );

    let parsedData = [];

    if (queryData?.shipmentsByZoneReports0) {
        let concatData = [];
        for (const key in queryData) {
            // console.log(key);
            if (key.startsWith("shipmentsByZoneReports")) {
                const listData = queryData[key].records?.data;
                concatData = concatData.concat(listData);
            }
        }
        parsedData = concatData;
    }

    useEffect(() => {
        if (parsedData.length > 0) {
            window.print();
        }
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryData]);


    const reportDetails = parsedData.length > 0;


    const header = !loading && !pageLoad && reportDetails && (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell padding="none">
                        <Table>
                            <TableBody>
                                <TableRow
                                    className={classesPrintTotalsPerZones.rowWithoutBorder}
                                >
                                    <TableCell>
                                        <Box className={classesPrintTotalsPerZones.logoSection}>
                                            <LogoImg height={30} forceLight={true} />
                                            {/* <img src={logo} alt="logo" height={30} /> */}
                                            <Typography
                                                className={clsx(
                                                    classesPrintTotalsPerZones.reportHeader,
                                                    "landscape-zoom"
                                                )}
                                            >
                                                {queryData.organization.reportHeader}
                                            </Typography>
                                        </Box>
                                    </TableCell>

                                    <TableCell
                                        className={clsx(classesPrintTotalsPerZones.headerDate)}
                                    >
                                        <Typography variant="h6">
                                            {moment(new Date()).format("ll")}
                                        </Typography>
                                        <TypographyKeyValue
                                            title={t("registrationNumber")}
                                            value={queryData.organization?.registrationNumber}
                                            hidden={!queryData.organization?.registrationNumber}
                                        />
                                        <TypographyKeyValue
                                            title={t("taxNumber")}
                                            value={queryData.organization?.taxNumber}
                                            hidden={!queryData.organization?.taxNumber}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell
                                        className={classesPrintTotalsPerZones.headerTableCell}
                                    >
                                        <Table className={classesPrintTotalsPerZones.whiteSpace}>
                                            <TableBody>
                                                <TableRow
                                                    className={
                                                        classesPrintTotalsPerZones.rowWithoutBorder
                                                    }
                                                >
                                                    <TableCell>
                                                        <TypographyKeyValue
                                                            title={t("fromDate")}
                                                            value={
                                                                urlQuery?.fromDate &&
                                                                moment(urlQuery?.fromDate).format("ll")
                                                            }
                                                        />
                                                        <TypographyKeyValue
                                                            title={t("toDate")}
                                                            value={
                                                                urlQuery?.toDate &&
                                                                moment(urlQuery?.toDate).format("ll")
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
    const table = !loading && !pageLoad && reportDetails !== null && (
        <Table id="manifestPrintTable">
            <EnhancedTableHead />

            <TableBody>
                {parsedData.length > 0 &&
                    parsedData?.map((row, index) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                <FixedTableCell component="th" scope="row">
                                    {row.zoneName}
                                </FixedTableCell>
                                <FixedTableCell component="th" scope="row">
                                    {row.shipmentsCount}
                                </FixedTableCell>
                                <FixedTableCell component="th" scope="row">
                                    {formatMoney(row.fees)}
                                </FixedTableCell>
                            </TableRow>
                        );
                    })}
                <TableRow>
                    <FixedTableCell variant="head">{t("total")}</FixedTableCell>
                    <FixedTableCell variant="head">{queryData?.shipmentsByZoneReport?.sum?.shipmentsCount}</FixedTableCell>
                    <FixedTableCell variant="head">{formatMoney(queryData?.shipmentsByZoneReport?.sum?.fees)}</FixedTableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

    return (
        <RootPrintTotalsPerZones>
            {loading || pageLoad ? (
                <>
                    <FullScreenLoading minHeight="20%" />
                    {/* this spans for download font before print */}
                    <span className={clsx(classesPrintTotalsPerZones.barcodeFont)} />
                    <span
                        style={{ opacity: 0 }}
                        className={classesPrintTotalsPerZones.cairoFont}
                    >
                        lا
                    </span>
                </>
            ) : reportDetails === null ? (
                <NotFound />
            ) : (
                <>
                    <div>
                        <div className="page-header">{header}</div>

                        <div className="page-footer">
                            {queryData.organization.reportFooter}
                        </div>

                        <table style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <td>
                                        <div className="page-header-space">{header}</div>
                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        <div
                                            className={clsx(
                                                classesPrintTotalsPerZones.shipmentsTable,
                                                {
                                                    // [classes.tableWidth]: tableZoom,
                                                }
                                            )}
                                        >
                                            {table}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td>
                                        <div className="page-footer-space">
                                            {queryData.organization.reportFooter}
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </>
            )}
        </RootPrintTotalsPerZones>
    );

    function TypographyKeyValue(props) {
        const { title, value, hidden, ...restProps } = props;
        return hidden ? null : (
            <Typography variant="body1" paddingBottom={1} {...restProps}>
                <span className={classesPrintTotalsPerZones.title}>{title}:</span>{" "}
                <span>{value}</span>
            </Typography>
        );
    }
};

function EnhancedTableHead(props) {
    //*********Table Function*********
    const { t } = useTranslation();
    const headCells = [
        { numeric: true, disablePadding: false, label: t("zone") },
        { numeric: true, disablePadding: false, label: t("shipments") },
        {
            numeric: true,
            disablePadding: false,
            label: t("fees"),
        },
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => {
                    return (
                        <FixedTableCell
                            key={index}
                            padding={headCell.disablePadding ? "none" : "normal"}
                        >
                            {headCell.label}
                        </FixedTableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

export default PrintTotalsPerZones;
