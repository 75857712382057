import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Globals } from "../HOC/Classes/Globals";
import { MANIFEST_BY_ID_QUERY_BUILDER } from "./ManifestGraphQl";
import ManifestFormBody from "./ManifestFormBody";
// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.

const ManifestForm = (props) => {
  // get manifest by id 
  const formType = props.match.params.type?.toUpperCase();
  const [manifestType, setManifestType] = useState(formType ? formType : null);
  const [disableView, setDisableView] = useState(false);
  const [manifestData, setManifestData] = useState();
  const [watchId, setWatchId] = useState();
  const clearanceManifest = props.clearanceManifest
  const manifestId = clearanceManifest ? clearanceManifest : props.match.params.id;
  const { data: manifestByIdQuery, loading: manifestByIdLoad, refetch: refetchManifestQuery } = useQuery(
    gql`
      ${MANIFEST_BY_ID_QUERY_BUILDER.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !manifestId,
      variables: {
        id: manifestId ? parseInt(manifestId) : watchId,
      },
      onCompleted: (data) => {
        if (
          data?.manifest === null ||
          !Globals.supportedTransactionType.some(
            (i) => i === data?.manifest?.transactionType?.type?.code
          )
        ) {
        } else {
          const manifest = data?.manifest;
          setManifestType(manifest?.transactionType?.type.code);
          setManifestData(manifest);
        }
      },
      onError: ({ graphQLErrors }) => {
        const auth = graphQLErrors[0].extensions.category
        auth === 'authorization' && setDisableView(true)
      }
    }
  );


  return (
    <ManifestFormBody
      {...props}
      manifestType={manifestType}
      setWatchId={setWatchId}
      manifestId={manifestId}
      manifestData={manifestData}
      refetchManifestQuery={refetchManifestQuery}
      manifestByIdLoad={manifestByIdLoad}
      manifestByIdQuery={manifestByIdQuery}
      disableView={disableView}
      setManifestData={setManifestData}
      clearanceManifest={clearanceManifest}
    />
  )
}
export default ManifestForm;


