import * as gqlb from "gql-query-builder";
export const LIST_PICKUPS = gqlb.query([{
  operation: "listWarehousePickups",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "date",
        "productsCount",
        "deliveryAgentNotes",
        "notes",
        {
          branch: ["id", "name"],
        },
        {
          deliveryAgent: ["id", "name"],
        },
        {
          transactionType: ["id", "name"],
        },
        {
          customer: ["id", "name"],
        },
        {
          status: ["code", "name"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListWarehousePickupsFilterInput",
    },
  },
}
  // , {
  //   operation: "sumPickups",
  //   fields: [
  //     "pickedCount",
  //     "shipmentsCount"
  //   ],
  //   variables: {
  //     input: {
  //       type: "ListWarehousePickupsFilterInput",
  //     },
  //   }
  // }
]);

// this pickup without customer
const pickup = [
  "id",
  "code",
  "timeFrom",
  "timeTo",
  "notes",
  // "shipmentsCount",
  // "pickedCount",
  {
    manifest: [
      "id",
      "date",
      "code"
    ]
  },
  "editable",
  "deletable",
  "createdAt",
  "deliveryAgentNotes",
  "date",
  { status: ["code", "name"] },
  { branch: ["id", "name"] },
  { deliveryAgent: ["id", "name"] },
  { vehicle: ["code", "name"] },
  { transactionType: ["id", "name"] },
  { createdBy: ["id", "username"] },
  { updatedBy: ["id", "username"] },
  {
    operation: "pickupProducts",
    fields: [
      {
        product: [
          "id",
          "code",
          "name",
        ],
      },
      "quantity",
    ],
    variables: {},
  },
];

// this pickup has customer with more details
const pickupView = [
  ...pickup,
  {
    customer: [
      "id",
      "name",
      "address",
      { zone: ["id", "name"] },
      { subzone: ["id", "name"] },
    ],
  },
];

export const PICKUP_VIEW = gqlb.query({
  operation: "warehousePickup",
  fields: pickupView,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

// this for adding customer for pickup array
pickup.push({ customer: ["id", "name"] });
export const PICKUP = gqlb.query({
  operation: "warehousePickup",
  fields: pickup,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_PICKUP_MUTATION = gqlb.mutation({
  operation: "saveWarehousePickup",
  fields: pickup,
  variables: {
    input: {
      type: "WarehousePickupInput",
      required: true,
    },
  },
});

export const DELETE_PICKUP_MUTATION = gqlb.mutation({
  operation: "deleteWarehousePickup",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const UPDATE_PICKUP_STATUS = gqlb.mutation({
  operation: "updateWarehousePickupStatus",
  fields: pickup,
  variables: {
    input: {
      type: "UpdateWarehousePickupStatusInput",
      required: true,
    },
  },
});

export const DELETE_SHIPMENT = gqlb.mutation({
  operation: "deleteShipment",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
