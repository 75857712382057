import * as gqlb from "gql-query-builder";

 export const SHIPMENT_QUERY = gqlb.query({
  operation: "listShipmentsForPayment",
  fields: [
    {
      operation: "data",
      fields:[
        "amount",
        "amountEditable",
        {
          operation: "shipment",
          fields: [
            "id",
            "code",
            "date",
            "notes",
            "recipientName",
            "recipientAddress",
            "recipientMobile",
            "recipientPhone",
            "description",
            "weight",
            "price",
            "piecesCount",
            "totalAmount",
            "senderName",
            "senderAddress",
            "senderPhone",
            "senderMobile",
            "returningDueFees",
            "customerDue",
            "amount",
            "deliveredAmount",
            "collectedFees",
            "allDueFees",
            "returnFees",
            "deliveredOrReturnedDate",
            "refNumber",
            {
              status: ["name"],
            },
            {
              deliveryType: ["name"],
            },
            {
              paymentType: ["name", "code"],
            },
            {
              returnType: ["name"],
            },
            {
              type: ["name"],
            },
            {
              recipientZone: ["id", "name"],
            },
            {
              recipientSubzone: ["name"],
            },
            {
              senderZone: ["id", "name"],
            },
            {
              senderSubzone: ["name"],
            },
        
            {
              customer: [
                "id",
                "name",
                "mobile",
                { zone: ["id", "name"] },
                { subzone: ["name"] },
              ],
            },
            {cancellationReason: ["name"]},
            {
              lastDeliveryAgent: ["id", "name", "commission"],
            },
          ],
          variables: {},
        },
      ],
      variables: {},
    },
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
      // required: true,
    },
    id: {
      type: "Int",
      required: true,
    },
    page: {
      type: "Int",
    },
    filter: {
      type: "ListShipmentsFilterInput",
    },
  },
});
