import {
  Icon,
  Paper,
  Toolbar,
  Typography,
  IconButton,
  Stack,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  DeleteOutline,
  Edit,
  LockOpenOutlined,
  Print,
  ViewColumn,
} from "@mui/icons-material";
import clsx from "clsx";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Can } from "../HOC/CustomComponents/Secured";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import useInvoicesDetails from "./InvoicesDetails";
import {
  DELETE_INVOICES_MUTATION,
  DISAPPROVE_INVOICES_MUTATION,
  INVOICES_VIEW,
  INVOICE_VIEW_SHIPMENT_DATA,
} from "./InvoicesListQuary";
import Grid from "@mui/material/Unstable_Grid2";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import ExportFinanceShipments from "../Payment/ExportFinanceShipments";
import PrintComponent from "../Shipments/PrintComponent";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import SelectColumView from "../shipmentInview/shipmentColumView/SelectFieldColumView";
import config from "../../config.json";
import { defaultQueryFieldsInvoice, shipmentFieldsFun, invoice } from "./listInvoicesFields";

const PREFIX = "InvoicesView";
const classes = {
  paper: `${PREFIX}-paper`,
  viewPaper: `${PREFIX}-viewPaper`,
  title: `${PREFIX}-title`,
  table: `${PREFIX}-table`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },
  [`& .${classes.viewPaper}`]: {
    padding: theme.spacing(3),
  },
  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const EnhancedTableToolbar = (props) => {
  const { selectedShipment, setLocalDataKey, invoicesId: invoiceId, localDataKey, total } = props;
  const shipmentId = selectedShipment.map((i) => i.shipment.id);
  const [shipmentViewColum, setOpenShipmentViewColum] = useState(false);
  const openColumView = () => {
    setOpenShipmentViewColum(true);
  };
  const { t } = useTranslation();
  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {t("totalShipments") + " " + total}
      </Typography>
      <Can showException permission="shipping.shipment.print">
        <PrintComponent shipmentsId={shipmentId} queryName={"invoice"} queryId={invoiceId} lastPage={Math.ceil(total / 100)} />
      </Can>
      <Can>
        <IconButton aria-label="print" onClick={openColumView}>
          <ViewColumn fontSize="inherit" />
        </IconButton>
        <SelectColumView
          openViewColum={shipmentViewColum}
          setOpenViewColum={setOpenShipmentViewColum}
          localStorageName={`shipmentInvoice`}
          initialData={localDataKey}
          setLocalDataKey={setLocalDataKey}
          shipmentFields={shipmentFieldsFun()}
        />
      </Can>
      <ExportFinanceShipments type="invoice" id={invoiceId} />
    </Toolbar>
  );
};

const InvoicesView = (props) => {
  const { t } = useTranslation();
  const invoicesId = props.match.params.id?.trim();
  const user = Globals.user;
  const eInvoicing = Globals.settings?.eInvoicing;
  const [invoicesData, setInvoicesData] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [openDisapprove, setOpenDisapprove] = useState(false);
  // const [shipmentListDetails, setShipmentListDetails] = useState({
  //   shipmentQueryFields: null,
  //   shipmentTableBody: [],
  //   shipmentTableHead: [],
  //   boolSum: false,
  // });

  const [localDataKey, setLocalDataKey] = useState(
    localStorage.getItem(`shipmentInvoice`)
      ? JSON.parse(localStorage.getItem(`shipmentInvoice`))
      : invoice
  );

  const [shipmentData, setShipmentData] = useState({
    page: 0,
    total: 0,
    rowPerPage: config.app.pageSize,
    pickedShipment: [],
  });

  const { details: invoicesDetails } = useInvoicesDetails({
    TotalData: invoicesData,
  });

  const journalEntryPermission = user.hasPermission(
    "accounting.journal_entry.list"
  );

  const invoicesQueryBody = INVOICES_VIEW(
    journalEntryPermission,
  );

  const openDeleteDialog = () => {
    setOpenDelete(true);
  };

  const openDisapproveDialog = () => {
    setOpenDisapprove(true);
  };

  const editURL = () => {
    pushUrl(props, `/admin/invoices/${invoicesId}/edit`);
  };
  /******************************************* Start Pagination Functions ********************************************/

  const handleChangePage = (event, newPage) => {
    setShipmentData((prev) => {
      return {
        ...prev,
        page: newPage,
      };
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setShipmentData((prev) => {
      return {
        ...prev,
        page: 0,
        rowPerPage: event.target.value,
      };
    });
  };
  /******************************************* Start invoices By Id ********************************************/


  const {
    data,
    loading: invoicesByIdLoad,
    refetch,
  } = useQuery(
    gql`
      ${invoicesQueryBody.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !invoicesId || !localDataKey,
      variables: {
        id: parseInt(invoicesId),
      },
      onCompleted: (data) => {
        if (data?.invoice === null) return;
        setInvoicesData(data?.invoice);
        getShipmentData();
        setShipmentData((prev) => {
          return {
            ...prev,
            total: data.invoice.entries.paginatorInfo.total,
          };
        });

      },
    }
  );

  const [
    getShipmentData,
    { loading: loadingShipmentData },
  ] = useLazyQuery(
    gql`
      ${INVOICE_VIEW_SHIPMENT_DATA(localDataKey ? defaultQueryFieldsInvoice(localDataKey) : []).query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",

      variables: {
        id: parseInt(invoicesId),
        page: shipmentData.page + 1,
        first: shipmentData.rowPerPage,
      },
      onCompleted: (data) => {
        if (data?.invoice === null) return;
        const invoice = data?.invoice;
        const shipments = invoice.entries.data.map((e) => {
          return {
            ...e,
            shipment: {
              ...e.shipment,
              pendingDelete: false,
            },
          };
        });
        setShipmentData((prev) => {
          return {
            ...prev,
            pickedShipment: shipments,
            total: invoice.entries.paginatorInfo.total,
            // noData: false,
          };
        });

      },
    }
  );
  /******************************************* PERMISSIONS ********************************************/
  const branchId = data?.invoice?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canDelete =
    user.hasPermission("shipping.invoice.delete") &&
    canAccessBranch &&
    !data?.invoice?.approved &&
    !invoicesByIdLoad;

  const etaDocumentDetails =
    eInvoicing &&
    user.hasPermission("shipping.invoice.submit") &&
    (!data?.invoice?.etaDocumentDetails?.id ||
      ["CANCELLED", "INVALID", "REJECTED"].includes(
        data?.invoice?.etaDocumentDetails?.status.code
      ));

  const canEdit =
    user.hasPermission("shipping.invoice.update") &&
    canAccessBranch &&
    (!data?.invoice?.glApproved || etaDocumentDetails) &&
    !invoicesByIdLoad;

  const canDisapprove =
    !invoicesByIdLoad &&
    user.hasPermission("shipping.invoice.disapprove") &&
    canAccessBranch &&
    data?.invoice?.approved &&
    !data?.etaDocumentDetails?.id &&
    !["VALID", "SUBMITTED"].includes(data?.etaDocumentDetails?.status.code);

  /******************************************* End invoices List ********************************************/

  let tableBody = null;
  let viewBody = null;
  let viewEInvoice = null;
  tableBody = (
    <Paper className={clsx(classes.table, classes.paper)}>
      <EnhancedTableToolbar
        {...props}
        selectedShipment={shipmentData?.pickedShipment}
        setLocalDataKey={setLocalDataKey}
        invoicesId={invoicesId}
        localDataKey={localDataKey}
        total={shipmentData.total}
      />
      {!loadingShipmentData && (
        <TableFixedHeaderWraper>
          <ShipmentsTable
            data={shipmentData?.pickedShipment}
            headCells={invoicesDetails.tableHead}
            parseBodyCell={invoicesDetails.tableBody}
            sumRow={shipmentData.total > 0 ? invoicesDetails.tableTotal : null}
          // total={invoicesDetails.tableTotal}
          // // sumTableNumber={invoicesDetails?.sumTableNumber}
          // sum={true}
          // ranking={true}
          />
        </TableFixedHeaderWraper>
      )}

      <Grid container justifyContent="center" alignItems="center">
        {loadingShipmentData ? (
          <FullScreenLoading height={"327px"} />
        ) : (
          shipmentData.total === 0 && (
            <EmptyTableMessage message={t("noResult")} />
          )
        )}
      </Grid>
      {shipmentData?.pickedShipment.length !== 0 && (
        <MUITablePagination
          count={shipmentData?.total}
          rowsPerPage={shipmentData.rowPerPage}
          page={shipmentData.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );

  const icons = [
    {
      id: "print",
      title: "print",
      action: () =>
        window.open(
          `${window.location.origin}/report/print/invoice/${invoicesId}`
        ),
      icon: Print,
      disabled: invoicesByIdLoad,
    },
    {
      id: "edit",
      title: "edit",
      action: editURL,
      icon: Edit,
      permission: canEdit,
    },
    {
      id: "delete",
      title: "delete",
      action: openDeleteDialog,
      icon: DeleteOutline,
      permission: Boolean(canDelete),
    },
    {
      id: "disapprove",
      title: "disapprove",
      action: openDisapproveDialog,
      icon: LockOpenOutlined,
      permission: Boolean(canDisapprove),
    },
  ];

  viewBody = (
    <Paper
      container
      className={classes.viewPaper}
      sx={{ mt: 1, width: "100%" }}
      component={Grid}
    >
      <KeyValuePair title={t("recordCode")} value={data?.invoice?.code} />
      <KeyValuePair title={t("theDate")} value={data?.invoice?.date} />
      <KeyValuePair
        title={t("branch")}
        value={
          data?.invoice?.branch?.name && (
            <SpanLink pathname={`/admin/branches/${data?.invoice?.branch?.id}`}>
              {data?.invoice?.branch?.name}
            </SpanLink>
          )
        }
      />

      <KeyValuePair
        title={t("transactionType")}
        value={
          <SpanLink
            pathname={`/admin/transaction-types/${data?.invoice?.transactionType?.id}`}
          >
            {data?.invoice?.transactionType?.name}
          </SpanLink>
        }
      />
      {data?.invoice?.customer ? (
        <KeyValuePair
          title={t("customer")}
          value={
            data?.invoice?.customer?.name && (
              <SpanLink
                pathname={`/admin/customers/${data?.invoice?.customer?.id}`}
              >
                {data?.invoice?.customer?.name}
              </SpanLink>
            )
          }
        />
      ) : null}

      <KeyValuePair
        title={t("journalEntry")}
        value={
          data?.invoice?.journalEntry?.code && (
            <SpanLink
              pathname={`/admin/finance/journal-entry/${data?.invoice?.journalEntry?.id}`}
            >
              {data?.invoice?.journalEntry?.code}
            </SpanLink>
          )
        }
      />

      <KeyValuePair title={t("notes")} value={data?.invoice?.notes} />
      <KeyValuePair
        title={t("approved")}
        value={
          data?.invoice?.approved ? (
            <Icon className={classes.iconColor}>check_circle_outline</Icon>
          ) : (
            <Icon color="error">highlight_off</Icon>
          )
        }
      />
      <KeyValuePair
        title={t("glApproved")}
        value={
          data?.invoice?.glApproved ? (
            <Icon className={classes.iconColor}>check_circle_outline</Icon>
          ) : (
            <Icon color="error">highlight_off</Icon>
          )
        }
      />
    </Paper>
  );

  viewEInvoice = (
    <Paper
      container
      className={classes.viewPaper}
      sx={{ mt: 1, width: "100%" }}
      component={Grid}
    >
      {data?.invoice?.etaDocumentDetails?.publicUrl ? (
        <KeyValuePair
          title={t("eInvoicing")}
          value={
            data?.invoice?.etaDocumentDetails?.code && (
              <Link
                href={data?.invoice?.etaDocumentDetails?.publicUrl}
                target="_blank"
              >
                {data?.invoice?.etaDocumentDetails?.code}
              </Link>
            )
          }
        />
      ) : (
        <KeyValuePair
          title={t("eInvoicing")}
          value={data?.invoice?.etaDocumentDetails?.code}
        />
      )}
      <KeyValuePair
        title={t("theDate")}
        value={data?.invoice?.etaDocumentDetails?.dateTimeIssued}
      />
      <KeyValuePair
        title={t("status")}
        value={data?.invoice?.etaDocumentDetails?.status.name}
      />
    </Paper>
  );

  return invoicesByIdLoad || !localDataKey ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classes.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.invoice ? (
    <NotFound />
  ) : (
    <Root>
      <MutationWithDialog
        mutaion={DELETE_INVOICES_MUTATION.query}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        dialogTitle={t("deleteRecord")}
        dialogContent={t("deleteRecordMessage")}
        mutaionProps={{ variables: { id: parseInt(invoicesId) } }}
        onCompleted={() => pushUrl(props, `/admin/invoices`)}
        onCompleteMessage={t("successfullyDeletedRecord")}
      />
      <MutationWithDialog
        mutaion={DISAPPROVE_INVOICES_MUTATION.query}
        setOpenDelete={setOpenDisapprove}
        openDelete={openDisapprove}
        dialogTitle={t("disapprove")}
        dialogContent={t("disapprovedMessage")}
        mutaionProps={{
          variables: { id: parseInt(invoicesId) },
        }}
        onCompleted={() => refetch()}
        onCompleteMessage={t("successfullyDisapproved")}
      />
      <TitleAppBar path={props.match.path}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      <Stack spacing={2} margin={2}>
        {props.children}
        {viewBody}
        {data?.invoice?.etaDocumentDetails?.id && eInvoicing && viewEInvoice}
        {!invoicesByIdLoad && tableBody}
      </Stack>
    </Root>
  );
};

export default InvoicesView;
