import * as gqlb from "gql-query-builder";

export const LIST_CURRENCIES = gqlb.query({
  operation: "listCurrencies",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "lastPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "name",
        "active",
        "local",
        {
          gender: ["id", "name", "code"],
        },
        {
          fractionGender: ["id", "name", "code"],
        },
        "pluralName",
        "coupleName",
        "fractionName",
        "fractionParts",
        "fractionCoupleName",
        "fractionPluralName",
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListCurrenciesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const CURRENCY_ID = gqlb.query({
  operation: "currency",
  fields: [
    "id",
    "code",
    "name",
    "active",
    "local",
    {
      gender: ["id", "name", "code"],
    },
    {
      fractionGender: ["id", "name", "code"],
    },
    "pluralName",
    "coupleName",
    "fractionParts",
    "fractionName",
    "fractionCoupleName",
    "fractionPluralName",
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_CURRENCY = gqlb.mutation({
  operation: "saveCurrency",
  fields: ["id"],
  variables: {
    input: {
      type: "CurrencyInput",
      required: true,
    },
  },
});