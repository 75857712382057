import { Icon, IconButton, Toolbar } from "@mui/material";
import { useState } from "react";
import { classesCollectionForm, classesCollectionView } from "../CollectionStyle";
import { Can } from "../../HOC/CustomComponents/Secured";
import PrintComponent from "../../Shipments/PrintComponent";
import ExportFinanceShipments from "../../Payment/ExportFinanceShipments";
import { AddCircleOutline, ViewColumn } from "@mui/icons-material";
import SelectColumView from "../../shipmentInview/shipmentColumView/SelectFieldColumView";
import clsx from "clsx";
import { shipmentFieldsFun } from "../listCollectionFields";

export const EnhancedTableToolbarView = (props) => {
    const { ids, collectionId, type, setLocalDataKey, typeCode, localKeys, permission, total } = props;
    const [shipmentViewColum, setOpenShipmentViewColum] = useState(false);
    const openColumView = () => {
        setOpenShipmentViewColum(true)
    };

    const data = {
        SHIPMENT:
            <Toolbar className={classesCollectionView.root}>
                <Can showException permission="shipping.shipment.print">
                    <PrintComponent shipmentsId={ids} queryName={"collection"} queryId={collectionId} lastPage={Math.ceil(total / 100)} />
                </Can>
                <ExportFinanceShipments type="collection" id={collectionId} />
                <Can >
                    <IconButton
                        aria-label="print"
                        onClick={openColumView}>
                        <ViewColumn fontSize="inherit" />
                    </IconButton>
                    <SelectColumView
                        openViewColum={shipmentViewColum}
                        setOpenViewColum={setOpenShipmentViewColum}
                        localStorageName={`shipmentcollection${typeCode}`}
                        initialData={localKeys}
                        setLocalDataKey={setLocalDataKey}
                        shipmentFields={shipmentFieldsFun(typeCode, permission)}
                    />
                </Can>
            </Toolbar>
    }
    return data[type]
};

export const EnhancedTableToolbarForm = (props) => {
    const { addShipment, approved, barcode } = props;
    return (
        <Toolbar className={clsx(classesCollectionForm.root)}>
            <IconButton
                aria-label="Add"
                className={classesCollectionForm.addIcon}
                onClick={addShipment}
                disabled={approved}
                size="large"
            >
                <AddCircleOutline />
            </IconButton>
            {barcode ? (
                <IconButton
                    aria-label="Add"
                    onClick={barcode}
                    disabled={approved}
                    size="large"
                >
                    <Icon>qr_code_scanner</Icon>
                </IconButton>
            ) : null}
        </Toolbar>
    );
};

EnhancedTableToolbarForm.propTypes = {
    // selectedShipment: PropTypes.array.isRequired,
};