import React from 'react'
import PropTypes from "prop-types";
import { FixedTableCell } from '../HOC/CustomComponents/FixedTableCell';
import { Typography } from '@mui/material';
import formatMoney from '../../helpers/numbersDot';

const GenerateShipmentSumCell = (props) => {
    const { shipmentSum, elementKey } = props
          
    const getShipmentsSumValue = (shipmentSum, elementKey) => {
        if (elementKey) {
            if (elementKey === "netCollection") {
                const netCollection = shipmentSum["pendingCollectionAmount"] - shipmentSum["deliveryCommission"]
                return formatMoney(netCollection?.toFixed(2)) ?? "--"
            }
            return formatMoney(shipmentSum[elementKey]?.toFixed(2)) ?? "--"
        } else {
            return ''
        }
    }

    return <FixedTableCell >
        <Typography color="inherit" variant="h6" >
            {getShipmentsSumValue(shipmentSum, elementKey)}
        </Typography>
    </FixedTableCell>

}

GenerateShipmentSumCell.prototype = {
    shipmentSum: PropTypes.object.isRequired,
}

export default GenerateShipmentSumCell