import { useQuery, gql } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import { Edit } from "@mui/icons-material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { FINANCIAL_SHEET } from "./Graphql";
// import FinancialSheetTables from "./PriceListTables";
import Grid from "@mui/material/Unstable_Grid2";

import { Globals } from "../HOC/Classes/Globals";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import FinancialSheetTables from "./FinancialSheetTables";
import SpanLink from "../HOC/CustomComponents/SpanLink";

const PREFIX = "FinancialSheetView";

const classes = {
  box: `${PREFIX}-box`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const FinancialSheetView = (props) => {
  const { t } = useTranslation();
  const financialSheetId = parseInt(props.match.params.id);

  const { data, loading } = useQuery(
    gql`
      ${FINANCIAL_SHEET.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: { id: financialSheetId },
      onCompleted: (data) => {},
    }
  );
  const user = Globals.user;
  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () =>
        pushUrl(props, `/admin/financial-sheets/${financialSheetId}/edit`),
      icon: Edit,
      permission:
        user?.hasPermission("accounting.financial_sheet.update") &&
        data?.financialSheet?.editable,
    },
  ];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classes.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.financialSheet ? (
    <NotFound />
  ) : (
    <Root>
      <Grid
        container
        justifyContent="center"
        sx={{ margin: "0", width: "100%" }}
      >
        <TitleAppBar path={props.match.path}>
          <LongMenu icons={icons} />
        </TitleAppBar>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ p: 2, width: "100%" }}
        >
          {loading ? (
            <FullScreenLoading minHeight="15%" />
          ) : (
            <>
              <Paper container className={classes.box} component={Grid}>
                <KeyValuePair
                  md={6}
                  title={t("code")}
                  value={data?.financialSheet?.code}
                />
                <KeyValuePair
                  md={6}
                  title={t("manifestName")}
                  value={data?.financialSheet.name}
                />
                <KeyValuePair
                  md={6}
                  title={t("branch")}
                  value={
                    data?.financialSheet?.branch?.name && (
                      <SpanLink
                        pathname={`/admin/branches/${data?.financialSheet?.branch?.id}`}
                      >
                        {data?.financialSheet?.branch?.name}
                      </SpanLink>
                    )
                  }
                />
                <KeyValuePair
                  md={6}
                  title={t("notes")}
                  value={data?.financialSheet.notes}
                />
              </Paper>
            </>
          )}
        </Grid>
        {data?.financialSheet?.lines && (
          <FinancialSheetTables LineList={data.financialSheet?.lines} />
        )}
      </Grid>
    </Root>
  );
};

export default FinancialSheetView;
