import { fileNames } from "./fileLoader";

export const waybills = (t) =>
    [
        {
            code: "SHEET_SAME_1",
            printLink: "SHEET_SAME_1",
            name: t("oneCopy")
        },
        {

            code: "SHEET_SAME_2",
            printLink: "SHEET_SAME_2",
            name: t("twoCopies")
        },
        {

            code: "SHEET_SAME_3",
            printLink: "SHEET_SAME_3",
            name: t("threeCopies")
        },
        {

            code: "SHEET_DIFF_2",
            printLink: "SHEET_DIFF_2",
            name: t("twoDifferentShipments")
        },
        {

            code: "SHEET_DIFF_3",
            printLink: "SHEET_DIFF_3",
            name: t("threeDifferentShipments")
        },
        ...fileNames(t)
    ]
