import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Icon,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

import { gql, useMutation } from "@apollo/client";
import { FilterList, Print, TaskAlt } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { TableCellColor } from "../HOC/CustomComponents/TableCellColor";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import SearchTable, { SEARCH } from "../ShipmentSearch/SearchTable";
import { UNPACKED_SHIPMENT } from "./Graphql";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { Can } from "../HOC/CustomComponents/Secured";
import ExportShipments from "../Shipments/ExportShipments";
import { dateFormatLL } from "../../helpers/dateFunctions";
import { useForm } from "react-hook-form";
import PrintComponent from "../Shipments/PrintComponent";
import { printLink } from "../../helpers/defaultPrintList";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';

const PREFIX = "UnpackedShipment";

const classes = {
  button: `${PREFIX}-button`,
  btnMargin: `${PREFIX}-btnMargin`,
  mainSearch: `${PREFIX}-mainSearch`,
  titles: `${PREFIX}-titles`,
  toolbar: `${PREFIX}-toolbar`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  overlay: `${PREFIX}-overlay`,
  searchPadding: `${PREFIX}-searchPadding`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  tableRow: `${PREFIX}-tableRow`,
  shipmentTable_: `${PREFIX}-shipmentTable_`,
  bodyCheckbox: `bodyCheckbox`,
  shipmentTable_bodyCode: `shipmentTable_bodyCode`,
  shipmentTable_button: `${PREFIX}-shipmentTable_button`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`& .${classes.mainSearch}`]: {
    margin: theme.spacing(0),
    width: "100%",
  },

  [`& .${classes.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: theme.palette.background.appTitle,
    width: "100%",
    justifyContent: "space-between",
  },

  [`& .${classes.searchForm}`]: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    margin: 0,
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.overlay}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "#ffffffa8",
    zIndex: 2,
  },

  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },

  //////////////////////Drawer///////////////////

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,

    zIndex: "250",

    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: theme.palette.background.hover,
      },
    },
  },

  [`& .${classes.shipmentTable_}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "101",
      right: "0",
    },
  },

  [`& .${classes.bodyCheckbox}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: 0,
      left: 0,
      minWidth: 60,
    },
  },

  [`& .${classes.shipmentTable_bodyCode}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: 0,
      left: "60px",
    },
  },

  [`& .${classes.shipmentTable_button}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },
}));

const drawerWidth = 240;

//*********Table Function*********

const UnpackedShipments = (props) => {
  const [pageFilter, setPageFilter] = useState({})
  const defaultPageInput = {
    forUnpacking: true
  }

  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [checkedIds, setCheckedIds] = useState([]);
  const checkedIdsIsEmpty = checkedIds.length === 0;


  const { handleSubmit } = useForm();

  const [dialogState, setDialogState] = useState(false);
  const [shipmentId, setShipmentId] = useState([0]);
  // const RECEIVE_SHIPMENT_IN_WAREHOUSE =
  //   receiveInWarehouseMutationBuilder(shipmentId);
  const [unpackedShipment, { loading, client }] = useMutation(
    gql`
      ${UNPACKED_SHIPMENT.query}
    `
  );
  const handelShipmentInWarehouse = (id) => {
    setCheckedIds(id);
    setShipmentId(id);
    setDialogState(true);
  };
  const handleDialogClose = () => {
    setDialogState(false);
  };
  const queryFields = [
    "id",
    "code",
    "date",
    "senderName",
    "recipientName",
    "deliveryDate",
    "attempts",
    {
      lastDeliveryAgent: ["id", "name"],
    },
    {
      status: ["name", "code"],
    },
    {
      type: ["name"],
    },
    {
      recipientZone: ["id", "name"],
    },
    {
      recipientSubzone: ["name"],
    },
    {
      deliveryType: ["name"],
    },
    {
      branch: ["id"],
    },
  ];
  const unpackedShipmentSubmit = (data) => {
    unpackedShipment({
      variables: {
        input: {
          id: shipmentId,
        }
      },
    })
      .then((data) => {
        setShipmentId([0]);
        setCheckedIds([]);
        handleDialogClose();
        client.refetchQueries({
          include: [
            gql`
              ${SEARCH(queryFields).query}
            `,
          ],
        });
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch((error) => {
        console.log(error);
        setShipmentId([0]);
        setCheckedIds([]);

        handleDialogClose();
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";
  const onSubmitFunc = () => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
  };

  const clearCheckBox = () => setCheckedIds([]);

  const tableHeadCell = [
    "code",
    "shipmentStatus",
    "packageType",
    "date",
    "redeliveryDate",
    "attempts",
    "deliveryAgents",
    "senderName",
    "recipient",
    "destination",
    "deliveryType",
    "update",
  ];

  const tableBody = (shipment, index, isChecked, toggleCheck) => {
    const user = Globals.user;
    const branchId = shipment.branch.id;
    const canReceiveShipment = user.canAccessBranch(branchId);
    return (
      <TableRow className={classes.tableRow} key={index}>
        <FixedTableCell className={classes.bodyCheckbox}>
          <Checkbox
            edge="start"
            checked={isChecked(shipment.id)}
            onChange={(e) => toggleCheck(e, shipment.id)}
            disableRipple
          />
        </FixedTableCell>
        <CellLink
          className={classes.shipmentTable_bodyCode}
          pathname={`/admin/shipments/${shipment.id}`}
        >
          {shipment.code}
        </CellLink>
        <TableCellColor
          shipment={shipment}
        />
        <FixedTableCell>{shipment.type.name}</FixedTableCell>

        <FixedTableCell>{dateFormatLL(shipment.date)}</FixedTableCell>
        <FixedTableCell>
          {shipment.deliveryDate && dateFormatLL(shipment.deliveryDate)}
        </FixedTableCell>
        <FixedTableCell>{shipment.attempts}</FixedTableCell>
        <CellLink
          pathname={`/admin/delivery-agents/${shipment.lastDeliveryAgent?.id}`}
        >
          {shipment.lastDeliveryAgent?.name}
        </CellLink>
        <FixedTableCell>{shipment.senderName}</FixedTableCell>
        <FixedTableCell>{shipment.recipientName}</FixedTableCell>
        {/* <CellLink pathname={`/admin/zones/${shipment.recipientZone.id}`}>
        {shipment.recipientZone?.name + " - " + shipment.recipientSubzone.name}
      </CellLink> */}
        <ShipmentRegion
          pathname={`/admin/zones/${shipment.recipientZone.id}`}
          zone={shipment.recipientZone?.name}
          subzone={shipment.recipientSubzone.name}
        />
        <FixedTableCell>{shipment.deliveryType?.name}</FixedTableCell>

        <FixedTableCell
          className={classes.shipmentTable_button}
          component="th"
          scope="row"
        >
          <Button
            color="primary"
            variant="outlined"
            size="small"
            disabled={!canReceiveShipment}
            onClick={() => handelShipmentInWarehouse([shipment.id])}
            startIcon={<Icon>task_alt</Icon>}
          >
            {t("unpacking")}
          </Button>
        </FixedTableCell>
      </TableRow>
    );
  };
  const warehousingSystem = Globals.user.warehousing

  const defaultPrint = Globals.settings.waybillCode;
  const [openPrint, setOpenPrint] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const openPrintDialog = () => {
    defaultPrint ?
      printLink(defaultPrint, false, checkedIds) :
      setOpenPrint(true)
  };
  const openExportDialog = () => {
    setOpenExport(true)
  };
  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "receive",
      title: "receive",
      action: () => handelShipmentInWarehouse(checkedIds),
      icon: TaskAlt,
      disabled: checkedIdsIsEmpty
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: checkedIds.length > 0
    },
    {
      id: "print",
      title: "print",
      action: openPrintDialog,
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: checkedIds.length === 0
    },
  ]

  return warehousingSystem ? (
    <Root>
      <Dialog
        open={dialogState}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <form onSubmit={handleSubmit(unpackedShipmentSubmit)}>
          <DialogTitle color="inherit" id="alert-dialog-title">
            {t("unpackedShipmentsMsg")}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              {t("cancel")}
            </Button>
            <Button color="primary" autoFocus disabled={loading} type="submit">
              {loading ? <ButtonLoading /> : t("confirm")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* *******Table******* */}
      <PrintComponent shipmentsId={checkedIds} openPrint={openPrint} setOpenPrint={setOpenPrint} />
      <ExportShipments
        filters={{
          ...defaultPageInput,
          ...pageFilter,
        }}
        openExport={openExport}
        setOpenExport={setOpenExport}
      />


      <SearchTable
        tableHeadCell={tableHeadCell}
        tableBody={tableBody}
        className={classes}
        queryFields={queryFields}
        setCheckedIds={(ids) => setCheckedIds(ids)}
        checkedIds={checkedIds}
        withCheckAll
        icons={icons}
        path={props.match.path}
        drawerState={drawerState[filterAnchor]}
        notSumShipments={true}
        clearCheckBox={clearCheckBox}
        setPageFilter={setPageFilter}
        defaultPageInput={defaultPageInput}
        onSubmitFunc={onSubmitFunc}
        filtersAllowed={[
          "date",
          "status",
          "branch",
          "originBranch",
          "customerType",
          "custm",
          "dlvAgent",
          "zoneSender",
          "subZoneSender",
          "zone",
          "subZone",
          "refNumber",
          "service",
          "deliveryType",
          "returnType",
          "packageType",
          "paymentType",
        ]}
        pathname={"unpacked-shipments"}
      />

    </Root>
  ) :
    (
      <Can permission="uneEditable" />
    )

};
export default UnpackedShipments;
