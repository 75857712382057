import * as gqlb from "gql-query-builder";

export const LIST_CONCIERGE_PROVIDERS = gqlb.query({
  operation: "listConciergeProviders",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "lastPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "name",
        "active",
        {
          images: ["path", "subjectCode"]
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListConciergeProvidersFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});


export const CONCIERGE_PROVIDER_ID = gqlb.query({
  operation: "conciergeProvider",
  fields: [
    "id",
    "code",
    "name",
    "active",
    {
      images: ["path", "subjectCode"]
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_CONCIERGE_PROVIDER = gqlb.mutation({
  operation: "saveConciergeProvider",
  fields: ["id"],
  variables: {
    input: {
      type: "ConciergeProviderInput",
      required: true,
    },
  },
});