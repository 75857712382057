import * as gqlb from "gql-query-builder";

export const SAVE_PRICE_LIST = gqlb.mutation({
  operation: "savePriceList",
  fields: ["id", "code", "name"],
  variables: {
    input: {
      type: "PriceListInput",
      required: true,
    },
  },
});

export const SAVE_PRICE_LIST_PICKUP = gqlb.mutation({
  operation: "savePriceListPickup",
  fields: ["id"],
  variables: {
    input: {
      type: "PriceListPickupInput",
      required: true,
    },
  },
});
export const CLONE_PRICE_LIST_PICKUP = gqlb.mutation({
  operation: "clonePriceList",
  fields: ["id"],
  variables: {
    input: {
      type: "PriceListCloneInput",
      required: true,
    },
  },
});

export const PRICE_LIST = gqlb.query({
  operation: "priceList",
  fields: [
    "id",
    "name",
    "active",
    "code",
    "description",
    "default",
    {
      customerType: ["id", "code"],
    },
    { branches: ["id", "name", "code"] },
    {
      operation: "pickups",
      fields: [
        "id",
        { services: ["id", "name"] },
        { zone: ["id", "name"] },
        { subzone: ["id", "name"] },
        {
          destinations: [
            { zone: ["id", "name"] },
            { subzone: ["id", "name"] },
            "returnFees",
            "weightUpTo",
            "weightStartFees",
            "weightExtraUnit",
            "weightExtraUnitFees",
            "collectionUpTo",
            "collectionStartFees",
            "collectionExtraUnit",
            "collectionExtraUnitFees",
          ],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const LIST_PRICE_LIST_PAGED = gqlb.query({
  operation: "listPriceLists",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: ["id", "name", "code", "active", "description", "default"],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListPriceListsFilterInput",
    },
  },
});

export const DELETE_PRICE_LIST = gqlb.mutation({
  operation: "deletePriceList",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DELETE_PRICE_LIST_PICKUP = gqlb.mutation({
  operation: "deletePriceListPickup",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
