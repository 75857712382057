import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from "react-i18next";
import {
  Collapse,
  Typography,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { gql, useMutation } from '@apollo/client';
import { SAVE_TEAM } from './Graphql';
import { useForm } from 'react-hook-form';
import { styled } from "@mui/material/styles";
import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import FormButton from '../CustomComponents/Buttons/FormButton';
import ButtonLoading from '../HOC/FunctionComponents/LoadingPages/ButtonLoading';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { setValidationError } from '../HOC/CustomFunctions/setValidationError';
import { MultipleAutocomplete } from '../HOC/MUI/MultipleAutocomplete';
import { LIST_SUPPORT_CATEGORIES_DROPDOWN, LIST_USERS_DROPDOWN } from '../../GlobalsQuery/ListDropdown/ListDropdown';


const PREFIX = "BranchList";

const classes = {
  mainGrid: `${PREFIX}-mainGrid`,
  button: `${PREFIX}-button`,
  multipleAutocomplete: `${PREFIX}-multipleAutocomplete`,
};

const Root = styled("div")(({ theme }) => ({

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  [`& .${classes.button}`]: {
    width: "100%",
    paddingRight: 0,
  },
  [`& .${classes.multipleAutocomplete}`]: {
    [`& .mui-1pbla0w-MuiFormControl-root-MuiTextField-root`]: {
      margin: 0,
    }
  }

}));

export default function TeamsDialog(props) {
  const {
    handleSubmit,
    setValue,
    control,
    setError,
    errors,
  } = useForm();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { supportTeamsObject } = props;
  const [autocompleteValues, setAutocompleteValues] = React.useState({
    members: [],
    categories: [],
  });

  const parseData = (data) => {
    return data;
  };

  useEffect(() => {
    if (supportTeamsObject) {
      const categoriesParams = [
        "id",
        "name",
        "code",
        "active",
      ];
      categoriesParams.forEach((i) => {
        supportTeamsObject[i] && setValue(i, supportTeamsObject[i]);
      });
      const membersIds = []
      const categoriesIds = []
      supportTeamsObject.members.map(ele => membersIds.push(ele.id))
      supportTeamsObject.categories.map(ele => categoriesIds.push(ele.id))
      setAutocompleteValues({
        members: membersIds,
        categories: categoriesIds,
      });
    }
  }, [supportTeamsObject, setValue])

  const [saveCategory, { loading: saveCategoryLoading }] = useMutation(
    gql`
      ${SAVE_TEAM.query}
    `
  );
  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    saveCategory({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        props.handleClose()
        props.refetch()
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      maxWidth={'xs'}
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container justifyContent="space-between" xs={12}>
          <Typography variant="h6" color={"text.primary"}>
            {supportTeamsObject ? t("technicalSupportTeamUpdate") : t("technicalSupportTeamCreate")}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Root>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              justifyContent="flex-start"
              aligns="center"
              className={clsx(classes.mainGrid)}
              spacing={2}
            >
              <Grid xs={12} sm={12} aligns="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid xs={12} sm={12} aligns="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
              <Grid xs={12} sm={12} aligns="flex-start" className={classes.multipleAutocomplete}>
                <MultipleAutocomplete
                  control={control}
                  errors={errors}
                  rules={{ required: t("fieldIsRequired") }}
                  name={"userIds"}
                  label={t("members")}
                  variables={{
                    input: {
                      accountId: null
                    }
                  }}
                  limitTags={1000}
                  parseData={(data) => parseData(data)}
                  query={LIST_USERS_DROPDOWN.query}
                  defaultValue={autocompleteValues.members}
                  multiple
                  hideCode={true}
                  valueKey="id"
                />
              </Grid>
              <Grid xs={12} sm={12} aligns="flex-start" className={classes.multipleAutocomplete}>
                <MultipleAutocomplete
                  control={control}
                  errors={errors}
                  rules={{ required: t("fieldIsRequired") }}
                  name={"categoryIds"}
                  label={t("categories")}
                  variables={{
                    input: {
                      active: true,
                    },
                  }}
                  hideCode={true}
                  limitTags={1000}
                  parseData={(data) => parseData(data)}
                  query={LIST_SUPPORT_CATEGORIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.categories}
                  multiple
                  valueKey="id"
                />
              </Grid>
              <Grid container justifyContent="flex-end" className={classes.button}>
                <FormButton disabled={saveCategoryLoading}>
                  {saveCategoryLoading ? <ButtonLoading /> : t("save")}
                </FormButton>
              </Grid>

            </Grid>
          </form>
        </Root>
      </DialogContent>
    </Dialog >
  );
}