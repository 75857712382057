import React from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import {
  Paper,
  Collapse,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { UPDATE_ORGANIZATION, ORGANIZATION } from "./Graphql";
import { useMutation, gql, useQuery } from "@apollo/client";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import Grid from "@mui/material/Unstable_Grid2";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import TitleAppBar from "../../Layout/TitleAppBar";
import getMobileData, { getFormatNumber, validNumber } from "../../helpers/asYouType";
import MuiPhone from "../HOC/MUI/MUIphoneNumber";
import { Globals } from "../HOC/Classes/Globals";
const PREFIX = 'UpdateOrganizationData';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const UpdateOrganizationData = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    formState,
    setValue,
    setError,
    getValues
  } = useForm();
  const { errors } = formState;
  const countryCode = Globals.settings.countryCode
  const { loading: dataLoading } = useQuery(
    gql`
      ${ORGANIZATION.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      variables: {},
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const organizationData = data.organization;
        const OrganizationParams = [
          "name",
          "owner",
          "activity",
          "registrationNumber",
          "taxArea",
          "taxNumber",
          "email",
          "website",
          "reportHeader",
          "taxActivityCode",
          "taxClientId",
          "taxClientSecret",
        ];

        const numbersObj = [
          {
            name: "phone",
            codeName: "phoneCode",
            value: getMobileData(organizationData.phone, countryCode)
          },
        ]
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        OrganizationParams.forEach((i) => {
          organizationData[i] && setValue(i, organizationData[i]);
        });
      },
    }
  );


  const [updateOrganization, { loading }] = useMutation(
    gql`
      ${UPDATE_ORGANIZATION.query}
    `
  );

  const onSubmit = (data) => {
    if (Globals.settings.allowPhoneKey) {
      const checkPhone = validNumber(data.phone, data.phoneCode, "notRequired")
      let names = [
        {
          name: "phone",
          validate: checkPhone.valid,
          message: checkPhone.message,
        },
      ];

      if (!checkPhone.valid) {
        names.map((e) => !e.validate && setError(e.name, { type: 'custom', message: t(e.message) }))
        return
      }

      data.phone = data.phone && getFormatNumber(data.phone, data.phoneCode)
    }
    for (const key in data) {
      if (key === "phoneCode") {
        delete data[key];
      }
    }
    updateOrganization({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/organization`);

        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TitleAppBar path={props.match.path} />

      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"name"}
              label={t("organizationName")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <MuiPhone
              setValue={setValue}
              codeValue={getValues().phoneCode}
              control={control}
              errors={errors}
              name={"phone"}
              selectName={"phoneCode"}
              label={t("mobile")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"owner"}
              label={t("ownerName")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"email"}
              label={t("email")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"activity"}
              label={t("activity")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"website"}
              label={t("website")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"registrationNumber"}
              label={t("registrationNumber")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"taxArea"}
              label={t("taxArea")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"taxNumber"}
              label={t("taxNumber")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"taxActivityCode"}
              label={t("taxActivityCode")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"taxClientId"}
              label={t("taxClientId")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"taxClientSecret"}
              label={t("taxClientSecret")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              rows={5}
              name={"reportHeader"}
              label={t("reportHeader")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              rows={5}
              name={"reportFooter"}
              label={t("reportFooter")}
            />
          </Grid>
        </Paper>

        <Grid container justifyContent="flex-end" className={classes.spacing}>
          <FormButton disabled={loading}>
            {loading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </form>
  );

  return dataLoading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading > :
    <Root>
      {body}
    </Root >

};

export default UpdateOrganizationData;
