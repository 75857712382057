import {
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, Fragment } from "react";

import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import * as gqlb from "gql-query-builder";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import "../ManifestPrint/print.css";
import { RootPrintCollectionList, classesPrintCollectionList } from "../Collection/CollectionStyle";
import { styled } from "@mui/material/styles";
import { zoom } from "../ManifestPrint/ManifestPrintFunc";
import { defaultQueryFields, getShipmentsSumQuery, getShipmentsTableHeader, initialData } from "../ShipmentSearch/ListShipmentTableCells";
import GenerateShipmentSumCell from "../ShipmentSearch/GenerateShipmentSumCell";
import GenerateShipmentCell from "../ShipmentSearch/GenerateShipmentCell";

const PREFIX = "ManifestPrint";

const classes = {
  headerCells: `${PREFIX}-headerCells`,
  title: `${PREFIX}-title`,
  notes: `${PREFIX}-notes`,
  noteCell: `${PREFIX}-noteCell`,
  barcodeFont: `${PREFIX}-barcodeFont`,
  cairoFont: `${PREFIX}-cairoFont`,
  barcode: `${PREFIX}-barcode`,
  headerTableCell: `${PREFIX}-headerTableCell`,
  mainCellBorder: `${PREFIX}-mainCellBorder`,
  signature: `${PREFIX}-signature`,
  reportHeader: `${PREFIX}-reportHeader`,
  hiddenFooter: `${PREFIX}-hiddenFooter`,
  pageFooter: `${PREFIX}-pageFooter`,
  logoSection: `${PREFIX}-logoSection`,
  headerDate: `${PREFIX}-headerDate`,
  whiteSpace: `${PREFIX}-whiteSpace`,
  approve: `${PREFIX}-approve`,
  rowWithoutBorder: `${PREFIX}-rowWithoutBorder`,
  mainDetailsCell: `${PREFIX}-mainDetailsCell`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
  tableWidth: `${PREFIX}-tableWidth`,
  shipmentsTable: `${PREFIX}-shipmentsTable`,
  signatureBlock: `${PREFIX}-signatureBlock`,
  shipmentTable_bodyCode: `shipmentTable_bodyCode`,
  shipmentTable_bodyStatus: `shipmentTable_bodyStatus`,
};

const Root = styled("div")(({ theme }) => ({
  // fontFamily: ['"Cairo"', "sans-serif"].join(","),

  [`& .${classes.cairoFont}`]: {
    // fontFamily: ['"Cairo"', "sans-serif"].join(","),
  },

  [`& .${classes.headerCells}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.title}`]: {
    color: theme.palette.info.main,
    // whiteS;pace: "break-spaces",
  },

  [`& .${classes.notes}`]: {
    padding: theme.spacing(1),
    border: "1px solid " + theme.palette.action.disabled,
    wordBreak: "break-all",
    width: "100%",
    minHeight: 66,
  },

  [`& .${classes.noteCell}`]: {
    width: "30%",
    overflow: "hidden",
  },

  [`& .${classes.barcodeFont}`]: {
    fontFamily: '"Libre Barcode 39 Text"',
  },

  [`& .${classes.barcode}`]: {
    fontSize: 40,
    textAlign: "center",
  },

  [`& .${classes.headerTableCell}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.mainCellBorder}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.signature}`]: {
    textAlign: "center",
    padding: theme.spacing(2, 1, 1, 1),
    border: "none",
  },

  [`&.${classes.reportHeader}`]: {
    whiteSpace: "pre-line",
    paddingLeft: theme.spacing(2),
    lineHeight: "120%",
    fontSize: 12,
  },

  [`& .${classes.hiddenFooter}`]: {
    visibility: "hidden",
  },

  [`& .${classes.pageFooter}`]: {
    //   "&::after": {
    //    " content:  counter(page)  of  counter(pages)"
    //   },
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid " + theme.palette.text.secondary,
    display: "flex",
    alignItems: "flex-end",
  },

  [`& .${classes.logoSection}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.headerDate}`]: {
    textAlign: "end",
  },

  [`& .${classes.whiteSpace}`]: {
    "& tr": {
      "& td": {
        whiteSpace: "initial",
      },
    },
  },

  [`& .${classes.approve}`]: {
    textAlign: "end",
  },

  [`& .${classes.rowWithoutBorder}`]: {
    "& td": {
      border: "none",
      padding: "2px",
    },
  },

  [`& .${classes.mainDetailsCell}`]: {
    "& td": { border: "none", verticalAlign: "top" },
    "& td:first-of-type": { paddingLeft: 0 },
    "& td:last-of-type": { paddingRight: "2px" },

    border: "none",
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.tableHeadCell}`]: {
    padding: 0,
  },

  [`& .${classes.tableWidth}`]: {
    width: "100vw",
  },

  [`& .${classes.shipmentsTable}`]: {
    "& tr": {
      "&:hover": {
        backgroundColor: "#ffffff00 !important",
      },
    },
    "& th": {
      padding: theme.spacing(1),
    },
    "& td": {
      padding: theme.spacing(0, 1),
    },
  },

  [`& .${classes.signatureBlock}`]: {
    breakInside: "avoid",
  },

  [`& .${classes.shipmentTable_bodyCode}`]: {

  },

  [`& .${classes.shipmentTable_bodyStatus}`]: {

  },
  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 32,
  },
}));

const org = {
  operation: "organization",
  fields: [
    "name",
  ],
  variables: {},
};
// const collectionsListFields = shipmentField;
const PAGE_COUNT = gqlb.query({
  operation: "listShipments",
  fields: [{ paginatorInfo: ["lastPage"] }],
  variables: {
    input: {
      type: "ListShipmentsFilterInput",
      required: true,
    },
    first: {
      type: "Int",
    },
  },
});
export const SHIPPING_SETTING = gqlb.query({
  operation: "shippingSettings",
  fields: ["customReturnStatus"],
  variables: {},
});

export const USER = gqlb.query({
  operation: "me",
  fields: [{
    operation: "account",
    fields: [
      {
        operation: "...on Customer",
        fields: [
          "id",
          "name",
        ],
        variables: {},
      },
    ],
    variables: {},
  },],
  variables: {},
});
const PrintShipmentList = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const listType = urlQuery?.listType;
  if (urlQuery["statusCode"])
    urlQuery["statusCode"] = urlQuery["statusCode"].split(",");
  if (
    urlQuery["routeId"] &&
    urlQuery["routeId"].length > 1
  ) {
    urlQuery["routeId"] = urlQuery["routeId"]
      .split(",")
      .map((ele) => {
        return parseInt(ele);
      });
  }
  if (
    urlQuery["recipientZoneId"] &&
    urlQuery["recipientZoneId"].length > 1
  ) {
    urlQuery["recipientZoneId"] = urlQuery["recipientZoneId"]
      .split(",")
      .map((ele) => {
        return parseInt(ele);
      });
  }
  if (
    urlQuery["recipientSubzoneId"] &&
    urlQuery["recipientSubzoneId"].length > 1
  ) {
    urlQuery["recipientSubzoneId"] = urlQuery["recipientSubzoneId"]
      .split(",")
      .map((ele) => {
        return parseInt(ele);
      });
  }

  if (urlQuery["search"]) {
    urlQuery["search"] = urlQuery["search"].toString().trim()
      .split(",")
      .filter((i) => i.trim() !== "")
      .map((i) => i.trim());
  }

  if (listType === 'uncollected-from-delivery-agents') {
    urlQuery["inDeliveryAgentCustody"] =
    {
      ...(urlQuery["lastDeliveryAgentId"] && {
        deliveryAgentId: urlQuery["lastDeliveryAgentId"],
      })
    }
    delete urlQuery["lastDeliveryAgentId"]
  }

  if (urlQuery["fromDate"] || urlQuery["toDate"] || urlQuery["fromDays"] || urlQuery["untilDays"]) {
    const date = {
      ...(urlQuery["fromDate"] && { fromDate: urlQuery["fromDate"] }),
      ...(urlQuery["toDate"] && { toDate: urlQuery["toDate"] }),
      ...(urlQuery["fromDays"] && { fromDays: urlQuery["fromDays"] }),
      ...(urlQuery["untilDays"] && { untilDays: urlQuery["untilDays"] }),
    }
    delete urlQuery["fromDate"]
    delete urlQuery["toDate"]
    delete urlQuery["fromDays"]
    delete urlQuery["untilDays"]
    urlQuery["date"] = date
  }
  if (urlQuery["lastTransactiontoDate"] || urlQuery["lastTransactionfromDate"] || urlQuery["lastTransactionfromDays"] || urlQuery["lastTransactionuntilDays"]) {
    const lastTransactionDate = {
      ...(urlQuery["lastTransactionfromDate"] && { fromDate: urlQuery["lastTransactionfromDate"] }),
      ...(urlQuery["lastTransactiontoDate"] && { toDate: urlQuery["lastTransactiontoDate"] }),
      ...(urlQuery["lastTransactionfromDays"] && { fromDays: urlQuery["lastTransactionfromDays"] }),
      ...(urlQuery["lastTransactionuntilDays"] && { untilDays: urlQuery["lastTransactionuntilDays"] }),
    }
    delete urlQuery["lastTransactiontoDate"]
    delete urlQuery["lastTransactionfromDate"]
    delete urlQuery["lastTransactionfromDays"]
    delete urlQuery["lastTransactionuntilDays"]
    urlQuery["lastTransactionDate"] = lastTransactionDate
  }
  const stringValues = ["code", "fromCode", "toCode"];

  const { data: settings, } = useQuery(
    gql`
      ${SHIPPING_SETTING.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
    }
  );

  const { data: user } = useQuery(
    gql`
      ${USER.query}
    `,
    {
      variables: {},
      onCompleted: (data) => {
      }
    }
  );

  const keys = localStorage.getItem(`${listType}`) ? JSON.parse(localStorage.getItem(`${listType}`)) : initialData[listType]
  const adminNotesPermission = keys && keys?.includes("adminNotes")
  const account = user?.me?.account
  const shipmentFieldsData = [
    {
      data: keys && defaultQueryFields(keys, adminNotesPermission, undefined, account)
    },
  ];
  const shipmentsSumQuery = keys && getShipmentsSumQuery(keys, account)
  const shipmentSum = {
    operation: "sumShipments",
    fields: shipmentsSumQuery?.queryFields,
    variables: {
      input: {
        type: "ListShipmentsFilterInput",
        required: true,
      },
    },
  };

  stringValues.forEach((i) => {
    if (urlQuery[i]) urlQuery[i] = String(urlQuery[i]);
  });

  delete urlQuery.listType;
  const { data: pageCount, loading: pageLoad } = useQuery(
    gql`
      ${PAGE_COUNT.query}
    `,
    {
      first: 100,
      variables: {
        input: {
          ...urlQuery,
        },
        first: 100,
      },
      onCompleted: (data) => {
      }
    }
  );

  const pages = pageCount?.listShipments?.paginatorInfo?.lastPage;

  const queryBuilder = () => {
    // const queryNumber = Math.ceil(pages / 100);
    const queryArray = [org];
    shipmentsSumQuery?.queryFields.length > 0 && queryArray.push(shipmentSum)
    for (let index = 0; index < pages; index++) {
      const initialQuery = {
        operation: `listShipments${index}:listShipments`,
        fields: shipmentFieldsData,
        variables: {
          input: {
            type: "ListShipmentsFilterInput",
            required: true,
          },
          first: {
            type: "Int",
          },
          ["page" + index]: {
            type: "Int",
            name: "page",
          },
        },
      };
      queryArray.push(initialQuery);
    }
    return queryArray;
  };

  const queryBody = queryBuilder();
  const SHIPMENT_PRINT = gqlb.query(queryBody);

  const { t } = useTranslation();

  let variables = {
    input: {
      ...(listType === 'uncollected-from-delivery-agents'
        && {
        inDeliveryAgentCustody: {
          ...(urlQuery["lastDeliveryAgentId"] && {
            deliveryAgentId: urlQuery["lastDeliveryAgentId"],
          }),
        },
      }),
      ...urlQuery
    },
    first: 100,
  };
  for (let index = 0; index < pages; index++) {
    variables["page" + index] = index + 1;
  }

  const { data: queryData, loading } = useQuery(
    gql`
      ${SHIPMENT_PRINT.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !pages || !shipmentFieldsData,
      variables,
      onCompleted: (data) => {
      },
      onError: () => {
        return;
      },
    }
  );

  let parsedData = [];

  if (queryData?.listShipments0) {
    let concatData = [];
    for (const key in queryData) {
      if (key.startsWith("list")) {
        const listData = queryData[key].data;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }

  useEffect(() => {
    if (parsedData) {
      if (parsedData.length === 0) return;
      // if (!zoomed) {
      const zoomDetails = zoom();

      let newLink = document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.type = "text/css";
      newLink.href = process.env.PUBLIC_URL + "/" + zoomDetails?.css;

      // document
      //   .querySelectorAll("#manifestPrintTable")
      //   .forEach((i) => (i.style.zoom = zoomDetails.percent));

      document.querySelectorAll("#shipmentsTableContainer").forEach((i) => {
        i.style.minWidth = "100%";
        i.style.width = "100vw";
      });
      newLink.onload = () => {
        window.print();
        newLink.onload = null;
      };
      document.getElementById("maniContainer").appendChild(newLink);
      // }

    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  // useEffect(() => {
  //   if (parsedData.length > 0) {
  //     window.print();
  //   }
  //   return () => { };
  // }, [queryData]);

  const reportDetails = parsedData.length > 0;
  const table = !loading && !pageLoad && reportDetails !== null && (
    // const table = (
    <Table id="manifestPrintTable">
      <TableHead>
        <TableRow>
          <FixedTableCell>
          </FixedTableCell>
          {getShipmentsTableHeader(keys, adminNotesPermission, undefined, account).map((headCell, index) => {
            return (
              <FixedTableCell
                key={index}
              >
                {t(headCell)}
              </FixedTableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {
          parsedData.map((shipment, k) => {
            return <TableRow key={k}>
              <FixedTableCell
                key={k}
              >
                {k + 1}
              </FixedTableCell>
              {keys.map((ele, i) => {
                return <Fragment key={i}>
                  <GenerateShipmentCell
                    shipment={shipment}
                    elementKey={ele}
                    ifCode={keys?.find((e) => e === "code")}
                    classes={classes}
                    adminNotesPermission={adminNotesPermission}
                    account={account}
                    print
                    customReturnStatus={settings?.shippingSettings?.customReturnStatus}
                  />
                </Fragment>
              })}
            </TableRow>
          })
        }
        {queryData?.sumShipments && <TableRow>
          <FixedTableCell> </FixedTableCell>
          {keys?.find((e) => e === "code") && <FixedTableCell > </FixedTableCell>}
          {keys?.find((e) => e === "status") && <FixedTableCell > </FixedTableCell>}
          {queryData?.sumShipments && shipmentsSumQuery.selected.map((ele, i) => {
            return (
              <Fragment key={i}>
                <GenerateShipmentSumCell
                  shipmentSum={queryData?.sumShipments}
                  elementKey={ele}
                />
              </Fragment>
            )
          })}
        </TableRow>}
      </TableBody>
    </Table>
  );

  return (
    <>
      {shipmentFieldsData && <RootPrintCollectionList id="maniContainer">
        {loading || pageLoad ? (
          // {true ? (
          <>
            <FullScreenLoading minHeight="20%" />
            {/* this spans for download font before print */}
            <span className={clsx(classesPrintCollectionList.barcodeFont)} />
            <span
              style={{ opacity: 0 }}
              className={classesPrintCollectionList.cairoFont}
            >
              lا
            </span>
          </>
        ) : "reportDetails" === null ? (
          <NotFound />
        ) : (
          <Root>
            <div id="printManifestContainer">
              <table >
                <tbody>
                  <tr>
                    <td>
                      <div
                        className={clsx(classes.shipmentsTable, {
                          // [classes.tableWidth]: tableZoom,
                        })}
                        id="shipmentsTableContainer"
                      >
                        {table}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Root>
        )}
      </RootPrintCollectionList>}
    </>);
  // "ok")
};

export default PrintShipmentList;