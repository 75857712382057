import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
  Icon,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Add, Delete, Edit } from "@mui/icons-material";
import clsx from "clsx";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { gql, useMutation } from "@apollo/client";
import { DELETE_PRICE_LIST_PICKUP, SAVE_PRICE_LIST_PICKUP } from "./Graphql";

const PREFIX = "PriceListTables";

const classes = {
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  tableRow: `${PREFIX}-tableRow`,
  destinations: `${PREFIX}-destinations`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  [`& .${classes.destinations}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function PriceListTable(props) {
  const { pickups = [], addPickup, addDestination, setPickupList, priceListId, priceListType } = props;
  const { t } = useTranslation();

  //////////// Pickup Functions ////////////

  const [pickupPage, setPickupPage] = useState(0);
  const [pickupRowsPerPage] = useState(20);
  const [pickupIndex, setPickupIndex] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPickupPage(newPage);
  };

  //////////// Destination Functions ////////////

  const [destinationPage, setDestinationPage] = useState(0);
  const [destinationRowsPerPage, setDestinationRowsPerPage] = useState(20);

  const handleDestinationPage = (event, newPage) => {
    setDestinationPage(newPage);
  };

  const handleDestinationRowsPerPage = (event) => {
    setDestinationRowsPerPage(parseInt(event.target.value));
    setDestinationPage(0);
  };
  const destinations = pickups?.[pickupIndex]?.destinations;
  // row.services.map(ele => ' ' + ele.name + ' , ')
  const GetNames = (row) => {
    const names = []
    row.map(ele => names.push(ele.name + ' '))
    return names.toString();
  }

  const [deletePriceListPickupMutation, { loading: deleteLoad }] = useMutation(
    gql`
      ${DELETE_PRICE_LIST_PICKUP.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const [savePriceListPickup, { loading: deleteDestinationLoad }] = useMutation(
    gql`
      ${SAVE_PRICE_LIST_PICKUP.query}
    `
  );

  return (
    /*//////////// Pickup Table ////////////*/

    <StyledGrid container alignItems="flex-start">
      <Grid container item sm={12} md={4} component={Paper}>
        <Toolbar className={classes.toolbar} variant="dense">
          <Typography className={classes.title} color="inherit" variant="h6">
            {priceListType === "CONCIERGE" ? t("services") : t("shipmentPickupPlaces")}
          </Typography>

          {addPickup ? (
            <IconButton
              color="primary"
              onClick={() => {
                addPickup();
                setDestinationPage(0);
                setPickupIndex(0);
              }}
              aria-label="Add pickup"
              size="large"
              disabled={priceListId !== 0 && !Boolean(priceListId)}
            >
              <Add />
            </IconButton>
          ) : null}
        </Toolbar>
        <Grid container item className={classes.table}>
          <TableFixedHeaderWraper className={classes.tableContainer}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("serviceName")}</FixedTableCell>
                  {priceListType !== "CONCIERGE" &&
                    <FixedTableCell>{t("source")}</FixedTableCell>}

                  {addPickup ? <FixedTableCell></FixedTableCell> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {pickups.length > 0 &&
                  (pickupRowsPerPage > 0
                    ? pickups.slice(
                      pickupPage * pickupRowsPerPage,
                      pickupPage * pickupRowsPerPage + pickupRowsPerPage
                    )
                    : pickups
                  ).map((row, index) => (
                    <TableRow
                      className={clsx(classes.tableRow, {
                        [classes.focus]:
                          pickupPage * pickupRowsPerPage + index ===
                          pickupIndex,
                      })}
                      hover
                      key={index}
                      onClick={() => {
                        setDestinationPage(0);
                        setPickupIndex(pickupPage * pickupRowsPerPage + index);
                      }}
                    >
                      <FixedTableCell>{GetNames(row.services)}</FixedTableCell>
                      <ShipmentRegion
                        pathname={`/admin/zones/${row?.zone?.id}`}
                        zone={row?.zone?.name}
                        subzone={row?.subzone?.name}
                      />
                      {addPickup ? (
                        <FixedTableCell>
                          <IconButton
                            size="small"
                            onClick={() =>
                              addPickup(pickupPage * pickupRowsPerPage + index)
                            }
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            size="small"
                            disabled={deleteLoad}
                            onClick={() => {
                              setPickupList((prev) => {
                                const filterd = prev.filter(
                                  (i, deleteIndex) =>
                                    deleteIndex !==
                                    pickupPage * pickupRowsPerPage + index
                                );
                                return filterd;
                              })
                              deletePriceListPickupMutation({
                                variables: {
                                  id: parseInt(row.id),
                                },
                              })
                            }}
                          >
                            <Delete />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() =>
                              addPickup(
                                pickupPage * pickupRowsPerPage + index,
                                true
                              )
                            }
                          >
                            <Icon>content_copy</Icon>
                          </IconButton>
                        </FixedTableCell>
                      ) : null}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={pickups?.length ?? 0}
            rowsPerPage={pickupRowsPerPage}
            page={pickupPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[20]}
            disableLastPage
          />
        </Grid>
      </Grid>

      {/*//////////// Destination Table ////////////*/}

      <Grid
        container
        item
        sm={12}
        md={8}
        className={classes.destinations}
        component={Paper}
      >
        <Toolbar className={classes.toolbar} variant="dense">
          <Typography className={classes.title} color="inherit" variant="h6">
            {t("shipmentDestinationPlaces")}
          </Typography>

          {addDestination ? (
            <IconButton
              disabled={Boolean(pickups.length === 0)}
              onClick={() => addDestination(pickupIndex)}
              aria-label="Add destination"
              color="primary"
              size="large"
            >
              <Add />
            </IconButton>
          ) : null}
        </Toolbar>
        <Grid container item className={classes.table}>
          <TableFixedHeaderWraper className={classes.tableContainer}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("destination")}</FixedTableCell>
                  {priceListType !== "CONCIERGE" &&
                    <FixedTableCell>{t("weightUpTo")}</FixedTableCell>
                  }
                  <FixedTableCell>{t("deliveryFees")}</FixedTableCell>
                  {priceListType !== "CONCIERGE" &&
                    <>
                      <FixedTableCell>{t("extraWeight")}</FixedTableCell>
                      <FixedTableCell>{t("extraWeightCost")}</FixedTableCell>
                      <FixedTableCell>{t("returnFees")}</FixedTableCell>
                    </>}
                  <FixedTableCell>{t("collectionFees")}</FixedTableCell>
                  <FixedTableCell>{t("collectionUpTo")}</FixedTableCell>
                  <FixedTableCell>
                    {t("collectionExtraUnitFees")}
                  </FixedTableCell>
                  <FixedTableCell>{t("collectionExtraUnit")}</FixedTableCell>
                  {addDestination ? <FixedTableCell></FixedTableCell> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {destinations &&
                  (destinationRowsPerPage > 0
                    ? destinations.slice(
                      destinationPage * destinationRowsPerPage,
                      destinationPage * destinationRowsPerPage +
                      destinationRowsPerPage
                    )
                    : destinations
                  )?.map((row, index) => (
                    <TableRow hover className={classes.tableRow} key={index}>
                      <ShipmentRegion
                        pathname={`/admin/zones/${row?.zone?.id}`}
                        zone={row?.zone?.name}
                        subzone={row?.subzone?.name}
                      />
                      {priceListType !== "CONCIERGE" &&
                        <FixedTableCell>{row.weightUpTo}</FixedTableCell>
                      }
                      <FixedTableCell>{row.weightStartFees}</FixedTableCell>
                      {priceListType !== "CONCIERGE" &&
                        <>
                          <FixedTableCell>{row.weightExtraUnit}</FixedTableCell>
                          <FixedTableCell>{row.weightExtraUnitFees}</FixedTableCell>
                          <FixedTableCell>{row.returnFees}</FixedTableCell>
                        </>}
                      <FixedTableCell>{row.collectionStartFees}</FixedTableCell>
                      <FixedTableCell>{row.collectionUpTo}</FixedTableCell>
                      <FixedTableCell>
                        {row.collectionExtraUnitFees}
                      </FixedTableCell>
                      <FixedTableCell>{row.collectionExtraUnit}</FixedTableCell>
                      {addDestination ? (
                        <FixedTableCell>
                          <IconButton
                            size="small"
                            onClick={() =>
                              addDestination(
                                pickupIndex,
                                destinationPage * destinationRowsPerPage + index
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            size="small"
                            disabled={deleteDestinationLoad}
                            onClick={() => {
                              setPickupList((prev) => {
                                const filterd = prev[pickupIndex][
                                  "destinations"
                                ].filter(
                                  (i, deleteIndex) =>
                                    deleteIndex !==
                                    destinationPage * destinationRowsPerPage + index
                                );
                                const updated = [...prev];

                                updated[pickupIndex]["destinations"] = filterd;
                                Number.isInteger(prev[pickupIndex]["destinations"].length / destinationRowsPerPage)
                                  && setDestinationPage(destinationPage)
                                return updated;
                              })
                              const servicesIds = pickups?.[pickupIndex].services.map(item => item.id);
                              const destinations = pickups?.[pickupIndex].destinations.length > 0
                                ? [
                                  ...pickups?.[pickupIndex].destinations.map((i) => {
                                    const destination = {
                                      ...i,
                                      zoneId: i.zone.id,
                                      ...(i?.subzone?.id && { subzoneId: i.subzone.id }),
                                    };
                                    delete destination["zone"];
                                    delete destination["subzone"];
                                    return destination;
                                  }),
                                ]
                                : [];
                              const input = {
                                id: pickups?.[pickupIndex].id,
                                priceListId: priceListId,
                                serviceIds: servicesIds,
                                ...(pickups?.[pickupIndex].subzone && { subzoneId: pickups?.[pickupIndex].subzone.id }),
                                destinations: destinations
                              };
                              const zoneId = pickups?.[pickupIndex].zone?.id ?? null;

                              if (zoneId !== null) {
                                input.zoneId = zoneId;
                              }
                              savePriceListPickup({
                                variables: {
                                  input,
                                },
                              })
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </FixedTableCell>
                      ) : null}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={destinations?.length ?? 0}
            rowsPerPage={destinationRowsPerPage}
            page={destinationPage}
            onPageChange={handleDestinationPage}
            onRowsPerPageChange={handleDestinationRowsPerPage}
          />
        </Grid>
      </Grid>
    </StyledGrid>
  );
}
