export const GetPermissionSlugWithArray = (module, name, arrayOfCodes, type) => {
    let permissions = arrayOfCodes.map(code => GetPermissionSlug(module, name, code, type))
    return permissions
};

export const GetManifestPermissionSlug = (code, manifestType) => {
    return `shipping.manifest_${code}.${manifestType}`;
};

export const GetCollectionPermissionSlug = (code, collectionType) => {
    return `shipping.collection_${code}.${collectionType}`;
};

export const GetPaymentPermissionSlug = (code, paymentType) => {
    return `shipping.payment_${code}.${paymentType}`;
};

export const GetCustomerReqPermissionSlug = (code, customerRequestType) => {
    return `shipping.customer_request_${code}.${customerRequestType}`;
};

export const GetPermissionSlug = (module, name, code, Type) => {
    return `${module}.${name}_${code?.toLowerCase()}.${Type}`;
};