import React from 'react'
import PropTypes from "prop-types";
import CellLink from '../HOC/CustomComponents/CellLink';
import { FixedTableCell } from '../HOC/CustomComponents/FixedTableCell';
import ShipmentRegion from '../HOC/CustomComponents/ShipmentRegion';
import { Icon } from '@mui/material';
import { getListShipmentTable, shipmentFieldsFun } from './listManifestFields';
import PhoneNumberActions from '../HOC/CustomComponents/PhoneNumberActions';


const GenerateShipmentCell = (props) => {

    const { shipment, elementKey, classes, type, Permission, print } = props
    const field = shipmentFieldsFun(type, Permission).find((e) => e.key === elementKey)
    const fieldKey = getListShipmentTable(shipment, field?.key, type)
    if (field?.type === "link") {
        return (
            <CellLink pathname={fieldKey?.pathname}>
                {fieldKey?.value}
            </CellLink>
        )
    } else if (field?.type === "zone") {
        return (
            <ShipmentRegion
                pathname={fieldKey?.pathname}
                zone={fieldKey?.zone}
                subzone={fieldKey?.subzone}
            />
        )
    } else if (field?.type === "boolean") {
        return (
            <FixedTableCell >
                {fieldKey?.value ? (
                    <Icon className={classes.iconColor}>
                        check_circle_outline
                    </Icon>
                ) : (
                    <Icon color="error">highlight_off</Icon>
                )}
            </FixedTableCell>
        )
    }
    else if (field?.type === "barcode") {
        return (
            <FixedTableCell className={classes.barcode} dir="ltr" auto={true}>
                *{fieldKey?.value}*
            </FixedTableCell>
        )
    }
    else if (field?.type === "infoGroup") {
        return (
            <FixedTableCell>
                <span style={{ display: "block" }} >
                    {fieldKey?.name}
                </span>

                <PhoneNumberActions mobile={fieldKey?.mobile} phone={fieldKey?.phone} print={print} />
            </FixedTableCell>
        )
    }
    else if (field?.type === "phone") {
        return (
            <FixedTableCell dir="ltr" >
                <PhoneNumberActions mobile={fieldKey?.mobile} phone={fieldKey?.phone} print={print} />
            </FixedTableCell>
        )
    }
    else if (fieldKey?.value === "noVal") {
        return null
    }

    else {
        return (
            <FixedTableCell className={fieldKey.condition && classes.shipmentTable_description}>
                {fieldKey?.value}
            </FixedTableCell>
        )
    }

}

GenerateShipmentCell.Prototype = {
    shipment: PropTypes.object.isRequired,
}

export default GenerateShipmentCell