import React, { useState } from "react";

import { styled } from '@mui/material/styles';

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  useTheme,
} from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import { AttachMoney, Done, DoneAll } from "@mui/icons-material";
import moment from "moment";
import { useForm } from "react-hook-form";
import {
  APPROVE_VOUCHER_MUTATION,
  GL_APPROVE_VOUCHER_MUTATION,
  SAVE_VOUCHER_MUTATION,
  VOUCHER_BY_ID_QUERY,
} from "./VouchersListQuary";
// import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router";
import NotFound from "../../Error/NotFound";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_SAFES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CustomButton from "../HOC/MUI/CustomButton";
// import MUIDate from "../HOC/MUI/MUIDate";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import TitleAppBar from "../../Layout/TitleAppBar";

const PREFIX = 'VoucherForm';

const classes = {
  button: `${PREFIX}-button`,
  glApprovedButton: `${PREFIX}-glApprovedButton`,
  overlay: `${PREFIX}-overlay`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.glApprovedButton}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },
  [`& .${classes.overlay}`]: {
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    opacity: 0.7,
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

/////////////////DIALOG///////////////
const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

const VoucherForm = (props) => {
  const { t } = useTranslation();

  const [pathURL, setPathURL] = useState(props.match.path)

  const theme = useTheme();
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    safe: null,
    deliveryAgent: null,
    transactionType: null,
  });
  const [openDialog, setOpenDialog] = useState({
    open: false,
    edit: false,
    approve: false,
    glApprove: false,
  });

  const [voucherData, setVoucherData] = useState();

  const [fieldsState, setfieldsState] = useState({
    createdAt: new Date(),
  });
  const { enqueueSnackbar } = useSnackbar();

  const {
    formState: { errors },
    handleSubmit,
    setError,
    control,
    setValue,
    watch,
    clearErrors,
  } = useForm();

  const voucherId = props.match.params.id;

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const [saveVoucherMutation, { loading: saveVoucherLoad }] = useMutation(
    gql`
      ${SAVE_VOUCHER_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const [approveVoucherMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_VOUCHER_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setVoucherData(data["approveVoucher"]),
    }
  );

  const [glApproveVoucherMutation, { loading: glApproveLoad }] = useMutation(
    gql`
      ${GL_APPROVE_VOUCHER_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setVoucherData(data["glApproveVoucher"]),
    }
  );

  const { data: voucherByIdQuery, loading: voucherByIdLoad } = useQuery(
    gql`
      ${VOUCHER_BY_ID_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !voucherId,
      variables: {
        id: +voucherId,
      },
      onCompleted: (data) => {
        if (data?.voucher === null) return;
        const voucher = data?.voucher;

        setVoucherData(voucher);
        setValue("id", +voucher?.id);
        setValue("code", voucher?.code);
        setValue("date", voucher?.date);
        setValue("amount", voucher?.amount);

        voucher?.notes && setValue("notes", voucher?.notes);

        setfieldsState((prev) => ({
          ...prev,
          createdAt: voucher?.date,
        }));

        setAutocompleteValues({
          branch: voucher.branch,
          ...(voucher?.customer && {
            customer: voucher?.customer,
          }),
          ...(voucher?.deliveryAgent && {
            deliveryAgent: voucher?.deliveryAgent,
          }),
          ...(voucher?.glAccount && {
            glAccount: voucher?.glAccount,
          }),
          safe: voucher.safe,
        });
      },
    }
  );

  ////////////////////END DRAWER////////////////
  const history = useHistory();

  const saveVoucher = (data) => {
    data["date"] = dateFormat(data["date"]);
    data["amount"] = +data["amount"];
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }

    return saveVoucherMutation({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        setValue("id", +data.data?.saveVoucher?.id);
        setValue("code", data.data?.saveVoucher?.code);
        setVoucherData(data.data["glApproveVoucher"]);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        const url = history.createHref({
          pathname: `/admin/vouchers/${data?.data?.saveVoucher?.id}/edit`,
        });
        setPathURL(url)
        windowReplaceUrl(url);
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        setValidationError(graphQLErrors, setError);
      });
  };

  const handleDialogClose = () => {
    setOpenDialog({ edit: false, open: false, approve: false });
  };

  const approveVoucher = () => {
    approveVoucherMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("successfullyApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        pushUrl(props, `/admin/vouchers/${watch("id")}`);
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        handleDialogClose();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const saveAndApproveVoucher = () => {
    const voucherData = watch();
    saveVoucher(voucherData).then(() => {
      approveVoucher();
    });
  };

  const glApproveVoucher = () => {
    glApproveVoucherMutation({
      variables: {
        id: parseInt(watch("id")),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("successfullyGlApproved"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
        handleDialogClose();

        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  const onApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, approve: true }));
  };
  const onGlApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, glApprove: true }));
  };

  const parseData = (data) => {
    return data;
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const voucher = voucherByIdQuery?.voucher;
  const payeeType = watch("payeeType");

  const payeeField = (type) => {
    if (type === "SHPCUST") {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            name={"customerId"}
            label={t("customer")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            query={LIST_CUSTOMERS_DROPDOWN.query}
            skip={!watch("branchId")}
            variables={{
              input: {
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            defaultValue={autocompleteValues.customer}
          />
        </Grid>
      );
    } else if (type === "SHPDLVBY") {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"deliveryAgentId"}
            label={t("shippingAgent")}
            rules={{ required: t("fieldIsRequired") }}
            disabled={!watch("branchId")}
            onChangeValue={(e) => {
              if (e?.id !== "") {
                clearErrors("deliveryAgentId");
              }
            }}
            parseData={(data) => parseData(data)}
            query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
            skip={!watch("branchId")}
            variables={{
              input: {
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            defaultValue={autocompleteValues.deliveryAgent}
          />
        </Grid>
      );
    } else {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            name={"glAccountId"}
            label={t("glAccount")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            query={LIST_GL_ACCOUNTS_DROPDOWN.query}
            skip={!watch("branchId")}
            variables={{
              input: {
                typeCode: "SUB",
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            defaultValue={autocompleteValues.glAccount}
          />
        </Grid>
      );
    }
  };

  let formBody = null;

  const clearPayeeFeild = () => {
    setValue("customerId", "");
    setValue("glAccountId", "");
    setValue("deliveryAgentId", "");
  };

  /////////////////////// Customer Shipments Table /////////////////////////////

  const formCondition = voucherId ? !voucherByIdLoad : true;
  formBody = (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(saveVoucher)}
      spacing={2}
      sx={{ padding: "24px", width: "100%", margin: "0" }}
    >
      {formCondition ? (
        <Paper container component={Grid} sx={{ padding: 1.25, flexGrow: 1 }}>
          {/* <Grid container sm={12} xs={12} justifyContent="space-between">
            <Typography color="inherit" variant="h6" component="div">
              {t("vouchers")}
            </Typography>
          </Grid> */}

          <Grid
            spacing={2}
            container
            sx={{ position: "relative", flexGrow: 1 }}
          >
            <Grid sm={4} xs={12}>
              <ControlMUItextField
                name="code"
                control={control}
                label={t("recordCode")}
                rules={{ required: watch("id") ? t("fieldIsRequired") : false }}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <MUIDateTime
                name="date"
                label={t("theDate")}
                control={control}
                defaultValue={voucher?.date}
                value={fieldsState.createdAt}
                onChange={(e) =>
                  setfieldsState((prev) => ({ ...prev, createdAt: e }))
                }
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <ListBranches
                control={control}
                errors={errors}
                name={"branchId"}
                rules={{ required: t("fieldIsRequired") }}
                defaultValue={autocompleteValues.branch}
                onChangeValue={(e) => {
                  clearPayeeFeild();
                  setValue("safeId", "");
                }}
                skipDefaultBranch={Boolean(voucherId)}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <LookupDropdown
                control={control}
                errors={errors}
                name={"type"}
                label={t("type")}
                rules={{ required: t("fieldIsRequired") }}
                variables={{
                  input: { code: "CSH_VOUCHER_TYPES" },
                }}
                onCompleted={(data) =>
                  lookupsComplete(data, voucher?.["type"]?.code, "type")
                }
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <LookupDropdown
                control={control}
                errors={errors}
                name={"payeeType"}
                label={t("payee")}
                rules={{ required: t("fieldIsRequired") }}
                onChanges={clearPayeeFeild}
                variables={{
                  input: { code: "PAYEE_TYPE" },
                }}
                onCompleted={(data) =>
                  lookupsComplete(
                    data,
                    voucher?.["payeeType"]?.code,
                    "payeeType"
                  )
                }
              />
            </Grid>
            {payeeField(payeeType)}

            <Grid sm={6} xs={12}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"safeId"}
                disabled={!watch("branchId")}
                label={t("safe")}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_SAFES_DROPDOWN.query}
                skip={!watch("branchId")}
                variables={{
                  input: {
                    branchId: watch("branchId"),
                  },
                }}
                defaultValue={autocompleteValues.safe}
              />
            </Grid>
            <Grid sm={6} xs={12}>
              <ControlMUItextField
                name="amount"
                control={control}
                rules={{ required: t("fieldIsRequired") }}
                label={t("amount")}
                type="number"
              />
            </Grid>
            <Grid sm={12} xs={12}>
              <ControlMUItextField
                name="notes"
                control={control}
                label={t("notes")}
                rows={2}
              />
            </Grid>
            {(voucherData?.approved) && (
              <Box className={classes.overlay}>
              </Box>
            )}
          </Grid>

          <Grid sm={12} xs={12} container justifyContent="flex-end">
            <CustomButton
              customcolor={theme.palette.success.main}
              type="submit"
              className={classes.button}
              disabled={voucherData?.approved || saveVoucherLoad}
              variant="contained"
              size="medium"
              loading={saveVoucherLoad}
              // className={classes.button}
              startIcon={!saveVoucherLoad && <Done />}
            >
              {!saveVoucherLoad && t("save")}
            </CustomButton>
            {watch("id") && (
              <Button
                disabled={voucherData?.approved || saveVoucherLoad}
                className={classes.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={onApproved}
                name="approved"
                startIcon={<DoneAll />}
              >
                {t("approve")}
              </Button>
            )}
            {voucherData?.approved && !voucherData?.glApproved && (
              <Button
                disabled={voucherData?.glApproved}
                className={classes.glApprovedButton}
                variant="contained"
                size="medium"
                onClick={onGlApproved}
                name="glApproved"
                startIcon={<AttachMoney />}
              >
                {t("glApprove")}
              </Button>
            )}
          </Grid>
        </Paper>
      ) : (
        <FullScreenLoading />
      )}
    </Grid>
  );

  return voucherByIdLoad ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !voucherByIdQuery && voucherId ? (
      <NotFound />
    ) :
      // <Can permission={pagePermission}>
      (
        <Root>
          <TitleAppBar path={pathURL} />
          {props.children}

          {formBody}

          <Dialog
            open={openDialog.open}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"xs"}
          >
            <DialogTitle id="alert-dialog-title" color={"text.primary"}>
              {openDialog.approve && t("approveRecord")}
              {openDialog.glApprove && t("glApprove")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {openDialog.approve && t("approveRecordConfirmationMessage")}
                {openDialog.glApprove && t("glApproveRecordMessage")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                {t("cancel")}
              </Button>
              <Button
                disabled={approveLoad || glApproveLoad}
                onClick={() => {
                  openDialog.approve && saveAndApproveVoucher();
                  openDialog.glApprove && glApproveVoucher();
                }}
                color="primary"
                autoFocus
              >
                {openDialog.approve && !approveLoad && t("approve")}
                {openDialog.glApprove && !glApproveLoad && t("glApprove")}
                {(approveLoad || glApproveLoad) && <ButtonLoading />}
              </Button>
            </DialogActions>
          </Dialog>
        </Root>
      );
};

export default withRouter(VoucherForm);
