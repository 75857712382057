import * as gqlb from "gql-query-builder";

export const SHIPMENTS_BY_ZONE_REPORT = gqlb.query({
  operation: "shipmentsByZoneReport",
  fields: [
    {
      operation: "records",
      fields: [
        {
          operation: "paginatorInfo",
          fields: ["count", "currentPage", "lastPage", "perPage", "total"],
          variables: {},
        },
        {
          operation: "data",
          fields: [
            "zoneId",
            "zoneName",
            "shipmentsCount",
            "fees",
          ],
          variables: {},
        },
      ],
      variables: {
        page: {
          type: "Int",
        },
        first: {
          type: "Int",
        },
      },
    },
    {
      operation: "sum",
      fields: [
        "shipmentsCount",
        "fees"
      ],
      variables: {
      },
    }
  ],
  variables: {
    input: {
      type: "ListShipmentsFilterInput",
    },
  },
});
export const SHIPMENTS_BY_FEES_REPORT = gqlb.query({
  operation: "shipmentsByFeesReport",
  fields: [
    {
      operation: "records",
      fields: [
        {
          operation: "paginatorInfo",
          fields: ["count", "currentPage", "lastPage", "perPage", "total"],
          variables: {},
        },
        {
          operation: "data",
          fields: [
            "feesSum",
            "shipmentsCount",
            "fees",
          ],
          variables: {},
        },
      ],
      variables: {
        page: {
          type: "Int",
        },
        first: {
          type: "Int",
        },
      },
    },
    {
      operation: "sum",
      fields: [
        "shipmentsCount",
        "fees"
      ],
      variables: {
      },
    }
  ],
  variables: {
    input: {
      type: "ListShipmentsFilterInput",
    },
  },
});
