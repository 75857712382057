/* eslint-disable no-useless-concat */
import React, { useCallback, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Collapse,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  Toolbar,
  Typography,
} from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import { Cancel, CheckCircle } from "@mui/icons-material";
import clsx from "clsx";
import * as gqlb from "gql-query-builder";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import CustomTableRow from "./CustomTableRow";
import { EnhancedTableHead } from "./ProductListDetails";
import ManifestProductsProvider from "./ManifestProductsProvider";
import { Globals } from "../../HOC/Classes/Globals";
import SearchFilter from "../../../Hooks/SearchFilter";
import useWidth, { isWidthDown } from "../../../Hooks/useWidth";
import { useForm } from "react-hook-form";
import FormButton from "../../CustomComponents/Buttons/FormButton";
import { useSnackbar } from "notistack";
import ButtonLoading from "../../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { printLink } from "../../../helpers/defaultPrintList";
import PrintComponent from "../PrintComponent";
import SearchProduct from "./SearchProduct";

const TOOLBAR_PREFIX = "styledToolbar";

const toolbarClasses = {
  highlight: `${TOOLBAR_PREFIX}-highlight`,
  title: `${TOOLBAR_PREFIX}-title`,
  filterButton: `${TOOLBAR_PREFIX}-filterButton`,
  toolbar: `${TOOLBAR_PREFIX}-toolbar`,
  shipmentCode: `${TOOLBAR_PREFIX}-shipmentCode`,
};

const StyledToolbar = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1,

  [`& .${toolbarClasses.highlight}`]:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[300],
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[800],
      },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 15%",
  },

  [`& .${toolbarClasses.filterButton}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  [`& .${toolbarClasses.toolbar}`]: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  [`& .${toolbarClasses.shipmentCode}`]: {
    display: "flex",
    whiteSpace: "nowrap"
  },
}));

// const FOOTER_PREFIX = "styledFooter";

// const FooterClasses = {
//   button: `${FOOTER_PREFIX}-button`,
// };

const StyledFooter = styled("div")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(2, 1),
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
}));

const PREFIX = "ProductList";

const classes = {
  track: `${PREFIX}-track`,
  paper: `${PREFIX}-paper`,
  filters: `${PREFIX}-filters`,
  header: `${PREFIX}-header`,
};

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  width: "100%",
  backgroundColor: theme.palette.background.default,

  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.header}`]: {
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowX: "auto",
    maxHeight: `calc(100vh - 120px)`,
  },

  [`& .${classes.filters}`]: {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 72px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },

    height: "100%",
    overflow: "hidden",
  },
}));

export const UPDATE_SHIPMENT_STATUS = gqlb.mutation({
  operation: "updateShipmentStatus",
  fields: ["id"],
  variables: {
    input: {
      type: "UpdateShipmentStatusInput",
      required: true,
    },
  },
});

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const screenWidth = useWidth();
  const screenIsSmall = isWidthDown("sm", screenWidth);
  const { numSelected, done, clear, disabled, filter } = props;
  return (
    <StyledToolbar>
      <Toolbar
        className={clsx(toolbarClasses.toolbar, {
          [toolbarClasses.highlight]: numSelected > 0,
        }, classes.header)}
      >
        {numSelected > 0 ? (
          <Typography
            className={toolbarClasses.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t("productCount", { count: numSelected })}
          </Typography>
        ) : (
          <Typography
            className={toolbarClasses.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("products")}
          </Typography>
        )}
        {!screenIsSmall && filter}
        <IconButton
          aria-label="done"
          sx={{ color: "success.main" }}
          disabled={disabled}
          onClick={done}
          size="large"
        >
          <CheckCircle />
        </IconButton>
        {/* </Tooltip> */}

        <IconButton
          aria-label="close"
          onClick={() => clear()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
        {screenIsSmall && filter}
      </Toolbar>
    </StyledToolbar>
  );
};
const EnhancedTableToolbarListShipment = (props) => {
  const { t } = useTranslation();
  const screenWidth = useWidth();
  const screenIsSmall = isWidthDown("sm", screenWidth);
  const { onClosePrepareDialog, filter, shipmentCode } = props;

  return (
    <StyledToolbar>
      <Toolbar
        className={clsx(toolbarClasses.toolbar, classes.header)}
      >
        <Typography
          className={clsx(toolbarClasses.shipmentCode, toolbarClasses.title)}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <Box>
            {t("shipment") + " :"}
          </Box>
          <Box mx={1}>
            {shipmentCode}
          </Box>
        </Typography>
        {!screenIsSmall && filter}
        <IconButton
          aria-label="close"
          onClick={() => onClosePrepareDialog()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
        {screenIsSmall && filter}
      </Toolbar>
    </StyledToolbar>
  );
};
const EnhancedFooterListShipment = (props) => {
  const { shipmentIds, products, setShipmentIndex, setChange, shipmentIndex, onClosePrepareDialog } = props
  const { handleSubmit } =
    useForm();
  const { t } = useTranslation();
  const saveOption = [
    {
      key: "pre",
      name: "prepare"
    },
    {
      key: "prePrint",
      name: "prepareAndPrint"
    }
  ];

  const defaultPrint = Globals.settings.waybillCode;

  const [openPrint, setOpenPrint] = useState(false);
  const openPrintDialog = () => {
    defaultPrint ?
      printLink(defaultPrint, false, shipmentIds[shipmentIndex]) :
      setOpenPrint(true)
  };

  let quantityAllowed = 0
  let quantityAllowedSelected = 0
  const allowSave = products.filter(e => e.select)?.length === products.length
  products?.map(e => quantityAllowed += e.quantity)
  products?.map(e => quantityAllowedSelected += e.quantityChecked)
  const optionKey = Boolean(localStorage.getItem("prepareProduct")) ? localStorage.getItem("prepareProduct") : "pre"
  const [selectedOptionPrepare, setSelectedOptionPrepare] = React.useState(saveOption.find(e => e.key === optionKey));
  const { enqueueSnackbar } = useSnackbar();

  const [updateShipmentStatus, { loading: updateShipmentStatusLoading }] = useMutation(
    gql`
      ${UPDATE_SHIPMENT_STATUS.query}
    `
  );
  const onSubmit = (data) => {
    updateShipmentStatus({
      variables: {
        input: {
          id: shipmentIds[shipmentIndex],
          statusCode: "PRPD"
        }
      },
    })
      .then((data) => {
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        if (selectedOptionPrepare.key === "prePrint") {
          openPrintDialog()
          setChange((prev) => !prev)
        } else {
          if (shipmentIndex === (shipmentIds.length - 1)) {
            onClosePrepareDialog()
          } else {
            setShipmentIndex((prev) => prev + 1)
            setChange((prev) => !prev)
          }
        }
      })
      .catch(({ graphQLErrors }) => {
        console.log(graphQLErrors);
      });
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (event, option) => {
    setSelectedOptionPrepare(option);
    localStorage.setItem("prepareProduct", option.key)
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleNextPrepareShipmentAfterPrint = () => {
    if (shipmentIndex === (shipmentIds.length - 1)) {
      onClosePrepareDialog()
    } else {
      setShipmentIndex((prev) => prev + 1)
      setChange((prev) => !prev)
    }
  }

  return (
    <StyledFooter>
      <PrintComponent
        openPrint={openPrint}
        shipmentsId={shipmentIds[shipmentIndex]}
        oneShipment
        hidden={["3d"]}
        setOpenPrint={setOpenPrint}
        onClosePrintDialog={handleNextPrepareShipmentAfterPrint}
      />
      <Box>
        <Typography color={"text.primary"} variant="h6">
          {t("productSelected", { count: quantityAllowedSelected, from: quantityAllowed })}
        </Typography>
      </Box>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <React.Fragment>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
              <FormButton width="auto" disabled={!allowSave}>
                {updateShipmentStatusLoading ? <ButtonLoading /> : t(selectedOptionPrepare.name)}
              </FormButton>
              <Button
                size="small"
                disabled={!allowSave}
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {saveOption.map((option, index) => (
                          <MenuItem
                            key={option.key}
                            selected={option.key === selectedOptionPrepare.key}
                            onClick={(event) => handleMenuItemClick(event, option)}
                          >
                            {t(option.name)}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        </form>
      </Box>
    </StyledFooter>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  done: PropTypes.func.isRequired,
};

EnhancedTableToolbarListShipment.propTypes = {
  onClosePrepareDialog: PropTypes.func.isRequired,
  filter: PropTypes.any.isRequired,
  shipmentCode: PropTypes.string.isRequired,
};

EnhancedFooterListShipment.propTypes = {
  shipmentIds: PropTypes.array.isRequired,
  shipmentIndex: PropTypes.number.isRequired,
  products: PropTypes.array.isRequired,
  setShipmentIndex: PropTypes.func.isRequired,
  setChange: PropTypes.func.isRequired,
  onClosePrepareDialog: PropTypes.func.isRequired,
};

//*********Table Function*********

const PRODUCT_QUERY = gqlb.query({
  operation: "listProducts",
  fields: [
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "createdAt",
        "name",
        "active",
        "price",
        "weight",
        "availableQuantity",
        {
          customer: [
            "id",
            "name",
            "mobile",
            { zone: ["id", "name"] },
            { subzone: ["name"] },
          ],
        },
      ],
      variables: {},
    },
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
      required: true,
    },
    page: {
      type: "Int",
    },
    input: {
      type: "ListProductsFilterInput",
    },
  },
});

const SHIPMENT_PRODUCT_QUERY = gqlb.query({
  operation: "shipment",
  fields: [
    "id",
    "code",
    {
      shipmentProducts: [
        "price",
        "quantity",
        "returned",
        "delivered",
        "type",
        {
          product: ["id", "code", "name", "availableQuantity", "weight"],
        },
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
    },
  },
});

const ProductList = (props) => {
  const { type, variables, done, setSelected, shipmentId, onClosePrepareDialog, prepareShipment, filter } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [products, setProducts] = useState([]);
  const [shipmentIndex, setShipmentIndex] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(prepareShipment ? 10 : 20);

  const [drawerState, setDrawerState] = useState(false);
  const [queryVariables, setQueryVariables] = useState({
    refetch: true,
  });

  const { refetch, ...restVariables } = queryVariables;
  const user = Globals.user?.account

  const { t } = useTranslation();
  const { data, loading } = useQuery(
    gql`
      ${PRODUCT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !variables,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...(!user && { customerId: variables }),
          ...(!filter && { withAvailableStock: true }),
          active: true,
          ...(restVariables.search && { search: [...restVariables.search] }),
        },
      },
      onCompleted: (data) => {
        const handledData =
          data?.listProducts?.data !== null ? data?.listProducts?.data : [];

        let updatedDate = handledData.map((i) => {
          const selectedProduct = selectedProducts.find(
            (ele) => ele.product.id === i.id
          );
          if (selectedProduct) {
            return selectedProduct;
          } else {
            return {
              product: i,
              select: false,
            };
          }
        });
        setProducts(updatedDate);
      },
    }
  );

  const { data: shipmentData, loading: shipmentDataLoading } = useQuery(
    gql`
      ${SHIPMENT_PRODUCT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !prepareShipment,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(prepareShipment ? shipmentId[shipmentIndex] : 1),
      },
      onCompleted: (data) => {
        const handledData =
          data?.shipment?.shipmentProducts !== null ? data?.shipment?.shipmentProducts : [];
          const outProducts =handledData?.filter(product => product.type === "OUT");
        let updatedDate = outProducts.map((i) => {
          return {
            product: i.product,
            select: false,
            quantity: i.quantity,
            quantityChecked: 0,
          };
        });
        
        setProducts(updatedDate);
      },
    }
  );

  const isProductSelected = (addProducts, deleteProducts) => {
    const mainProducts = addProducts
      ? [...addProducts]
      : [...selectedProducts];

    const filterProducts = deleteProducts
      ? [...deleteProducts]
      : [...selectedProducts];

    return mainProducts.filter((newProduct) => {
      return !filterProducts.some(
        (current) => current.product.id === newProduct.product.id
      );
    });
  };

  const updateSelectedProductByForm = useCallback(
    ({ id, checkIsEmpty, name, value }) => {
      const update = [...products];
      const productIndex = update.findIndex((i) => {
        return i.product.id === id;
      });
      if (checkIsEmpty !== null || checkIsEmpty !== undefined)
        update[productIndex]["valid"] = checkIsEmpty;
      update[productIndex][name] = value;
      setProducts(update);
    },
    [products]
  );

  const handleSelectAllClick = (event, rows) => {
    const selected = products?.map((i) => {
      if (event.target.checked) {
        return {
          ...i,
          select: true,
        };
      } else {
        return {
          ...i,
          select: false,
        };
      }
    });
    if (event.target.checked) {
      const newProducts = isProductSelected(selected);
      setSelectedProducts((prev) => [...prev, ...newProducts]);
    } else {
      const filteredProducts = isProductSelected(null, selected);
      setSelectedProducts(filteredProducts);
    }
    setProducts(selected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const toggleFilterDrawer = () => setDrawerState(!drawerState);


  const resetPage = () => setPage(0);

  const [change, setChange] = useState(false)

  const filtersForm = (
    <SearchFilter
      {...{ resetPage, loading }}
      preventPushUrl
      queryVariables={(variables) => setQueryVariables(variables)}
      onSubmitFunc={() => setDrawerState(false)}
    />
  );

  const checkProducts = (value, products) => {
    let response;
    const productChecked = products.filter((e) => e.product.code.toLowerCase().trim() === value.toLowerCase().trim())
    if (productChecked.length > 0) {
      if (productChecked[0].quantityChecked === productChecked[0].quantity) {
        enqueueSnackbar(t("noFoundCode", { code: value }), {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      } else {
        productChecked[0].quantityChecked += 1
        if (productChecked[0].quantityChecked === productChecked[0].quantity) {
          productChecked[0].select = true
        }
      }
      response = true
    } else {
      enqueueSnackbar(t("noFoundCode", { code: value }), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
      response = false
    }

    return response
  };

  const filtersInProduct = !shipmentDataLoading && (
    <SearchProduct
      checkProductsFun={(variables) => checkProducts(variables, products)}
      checkProduct={true}
      change={change}
      products={products}
      setChange={setChange}
    />
  );
  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const selectedProductLength = selectedProducts.length;

  let tableBody = null;

  let progress = (
    <Grid container item justifyContent="center" className={classes.track} marginBottom='50px'>
      <CircularProgress />
    </Grid>
  );

  const selectedProductParPage = () => {
    return products.filter((i) => i.select === true).length;
  };
  const validProducts = () => {
    return type === "PADD"
      ? selectedProductLength <= 0 ||
      selectedProducts.some((i) => i.valid === false)
      : selectedProductLength <= 0;
  };

  const arrayData = products && prepareShipment ? (rowsPerPage > 0 ? products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : products) : products

  if (products && !(loading || shipmentDataLoading)) {
    tableBody = (
      <Grid container justifyContent="center" sx={{ width: "100%" }} >
        {/* *******Table******* */}
        <div className={classes.paper}>
          <TableFixedHeaderWraper containerScroll className={classes.noScroll}>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <EnhancedTableHead
                numSelected={selectedProductParPage()}
                onSelectAllClick={(e) => handleSelectAllClick(e, products)}
                rowCount={products?.length}
                type={type ?? "PADD"}
                prepareShipment={prepareShipment}
              />
              <TableBody>
                {arrayData.map((product, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <CustomTableRow
                      prepareShipment={prepareShipment}
                      key={product.product.id}
                      product={product}
                      type={type}
                      onChangeCheck={(check) => {
                        setProducts((prev) => {
                          let updated = [...prev];
                          updated[index] = {
                            ...updated[index],
                            select: !updated[index].select,
                          };

                          if (check.target.checked) {
                            setSelectedProducts((prev) => [
                              ...prev,
                              updated[index],
                            ]);
                          } else {
                            setSelectedProducts((prev) => {
                              return prev.filter(
                                (i) => i.product.id !== product.product.id
                              );
                            });
                          }

                          return updated;
                        });
                      }}
                      labelId={labelId}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={prepareShipment ? products.length : data?.listProducts?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            rowsPerPageOptions={prepareShipment ? [5, 10, 15] : null}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Grid>
    );
  }
  return (
    <ManifestProductsProvider
      value={{ updateSelectedProductByForm }}
    >
      <Root container justifyContent="center" >
        <Grid item container sm={12} alignContent="flex-start">
          {prepareShipment ? <EnhancedTableToolbarListShipment
            disabled={validProducts()}
            shipmentCode={shipmentData?.shipment?.code ?? ''}
            filter={
              filtersInProduct
            }
            onClosePrepareDialog={onClosePrepareDialog}
          />
            :
            <EnhancedTableToolbar
              numSelected={selectedProductLength}
              clear={done}
              toggleFilterDrawer={toggleFilterDrawer}
              done={() => {
                done();
                setSelected(selectedProducts);
              }}
              disabled={validProducts()}
              filter={filtersForm}
            />
          }

          {(loading || shipmentDataLoading)
            ? progress
            : products && products?.length !== 0
              ? tableBody
              : !(loading || shipmentDataLoading) && (
                <EmptyTableMessage loading={false} message={t("noProducts")} />
              )}

          {!shipmentDataLoading && prepareShipment &&
            <EnhancedFooterListShipment
              shipmentIds={shipmentId}
              shipmentIndex={shipmentIndex}
              products={products}
              setShipmentIndex={setShipmentIndex}
              setChange={setChange}
              onClosePrepareDialog={onClosePrepareDialog}
            />}
        </Grid>
      </Root>
    </ManifestProductsProvider>
  );
};

ProductList.propTypes = {
  type: PropTypes.string.isRequired,
  variables: PropTypes.any,
  done: PropTypes.any,
  setSelected: PropTypes.any,
  shipmentId: PropTypes.array,
  onClosePrepareDialog: PropTypes.func,
  prepareShipment: PropTypes.bool
}

export default ProductList;
