import React from "react";
import { styled } from "@mui/material/styles";
import {
  Collapse,
  DialogTitle,
  Typography,
  DialogContent,
  Dialog,
  Button,
  DialogActions,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { BANK, SAVE_BANK } from "./Graphql";
import { useMutation, gql, useQuery } from "@apollo/client";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import Grid from "@mui/material/Unstable_Grid2";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MuiSwitch from "../HOC/MUI/MUIswitch";
const PREFIX = "BranchForm";

const classes = {
  spacing: `${PREFIX}-spacing`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },
}));

const BankForm = (props) => {
  const { t } = useTranslation();
  const { id } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, setValue, setError } = useForm({
    defaultValues: {
      active: true,
    },
  });
  const { errors } = formState;

  const { loading } = useQuery(
    gql`
      ${BANK.query}
    `,
    {
      skip: !id,
      notifyOnNetworkStatusChange: true,
      variables: { id: parseInt(id) },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const bankData = data.bank;
        const bankParams = ["code", "name", "active"];
        bankParams?.forEach((i) => {
          bankData[i] && setValue(i, bankData[i]);
        });
        setValue("active", bankData["active"]);
      },
    }
  );

  const [saveBank, { loading: saveBankLoading }] = useMutation(
    gql`
      ${SAVE_BANK.query}
    `
  );

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === undefined || data[key] === "" || data[key] === null) {
        delete data[key];
      }
    }
    saveBank({
      variables: {
        input: {
          ...data,
          ...(id && { id: id }),
        },
      },
    })
      .then((data) => {
        // pushUrl(props, `/admin/banks/${data?.data?.saveBank?.id}`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        props.handelCloseDialog();
        props.refetch();
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const body = (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={props.open}
      onClose={props.handelCloseDialog}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Grid sm={12}>
            <Typography variant="h6" color={"text.primary"}>
              {t("addBank")}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Root>
            {loading ? (
              <FullScreenLoading minHeight="100px" />
            ) : (
              <Grid
                container
                justifyContent="flex-start"
                aligns="center"
                spacing={2}
              >
                <Grid container xs={12} sm={12} justifyContent="end">
                  <MuiSwitch
                    edge="end"
                    name="active"
                    label={t("active")}
                    control={control}
                  />
                </Grid>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                  margin="normal"
                />
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  margin="normal"
                  label={t("name")}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
            )}
          </Root>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handelCloseDialog}>{t("cancel")}</Button>
          <Button type="submit">
            {saveBankLoading ? <ButtonLoading /> : t("confirm")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return (
    <Root>
      <>{body}</>
    </Root>
  );
};

export default BankForm;
