/* eslint-disable no-useless-concat */
import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import {
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
  Icon,
  Dialog,
  DialogContent,
} from "@mui/material";

import { ZONE_ID, SAVE_ZONE } from "./Graphql";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useForm } from "react-hook-form";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { Add, Edit } from "@mui/icons-material";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { isEmpty } from "lodash";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";

const PREFIX = "ZoneForm";

const classes = {
  paper: `${PREFIX}-paper`,
  toolbar: `${PREFIX}-toolbar`,
  iconColor: `${PREFIX}-iconColor`,
  title: `${PREFIX}-title`,
  table: `${PREFIX}-table`,
  dialog: `${PREFIX}-dialog`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.table}`]: {
    display: "grid",
    minWidth: "100%",
  },

  [`& .${classes.dialog}`]: {
    paddingBottom: theme.spacing(1),
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const ZoneForm = (props) => {
  const [active, setActive] = useState({
    zone: true,
    subzones: true,
  });
  const [dialogState, setDialogState] = useState(false);
  const [zoneLoading, setZoneLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [updateSubzone, setUpdateSubzone] = useState(null);
  const [autocompleteValues, setAutocompleteValues] = useState({
    zoneBranch: null,
    subzoneBranch: null,
  });

  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const openDialog = (subzone) => {
    setDialogState(true);
    subzone && setUpdateSubzone(subzone);
  };
  const closeDialog = () => {
    setDialogState(false);
    setUpdateSubzone(null);
    setSubzoneValue("code", "");
    setSubzoneValue("name", "");
    setSubzoneValue("branchId", "");
    setAutocompleteValues((prev) => ({
      ...prev,
      subzoneBranch: null,
    }));
    setActive((prev) => ({
      ...prev,
      subzones: true,
    }));
  };

  const handelActivate = (e, parameter) => {
    const active = e.target.checked;
    setActive((prev) => ({
      ...prev,
      [parameter]: active,
    }));
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm();

  const {
    handleSubmit: handleSubzoneSubmit,
    control: subZoneControl,
    formState: { errors: subzoneErrors },
    setValue: setSubzoneValue,
    setError: setSubzoneError,
  } = useForm();

  const zoneId = parseInt(props.match.params.id);
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${ZONE_ID.query}
    `,
    {
      skip: !zoneId,
      fetchPolicy: "network-only",
      variables: { id: parseInt(zoneId) },
      onCompleted: (data) => {
        const subzones = data?.zone?.children ?? [];
        setSubzon(subzones);
        setValue("code", data?.zone?.code);
        setValue("name", data?.zone?.name);
        setAutocompleteValues((prev) => ({
          ...prev,
          zoneBranch: data?.zone?.branch,
        }));
        setActive((prev) => ({
          ...prev,
          zone: data?.zone?.active,
        }));
      },
    }
  );
  const subzonesArr = data?.zone?.children ?? [];
  const [subzones, setSubzon] = useState(subzonesArr);

  const [saveZone, { loading: saveZoneLoading }] = useMutation(
    gql`
      ${SAVE_ZONE.query}
    `
  );
  //////////////////////////////// SUBZONE //////////////////////////

  const onSubmitSubzone = (data, parentId, subzoneId) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    saveZone({
      variables: {
        input: {
          ...(parentId && { parentId: parentId }),
          ...(subzoneId && { id: subzoneId }),
          active: active.subzones,
          ...data,
        },
      },
    })
      .then((data) => {
        const checksubZone = subzones.map(
          (ele) => ele.id === data.data.saveZone.id
        );
        const check = checksubZone.includes(true);
        if (check) {
          const index = subzones.findIndex(
            (x) => x.id === data.data.saveZone.id
          );
          subzones[index] = data.data.saveZone;
          setSubzon(subzones);
        } else {
          subzones.push(data.data.saveZone);
          setSubzon(subzones);
        }
        closeDialog();
        // refetch();
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setSubzoneError);
        console.log(graphQLErrors);
      });
  };

  //////////////////////////////// ZONE //////////////////////////
  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    setZoneLoading(true);
    saveZone({
      variables: {
        input: {
          ...(zoneId && { id: zoneId }),
          active: active.zone,
          ...data,
        },
      },
    })
      .then((data) => {
        setZoneLoading(false);

        !zoneId && pushUrl(props, `${data.data.saveZone.id}/edit`);
        zoneId && pushUrl(props, `/admin/zones/${data?.data?.saveZone?.id}`);
      })
      .catch(({ graphQLErrors }) => {
        setZoneLoading(false);

        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classes.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.zone && zoneId ? (
    <NotFound />
  ) : (
    <Root>
      <TitleAppBar path={props.match.path} />
      <Paper className={classes.paper}>
        <Form
          setActive={setActive}
          setAutocomplete={setAutocompleteValues}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          errors={errors}
          active={active}
          handelActivate={handelActivate}
          autocomplete={autocompleteValues}
          saveZoneLoading={saveZoneLoading}
          zoneLoading={zoneLoading}
          zoneId={zoneId}
        />
      </Paper>
      <Dialog
        fullWidth
        maxWidth="xs"
        classes={{
          paper: classes.dialog,
        }}
        open={dialogState}
        onClose={closeDialog}
      >
        <DialogContent>
          <Form
            parentId={zoneId}
            updateSubzone={updateSubzone}
            setSubzoneValue={setSubzoneValue}
            setActive={setActive}
            setAutocomplete={setAutocompleteValues}
            handleSubzoneSubmit={handleSubzoneSubmit}
            onSubmitSubzone={onSubmitSubzone}
            subZoneControl={subZoneControl}
            subzoneErrors={subzoneErrors}
            active={active}
            handelActivate={handelActivate}
            autocomplete={autocompleteValues}
            saveZoneLoading={saveZoneLoading}
            zoneId={zoneId}
          />
        </DialogContent>
      </Dialog>
      <Paper
        container
        sm={12}
        md={8}
        className={classes.table}
        component={Grid}
      >
        <Toolbar className={classes.toolbar} variant="dense">
          <Typography className={classes.title} color="inherit" variant="h6">
            {t("subzone")}
          </Typography>

          <IconButton
            disabled={!zoneId}
            onClick={() => openDialog()}
            aria-label="Add destination"
            color="primary"
            size="large"
          >
            <Add />
          </IconButton>
        </Toolbar>
        <TableFixedHeaderWraper>
          {loading || isEmpty(subzones) ? (
            <EmptyTableMessage
              loading={loading}
              message={t("searchNoResult")}
            />
          ) : (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("#")}</FixedTableCell>
                  <FixedTableCell>{t("subzoneCode")}</FixedTableCell>
                  <FixedTableCell>{t("subzoneName")}</FixedTableCell>
                  <FixedTableCell>{t("branch")}</FixedTableCell>
                  <FixedTableCell>{t("active")}</FixedTableCell>
                  <FixedTableCell></FixedTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subzones &&
                  (rowsPerPage > 0
                    ? subzones.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : subzones
                  )?.map((row, index) => (
                    <TableRow hover key={index}>
                      <FixedTableCell>{row.id}</FixedTableCell>
                      <FixedTableCell>{row.code}</FixedTableCell>
                      <FixedTableCell>{row.name}</FixedTableCell>
                      <FixedTableCell>{row?.branch?.name}</FixedTableCell>
                      <FixedTableCell>
                        {row?.active ? (
                          <Icon className={classes.iconColor}>
                            check_circle_outline
                          </Icon>
                        ) : (
                          <Icon color="error">highlight_off</Icon>
                        )}
                      </FixedTableCell>
                      <FixedTableCell>
                        <IconButton
                          size="small"
                          onClick={() => openDialog(row)}
                        >
                          <Edit />
                        </IconButton>
                      </FixedTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableFixedHeaderWraper>
        <MUITablePagination
          count={subzones.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePage}
          onRowsPerPageChange={handleRowsPerPage}
        />
      </Paper>
    </Root>
  );
};

export default ZoneForm;

const Form = (props) => {
  const {
    parentId,
    updateSubzone,
    setSubzoneValue,
    setActive,
    setAutocomplete,
    handleSubzoneSubmit,
    onSubmitSubzone,
    handleSubmit,
    onSubmit,
    subZoneControl,
    control,
    subzoneErrors,
    errors,
    active,
    handelActivate,
    autocomplete,
    saveZoneLoading,
    zoneLoading,
    zoneId,
  } = props;
  useEffect(() => {
    if (updateSubzone) {
      setSubzoneValue("code", updateSubzone?.code);
      setSubzoneValue("name", updateSubzone?.name);
      setActive((prev) => ({
        ...prev,
        subzones: updateSubzone?.active,
      }));
      setAutocomplete((prev) => ({
        ...prev,
        subzoneBranch: updateSubzone?.branch,
      }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        component="form"
        onSubmit={
          parentId
            ? handleSubzoneSubmit((data) =>
                onSubmitSubzone(data, parentId, updateSubzone?.id)
              )
            : handleSubmit(onSubmit)
        }
      >
        <Grid
          container
          justifyContent="space-between"
          xs={12}
          alignItems={"center"}
        >
          <Typography variant="h6" color={"text.primary"}>
            {t(parentId && "subzone")}
          </Typography>

          <FormControlLabel
            checked={parentId ? active.subzones : active.zone}
            control={<Switch color="primary" />}
            label={t("active")}
            labelPlacement="start"
            onChange={(e) => handelActivate(e, parentId ? "subzones" : "zone")}
          />
        </Grid>

        <Grid xs={12} sm={6}>
          <ControlMUItextField
            control={parentId ? subZoneControl : control}
            errors={parentId ? subzoneErrors : errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <ControlMUItextField
            control={parentId ? subZoneControl : control}
            errors={parentId ? subzoneErrors : errors}
            name={"name"}
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <ListBranches
            control={parentId ? subZoneControl : control}
            errors={parentId ? subzoneErrors : errors}
            name={"branchId"}
            skipDefaultBranch={Boolean(zoneId)}
            defaultValue={
              parentId ? autocomplete.subzoneBranch : autocomplete.zoneBranch
            }
          />
        </Grid>
        {/* <Grid container  sm={12}>
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"notes"}
                    label={t("notes")}
                    rules={{ required: t("fieldIsRequired") }}
                  />
                </Grid> */}
        <Grid container sm={12} justifyContent="flex-end">
          {parentId ? (
            <FormButton className={classes.button} disabled={saveZoneLoading}>
              {saveZoneLoading ? <ButtonLoading /> : t("save")}
            </FormButton>
          ) : (
            <FormButton className={classes.button} disabled={zoneLoading}>
              {zoneLoading ? <ButtonLoading /> : t("save")}
            </FormButton>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
