import React, { useEffect } from 'react'
import CustomTab from '../../HOC/FunctionComponents/CustomTab';
import { useTranslation } from 'react-i18next';
import { Chip, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import clsx from 'clsx';
import TableFixedHeaderWraper from '../../HOC/CustomComponents/TableWithFixedHeader';
import ShipmentsTable from '../../HOC/CustomComponents/ShipmentsTable';
import MUITablePagination from '../../HOC/MUI/TablePagination/MUITablePagination';
import EmptyTableMessage from '../../HOC/FunctionComponents/EmptyTableMessage';
import useConciergeRequestsPaymentDetails from '../ConciergeRequestsDetails';
import usePaymentsDetails from '../paymentDetails';
import usePaymentPickupsDetails from '../paymentPickupsDetails';
import { EnhancedTableToolbarView } from './EnhancedTableToolbar';
import { Globals } from '../../HOC/Classes/Globals';
import FullScreenLoading from '../../HOC/FunctionComponents/LoadingPages/FullScreenLoading';

function Tabs(props) {
    const {
        paymentType,
        paymentsData,
        classes,
        paymentId,
        setLocalDataKey,
        localKeys,
        shipmentData,
        setShipmentData,
        conciergeRequestsData,
        setConciergeRequestsData,
        getConciergeData,
        loadingConciergeData,
        loadingShipmentData,
        loadingPickupData,
        getPickupData,
        pickupData,
        setPickupData
    } = props

    const ALLOWED_CONCIERGE = paymentType === "DLVBY" && Globals.settings.allowedCustomerTypes.includes("CONCIERGE")
    const ALLOWED_PICKUP = paymentType === "DLVBY"
    const ALLOWED_SHIPMENT = Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") || Globals.settings.allowedCustomerTypes.includes("MERCHANT")
    const { t } = useTranslation()


    const tapsArray = [];

    if (ALLOWED_SHIPMENT) {
        tapsArray.push(
            {
                tabHead:
                    <Typography
                        className={classes.title}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {t("shipments")}
                        <Chip color="primary" size="small" label={shipmentData.total} sx={{ mx: 1 }} />
                    </Typography>,
                panel:
                    <Shipment
                        setShipmentData={setShipmentData}
                        shipmentData={shipmentData}
                        paymentId={paymentId}
                        setLocalDataKey={setLocalDataKey}
                        paymentType={paymentType}
                        paymentsData={paymentsData}
                        loadingShipmentData={loadingShipmentData}
                        classes={classes}
                        localKeys={localKeys}
                    />,
            }
        )
    }
    if (ALLOWED_PICKUP ? paymentsData?.deliveryAgent?.pickupCommission === null ? Globals.settings.pickupCommission : paymentsData?.deliveryAgent?.pickupCommission : false) {
        tapsArray.push(
            {
                tabHead:
                    <Typography
                        className={classes.title}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {t("pickups")}
                        <Chip color="primary" size="small" label={pickupData.total} sx={{ mx: 1 }} />
                    </Typography>,
                panel:
                    <Pickup
                        paymentId={paymentId}
                        paymentType={paymentType}
                        paymentsData={paymentsData}
                        getPickupData={getPickupData}
                        pickupData={pickupData}
                        setPickupData={setPickupData}
                        loadingPickupData={loadingPickupData}
                        classes={classes}
                    />
            }
        )
    }
    if (ALLOWED_CONCIERGE) {
        tapsArray.push(
            {
                tabHead:
                    <Typography
                        className={classes.title}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {t("totalConciergeRequests")}
                        <Chip color="primary" size="small" label={conciergeRequestsData.total} sx={{ mx: 1 }} />
                    </Typography>,
                panel:
                    <ConciergeRequests
                        paymentId={paymentId}
                        paymentType={paymentType}
                        paymentsData={paymentsData}
                        getConciergeData={getConciergeData}
                        conciergeRequestsData={conciergeRequestsData}
                        setConciergeRequestsData={setConciergeRequestsData}
                        loadingConciergeData={loadingConciergeData}
                        classes={classes}
                    />
            }
        )
    }


    return (
        <CustomTab tapDetails={tapsArray} />
    )
}
const Shipment = (props) => {
    const {
        setShipmentData,
        shipmentData,
        paymentId,
        setLocalDataKey,
        paymentType,
        paymentsData,
        loadingShipmentData,
        classes,
        localKeys
    } = props

    const handleChangePageShip = (event, newPage) => {
        setShipmentData(prev => {
            return {
                ...prev,
                page: newPage
            }
        })
    };

    const handleChangeRowsPerPageShip = (event) => {
        setShipmentData(prev => {
            return {
                ...prev,
                page: 0,
                rowsPerPage: event.target.value
            }
        })
    };
    const { details: paymentDetails } = usePaymentsDetails({
        type: paymentType,
        typeData: paymentsData,
    });

    return <Paper
        className={clsx(
            classes.table,
            classes.paper
        )}
    >
        <EnhancedTableToolbarView
            {...props}
            type="SHIPMENT"
            ids={shipmentData?.pickedShipment.map((i) => i.shipment.id)}
            total={shipmentData?.total}
            paymentId={paymentId}
            setLocalDataKey={setLocalDataKey}
            typeCode={paymentType}
            localKeys={localKeys}
        />

        {!loadingShipmentData && (
            <TableFixedHeaderWraper>
                <ShipmentsTable
                    data={shipmentData?.pickedShipment}
                    headCells={paymentDetails.tableHead}
                    shipmentNumber={(index) => shipmentData.page * shipmentData.rowsPerPage + index + 1}
                    parseBodyCell={paymentDetails.tableBody}
                    sumRow={shipmentData.total > 0 ? paymentDetails.tableTotal : null}

                />
            </TableFixedHeaderWraper>
        )}
        <Grid container justifyContent="center" alignItems="center">
            {loadingShipmentData ?
                <FullScreenLoading height={"327px"} />
                : shipmentData.total === 0 && (
                    <EmptyTableMessage loading={false} />
                )}
        </Grid>
        {shipmentData.total !== 0 &&
            <MUITablePagination
                count={shipmentData.total}
                rowsPerPage={shipmentData.rowsPerPage}
                page={shipmentData.page}
                onPageChange={handleChangePageShip}
                onRowsPerPageChange={handleChangeRowsPerPageShip}
            />}

    </Paper>
}

const ConciergeRequests = (props) => {
    const {
        paymentType,
        paymentsData,
        getConciergeData,
        conciergeRequestsData,
        setConciergeRequestsData,
        loadingConciergeData,
        classes,
    } = props

    useEffect(() => {
        conciergeRequestsData.noData && getConciergeData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangePageConcierge = (event, newPage) => {
        setConciergeRequestsData(prev => {
            return {
                ...prev,
                page: newPage
            }
        })
    };

    const handleChangeRowsPerPageConcierge = (event) => {
        setConciergeRequestsData(prev => {
            return {
                ...prev,
                page: 0,
                rowsPerPage: event.target.value
            }
        })
    };

    const { details: conciergeRequestsDetails } = useConciergeRequestsPaymentDetails({
        type: paymentType,
        typeData: paymentsData
    });



    return <Paper
        className={clsx(
            classes.table,
            classes.paper
        )}
    >
        {!loadingConciergeData && (
            <TableFixedHeaderWraper>
                <ShipmentsTable
                    data={conciergeRequestsData.pickedConciergeRequests}
                    headCells={conciergeRequestsDetails.tableHead}
                    parseBodyCell={conciergeRequestsDetails.tableBody}
                    total={conciergeRequestsDetails.tableTotal}
                    sumRow={conciergeRequestsDetails?.sum}
                    sumTableNumber={conciergeRequestsDetails?.sumTableNumber}
                />
            </TableFixedHeaderWraper>
        )}
        <Grid container justifyContent="center" alignItems="center">
            {loadingConciergeData ?
                <FullScreenLoading height={"327px"} />
                : conciergeRequestsData.total === 0 && (
                    <EmptyTableMessage loading={false} />
                )}
        </Grid>
        {conciergeRequestsData.total !== 0 &&
            <MUITablePagination
                count={conciergeRequestsData.total}
                rowsPerPage={conciergeRequestsData.rowsPerPage}
                page={conciergeRequestsData.page}
                onPageChange={handleChangePageConcierge}
                onRowsPerPageChange={handleChangeRowsPerPageConcierge}
            />}


    </Paper>
}
const Pickup = (props) => {
    const {
        paymentType,
        paymentsData,
        loadingPickupData,
        getPickupData,
        pickupData,
        setPickupData,
        classes,

    } = props

    useEffect(() => {
        pickupData.noData && getPickupData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangePageConcierge = (event, newPage) => {
        setPickupData(prev => {
            return {
                ...prev,
                page: newPage
            }
        })
    };

    const handleChangeRowsPerPageConcierge = (event) => {
        setPickupData(prev => {
            return {
                ...prev,
                page: 0,
                rowsPerPage: event.target.value
            }
        })
    };
    const { details: paymentPickupsDetails } = usePaymentPickupsDetails({
        type: paymentType,
        typeData: paymentsData
    });
    return <Paper
        className={clsx(
            classes.table,
            classes.paper
        )}
    >

        {!loadingPickupData && pickupData.total !== 0 && (
            <TableFixedHeaderWraper>
                <ShipmentsTable
                    data={pickupData.pickedPickup}
                    headCells={paymentPickupsDetails.tableHead}
                    parseBodyCell={paymentPickupsDetails.tableBody}
                    total={paymentPickupsDetails.tableTotal}
                    sumRow={paymentPickupsDetails?.sum}
                    sumTableNumber={paymentPickupsDetails?.sumTableNumber}
                />
            </TableFixedHeaderWraper>
        )}
        <Grid container justifyContent="center" alignItems="center">
            {loadingPickupData ?
                <FullScreenLoading height={"327px"} />
                : pickupData.total === 0 && (
                    <EmptyTableMessage loading={false} />
                )}
        </Grid>
        {pickupData.total !== 0 &&
            <MUITablePagination
                count={pickupData.total}
                rowsPerPage={pickupData.rowsPerPage}
                page={pickupData.page}
                onPageChange={handleChangePageConcierge}
                onRowsPerPageChange={handleChangeRowsPerPageConcierge}
            />}


    </Paper>
}


export default Tabs