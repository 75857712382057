
import * as gqlb from "gql-query-builder";
import { gql, useQuery } from "@apollo/client";
import { styled } from '@mui/material/styles';
import {
  Paper,
  TableRow,
} from "@mui/material";
import { DeleteOutline, Edit } from "@mui/icons-material";
import clsx from "clsx";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { Can } from "../HOC/CustomComponents/Secured";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import { DELETE_PICKUP_MUTATION, PICKUP_VIEW } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import { GetPaymentPermissionSlug } from "../../helpers/getPermissionSlug";
import { Globals } from "../HOC/Classes/Globals";
import formatMoney from "../../helpers/numbersDot";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";

const PREFIX = 'PickupView';

const classes = {
  paper: `${PREFIX}-paper`,
  main: `${PREFIX}-main`,
  table: `${PREFIX}-table`,
};

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.table}`]: {
    display: "grid",
    padding: 0,
    marginTop: theme.spacing(2),
    borderRadius: 0,
    width: "100%",
  },
}));

const formatTime = (time) => moment(time, "hh:mm").format("hh:mm A");

const PickupView = (props) => {

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const pickupId = props.match.params.id?.trim();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const SETTINGS = gqlb.query({
    operation: "shippingSettings",
    fields: ["pickupAcceptance"],
    variables: {},
  });

  const { data: settings } = useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        // if (settingsPermission) {
        //   if (!urlUpdateShipmentId) {

        //     setAutocompleteValues((prev) => ({
        //       ...prev,
        //       transactionType: data.shippingSettings.defaultTransactionType,
        //     }));
        //   }
        // }
      },
    }
  );
  console.log(settings);
  const paymentPermission = Globals.user.hasPermission(GetPaymentPermissionSlug('dlvby', 'list'))
  const pickupQueryBody = PICKUP_VIEW(paymentPermission);

  const { loading, data } = useQuery(
    gql`
      ${pickupQueryBody.query}
    `,

    {
      skip: !pickupId,
      variables: { id: parseInt(pickupId) },
      fetchPolicy: "no-cache",
    }
  );

  const pickup = data?.pickup;
  const shipmentTableHead = [
    "requestCode",
    "recipient",
    "destination",
    "recipientAddress",
    "description",
    "pieceCount",
    "value",
    "returningDueFees",
    "totalCost",
    "",
  ];

  const total = {
    piecesCount: 0,
    amount: 0,
    allDueFees: 0,
    totalAmount: 0,
  };

  const sumTableNumber = (shipment, tableTotal) => {
    Object.entries(tableTotal).forEach(([k, v]) => {
      tableTotal[k] += shipment[k] ?? 0;
    });
  };

  const shipmentTableBody = (shipment, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        {shipment.__typename === "Barcode" ? <FixedTableCell>{shipment?.code}</FixedTableCell>
          : <CellLink pathname={`/admin/shipments/${shipment.id}`}>
            {shipment.code}
          </CellLink>}
        <FixedTableCell>{shipment?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipment?.recipientZone?.id}`}
          zone={shipment?.recipientZone?.name}
          subzone={shipment?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipment?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipment?.description}</FixedTableCell>
        <FixedTableCell>{shipment?.piecesCount}</FixedTableCell>
        <FixedTableCell>{formatMoney(shipment?.amount)}</FixedTableCell>
        <FixedTableCell>{formatMoney(shipment?.allDueFees)}</FixedTableCell>
        <FixedTableCell>{formatMoney(shipment?.totalAmount)}</FixedTableCell>
        <FixedTableCell> </FixedTableCell>
      </TableRow>
    );
  };

  const editURL = () => {
    pushUrl(props, `/admin/pickups/${pickupId}/edit`);
  }
  const [openDelete, setOpenDelete] = useState(false);
  const openDeleteDialog = () => {
    setOpenDelete(true)
  };
  const icons = [
    {
      id: "delete",
      title: "delete",
      action: openDeleteDialog,
      icon: DeleteOutline,
      permission: Boolean(pickup?.deletable),
    },
    {
      id: "edit",
      title: "edit",
      action: editURL,
      icon: Edit,
      permission: Boolean(pickup?.editable),
    },
  ]

  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading > : !pickup ? (
      <NotFound />
    ) : (
      <Root>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          className={classes.mainTracking}
          sx={{ margin: "0", width: "100%" }}
        >
          <Fragment>
            <MutationWithDialog
              mutaion={DELETE_PICKUP_MUTATION.query}
              setOpenDelete={setOpenDelete}
              openDelete={openDelete}
              dialogTitle={t("deletePickup")}
              dialogContent={t("deletePickupMessage")}
              mutaionProps={{ variables: { id: parseInt(pickupId) } }}
              onCompleted={() => pushUrl(props, `/admin/pickups`)}
              onCompleteMessage={t("deleteMessage")}
            />
            <TitleAppBar path={props.match.path}>
              <LongMenu icons={icons} />
            </TitleAppBar>
          </Fragment>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ margin: "16px 0 0 0", width: "100%" }}
          >
            <Grid
              container
              spacing={3}
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <Grid container justifyContent="center">
                <Paper container className={classes.paper} component={Grid}>
                  <KeyValuePair
                    title={t("requestCode")}
                    value={pickup?.code}
                  />
                  <KeyValuePair title={t("theDate")} value={pickup?.date} />
                  <KeyValuePair
                    title={t("branch")}
                    value={
                      pickup?.branch ? (
                        <SpanLink
                          pathname={`/admin/branches/${pickup?.branch?.id}`}
                        >
                          {pickup?.branch?.name}
                        </SpanLink>
                      ) : (
                        "--"
                      )
                    }
                  />
                  <KeyValuePair
                    title={t("fromHour")}
                    value={
                      pickup?.timeFrom ? formatTime(pickup?.timeFrom) : null
                    }
                  />
                  <KeyValuePair
                    title={t("toHour")}
                    value={pickup?.timeTo ? formatTime(pickup?.timeTo) : null}
                  />
                  <KeyValuePair
                    title={t("shippingAgent")}
                    value={
                      pickup?.deliveryAgent ? (
                        <SpanLink
                          pathname={`/admin/delivery-agents/${pickup?.deliveryAgent?.id}`}
                        >
                          {pickup?.deliveryAgent?.name}
                        </SpanLink>
                      ) : (
                        "--"
                      )
                    }
                  />

                  <KeyValuePair
                    title={t("transactionTypeName")}
                    value={
                      pickup?.transactionType ? (
                        <SpanLink
                          pathname={`/admin/transaction-types/${pickup?.transactionType?.id}`}
                        >
                          {pickup?.transactionType?.name}
                        </SpanLink>
                      ) : (
                        "--"
                      )
                    }
                  />
                  <KeyValuePair
                    title={t("customer")}
                    value={
                      pickup?.customer ? (
                        <SpanLink
                          pathname={`/admin/customers/${pickup?.customer?.id}`}
                        >
                          {pickup?.customer?.name}
                        </SpanLink>
                      ) : (
                        "--"
                      )
                    }
                  />

                  <KeyValuePair
                    title={t("source")}
                    value={
                      pickup?.customer?.zone ? (
                        <SpanLink
                          pathname={`/admin/zones/${pickup?.customer?.zone?.id}`}
                        >
                          {pickup?.customer?.zone?.name +
                            " - " +
                            pickup?.customer?.subzone?.name}
                        </SpanLink>
                      ) : (
                        "--"
                      )
                    }
                  />

                  <KeyValuePair
                    title={t("address")}
                    value={pickup?.customer?.address}
                  />

                  <KeyValuePair
                    title={t("expectedShipments")}
                    value={pickup?.shipmentsCount}
                  />
                  <KeyValuePair title={t("notes")} value={pickup?.notes} />
                  <KeyValuePair
                    title={t("deliveryAgentNotes")}
                    value={pickup?.deliveryAgentNotes}
                  />
                  <KeyValuePair
                    title={t("vehicle")}
                    value={pickup?.vehicle?.name}
                  />
                  <KeyValuePair
                    title={t("pickedShipments")}
                    value={pickup?.pickedCount}
                  />
                  <KeyValuePair
                    title={t("status")}
                    value={pickup?.status?.name}
                  />
                  <Can
                    permission={settings?.shippingSettings?.pickupAcceptance}
                    showException
                  >
                    <KeyValuePair
                      title={t("deliveryAgentAccepted")}
                      value={pickup?.acceptance?.name}
                    />
                  </Can>
                  <Can
                    permission={GetPaymentPermissionSlug('dlvby', 'list')}
                    showException
                  >
                    <KeyValuePair
                      title={t("payment")}
                      value={pickup?.payment ? (
                        <SpanLink
                          pathname={`/admin/payments/${pickup?.payment?.id}`}
                        >
                          {pickup?.payment?.code}
                        </SpanLink>
                      ) : (
                        "--"
                      )}
                    />
                  </Can>
                  <Can
                    permission="shipping.shipment.choose_customer"
                    showException
                  >
                    <KeyValuePair
                      title={t("createdBy")}
                      value={
                        pickup?.createdBy ? (
                          <SpanLink
                            pathname={`/admin/users/${pickup?.createdBy?.id}`}
                          >
                            {pickup?.createdBy?.username}
                          </SpanLink>
                        ) : (
                          "--"
                        )
                      }
                    />
                    <KeyValuePair
                      title={t("updatedBy")}
                      value={
                        pickup?.updatedBy ? (
                          <SpanLink
                            pathname={`/admin/users/${pickup?.updatedBy?.id}`}
                          >
                            {pickup?.updatedBy?.username}
                          </SpanLink>
                        ) : (
                          "--"
                        )
                      }
                    />
                  </Can>
                  <KeyValuePair title={t("createdAt")} value={pickup?.createdAt} />

                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {pickup?.entries.length > 0 && (
          <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
            {/* *******Table******* */}
            <Grid container sx={{ width: "100%" }}>
              <Paper className={clsx(classes.table)}>
                <EnhancedTableToolbar
                  {...props}
                  selectedShipment={pickup?.entries}
                />
                <TableFixedHeaderWraper>
                  <ShipmentsTable
                    page={page}
                    rowsPerPage={rowsPerPage}
                    data={pickup?.entries}
                    headCells={shipmentTableHead}
                    parseBodyCell={shipmentTableBody}
                    total={total}
                    sumTableNumber={sumTableNumber}
                  />
                </TableFixedHeaderWraper>
                {pickup?.entries.length > 4 && (
                  <MUITablePagination
                    // classeName={classes.pagination}
                    count={pickup?.entries.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
      </Root>
    );
};

export default PickupView;
