import React from 'react'
import PropTypes from "prop-types";
import { FixedTableCell } from '../HOC/CustomComponents/FixedTableCell';
import { Typography } from '@mui/material';
import formatMoney from '../../helpers/numbersDot';
import { getShipmentsSumQuery, shipmentFieldsFun } from './listCollectionFields';


const GenerateShipmentSumCell = (props) => {
    const { shipment, elementKey, type ,permission} = props
    const field = shipmentFieldsFun(type,permission).find((e) => e.key === elementKey)
    const fieldKey = getShipmentsSumQuery(shipment, field?.key)
    if (!fieldKey?.value && fieldKey?.value !== 0) {
        return <FixedTableCell> </FixedTableCell>
    }
    else if (fieldKey?.value === "noVal") {
        return null
    }
    else {
        return (
                <FixedTableCell><Typography color="inherit" variant="h6" >  
                {formatMoney(fieldKey?.value?.toFixed(2)) ?? "--"}
           </Typography> </FixedTableCell>
        )
    }
}

GenerateShipmentSumCell.prototype = {
    shipmentSum: PropTypes.object.isRequired,
}

export default GenerateShipmentSumCell