import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import { Add, Delete, Edit } from "@mui/icons-material";
import clsx from "clsx";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";

const PREFIX = "ServiceTable";

const classes = {
  p: `${PREFIX}-p`,
  tap: `${PREFIX}-tap`,
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  destinations: `${PREFIX}-destinations`,
  iconColor: `${PREFIX}-iconColor`,
  deleteIcon: `${PREFIX}-deleteIcon`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.p}`]: {
    fontSize: "14px",
    margin: "2px 1px",
    width: 100,
  },

  [`& .${classes.tap}`]: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    // flexDirection: "column",
    textTransform: "capitalize",
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.destinations}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.deleteIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const ServiceTable = (props) => {
  const { services = [], addService, setServiceList, title } = props;
  const { t } = useTranslation();
  const [serviceIndex, setServiceIndex] = useState(0);
  const [servicePage, serServicePage] = useState(0);
  const [serviceRowsPerPage, setServiceRowsPerPage] = useState(20);

  const handleTreasurersPage = (event, newPage) => {
    serServicePage(newPage);
  };
  const handleTreasurersRowsPerPage = (event) => {
    setServiceRowsPerPage(parseInt(event.target.value));
    serServicePage(0);
  };
  return (

    <StyledGrid item xs={12} alignItems="flex-start" component={Paper} >
      <Toolbar className={classes.toolbar} variant="dense">
        <Typography className={classes.title} color="inherit" variant="h6">
          {t(`${title}`)}
        </Typography>

        {addService ? (
          <IconButton
            color="primary"
            onClick={() => {
              addService();
              setServiceIndex(0);
            }}
            aria-label="Add title"
            size="large"
          >
            <Add />
          </IconButton>
        ) : null}
      </Toolbar>
      <Grid container item className={classes.table}>
        {services.length <= 0 ? (
          <EmptyTableMessage loading={false} message={t(`${title}`)} />
        ) : (
          <TableFixedHeaderWraper className={classes.tableContainer}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t(`${title}`)}</FixedTableCell>
                  <FixedTableCell>{t("remoteId")}</FixedTableCell>
                  {addService ? <FixedTableCell></FixedTableCell> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {services.length > 0 &&
                  (serviceRowsPerPage > 0
                    ? services.slice(
                      servicePage * serviceRowsPerPage,
                      servicePage * serviceRowsPerPage +
                      serviceRowsPerPage
                    )
                    : services
                  ).map((row, index) => (
                    <TableRow
                      className={clsx({
                        [classes.focus]:
                          servicePage * serviceRowsPerPage + index ===
                          serviceIndex,
                      })}
                      hover
                      key={index}
                      onClick={() => {
                        serServicePage(0);
                        setServiceIndex(
                          servicePage * serviceRowsPerPage + index
                        );
                      }}
                    >
                      <FixedTableCell>
                        {row?.service?.name ?? row.service?.username}
                      </FixedTableCell>
                      <FixedTableCell>
                        {row.remoteId}
                      </FixedTableCell>
                      {addService ? (
                        <FixedTableCell>
                          <IconButton
                            size="small"
                            onClick={() =>
                              addService(
                                servicePage * serviceRowsPerPage + index
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            size="small"
                            className={classes.deleteIcon}
                            onClick={() =>
                              setServiceList((prev) => {
                                const filterd = prev.filter(
                                  (i, deleteIndex) => deleteIndex !== index
                                );
                                return filterd;
                              })
                            }
                          >
                            <Delete />
                          </IconButton>
                        </FixedTableCell>
                      ) : null}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
        )}
        <MUITablePagination
          count={services?.length ?? 0}
          rowsPerPage={serviceRowsPerPage}
          page={servicePage}
          onPageChange={handleTreasurersPage}
          onRowsPerPageChange={handleTreasurersRowsPerPage}
        />
      </Grid>
    </StyledGrid>

  );
};

export default ServiceTable;
