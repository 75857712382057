import { IconButton, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material"; import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { DELETE_COLLECTION_ENTRIES_MUTATION } from "./CollectionListQuary";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import Collapse from "@mui/material/Collapse";
import GenerateShipmentSumCell from "./GenerateShipmentSumCell";
import GenerateShipmentCell from "./GenerateShipmentCell";
import { getShipmentsTableHeader, initialData } from "./listCollectionFields";
const PREFIX = "collectionDetails";
const classes = {
  barcode: `${PREFIX}-barcode`,

};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 32,
  },

}));

const useCollectionsDetails = ({
  type,
  approved,
  typeData,
  setShipmentData: removeShipment,
  refetchShipmentDataQuery,
  print
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteCollectionEntries, { loading: deleteCollectionEntriesLoading }] = useMutation(
    gql`
      ${DELETE_COLLECTION_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const deleteShipment = (shipment) => (
    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={!approved || shipment.shipment?.pendingDelete}
        onClick={() => {
          removeShipment(prev => {
            const shipments = prev.pickedShipment.map(e => ({
              ...e,
              shipment: {
                ...e.shipment,
                pendingDelete: e.shipment.id === shipment.shipment.id ? true : e.shipment.pendingDelete
              }
            }));
            return {
              ...prev,
              pickedShipment: shipments
            }
          })
          deleteCollectionEntries({
            variables: {
              input: {
                collectionId: typeData.id,
                shipmentIds: [shipment.shipment.id]
              }
            }
          }).then(() => {
            refetchShipmentDataQuery()
            enqueueSnackbar(t("successfullyDeletedRecord"), {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          }).catch((error) => {
            enqueueSnackbar(error.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          })
        }}
      >
        {deleteCollectionEntriesLoading && shipment.shipment?.pendingDelete ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );
  const localDataKey = localStorage.getItem(`shipmentcollection${type}`) ? JSON.parse(localStorage.getItem(`shipmentcollection${type}`)) : initialData[type]

  const autoPayCommission_DLVBY = typeData?.deliveryAgent?.autoPayCommission

  const tableBodyCell = (collection, index, shipmentNumber) => {
    // let table = tableBody(localDataKey, collection, autoPayCommission_DLVBY)
    return (
      <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{shipmentNumber ? shipmentNumber(index) : (index + 1)}</FixedTableCell>
        {localDataKey && localDataKey.map((i, index) =>
          <Fragment key={index}>
            <GenerateShipmentCell shipment={collection} elementKey={i} classes={classes} type={type} permission={autoPayCommission_DLVBY} print={print} />
          </Fragment>
        )}
        {removeShipment && deleteShipment(collection)}
      </StyledTableRow>
    );
  };

  const tableHeadCell = (permission) => {
    if (localDataKey) {
      let table = getShipmentsTableHeader(localDataKey, type, permission)
      const removed = removeShipment ? "" : null;
      return ["#", ...table, removed]
    }
  }

  const tableHeadCellArr = tableHeadCell(autoPayCommission_DLVBY)

  const tableSum = localDataKey && <TableRow>
    <FixedTableCell> </FixedTableCell>

    {localDataKey && localDataKey.map((i, index) =>
      <Fragment key={index}>
        {typeData && <GenerateShipmentSumCell
          shipment={typeData} elementKey={i} type={type} permission={autoPayCommission_DLVBY}
        />}
      </Fragment>
    )}
    {removeShipment && <FixedTableCell> </FixedTableCell>}

  </TableRow >


  /* --------------------------------- Manifests Object --------------------------------- */

  const collectionObject = {
    CUSTM: {
      id: "1",
      customer: true,
      label: t("manifestDetails"),
      tableHead: tableHeadCellArr,
      tableBody: tableBodyCell,
      tableTotal: tableSum,
    },
    DLVBY: {
      id: "2",
      customer: true,
      label: t("manifestDetails"),
      tableHead: tableHeadCellArr,
      tableBody: tableBodyCell,
      tableTotal: tableSum,
    },
  };

  return {
    details: collectionObject[type],
  };
};

export default useCollectionsDetails;
