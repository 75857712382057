import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import { useTranslation } from "react-i18next";
import {
  Box,
  Collapse,
  Typography,
  DialogTitle,
  DialogContent,
  Badge
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { gql, useMutation } from '@apollo/client';
import { SAVE_ALERT } from './Graphql';
import { useForm } from 'react-hook-form';
import { styled } from "@mui/material/styles";
import { Clear, Done } from '@mui/icons-material';
import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import { useSnackbar } from 'notistack';
import { setValidationError } from '../HOC/CustomFunctions/setValidationError';
import { LIST_CUSTOMERS_DROPDOWN, LIST_DELIVERY_AGENTS_DROPDOWN, LIST_LOOKUP_ENTRIES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import UploadFile from "../HOC/MUI/UploadFile";
import config from "../../config.json";
import CustomButton from "../HOC/MUI/CustomButton";
import { useTheme } from "@mui/system";
import SendIcon from '@mui/icons-material/Send';

const PREFIX = "AdminAlert";

const classes = {
  mainGrid: `${PREFIX}-mainGrid`,
  button: `${PREFIX}-button`,
  saveButton: `${PREFIX}-saveButton`,
};

const Root = styled("div")(({ theme }) => ({

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  [`& .${classes.saveButton}`]: {
    width: "100%"
  }

}));

export default function AdminAlertForm(props) {
  const theme = useTheme();
  const { alertObject } = props;
  const disable = alertObject?.sent
  const {
    handleSubmit,
    setValue,
    control,
    setError,
    errors,
    watch
  } = useForm({
    defaultValues: {
      ...(!alertObject && { sent: true })
    },
  });

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [autocompleteValues, setAutocompleteValues] = useState({
    channels: [],
    recipients: [],
    typeCode: null,
    recipientType: null,
    images: []
  });

  useEffect(() => {
    if (alertObject) {
      const alertParams = [
        "id",
        "sent",
        "subject",
        "message",
      ];

      alertParams.forEach((i) => {
        alertObject[i] && setValue(i, alertObject[i]);
      });
      const channelsIds = []
      const pathIds = []
      const recipientIds = []
      alertObject.channels.map(ele => channelsIds.push(ele.code))
      alertObject.recipients.map(ele => recipientIds.push(ele.id))

      alertObject.images.map(ele => pathIds.push(ele.path))
      setAutocompleteValues((prev) => ({
        ...prev,
        typeCode: alertObject?.type,
        recipientType: alertObject?.recipientType,
        channels: channelsIds,
        recipients: recipientIds,
        images: pathIds
      }));
    }
  }, [alertObject, setValue])

  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };

  const [saveAlert, { loading: saveAlertLoading }] = useMutation(
    gql`
      ${SAVE_ALERT.query}
    `, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setAutocompleteValues((prev) => ({
        ...prev,
      }));
    },
  }
  );

  const [previewImage, setPreviewImage] = useState(null);
  const onSubmit = (data, e) => {
    const action = e.nativeEvent.submitter.name;
    let sent = false;
    if (action === "saveAndSend") {
      sent = true;
    }

    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }

      if (key === "recipientIds") {
        data[key] = data[key] === null ? [] : data[key];
      }

      if (key === "fileName") {
        delete data[key];
      }
    }

    if (autocompleteValues.images[0]) {
      delete data["images"];
    }

    if (data["images"] === null) {
      data["images"] = [];
    }

    const image = data?.images?.[0];

    saveAlert({
      variables: {
        input: {
          ...data,
          sent,
          ...(image && {
            images: [{
              file: image
            }
            ]
          }),
        },
      },
    }).then((data) => {
      props.handleClose()
      props.refetch()
      enqueueSnackbar(t("saveSuccessful"), {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
    }).catch(({ graphQLErrors }) => {
      setValidationError(graphQLErrors, setError);
      console.log(graphQLErrors);
    });
  };

  const selectDefaultValue = (data) => {
    return data;
  };

  const setClear = () => {
    setAutocompleteValues((prev) => ({
      ...prev,
      images: []
    }));
    setPreviewImage(null);
    setValue("fileName", "")
    setValue("images", "")
  }

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      maxWidth={'xs'}
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid container justifyContent="space-between" xs={12}>
          <Typography variant="h6" color={"text.primary"}>
            {alertObject ? t("updateAlert") : t("createAlert")}
          </Typography>
          {/* <Box>
            <MuiSwitch
              edge="end"
              name="sent"
              label={t("sent")}
              control={control}
            />
          </Box> */}
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Root>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              justifyContent="flex-start"
              aligns="center"
              className={classes.mainGrid}
              spacing={2}
            >
              <Grid xs={12} sm={12} aligns="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"subject"}
                  label={t("subject")}
                  rules={{ required: t("fieldIsRequired") }}
                  disabled={disable}
                />
              </Grid>
              <Grid xs={12} sm={12} aligns="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"message"}
                  label={t("alertMessage")}
                  rules={{ required: t("fieldIsRequired") }}
                  rows={3}
                  disabled={disable}
                />
              </Grid>
              <Grid xs={12} sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  valueKey="code"
                  control={control}
                  errors={errors}
                  rules={{ required: t("fieldIsRequired") }}
                  name={"recipientTypeCode"}
                  label={t("recipientType")}
                  parseData={(data) => selectDefaultValue(data)}
                  variables={{ input: { code: "SHP_ALERTS_RECIPIENT_TYPE" } }}
                  onChangeValue={(e) => {
                    setAutocompleteValues((prev) => ({
                      ...prev,
                      recipients: [],
                    }));
                  }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.recipientType}
                  disabled={disable}
                />
              </Grid>
              {watch("recipientTypeCode") === "CUSTOMER" && <Grid xs={12} sm={12} aligns="flex-start">
                <MultipleAutocomplete
                  valueKey="id"
                  limitTags={100}
                  multiple
                  // hideCode={true}
                  control={control}
                  showCode={true}
                  errors={errors}
                  name={"recipientIds"}
                  label={t("customer")}
                  parseData={(data) => selectDefaultValue(data)}
                  query={LIST_CUSTOMERS_DROPDOWN.query}
                  defaultValue={autocompleteValues.recipients}
                  disabled={disable}
                  variables={{
                    input: {
                      active: true,
                    },
                  }}
                />
              </Grid>}
              {watch("recipientTypeCode") === "DELIVERY_AGENT" && <Grid xs={12} sm={12} aligns="flex-start">
                <MultipleAutocomplete
                  valueKey="id"
                  limitTags={100}
                  showCode={true}
                  multiple
                  // hideCode={true}
                  control={control}
                  errors={errors}
                  name={"recipientIds"}
                  label={t("deliveryAgent")}
                  parseData={(data) => selectDefaultValue(data)}
                  query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                  defaultValue={autocompleteValues.recipients}
                  disabled={disable}
                />
              </Grid>}
              <Grid xs={12} sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  valueKey="code"
                  control={control}
                  errors={errors}
                  rules={{ required: t("fieldIsRequired") }}
                  name={"typeCode"}
                  label={t("type")}
                  parseData={(data) => selectDefaultValue(data)}
                  variables={{ input: { code: "SHP_ALERTS_TYPE" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.typeCode}
                  disabled={disable}
                />
              </Grid>
              <Grid xs={12} sm={12} alignItems="flex-start">
                <MultipleAutocomplete
                  valueKey="code"
                  limitTags={100}
                  multiple
                  hideCode={true}
                  control={control}
                  errors={errors}
                  rules={{ required: t("fieldIsRequired") }}
                  name={"channels"}
                  label={t("channels")}
                  parseData={(data) => selectDefaultValue(data)}
                  variables={{
                    input: {
                      code: "SHP_ALERT_CHANNELS"
                    }
                  }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.channels}
                  disabled={disable}
                />
              </Grid>
              <Grid xs={12} sm={autocompleteValues.images[0] || previewImage ? 8 : 12} alignItems="flex-start">
                <UploadFile
                  control={control}
                  setValue={setValue}
                  name="images"
                  icon={"add_photo_alternate"}
                  label={t("uploadImage")}
                  accept=".png,.jpg"
                  disabled={disable}
                  iconDisable={disable}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      // setSelectedImage(file);
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setPreviewImage(reader.result);
                      };
                      reader.readAsDataURL(file);
                    } else {
                      // setSelectedImage(null);
                      setPreviewImage(null);
                    }
                    setAutocompleteValues((prev) => ({
                      ...prev,
                      images: []
                    }));
                  }}
                />
              </Grid>

              {(autocompleteValues.images[0] || previewImage) && <Grid xs={12} sm={4} textAlign="center">
                {previewImage && (
                  <Badge
                    onClick={setClear}
                    badgeContent={<Clear sx={{ fontSize: "10px" }} />}
                    color="error"
                  >
                    <img src={previewImage} alt="Preview" style={{ width: "60px", height: "60px", borderRadius: "1%" }}   onClick={(event) => event.stopPropagation()} /></Badge>
                )}

                {autocompleteValues.images[0] && <Badge
                  onClick={() => { !disable && setClear() }}
                  badgeContent={<Clear sx={{ fontSize: "10px" }} />}
                  color="error"
                  invisible={disable}
                >
                  <Box
                    sx={{ width: "60px", height: "60px", borderRadius: "1%" }}
                    component="img" alt="signature"
                    src={getBackendUri(autocompleteValues.images[0])}
                    onClick={(event) => event.stopPropagation()}
                  />
                </Badge>}
              </Grid>}
              {!disable &&
                <Grid container justifyContent="space-around" className={classes.button} xs={12}>
                  <Grid item xs={12} md={6}>
                    <CustomButton
                      customcolor={theme.palette.success.main}
                      type="submit"
                      name="save"
                      className={classes.saveButton}
                      variant="contained"
                      size="medium"
                      loading={saveAlertLoading}
                      disabled={saveAlertLoading}
                      startIcon={!saveAlertLoading && <Done />}
                    >
                      {!saveAlertLoading && t("save")}
                    </CustomButton>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomButton
                      className={classes.saveButton}
                      customcolor={theme.palette.primary.main}
                      type="submit"
                      name="saveAndSend"
                      variant="contained"
                      size="medium"
                      loading={saveAlertLoading}
                      disabled={saveAlertLoading}
                      startIcon={!saveAlertLoading && <SendIcon />}
                    >
                      {!saveAlertLoading && t("saveAndSend")}
                    </CustomButton>
                  </Grid>
                </Grid>
              }
            </Grid>
          </form>
        </Root>
      </DialogContent>
    </Dialog>
  );
}