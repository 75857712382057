import React, { useState } from "react";
import "./style.css";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import img from "../images/contact1.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { LiaHandPointer } from "react-icons/lia";
import { Typography, TextField, Button, Container, } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

const PREFIX = "ContactSection";

const classes = {
  header: `${PREFIX}-header`,
  span: `${PREFIX}-span`,
  contactIcon: `${PREFIX}-contactIcon`,
  active: `${PREFIX}-active`,
  slideImg: `${PREFIX}-slideImg`,
  contactImg: `${PREFIX}-contactImg`,
  icon: `${PREFIX}-icon`,
  link: `${PREFIX}-link`,
  title: `${PREFIX}-title`,
};

const Root = styled("div")(({ theme }) => ({
  background: theme.palette.background.paper,
  [`& .${classes.header}`]: {
    "&::before": {
      content: "' '",
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "50px",
      height: "5px",
      background: theme.palette.primary.main,
    },
    color: theme.palette.text.primary
  },

  [`& .${classes.span}`]: {
    display: "block",
    paddingLeft: "30px",
    paddingBottom: "20px",
    position: "relative",
    "&::before": {
      content: "' '",
      position: "absolute",
      left: 0,
      top: "5px",
      width: "15px",
      height: "15px",
      background: theme.palette.primary.main,
    },
  },

  [`& .${classes.title}`]: {
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  [`& .${classes.contactIcon}`]: {
    width: "40px",
    color: theme.palette.primary.main,
  },
  [`& .${classes.slideImg}`]: {
    display: "none",
  },
  [`& .${classes.active}`]: {
    display: "block",
  },
  [`& .${classes.contactImg}`]: {
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    margin: "auto",
    width: " 80%",
  },
  [`& .${classes.icon}`]: {
    fontFamily: "Material Icons Outlined",
    fontSize: 35,
    verticalAlign: "middle",
    color: "white",
  },
  [`& .${classes.link}`]: {
    background: theme.palette.primary.main,
    width: "70px",
    height: " 70px",
    border: "6px solid rgba(255, 255, 255, 0.8)",
    borderRadius: "50%",
    display: " flex",
    alignItems: " center",
    justifyContent: "center",
  },
}));

const ContactSection = () => {

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  // المستحق للتحصيل
  return (
    <Root id="contact" className="wpo-contact-area">
      <Container maxWidth="lg">
        <Grid xs={12} container spacing={2}>
          <Grid xs={12} className="contact-section-title" sx={{ textAlign: "center" }}>
            <Typography variant="h2" color={"text.primary"} mb={6}>Contact us </Typography>
          </Grid>
          <Grid container xs={12} lg={12}>
            <Grid xs={12} lg={6}>
              <div className="wpo-contact-img">
                <div className={clsx(classes.contactImg, "wpo-contact-img")}>
                  <img src={img} alt="contact img" />
                </div>
                <Grid container>
                  <Grid xs={6} sx={{ textAlign: "center" }} >
                    <h4 className={classes.title}>Location</h4>
                    <div className="wpo-contact-icon">
                      <LocationOnOutlinedIcon
                        className={clsx(classes.contactIcon)}
                      ></LocationOnOutlinedIcon>
                      <Typography variant="body1" color="text.primary">
                        Al Khoud 6, Muscat, Oman <br></br> PC: 316, PO: 693
                      </Typography>
                      <Typography variant="body1" color="text.primary">
                        Mobile +96872329044
                      </Typography>
                      <Typography variant="body1" color="text.primary">
                        Mobile +96872329088
                      </Typography>
                      <Typography variant="body1" color="text.primary">
                        Email wd.info@wasl.om
                      </Typography>
                    </div>
                  </Grid>
                  <Grid xs={6} sx={{ textAlign: "center" }} >
                    <h4 className={classes.title} >Working Hours</h4>
                    <AccessTimeOutlinedIcon
                      className={clsx(classes.contactIcon)}
                    ></AccessTimeOutlinedIcon>
                    <Typography variant="body1" color="text.primary">
                      Sunday To Thurusday
                    </Typography>
                    <Typography variant="body1" color="text.primary">
                      from 9am to 3pm
                    </Typography>
                    <Typography variant="body1" color="text.primary">
                      from 4pm to 9pm
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid xs={12} lg={6}>
              <div className="wpo-contact-content" >
                <h2 className={classes.header}>
                  Get to know more about our Company!
                </h2>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField sx={{ zIndex: 0 }}
                        fullWidth
                        label="Name"
                        name="name"
                        variant="outlined"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField sx={{ zIndex: 0 }}
                        fullWidth
                        label="Phone"
                        name="phone"
                        variant="outlined"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField sx={{ zIndex: 0 }}
                        fullWidth
                        label="Message"
                        name="message"
                        multiline
                        rows={4}
                        variant="outlined"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" color="primary">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
          </Grid>
          <Grid xs={12} sx={{ textAlign: "center" }} >

            <h3 className={classes.title}> How can we help you?</h3>
            <div className="link-container">
              <Grid>
                <div className="wpo-service-itemm">
                  <div className="wpo-service-iconn">
                    <a
                      className={classes.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://accuratess.com"
                    >
                      <LiaHandPointer className={classes.icon} />
                    </a>
                  </div>
                </div>
              </Grid>
              <Grid>
                <div className="wpo-service-itemm">
                  <div className="wpo-service-iconn">
                    <a
                      className={classes.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://accuratess.com"
                    >
                      <LiaHandPointer className={classes.icon} />
                    </a>
                  </div>
                </div>
              </Grid>
              <Grid>
                <div className="wpo-service-itemm">
                  <div className="wpo-service-iconn">
                    <a
                      className={classes.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://accuratess.com"
                    >
                      <LiaHandPointer className={classes.icon} />
                    </a>
                  </div>
                </div>
              </Grid>
              <Grid>
                <div className="wpo-service-itemm">
                  <div className="wpo-service-iconn">
                    <a
                      className={classes.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://accuratess.com"
                    >
                      <LiaHandPointer className={classes.icon} />
                    </a>
                  </div>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
};

export default ContactSection;
