import * as gqlb from "gql-query-builder";

export const LIST_TEAMS = gqlb.query({
  operation: "listTeams",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "lastPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "name",
        {
          members: [
            "username",
            "id"
          ]
        },
        {
          categories: [
            "id",
            "name"
          ]
        }
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListTeamsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SUPPORT_CATEGORIES_ID = gqlb.query({
  operation: "supportCategory",
  fields: [
    "id",
    "name",
    "active",
    "code",
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_TEAM = gqlb.mutation({
  operation: "saveTeam",
  fields: ["id"],
  variables: {
    input: {
      type: "TeamInput",
      required: true,
    },
  },
});

export const DELETE_TEAM = gqlb.mutation({
  operation: "deleteTeam",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});