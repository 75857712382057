import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  TableBody,
  TableRow,
  Icon,
  Button,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Add, FilterList, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { styled } from "@mui/material/styles";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import useWidth from "../../Hooks/useWidth";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { LIST_CANCELLATION_REASONS } from "./Graphql";
import CancellationReasonForm from "./CancellationReasonForm";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";
import { Globals } from "../HOC/Classes/Globals";

const PREFIX = "CancellationReasonsList";

const classes = {
  span: `${PREFIX}-span`,
  iconColor: `${PREFIX}-iconColor`,
  headerTable: `${PREFIX}-headerTable`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.span}`]: {
    display: "inline"
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },
}));

const CancellationReasonsList = (props) => {
  const { t } = useTranslation();
  const [refetchs, setrefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const user = Globals.user;
  const userCanEdit = user.hasPermission("shipping.cancellation_reason.update");
  const [open, setOpen] = React.useState(false);
  const [cancellationReasonObject, setCancellationReasonObject] =
    React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCancellationReasonObject();
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    const url = history.createHref({
      pathname: "/admin/cancellation-reasons",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    (refetchs) ? setrefetch(false) : setrefetch(true)
    let handledData = {
      name: data.name.toString(),
      type: data.type,
      page: 0,
    };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const { data, loading, refetch } = useQuery(
    gql`
      ${LIST_CANCELLATION_REASONS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetchs,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.name && { name: search.name }),
          ...(search.type && { type: search.type }),
        },
      },
    }
  );
  const listCancellationReasons = data?.listCancellationReasons?.data;
  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: () => handleClickOpen(),
      icon: Add,
      permission: "shipping.cancellation_reason.create",
    },
  ]

  return (
    <Root>
      {open && (
        <CancellationReasonForm
          open={open}
          handleClose={handleClose}
          refetch={refetch}
          cancellationReasonObject={cancellationReasonObject}
        />
      )}
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listCancellationReasons?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <LookupDropdown
                  control={control}
                  errors={errors}
                  skip={loading}
                  name={"type"}
                  label={t("type")}
                  variables={{
                    input: { code: "SHP_REASON_TYPE" },
                  }}
                  onCompleted={(data) =>
                    lookupsComplete(data, urlQuery["type"], "type")
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          [
            {
              name: "name"
            },
            {
              name: "code"
            },
            {
              name: "type"
            },
            {
              name: "active"
            },
          ]
        }
        tableBody={
          <TableBody>
            {listCancellationReasons &&
              listCancellationReasons?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {userCanEdit ? <CellLink
                      onClick={() => {
                        handleClickOpen();
                        setCancellationReasonObject(row);
                      }}
                    >
                      {row?.name}
                    </CellLink> : <FixedTableCell>{row?.name}</FixedTableCell>}

                    <FixedTableCell>{row?.code}</FixedTableCell>
                    <FixedTableCell>{row?.type?.name}</FixedTableCell>
                    <FixedTableCell>
                      {row?.active ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listCancellationReasons.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listCancellationReasons ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default CancellationReasonsList;
