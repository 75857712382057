import { createContext, useContext } from "react";

export const ManifestProductsContext = createContext({});

const ManifestProductsProvider = (props) => {
  const { value } = props;
  return (
    <ManifestProductsContext.Provider value={value}>
      {props.children}
    </ManifestProductsContext.Provider>
  );
};

export default ManifestProductsProvider;

export const useManifestProductsContext = () => {
  const manifestProductsContext = useContext(ManifestProductsContext);

  return {
    manifestProductsContext,
  };
};
