import * as gqlb from "gql-query-builder";

export const LIST_WARE_HOUSE_SECTIONS = gqlb.query({
  operation: "listWarehouseSections",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "lastPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "name",
        "active",
        {
          branch: ["id", "name"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListWarehouseSectionFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const WARE_HOUSE_ID = gqlb.query({
  operation: "warehouseSection",
  fields: [
    "id",
    "name",
    "active",
    {
      branch: ["id", "name"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_WARE_HOUSE = gqlb.mutation({
  operation: "saveWarehouseSection",
  fields: ["id"],
  variables: {
    input: {
      type: "WarehouseSectionInput",
      required: true,
    },
  },
});
