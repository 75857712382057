import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { gql, useQuery } from "@apollo/client";
import { Add, Clear, FilterList, Search } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2';
import {
  Button,
  Icon,
  IconButton,
  InputAdornment,
  TableBody,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_BARCODE_BATCH } from "./Graphql";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import MUItextField from "../HOC/MUI/MUItextField";
import MUIselect from "../HOC/MUI/MUIselect";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";

const PREFIX = "GenrateBarcodesList";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterFields: `${PREFIX}-filterFields`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },
}));


const GenrateBarcodesList = (props) => {
  const { t } = useTranslation();
  const urlQuery = urlParameters(window.location.search);
  const [refetch, setrefetch] = useState(true);
  const [filterLoad, setFilterLoad] = useState(true);
  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : null;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : null;

  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
  });

  const screenWidth = useWidth();
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    register,
    watch
  } = useForm();
  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(0);

  const initSearch = () => {
    let searchParams = {
      page: page,
      refetch: true,
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["barcode"]) {
        urlQuery["barcode"] = urlQuery["barcode"].toString().trim();
      }
      searchParams = urlQuery;
      if (searchParams["barcode"])
        searchParams["barcode"] = searchParams["barcode"]
          .split(",")
          .filter((i) => i.trim() !== "")
          .map((i) => i.trim());
    }
    return {
      ...searchParams,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["barcode"] && setValue("barcode", urlQuery["barcode"].toString());
    urlQuery["approved"] !== undefined && setValue("approved", urlQuery["approved"]);
    // setAutocompleteValues((prev) => ({
    //   ...prev,
    //   ...(urlQuery["paymentTypes"] && {
    //     paymentTypes: urlQuery["paymentTypes"],
    //   }),
    // }));
    return () => { };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/barcode-batches",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    (refetch) ? setrefetch(false) : setrefetch(true)

    setFilterLoad(false)
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    let handledData = {
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      barcode: data?.barcode?.toString().trim(),
      branchId: data.branchId,
      customerId: data.customerId,
      approved: data.approved,
      page: 0,
    };
    data["active"] === "" && delete handledData["active"];
    data["approved"] === "" && delete handledData["approved"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null ||
        handledData[key].length === 0
      ) {
        delete handledData[key];
      }
    }
    if (data.barcode) {
      handledData = {
        barcode: data.barcode.toString(),
      };
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
    if (handledData.barcode) {
      handledData.barcode = handledData.barcode
        .split(",")
        .filter((i) => i.trim() !== "")
        .map((i) => i.trim());
    }
  };

  const parseData = (data) => {
    return data;
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const resetPage = () => setPage(0);

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: page,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: page }));
  };

  const resetDate = () => {
    setDateRange([null, null]);
  };

  const { data, loading } = useQuery(
    gql`
      ${LIST_BARCODE_BATCH.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          approved: search.approved,
          ...(search.barcode && { barcode: search.barcode }),
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.customerId && { customerId: search.customerId }),
          ...(search.branchId && {
            branchId: search.branchId,
          }),
        },
      },
      onCompleted: (data) => { },
    }
  );

  const GenrateBarcodesList =
    data?.listBarcodeBatches?.data !== null ? data?.listBarcodeBatches?.data : null;

  const emptyGenrateBarcodesList = data?.listBarcodeBatches?.data?.length === 0;

  const clearCode = () => {
    setValue("barcode", "");
    pushUrlSearch({});
    resetPage();
    setSearch((prev) => ({
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
      refetch: !prev.refetch,
    }));
  };

  const addURL = () => {
    pushUrl(props, "/admin/barcode-batches/create");
  }

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: addURL,
      icon: Add,
      permission: "shipping.barcode_batch.create",
    },
  ]

  const barcodeValidation = Boolean(watch("barcode"));

  return (
    <Root>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={emptyGenrateBarcodesList}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <MUItextField
                  label={t("barcode")}
                  name={"barcode"}
                  inputProps={{
                    dir: "ltr",
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    const input = e.target;
                    const inputValue = e.clipboardData
                      .getData("Text")
                      .replace(/\n/g, ",");

                    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                      window.HTMLInputElement.prototype,
                      "value"
                    ).set;
                    nativeInputValueSetter.call(input, input.value + inputValue);

                    input.dispatchEvent(new Event("input", { bubbles: true }));
                  }}
                  onKeyPress={(e) => {
                    const value = e.target.value.trim();
                    if (value.slice(-1) !== ',' && value !== '') {
                      if (e.key === "Enter") {
                        // e.preventDefault();
                        setValue("barcode", `${value},`);
                      }
                    }
                  }}
                  formType={"requireFalse"}
                  register={register}
                  errors={errors}
                  InputProps={{
                    endAdornment: Boolean(watch("barcode")) && (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={loading}
                          aria-label="code"
                          onClick={clearCode}
                          edge="end"
                          size="large"
                        >
                          <Clear color="error" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  watch={watch("barcode")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start" >
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  resetDate={resetDate}
                  disabled={barcodeValidation}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading&&filterLoad}
                  name={"branchId"}
                  label={t("branch")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                  disabled={barcodeValidation}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  name={"customerId"}
                  label={t("customer")}
                  skip={loading&&filterLoad}
                  control={control}
                  errors={errors}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["customerId"],
                      "customer"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_CUSTOMERS_DROPDOWN.query}
                  defaultValue={autocompleteValues.customer}
                  disabled={barcodeValidation}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"approved"}
                  label={t("approved")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("no"), value: false },
                    { key: t("yes"), value: true },
                  ]}
                  disabled={barcodeValidation}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          [
            {
              name: "date"
            },
            {
              name: "branch"
            },
            {
              name: "customer"
            },
            {
              name: "barcodesCount"
            },
            {
              name: "approved"
            },
          ]
        }
        tableBody={
          <TableBody>
            {GenrateBarcodesList &&
              GenrateBarcodesList?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    <CellLink pathname={`/admin/barcode-batches/${row?.id}`}>
                      {moment(row.date).format("ll")}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/branches/${row?.branch?.id}`}
                    >
                      {row?.branch?.name}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/customers/${row?.customer?.id}`}
                    >
                      {row?.customer?.name}
                    </CellLink>
                    <FixedTableCell>{row?.barcodesCount}</FixedTableCell>
                    <FixedTableCell>
                      {row?.approved ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listBarcodeBatches?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default GenrateBarcodesList;
