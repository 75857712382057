import { gql, useQuery } from "@apollo/client";
import { styled } from '@mui/material/styles';
import {
  Paper,
  Stack,
  TableRow,
} from "@mui/material";
import { DeleteOutline, Edit } from "@mui/icons-material";
import clsx from "clsx";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { Can } from "../HOC/CustomComponents/Secured";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import { DELETE_PICKUP_MUTATION, PICKUP_VIEW } from "./Graphql";
import Grid from "@mui/material/Unstable_Grid2";
import ProductsTable from "../HOC/CustomComponents/ProductsTable";
import { Globals } from "../HOC/Classes/Globals";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";

const PREFIX = 'PickupView';

const classes = {

  paper: `${PREFIX}-paper`,
  main: `${PREFIX}-main`,

  table: `${PREFIX}-table`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.table}`]: {
    display: "grid",
    padding: 0,
    borderRadius: 0,
    width: "100%",
  }
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const formatTime = (time) => moment(time, "hh:mm").format("hh:mm A");

const WarehousePickupView = (props) => {

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const pickupId = props.match.params.id?.trim();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const { loading, data } = useQuery(
    gql`
      ${PICKUP_VIEW.query}
    `,

    {
      skip: !pickupId,
      variables: { id: parseInt(pickupId) },
      fetchPolicy: "no-cache",
    }
  );
  const warehousePickup = data?.warehousePickup;

  const productTableHead = [
    "code",
    "name",
    "quantity",
    ""
  ];

  const total = {
    quantity: 0,
  };

  const user = Globals.user

  const sumTableNumber = (product, tableTotal) => {
    Object.entries(tableTotal).forEach(([k, v]) => {
      tableTotal[k] += product[k] ?? 0;
    });
  };

  const productTableBody = (product, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <CellLink pathname={`/admin/products/${product.product.id}`}>
          {product.product.code}
        </CellLink>
        <FixedTableCell>{product.product.name}</FixedTableCell>
        <FixedTableCell>{product.quantity}</FixedTableCell>
        <FixedTableCell> </FixedTableCell>
      </TableRow>
    );
  };

  const viewIconEdit = warehousePickup?.status.code === 'DON' || warehousePickup?.status.code === 'CXL'

  const [openDelete, setOpenDelete] = useState(false);
  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/warehouse-pickups/${pickupId}/edit`),
      icon: Edit,
      permission: (warehousePickup?.editable || (!viewIconEdit && user.hasPermission("shipping.warehouse_pickup.update_status"))),
    },
    {
      id: "delete",
      title: "delete",
      action: () => setOpenDelete(true),
      icon: DeleteOutline,
      permission: Boolean(warehousePickup?.deletable),
    },
  ]

  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !warehousePickup ? (
      <NotFound />
    ) : (
      <Root>
        <TitleAppBar path={props.match.path}>
          <LongMenu icons={icons} />
          <MutationWithDialog
            mutaion={DELETE_PICKUP_MUTATION.query}
            setOpenDelete={setOpenDelete}
            openDelete={openDelete}
            dialogTitle={t("deletePickup")}
            dialogContent={t("deletePickupMessage")}
            mutaionProps={{ variables: { id: parseInt(pickupId) } }}
            onCompleted={() => pushUrl(props, `/admin/warehouse-pickups`)}
            onCompleteMessage={t("deleteMessage")}
          />
        </TitleAppBar>
        <Stack spacing={2} p={2}>
          <Paper container className={classes.paper} component={Grid}>
            <KeyValuePair
              title={t("requestCode")}
              value={warehousePickup?.code}
            />
            <KeyValuePair title={t("theDate")} value={warehousePickup?.date} />
            <KeyValuePair
              title={t("branch")}
              value={
                warehousePickup?.branch ? (
                  <SpanLink
                    pathname={`/admin/branches/${warehousePickup?.branch?.id}`}
                  >
                    {warehousePickup?.branch?.name}
                  </SpanLink>
                ) : (
                  "--"
                )
              }
            />
            <KeyValuePair
              title={t("fromHour")}
              value={
                warehousePickup?.timeFrom ? formatTime(warehousePickup?.timeFrom) : null
              }
            />
            <KeyValuePair
              title={t("toHour")}
              value={warehousePickup?.timeTo ? formatTime(warehousePickup?.timeTo) : null}
            />
            <KeyValuePair
              title={t("shippingAgent")}
              value={
                warehousePickup?.deliveryAgent ? (
                  <SpanLink
                    pathname={`/admin/delivery-agents/${warehousePickup?.deliveryAgent?.id}`}
                  >
                    {warehousePickup?.deliveryAgent?.name}
                  </SpanLink>
                ) : (
                  "--"
                )
              }
            />

            <KeyValuePair
              title={t("transactionTypeName")}
              value={
                warehousePickup?.transactionType ? (
                  <SpanLink
                    pathname={`/admin/transaction-types/${warehousePickup?.transactionType?.id}`}
                  >
                    {warehousePickup?.transactionType?.name}
                  </SpanLink>
                ) : (
                  "--"
                )
              }
            />
            <KeyValuePair
              title={t("customer")}
              value={
                warehousePickup?.customer ? (
                  <SpanLink
                    pathname={`/admin/customers/${warehousePickup?.customer?.id}`}
                  >
                    {warehousePickup?.customer?.name}
                  </SpanLink>
                ) : (
                  "--"
                )
              }
            />

            <KeyValuePair
              title={t("source")}
              value={
                warehousePickup?.customer?.zone ? (
                  <SpanLink
                    pathname={`/admin/zones/${warehousePickup?.customer?.zone?.id}`}
                  >
                    {warehousePickup?.customer?.zone?.name +
                      " - " +
                      warehousePickup?.customer?.subzone?.name}
                  </SpanLink>
                ) : (
                  "--"
                )
              }
            />
            <KeyValuePair
              title={t("address")}
              value={warehousePickup?.customer?.address}
            />
            <KeyValuePair title={t("notes")} value={warehousePickup?.notes} />
            <KeyValuePair
              title={t("deliveryAgentNotes")}
              value={warehousePickup?.deliveryAgentNotes}
            />
            <KeyValuePair
              title={t("vehicle")}
              value={warehousePickup?.vehicle?.name}
            />
            <KeyValuePair
              title={t("status")}
              value={warehousePickup?.status?.name}
            />
            <Can
              permission="shipping.shipment.choose_customer"
              showException
            >
              <KeyValuePair
                title={t("createdBy")}
                value={
                  warehousePickup?.createdBy ? (
                    <SpanLink
                      pathname={`/admin/users/${warehousePickup?.createdBy?.id}`}
                    >
                      {warehousePickup?.createdBy?.username}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
              <KeyValuePair
                title={t("updatedBy")}
                value={
                  warehousePickup?.updatedBy ? (
                    <SpanLink
                      pathname={`/admin/users/${warehousePickup?.updatedBy?.id}`}
                    >
                      {warehousePickup?.updatedBy?.username}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
            </Can>
            <KeyValuePair title={t("createdAt")} value={warehousePickup?.createdAt} />
            <Can showException permission="shipping.warehouse_manifest.list">
              <KeyValuePair
                title={t("manifest")}
                value={
                  warehousePickup?.manifest?.code ? (
                    <SpanLink
                      pathname={`/admin/warehouse-manifests/${warehousePickup?.manifest?.id}`}
                    >
                      {warehousePickup?.manifest?.code}
                    </SpanLink>
                  ) : (
                    "--"
                  )
                }
              />
            </Can>
          </Paper>
          {warehousePickup?.pickupProducts.length > 0 && (
            <Paper className={clsx(classes.table)}>
              <EnhancedTableToolbar
                {...props}
                selectedProduct={warehousePickup?.pickupProducts}
              />
              <TableFixedHeaderWraper>
                <ProductsTable
                  page={page}
                  rowsPerPage={rowsPerPage}
                  data={warehousePickup?.pickupProducts}
                  headCells={productTableHead}
                  parseBodyCell={productTableBody}
                  total={total}
                  sumTableNumber={sumTableNumber}
                />
              </TableFixedHeaderWraper>
              {warehousePickup?.pickupProducts.length > 4 && (
                <MUITablePagination
                  // classeName={classes.pagination}
                  count={warehousePickup?.pickupProducts.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          )}
        </Stack>
      </Root>
    );
};

export default WarehousePickupView;


