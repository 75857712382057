import React, { Fragment } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { SlLike } from "react-icons/sl";
import * as gqlb from "gql-query-builder";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import useWidth, { isWidthDown } from "../../Hooks/useWidth";
import MapComponent from "../HOC/GoogleMap/PickpUpMap";
import moment from "moment";

export const VALIDATE_SHIPMENT_DELIVERY_TOKEN = gqlb.query({
    operation: "validateShipmentDeliveryInfoToken",
    variables: {
        token: {
            type: "String",
            required: true,
        },
    },
});

export const UPDATE_SHIPMENT_DELIVERY_INFO = gqlb.mutation({
    operation: "updateShipmentDeliveryInfo",
    fields: ["id"],
    variables: {
        input: {
            type: "UpdateShipmentDeliveryInfoInput",
            required: true,
        },
    },
});

export default function UserLocation(props) {
    const token = props.match.params.token?.trim();
    const { t } = useTranslation();
    const dateFormat = (date) =>
        date ? moment(date).locale("en").format("YYYY-MM-DD") : null;
    const { handleSubmit, control, watch } = useForm();
    const screenWidth = useWidth();
    const isScreenSmall = isWidthDown("sm", screenWidth);

    const [saveShipmentDeliveryInfo, { loading: saveDeliveryInfoLoading }] =
        useMutation(
            gql`
        ${UPDATE_SHIPMENT_DELIVERY_INFO.query}
      `
        );

    const { loading, data } = useQuery(
        gql`
      ${VALIDATE_SHIPMENT_DELIVERY_TOKEN.query}
    `,
        {
            fetchPolicy: "network-only",
            variables: { token: token },
        }
    );
    const [fieldsState, setFieldsState] = useState({
        createdAt: null,
    });
    const [rateDone, setRateDone] = useState(false);
    const [locationValue, setLocationValue] = useState(null);

    const onSubmit = (data) => {
        const deliveryDate = dateFormat(data.deliveryDate);
        saveShipmentDeliveryInfo({
            variables: {
                input: {
                    ...(data.deliveryDate && { deliveryDate: deliveryDate }),
                    token: token,
                    ...(locationValue && { recipientLatitude: locationValue.lat }),
                    ...(locationValue && { recipientLongitude: locationValue.lng }),
                },
            },
        })
            .then((data) => {
                data.data.updateShipmentDeliveryInfo.id && setRateDone(true);
            })
            .catch(({ graphQLErrors }) => { });
    };
    return (
        <Fragment>
            {loading ? (
                <FullScreenLoading height={"100%"} />
            ) : data ? (
                !rateDone ? (
                    <Fragment>
                        <Grid
                            component={"form"}
                            onSubmit={handleSubmit(onSubmit)}
                            Grid
                            container
                            spacing={2}
                            justifyContent={"center"}
                            width={"100%"}
                            m={0}
                        >
                            <Grid item xs={12} textAlign={"center"}>
                                <Typography
                                    variant="h5"
                                    color={"text.primary"}
                                    sx={{ textTransform: "capitalize" }}
                                >
                                    {t("deliveryInfo")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} textAlign={"center"}>
                                <MUIDateTime
                                    name="deliveryDate"
                                    label={t("deliveryDate")}
                                    control={control}
                                    value={fieldsState.createdAt}
                                    minDate={new Date() - 1}
                                    onChange={(e) =>
                                        setFieldsState((prev) => ({ ...prev, createdAt: e }))
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12} sm={6} md={3} textAlign={"center"}>
                                <MapComponent
                                    setLocationValue={setLocationValue}
                                    heightValue={"50vh"}
                                />
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12} sm={6} md={3} textAlign={"center"}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="large"
                                    disabled={
                                        saveDeliveryInfoLoading ||
                                        (!locationValue && !watch("deliveryDate"))
                                    }
                                >
                                    {t("sent")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Fragment>
                ) : (
                    <Grid
                        container
                        spacing={2}
                        justifyContent={"center"}
                        width={"100%"}
                        m={0}
                    >
                        <Grid item xs={12} textAlign={"center"}>
                            <SlLike
                                size={isScreenSmall ? 50 : 80}
                                color="gray"
                                style={{ margin: "0 5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                            <Typography variant="h4" color={"text.primary"}>
                                {t("ThankYou")}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            ) : (
                <Grid
                    container
                    spacing={2}
                    justifyContent={"center"}
                    width={"100%"}
                    m={0}
                >
                    <Grid xs={12} textAlign={"center"}>
                        "INVALED_TOKEN"
                    </Grid>
                </Grid>
            )}
        </Fragment>
    );
}
