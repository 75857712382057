
import { invoice } from "../Invoices/listInvoicesFields";
import { TableRow } from "@mui/material";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { initialPaymentData } from "../Payment/listPaymentFields";
import { Fragment } from "react";
import GenerateShipmentSumCell from "../Payment/GenerateShipmentSumCell";
import GenerateShipmentSumCellInvoice from "../Invoices/GenerateShipmentSumCell";

const useTotalTableDetails = ({
    printType,
    reportDetails,
    manifestTransactionType
}) => {
    let custmTableTotal
    if (printType === 'invoice') {
        const localDataKey = localStorage.getItem(`shipmentInvoice`) ? JSON.parse(localStorage.getItem(`shipmentInvoice`)) : invoice
        const tableSum = localDataKey && <TableRow>
            <FixedTableCell> </FixedTableCell>

            {localDataKey && localDataKey.map((i, index) =>
                <Fragment key={index}>
                    {reportDetails && <GenerateShipmentSumCellInvoice
                        shipment={reportDetails} elementKey={i}
                    />}
                </Fragment>
            )}


        </TableRow >
        custmTableTotal = tableSum

    }
    else if (printType === 'payment') {
        if (manifestTransactionType === 'DLVBY' || manifestTransactionType === 'CUSTM') {
            const localDataKey = localStorage.getItem(`shipmentPayment${manifestTransactionType}`) ? JSON.parse(localStorage.getItem(`shipmentPayment${manifestTransactionType}`)) : initialPaymentData[manifestTransactionType]
            const adminNotesPermissionPayment = localDataKey && localDataKey?.includes("adminNotes")
            const tableSum = localDataKey && <TableRow>
                <FixedTableCell> </FixedTableCell>

                {localDataKey && localDataKey.map((i, index) =>
                    <Fragment key={index}>
                        {reportDetails && <GenerateShipmentSumCell
                            shipment={reportDetails} elementKey={i} type={manifestTransactionType} Permission={adminNotesPermissionPayment}
                        />}
                    </Fragment>
                )}


            </TableRow >
            custmTableTotal = tableSum
        }
    }
    return {
        custmTableTotal,
    };
}

export default useTotalTableDetails;