import {
    Button,
    Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Search } from "@mui/icons-material";
import clsx from "clsx";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Can } from "../../Component/HOC/CustomComponents/Secured";
import { windowUrl } from "../../Component/HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../../Component/HOC/CustomFunctions/urlParameters";
import ControlMUItextField from "../../Component/HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../../Component/HOC/MUI/CustomAutocomplete";
import MUIDateRangeCustom from "../../Component/HOC/MUI/MUIDateRangeCustom";
import {
    LIST_BRANCHES_DROPDOWN,
    LIST_CUSTOMERS_DROPDOWN,
    LIST_DELIVERY_AGENTS_DROPDOWN,
    LIST_LOOKUP_ENTRIES_DROPDOWN,
    LIST_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import MUItextField from "../HOC/MUI/MUItextField";

const PREFIX = "shipmentListFilters";

const classes = {
    button: `${PREFIX}-button`,
    btnMargin: `${PREFIX}-btnMargin`,
    searchForm: `${PREFIX}-searchForm`,
    searchField: `${PREFIX}-searchField`,
    overlay: `${PREFIX}-overlay`,
    searchPadding: `${PREFIX}-searchPadding`,
};

const Root = styled(Grid)(({ theme }) => ({
    [`& .${classes.button}`]: {
        position: "sticky",
        bottom: 0,
        zIndex: 3,
        backgroundColor: theme.palette.background.paper,
        marginTop: 10,
        padding: 7,
    },

    [`& .${classes.btnMargin}`]: {
        width: "100%",
    },

    [`&.${classes.searchForm}`]: {
        overflowY: "scroll",
        width: "100%",
        margin: 0,
        height: "100vh",
        alignContent: "space-between"
    },

    [`& .${classes.searchField}`]: {
        padding: theme.spacing(1, 1, 0, 1),
        position: "relative",
        overflow: "hidden",
    },

    [`& .${classes.overlay}`]: {
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        opacity: 0.8,
        zIndex: 2,
    },

    //////////////////////Drawer///////////////////
    [`& .${classes.searchPadding}`]: {
        padding: theme.spacing(1),
    },
}));

const ConciergeRequestFilter = ({
    loading,
    resetPage,
    onSubmitFunc,
    rowsPerPage,
    preventPushUrl,
    initData,
    pathname,
    clearCheckBox,
    removeResetDate,
    filtersAllowed,
    setSearchQuery,
    listType,
    noData,
    refetch,
    setRefetch,
    removeSearch,
    allowCode,
}) => {
    const { handleSubmit, register, errors, watch, control, setValue } = useForm();

    const history = useHistory();
    const { t } = useTranslation();

    const urlQuery = urlParameters(window.location.search);
    const validUrlParameters = Object.keys(urlQuery).length !== 0;

    const initFromDate = urlQuery["fromDate"]
        ? urlQuery["fromDate"]
        : initData?.["fromDate"] ?? null;
    const initToDate = urlQuery["toDate"]
        ? urlQuery["toDate"]
        : initData?.["toDate"] ?? null;

    const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

    const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
    const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");

    const initSearch = () => {
        let searchParameters = {
            // ...(dateRange[0] && { fromDate }),
            // ...(dateRange[1] && { toDate }),
        };

        if (validUrlParameters) {
            if (urlQuery["search"]) {
                urlQuery["search"] = urlQuery["search"].toString().trim();
            }
            if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"]?.toString();
            if (urlQuery["accountNumber"]) urlQuery["accountNumber"] = urlQuery["code"]?.toString();
            const searchParams = { ...urlQuery };
            delete searchParams["page"];
            delete searchParams["rowsPerPage"];
            if (urlQuery["statusCode"])
                searchParams["statusCode"] = searchParams["statusCode"].split(",");
            searchParameters = searchParams;
        }
        !preventPushUrl && setSearchQuery(searchParameters)
        return searchParameters;
    };



    useEffect(() => {
        initSearch()
        urlQuery["search"] && setValue("search", urlQuery["search"].toString());
        urlQuery["code"] && setValue("code", urlQuery["code"].toString());
        setAutocompleteValues((prev) => ({
            ...prev,
            ...(urlQuery["statusCode"] && {
                statusCode: urlQuery["statusCode"].split(","),
            }),
        }));
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [autocompleteValues, setAutocompleteValues] = useState({
        branch: null,
        customer: null,
        deliveryAgent: null,
        zone: null,
        subzone: null,
        statusCode: [],
    });

    const pushUrlSearch = (param) => {
        if (preventPushUrl) return;
        const queryParams = [];
        for (const i in param) {
            encodeURIComponent(param[i]) &&
                queryParams.push(
                    encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
                );
        }
        const queryString = queryParams.join("&");
        const url = history.createHref({
            ...(listType ? { pathname: `/admin/${pathname}/${listType}` } : { pathname: `/admin/${pathname}` }),
            search: "?" + queryString,
        });
        windowUrl(url);
    };

    const onSubmit = (data) => {
        onSubmitFunc && onSubmitFunc(data);
        setRefetch(prev => !prev)

        clearCheckBox && clearCheckBox();
        let handledData = {
            ...(dateRange[0] && {
                fromDate,
            }),
            ...(dateRange[1] && {
                toDate,
            }),
            statusCode: data.statusCode,
            code: data?.code?.toString(),
            search: data?.search?.toString().trim(),
            branchId: data.branchId,
            customerId: data.customerId,
            accountNumber: data.accountNumber?.toString(),
            lastDeliveryAgentId: data.lastDeliveryAgentId,
            zoneId: data.zoneId,
            subzoneId: data.subzoneId,
        };
        setSearchQuery(handledData)
        for (const key in handledData) {
            if ([undefined, "", null, []].includes(handledData[key]) ||
                (Array.isArray(handledData[key]) && handledData[key].length === 0)) {
                delete handledData[key];
            }
        }
        resetPage();
        if (handledData.search) {
            handledData.search = handledData.search
                .split(",")
                .filter((i) => i.trim() !== "")
                .map((i) => i.trim());
        }
        pushUrlSearch({
            ...handledData,
            rowsPerPage,
            page: 0,
        });
    };

    const parseData = (data) => {
        return data;
    };
    const selectedData = (data) => {
        let filteredData = data
        if (listType === "top") {
            filteredData = data?.filter((i) => !["TRANSFERRED", "PAID", "REVIEWD"].includes(i.code))
        }
        if (listType === "wdr") {
            filteredData = data?.filter((i) => !["RECHARGED", "COLLECTED", "ASSIGNED"].includes(i.code))
        }
        if (pathname === "uncollected-from-delivery-agents-concierge") {
            filteredData = data?.filter((i) => ["CONFIRMED", "PAID", "COLLECTED", "RECHARGED"].includes(i.code))
        }
        return filteredData;
    };

    const handelAutocompleteDefaultValue = (data, id, fieldName) => {
        const defaultValue = data[Object.keys(data)[0]].find(
            (i) => i.id === parseInt(id)
        );
        defaultValue &&
            setAutocompleteValues((prev) => ({
                ...prev,
                [fieldName]: defaultValue,
            }));
    };

    const resetDate = () => {
        setDateRange([null, null]);
    };

    const filtersComponents = [
        {
            id: 1,
            code: "date",
            component: (
                <MUIDateRangeCustom
                    value={dateRange}
                    onChangeValue={(value) => setDateRange(value)}
                    resetDate={removeResetDate ? false : resetDate}
                />
            ),
        },
        {
            id: 2,
            code: "code",
            component: (
                <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"code"}
                    label={t("code")}
                />
            ),
        },
        {
            id: 3,
            code: "branch",
            Permissions: "shipping.concierge_request.filter_by_branch",
            component: (
                <CustomAutocomplete
                    name={"branchId"}
                    label={t("branch")}
                    skip={noData}
                    control={control}
                    errors={errors}
                    parseData={(data) => parseData(data)}
                    onCompleted={(data) =>
                        handelAutocompleteDefaultValue(data, urlQuery["branchId"], "branch")
                    }
                    query={LIST_BRANCHES_DROPDOWN.query}
                    defaultValue={autocompleteValues.branch}
                />
            ),
        },
        {
            id: 4,
            code: "custm",
            Permissions: "shipping.concierge_request.filter_by_customer",
            component: (
                <CustomAutocomplete
                    name={"customerId"}
                    label={t("customer")}
                    control={control}
                    skip={noData}
                    errors={errors}
                    onCompleted={(data) =>
                        handelAutocompleteDefaultValue(
                            data,
                            urlQuery["customerId"],
                            "customer"
                        )
                    }
                    parseData={(data) => parseData(data)}
                    query={LIST_CUSTOMERS_DROPDOWN.query}
                    variables={{
                        input: {
                            active: true,
                            typeCode: "CONCIERGE"

                        },
                    }}
                    defaultValue={autocompleteValues?.customer}
                />
            ),
        },
        {
            id: 5,
            code: "dlvAgent",
            Permissions: "shipping.concierge_request.filter_by_delivery_agent",
            component: (
                <CustomAutocomplete
                    name={"lastDeliveryAgentId"}
                    label={t("shippingAgent")}
                    skip={noData}
                    control={control}
                    errors={errors}
                    parseData={(data) => parseData(data)}
                    onCompleted={(data) =>
                        handelAutocompleteDefaultValue(
                            data,
                            urlQuery["lastDeliveryAgentId"],
                            "deliveryAgent"
                        )
                    }
                    query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                    variables={{
                        input: {
                            active: true,
                        },
                    }}
                    defaultValue={autocompleteValues.deliveryAgent}
                />
            ),
        },
        {
            id: 6,
            code: "zone",
            component: (
                <CustomAutocomplete
                    control={control}
                    errors={errors}
                    skip={noData}
                    name={"zoneId"}
                    label={t("zone")}
                    onChangeValue={(e) => {
                        setValue("subzoneId", "");
                        // pushUrlSearch({ ...urlQuery });
                    }}
                    onCompleted={(data) =>
                        handelAutocompleteDefaultValue(
                            data,
                            urlQuery["zoneId"],
                            "zone"
                        )
                    }
                    variables={{ input: { parentId: null } }}
                    parseData={(data) => parseData(data)}
                    query={LIST_ZONES_DROPDOWN.query}
                    defaultValue={autocompleteValues.zone}
                />
            ),
        },
        {
            id: 7,
            code: "subZone",
            component: (
                <CustomAutocomplete
                    control={control}
                    errors={errors}
                    name={"subzoneId"}
                    label={t("subzone")}
                    skip={!watch("zoneId")}
                    disabled={!watch("zoneId")}
                    variables={{ input: { parentId: watch("zoneId") } }}
                    onCompleted={(data) =>
                        handelAutocompleteDefaultValue(
                            data,
                            urlQuery["subzoneId"],
                            "subzone"
                        )
                    }
                    parseData={(data) => parseData(data)}
                    query={LIST_ZONES_DROPDOWN.query}
                    defaultValue={autocompleteValues.subzone}
                />
            ),
        },
        {
            id: 8,
            code: "account",
            component: (
                <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"accountNumber"}
                    label={t("accountNumber")}
                />
            ),
        },
        {
            id: 9,
            code: "status",
            component: (
                <MultipleAutocomplete
                    name={"statusCode"}
                    label={t("status")}
                    skip={noData}
                    control={control}
                    errors={errors}
                    variables={{ input: { code: "CONCIERGE_REQUEST_STATUS" } }}
                    query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                    parseData={(data) => selectedData(data)}
                    multiple
                    valueKey="code"
                    defaultValue={autocompleteValues.statusCode}
                />
            ),
        },
    ];

    const showFilters = [];
    for (let x = 0; x < filtersAllowed.length; x++) {
        const filter = filtersComponents.filter(
            (elem) => elem.code === filtersAllowed[x]
        );
        showFilters.push(filter[0]);
    }

    return (
        <Root
            container
            item
            justifyContent="center"
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
            className={classes.searchForm}
            alignContent="flex-start"
        >
            <Grid
                container
                item
                sm={12}
                justifyContent="flex-start"
                spacing={1}
                className={classes.searchField}
            >

                <Grid container item sm={12} alignItems="flex-start">
                    <Can permission={!removeSearch} showException>
                        <MUItextField
                            label={t("search")}
                            name={"search"}
                            inputProps={{
                                dir: "ltr",
                            }}
                            onPaste={(e) => {
                                e.preventDefault();
                                const input = e.target;
                                const inputValue = e.clipboardData
                                    .getData("Text")
                                    .replace(/\n/g, ",");

                                const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                                    window.HTMLInputElement.prototype,
                                    "value"
                                ).set;
                                nativeInputValueSetter.call(input, input.value + inputValue);

                                input.dispatchEvent(new Event("input", { bubbles: true }));
                            }}
                            onKeyPress={(e) => {
                                const value = e.target.value.trim();
                                if (value.slice(-1) !== "," && value !== "") {
                                    if (e.key === "Enter") {
                                        // e.preventDefault();
                                        setValue("search", `${value},`);
                                    }
                                }
                            }}
                            formType={"requireFalse"}
                            register={register}
                            errors={errors}
                            // InputProps={{
                            //   endAdornment: Boolean(watch("search")) && (
                            //     <InputAdornment position="end">
                            //       <IconButton
                            //         disabled={loading}
                            //         aria-label="code"
                            //         onClick={clearCode}
                            //         edge="end"
                            //         size="large"
                            //       >
                            //         <Clear color="error" />
                            //       </IconButton>
                            //     </InputAdornment>
                            //   ),
                            // }}
                            watch={watch("search")}
                        />
                    </Can>
                    <Can permission={Boolean(allowCode)} showException>
                        <MUItextField
                            label={t("code")}
                            name={"code"}
                            inputProps={{
                                dir: "ltr",
                            }}
                            formType={"requireFalse"}
                            register={register}
                            errors={errors}
                            watch={watch("code")}
                        />
                    </Can>
                </Grid>
                {showFilters.map((ele, index) => (
                    <Can showException permission={ele.Permissions} key={index}>
                        <Grid container item sm={12} alignItems="flex-start">
                            {ele.component}
                        </Grid>
                    </Can>
                ))}
            </Grid>
            <Grid
                container
                item
                sm={12}
                className={clsx(classes.searchPadding, classes.button)}
                alignItems="center"
                justifyContent="flex-end"
            >
                <Button
                    className={clsx(classes.btnMargin)}
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    startIcon={<Search />}
                    disabled={loading}
                >
                    {t("search")}
                </Button>
            </Grid>
        </Root>
    );
};

export default memo(
    ConciergeRequestFilter,
    (prevProps, nextProps) =>
        prevProps.noData === nextProps.noData &&
        prevProps.loading === nextProps.loading &&
        prevProps.rowsPerPage === nextProps.rowsPerPage
);
