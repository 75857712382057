import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { styled } from "@mui/material/styles";
import clsx from 'clsx';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

const PREFIX = "ShipmentsView";

const classes = {
    checked: `${PREFIX}-checked`,
}

const FormControlLabelStyle = styled(FormControlLabel)(({ theme }) => ({
    width: "320px",
    [`&:hover`]: {
        // background: theme.palette.divider,
        borderRadius: theme.spacing(1),
        color: theme.palette.primary.main
    },
    [`&.${classes.checked}`]: {
        // background: theme.palette.divider,
        borderRadius: theme.spacing(1),
        color: theme.palette.primary.main
    },
}));

export default function SelectPeriodRadio(props) {
    const {
        options,
        value: initialValue,
        onChangeValue,
        setRangeState,
        rangeState,
    } = props

    const ifInitialValueInOptions = options.find(option => option.value === initialValue)

    const { t } = useTranslation();
    const [value, setValue] = React.useState(initialValue);

    const handleChange = (event) => {
        if (event.target.value) {
            onChangeValue(parseInt(event.target.value))
            setValue(parseInt(event.target.value));
        } else {
            onChangeValue("")
            setValue("");
        }
    };

    const handleChangeToggleButton = (event, newRangeState) => {
        setRangeState(newRangeState);
    };

    return (
        <Stack p={2} spacing={2} alignItems={"center"}>
            <ToggleButtonGroup
                color="primary"
                value={rangeState}
                exclusive
                onChange={handleChangeToggleButton}
                aria-label="Platform"
            >
                <ToggleButton value="SINCE">{t("since")}</ToggleButton>
                <ToggleButton value="UNTIL">{t("until")}</ToggleButton>
            </ToggleButtonGroup>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                >
                    {options.map((option) => {
                        const isChecked = option.value === value
                        return <FormControlLabelStyle
                            className={clsx({ [classes.checked]: isChecked })}
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={t("days", { count: option.value })}
                        />
                    }
                    )}
                </RadioGroup>
            </FormControl>
            <Stack direction={"row"} alignItems={"center"} spacing={1} width={"100%"}>
                <TextField
                    fullWidth
                    label={""}
                    id="outlined-size-small"
                    defaultValue={!!ifInitialValueInOptions ? "" : initialValue}
                    onChange={handleChange}
                    size="small"
                    autoComplete='off'
                />
                <Typography paragraph mb={0}>{t("day")}</Typography>
            </Stack>

        </Stack>
    );
}