import * as gqlb from "gql-query-builder";

export const LIST_BARCODE_BATCH = gqlb.query([{
  operation: "listBarcodeBatches",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "date",
        "barcodesCount",
        "approved",
        {
          branch: ["id", "name"],
        },
        {
          transactionType: ["id", "name"],
        },
        {
          customer: ["id", "name"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListBarcodeBatchesFilterInput",
      required: true
    },
  },
}
]);

const barcodeBatch = [
  "id",
  {
    transactionType: [
      "id",
      "name",
    ]
  },
  {
    customer: [
      "id",
      "name",
      "address",
      { zone: ["id", "name"] },
      { subzone: ["id", "name"] },
    ],
  },
  "editable",
  "deletable",
  { createdBy: ["id", "username"] },
  { updatedBy: ["id", "username"] },
  { branch: ["id", "name"] },
  { barcodes: ["id", "code"] }
];

export const BARCODEBATCH = gqlb.query({
  operation: "barcodeBatch",
  fields: barcodeBatch,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_BARCODE_BATCH = gqlb.mutation({
  operation: "saveBarcodeBatch",
  fields: barcodeBatch,
  variables: {
    input: {
      type: "BarcodeBatchInput",
      required: true,
    },
  },
});

export const DELETE_BARCODEBATCH_MUTATION = gqlb.mutation({
  operation: "deleteBarcodeBatch",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

