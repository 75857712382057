import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import {
  Paper,
  Switch,
  FormControlLabel,
  Collapse,
} from "@mui/material";
import {
  SAVE_TRANSACTION_TYPE,
  TRANSACTION_TYP_ID,
} from "./Graphql";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { useMutation, gql, useQuery } from "@apollo/client";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_JOURNAL_TYPES_DROPDOWN,
  LIST_SEQUENCES_DROPDOWN,
  LIST_SUBSIDIARIES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";

const PREFIX = 'ShipmentType';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  }
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const TransactionType = (props) => {
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    glAccount: null,
    sequence: null,
    journalType: null,
    subsidiary: null,
  });


  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, watch, setValue, setError } =
    useForm();
  const { errors } = formState;

  const parseData = (data) => {
    return data;
  };

  const [
    saveTransactionType,
    { loading: saveTransactionTypeLoading },
  ] = useMutation(
    gql`
      ${SAVE_TRANSACTION_TYPE.query}
    `
  );

  //update
  const TransactionTypeId = parseInt(props.match.params.id);

  const { data: updateTransactionType, loading: updateTransactionLoading } = useQuery(
    gql`
      ${TRANSACTION_TYP_ID.query}
    `,
    {
      skip: !TransactionTypeId,
      variables: { id: TransactionTypeId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const transactionTypesData = data.transactionType;
        setActiveUser(transactionTypesData.active);
        const saveTransactionTypesParams = ["id", "code", "name"];

        saveTransactionTypesParams.forEach((i) => {
          transactionTypesData[i] &&
            setValue(i, transactionTypesData[i]);
        });
        setValue("active", transactionTypesData["active"]);

        setAutocompleteValues({
          glAccount: transactionTypesData?.glAccount,
          branch: transactionTypesData?.branch,
          sequence: transactionTypesData?.sequence,
          journalType: transactionTypesData?.journalType,
          subsidiary: transactionTypesData?.subsidiary,
        });
      },
    }
  );
  const transactionTypesData =
    updateTransactionType?.transactionType;
  const [activeUser, setActiveUser] = useState(true);
  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveUser(active);
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    saveTransactionType({
      variables: {
        input: {
          ...data,
          active: activeUser,
        },
      },
    })
      .then((data) => {
        pushUrl(
          props,
          `/admin/transaction-types/${data?.data?.saveTransactionType?.id}`
        );
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid container justifyContent="end" xs={12}>
            <FormControlLabel
              checked={activeUser}
              control={<Switch color="primary" />}
              label={t("active")}
              labelPlacement="start"
              onChange={handelActivate}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("code")}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"name"}
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"type"}
              label={t("documentType")}
              rules={{ required: t("fieldIsRequired") }}
              disabled={transactionTypesData?.type ? true : false}
              variables={{ input: { code: "SHP_ACTION_TYPES" } }}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  transactionTypesData?.type?.code,
                  "type"
                )
              }
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"sequenceId"}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => parseData(data)}
              label={t("sequence")}
              disabled={transactionTypesData?.sequence ? true : false}
              query={LIST_SEQUENCES_DROPDOWN.query}
              defaultValue={autocompleteValues.sequence}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ListBranches
              control={control}
              errors={errors}
              name={"branchId"}
              defaultValue={autocompleteValues.branch}
              disabled={TransactionTypeId ? true : false}
              onChangeValue={() => {
                setValue("glAccountId", "");
              }}
              skipDefaultBranch={Boolean(TransactionTypeId)}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"glAccountId"}
              label={t("glAccount")}
              disabled={transactionTypesData?.glAccount ? true : false}
              parseData={(data) => parseData(data)}
              query={LIST_GL_ACCOUNTS_DROPDOWN.query}
              variables={{
                input: {
                  typeCode: "SUB",
                  ...(watch("branchId") && {
                    branchId: { value: watch("branchId") },
                  }),
                },
              }}
              defaultValue={autocompleteValues.glAccount}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"journalTypeId"}
              label={t("journalType")}
              // rules={{ required: t("fieldIsRequired") }}
              disabled={
              transactionTypesData?.journalType ? true : false
              }
              parseData={(data) => parseData(data)}
              query={LIST_JOURNAL_TYPES_DROPDOWN.query}
              defaultValue={autocompleteValues.journalType}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"subsidiaryId"}
              label={t("subsidiary")}
              disabled={transactionTypesData?.subsidiary ? true : false}
              parseData={(data) => parseData(data)}
              query={LIST_SUBSIDIARIES_DROPDOWN.query}
              defaultValue={autocompleteValues.subsidiary}
            />
          </Grid>
        </Paper>
        <Grid container justifyContent="flex-end" className={classes.spacing}>
          <FormButton disabled={saveTransactionTypeLoading}>
            {saveTransactionTypeLoading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </form>
  );
  return updateTransactionLoading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading >
    : !updateTransactionType && TransactionTypeId ? <NotFound /> :
      <Root>
        <>
          <TitleAppBar path={props.match.path} />
          {body}
        </>
      </Root>
};

export default TransactionType;
