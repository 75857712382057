import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  TableBody,
  TableRow,
  Icon,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { FilterList, Search, DeleteOutline, Add, Image, } from "@mui/icons-material";
import { DELETE_ALERT, LIST_ALERTS } from "./Graphql";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { styled } from "@mui/material/styles";
// import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import useWidth from "../../Hooks/useWidth";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { LIST_LOOKUP_ENTRIES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { Globals } from "../HOC/Classes/Globals";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUIselect from "../HOC/MUI/MUIselect";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import AdminAlertForm from "./AdminAlertForm";
import config from "../../config.json";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { dateFormatLL } from "../../helpers/dateFunctions";
const PREFIX = "ADMINALERT";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterFields: `${PREFIX}-filterFields`,
  filterField: `${PREFIX}-filterField`,
  headerTable: `${PREFIX}-headerTable`,
};
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));

const AdminAlert = (props) => {
  const { t } = useTranslation();
  const parseData = (data) => {
    return data;
  };
  const [refetchs, setrefetch] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const screenWidth = useWidth();
  const user = Globals.user

  const initCreateFromDate = urlQuery["fromDateCreate"]
    && urlQuery["fromDateCreate"]

  const initCreateToDate = urlQuery["toDateCreate"]
    && urlQuery["toDateCreate"]

  const [dateRangeCreate, setDateRangeCreate] = useState([initCreateFromDate, initCreateToDate]);
  const fromDateCreate = moment(dateRangeCreate[0]).locale("en").format("YYYY-MM-DD");
  const toDateCreate = moment(dateRangeCreate[1]).locale("en").format("YYYY-MM-DD");



  const initSendFromDate = urlQuery["fromDateSent"]
    && urlQuery["fromDateSent"]

  const initSendToDate = urlQuery["toDateSent"]
    && urlQuery["toDateSent"]

  const [dateRangeSent, setDateRangeSent] = useState([initSendFromDate, initSendToDate]);
  const fromDateSent = moment(dateRangeSent[0]).locale("en").format("YYYY-MM-DD");
  const toDateSent = moment(dateRangeSent[1]).locale("en").format("YYYY-MM-DD");

  const userCanEdit = user.hasPermission('core.alert.update')
  const userCanCreate = user.hasPermission('core.alert.create')
  const userCanDelete = user.hasPermission('core.alert.delete')
  const [open, setOpen] = React.useState(false);
  const [alertObject, setAlertObject] =
    React.useState();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAlertObject();
  };
  const convertToString = (row) => {
    let content = row.map((channels, index) =>
      <span key={index} className={classes.span}>
        {channels.name} {!(row.length - 1 === index) && ' , '}
      </span>
    )
    return content
  }
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? 20);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(dateRangeCreate[0] && { fromDateCreate }),
      ...(dateRangeCreate[1] && { toDateCreate }),
      ...(dateRangeSent[0] && { fromDateSent }),
      ...(dateRangeSent[1] && { toDateSent }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["sent"] !== undefined && setValue("sent", urlQuery["sent"]);
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const resetDate = () => {
    setDateRangeCreate([null, null]);
  };
  const resetDateSent = () => {
    setDateRangeSent([null, null]);
  };
  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/alert",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";
  const [autocompleteValues, setAutocompleteValues] = useState({
    typeCode: null,
    recipientTypeCode: null,
    channel: null,
  });
  const handelAutocompleteDefaultValueCode = (data, code, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.code === code
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: !drawerState[filterAnchor] });
    (refetchs) ? setrefetch(false) : setrefetch(true)
    setFilterLoading(false)
    let handledData = {
      ...(dateRangeCreate[0] && {
        fromDateCreate,
      }),
      ...(dateRangeCreate[1] && {
        toDateCreate,
      }),
      ...(dateRangeSent[0] && {
        fromDateSent,
      }),
      ...(dateRangeSent[1] && {
        toDateSent,
      }),
      sent: data.sent,
      channel: data.channel,
      typeCode: data.typeCode,
      recipientTypeCode: data.recipientTypeCode,
      page: 0,
    };
    data["sent"] === "" && delete handledData["sent"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const { data, loading, refetch } = useQuery(
    gql`
      ${LIST_ALERTS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetchs,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.fromDateCreate && { createdFromDate: search.fromDateCreate }),
          ...(search.toDateCreate && { createdToDate: search.toDateCreate }),
          ...(search.fromDateSent && { sentFromDate: search.fromDateSent }),
          ...(search.toDateSent && { sentToDate: search.toDateSent }),
          ...(search.sent != null && { sent: search.sent }),
          ...(search.typeCode && { typeCode: search.typeCode }),
          ...(search.recipientTypeCode && { recipientTypeCode: search.recipientTypeCode }),
          ...(search.channel && { channel: search.channel }),
        },
      },
    }
  );
  const listAdminAlerts = data?.listAlerts?.data;
  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: () => handleClickOpen(),
      icon: Add,
      permission: userCanCreate,
    },
  ]
  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
  });
  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };
  const openSignatureDialog = (imgPath) => {
    setDialogDetails((prev) => ({
      state: true,
      title: null,
      content: (
        <Box sx={{ background: "#fff", width: "100%" }} component="img" alt="signature" src={getBackendUri(imgPath.path)} />
      ),
      confirmAction: false,
      function: () => { },
    }));
  };


  return (
    <Root>
      {open && (
        <AdminAlertForm
          open={open}
          handleClose={handleClose}
          refetch={refetch}
          alertObject={alertObject}
        />
      )}
      <CustomDialog
        title={dialogDetails.title}
        fullWidth
        maxWidth="xs"
        onClose={closeConfirmationDialog}
        content={dialogDetails.content}
        open={dialogDetails.state}
        actions={
          dialogDetails.function !== null && (
            <>
              <Button color="primary" onClick={closeConfirmationDialog}>
                {dialogDetails.confirmAction ? t("cancel") : t("close")}
              </Button>
              {dialogDetails.confirmAction && (
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={dialogDetails.function}
                >
                  {loading ? (
                    <ButtonLoading />
                  ) : (
                    t("confirm")
                  )}
                </Button>
              )}
            </>
          )
        }
      >
        {dialogDetails.function === null && " "}
      </CustomDialog>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listAdminAlerts?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} xs={12}>
                <MUIDateRangeCustom
                  startText={t("createdAt")}
                  value={dateRangeCreate}
                  onChangeValue={(value) => setDateRangeCreate(value)}
                  resetDate={resetDate}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <MUIDateRangeCustom
                  startText={t("sentAt")}
                  value={dateRangeSent}
                  onChangeValue={(value) => setDateRangeSent(value)}
                  resetDate={resetDateSent}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading && filterLoading}
                  name={"typeCode"}
                  label={t("type")}
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "SHP_ALERTS_TYPE" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValueCode(
                      data,
                      urlQuery["typeCode"],
                      "typeCode"
                    )
                  }
                  valueKey="code"
                  defaultValue={autocompleteValues.typeCode}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading && filterLoading}
                  name={"recipientTypeCode"}
                  label={t("recipientType")}
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "SHP_ALERTS_RECIPIENT_TYPE" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValueCode(
                      data,
                      urlQuery["recipientTypeCode"],
                      "recipientTypeCode"
                    )
                  }
                  valueKey="code"
                  defaultValue={autocompleteValues.typeCode}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading && filterLoading}
                  name={"channel"}
                  label={t("channels")}
                  parseData={(data) => parseData(data)}
                  variables={{ input: { code: "SHP_ALERT_CHANNELS" } }}
                  query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValueCode(
                      data,
                      urlQuery["channel"],
                      "channel"
                    )
                  }
                  valueKey="code"
                  defaultValue={autocompleteValues.channel}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"sent"}
                  label={t("sent")}
                  control={control}
                  errors={errors}
                  // disabled={Boolean(watch("name"))}
                  data={[
                    { key: t("yes"), value: true },
                    { key: t("no"), value: false },
                  ]}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          [
            {
              name: "subject"
            },
            {
              name: "alertMessage"
            },
            {
              name: "type"
            },
            {
              name: "recipientType"
            },
            {
              name: "channels"
            },
            {
              name: "image"
            },
            {
              name: "createdAt"
            },
            {
              name: "sentAt"
            },
            {
              name: "createdBy"
            },
            {
              name: "updatedBy"
            },

            {
              name: "sent"
            },
            {
              name: " "
            },
          ]
        }
        tableBody={
          <TableBody>
            {listAdminAlerts &&
              listAdminAlerts?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    {userCanEdit ?
                      <CellLink
                        onClick={() => {
                          handleClickOpen();
                          setAlertObject(row);
                        }}
                      >
                        {row?.subject}
                      </CellLink> : <FixedTableCell>{row?.subject}</FixedTableCell>}
                    <FixedTableCell >{row?.message.length > 70 ? `${row?.message?.slice(0, 70)}...` : row?.message}</FixedTableCell>
                    <FixedTableCell>{row?.type?.name}</FixedTableCell>
                    <FixedTableCell>{row?.recipientType?.name}</FixedTableCell>
                    <FixedTableCell>
                      ( {convertToString(row.channels)} )
                    </FixedTableCell>
                    {row?.images.length > 0 ? <FixedTableCell>
                      <IconButton
                        size="small"
                        onClick={() => openSignatureDialog(row.images[0])}
                      >
                        <Image fontSize="small" />
                      </IconButton>
                      {/* {row?.images[0]?.path} */}
                    </FixedTableCell> : <FixedTableCell>

                    </FixedTableCell>}
                    <FixedTableCell>
                      {dateFormatLL(row?.createdAt)}
                    </FixedTableCell>
                    <FixedTableCell>
                      {dateFormatLL(row?.sentAt)}
                    </FixedTableCell>
                    {row?.createdBy ? (
                      <CellLink
                        align="left"
                        pathname={`/admin/users/${row?.createdBy?.id}`}
                      >
                        {row?.createdBy?.username}
                      </CellLink>
                    ) : (
                      <FixedTableCell> </FixedTableCell>
                    )}
                    {row?.updatedBy ? (
                      <CellLink
                        align="left"
                        pathname={`/admin/users/${row?.updatedBy?.id}`}
                      >
                        {row?.updatedBy?.username}
                      </CellLink>
                    ) : (
                      <FixedTableCell> </FixedTableCell>
                    )}


                    <FixedTableCell>
                      {row?.sent ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <FixedTableCell>
                      {userCanDelete && !row?.sent &&
                        <MutationWithDialog
                          mutaion={DELETE_ALERT.query}
                          icon={DeleteOutline}
                          permission={true}
                          iconTooltip={t("deleteAlert")}
                          dialogTitle={t("deleteAlert")}
                          dialogContent={t("deleteAlertConfirmation")}
                          mutaionProps={{ variables: { id: parseInt(row.id) } }}
                          onCompleted={() =>
                            refetch()
                          }
                          onCompleteMessage={t("deleteAlert")}
                        />}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listAlerts?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listAlerts ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default AdminAlert;
