import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";

const useLocalCurrency = () => {
  const query = gqlb.query({
    operation: "shippingSettings",
    fields: [{ localCurrency: ["name"] }],
    variables: {},
  });
  const { data } = useQuery(
    gql`
      ${query?.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );
  const currency = ` ${data?.shippingSettings?.localCurrency?.name} ` ?? "";
  return {
    currency,
  };
};

export default useLocalCurrency;
