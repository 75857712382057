import { useTranslation } from "react-i18next";
import { ConciergeRequestTableForm, PickupTableForm, ShipmentTableForm } from "./FormTables";
import { Chip, Paper, Typography } from "@mui/material";
import CustomTab from "../../HOC/FunctionComponents/CustomTab";
import clsx from "clsx";
import { Globals } from "../../HOC/Classes/Globals";


function FormTabs(props) {
    const {
        classes,
        watch,
        disableForm,
        paymentType,
        paymentData,
        paymentDetails,
        paymentPickupsDetails,
        conciergeRequestsDetails,

        conciergeRequestsData,
        setConciergeRequestsData,
        loadingConciergeData,
        refetchConciergeDataQuery,
        getConciergeData,

        shipmentData,
        setShipmentData,
        loadingShipmentData,
        refetchShipmentDataQuery,


        pickupData,
        getPickupData,
        setPickupData,
        loadingPickupData,
        refetchPickupDataQuery,
    } = props

    const { t } = useTranslation()
    let shipments = (
        <ShipmentTableForm
            classes={classes}
            watch={watch}
            disableForm={disableForm}
            paymentType={paymentType}
            paymentData={paymentData}
            paymentDetails={paymentDetails}
            shipmentData={shipmentData}
            setShipmentData={setShipmentData}
            loadingShipmentData={loadingShipmentData}
            refetchShipmentDataQuery={refetchShipmentDataQuery}
        />
    )

    const tapsArray = [
        {
            tabHead:
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {t("shipments")}
                    <Chip color="primary" size="small" label={shipmentData?.total} sx={{ mx: 1 }} />
                </Typography>,
            panel:
                <Paper className={clsx(classes.table, classes.paper)}>
                    {shipments}
                </Paper>
            ,
        },
    ];

    if (paymentType === "DLVBY" &&
        watch("deliveryAgentId") &&
        paymentData?.deliveryAgent?.pickupCommission === null ? Globals.settings.pickupCommission : paymentData?.deliveryAgent?.pickupCommission) {
        let pickup = (
            <PickupTableForm
                classes={classes}
                paymentType={paymentType} 
                watch={watch}
                paymentPickupsDetails={paymentPickupsDetails}
                disableForm={disableForm}
                paymentData={paymentData}

                pickupData={pickupData}
                getPickupData={getPickupData}
                setPickupData={setPickupData}
                loadingPickupData={loadingPickupData}
                refetchPickupDataQuery={refetchPickupDataQuery}
            />
        )
        tapsArray.push(
            {
                tabHead:
                    <Typography
                        className={classes.title}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {t("pickups")}
                        <Chip color="primary" size="small" label={pickupData.total} sx={{ mx: 1 }} />
                    </Typography>
                ,
                panel:
                    <Paper className={clsx(classes.table, classes.paper)}>
                        {pickup}
                    </Paper>
            },
        )
    }

    if (paymentType === "DLVBY" && Globals.settings.allowedCustomerTypes.includes("CONCIERGE")) {
        let conciergeRequests = (
            <ConciergeRequestTableForm
                watch={watch}
                disableForm={disableForm}
                paymentType={paymentType}
                paymentData={paymentData}
                conciergeRequestsDetails={conciergeRequestsDetails}
                classes={classes}
                
                conciergeRequestsData={conciergeRequestsData}
                setConciergeRequestsData={setConciergeRequestsData}
                loadingConciergeData={loadingConciergeData}
                refetchConciergeDataQuery={refetchConciergeDataQuery}
                getConciergeData={getConciergeData}
            />
        )
        tapsArray.push(
            {
                tabHead:
                    <Typography
                        className={classes.title}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {t("conciergeRequests")}
                        <Chip color="primary" size="small" label={conciergeRequestsData.total} sx={{ mx: 1 }} />
                    </Typography>
                ,
                panel:
                    <Paper className={clsx(classes.table, classes.paper)}>
                        {conciergeRequests}
                    </Paper>
            },
        )
    }

    return (
        <CustomTab tapDetails={tapsArray} />
    )
}

export default FormTabs