import { Box, SwipeableDrawer, Typography, useTheme } from "@mui/material"
import { EnhancedTableToolbarForm } from "./EnhancedTableToolbar"
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader"
import ShipmentsTable from "../../HOC/CustomComponents/ShipmentsTable"
import { useEffect, useState } from "react"
import useWidth from "../../../Hooks/useWidth"
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination"
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { useTranslation } from "react-i18next"
import BarcodePickupSelector from "../PaymentPickups/BarcodeSelector"
import PickupList from "../PaymentPickups/PickupList"
import ShipmentList from "../PaymentShipments/ShipmentList"
import BarcodeSelector from "../PaymentShipments/BarcodeSelector"
import ConciergeRequestsList from "../PaymentConciergeRequests/ConciergeRequestsList"
import ConciergeRequestsBarcodeSelector from "../PaymentConciergeRequests/ConciergeRequestsBarcodeSelector"
import FullScreenLoading from "../../HOC/FunctionComponents/LoadingPages/FullScreenLoading"

export const ShipmentTableForm = (props) => {
    const {
        classes,
        watch,
        disableForm,
        paymentType,
        paymentData,
        paymentDetails,
        shipmentData,
        setShipmentData,
        loadingShipmentData,
        refetchShipmentDataQuery
    } = props
    const screenWidth = useWidth();
    const anchor = screenWidth === "xs" ? "bottom" : "right";
    const { t } = useTranslation()
    const theme = useTheme()
    const handleChangePage = (event, newPage) => {
        setShipmentData(prev => {
            return {
                ...prev,
                page: newPage
            }
        })
    };

    const handleChangeRowsPerPage = (event) => {
        setShipmentData(prev => {
            return {
                ...prev,
                page: 0,
                rowsPerPage: event.target.value
            }
        })
    };

    const [state, setState] = useState({
        shipmentList: {
            left: false,
            bottom: false,
            right: false,
        },
    });


    const drawerHandler = (anchor, open) => {
        setState((prev) => ({
            ...prev,
            shipmentList: { ...prev.shipmentList, [anchor]: open },
        }));
    };

    const [barcodeDrawerState, setBarcodeDrawerState] = useState({
        left: false,
        bottom: false,
        right: false,
    });

    const barcodeDrawerHandler = (anchor, open) => {
        setBarcodeDrawerState((prev) => ({
            ...prev,
            [anchor]: open,
        }));
    };

    return (
        <>
            <SwipeableDrawer
                variant="persistent"
                disableDiscovery={true}
                disableSwipeToOpen={true}
                anchor={anchor}
                open={state.shipmentList[anchor]}
                onClose={() => false}
                onOpen={() => drawerHandler(anchor, true)}
                classes={{ paper: classes.shipmentList }}
                dir={theme.direction}
            >
                {state.shipmentList[anchor] && (
                    <ShipmentList
                        type={paymentType}
                        done={() => drawerHandler(anchor, false)}
                        variables={watch()}
                        refetchShipmentDataQuery={refetchShipmentDataQuery}
                    />
                )}
            </SwipeableDrawer>

            <SwipeableDrawer
                disableDiscovery={true}
                disableSwipeToOpen={true}
                anchor={anchor}
                open={barcodeDrawerState[anchor]}
                onClose={() => false}
                onOpen={() => barcodeDrawerHandler(anchor, true)}
                sx={{
                    width: "auto",
                    "& .MuiDrawer-paper": {
                        width: { md: "50%" },

                        height: "100%",
                        overflow: "hidden",
                    },
                }}
                dir={theme.direction}
            >
                {barcodeDrawerState[anchor] && <BarcodeSelector
                    type={paymentType}
                    done={() => barcodeDrawerHandler(anchor, false)}
                    variables={watch()}
                    refetchShipmentDataQuery={refetchShipmentDataQuery}
                />}
            </SwipeableDrawer>
            <EnhancedTableToolbarForm
                {...props}
                title={t("shipmentsNumber")}
                addShipment={() => drawerHandler(anchor, true)}
                barcode={() => barcodeDrawerHandler(anchor, true)}
                approved={paymentData?.approved}
            />
            <TableFixedHeaderWraper>
                {loadingShipmentData ?
                    <FullScreenLoading minHeight={"100px"} /> : paymentDetails && (
                        <ShipmentsTable
                            data={shipmentData?.pickedShipment}
                            headCells={paymentDetails.tableHead}
                            shipmentNumber={(index) => shipmentData.page * shipmentData.rowsPerPage + index + 1}
                            parseBodyCell={paymentDetails.tableBody}
                            sumRow={shipmentData.total > 0 ? paymentDetails.tableTotal : null}

                        />
                    )}
            </TableFixedHeaderWraper>
            {shipmentData.pickedShipment.length > 0 && (
                <MUITablePagination
                    // classeName={classes.pagination}
                    count={shipmentData.total}
                    rowsPerPage={shipmentData.rowsPerPage}
                    page={shipmentData.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}

            {(!watch("id") || !disableForm) && (
                <Box className={classes.overlay}>
                    <Typography color="textPrimary" variant="body1">
                        {t("saveManifestFirst")}
                    </Typography>
                </Box>
            )}
            <Grid container justifyContent="center" alignItems="center">
                {!loadingShipmentData && shipmentData.pickedShipment.length === 0 && watch("id") && disableForm && (
                    <EmptyTableMessage
                        message={t("noResult")}
                    />
                )}
            </Grid>
        </>
    )
}

export const PickupTableForm = (props) => {
    const {
        classes,
        paymentType,
        watch,
        paymentPickupsDetails,
        disableForm,
        paymentData,
        pickupData,
        getPickupData,
        setPickupData,
        loadingPickupData,
        refetchPickupDataQuery,
    } = props

    const screenWidth = useWidth();
    const anchor = screenWidth === "xs" ? "bottom" : "right";
    const { t } = useTranslation()
    const theme = useTheme()

    const handleChangePage = (event, newPage) => {
        setPickupData(prev => {
            return {
                ...prev,
                page: newPage
            }
        })
    };

    const handleChangeRowsPerPage = (event) => {
        setPickupData(prev => {
            return {
                ...prev,
                page: 0,
                rowsPerPage: event.target.value
            }
        })
    };

    const [state, setState] = useState({
        pickupList: {
            left: false,
            bottom: false,
            right: false,
        },
    });


    const drawerHandler = (anchor, open) => {
        setState((prev) => ({
            ...prev,
            pickupList: { ...prev.pickupList, [anchor]: open },
        }));
    };

    const [barcodeDrawerState, setBarcodeDrawerState] = useState({
        left: false,
        bottom: false,
        right: false,
    });

    const barcodeDrawerHandler = (anchor, open) => {
        setBarcodeDrawerState((prev) => ({
            ...prev,
            [anchor]: open,
        }));
    };
    useEffect(() => {
        pickupData.noData && watch("id") && getPickupData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <SwipeableDrawer
                variant="persistent"
                disableDiscovery={true}
                disableSwipeToOpen={true}
                anchor={anchor}
                open={state.pickupList[anchor]}
                onClose={() => false}
                onOpen={() => drawerHandler(anchor, true)}
                classes={{ paper: classes.shipmentList }}
                dir={theme.direction}
            >
                {state.pickupList[anchor] && (
                    <PickupList
                        type={paymentType}
                        // selected={paymentPickups}
                        done={() => drawerHandler(anchor, false)}
                        variables={watch()}
                        refetchPickupDataQuery={refetchPickupDataQuery}
                    />
                )}
            </SwipeableDrawer>


            <SwipeableDrawer
                disableDiscovery={true}
                disableSwipeToOpen={true}
                anchor={anchor}
                open={barcodeDrawerState[anchor]}
                onClose={() => false}
                onOpen={() => barcodeDrawerHandler(anchor, true)}
                sx={{
                    width: "auto",
                    "& .MuiDrawer-paper": {
                        width: { md: "50%" },

                        height: "100%",
                        overflow: "hidden",
                    },
                }}
                dir={theme.direction}
            >
                {barcodeDrawerState[anchor] &&
                    <BarcodePickupSelector
                        type={paymentType}
                        refetchPickupDataQuery={refetchPickupDataQuery}
                        done={() => barcodeDrawerHandler(anchor, false)}
                        variables={watch()}
                    />}
            </SwipeableDrawer>
            <EnhancedTableToolbarForm
                {...props}
                title={t("pickupsNumber")}
                addShipment={() => drawerHandler(anchor, true)}
                barcode={() => barcodeDrawerHandler(anchor, true)}
                approved={paymentData?.approved}
            />

            <TableFixedHeaderWraper>
                {loadingPickupData ?
                    <FullScreenLoading minHeight={"100px"} /> : pickupData?.pickedPickup && (
                        <ShipmentsTable
                            data={pickupData?.pickedPickup}
                            headCells={paymentPickupsDetails.tableHead}
                            parseBodyCell={paymentPickupsDetails.tableBody}
                            total={paymentPickupsDetails.tableTotal}
                            sumRow={paymentPickupsDetails?.sum}
                            sumTableNumber={paymentPickupsDetails?.sumTableNumber}
                        // sum={true}
                        />
                    )}
            </TableFixedHeaderWraper>
            {pickupData.pickedPickup?.length > 0 && (
                <MUITablePagination
                    count={pickupData.total}
                    rowsPerPage={pickupData.rowsPerPage}
                    page={pickupData.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}

            {(!watch("id") || !disableForm) && (
                <Box className={classes.overlay}>
                    <Typography color="textPrimary" variant="body1">
                        {t("saveManifestPickupFirst")}
                    </Typography>
                </Box>
            )}
            <Grid container justifyContent="center" alignItems="center">
                {!loadingPickupData && pickupData?.pickedPickup?.length === 0 && watch("id") && disableForm && (
                    <EmptyTableMessage
                        message={t("noResult")}
                    />
                )}
            </Grid>
        </>
    )
}

export const ConciergeRequestTableForm = (props) => {
    const {
        watch,
        disableForm,
        paymentType,
        paymentData,
        conciergeRequestsDetails,
        classes,
        conciergeRequestsData,
        setConciergeRequestsData,
        loadingConciergeData,
        refetchConciergeDataQuery,
        getConciergeData,
    } = props

    const screenWidth = useWidth();
    const anchor = screenWidth === "xs" ? "bottom" : "right";
    const { t } = useTranslation()
    const theme = useTheme()
    const handleChangePage = (event, newPage) => {
        setConciergeRequestsData(prev => {
            return {
                ...prev,
                page: newPage
            }
        })
    };

    const handleChangeRowsPerPage = (event) => {
        setConciergeRequestsData(prev => {
            return {
                ...prev,
                page: 0,
                rowsPerPage: event.target.value
            }
        })
    };

    const [state, setState] = useState({
        conciergeRequestList: {
            left: false,
            bottom: false,
            right: false,
        },
    });


    const drawerHandler = (anchor, open) => {
        setState((prev) => ({
            ...prev,
            conciergeRequestList: { ...prev.conciergeRequestList, [anchor]: open },
        }));
    };

    const [barcodeDrawerState, setBarcodeDrawerState] = useState({
        left: false,
        bottom: false,
        right: false,
    });

    const barcodeConciergeRequestDrawerHandler = (anchor, open) => {
        setBarcodeDrawerState((prev) => ({
            ...prev,
            [anchor]: open,
        }));
    };
    useEffect(() => {
        conciergeRequestsData.noData && watch("id") && getConciergeData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <SwipeableDrawer
                variant="persistent"
                disableDiscovery={true}
                disableSwipeToOpen={true}
                anchor={anchor}
                open={state.conciergeRequestList[anchor]}
                onClose={() => false}
                onOpen={() => drawerHandler(anchor, true)}
                classes={{ paper: classes.shipmentList }}
                dir={theme.direction}
            >
                {state.conciergeRequestList[anchor] && (
                    <ConciergeRequestsList
                        refetchConciergeDataQuery={refetchConciergeDataQuery}
                        type={paymentType}
                        done={() => drawerHandler(anchor, false)}
                        variables={watch()}
                    />
                )}
            </SwipeableDrawer>

            <SwipeableDrawer
                disableDiscovery={true}
                disableSwipeToOpen={true}
                anchor={anchor}
                open={barcodeDrawerState[anchor]}
                onClose={() => false}
                onOpen={() => barcodeConciergeRequestDrawerHandler(anchor, true)}
                sx={{
                    width: "auto",
                    "& .MuiDrawer-paper": {
                        width: { md: "50%" },

                        height: "100%",
                        overflow: "hidden",
                    },
                }}
                dir={theme.direction}
            >
                {barcodeDrawerState[anchor] && (
                    <ConciergeRequestsBarcodeSelector
                        refetchConciergeDataQuery={refetchConciergeDataQuery}
                        type={paymentType}
                        done={() => barcodeConciergeRequestDrawerHandler(anchor, false)}
                        variables={watch()}
                    />)}
            </SwipeableDrawer>

            <EnhancedTableToolbarForm
                {...props}
                title={t("totalConciergeRequests")}
                addShipment={() => drawerHandler(anchor, true)}
                barcode={() => barcodeConciergeRequestDrawerHandler(anchor, true)}
                approved={paymentData?.approved}
            />

            <TableFixedHeaderWraper>
                {loadingConciergeData ?
                    <FullScreenLoading minHeight={"100px"} /> : conciergeRequestsData.pickedConciergeRequests && (
                        <ShipmentsTable
                            page={conciergeRequestsData.page}
                            rowsPerPage={conciergeRequestsData.rowsPerPage}
                            data={conciergeRequestsData.pickedConciergeRequests}
                            headCells={conciergeRequestsDetails.tableHead}
                            parseBodyCell={conciergeRequestsDetails.tableBody}
                            total={conciergeRequestsDetails.tableTotal}
                            sumRow={conciergeRequestsDetails?.sum}
                            sumTableNumber={conciergeRequestsDetails?.sumTableNumber}
                        />
                    )
                }
            </TableFixedHeaderWraper>
            {conciergeRequestsData.pickedConciergeRequests?.length > 0 && (
                <MUITablePagination
                    count={conciergeRequestsData.total}
                    rowsPerPage={conciergeRequestsData.rowsPerPage}
                    page={conciergeRequestsData.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}

            {(!watch("id") || !disableForm) && (
                <Box className={classes.overlay}>
                    <Typography color="textPrimary" variant="body1">
                        {t("saveManifestFirst")}
                    </Typography>
                </Box>
            )}
            <Grid container justifyContent="center" alignItems="center">
                {!loadingConciergeData && conciergeRequestsData.total === 0 && watch("id") && disableForm && (
                    <EmptyTableMessage loading={false} />
                )}
            </Grid>
        </>
    )
}
