import React from "react";
import { styled } from '@mui/material/styles';
import { Paper, Typography, Collapse, Table, TableHead, TableRow, TableBody, Toolbar } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { SAVE_LOOKUP_ENTRIES, LIST_LOOKUP_ID } from "./Graphql";
import { useMutation, gql, useQuery } from "@apollo/client";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";

import MuiSwitch from "../HOC/MUI/MUIswitch";
import Grid from "@mui/material/Unstable_Grid2";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Fragment } from "react";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";

const PREFIX = 'LookupEntriesForm';

const classes = {
  loading: `${PREFIX}-loading`,
  paper: `${PREFIX}-paper`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  main: `${PREFIX}-main`,
  field: `${PREFIX}-field`,
  iconColor: `${PREFIX}-iconColor`,
  toolbarTable: `${PREFIX}-toolbarTable`,
  table: `${PREFIX}-table`,
  title: `${PREFIX}-title`,
  spacing: `${PREFIX}-spacing`,
  form: `${PREFIX}-form`,
  spacingTextField: `${PREFIX}-spacingTextField`,
  saveButton: `${PREFIX}-saveButton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.loading}`]: {},

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.toolbarTable}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },
  [`& .${classes.table}`]: {
    display: "grid",
    minWidth: "100%",
    padding: theme.spacing(2)
  },
  [`& .${classes.title}`]: {
    flex: 1,
  },
  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.main}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },
  [`& .${classes.saveButton}`]: {
    margin: theme.spacing(2, 0, 0, 0),
  },
  [`& .${classes.spacingTextField}`]: {
    margin: theme.spacing(1, 0),
  },
  [`& .${classes.form}`]: {
    overflowX: "auto"
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const LookupEntriesForm = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, formState, setValue, setError } =
    useForm();

  const { errors } = formState;
  const lookupId = parseInt(props.match.params.id);

  const [items, setItems] = useState([]);

  const { data: lookupData ,loading:lookupLoading} = useQuery(
    gql`
      ${LIST_LOOKUP_ID.query}
    `,
    {
      skip: !lookupId,
      notifyOnNetworkStatusChange: true,
      variables: { id: lookupId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const lookupEntries = data.lookup.entries;
        setItems(lookupEntries)
        lookupEntries.forEach((lookupEntrie) => {
          lookupEntrie["name"] && setValue(`${lookupEntrie["id"]}.name`, lookupEntrie["name"]);
          lookupEntrie["active"] && setValue(`${lookupEntrie["id"]}.active`, lookupEntrie["active"]);
          lookupEntrie["mobileActive"] && setValue(`${lookupEntrie["id"]}.mobileActive`, lookupEntrie["mobileActive"]);
        })
      },
    }
  );


  const lookup = lookupData?.lookup;

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    for (let i = 0; i < reorderedItems.length; i++) {
      reorderedItems[i].weight = i
    }
    setItems(reorderedItems);
  };

  const [saveLookupEntries, { loading: saveLookupEntriesLoading }] = useMutation(
    gql`
      ${SAVE_LOOKUP_ENTRIES.query}
    `
  );

  const onSubmit = (data) => {
    const entriesData = []
    for (const key in data) {
      data[key].id = Number(key)
      entriesData.push(data[key])
    }
    for (let i = 0; i < entriesData.length; i++) {
      for (let j = 0; j < items.length; j++) {
        if (entriesData[i].id === items[j].id) {
          entriesData[i].weight = items[j].weight
        }
      }
    }

    saveLookupEntries({
      variables: {
        input: {
          id: lookupId,
          entries: entriesData
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/lookup/${data?.data?.updateLookup?.id}`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  return <Root
    container
    justifyContent="center"
    sx={{ margin: "0", width: "100%" }}
  > { lookupLoading? <StyledLoading container item justifyContent="center" className={classes.main}>
  <FullScreenLoading height={"100%"} />
</StyledLoading>
:!lookupData&&lookupId ? (
<NotFound/>

      ):(
      <>
      {!props.id ? (
      <Fragment>
        <TitleAppBar path={props.match.path} />
      </Fragment>
    ) : null}

    <Grid
      container
      justifyContent="center"
      sx={{ width: "100%", p: 2, gap: 2 }}
    > 
        <Fragment>
          <Paper container xs={12} className={classes.paper} component={Grid}>
            <KeyValuePair title={t("name")} value={lookup?.name} />
            <KeyValuePair title={t("code")} value={lookup?.code} />
          </Paper>
          {/* </Fragment> */}
          <Paper
            container
            sm={12}
            md={8}
            className={classes.table}
            component={Grid}
          >
            <Toolbar className={classes.toolbarTable} variant="dense">
              <Typography
                className={classes.title}
                color="inherit"
                variant="h6"
              >
                {t("entries")}
              </Typography>
            </Toolbar>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
              <Grid container justifyContent="flex-end" className={classes.spacing}>
                <TableFixedHeaderWraper component={Paper} >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <FixedTableCell> </FixedTableCell>
                        <FixedTableCell>{t("code")}</FixedTableCell>
                        <FixedTableCell>{t("name")}</FixedTableCell>
                        <FixedTableCell>{t("active")}</FixedTableCell>
                        <FixedTableCell>{t("mobileActive")}</FixedTableCell>
                      </TableRow>
                    </TableHead>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable" direction="vertical">
                        {(provided, snapshot) => (
                          <TableBody {...provided.droppableProps}
                            ref={provided.innerRef}>
                            {items &&
                              items?.map((row, index) => (
                                <Draggable key={row.id} draggableId={row?.code} index={index}>
                                  {(provided, snapshot) => (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={index}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <FixedTableCell>
                                        <DragHandleIcon />
                                      </FixedTableCell>
                                      <FixedTableCell>
                                        {row?.code}
                                      </FixedTableCell>
                                      <FixedTableCell>
                                        <ControlMUItextField
                                          control={control}
                                          errors={errors}
                                          name={`${row?.id}.name`}
                                          label={t("name")}
                                          rules={{ required: t("fieldIsRequired") }}
                                          variant="outlined"
                                          className={classes.spacingTextField}
                                        />
                                      </FixedTableCell>
                                      <FixedTableCell>
                                        <MuiSwitch
                                          edge="end"
                                          name={`${row?.id}.active`}
                                          control={control}
                                        />
                                      </FixedTableCell>
                                      <FixedTableCell>
                                        <MuiSwitch
                                          edge="end"
                                          name={`${row?.id}.mobileActive`}
                                          control={control}
                                        />
                                      </FixedTableCell>
                                    </TableRow>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </TableBody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Table>
                </TableFixedHeaderWraper>
                <FormButton disabled={saveLookupEntriesLoading} className={classes.saveButton}>
                  {saveLookupEntriesLoading ? <ButtonLoading /> : t("save")}
                </FormButton>
              </Grid>
            </form>
          </Paper>
        </Fragment >
     
    </Grid> </>)}
  </Root >;
};

export default LookupEntriesForm;
