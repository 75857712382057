import * as React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { useController } from "react-hook-form";
import { InputAdornment } from "@mui/material";
import { Globals } from "../Classes/Globals";



const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref
) {
    const { onChange, InputProps, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        // prefix={currencyCode}
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function ControlMUItextMoney(props) {
    const {
        name,
        control,
        defaultValue,
        readOnly,
        inputProps,
        rules,
        onChange,
        variant,
        serverValidation,
        label,
        ...restProps
    } = props;
    const { t } = useTranslation();
    const {
        formState: { errors },
        field: { ref, onChange: fieldChange, value, ...fieldProps },
    } = useController({
        name,
        control,
        rules: {
            ...rules,
            ...(rules && {
                validate: {
                    whiteSpace: (value) => {
                        if (value && typeof value === "string") {
                            return !!value.trim() || t("fieldIsRequired");
                        }
                    },
                    ...(rules["validate"] && rules["validate"]),
                },
            }),
        },
        defaultValue: defaultValue ?? "",
    });

    const errorName = name.includes(".") && name.split(".");
    const serverError = errorName ? errorName[1] : name;
    const fieldError = errorName
        ? errors?.[errorName[0]]?.[errorName[1]]
        : errors?.[name];
    const isRequired =
        (rules && rules?.required) ||
        (rules?.validate?.max && typeof rules?.validate?.max() === "string") ||
        (rules?.validate?.require &&
            typeof rules?.validate?.require() === "string");

    const currencyCode = Globals?.settings?.localCurrency?.name ?? "";;
    return (
        <TextField
            inputRef={ref}
            {...fieldProps}
            {...restProps}
            label={isRequired ? label + " *" : label}
            value={value}
            variant={variant || "filled"}
            onChange={(e) => {
                fieldChange(e);
                onChange && onChange(e);
            }}
            name="numberformat"
            id="formatted-numberformat-input"
            fullWidth
            autoComplete="off"
            error={Boolean(fieldError || serverValidation?.[serverError])}
            helperText={
                errors
                    ? fieldError?.message
                    : Boolean(serverValidation) && serverValidation?.[serverError]
                        ? serverValidation?.[serverError][0]
                        : null
            }
            InputProps={{
                inputComponent: NumericFormatCustom,
                readOnly: readOnly,
                endAdornment: (
                    <InputAdornment position="end">{currencyCode}</InputAdornment>
                ),
                ...inputProps,
            }}
            size="small"
        />
    );
}
