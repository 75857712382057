import React, { Fragment, useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  lighten,
  Paper,
  Toolbar,
  Typography,
  Container,
} from "@mui/material";

import { gql, useLazyQuery } from "@apollo/client";
import { Cancel, CheckCircle } from "@mui/icons-material";
import clsx from "clsx";
import * as gqlb from "gql-query-builder";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LIST_BRANCHES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { Can } from "../HOC/CustomComponents/Secured";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUItextField from "../HOC/MUI/MUItextField";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";

const PREFIX = "Stocktake";

const classes = {
  root: `${PREFIX}-root`,
  track: `${PREFIX}-track`,
  typo: `${PREFIX}-typo`,
  mainGrid: `${PREFIX}-mainGrid`,
  paper: `${PREFIX}-paper`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  form: `${PREFIX}-form`,
  chipError: `${PREFIX}-chipError`,
  chipSuccess: `${PREFIX}-chipSuccess`,
  chipContainer: `${PREFIX}-chipContainer`,
  chipContainerBorder: `${PREFIX}-chipContainerBorder`,
  chipHeader: `${PREFIX}-chipHeader`,
  successCount: `${PREFIX}-successCount`,
  errorCount: `${PREFIX}-errorCount`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    // width: '100%',
  },

  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.mainGrid}`]: {
    margin: 0,
    width: "100%",
    // backgroundColor: "#fff",
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    // backgroundColor: "#fff",
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.form}`]: {
    padding: theme.spacing(2),
    width: "100%",
    margin: 0,
  },

  [`& .${classes.chipError}`]: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.error.main + "!important",
    margin: theme.spacing(1),
  },

  [`& .${classes.chipSuccess}`]: {
    color: theme.palette.getContrastText(theme.palette.success.dark),
    backgroundColor: theme.palette.success.main + "!important",
    margin: theme.spacing(1),
  },

  [`& .${classes.chipContainer}`]: {
    borderBottom: "1px solid lightgray",
    padding: theme.spacing(1),
  },

  [`& .${classes.chipContainerBorder}`]: {
    [theme.breakpoints.up("sm")]: {
      borderRight: "solid 2px lightgray",
    },
  },

  [`& .${classes.chipHeader}`]: {
    fontWeight: 700,
    fontSize: 14,
    padding: theme.spacing(1),
    borderBottom: "2px solid lightgray",
    display: "flex",
  },

  [`& .${classes.successCount}`]: {
    color: theme.palette.success.main,
    fontWeight: 700,
    margin: theme.spacing(0, 1),
  },

  [`& .${classes.errorCount}`]: {
    color: theme.palette.error.main,
    fontWeight: 700,
    margin: theme.spacing(0, 1),
  },
}));

const toolbarClasses = {
  root: `${PREFIX}-root`,
  track: `${PREFIX}-track`,
  typo: `${PREFIX}-typo`,
  mainGrid: `${PREFIX}-mainGrid`,
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [`& .${toolbarClasses.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    width: "100%",
    zIndex: 1,
  },

  [`& .${toolbarClasses.highlight}`]: {
    ...(theme.palette.mode === "light"
      ? {
        color: theme.palette.info.main,
        backgroundColor: lighten(theme.palette.info.main, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.info.main,
      }),
  },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 100%",
  },

  [`& .${toolbarClasses.button}`]: {
    color: theme.palette.success.main,
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { numSelected, done, clear, disabled } = props;
  return (
    <StyledToolbar
      className={clsx(toolbarClasses.root, {
        [toolbarClasses.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={toolbarClasses.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {t("shipment", { count: numSelected })}
        </Typography>
      ) : (
        <Typography
          className={toolbarClasses.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t("shipments")}
        </Typography>
      )}
      {/* <Tooltip title="تم" > */}
      <IconButton
        aria-label="done"
        disabled={disabled}
        onClick={done}
        className={toolbarClasses.button}
        size="large"
      >
        <CheckCircle />
      </IconButton>
      {/* </Tooltip> */}

      <IconButton
        aria-label="close"
        onClick={() => clear()}
        color={"primary"}
        size="large"
      >
        <Cancel />
      </IconButton>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  done: PropTypes.func.isRequired,
};

//*********Table Function*********

const SHIPMENT_ID = gqlb.query({
  operation: "shipment",
  fields: ["id", "code"],
  variables: {
    code: {
      type: "String",
    },
  },
});

const SHIPMENT_QUERY = gqlb.query({
  operation: "listShipments",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["lastPage"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
    },

    input: {
      type: "ListShipmentsFilterInput",
    },
  },
});

const queryBuilder = (pageNumber) => {
  const queryArray = [];
  for (let index = 0; index < pageNumber; index++) {
    const initialQuery = {
      operation: `page${index + 1}:listShipments`,
      fields: [
        {
          data: ["id", "code"],
        },
      ],
      variables: {
        [`page${index + 1}`]: {
          name: "page",
          type: "Int",
          value: index + 1,
        },
        first: {
          type: "Int",
        },
        input: {
          type: "ListShipmentsFilterInput",
        },
      },
    };
    queryArray.push(initialQuery);
  }
  return queryArray;
};

const Stocktake = (props) => {
  const storageShipments = localStorage.getItem("stocktake");
  const parsedStorageData = storageShipments
    ? JSON.parse(storageShipments)
    : [];
  const [shipments, setShipments] = useState(parsedStorageData);
  const [stocktakingState, setStocktakingState] = useState(
    storageShipments ? "start" : "initial"
  );

  const [pagesNumber, setPagesNumber] = useState(1);
  const [shipmentsState, setShipmentsState] = useState({
    inStockAndSystem: 0,
    notInSystem: 0,
    notInStock: 0,
  });
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
  });
  const [dialog, setDialog] = useState(false);

  const closeDialog = () => setDialog(false);
  const openDialog = () => setDialog(true);

  useEffect(() => {
    if (shipments.length > 0) {
      let initialState = {
        inStockAndSystem: 0,
        notInSystem: 0,
        notInStock: 0,
      };
      shipments.forEach((i) => {
        if (i.inStock && i.inSystem) initialState["inStockAndSystem"]++;
        if (!i.inStock && i.inSystem) initialState["notInSystem"]++;
        if (i.inStock && !i.inSystem) initialState["notInStock"]++;
      });
      setShipmentsState(initialState);
    }

    return () => { };
  }, [shipments]);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { register, setValue, control, watch } = useForm();

  const [getShimentIdQuery, { loading: getShipmentIdLoad }] = useLazyQuery(
    gql`
      ${SHIPMENT_ID.query}
    `,
    {
      notifyOnNetworkStatusChange: true,

      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        const shipment = data.shipment;
        if (shipment === null) {
          return enqueueSnackbar(t("noShipmentWithCode"), {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
        shipments.push({
          id: shipment.id,
          code: shipment.code,
          inStock: true,
          inSystem: false,
        });

        setValue("code", "");

        setShipments([...shipments]);
        localStorage.setItem("stocktake", JSON.stringify(shipments));
      },
    }
  );
  const [getPagesNumber, { loading: pageNumberLoading }] = useLazyQuery(
    gql`
      ${SHIPMENT_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: 100,
        input: {
          inWarehouse: true,
          statusCode: ["DEX", "RITS", "HTR", "RTS"],
        },
      },
      onCompleted: (data) => {
        setPagesNumber(data.listShipments.paginatorInfo.lastPage);
        getListShipments({
          variables: {
            input: {
              ...(watch("branchId") && { branchId: watch("branchId") }),
              inWarehouse: true,
              statusCode: ["DEX", "RITS", "HTR", "RTS"],
            },
          },
        });
      },
    }
  );
  const shpmentsQueryBody = pagesNumber && queryBuilder(pagesNumber);
  const LIST_SHIPMENT_QUERY = gqlb.query(shpmentsQueryBody);

  const [getListShipments, { loading }] = useLazyQuery(
    gql`
      ${LIST_SHIPMENT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        ...LIST_SHIPMENT_QUERY.variables,
        first: 100,
        input: {
          inWarehouse: true,
          statusCode: ["DEX", "RITS", "HTR", "RTS"],
        },
      },

      onCompleted: (data) => {
        let listShipments = [];
        Object.keys(data).forEach((i) => {
          listShipments = listShipments.concat(data[i]["data"]);
        });

        const updatedList = listShipments.map((i) => ({
          ...i,
          inSystem: true,
          inStock: false,
        }));
        setShipments(updatedList);
        document.getElementsByName("code")[0].focus();
      },
    }
  );

  const getShimentId = (code) => {
    return getShimentIdQuery({
      variables: { code },
    });
  };

  const shipmentsHandler = (e) => {
    const value = e.target.value;
    if (e.key === "Enter" && value) {
      const shipmentIndex = shipments.findIndex(
        (i) => i.code.toLowerCase() === value.toLowerCase()
      );
      if (shipmentIndex !== -1) {
        shipments[shipmentIndex] = {
          ...shipments[shipmentIndex],
          inStock: true,
        };
      } else {
        return getShimentId(value);
      }

      setValue("code", "");
      setShipments([...shipments]);
      localStorage.setItem("stocktake", JSON.stringify(shipments));
    }
    // if (duplicatedCode !== -1) {
    //   return;
    // }
  };

  const openNewWindow = (id) => {
    window.open(`${window.location.origin}/admin/shipments/${id}`);
  };
  const newStocktak = () => {
    setShipments([]);
    localStorage.removeItem("stocktake");
    localStorage.removeItem("stocktakeBranch");
    setStocktakingState((prev) => (prev === "initial" ? "start" : "initial"));
    closeDialog();
  };

  // const getPagesNumberQuery = (id) => {
  //   getPagesNumber
  // }

  let tableBody = null;

  const shipmentLength = shipments.length > 0;
  const DividerComponent = (props) => {
    const { style, ...rest } = props;
    return (
      <Divider
        sx={{
          backgroundColor: "lightgray",
          width: "100%",
          height: "2px !important",
          margin: "8px 0",
          opacity: 1,
          ...style,
        }}
        {...rest}
      />
    );
  };

  if (shipmentLength) {
    // if (true) {
    tableBody = (
      <Paper
        component={Grid}
        sx={{ width: "100%", m: 0 }}
        alignContent="center"
        container
      >
        <Grid
          alignContent="flex-start"
          className={clsx(classes.chipContainer, classes.chipContainerBorder)}
          sm={6}
          xs={12}
        >
          <Grid
            className={classes.chipHeader}
            xs={12}
            alignContent="flex-start"
            justifyContent="space-between"
          >
            <Grid xs={12} sm={4}>
              <Typography color={"text.primary"} variant="h6">{t("system")}</Typography>
            </Grid>
            <Grid xs={12} sm={4}>
              <Typography color={"text.primary"} variant="h6">
                <Box component="span" fontSize={12}>
                  {t("match")}
                </Box>
                <Box component="span" className={classes.successCount}>
                  {shipmentsState.inStockAndSystem}
                </Box>
              </Typography>
            </Grid>
            <Grid xs={12} sm={4}>
              <Typography color={"text.primary"} variant="h6">
                <Box component="span" fontSize={12}>
                  {t("different")}
                </Box>
                <Box component="span" className={classes.errorCount}>
                  {shipmentsState.notInSystem}
                </Box>
              </Typography>
            </Grid>
          </Grid>

          <Grid alignContent="flex-start">
            {shipments?.map((shipment, index) => {
              return (
                shipment.inSystem &&
                !shipment.inStock && (
                  <Chip
                    key={shipment.id}
                    className={classes.chipError}
                    size="small"
                    clickable
                    onClick={() => openNewWindow(shipment.id)}
                    label={shipment.code}
                  />
                )
              );
            })}
          </Grid>
          <Grid alignContent="flex-start">
            {shipmentsState.inStockAndSystem && shipmentsState.notInSystem ? (
              <DividerComponent />
            ) : null}

            {shipments?.map((shipment, index) => {
              return (
                shipment.inSystem &&
                shipment.inStock && (
                  <Chip
                    key={shipment.id}
                    className={classes.chipSuccess}
                    size="small"
                    clickable
                    onClick={() => openNewWindow(shipment.id)}
                    label={shipment.code}
                  />
                )
              );
            })}
          </Grid>
        </Grid>

        <Grid
          alignContent="flex-start"
          className={classes.chipContainer}
          sm={6}
          xs={12}
        >
          <Grid
            className={classes.chipHeader}
            xs={12}
            alignContent="flex-start"
            justifyContent="space-between"
          >
            <Grid xs={12} sm={4}>
              <Typography color={"text.primary"} variant="h6">{t("actual")}</Typography>
            </Grid>
            <Grid xs={12} sm={4}>
              <Typography color={"text.primary"} variant="h6">
                <Box component="span" fontSize={12}>
                  {t("match")}
                </Box>
                <Box component="span" className={classes.successCount}>
                  {shipmentsState.inStockAndSystem}
                </Box>
              </Typography>
            </Grid>
            <Grid xs={12} sm={4}>
              <Typography color={"text.primary"} variant="h6">
                <Box component="span" fontSize={12}>
                  {t("different")}
                </Box>
                <Box component="span" className={classes.errorCount}>
                  {shipmentsState.notInStock}
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid alignContent="flex-start">
            {shipments?.map((shipment, index) => {
              return (
                !shipment.inSystem &&
                shipment.inStock && (
                  <Chip
                    key={shipment.id}
                    className={classes.chipError}
                    size="small"
                    clickable
                    onClick={() => openNewWindow(shipment.id)}
                    label={shipment.code}
                  />
                )
              );
            })}
          </Grid>

          <Grid alignContent="flex-start">
            {shipmentsState.inStockAndSystem && shipmentsState.notInStock ? (
              <DividerComponent />
            ) : null}

            {shipments?.map((shipment, index) => {
              return (
                shipment.inSystem &&
                shipment.inStock && (
                  <Chip
                    key={shipment.id}
                    className={classes.chipSuccess}
                    size="small"
                    clickable
                    onClick={() => openNewWindow(shipment.id)}
                    label={shipment.code}
                  />
                )
              );
            })}
          </Grid>
        </Grid>
      </Paper>
    );
  }
  return (
    <Root>
      <TitleAppBar path={props.match.path} />

      {props.children}
      <Paper sx={{ p: 2 }}>
        <Container maxWidth="xl">
          <CustomDialog
            fullWidth
            maxWidth="xs"
            onClose={closeDialog}
            content={t("newStocktakeConfirmation")}
            open={dialog}
            actions={
              <Fragment>
                <Button color="primary" onClick={closeDialog}>
                  {t("cancel")}
                </Button>
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={() => newStocktak()}
                >
                  {t("confirm")}
                </Button>
              </Fragment>
            }
          />

          <Grid container justifyContent="center" className={classes.mainGrid}>
            <Can permission="shipping.shipment.filter_by_branch" showException>
              <Grid sx={{ p: 1 }} justifyContent="center" xs={6} sm={5}>
                <CustomAutocomplete
                  name={"branchId"}
                  label={t("currentBranch")}
                  control={control}
                  parseData={(data) => data}
                  query={LIST_BRANCHES_DROPDOWN.query}
                  disabled={stocktakingState === "start"}
                  onChangeValue={(e) =>
                    localStorage.setItem("stocktakeBranch", JSON.stringify(e))
                  }
                  onCompleted={() => {
                    document.getElementsByName("code")[0].focus();
                    const savedBranch = JSON.parse(
                      localStorage.getItem("stocktakeBranch")
                    );

                    savedBranch &&
                      setAutocompleteValues((prev) => ({
                        branch: savedBranch,
                      }));
                  }}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>
            </Can>
            <Grid sx={{ p: 1 }} justifyContent="center" xs={6} sm={5}>
              <MUItextField
                disabled={stocktakingState === "initial" || !shipmentLength}
                label={t("barcode")}
                autoFocus
                name={"code"}
                register={register}
                inputProps={{
                  dir: "ltr",
                }}
                readOnly={getShipmentIdLoad}
                InputProps={{
                  autoFocus: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      {getShipmentIdLoad && (
                        <CustomSpinner name="PulseLoader" size={5} margin={2} />
                      )}
                    </InputAdornment>
                  ),
                }}
                defaultValue=""
                onKeyPress={shipmentsHandler}
              />
            </Grid>
            <Grid sx={{ p: 1, display: "flex" }} justifyContent="center" xs={12} sm={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"

                onClick={() => {
                  if (stocktakingState === "start") {
                    openDialog();
                  } else {
                    getPagesNumber({
                      variables: {
                        input: {
                          inWarehouse: true,
                          statusCode: ["DEX", "RITS", "HTR", "RTS"],
                          ...(watch("branchId") && { branchId: watch("branchId") }),
                        },
                      },
                    });

                    setStocktakingState((prev) =>
                      prev === "initial" ? "start" : "initial"
                    );
                  }
                }}
              >
                {stocktakingState === "initial"
                  ? t("startStocktake")
                  : t("newStocktake")}
              </Button>
            </Grid>

            {loading || pageNumberLoading ? (
              <FullScreenLoading minHeight={"20%"} />
            ) : (
              tableBody
            )}
          </Grid>
        </Container>
      </Paper>
    </Root>
  );
};

export default Stocktake;
