import * as gqlb from "gql-query-builder";

export const SEARCH = gqlb.query({
    operation: "universalSearch",
    fields: [
        {
            operation: "...on WarehouseManifest",
            fields: ["id", "code", "__typename", {
                operation: "WarehouseManifestTransactionType:transactionType",
                fields: [{
                    type: [
                        "code"
                    ]
                }],
                variables: {},
            }],
            variables: {},
        },
        {
            operation: "...on Invoice",
            fields: ["id", "code", "__typename"],
            variables: {},
        },
        {
            operation: "...on Pickup",
            fields: ["id", "code", "__typename"],
            variables: {},
        },
        {
            operation: "...on Collection",
            fields: ["id", "code", "__typename", {
                type: [
                    "code"
                ]
            }],
            variables: {},
        },
        {
            operation: "...on Shipment",
            fields: ["id", "code", "__typename"],
            variables: {},
        },
        {
            operation: "...on ConciergeRequest",
            fields: ["id", "code", "__typename", { type: ["code"] }],
            variables: {},
        },
        {
            operation: "...on Payment",
            fields: ["id", "code", "__typename", {
                type: [
                    "code"
                ]
            }],
            variables: {},
        },
        {
            operation: "...on Manifest",
            fields: [
                "id",
                "code",
                "__typename",
                {
                    operation: "manifestTransactionType:transactionType",
                    fields: [{
                        type: [
                            "code"
                        ]
                    }],
                    variables: {},
                },
            ],
            variables: {},
        },
    ],
    variables: {
        keyword: {
            type: "String",
            required: "true"
        }
    },
});