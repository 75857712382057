import { Collapse, IconButton, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { DELETE_INVOICES_ENTRIES_MUTATION } from "./InvoicesListQuary";
import { useSnackbar } from "notistack";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { invoice } from "./listInvoicesFields";
import GenerateShipmentCell from "./GenerateShipmentCell";
import { getShipmentsTableHeader } from "./listInvoicesFields";
import GenerateShipmentSumCellInvoice from "./GenerateShipmentSumCell";
// import GenerateShipmentCellInvoice from "./GenerateShipmentCell";
const PREFIX = "useInvoiceDetails";
const classes = {
  barcode: `${PREFIX}-barcode`,

};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 32,
  },

}));

export const tablesum = (localDataKey, TotalData) => {
  const sumTable = [
    {
      key: "collectedAmount",
      value: TotalData?.collectedAmount,
    },
    {
      key: "weight",
      value: TotalData?.sumEntries.weight,
    },
    {
      key: "piecesCount",
      value: TotalData?.sumEntries.pieces,
    },
    {
      key: "totalAmount",
      value: TotalData?.totalAmount,
    },
    {
      key: "collectedFees",
      value: TotalData?.collectedFees,
    },
    {
      key: "returningDueFees",
      value: TotalData?.dueFees,
    },
    // {
    //   key: "paidAmount",
    //   value: 0,
    // },
    {
      key: "customerDueAmount",
      value: TotalData?.paymentAmount,
    },
    {
      key: "deliveredAmount",
      value: TotalData?.deliveredAmount,
    },
    {
      key: "deliveryFees",
      value: TotalData?.sumEntries?.deliveryFees,
    },
    {
      key: "postFees",
      value: TotalData?.sumEntries?.postFees,
    },
    {
      key: "tax",
      value: TotalData?.sumEntries?.tax,
    },
    {
      key: "allFees",
      value: TotalData?.sumEntries?.allFees,
    },
  ];
  let selected = [];
  localDataKey.forEach((e, index) => {
    const selectBody = sumTable.filter((ele, i) => ele.key === e);
    selected[selectBody[0] ? selectBody[0].key : `${index}key`] = selectBody[0]
      ? selectBody[0].value
      : " ";
  });

  return selected;
};
const useInvoicesDetails = ({
  approved,
  setShipmentData: removeShipment,
  refetchShipmentDataQuery,
  refetch,
  TotalData,
  print
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const localDataKey = localStorage.getItem(`shipmentInvoice`)
    ? JSON.parse(localStorage.getItem(`shipmentInvoice`))
    : invoice;

  const [deleteCollectionEntries, { loading: deleteCollectionEntriesLoading }] =
    useMutation(
      gql`
        ${DELETE_INVOICES_ENTRIES_MUTATION.query}
      `,
      {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
      }
    );

  const deleteShipment = (shipment) => (
    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={!approved || shipment.shipment?.pendingDelete}
        onClick={() => {
          removeShipment((prev) => {
            const shipments = prev.pickedShipment.map((e) => ({
              ...e,
              shipment: {
                ...e.shipment,
                pendingDelete:
                  e.shipment.id === shipment.shipment.id
                    ? true
                    : e.shipment.pendingDelete,
              },
            }));
            return {
              ...prev,
              pickedShipment: shipments,
            };
          });
          deleteCollectionEntries({
            variables: {
              input: {
                invoiceId: TotalData.id,
                shipmentIds: [shipment.shipment.id],
              },
            },
          })
            .then(() => {
              refetchShipmentDataQuery();
              refetch();
              enqueueSnackbar(t("successfullyDeletedRecord"), {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
            })
            .catch((error) => {
              enqueueSnackbar(error.message, {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
            });
        }}
      >
        {deleteCollectionEntriesLoading && shipment.shipment?.pendingDelete ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );

  const tableBodyCell = (invoiceShipment, index) => {
    // let table = tableBody(localDataKey, invoiceShipment);
    return (
      <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{index + 1}</FixedTableCell>
        {localDataKey.map((i,index) =>  <Fragment key={index}>
          <GenerateShipmentCell shipment={invoiceShipment} elementKey={i} classes={classes} print={print} />
        </Fragment>)}
        {removeShipment && deleteShipment(invoiceShipment)}
      </StyledTableRow>
    );
  };

  const tableHeadCell = () => {
    if (localDataKey) {
      let table =  getShipmentsTableHeader(localDataKey);
      const removed = removeShipment ? "" : null;
      return ["#", ...table, removed];
    }
  };
  const TableCell = tableHeadCell();

  const sumRow =
    <TableRow>
      <FixedTableCell> </FixedTableCell>

      {localDataKey && localDataKey.map((i, index) =>
        <Fragment key={index}>
          {TotalData && <GenerateShipmentSumCellInvoice
            shipment={TotalData} elementKey={i} 
          />}
        </Fragment>
      )}
      {removeShipment&& <FixedTableCell> </FixedTableCell>}
    </TableRow >


  /* --------------------------------- DLVBY --------------------------------- */

  /* --------------------------------- Manifests Object --------------------------------- */

  const details = {
    id: "1",
    customer: true,
    label: t("manifestDetails"),
    tableHead: TableCell,
    tableBody: tableBodyCell,
    tableTotal: sumRow,
    // sumTableNumber,
    // totalTest:totalTest
  };

  return {
    details,
  };
};

export default useInvoicesDetails;
