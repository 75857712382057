import { IconButton, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Delete } from "@mui/icons-material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import Collapse from "@mui/material/Collapse";
import { DELETE_MANIFEST_ENTRIES_MUTATION } from "./ManifestGraphQl";
import GenerateShipmentCell from "./GenerateShipmentCell";
import { getShipmentsTableHeader, initialManifest } from "./listManifestFields";
import GenerateShipmentSumCell from "./GenerateShipmentSumCell";
import { Globals } from "../HOC/Classes/Globals";

const PREFIX = "useManifestDetails";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
  barcode: `${PREFIX}-barcode`,

};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 32,
  },

}));
const useManifestDetails = ({
  type,
  typeData,
  approved,
  manifestId,
  refetchShipmentDataQuery,
  setShipmentData: removeShipment,
  saveManifestLoad,
  print
}) => {
  const { t } = useTranslation();
  const adminNotesPermission = Globals?.user?.hasPermission(
    "shipping.shipment.view_admin_note"
  );
  const { enqueueSnackbar } = useSnackbar();

  const [deleteManifestEntries, { loading: deleteManifestEntriesLoading }] = useMutation(
    gql`
      ${DELETE_MANIFEST_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );
  const localDataKey = localStorage.getItem(`shipmentManifest${type}`) ? JSON.parse(localStorage.getItem(`shipmentManifest${type}`)) : initialManifest[type]

  const deleteShipment = (manifestShipment) => (
    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={approved || saveManifestLoad}
        onClick={() => {
          removeShipment(prev => {
            const shipments = prev.pickedShipment.map(e => ({
              ...e,
              shipment: {
                ...e.shipment,
                pendingDelete: e.shipment.id === manifestShipment.shipment.id ? true : e.shipment.pendingDelete
              }
            }));
            return {
              ...prev,
              pickedShipment: shipments
            }
          })
          deleteManifestEntries({
            variables: {
              input: {
                manifestId: manifestId,
                shipmentIds: [manifestShipment.shipment.id]
              }
            }
          }).then(() => {
            refetchShipmentDataQuery()
            enqueueSnackbar(t("successfullyDeletedRecord"), {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          }).catch((error) => {
            enqueueSnackbar(error.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          })
        }}
      >
        {deleteManifestEntriesLoading && manifestShipment.shipment?.pendingDelete ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );
let permission=adminNotesPermission??localDataKey?.includes("adminNotes")
  if(!permission&&localDataKey){

      let updateStorage = localDataKey.filter(element => !["adminNotes"].includes(element));
    localStorage.setItem(`shipmentManifest${type}`, JSON.stringify(updateStorage));
  }

  /* --------------------------------- PKM --------------------------------- */
  const shipmentTableBody = (manifestShipment, index, shipmentNumber) => {
    return (
      <StyledTableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={manifestShipment?.shipment.id}
      >
        <FixedTableCell>{shipmentNumber ? shipmentNumber(index) : (index + 1)}</FixedTableCell>
        {localDataKey && localDataKey.map((ele, i) => {
          return <Fragment key={i}>
            <GenerateShipmentCell shipment={manifestShipment} elementKey={ele} classes={classes} type={type} Permission={adminNotesPermission?adminNotesPermission:localDataKey?.includes("adminNotes")}print={print} />
          </Fragment>
        })}
        {removeShipment && deleteShipment(manifestShipment)}
      </StyledTableRow>
    );
  };

  const tableHeadCell = () => {
    if (localDataKey) {
      let table = getShipmentsTableHeader(localDataKey, type,permission)
      const removed = removeShipment ? "" : null;
      return ["#", ...table, removed]
    }
  }
  const TableCell = tableHeadCell()
  const sumRow =
    <TableRow>
      <FixedTableCell> </FixedTableCell>

      {localDataKey && localDataKey.map((i, index) =>
        <Fragment key={index}>
          {typeData && <GenerateShipmentSumCell
            shipment={typeData} elementKey={i} type={type} Permission={permission}
          />}
        </Fragment>
      )}
      {removeShipment && <FixedTableCell> </FixedTableCell>}
    </TableRow >
  /* --------------------------------- Manifests Object --------------------------------- */
  const manifestsObject = {
    PKM: {
      id: "1",
      customer: true,
      label: t("pickupLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: sumRow,
    },
    RITS: {
      id: "3",
      customer: false,
      label: t("receivedInStore"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: sumRow,
    },
    PRP: {
      id: "7",
      customer: false,
      label: t("packagingList"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: sumRow,
    },
    BMT: {
      id: "4",
      customer: false,
      label: t("branchTransfareTo"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: sumRow,
    },
    BMR: {
      id: "5",
      customer: false,
      label: t("branchTransfareFrom"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: sumRow,
    },
    OTD: {
      id: "6",
      customer: false,
      label: t("outForDeliveryLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: sumRow,
    },
    STD: {
      id: "6",
      customer: false,
      label: t("outForDeliveryLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: sumRow,
    },
    DEX: {
      id: "8",
      sumTableNumber: null,
      customer: false,
      label: t("deliveryException"),
    },
    HTR: {
      id: "9",
      customer: false,
      label: t("retryDeliveryLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: sumRow,
    },
    RCV: {
      id: "9",
      customer: false,
      label: t("retryDeliveryLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: sumRow,
    },
    RTS: {
      id: "10",
      customer: false,
      label: t("returnToCustomer"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: sumRow,
    },
    OTR: {
      id: "11",
      customer: true,
      label: t("returnDelivery"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: sumRow,

    },
    RTRN: {
      id: "12",
      customer: true,
      label: t("returnHandlingLists"),
      tableHead: TableCell,
      tableBody: shipmentTableBody,
      tableTotal: sumRow,
    },
  };

  return {
    details: manifestsObject[type],
  };
};

export default useManifestDetails;
