import * as gqlb from "gql-query-builder";

export const fields = [
  "date",
  "code",
  "id",
  "phone",
  "address",
  "amount",
  "accountNumber",
  "collected",
  "collectionFees",
  "commissionAmount",
  "commissionPaid",
  "createdAt",
  "deliveryFees",
  "totalAmount",
  "exchangeRate",
  "latitude",
  "longitude",
  "pendingCollectionAmount ",
  "referenceNumber",
  "updatedAt",
  "localAmount",
  {
    transactionType: [
      "id",
      "code",
      "name",
      {
        type: ["code", "name"],
      },
    ],
  },
  { branch: ["id", "name"] },
  { currency: ["id", "name", "code"] },
  { customer: ["id", "name", { type: ["code"] }] },
  { service: ["id", "name"] },
  { type: ["id", "name", "code"] },
  { provider: ["id", "name", "code"] },
  { zone: ["id", "name", "code"] },
  { subzone: ["id", "name", "code"] },
  { collection: ["id", "code"] },
  // {payment:["id","code"]}, 
  {
    status: ["code", "name"],
  },
  {
    createdBy: ["id", "username"],
  },
  {
    updatedBy: ["id", "username"],
  },
  {
    operation: `payment`,
    fields: [
      "id",
      "code",
      "approved",
      {
        type: ["code", "name"],
      },
    ],
    variables: {},
  },

  {
    operation: `collection`,

    fields: [
      "id",
      "code",
      "approved",
      {
        type: ["code", "name"],
      },
    ],
    variables: {},
  },
  {
    transactions: [
      "id",
      "note",
      "updatedAt",
      "createdAt",
      "deliveryAgentAccepted",
      {
        cancellationReason: ["name", "code"],
      },
      {
        createdBy: ["id", "username"],
      },
      {
        deliveryAgent: ["id", "name"],
      },
      {
        request: ["id", "code"],
      },
      {
        status: ["code", "name", "id"],
      },
      {
        updatedBy: ["id", "username"],
      },
      {
        images: ["path"],
      },
    ],
  },
]

export const CONCIERGE_REQUESTS_LIST = gqlb.query({
  operation: "listConciergeRequests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "total",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: fields,
      variables: {},
    }
  ], variables: {
    input: {
      type: "ListConciergeRequestsFilterInput",
    },
    first: {
      type: "Int",
      value: 10,
    },
    page: {
      type: "Int",
      value: 1,
    },

  },

})


export const SUM_CONCIERGE_REQUESTS_QUERY = gqlb.query({
  operation: "sumConciergeRequests",
  fields: [
    "deliveryFees",
    "collectionFees",
    "localAmount",
    "totalAmount",
    "commissionAmount",
    "pendingCollectionAmount"
  ],
  variables: {
    input: {
      type: "ListConciergeRequestsFilterInput",
    },
  },
})

export const SAVE_CONCIERGE_REQUEST_MUTATION = gqlb.mutation({
  operation: "saveConciergeRequest",
  fields: ["code", "id", "phone"],
  variables: {
    input: {
      type: "ConciergeRequestInput",
      required: true,
    },
  },
});

export const DELETE_CONCIERGE_REQUEST_MUTATION = gqlb.mutation({
  operation: "deleteConciergeRequest",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
})


export const CONCIERGE_REQUEST_BY_ID_QUERY = gqlb.query({
  operation: "conciergeRequest",
  fields: [...fields, "deletable", "editable"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});


export const updateConciergeRequestStatusMutationBuilder = (ids) => {
  const queryArray = [];
  for (const id of ids) {
    const initialQuery = {
      operation: `concierge_${id}:updateConciergeRequestStatus`,
      fields: ["id"],
      variables: {
        ["input" + id]: {
          name: "input",
          type: "UpdateConciergeRequestStatusInput",
          required: true,
        },
      },
    };
    queryArray.push(initialQuery);
  }
  return gqlb.mutation(queryArray);
};
export const CALCULATE_FEES_QUERY = gqlb.query({
  operation: "calculateConciergeRequestFees",
  fields: [
    "delivery",
    "collection",
    "localAmount",
    "total",
    "exchangeRate"
  ],
  variables: {
    input: {
      type: "CalculateConciergeRequestFeesInput",
      required: true,
    },
  },
});


export const CUSTOMER_ID = gqlb.query([
  {
    operation: "customer",
    fields: [
      "id",
      "name",
      "phone",
      "mobile",
      "address",
      {
        zone: [
          "id",
          "name",
          {
            branch: [
              {
                country: ["id"],
              },
            ],
          },
        ],
      },
      {
        subzone: ["id", "name"],
      },
      {
        type: ["id", "name", "code"],
      },
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "listShippingServicesDropdown",
    fields: ["id", "name"],

    variables: {
      input: {
        type: "ListShippingServicesFilterInput",
      },
    },
  },
]);

export const DELETE_LAST_CONCIERGE_REQUEST_ACTION = gqlb.mutation({
  operation: "deleteLastConciergeRequestAction",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});