import React, { Fragment, useContext, useEffect } from "react";
import withRoot from "./Component/HOC/withRoot";
import "./App.css";
import { withRouter } from "react-router";

import { useTranslation } from "react-i18next";
import config from "./config.json";
// *****Component*****

import CustomeRoute from "./Component/HOC/Routes";
import withErrorBoundarys from "./Error/withErrorBoubdary";
import moment from "moment";
import "moment/locale/ar";
import "moment/locale/ku";
import withApolloProvider from "./Component/HOC/withApolloProvider";
import WebsiteHeader from "./Layout/WebsiteHeader";
import { ModeContext } from "./Context/ModeContext";
import { LANGUAGES_DETAILS } from "./helpers/LanguagesVariables";

function App(props) {
  const { i18n } = useTranslation();

  document.getElementsByTagName("html")[0].setAttribute("dir", i18n.language === "ku" ? "rtl" : i18n.dir());
  const Languages = config.app.languages;

  const { firstLoad } = useContext(ModeContext)

  useEffect(() => {
    const lang = LANGUAGES_DETAILS[i18n.language];
    document.body.style.fontFamily = `${lang.fontFamily}, sans-serif !important`;

    return () => {
      document.body.style.fontFamily = '';
    };
  }, [i18n.language]);

  moment.locale(localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : Languages[0]);

  return (
    <Fragment>
      {document.location.pathname.startsWith("/admin") ? firstLoad && <WebsiteHeader  {...props} /> : <WebsiteHeader  {...props} />}
      <CustomeRoute {...props} />
    </Fragment>
  );
}

export default withErrorBoundarys(
  withRoot(withApolloProvider(withRouter(App)))
);
