import React from "react";
import CellLink from "./CellLink";

const ShipmentRegion = (props) => {
  const { pathname, zone, subzone, ...restProps } = props;

  return (
    <CellLink {...restProps} pathname={pathname}>
      {zone}
      {subzone && " - "}
      {subzone}
    </CellLink>
  );
};

export default ShipmentRegion;
