import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useQuery, gql } from "@apollo/client";
import {
  TableBody,
  TableRow,
  Icon,
  Button,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Add, FilterList, Search } from "@mui/icons-material";

import { Can } from "../HOC/CustomComponents/Secured";
// import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { LIST_PRICE_LIST_PAGED } from "./Graphql";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { useState } from "react";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { useHistory } from "react-router";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import useWidth from "../../Hooks/useWidth";
import { GetPermissionSlug } from "../../helpers/getPermissionSlug";

import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";

const PREFIX = "ListPriceList";

const classes = {
  span: `${PREFIX}-span`,
  iconColor: `${PREFIX}-iconColor`,
  headerTable: `${PREFIX}-headerTable`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.span}`]: {
    display: "inline"
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },
}));

// const DRAWER_WIDTH = 240;

const ListPriceList = (props) => {
  const { t } = useTranslation();

  // const listName = {
  //   B2C: t("listPriceListsCustomers"),
  //   C2C: t("listPriceListsIndividuals"),
  // };

  const [refetch, setrefetch] = useState(true);
  // const theme = useTheme();
  const urlQuery = urlParameters(window.location.search);
  const listType = props.match?.params?.type.toUpperCase();

  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());
  useEffect(() => {
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);

    return () => { };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: `/admin/price-list/${listType.toLowerCase()}`,
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    (refetch) ? setrefetch(false) : setrefetch(true)
    let handledData = {
      name: data.name,
      code: data.code,
      page: 0,
    };

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const { data, loading } = useQuery(
    gql`
      ${LIST_PRICE_LIST_PAGED.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.name && { name: search.name }),
          ...(search.code && { code: search.code }),
          ...(listType && { customerTypeCode: listType }),
        },
      },
    }
  );
  const listPriceLists = data?.listPriceLists?.data;

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const pagePermission = GetPermissionSlug("shipping", "price_list", listType, "list")

  const addURL = () => {
    pushUrl(props, `/admin/price-list/create/${listType.toLowerCase()}`);
  }
  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: addURL,
      icon: Add,
      permission: GetPermissionSlug("shipping", "price_list", listType, "create"),
    },
  ]

  return (
    <Can permission={pagePermission}>
      <Root>
        <ListWrapper
          drawerState={drawerState[filterAnchor]}
          icons={icons}
          path={props.match.path}
          type={listType}
          empty={listPriceLists?.length === 0}
          loading={loading}
          filters={
            <Grid
              container
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              className={classes.filterForm} >
              <Grid
                container
                item
                sm={12}
                justifyContent="flex-start"
                spacing={1}
                className={classes.filterField}
              >
                <Grid item sm={12} alignItems="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"name"}
                    label={t("name")}
                  />
                </Grid>
                <Grid item sm={12} alignItems="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"code"}
                    label={t("code")}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                sm={12}
                className={classes.button}
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="medium"
                  color="primary"
                  startIcon={<Search />}
                >
                  {t("search")}
                </Button>
              </Grid>
            </Grid>
          }
          tableHeaders={[
            {
              name: "name"
            },
            {
              name: "code"
            },
            {
              name: "description"
            },
            {
              name: "active"
            },
            {
              name: "default"
            },

          ]
          }
          tableBody={
            <TableBody>
              {listPriceLists &&
                listPriceLists?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <CellLink pathname={`${row.id}`}>
                        {row?.name}
                      </CellLink>
                      <FixedTableCell>{row.code}</FixedTableCell>
                      <FixedTableCell>{row?.description}</FixedTableCell>
                      <FixedTableCell>
                        {row?.active ? (
                          <Icon className={classes.iconColor}>
                            check_circle_outline
                          </Icon>
                        ) : (
                          <Icon color="error">highlight_off</Icon>
                        )}
                      </FixedTableCell>
                      <FixedTableCell>
                        {row?.default ? (
                          <Icon className={classes.iconColor}>
                            check_circle_outline
                          </Icon>
                        ) : (
                          <Icon color="error">highlight_off</Icon>
                        )}
                      </FixedTableCell>
                      {/* <FixedTableCell>
                      <SecuredNavLink
                        permission={GetCustomerPermissionSlug("shipping", "price_list", listType, "create")}
                        to={{
                          pathname: `/admin/price-list/${row.id}/copy`,
                        }}
                      >
                        <Tooltip title="import Pricelist">
                          <IconButton
                            aria-label="import Pricelist"
                            size="large"
                          >
                            <Icon>content_copy</Icon>
                          </IconButton>
                        </Tooltip>
                      </SecuredNavLink>
                    </FixedTableCell> */}
                    </TableRow>
                  );
                })}
            </TableBody>
          }
          pagination={
            <MUITablePagination
              count={data?.listPriceLists?.paginatorInfo?.total}
              rowsPerPage={rowsPerPage}
              page={!data?.listPriceLists ? 0 : search.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          }
        />
      </Root>
    </Can>
  );
};

export default ListPriceList;
