import { TableRow, TableHead, Table, TableBody } from "@mui/material"
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell"
import CellLink from "../../HOC/CustomComponents/CellLink";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
export const ProductTableForm = (props) => {
  const {
    classes,
    trackingData,
    productList,
    inProduct,
    shipmentTypeCode
  } = props
  const { t } = useTranslation();
  return (
    <Grid xs={12} justifyContent="center" mt={2} p={0}>
      <TableFixedHeaderWraper>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <FixedTableCell>#</FixedTableCell>
              <FixedTableCell>{t("name")}</FixedTableCell>
              <FixedTableCell>{t("quantity")}</FixedTableCell>
              {(trackingData?.status?.code === "DTR" && !inProduct) && (
                <FixedTableCell>{t("delivered")}</FixedTableCell>
              )}
              {(trackingData?.status?.code === "DTR" && !inProduct) && (
                <FixedTableCell>{t("returned")}</FixedTableCell>
              )}
              {(((inProduct && shipmentTypeCode === "RTS") || !inProduct) && <FixedTableCell>{t("price")}</FixedTableCell>)}
              {(((inProduct && shipmentTypeCode === "RTS") || !inProduct) && <FixedTableCell>{t("weight")}</FixedTableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {productList.map((product, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                // className={classes.tableBodyRow}
                >
                  <FixedTableCell>{index + 1}</FixedTableCell>
                  <CellLink
                    align="left"
                    pathname={`/admin/products/${product?.product?.id}`}
                  >
                    {product.product.name}
                  </CellLink>
                  <FixedTableCell>{product.quantity}</FixedTableCell>
                  {(trackingData?.status?.code === "DTR" && !inProduct) && (
                    <FixedTableCell className={clsx(classes.delivered)}>
                      {product?.delivered}
                    </FixedTableCell>
                  )}
                  {(trackingData?.status?.code === "DTR" && !inProduct) && (
                    <FixedTableCell className={clsx(classes.returned)}>
                      {product?.returned}
                    </FixedTableCell>
                  )}
                  {(((inProduct && shipmentTypeCode === "RTS") || !inProduct) && <FixedTableCell>{product.price}</FixedTableCell>)}
                  {(((inProduct && shipmentTypeCode === "RTS") || !inProduct) && <FixedTableCell>
                    {product.product.weight}
                  </FixedTableCell>)}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableFixedHeaderWraper>
    </Grid>
  )
}



