import { Box } from "@mui/material";
import formatMoney from "../../helpers/numbersDot";
import { dateFormatLL } from "../../helpers/dateFunctions";
export const defaultQueryFieldsPayment = (keys, permission) => {
    const defaultKeys = []
    const shipmentFields = []
    const paymentEntriesShipment = (paymentShipmentData) => {
        return {
            operation: `shipment`,
            fields: [
                "id",
                ...paymentShipmentData
            ],
            variables: {}
        }


    };
    const queriesEntries = {
        collectedAmount: {
            queryName: "collectedAmount"
        },
        paidAmount: {
            queryName: "paidAmount"
        },
    }
    const queriesShipment = {
        ...(permission && {
            adminNotes: {
                queryName: "adminNotes"
            }
        }),
        code: {
            queryName: "code"
        },
        description: {
            queryName: "description"
        },
        totalAmount: {
            queryName: "totalAmount"
        },
        senderAddress: {
            queryName: "senderAddress"
        },
        date: {
            queryName: "date"
        },
        deliveredOrReturnedDate: {
            queryName: "deliveredOrReturnedDate"
        },
        recipientAddress: {
            queryName: "recipientAddress"
        },
        refNumber: {
            queryName: "refNumber"
        },
        piecesCount: {
            queryName: "piecesCount"
        },
        price: {
            queryName: "price"
        },
        weight: {
            queryName: "weight"
        },
        deliveredAmount: {
            queryName: "deliveredAmount"
        },
        amount: {
            queryName: "amount"
        },
        attempts: {
            queryName: "attempts"
        },
        status: {
            queryName: {
                status: ["name", "code"],
            }
        },
        lastTransactionDate: {
            queryName: {
                lastTransaction: ["date"],
            }
        },
        transactions: {
            queryName: {

                transactions: [{ returnType: ["name"] }],

            }
        },
        recipientZone: {
            queryName: [
                {
                    recipientZone: ["id", "name"],
                },
                {
                    recipientSubzone: ["name"]
                }
            ]
        },
        senderZone: {
            queryName: [
                {
                    senderZone: ["id", "name"],
                },
                {
                    senderSubzone: ["id", "name"],
                }
            ]
        },
        type: {
            queryName: {
                type: ["name"],
            }
        },
        paymentType: {
            queryName: {
                paymentType: ["name"],
            }
        },
        openable: {
            queryName: {
                openable: ["name", "code"],
            }
        },
        returnType: {
            queryName: {
                returnType: ["name"],
            }
        },
        deliveryType: {
            queryName: [{
                deliveryType: ["name"],

            },
            { status: ["name", "code"] }
            ]
        },
        warehouseSection: {
            queryName: {
                warehouseSection: ["name"],
            }
        },
        note: {
            queryName: "notes"
        },
        pendingCollectionAmount: {
            queryName: "pendingCollectionAmount"
        },
        deliveryCommission: {
            queryName: "deliveryCommission"
        },
        senderName: {
            queryName: "senderName"
        },
        recipientName: {
            queryName: "recipientName"
        },

        sender: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "senderName",
                "senderMobile",
                "senderPhone"
            ]
        },
        recipient: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "recipientName",
                "recipientMobile",
                "recipientPhone"
            ]
        },
        recipientPhone: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "recipientMobile",
                "recipientPhone"
            ]
        },
        senderPhone: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "senderMobile",
                "senderPhone"
            ]
        },
        returningDueFees: {
            queryName: "returningDueFees"
        },
        deliveryDate: {
            queryName: "deliveryDate"
        },
        returnedValue: {
            queryName: "returnedValue"
        },
        cancellationReason: {
            queryName: { cancellationReason: ["name"] }
        },
        customer: {
            queryName: { customer: ["id", "name", "code"], }
        },
        barcode: {
            queryName: "code"
        },
        originBranch: {
            queryName: { originBranch: ["id", "name"], }
        },
        currentBranch: {
            queryName: {
                branch: ["id", "name"],
            },
        },
        collectedFees: {
            queryName: "collectedFees"
        },

    }
    keys.map((keyName) => {
        if (queriesEntries?.[keyName]) {
            if (Array.isArray(queriesEntries[keyName].queryName)) {
                queriesEntries[keyName].queryName.map((i) => defaultKeys.push(i))
            } else {
                defaultKeys.push(queriesEntries[keyName].queryName)
            }
        }
        if (queriesShipment?.[keyName]) {
            if (Array.isArray(queriesShipment[keyName].queryName)) {
                queriesShipment[keyName].queryName.map((i) => shipmentFields.push(i))
            }
            else {
                shipmentFields.push(queriesShipment[keyName].queryName)
            }
        }
        return ''
    })
    let uniqueArray = shipmentFields.filter((item, index, self) => {
        if (typeof item === 'object') {
            // For objects, check if it's not a duplicate by comparing JSON strings
            return self.findIndex(other => JSON.stringify(other) === JSON.stringify(item)) === index;
        } else {
            // For non-objects, remove duplicates as before
            return self.indexOf(item) === index;
        }
    });
    defaultKeys.push(paymentEntriesShipment(uniqueArray))
    return defaultKeys
};

export const shipmentFieldsFun = (paymentType, permission) => {
    const shipmentFields = [
        {
            key: "code",
            label: "code",
            type: "link",
            path: "shipments"
        },

        {
            key: "description",
            label: "packageDescription",
            type: ""
        },
        {
            key: "barcode",
            label: "barcode",
            type: "barcode"
        },
        {
            key: "recipientName",
            label: "recipient",
            type: ""
        },
        {
            key: "senderName",
            label: "senderName",
            type: ""
        },
        {
            key: "note",
            label: "notes",
            type: "",
        },
        {
            key: "recipientZone",
            label: "destination",
            type: "zone"
        },
        {
            key: "deliveredOrReturnedDate",
            label: "deliveryCanclingDate",
            type: ""
        },
        {
            key: "status",
            label: "shipmentStatus",
            type: ""
        },
        {
            key: "deliveryType",
            label: "deliveryType",
            type: ""
        },


    ]
    const shipmentCollection = {
        CUSTM: [
            {
                key: "date",
                label: "date",
                type: ""
            },

            {
                key: "returningDueFees",
                label: "returningDueFees",
                type: "",
                ifSum: "returningDueFees"
            },
            {
                key: "collectedFees",
                label: "collectedFees",
                type: "",
                ifSum: "collectedFees"
            },
            {
                key: "paidAmount",
                label: "customerDueAmount",
                type: "",
                ifSum: "paidAmount"
            },

            {
                key: "deliveredAmount",
                label: "deliveredAmount",
                type: "",
                ifSum: "deliveredAmount"
            },

            {
                key: "piecesCount",
                label: "pieceCount",
                type: "",
                ifSum: "piecesCount"
            },
            {
                key: "weight",
                label: "weight",
                type: "",
                ifSum: "weight"
            },
            {
                key: "recipientPhone",
                label: "recipientPhone",
                type: "phone",
            },
            {
                key: "senderPhone",
                label: "senderPhone",
                type: "phone",
            },
            {
                key: "senderAddress",
                label: "senderAddress",
                type: ""
            },
            {
                key: "type",
                label: "packageType",
                type: ""
            },
            {
                key: "senderZone",
                label: "source",
                type: "zone"
            },
            {
                key: "returnType",
                label: "returnType",
                type: ""
            },
            {
                key: "paymentType",
                label: "paymentType",
                type: ""
            },
            {
                key: "refNumber",
                label: "refNumber",
                type: "refNumber"
            },
            {
                key: "cancellationReason",
                label: "CancellationReasonName",
                type: "",
            },
        ].concat(shipmentFields),
        DLVBY: [
            {
                key: "recipientAddress",
                label: "recipientAddress",
                type: ""
            },
            {
                key: "paidAmount",
                label: "deservedAmount",
                type: "",
                ifSum: "paidAmount"
            },
        ].concat(shipmentFields),
    }
    permission && shipmentCollection["CUSTM"].push({ key: "adminNotes", label: "adminNotes", type: "text" })
    return shipmentCollection[paymentType]
}
export const getListShipmentTable = (paymentShipment, key) => {
    if (key === undefined) {
        return { value: "noVal" }
    }
    const ListShipmentDetails = {
        customer: {
            pathname: `/admin/customers/${paymentShipment?.shipment?.customer?.id}`,
            value: <>
                <Box component="span" fontWeight="bold">{` (${paymentShipment?.shipment?.customer?.code}) `}</Box>
                <Box component="span">  {paymentShipment?.shipment?.customer?.name}</Box>
            </>
        },
        code: {
            pathname: `/admin/shipments/${paymentShipment?.shipment?.id}`,
            value: paymentShipment?.shipment?.code,
            ifCode: "code"
        },
        date: {
            value: dateFormatLL(paymentShipment?.shipment?.date)
        },
        entriesDeliveryDate: {
            value: dateFormatLL(paymentShipment?.deliveryDate)
        },
        deliveryDate: {
            value: dateFormatLL(paymentShipment?.shipment?.deliveryDate)
        },
        deliveredOrReturnedDate: {
            value: dateFormatLL(paymentShipment?.shipment?.deliveredOrReturnedDate)
        },
        description: {
            value: paymentShipment?.shipment?.description,
            condition: "description",
        },
        senderZone: {
            pathname: `/admin/zones/${paymentShipment?.shipment?.senderZone?.id}`,
            zone: paymentShipment?.shipment?.senderZone?.name,
            subzone: paymentShipment?.shipment?.senderSubzone?.name
        },
        recipientZone: {
            pathname: `/admin/zones/${paymentShipment?.shipment?.recipientZone?.id}`,
            zone: paymentShipment?.shipment?.recipientZone?.name,
            subzone: paymentShipment?.shipment?.recipientSubzone?.name
        },
        price: {
            value: formatMoney(paymentShipment.shipment?.price),
        },
        senderAddress: {
            value: paymentShipment.shipment?.senderAddress,
        },
        deliveredAmount: {
            value: formatMoney(paymentShipment.shipment?.deliveredAmount),
        },
        amount: {
            value: formatMoney(paymentShipment?.shipment?.amount)
        },
        totalAmount: {
            value: formatMoney(paymentShipment?.shipment?.totalAmount),
        },
        returningDueFees: {
            value: formatMoney(paymentShipment?.shipment?.returningDueFees)
        },
        entriesPickupAmount: {
            value: formatMoney(paymentShipment?.pickupAmount)
        },
        status: {
            value: paymentShipment.shipment?.status?.name,
        },
        attempts: {
            value: paymentShipment.shipment?.attempts,
        },
        lastTransactionDate: {
            value: dateFormatLL(paymentShipment?.shipment?.lastTransaction?.date)
        },
        transactions: {
            value: paymentShipment?.shipment?.transactions?.find((i) => i.returnType !== null)?.returnType?.name,
        },
        piecesCount: {
            value: formatMoney(paymentShipment?.shipment?.piecesCount)
        },
        commission: {
            value: formatMoney(paymentShipment?.commission)
        },
        collectedAmount: {
            value: formatMoney(paymentShipment?.collectedAmount)
        },
        NetAmount: {
            value: formatMoney(paymentShipment?.collectedAmount - paymentShipment?.commission)
        },
        weight: {
            value: paymentShipment?.shipment?.weight
        },
        type: {
            value: paymentShipment?.shipment?.type?.name
        },
        paymentType: {
            value: paymentShipment?.shipment?.paymentType?.name
        },
        openable: {
            value: paymentShipment?.shipment?.openable?.code === "Y" ? true : false
        },
        entriesCancellationReason: {
            value: paymentShipment.cancellationReason?.name
        },
        cancellationReason: {
            value: paymentShipment.shipment?.cancellationReason?.name
        },
        returnType: {
            value: paymentShipment?.shipment?.returnType?.name
        },
        entriesReturnType: {
            value: paymentShipment?.returnType?.name
        },

        adminNotes: {
            value: paymentShipment?.shipment?.adminNotes
        },
        deliveryType: {
            value: paymentShipment.shipment?.deliveryType?.name ?? paymentShipment.shipment?.status?.name
        },
        entriesWarehouseSection: {
            pathname: `/admin/shipments/${paymentShipment?.warehouseSection?.id}`,
            value: paymentShipment?.warehouseSection?.name
        },
        warehouseSection: {
            pathname: `/admin/shipments/${paymentShipment?.shipment?.warehouseSection?.id}`,
            value: paymentShipment?.shipment?.warehouseSection?.name
        },
        originBranch: {
            pathname: `/admin/branches/${paymentShipment.shipment?.originBranch?.id}`,
            value: paymentShipment.shipment?.originBranch?.name
        },
        currentBranch: {
            pathname: `/admin/branches/${paymentShipment.shipment?.currentBranch?.id}`,
            value: paymentShipment.shipment?.branch?.name
        },
        barcode: {
            value: paymentShipment?.shipment?.code
        },
        senderName: {
            value: paymentShipment?.shipment?.senderName
        },
        sender: {
            name: paymentShipment?.shipment?.senderName,

            mobile: paymentShipment?.shipment?.senderMobile,
            phone: paymentShipment?.shipment?.senderPhone
        },
        note: {
            value: paymentShipment?.shipment?.notes
        },
        entriesNotes: {
            value: paymentShipment?.notes
        },
        refNumber: {
            value: paymentShipment?.shipment?.refNumber
        },
        recipientName: {
            value: paymentShipment?.shipment?.recipientName
        },

        recipientAddress: {
            value: paymentShipment?.shipment?.recipientAddress
        },
        senderPhone: {
            mobile: paymentShipment?.shipment?.senderMobile,
            phone: paymentShipment?.shipment?.senderPhone
        },
        recipient: {
            name: paymentShipment?.shipment?.recipientName,
            mobile: paymentShipment?.shipment?.recipientMobile,
            phone: paymentShipment?.shipment?.recipientPhone
        },
        recipientPhone: {
            mobile: paymentShipment?.shipment?.recipientMobile,
            phone: paymentShipment?.shipment?.recipientPhone
        },
        entriesFees: {
            value: formatMoney(paymentShipment?.fees)
        },
        returnedValue: {
            value: formatMoney(paymentShipment?.shipment?.returnedValue)
        },
        collectedFees: {
            value: formatMoney(paymentShipment?.shipment?.collectedFees)
        },
        paidAmount: {
            value: formatMoney(paymentShipment?.paidAmount)
        },
    }
    return Boolean(ListShipmentDetails[key]) ? ListShipmentDetails[key] : { value: paymentShipment[key] }
}

export const getShipmentsTableHeader = (keys, paymentType, permission) => {
    let tableHeader = []
    keys.map((keyName) =>
        shipmentFieldsFun(paymentType, permission).map((i) =>
            keyName === i.key && tableHeader.push(i.label)
        )
    )
    return tableHeader
}


export const getShipmentsSumQuery = (TotalData, key) => {
    if (key === undefined) {
        return { value: "noVal" }
    }
    const ListShipmentDetails = {
        weight: { value: TotalData?.sumEntriesShipment?.weight },
        piecesCount: { value: TotalData?.sumEntriesShipment?.piecesCount },
        totalAmount: { value: TotalData?.sumEntriesShipment?.totalAmount },


        returningDueFees: { value: TotalData?.sumEntriesShipment?.dueFees },

        price: { value: TotalData?.sumEntriesShipment?.price, },

        deliveredAmount: { value: TotalData?.sumEntriesShipment?.deliveredAmount },

        entriesPickupAmount: { value: TotalData?.sumEntriesShipment?.pickupAmount },

        amount: { value: TotalData?.sumEntriesShipment?.amount, },

        entriesFees: { value: TotalData?.sumEntriesShipment?.fees, },

        returnedValue: { value: TotalData?.sumEntriesShipment?.returnedValue, },
        commission: {
            value: TotalData?.sumEntriesShipment?.commission
        },
        collectedAmount: {
            value: TotalData?.sumEntriesShipment?.collectedAmount
        },
        NetAmount: {
            value: TotalData?.sumEntriesShipment?.collectedAmount - TotalData?.sumEntriesShipment?.commission
        },
        collectedFees: {
            value: TotalData?.sumEntriesShipment?.collectedFees
        },
        paidAmount: {
            value: TotalData?.sumEntriesShipment?.paymentAmount
        },
    }
    return Boolean(ListShipmentDetails[key]) ? ListShipmentDetails[key] : { value: undefined }
}

export const initialPaymentData = {
    DLVBY: [
        "code",
        "deliveredOrReturnedDate",
        "senderName",
        "recipientName",
        "recipientZone",
        "recipientAddress",
        "status",
        "deliveryType",
        "paidAmount",
    ],
    CUSTM: [
        "code",
        "date",
        "deliveredOrReturnedDate",
        "recipientName",
        "recipientPhone",
        "recipientZone",
        "paymentType",
        "status",
        "weight",
        "piecesCount",
        "deliveredAmount",
        "collectedFees",
        "returningDueFees",
        "paidAmount",
    ],



}