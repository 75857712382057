import React from "react";
import {
  FormControl,
  FormHelperText,
  TextField,
  // useTheme
} from "@mui/material";
import { useController } from "react-hook-form";
import { validation } from "../CustomFunctions/validation";
import PropTypes from "prop-types";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const MUITimePicker = (props) => {
  const {
    control,
    disabled,
    name,
    label,
    rules,
    variant,
    onChange,
    value,
    InputProps,
  } = props;
  // const theme = useTheme();
  // const dir = theme.direction
  const {
    formState: { errors },
    field: { ref, value: val, onChange: fieldChange, ...fieldProps },
  } = useController({
    name,
    control,
    rules: { ...rules },
    // defaultValue: defaultValue ?? new Date(),
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl variant="filled" fullWidth size="small">
        <TimePicker
          label={label}
          value={value}
          // inputFormat="dd/MM/yyyy"
          onChange={(newValue) => {
            fieldChange(newValue);
            onChange && onChange(newValue);
          }}
          disabled={disabled}
          renderInput={(props) => {
            return (
              <TextField
                {...props}
                size="small"
                InputProps={{
                  ...props.InputProps,

                  ...(props?.InputProps?.endAdornment && {
                    endAdornment: {
                      ...props?.InputProps?.endAdornment,
                      props: {
                        ...props?.InputProps?.endAdornment.props,
                        children: [
                          props?.InputProps?.endAdornment,
                          InputProps?.endAdornment,
                        ],
                      },
                    },
                  }),
                  // ...(InputProps?.startAdornment && {
                  //   startAdornment: { ...InputProps.startAdornment },
                  // }),
                }}
                variant={variant ?? "filled"}
                helperText={null}
                error={Boolean(validation(errors, "", name))}
                inputProps={{
                  ...props.inputProps,
                  readOnly: true,
                }}
                {...fieldProps}
              />
            );
          }}
        />
        {errors[name] && (
          <FormHelperText error>{validation(errors, "", name)}</FormHelperText>
        )}
      </FormControl>
    </LocalizationProvider>
  );
};

MUITimePicker.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.any,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.any,
  variant: PropTypes.string,
  onChange: PropTypes.func,
};

export default MUITimePicker;
