import { Globals } from "../Component/HOC/Classes/Globals";

export const sumArrayOfObjectValue = (array = [], paramter) => {
  let sum = 0;
  array.forEach((ele) => (sum += ele[paramter]));
  return sum;
};

export const UserLogout = (func) => {
  localStorage.removeItem("token");
  localStorage.removeItem("instanceCode");
  localStorage.removeItem("navDrawerOpen");
  localStorage.removeItem("export-shipments");
  localStorage.removeItem("payment-export-shipments");
  localStorage.removeItem("collection-export-shipments");
  localStorage.removeItem("invoice-export-shipments");
  localStorage.removeItem("firstOpen");
  localStorage.removeItem("openedMessages");
  localStorage.removeItem("messagesNotifications");
  localStorage.removeItem("notifications");
  localStorage.removeItem("stocktake");
  localStorage.removeItem("stocktakeBranch");

  Globals.setUser(null);
  Globals.settings = null;
  func && func();
};
