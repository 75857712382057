import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import "./style.css";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Typography,
} from "@mui/material";
import {
  LIST_SHIPPING_SERVICES_DROPDOWN,
  LIST_ZONES_DROPDOWN,
} from "../../../GlobalsQuery/ListDropdown/ListDropdown";
import { useForm } from "react-hook-form";
import * as gqlb from "gql-query-builder";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";
import CustomButton from "../../HOC/MUI/CustomButton";
import { Done, LocationOn } from "@mui/icons-material";
import { useLazyQuery, gql } from "@apollo/client";
import { useTheme } from "@emotion/react";
import { VscPackage } from "react-icons/vsc";
import { RiBankLine } from "react-icons/ri";
import ScaleOutlinedIcon from "@mui/icons-material/ScaleOutlined";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PREFIX = "CalculatorSection";

const classes = {
  header: `${PREFIX}-header`,
  span: `${PREFIX}-span`,
  calculatorIcon: `${PREFIX}-calculatorIcon`,
  active: `${PREFIX}-active`,
  slideImg: `${PREFIX}-slideImg`,
  calculatorImg: `${PREFIX}-calculatorImg`,
  trackButtonContainer: `${PREFIX}-trackButtonContainer`,
};

const Root = styled("div")(({ theme }) => ({
  background: theme.palette.background.paper,
  [`& .${classes.header}`]: {
    marginTop: 0,
    "&::before": {
      content: "' '",
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "50px",
      height: "5px",
      background: theme.palette.primary.main,
    },
    color: theme.palette.text.primary,
  },
  [`& .${classes.trackButtonContainer}`]: {
    minWidth: "150px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  [`& .${classes.span}`]: {
    display: "block",
    paddingLeft: "30px",
    paddingBottom: "20px",
    position: "relative",
    "&::before": {
      content: "' '",
      position: "absolute",
      left: 0,
      top: "5px",
      width: "15px",
      height: "15px",
      background: theme.palette.primary.main,
    },
  },

  [`& .${classes.calculatorIcon}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.slideImg}`]: {
    display: "none",
  },
  [`& .${classes.active}`]: {
    display: "block",
  },
  [`& .${classes.calculatorImg}`]: {
    display: "flex",
    justifyContent: "center",
  },
}));
const StyledLink = styled(Link)(({ theme }) => ({
  display: "inline-block",
  textDecoration: "none",
  textTransform: "uppercase",
  fontSize: 13,
  fontWeight: 500,
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0, 1.5),
  },
  color: theme.palette.text.primary,
  "&:hover": {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    padding: theme.spacing(3, 0),
    borderBottom: `1px solid ${theme.palette.action.hover}`,
    width: "100%",
    color: theme.palette.text.secondary,
  },
}));

export const CALCULATE_FEES_QUERY = gqlb.query({
  operation: "calculateShipmentFees",
  fields: ["delivery", "total"],
  variables: {
    input: {
      type: "CalculateShipmentFeesInput",
      required: true,
    },
  },
});
const CalculatorSection = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: errors,
  } = useForm();

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState();

  const handelCloseDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    setValue("height", 0);
    setValue("length", 0);
    setValue("width", 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseData = (data) => {
    return data;
  };

  function onSubmit(data) {
    for (const key in data) {
      if (typeof data[key] === "string") {
        data[key] = parseFloat(data[key]);
      }
    }
    const size = {
      width: data.width,
      length: data.length,
      height: data.height,
    };

    delete data.width;
    delete data.length;
    delete data.height;

    getShipmentData({
      variables: {
        input: {
          ...data,
          customerTypeCode: "MERCHANT",
          size: size,
          price: data.price ?? 0,
        },
      },
    });
  }
  const [getShipmentData, { loading: loadingShipmentData }] = useLazyQuery(
    gql`
      ${CALCULATE_FEES_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setOpen(true);
        setDialogData(data);
        console.log(data);
      },
    }
  );

  return (
    <Root id="calculator" className="wpo-calculator-area">
      <Dialog fullWidth maxWidth="xs" open={open} onClose={handelCloseDialog}>
        <DialogTitle>
          <Grid sm={12}>
            <Typography variant="h6" color={"text.primary"}>
              {t("estimatedShippingFees")} :{" "}
              {dialogData?.calculateShipmentFees?.total}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" color={"text.primary"}>
            {t("publicCalculateHint")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <StyledLink to="/login">
            <Button onClick={handelCloseDialog} color="primary">
              {t("loginForAccurateFees")}
            </Button>
          </StyledLink>
        </DialogActions>
      </Dialog>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid
            xs={12}
            className="contact-section-title"
            sx={{ textAlign: "center" }}
          >
            <Typography variant="h2" color={"primary"} mb={6}>
              {t("feesCalculator")}
            </Typography>
          </Grid>

          <Grid
            lg={12}
            xs={12}
            container
            justifyContent="space-between"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid
              lg={6}
              xs={12}
              container
            // justifyContent="center"
            >
              <Grid xs={12} alignItems="flex-start">
                <Grid item display={"flex"} spacing={1} gap={"5px"}>
                  <Icon>
                    <VscPackage />
                  </Icon>
                  <Typography variant="body2" color={"text.primary"}>
                    {t("service")}
                  </Typography>
                </Grid>
                <Grid container p={0}>
                  <Grid xs={12} alignItems="flex-start">
                    <CustomAutocomplete
                      control={control}
                      variant="outlined"
                      size="Normal"
                      errors={errors}
                      rules={{ required: t("fieldIsRequired") }}
                      name={"serviceId"}
                      label={t("service")}
                      selectFirst={true}
                      query={LIST_SHIPPING_SERVICES_DROPDOWN.query}
                      parseData={(data) => parseData(data)}
                      variables={{
                        input: {
                          customerTypeCode: "MERCHANT",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12}>
                <Grid item display={"flex"} spacing={1} gap={"5px"}>
                  <Icon>
                    <ScaleOutlinedIcon />
                  </Icon>
                  <Typography variant="body2" color={"text.primary"}>
                    {t("packageWeight")}
                  </Typography>
                </Grid>
                <Grid container p={0}>
                  <Grid xs={6} alignItems="flex-start">
                    <ControlMUItextField
                      control={control}
                      errors={errors}
                      variant="outlined"
                      size="Normal"
                      type={"number"}
                      name={"weight"}
                      label={t("packageWeight")}
                      rules={{
                        required: t("fieldIsRequired"),
                        min: {
                          value: 0,
                          message: t("weightValidation"),
                        },
                      }}
                    />
                  </Grid>
                  <Grid xs={2} alignItems="flex-start">
                    <ControlMUItextField
                      control={control}
                      errors={errors}
                      variant="outlined"
                      size="Normal"
                      type={"number"}
                      name={"length"}
                      label={t("length")}
                      rules={{
                        required: t("fieldIsRequired"),
                        min: {
                          value: 0,
                          message: t("validation:min", {
                            field: t("length"),
                            number: "0",
                          }),
                        },
                      }}
                      onChange={(e) => {
                        setValue("height", "");
                        setValue("width", "");
                      }}
                    />
                  </Grid>
                  <Grid xs={2} alignItems="flex-start">
                    <ControlMUItextField
                      control={control}
                      errors={errors}
                      variant="outlined"
                      size="Normal"
                      type={"number"}
                      name={"width"}
                      label={t("width")}
                      rules={{
                        required: t("fieldIsRequired"),
                        min: {
                          value: 0,
                          message: t("validation:min", {
                            field: t("width"),
                            number: "0",
                          }),
                        },
                      }}
                    />
                  </Grid>
                  <Grid xs={2} alignItems="flex-start">
                    <ControlMUItextField
                      control={control}
                      variant="outlined"
                      size="Normal"
                      type={"number"}
                      name={"height"}
                      label={t("height")}
                      rules={{
                        required: t("fieldIsRequired"),
                        min: {
                          value: 0,
                          message: t("validation:min", {
                            field: t("height"),
                            number: "0",
                          }),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid lg={6} xs={12} container justifyContent="center">
              <Grid xs={12}>
                <Grid item display={"flex"} spacing={1} gap={"5px"}>
                  <Icon>
                    <RiBankLine />
                  </Icon>
                  <Typography variant="body2" color={"text.primary"}>
                    {t("shippingFrom")}
                  </Typography>
                </Grid>
                <Grid container p={0}>
                  <Grid xs={6} alignItems="flex-start">
                    <CustomAutocomplete
                      control={control}
                      errors={errors}
                      variant="outlined"
                      size="Normal"
                      rules={{ required: t("fieldIsRequired") }}
                      label={t("zone")}
                      name={"senderZoneId"}
                      query={LIST_ZONES_DROPDOWN.query}
                      parseData={(data) => parseData(data)}
                      skip={!watch("serviceId")}
                      variables={{
                        input: {
                          active: true,
                          parentId: null,

                          service: {
                            serviceId: watch("serviceId"),
                            customerTypeCode: "MERCHANT",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid xs={6} alignItems="flex-start">
                    <CustomAutocomplete
                      control={control}
                      errors={errors}
                      variant="outlined"
                      size="Normal"
                      rules={{ required: t("fieldIsRequired") }}
                      name={"senderSubzoneId"}
                      label={t("subzone")}
                      skip={!watch("serviceId") || !watch("senderZoneId")}
                      query={LIST_ZONES_DROPDOWN.query}
                      parseData={(data) => parseData(data)}
                      variables={{
                        input: {
                          active: true,
                          parentId: watch("senderZoneId"),
                          service: {
                            serviceId: watch("serviceId"),
                            customerTypeCode: "MERCHANT",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12}>
                <Grid item display={"flex"} spacing={1} gap={"5px"}>
                  <Icon>
                    <LocationOn />
                  </Icon>
                  <Typography variant="body2" color={"text.primary"}>
                    {t("shippingTo")}
                  </Typography>
                </Grid>
                <Grid container p={0}>
                  <Grid xs={6} alignItems="flex-start">
                    <CustomAutocomplete
                      control={control}
                      errors={errors}
                      variant="outlined"
                      size="Normal"
                      rules={{ required: t("fieldIsRequired") }}
                      label={t("zone")}
                      name={"recipientZoneId"}
                      query={LIST_ZONES_DROPDOWN.query}
                      parseData={(data) => parseData(data)}
                      skip={
                        !watch("serviceId") ||
                        !watch("senderZoneId") ||
                        !watch("senderSubzoneId")
                      }
                      variables={{
                        input: {
                          active: true,
                          parentId: null,
                          service: {
                            serviceId: watch("serviceId"),
                            customerTypeCode: "MERCHANT",
                            fromZoneId: watch("senderZoneId"),
                            fromSubzoneId: watch("senderSubzoneId"),
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid xs={6} alignItems="flex-start">
                    <CustomAutocomplete
                      control={control}
                      errors={errors}
                      variant="outlined"
                      size="Normal"
                      rules={{ required: t("fieldIsRequired") }}
                      name={"recipientSubzoneId"}
                      label={t("subzone")}
                      skip={
                        !watch("serviceId") ||
                        !watch("recipientZoneId") ||
                        !watch("senderSubzoneId")
                      }
                      query={LIST_ZONES_DROPDOWN.query}
                      parseData={(data) => parseData(data)}
                      variables={{
                        input: {
                          active: true,
                          parentId: watch("recipientZoneId"),
                          service: {
                            serviceId: watch("serviceId"),
                            customerTypeCode: "MERCHANT",
                            fromZoneId: watch("senderZoneId"),
                            fromSubzoneId: watch("senderSubzoneId"),
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sm={12}
              xs={12}
              container
              justifyContent="flex-end"
              className={classes.trackButtonContainer}
            >
              <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                name="save"
                className={classes.saveButton}
                variant="contained"
                size="medium"
                sx={{ minWidth: "150px", p: 1, m: 1 }}
                loading={loadingShipmentData}
                startIcon={!loadingShipmentData && <Done />}
              >
                {!loadingShipmentData && t("calculate")}
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
};

export default CalculatorSection;
