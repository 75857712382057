import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  TableBody,
  TableRow,
  Button,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { FilterList, Search, Add } from "@mui/icons-material";
import { LIST_TICKETS } from "./Graphql";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { styled } from "@mui/material/styles";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import useWidth from "../../Hooks/useWidth";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { Globals } from "../HOC/Classes/Globals";
import CellLink from "../HOC/CustomComponents/CellLink";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { LIST_CUSTOMERS_DROPDOWN, LIST_MEMBERS_DROPDOWN, LIST_SUPPORT_CATEGORIES_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";
import TicketDialog from "./TicketDialog";

const PREFIX = "TICKETS";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterFields: `${PREFIX}-filterFields`,
  filterField: `${PREFIX}-filterField`,
  headerTable: `${PREFIX}-headerTable`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Tickets = (props) => {
  const { t } = useTranslation();
  const [reFetches, setReFetches] = useState(true);
  const [noData, setNoData] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const screenWidth = useWidth();
  const user = Globals.user
  const [autocompleteValues, setAutocompleteValues] = useState({
    customer: null,
    category: null,
    assignee: null,
  });

  const userCanCreate = user.hasPermission('crm.ticket.create') || user.member.teams

  const [open, setOpen] = React.useState(false);
  const [supportCategoriesObject, setSupportCategoriesObject] =
    React.useState();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSupportCategoriesObject();
  };

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : null;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : null;
  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);
  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
    };
    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    if (urlQuery["title"]) urlQuery["title"] = urlQuery["title"].toString();
    urlQuery["title"] && setValue("title", urlQuery["title"]);
    if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
    urlQuery["code"] && setValue("code", urlQuery["code"]);

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/crm/tickets",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    setReFetches((prev) => !prev)
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    let handledData = {
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      active: data.active,
      code: data.code.toString(),
      title: data.title.toString(),
      customerId: data.customerId,
      categoryId: data.categoryId,
      assigneeId: data.assigneeId,
      statusCode: data.statusCode,
      page: page,
    };
    data["active"] === "" && delete handledData["active"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  const { data, loading, refetch } = useQuery(
    gql`
      ${LIST_TICKETS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: reFetches,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.code && { code: search.code.toString() }),
          ...(search.title && { title: search.title.toString() }),
          ...(search.active != null && { active: search.active }),
          ...(search.customerId && { customerId: search.customerId }),
          ...(search.categoryId && { categoryId: search.categoryId }),
          ...(search.assigneeId && { assigneeId: search.assigneeId }),
          ...(search.statusCode && { statusCode: search.statusCode }),
        },
      },
      onCompleted: () => {
        setNoData(false)
      }
    },
  );
  const listTickets = data?.listTickets?.data;

  const resetDate = () => {
    setDateRange([null, null]);
  };
  const parseData = (data) => {
    return data;
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: () => handleClickOpen(),
      icon: Add,
      permission: userCanCreate,
    },
  ]

  return (
    <Root>
      {open && (
        <TicketDialog
          open={open}
          handleClose={handleClose}
          refetch={refetch}
          supportCategoriesObject={supportCategoriesObject}
        />
      )}

      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listTickets?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"title"}
                  label={t("title")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  // startText={t('registrationDate')}
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  resetDate={resetDate}
                />
              </Grid>
              {!user.account && <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"customerId"}
                  label={t("customer")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["customerId"],
                      "customer"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_CUSTOMERS_DROPDOWN.query}
                  defaultValue={autocompleteValues.customer}
                  variables={{
                    input: {
                      active: true,
                      typeCode: ["MERCHANT"],
                    },
                  }}
                />
              </Grid>}
              <Grid item sm={12} xs={12}>
                <LookupDropdown
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"statusCode"}
                  label={t("status")}
                  variables={{
                    input: { code: "SHP_COMPLAINT_TICKETS_STATUS" },
                  }}
                  onCompleted={(data) =>
                    lookupsComplete(
                      data,
                      urlQuery["statusCode"],
                      "statusCode"
                    )
                  }
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"categoryId"}
                  label={t("category")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["categoryId"],
                      "category"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_SUPPORT_CATEGORIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.category}
                />
              </Grid>
              {!user.account && <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={noData}
                  name={"assigneeId"}
                  label={t("assignee")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["assigneeId"],
                      "assignee"
                    )
                  }
                  variables={{
                    input: {
                      ...(watch('categoryId') && { categoryId: watch('categoryId') })
                    }
                  }}
                  parseData={(data) => parseData(data)}
                  query={LIST_MEMBERS_DROPDOWN.query}
                  defaultValue={autocompleteValues.assignee}
                />
              </Grid>}
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          [
            {
              name: "code"
            },
            {
              name: "createdAt"
            },
            {
              name: "title"
            },
            {
              name: "customer"
            },
            {
              name: "category"
            },
            {
              name: "status"
            },
            {
              name: "assignee"
            },
          ]
        }
        tableBody={
          <TableBody>
            {listTickets &&
              listTickets?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    <CellLink pathname={`/admin/crm/tickets/${row?.id}`}>
                      {row?.code}
                    </CellLink>
                    <FixedTableCell>
                      {moment(row.createdAt).format("ll")}
                    </FixedTableCell>
                    <FixedTableCell>
                      {row?.title}
                    </FixedTableCell>
                    <CellLink pathname={`/admin/customers/${row?.customer.id}`}>
                      {row?.customer.name}
                    </CellLink>
                    <CellLink
                      pathname={`/admin/crm/categories`}
                      search={`code=${row.category.id}`}
                    >
                      {row?.category.name}
                    </CellLink>
                    <FixedTableCell>{row?.status.name}</FixedTableCell>
                    <CellLink pathname={`/admin/users/${row?.assignee?.id}`}>
                      {row?.assignee?.username}
                    </CellLink>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listTickets?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listTickets ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default Tickets;
