import * as gqlb from "gql-query-builder";

export const SUM_MANIFEST_QUERY = gqlb.query({
  operation: "sumManifests",
  fields: ["shipmentsCount"],
  variables: {
    input: {
      type: "ListManifestsFilterInput",
      required: true,
    },
  },
});
export const LISTCUSTOMER_REQUESTS_QUERY = gqlb.query({
  operation: "listCustomerRequests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "total",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "date",
        "paymentAccountNumber",
        {
          paymentBank: ["id", "code", "name"],
        },
        {
          status: ["code", "id", "name"],
        },
        {
          customer: ["name", "id"],
        },
        {
          deliveryType: ["name", "code"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListCustomerRequestFilterInput",
      required: true,
    },
    first: {
      type: "Int",
      required: true,
      value: 15,
    },
    page: {
      type: "Int",
      value: 1,
    },
  },
});

export const customerRequestsFields = [
  "id",
  "date",
  "notes",
  "payeeName",
  "editable",
  "deletable",
  "createdAt",
  "paymentAccountNumber",
  {
    paymentBank: ["id", "code", "name"],
  },
  {
    type: ["id", "code"],
  },
  {
    branch: ["id", "code", "name"],
  },
  {
    deliveryType: ["id", "code", "name"],
  },
  {
    status: ["code", "id", "name"],
  },
  {
    updatedBy: ["id", "username"],
  },
  {
    createdBy: ["id", "username"],
  },
  {
    updatedBy: ["id", "username"],
  },
  {
    payment: ["id", "code", { type: ["code"] }],
  },
  {
    manifest: ["id", "code", { transactionType: [{ type: ["code"] }] }],
  },
  {
    deliveryAgent: ["id", "name", "code"],
  },
  {
    customer: [
      "id",
      "name",
      "phone",
      "address",
      "code",
      {
        zone: ["name"],
      },
      {
        subzone: ["name"],
      },
    ],
  },
];
export const CUSTOMER_REQUEST_BY_ID_QUERY = gqlb.query({
  operation: "customerRequest",
  fields: customerRequestsFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const MANIFEST_BY_ID_AND_PRINT = gqlb.query([
  {
    operation: "manifest",
    fields: customerRequestsFields,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "organization",
    fields: [
      "name",
      "phone",
      "email",
      "website",
      "reportHeader",
      "reportFooter",
    ],
    variables: {},
  },
]);

export const SAVE_CUSTOMER_REQUEST = gqlb.mutation({
  operation: "saveCustomerRequest",
  fields: customerRequestsFields,
  variables: {
    input: {
      type: "CustomerRequestInput",
      required: true,
    },
  },
});
export const DELETE_CUSTOMER_REQUEST_MUTATION = gqlb.mutation({
  operation: "deleteCustomerRequest",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const APPROVE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "approveManifest",
  fields: customerRequestsFields,
  variables: {
    input: {
      type: "ApproveManifestInput",
      required: true,
    },
  },
});

export const DELIVERY_AGENT_ID = gqlb.query({
  operation: "deliveryAgent",
  fields: [
    {
      route: ["name", "id"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const UPDATE_CUSTOMER_REQUEST_STATUS = gqlb.mutation({
  operation: "updateCustomerRequestStatus",
  fields: customerRequestsFields,
  variables: {
    input: {
      type: "UpdateCustomerRequestStatusInput",
      required: true,
    },
  },
});
