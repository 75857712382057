import * as gqlb from "gql-query-builder";

export const LIST_CANCELLATION_REASONS = gqlb.query({
    operation: "listCancellationReasons",
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
            "count",
            "currentPage",
            "firstItem",
            "hasMorePages",
            "lastItem",
            "lastPage",
            "perPage",
            "total",
        ],
        variables: {},
     },{
      operation: "data",
      fields: ["id", "code", "name","active", { type: ["code", "name"] }],
      variables: {},
     }
    ],
    variables: {
      input: {
        type: "ListCancellationReasonsFilterInput",
    } ,
    page: {
        type: "Int",
      },
      first: {
        type: "Int",
      },
    }
});


export const SAVE_CANCELLATION_REASONS= gqlb.mutation({
    operation: "saveCancellationReason",
    fields: ["id", "code","name"],
    variables: {
      input: {
        type: "CancellationReasonInput",
        required: true,
      },
    },
  });