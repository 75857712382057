import {
  Box,
  Icon,

  Paper,

  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import React, { Fragment, useState } from "react";

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { DeleteOutline, Edit, Print } from "@mui/icons-material";
import clsx from "clsx";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { DELETE_MANIFEST_MUTATION, MANIFEST_BY_ID_AND_PRINT,MANIFEST_VIEW_PRODUCT_DATA} from "./Graphql";

import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import useWarehouseManifestDetails from "./WarehouseManifestDetails";

import { Globals } from "../HOC/Classes/Globals";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { dateFormatLL } from "../../helpers/dateFunctions";
import ProductsTable from "../HOC/CustomComponents/ProductsTable";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import config from "../../config.json";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";

const PREFIX = "ManifestView";

const classes = {
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  viewPaper: `${PREFIX}-viewPaper`,
  table: `${PREFIX}-table`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  picker: `${PREFIX}-picker`,
  button: `${PREFIX}-button`,
  margin: `${PREFIX}-margin`,
  overlay: `${PREFIX}-overlay`,


  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(2, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classes.viewPaper}`]: {
    padding: theme.spacing(3),
  },




  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },


  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },// overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.picker}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },

      height: "100%",
      overflow: "hidden",
    },
  },






  [`& .${classes.overlay}`]:
  {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },


  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

export { };

const ManifestView = (props) => {
  const { t } = useTranslation();

  const [manifestType, setManifestType] = useState(null);

  const [disableForm, setDisableForm] = useState(false);
  const [productData, setProductData] = useState({
    page: 0,
    total: 0,
    rowsPerPage: config.app.pageSize,
    pickedProduct: [],
    noData: true,
  });





  /******************************************* Start Pagination Functions ********************************************/

  const handleChangePage = (event, newPage) => {
    setProductData((prev) => {
      return {
        ...prev,
        page: newPage,
      };
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setProductData((prev) => {
      return {
        ...prev,
        page: 0,
        rowsPerPage: event.target.value,
      };
    });
  };

  /******************************************* Start Manifest By Id ********************************************/
  const manifestId = props.match.params.id?.trim();

  const { data, loading: manifestByIdLoad } = useQuery(
    gql`
      ${MANIFEST_BY_ID_AND_PRINT.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      // skip: !props.match.params.id,
      variables: {
        id: parseInt(props.match.params.id),
      },
      onCompleted: (data) => {
        if (data?.warehouseManifest === null) return;
        const warehouseManifest = data?.warehouseManifest;
        setManifestType(warehouseManifest?.transactionType?.type.code);
        setProductData(prev => {
          return {
            ...prev,
            total: warehouseManifest.manifestProducts.paginatorInfo.total,
          }
        })
        getProductData()
        setDisableForm(true);
      },
    }
  );
  const [getProductData, { loading: loadingProductData }] = useLazyQuery(
    gql`
        ${MANIFEST_VIEW_PRODUCT_DATA().query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id:  parseInt(props.match.params.id),
        page: productData.page + 1,
        first: productData.rowsPerPage,
      },
      onCompleted: (data) => {

        if (data?.warehouseManifest === null) return;
        const warehouseManifest = data?.warehouseManifest;
        const product = warehouseManifest.manifestProducts.data
        setProductData(prev => {
          return {
            ...prev,
            pickedProduct: product,
            total: warehouseManifest.manifestProducts.paginatorInfo.total,
            noData: false,
          }
        })
      },
    }
  );
  const { details: manifestDetails } = useWarehouseManifestDetails({
    type: manifestType,
    typeData:data?.warehouseManifest
    
  });
  const manifestStatus = manifestDetails;
  const user = Globals.user;
  const branchId = data?.safe?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canEdit =
    user.hasPermission("shipping.warehouse_manifest.update") &&
    canAccessBranch &&
    !data?.warehouseManifest?.approved &&
    !manifestByIdLoad;
  const canDelete =
    user.hasPermission("shipping.warehouse_manifest.delete") &&
    canAccessBranch &&
    !data?.warehouseManifest?.approved &&
    !manifestByIdLoad;

  /******************************************* End Manifest List ********************************************/
  let tableBody = null;
  let viewBody = null;
  tableBody = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          selectedProduct={productData?.total}
          manifestType={manifestType}
        />
      
        {!loadingProductData&&manifestStatus && (
          <TableFixedHeaderWraper>
            <ProductsTable
              data={productData?.pickedProduct}
              headCells={manifestStatus?.tableHead}
              parseBodyCell={manifestStatus?.tableBody}
              total={manifestStatus.tableTotal}
              sumRow={manifestStatus?.sum}
              sumTableNumber={manifestStatus?.sumTableNumber}
            />
          </TableFixedHeaderWraper>
        )}
        {productData?.total !== 0  && (
          <MUITablePagination
            count={productData.total}
            rowsPerPage={productData?.rowsPerPage}
            page={productData?.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        {(!manifestId || !disableForm) && (
          <Box className={classes.overlay}>
            <Typography color="textPrimary" variant="body1">
              {t("saveManifestRequired")}
            </Typography>
          </Box>
        )}
        <Grid container justifyContent="center" alignItems="center">
        {loadingProductData ? (
          <FullScreenLoading height={"327px"} />
        ) : (
          productData?.total === 0 && manifestId && disableForm && (
            <EmptyTableMessage
              message={t("noResult")}
            />
          ))}
        </Grid>
      </Paper>
    </Grid>
  );

  const [openDelete, setOpenDelete] = useState(false);

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/warehouse-manifests/${manifestId}/edit`),
      icon: Edit,
      permission: canEdit,
    },
    {
      id: "print",
      title: "print",
      action: () =>
        window.open(
          `${window.location.origin}/report/print/warehousemanifest/${manifestId}/${manifestType}`
        ),
      icon: Print,
      disabled: manifestByIdLoad
    },
    {
      id: "delete",
      title: "delete",
      action: () => setOpenDelete(true),
      icon: DeleteOutline,
      permission: Boolean(canDelete),
    },
  ]
  viewBody = (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      sx={{ margin: "0", width: "100%" }}
    >
      <Fragment>
        <TitleAppBar path={props.match.path} type={manifestType}>
          <LongMenu icons={icons} />
          <MutationWithDialog
            mutaion={DELETE_MANIFEST_MUTATION.query}
            setOpenDelete={setOpenDelete}
            openDelete={openDelete}
            dialogTitle={t("deleteManifest")}
            dialogContent={t("deleteManifestConfirmation")}
            mutaionProps={{ variables: { id: parseInt(manifestId) } }}
            onCompleted={() =>
              pushUrl(props, `/admin/warehouse-manifests/${manifestType}`)
            }
            onCompleteMessage={t("manifestDeleted")}
          />
        </TitleAppBar>
      </Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "0", width: "100%" }}
      >
        {manifestByIdLoad ? (
          <FullScreenLoading minHeight="25%" />
        ) : (
          <Paper
            container
            className={classes.viewPaper}
            sx={{ width: "100%", mt: 1 }}
            component={Grid}
          >
            <KeyValuePair
              title={t("manifestCode")}
              value={data?.warehouseManifest?.code}
            />
            <KeyValuePair
              title={t("theDate")}
              value={dateFormatLL(data?.warehouseManifest?.date)}
            />
            <KeyValuePair
              title={t("branch")}
              value={
                data?.warehouseManifest?.branch?.name && (
                  <SpanLink
                    pathname={`/admin/branches/${data?.warehouseManifest?.branch?.id}`}
                  >
                    {data?.warehouseManifest?.branch?.name}
                  </SpanLink>
                )
              }
            />
            <KeyValuePair
              title={t("transactionType")}
              value={
                data?.warehouseManifest?.transactionType?.name && (
                  <SpanLink
                    pathname={`/admin/transaction-types/${data?.warehouseManifest?.transactionType?.id}`}
                  >
                    {data?.warehouseManifest?.transactionType?.name}
                  </SpanLink>
                )
              }
            />
            {data?.warehouseManifest?.customer ? (
              <KeyValuePair
                title={t("customer")}
                value={
                  data?.warehouseManifest?.customer?.name && (
                    <SpanLink
                      pathname={`/admin/customers/${data?.warehouseManifest?.customer?.id}`}
                    >
                      {data?.warehouseManifest?.customer?.name}
                    </SpanLink>
                  )
                }
              />
            ) : null}
            {data?.warehouseManifest?.referenceBranch ? (
              <KeyValuePair
                title={
                  data?.warehouseManifest?.transactionType?.type?.code === "PMBT"
                    ? t("toBranch")
                    : t("fromBranch")
                }
                value={
                  data?.warehouseManifest?.referenceBranch?.name && (
                    <SpanLink
                      pathname={`/admin/branches/${data?.warehouseManifest?.referenceBranch?.id}`}
                    >
                      {data?.warehouseManifest?.referenceBranch?.name}
                    </SpanLink>
                  )
                }
              />
            ) : null}
            <KeyValuePair title={t("notes")} value={data?.warehouseManifest?.notes} />
            <KeyValuePair
              title={t("approved")}
              value={
                data?.warehouseManifest?.approved ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
            {data?.warehouseManifest?.fromManifest ? (
              <KeyValuePair
                title={t("fromManifest")}
                value={
                  data?.warehouseManifest?.fromManifest?.code && (
                    <SpanLink
                      pathname={`/admin/warehouse-manifests/${data?.warehouseManifest?.fromManifest?.id}`}
                    >
                      {data?.warehouseManifest?.fromManifest?.code}
                    </SpanLink>
                  )
                }
              />
            ) : null}
            <KeyValuePair
              title={t("createdBy")}
              value={
                data?.warehouseManifest?.createdBy?.username && (
                  <SpanLink
                    pathname={`/admin/users/${data?.warehouseManifest?.createdBy?.id}`}
                  >
                    {data?.warehouseManifest?.createdBy?.username}
                  </SpanLink>
                )
              }
            />
            <KeyValuePair
              title={t("updatedBy")}
              value={
                data?.warehouseManifest?.updatedBy?.username && (
                  <SpanLink
                    pathname={`/admin/users/${data?.warehouseManifest?.updatedBy?.id}`}
                  >
                    {data?.warehouseManifest?.updatedBy?.username}
                  </SpanLink>
                )
              }
            />
          </Paper>
        )}
      </Grid>
    </Grid>
  );

  const tableCondition = Boolean(manifestId) && !manifestByIdLoad;

  return manifestByIdLoad ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !data?.warehouseManifest ? (
      <NotFound />
    ) : (<Root>
      {props.children}

      {viewBody}

      <Grid
        container
        spacing={3}
        justifyContent="center"
        className={classes.mainTracking}
      >
        {tableCondition && tableBody}
      </Grid>
    </Root>
    );
};

export default ManifestView;
