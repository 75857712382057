import { Box } from "@mui/material";
import formatMoney from "../../helpers/numbersDot";
import { dateFormatLL } from "../../helpers/dateFunctions";
export const defaultQueryFields = (keys, permission) => {
    const defaultKeys = []
    const shipmentFields = []
    const manifestEntriesShipment = (manifestShipmentData) => {
        return {
            operation: `shipment`,
            fields: [
                "id",
                ...manifestShipmentData
            ],
            variables: {}
        }


    };
    const queriesEntries = {
        entriesFees: {
            queryName: "fees"
        },

        entriesDeliveryDate: {
            queryName: "deliveryDate"
        },
        entriesReturnType: {
            queryName: {
                returnType: ["name"],
            }
        },
        entriesWarehouseSection: {
            queryName: {
                warehouseSection: ["name"],
            }
        },
        entriesCancellationReason: {
            queryName: {
                cancellationReason: ["name", "id"],
            }
        },
        entriesNotes: {
            queryName: "notes"
        },
        entriesPickupAmount: {
            queryName: "pickupAmount"
        },
    }
    const queriesShipment = {
        ...(permission && {
            adminNotes: {
                queryName: "adminNotes"
            }
        }),
        code: {
            queryName: "code"
        },
        description: {
            queryName: "description"
        },
        totalAmount: {
            queryName: [{ type: ["code"] },
                "totalAmount"]
        },
        senderAddress: {
            queryName: "senderAddress"
        },
        date: {
            queryName: "date"
        },
        deliveredOrReturnedDate: {
            queryName: "deliveredOrReturnedDate"
        },
        recipientAddress: {
            queryName: "recipientAddress"
        },
        refNumber: {
            queryName: "refNumber"
        },
        piecesCount: {
            queryName: "piecesCount"
        },
        price: {
            queryName: "price"
        },
        weight: {
            queryName: "weight"
        },
        deliveredAmount: {
            queryName: "deliveredAmount"
        },
        amount: {
            queryName: "amount"
        },
        attempts: {
            queryName: "attempts"
        },
        status: {
            queryName: {
                status: ["name", "code"],
            }
        },
        lastTransactionDate: {
            queryName: {
                lastTransaction: ["date"],
            }
        },
        transactions: {
            queryName: {

                transactions: [{ returnType: ["name"] }],

            }
        },
        recipientZone: {
            queryName: [
                {
                    recipientZone: ["id", "name"],
                },
                {
                    recipientSubzone: ["name"]
                }
            ]
        },
        senderZone: {
            queryName: [
                {
                    senderZone: ["id", "name"],
                },
                {
                    senderSubzone: ["id", "name"],
                }
            ]
        },
        type: {
            queryName: {
                type: ["name"],
            }
        },
        paymentType: {
            queryName: {
                paymentType: ["name"],
            }
        },
        openable: {
            queryName: {
                openable: ["name", "code"],
            }
        },
        returnType: {
            queryName: {
                returnType: ["name"],
            }
        },
        deliveryType: {
            queryName: [{
                deliveryType: ["name"],

            },
            { status: ["name", "code"] }
            ]
        },
        warehouseSection: {
            queryName: {
                warehouseSection: ["name"],
            }
        },
        notes: {
            queryName: "notes"
        },
        pendingCollectionAmount: {
            queryName: "pendingCollectionAmount"
        },
        deliveryCommission: {
            queryName: "deliveryCommission"
        },
        senderName: {
            queryName: "senderName"
        },
        recipientName: {
            queryName: "recipientName"
        },

        sender: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "senderName",
                "senderMobile",
                "senderPhone"
            ]
        },
        recipient: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "recipientName",
                "recipientMobile",
                "recipientPhone"
            ]
        },
        recipientPhone: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "recipientMobile",
                "recipientPhone"
            ]
        },
        senderPhone: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "senderMobile",
                "senderPhone"
            ]
        },
        returningDueFees: {
            queryName: "returningDueFees"
        },
        deliveryDate: {
            queryName: "deliveryDate"
        },
        returnedValue: {
            queryName: "returnedValue"
        },
        cancellationReason: {
            queryName: { cancellationReason: ["name"] }
        },
        customer: {
            queryName: { customer: ["id", "name", "code"], }
        },
        barcode: {
            queryName: "code"
        },


    }
    keys.map((keyName) => {
        if (queriesEntries?.[keyName]) {
            if (Array.isArray(queriesEntries[keyName].queryName)) {
                queriesEntries[keyName].queryName.map((i) => defaultKeys.push(i))
            } else {
                defaultKeys.push(queriesEntries[keyName].queryName)
            }
        }
        if (queriesShipment?.[keyName]) {
            if (Array.isArray(queriesShipment[keyName].queryName)) {
                queriesShipment[keyName].queryName.map((i) => shipmentFields.push(i))
            }
            else {
                shipmentFields.push(queriesShipment[keyName].queryName)
            }
        }
        return ''
    })
    let uniqueArray = shipmentFields.filter((item, index, self) => {
        if (typeof item === 'object') {
            // For objects, check if it's not a duplicate by comparing JSON strings
            return self.findIndex(other => JSON.stringify(other) === JSON.stringify(item)) === index;
        } else {
            // For non-objects, remove duplicates as before
            return self.indexOf(item) === index;
        }
    });
    defaultKeys.push(manifestEntriesShipment(uniqueArray))
    return defaultKeys
};

export const shipmentFieldsFun = (manifestType, permission) => {
    const shipmentFields = [
        {
            key: "code",
            label: "code",
            type: "link",
            path: "shipments"
        },

        {
            key: "description",
            label: "packageDescription",
            type: ""
        },
        {
            key: "barcode",
            label: "barcode",
            type: "barcode"
        },
        {
            key: "refNumber",
            label: "refNumber",
            type: "refNumber"
        },
    ]
    const shipmentManifest = {
        PKM: [
            {
                key: "date",
                label: "date",
                type: ""
            },
            {
                key: "sender",
                label: "senderName",
                type: "infoGroup"
            },
            {
                key: "senderZone",
                label: "source",
                type: "zone"
            },
            {
                key: "senderAddress",
                label: "senderAddress",
                type: ""
            },
            {
                key: "type",
                label: "packageType",
                type: ""
            },
            {
                key: "price",
                label: "packagePrice",
                type: "",
                ifSum: "price"
            },
            {
                key: "piecesCount",
                label: "pieceCount",
                type: "",
                ifSum: "piecesCount"
            },
            {
                key: "weight",
                label: "weight",
                type: "",
                ifSum: "weight"
            },
            {
                key: "entriesPickupAmount",
                label: "amountMustBeCollected",
                type: "",
                ifSum: "pickupAmount"
            },
            {
                key: "notes",
                label: "notes",
                type: "",
            },
        ].concat(shipmentFields),
        BMT: [
            {
                key: "senderName",
                label: "senderName",
                type: ""
            },
            {
                key: "senderZone",
                label: "source",
                type: "zone"
            },
            {
                key: "status",
                label: "shipmentStatus",
                type: ""
            },
            {
                key: "recipientName",
                label: "recipient",
                type: ""
            },
            {
                key: "recipientZone",
                label: "destination",
                type: "zone"
            },
            {
                key: "price",
                label: "packagePrice",
                type: "",
                ifSum: "price"
            },
            {
                key: "notes",
                label: "notes",
                type: "",
            },
            {
                key: "piecesCount",
                label: "pieceCount",
                type: "",
                ifSum: "piecesCount"
            },
            {
                key: "weight",
                label: "weight",
                type: "",
                ifSum: "weight"
            },
            {
                key: "cancellationReason",
                label: "CancellationReasonName",
                type: "",
            },
            {
                key: "attempts",
                label: "attempts",
                type: "",
            },

        ].concat(shipmentFields),
        RITS: [
            {
                key: "date",
                label: "date",
                type: ""
            },
            {
                key: "status",
                label: "shipmentStatus",
                type: ""
            },
            {
                key: "type",
                label: "packageType",
                type: ""
            },
            {
                key: "senderPhone",
                label: "senderPhone",
                type: "phone",
            },
            {
                key: "lastTransactionDate",
                label: "lastTransactionDate",
                type: "",
            },
            {
                key: "entriesWarehouseSection",
                label: "wareHouse",
                type: "link",
                path: "shipments"
            },
            {
                key: "senderName",
                label: "senderName",
                type: ""
            },
            {
                key: "recipient",
                label: "recipient",
                type: "infoGroup"
            },
            {
                key: "paymentType",
                label: "paymentType",
                type: ""
            },
            {
                key: "piecesCount",
                label: "pieceCount",
                type: "",
                ifSum: "piecesCount"
            },
            {
                key: "price",
                label: "packagePrice",
                type: "",
                ifSum: "price"
            },
            {
                key: "amount",
                label: "customerDueAmount",
                type: "",
                ifSum: "amount"
            },
            {
                key: "recipientZone",
                label: "destination",
                type: "zone"
            },
            {
                key: "weight",
                label: "weight",
                type: "",
                ifSum: "weight"
            },
            {
                key: "returningDueFees",
                label: "returningDueFees",
                type: "",
                ifSum: "returningDueFees"
            },
            {
                key: "notes",
                label: "notes",
                type: "",
            },

        ].concat(shipmentFields),
        PRP: [
            {
                key: "date",
                label: "date",
                type: ""
            },
            {
                key: "status",
                label: "shipmentStatus",
                type: ""
            },
            {
                key: "type",
                label: "packageType",
                type: ""
            },
            {
                key: "sender",
                label: "senderName",
                type: "infoGroup"
            },
            {
                key: "lastTransactionDate",
                label: "lastTransactionDate",
                type: "",
            },
            {
                key: "recipient",
                label: "recipient",
                type: "infoGroup"
            },
            {
                key: "recipientZone",
                label: "destination",
                type: "zone"
            },
            {
                key: "paymentType",
                label: "paymentType",
                type: ""
            },
            {
                key: "piecesCount",
                label: "pieceCount",
                type: "",
                ifSum: "piecesCount"
            },
            {
                key: "weight",
                label: "weight",
                type: "",
                ifSum: "weight"
            },
            {
                key: "price",
                label: "packagePrice",
                type: "",
                ifSum: "price"
            },
            {
                key: "returningDueFees",
                label: "returningDueFees",
                type: "",
                ifSum: "returningDueFees"
            },
            {
                key: "amount",
                label: "customerDueAmount",
                type: "",
                ifSum: "amount"
            },
            {
                key: "notes",
                label: "notes",
                type: "",
            },
        ].concat(shipmentFields),
        RTS: [
            {
                key: "status",
                label: "shipmentStatus",
                type: ""
            },
            {
                key: "type",
                label: "packageType",
                type: ""
            },
            {
                key: "senderPhone",
                label: "senderPhone",
                type: "phone",
            },
            {
                key: "entriesWarehouseSection",
                label: "wareHouse",
                type: "link",
                path: "shipments"
            },
            {
                key: "senderName",
                label: "senderName",
                type: ""
            },
            {
                key: "senderZone",
                label: "source",
                type: "zone"
            },
            {
                key: "recipientName",
                label: "recipient",
                type: ""
            },
            {
                key: "recipientZone",
                label: "destination",
                type: "zone"
            },
            {
                key: "entriesCancellationReason",
                label: "reasonName",
                type: "",
            },
            {
                key: "entriesReturnType",
                label: "returnType",
                type: "",
            },
            {
                key: "entriesFees",
                label: "feesAmount",
                type: "",
            },
            {
                key: "notes",
                label: "notes",
                type: "",
            },
        ].concat(shipmentFields),
        OTR: [
            {
                key: "status",
                label: "shipmentStatus",
                type: ""
            },
            {
                key: "type",
                label: "packageType",
                type: ""
            },
            {
                key: "senderPhone",
                label: "senderPhone",
                type: "phone",
            },
            {
                key: "warehouseSection",
                label: "wareHouse",
                type: "link",
                path: "shipments"
            },
            {
                key: "senderName",
                label: "senderName",
                type: ""
            },
            {
                key: "recipientZone",
                label: "destination",
                type: "zone"
            },
            {
                key: "senderZone",
                label: "source",
                type: "zone"
            },
            {
                key: "transactions",
                label: "returnType",
                type: "",
            },
            {
                key: "senderAddress",
                label: "senderAddress",
                type: ""
            },
            {
                key: "recipientName",
                label: "recipient",
                type: ""
            },
            {
                key: "recipientPhone",
                label: "recipientPhone",
                type: "phone",
            },
            {
                key: "notes",
                label: "notes",
                type: "",
            },
        ].concat(shipmentFields),
        RTRN: [
            {
                key: "customer",
                label: "customer",
                type: "link",
                path: "shipments"
            },
            {
                key: "status",
                label: "shipmentStatus",
                type: ""
            },
            {
                key: "type",
                label: "packageType",
                type: ""
            },
            {
                key: "senderName",
                label: "senderName",
                type: ""
            },
            {
                key: "senderAddress",
                label: "senderAddress",
                type: ""
            },
            {
                key: "deliveredAmount",
                label: "deliveredAmount",
                type: "",
                ifSum: "weight"
            },
            {
                key: "recipientName",
                label: "recipient",
                type: ""
            },
            {
                key: "recipientPhone",
                label: "recipientPhone",
                type: "phone",
            },
            {
                key: "recipientZone",
                label: "destination",
                type: "zone"
            },
            {
                key: "returnedValue",
                label: "returnedValue",
                type: "",
                ifSum: "returnedValue"
            },
            {
                key: "returnType",
                label: "returnType",
                type: ""
            },
            {
                key: "amount",
                label: "totalPackagePrice",
                type: "",
                ifSum: "amount"
            },
            {
                key: "weight",
                label: "weight",
                type: "",
                ifSum: "weight"
            },
            {
                key: "notes",
                label: "notes",
                type: "",
            },
        ].concat(shipmentFields),
        BMR: [
            {
                key: "recipientName",
                label: "recipient",
                type: ""
            },
            {
                key: "recipientZone",
                label: "destination",
                type: "zone"
            },
            {
                key: "senderName",
                label: "senderName",
                type: ""
            },
            {
                key: "senderZone",
                label: "source",
                type: "zone"
            },
            {
                key: "recipientAddress",
                label: "recipientAddress",
                type: ""
            },
            {
                key: "entriesWarehouseSection",
                label: "wareHouse",
                type: "link",
                path: "shipments"
            },
            {
                key: "price",
                label: "packagePrice",
                type: "",
                ifSum: "price"
            },
            {
                key: "weight",
                label: "weight",
                type: "",
                ifSum: "weight"
            },
            {
                key: "notes",
                label: "notes",
                type: "",
            },
            {
                key: "cancellationReason",
                label: "CancellationReasonName",
                type: "",
            },
            {
                key: "attempts",
                label: "attempts",
                type: "",
            },
        ].concat(shipmentFields),

        HTR: [
            {
                key: "senderName",
                label: "senderName",
                type: ""
            },
            {
                key: "senderZone",
                label: "source",
                type: "zone"
            },
            {
                key: "recipientName",
                label: "recipient",
                type: ""
            },
            {
                key: "recipientAddress",
                label: "recipientAddress",
                type: ""
            },
            {
                key: "senderPhone",
                label: "senderPhone",
                type: "phone",
            },
            {
                key: "entriesWarehouseSection",
                label: "wareHouse",
                type: "link",
                path: "shipments"
            },
            {
                key: "recipientZone",
                label: "destination",
                type: "zone"
            },
            {
                key: "weight",
                label: "weight",
                type: "",
                ifSum: "weight"
            },
            {
                key: "entriesNotes",
                label: "notes",
                type: ""
            },
            {
                key: "entriesDeliveryDate",
                label: "redeliveryDate",
                type: ""
            },
        ].concat(shipmentFields),
        RCV: [
            {
                key: "status",
                label: "shipmentStatus",
                type: ""
            },
            {
                key: "type",
                label: "packageType",
                type: ""
            },
            {
                key: "deliveryType",
                label: "deliveryType",
                type: "",
            },
            {
                key: "deliveredOrReturnedDate",
                label: "deliveryCanclingDate",
                type: "",
            },
            {
                key: "cancellationReason",
                label: "CancellationReasonName",
                type: "",
            },
            {
                key: "attempts",
                label: "attempts",
                type: "",
            },
            {
                key: "entriesWarehouseSection",
                label: "wareHouse",
                type: "link",
                path: "shipments"
            },
            {
                key: "senderName",
                label: "senderName",
                type: ""
            },
            {
                key: "senderZone",
                label: "source",
                type: "zone"
            },
            {
                key: "recipientName",
                label: "recipient",
                type: ""
            },
            {
                key: "recipientZone",
                label: "destination",
                type: "zone"
            },
            {
                key: "notes",
                label: "notes",
                type: "",
            },
            {
                key: "deliveryDate",
                label: "redeliveryDate",
                type: "",
            },
            {
                key: "weight",
                label: "weight",
                type: "",
                ifSum: "weight"
            },
        ].concat(shipmentFields),
        OTD: [
            {
                key: "sender",
                label: "senderName",
                type: "infoGroup"
            },
            {
                key: "recipient",
                label: "recipient",
                type: "infoGroup"
            },
            {
                key: "recipientZone",
                label: "destination",
                type: "zone"
            },
            {
                key: "recipientAddress",
                label: "recipientAddress",
                type: ""
            },
            {
                key: "openable",
                label: "packageOpen",
                type: "boolean"
            },
            {
                key: "type",
                label: "packageType",
                type: ""
            },
            {
                key: "totalAmount",
                label: "total",
                type: "",
                ifSum: "totalAmount"
            },
            {
                key: "notes",
                label: "notes",
                type: "",
            },
            {
                key: "cancellationReason",
                label: "CancellationReasonName",
                type: "",
            },
        ].concat(shipmentFields),
        STD: [
            {
                key: "date",
                label: "date",
                type: ""
            },
            {
                key: "sender",
                label: "senderName",
                type: "infoGroup"
            },
            {
                key: "recipient",
                label: "recipient",
                type: "infoGroup"
            },
            {
                key: "recipientZone",
                label: "destination",
                type: "zone"
            },
            {
                key: "recipientAddress",
                label: "recipientAddress",
                type: ""
            },
            {
                key: "openable",
                label: "packageOpen",
                type: "boolean"
            },
            {
                key: "type",
                label: "packageType",
                type: ""
            },
            {
                key: "totalAmount",
                label: "total",
                type: "",
                ifSum: "totalAmount"
            },
            {
                key: "notes",
                label: "notes",
                type: "",
            },
        ].concat(shipmentFields),

    }
    permission && shipmentManifest["OTD"].push({ key: "adminNotes", label: "adminNotes", type: "text" })
    return shipmentManifest[manifestType]
}
export const getListShipmentTable = (manifestShipment, key, type) => {
    if (key === undefined) {
        return { value: "noVal" }
    }
    const ListShipmentDetails = {
        customer: {
            pathname: `/admin/customers/${manifestShipment?.shipment?.customer?.id}`,
            value: <>
                <Box component="span" fontWeight="bold">{` (${manifestShipment?.shipment.customer?.code}) `}</Box>
                <Box component="span">  {manifestShipment?.shipment.customer?.name}</Box>
            </>
        },
        code: {
            pathname: `/admin/shipments/${manifestShipment?.shipment.id}`,
            value: manifestShipment?.shipment.code,
            ifCode: "code"
        },
        date: {
            value: dateFormatLL(manifestShipment?.shipment?.date)
        },
        entriesDeliveryDate: {
            value: dateFormatLL(manifestShipment?.deliveryDate)
        },
        deliveryDate: {
            value: dateFormatLL(manifestShipment?.shipment?.deliveryDate)
        },
        deliveredOrReturnedDate: {
            value: dateFormatLL(manifestShipment?.shipment?.deliveredOrReturnedDate)
        },
        description: {
            value: manifestShipment?.shipment?.description,
            condition: "description",
        },
        senderZone: {
            pathname: `/admin/zones/${manifestShipment?.shipment.senderZone?.id}`,
            zone: manifestShipment?.shipment.senderZone?.name,
            subzone: manifestShipment?.shipment.senderSubzone?.name
        },
        recipientZone: {
            pathname: `/admin/zones/${manifestShipment?.shipment?.recipientZone?.id}`,
            zone: manifestShipment?.shipment?.recipientZone?.name,
            subzone: manifestShipment?.shipment?.recipientSubzone?.name
        },
        price: {
            value: formatMoney(manifestShipment.shipment?.price),
        },
        senderAddress: {
            value: manifestShipment.shipment?.senderAddress,
        },
        deliveredAmount: {
            value: formatMoney(manifestShipment.shipment?.deliveredAmount),
        },
        amount: {
            value: formatMoney(manifestShipment?.shipment?.amount)
        },
        totalAmount: {
            value: formatMoney(manifestShipment?.shipment?.type?.code === "RTS" ? 0 : manifestShipment?.shipment?.totalAmount),
        },
        returningDueFees: {
            value: formatMoney(manifestShipment?.shipment?.returningDueFees)
        },
        entriesPickupAmount: {
            value: formatMoney(manifestShipment?.pickupAmount)
        },
        status: {
            value: manifestShipment.shipment?.status?.name,
        },
        attempts: {
            value: manifestShipment.shipment?.attempts,
        },
        lastTransactionDate: {
            value: dateFormatLL(manifestShipment?.shipment.lastTransaction?.date)
        },
        transactions: {
            value: manifestShipment?.shipment?.transactions?.find((i) => i.returnType !== null)?.returnType?.name,
        },
        piecesCount: {
            value: formatMoney(manifestShipment?.shipment?.piecesCount)
        },
        weight: {
            value: manifestShipment?.shipment?.weight
        },
        type: {
            value: manifestShipment?.shipment.type?.name
        },
        paymentType: {
            value: manifestShipment?.shipment.paymentType?.name
        },
        openable: {
            value: manifestShipment?.shipment?.openable?.code === "Y" ? true : false
        },
        entriesCancellationReason: {
            value: manifestShipment.cancellationReason?.name
        },
        cancellationReason: {
            value: manifestShipment.shipment.cancellationReason?.name
        },
        returnType: {
            value: manifestShipment?.shipment.returnType?.name
        },
        entriesReturnType: {
            value: manifestShipment?.returnType?.name
        },
        deliveryType: {
            value: manifestShipment.shipment?.deliveryType?.name ?? manifestShipment.shipment?.status?.name
        },
        entriesWarehouseSection: {
            pathname: `/admin/shipments/${manifestShipment?.warehouseSection?.id}`,
            value: manifestShipment?.warehouseSection?.name
        },
        warehouseSection: {
            pathname: `/admin/shipments/${manifestShipment?.shipment?.warehouseSection?.id}`,
            value: manifestShipment?.shipment?.warehouseSection?.name
        },
        barcode: {
            value: manifestShipment?.shipment.code
        },
        senderName: {
            value: manifestShipment?.shipment.senderName
        },
        sender: {
            name: manifestShipment?.shipment?.senderName,

            mobile: (manifestShipment?.shipment?.customer?.isMobileHidden && type === "OTD") ? "" : manifestShipment?.shipment?.senderMobile,
            phone: (manifestShipment?.shipment?.customer?.isMobileHidden && type === "OTD") ? "" : manifestShipment?.shipment?.senderPhone
        },
        notes: {
            value: manifestShipment?.shipment.notes
        },
        adminNotes: {
            value: manifestShipment?.shipment.adminNotes
        },

        entriesNotes: {
            value: manifestShipment?.notes
        },
        refNumber: {
            value: manifestShipment?.shipment.refNumber
        },
        recipientName: {
            value: manifestShipment?.shipment.recipientName
        },

        recipientAddress: {
            value: manifestShipment?.shipment.recipientAddress
        },
        senderPhone: {
            mobile: (manifestShipment?.shipment?.customer?.isMobileHidden && type === "OTD") ? "" : manifestShipment?.shipment?.senderMobile,
            phone: (manifestShipment?.shipment?.customer?.isMobileHidden && type === "OTD") ? "" : manifestShipment?.shipment?.senderPhone
        },
        recipient: {
            name: manifestShipment?.shipment?.recipientName,
            mobile: manifestShipment?.shipment?.recipientMobile,
            phone: manifestShipment?.shipment?.recipientPhone
        },
        recipientPhone: {
            mobile: manifestShipment?.shipment?.recipientMobile,
            phone: manifestShipment?.shipment?.recipientPhone
        },
        entriesFees: {
            value: formatMoney(manifestShipment?.fees)
        },
        returnedValue: {
            value: formatMoney(manifestShipment?.shipment?.returnedValue)
        }
    }
    return Boolean(ListShipmentDetails[key]) ? ListShipmentDetails[key] : { value: manifestShipment[key] }
}

export const getShipmentsTableHeader = (keys, manifestType, permission) => {
    let tableHeader = []
    keys.map((keyName) =>
        shipmentFieldsFun(manifestType, permission).map((i) =>
            keyName === i.key && tableHeader.push(i.label)
        )
    )
    return tableHeader
}


export const getShipmentsSumQuery = (TotalData, key) => {
    if (key === undefined) {
        return { value: "noVal" }
    }
    const ListShipmentDetails = {
        weight: { value: TotalData?.sumEntries?.weight },
        piecesCount: { value: TotalData?.sumEntries?.piecesCount },
        totalAmount: { value: TotalData?.sumEntries?.totalAmount },


        returningDueFees: { value: TotalData?.sumEntries?.returningDueFees },

        price: { value: TotalData?.sumEntries?.price, },

        deliveredAmount: { value: TotalData?.sumEntries?.deliveredAmount },

        entriesPickupAmount: { value: TotalData?.sumEntries?.pickupAmount },

        amount: { value: TotalData?.sumEntries?.amount, },

        entriesFees: { value: TotalData?.sumEntries?.fees, },

        returnedValue: { value: TotalData?.sumEntries?.returnedValue, }
    }
    return Boolean(ListShipmentDetails[key]) ? ListShipmentDetails[key] : { value: undefined }
}

export const initialManifest = {
    PKM: [
        "code",
        "senderZone",
        "senderAddress",
        "type",
        "description",
        "price",
        "piecesCount",
        "weight",
        "entriesPickupAmount",
    ],
    BMT: [
        "code",
        "senderName",
        "senderZone",
        "recipientName",
        "recipientZone",
        "description",
        "piecesCount",
        "weight",
        "price"
    ],

    RITS: [
        "code",
        "entriesWarehouseSection",
        "senderName",
        "recipient",
        "recipientZone",
        "paymentType",
        "description",
        "piecesCount",
        "weight",
        "price",
        "returningDueFees",
        "amount",
    ],
    PRP: [
        "code",
        "sender",
        "recipient",
        "recipientZone",
        "paymentType",
        "description",
        "piecesCount",
        "weight",
        "price",
        "returningDueFees",
        "amount",
    ],
    RTS: [
        "code",
        "senderName",
        "recipientName",
        "recipientZone",
        "entriesCancellationReason",
        "entriesReturnType",
        "entriesFees",

    ],
    OTR: [
        "code",
        "warehouseSection",
        "senderName",
        "senderZone",
        "senderAddress",
        "recipientName",
        "senderZone",
        "description",
        "transactions",
    ],
    RTRN: [
        "code",
        "customer",
        "recipientName",
        "recipientZone",
        "description",
        "returnType",
        "weight",
        "returnedValue",
        "amount",
    ], BMR: [
        "code",
        "senderName",
        "senderZone",
        "recipientName",
        "recipientZone",
        "description",
        "weight",
    ],
    HTR: [
        "code",
        "senderName",
        "senderZone",
        "recipientName",
        "recipientZone",
        "description",
        "entriesDeliveryDate",
        "Notes",
        "weight",
    ],
    RCV: [
        "code",
        "entriesWarehouseSection",
        "senderName",
        "senderZone",
        "recipientName",
        "recipientZone",
        "description",
        "deliveryDate",
        "notes",
        "weight",

    ],


    OTD: [
        "code",
        "recipient",
        "recipientZone",
        "recipientAddress",
        "sender",
        "type",
        "description",
        "openable",
        "notes",
        "totalAmount",
    ],

    STD: [
        "code",
        "recipient",
        "recipientZone",
        "recipientAddress",
        "sender",
        "type",
        "description",
        "openable",
        "notes",
        "totalAmount",
    ],
}
export const changeKey = (originalArray, localStorageName) => {
    let keyArray = originalArray.map(field => {
        switch (field) {
            case "note":
                return "entriesNotes";
            case "allDueFees":
                return "entriesPickupAmount";
            case "deliveryDateshipment":
                return "deliveryDate";
            case "fees":
                return "entriesFees";
            case "warehouseSections":
                return "warehouseSection";
            case "returnTypes":
                return "entriesReturnType";
            case "deliveredCanceledDate":
                return "deliveredOrReturnedDate";
            case "cancellationReasonshipment":
                return "cancellationReason";
            case "amounts":
                return "amount";
            default:
                return field;
        }
    });
    localStorage.setItem(localStorageName, JSON.stringify(keyArray));
    return (keyArray)


}