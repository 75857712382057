/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  Toolbar,
  IconButton,
  Collapse,
  InputAdornment,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";
import clsx from "clsx";
import MUItextField from "../../HOC/MUI/MUItextField";
import PropTypes from "prop-types";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import CustomTableRow from "./CustomTableRow";
import { EnhancedTableHead } from "./ShipmentListDetails";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import CustomSpinner from "../../HOC/FunctionComponents/CustomSpinner";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import { SAVE_INVOICE_ENTRIES_MUTATION } from "../InvoicesListQuary";
import { SHIPMENT_QUERY } from "./Graphql";
import soundSuccess from "../../../assets/Sound/success.mp3";
import soundFail from "../../../assets/Sound/fail.mp3";

const TOOLBAR_PREFIX = "styledToolbar";
const toolbarClasses = {
  highlight: `${TOOLBAR_PREFIX}-highlight`,
  title: `${TOOLBAR_PREFIX}-title`,
};

const StyledToolbar = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1,

  [`& .${toolbarClasses.highlight}`]:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[300],
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[800],
      },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 100%",
  },
}));

const PREFIX = "BarcodeSelector";
const classes = {
  paper: `${PREFIX}-paper`,
};

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  [`& .${classes.paper}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowX: "auto",
    maxHeight: `calc(100vh - ${theme.mixins.toolbar["minHeight"]}px)`,
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { numSelected, done, clear, disabled } = props;
  return (
    <StyledToolbar>
      <Toolbar
        className={clsx({
          [toolbarClasses.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={toolbarClasses.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t("shipment", { count: numSelected })}
          </Typography>
        ) : (
          <Typography
            className={toolbarClasses.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("shipments")}
          </Typography>
        )}

        <IconButton
          aria-label="done"
          disabled={disabled}
          onClick={done}
          sx={{ color: "success.main" }}
          size="large"
        >
          <CheckCircle />
        </IconButton>

        <IconButton
          aria-label="close"
          onClick={() => clear()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
      </Toolbar>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  done: PropTypes.func.isRequired,
};

//*********Table Function*********

const BarcodeSelector = (props) => {
  const { variables, done, refetchShipmentDataQuery, invoiceRefetch } = props;
  const audioSuccess = new Audio(soundSuccess)
  const audioFail = new Audio(soundFail)
  const [shipments, setShipments] = useState([]);
  const [shipmentCode, setShipmentCode] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { register, setValue } = useForm();

  const handleOnClose = () => {
    setShipments([]);
  };

  const validShipments = () => {
    return selectedShipmentLength <= 0;
  };

  const { loading } = useQuery(
    gql`
      ${SHIPMENT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !variables.id || !shipmentCode,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: variables.id,
        filter: {
          // forInvoiceId: variables.id,
          code: shipmentCode,
        },
      },
      onCompleted: (data) => {
        const handledData =
          data?.listShipmentsForInvoice?.data !== null ? data?.listShipmentsForInvoice?.data : [];
        if (handledData.length > 0) {
          const enhancedData = handledData.map((i) => ({
            shipment: i,
            select: true,
          }));

          setShipments((prev) => [...prev, ...enhancedData]);
          setValue("barCode", "");
          setShipmentCode(null);
          audioSuccess.play()
        } else {
          audioFail.play()
          enqueueSnackbar(t("noShipmentWithCode"), {
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      },
    }
  );
  const selectedShipment = shipments.filter((i) => i.select === true);
  const selectedShipmentLength = selectedShipment.length;
  const selectedValidShipment = selectedShipment;

  const handleSelectAllClick = (event, rows) => {
    const selected = shipments?.map((i) => {
      if (event.target.checked) {
        return {
          ...i,
          select: true,
        };
      } else {
        return {
          ...i,
          select: false,
        };
      }
    });
    setShipments(selected);
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const [saveinvoiceEntriesMutation, { loading: barcodeLoading }] = useMutation(
    gql`
      ${SAVE_INVOICE_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );
  const onSaveEntries = (data) => {
    const shipmentIds = data.map((item) => item.shipment?.shipment.id);
    shipmentIds.length > 0 &&
      saveinvoiceEntriesMutation({
        variables: {
          input: {
            invoiceId: variables.id,
            shipmentIds: shipmentIds,
          },
        },
      })
        .then((data) => {
          enqueueSnackbar(t("saveSuccessful"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          invoiceRefetch();
          refetchShipmentDataQuery();
          props.done();
        })
        .catch((error) => {
          console.log(error);
        });
  };

  let tableBody = null;
  if (shipments) {
    tableBody = (
      <Grid container justifyContent="center" sx={{ width: "100%" }}>
        {/* *******Table******* */}
        <Grid container item>
          <div className={classes.paper}>
            <TableFixedHeaderWraper containerScroll>
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <EnhancedTableHead
                  numSelected={selectedShipmentLength}
                  onSelectAllClick={(e) => handleSelectAllClick(e, shipments)}
                  rowCount={shipments?.length}
                />
                <TableBody>
                  {
                    // stableSort(, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    shipments?.map((shipment, index) => {
                      // const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <CustomTableRow
                          key={index}
                          shipment={shipment}
                          onChangeCheck={(check, selectedShipment) => {
                            setShipments((prev) => {
                              let updated = [...prev];
                              updated[index] = {
                                ...updated[index],
                                select: !updated[index].select,
                              };

                              return updated;
                            });
                          }}
                          labelId={labelId}
                        />
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          </div>
        </Grid>
      </Grid>
    );
  }
  return (
    <Root container justifyContent="center">
      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={barcodeLoading}
        onClick={() => { }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <EnhancedTableToolbar
        numSelected={selectedShipmentLength}
        clear={() => {
          handleOnClose();
          done();
          setValue("barCode", "");
        }}
        done={() => {
          onSaveEntries(selectedValidShipment);
        }}
        disabled={validShipments()}
      />

      <Grid container item justifyContent="center" sx={{ p: 3 }} xs={12}>
        <MUItextField
          label={t("barcode")}
          autoFocus
          name={"barCode"}
          register={register}
          inputProps={{
            dir: "ltr",
          }}
          readOnly={loading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loading && (
                  <CustomSpinner name="PulseLoader" size={5} margin={2} />
                )}
              </InputAdornment>
            ),
          }}
          defaultValue=""
          onKeyPress={(e) => {
            const value = e.target.value;
            const duplicatedCode = shipments.findIndex(
              (i) => i.shipment?.shipment.code.toLowerCase() === value.toLowerCase()
            );
            if (duplicatedCode !== -1) {
              audioFail.play()
              enqueueSnackbar(t("thisShipmentIsRepeated"), {
                variant: "info",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
              setValue("barCode", "");
              return;
            }
            if (e.key === "Enter") {
              setShipmentCode(value);
            }
          }}
        />
      </Grid>
      {shipments && shipments?.length !== 0 ? (
        tableBody
      ) : (
        <EmptyTableMessage message={t("noResult")} />
      )}
    </Root>
  );
};

export default BarcodeSelector;
