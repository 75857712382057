import * as gqlb from "gql-query-builder";

export const LIST_ALERTS = gqlb.query({
  operation: "listAlerts",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "lastPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "message",
        "subject",
        "sentAt",
        "createdAt",
        "sent",
        // recipients{
        //   ...on Customer {
        //     id
        //     name
        //     __typename
        //   }
        //   ...on DeliveryAgent {
        //     id
        //     name
        //     __typename
        //   }
        // }
        {
          operation: "recipients",
          fields: [
            {
              operation: "...on Customer",
              fields: ["id", "name", "code"],
              variables: {},
            },
            {
              operation: "...on DeliveryAgent",
              fields: ["id", "name", "code"],
              variables: {},
            },
          ],
          variables: {},
        },
        {
          images: ["path", "subjectCode"]
        },
        {
          createdBy: ["id", "username"],
        },
        {
          updatedBy: ["id", "username"],
        },
        {
          channels: ["code", "name"]
        },
        {
          channels: ["code", "name"]
        },
        { type: ["name", "code"], },
        { recipientType: ["name", "code"], },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListAlertsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SAVE_ALERT = gqlb.mutation({
  operation: "saveAlert",
  fields: [
    "id",
    "message",
    "subject",
    "sent",
    {
      images: ["path"]
    },
    {
      channels: ["code", "name"]
    },
    { type: ["name", "code"], },
  ],
  variables: {
    input: {
      type: "AlertInput",
      required: true,
    },
  },
});

export const DELETE_ALERT = gqlb.mutation({
  operation: "deleteAlert",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});