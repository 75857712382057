import React, { useEffect } from "react";
// import "./ShipmentPrint.css";
import * as gqlb from "gql-query-builder";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";

import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
// import "./ShipmentPrint.css";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import clsx from "clsx";

// import { Box, Typography } from "@mui/material";
// import { Root, classes } from "./ShipmentPrintStyle";
// import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import WaybillA4, { Root, classes } from "./Waybill/WaybillA4";
import LogoImg from "../../Layout/LogoImg";
import useSettingsQuery from "./Waybill/queriesBuilder/useSettingsQuery";
import useWaybillShipment from "./Waybill/queriesBuilder/useWaybillShipment";
import useWaybillBatch from "./Waybill/queriesBuilder/useWaybillBatch";
import useWaybillOperation from "./Waybill/queriesBuilder/useWaybillOperation";

const listShipmentFields = [
  {
    data: [
      "code",
      "recipientName",
      "recipientAddress",
      "recipientMobile",
      "recipientPhone",
      "description",
      "weight",
      "piecesCount",
      "totalAmount",
      "senderName",
      "senderPhone",
      "senderMobile",
      "amount",
      "refNumber",
      "allDueFees",
      "notes",
      "date",
      {
        type: ["name", "code"],
      },
      {
        originBranch: ["name"],
      },
      {
        customer: [
          "code",
          {
            operation: `isMobileHidden(code: WAYBILL)`,
            fields: [],
            variables: {
            },
          },
        ],
      },
      {
        openable: ["code"],
      },

      {
        recipientZone: ["name"],
      },
      {
        recipientSubzone: ["name"],
      },
      {
        senderZone: ["name"],
      },
      {
        senderSubzone: ["name"],
      },
    ],
  },
];

const barcodeBatch = [
  "id",
  {
    barcodes: [
      "id",
      "code",
      {
        customer: [
          "code",
          {
            operation: `isMobileHidden(code: WAYBILL)`,
            fields: [],
            variables: {
            },
          },
        ],
      },
    ],
  },
];
export const SHIPPING_SETTING = gqlb.query({
  operation: "shippingSettings",
  fields: [{ localCurrency: ["name"] }],
  variables: {},
});

const ShipmentPrint = (props) => {
  const id = props.match.params.id.split(",").map(Number);
  const isBatch = props.match.params.type === "batch";
  const threeCopies = props.match.params.size === "3c";
  const twoCopies = props.match.params.size === "2c";
  const threeDifferent = props.match.params.size === "3d";
  const oneCopy = props.match.params.size === "1c";
  const operation = props.match.params.operation
  const urlQuery = urlParameters(window.location.search);
  const { i18n } = useTranslation();


  const token = urlQuery["token"];
  const lang = urlQuery["lang"];

  const { settings, currency, footer, header } = useSettingsQuery({
    token,
    lang,
    fields: ["reportHeader", "reportFooter"]
  });

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
    return () => { };
  }, [i18n, lang, settings]);

  const shipments = useWaybillShipment({
    id,
    templateShipmentFields: listShipmentFields,
    skip: isBatch || operation,
    token,
    lang,
  });

  const batch = useWaybillBatch({
    id,
    templateBatchFields: barcodeBatch,
    skip: !isBatch || operation,
    token,
    lang,
  });


  const entries = useWaybillOperation({
    id,
    operation,
    skip: !operation,
    token,
    lang,
    templateShipmentFields: listShipmentFields,
  });

  const waybillData = shipments?.parsedData || batch?.parsedData || entries.parsedData
  const waybillLoading = shipments?.loading || batch?.loading || entries.loading

  let shipmentsArray = [];
  if (waybillData.length > 0) {
    waybillData.forEach((shipment, i) => {
      if (oneCopy) {
        const copyNumber = 1;
        let smallShipment = [];
        for (let i = 0; i < copyNumber; i++) {
          smallShipment.push(shipment);
        }
        shipmentsArray.push(smallShipment);
      } else if (threeCopies || twoCopies) {
        const copyNumber = twoCopies ? 2 : 3;
        let smallShipment = [];
        for (let i = 0; i < copyNumber; i++) {
          smallShipment.push(shipment);
        }
        shipmentsArray.push(smallShipment);
      } else {
        const differentNumber = threeDifferent ? 3 : 2;
        const index = shipmentsArray.findIndex(
          (i) => i.length < differentNumber
        );
        if (index !== -1) {
          shipmentsArray[index].push(shipment);
        } else {
          shipmentsArray.push([shipment]);
        }
      }
    });
  }

  const logoImg = <LogoImg className={classes.logoImg} forceLight={true} />

  useEffect(() => {
    if (shipmentsArray.length) {
      document.fonts.onloadingdone = function (fontFaceSetEvent) {
        window.print();
        document.fonts.onloadingdone = null;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipments?.data || batch?.data || entries?.data])

  return (
    <Root>
      <meta name="viewport" content="width=no, user-scalable=no" />
      <link
        rel="stylesheet"
        type="text/css"
        href={process.env.PUBLIC_URL + "/print-portrait.css"}
      />
      {waybillLoading ? (
        <>
          <div style={{ visibility: "hidden", height: 0 }}>{logoImg}</div>
          <FullScreenLoading minHeight="30%" />
        </>
      ) : (
        shipmentsArray.map((page, index) => {
          return (
            <div key={index} className={clsx(classes.shipmentInvoiceContainer)}>
              {page.map((shipment, shipmentIndex) => {
                const rtsShipment = shipment?.type?.code === "RTS";
                return (
                  <WaybillA4
                    key={shipmentIndex}
                    shipment={shipment}
                    shipmentIndex={shipmentIndex}
                    threeCopies={threeCopies}
                    threeDifferent={threeDifferent}
                    oneCopy={oneCopy}
                    logoImg={logoImg}
                    header={header}
                    currency={currency}
                    rtsShipment={rtsShipment}
                    isBatch={isBatch}
                    footer={footer}
                  />
                );
              })}
            </div>
          );
        })
      )}
    </Root>
  );
};

export default withRouter(ShipmentPrint);
