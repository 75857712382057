import React, { useState, useEffect, useMemo } from "react";
import { styled } from '@mui/material/styles';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
  IconButton,
  InputAdornment,
  Typography,
  Container,
  CssBaseline,
  Avatar,
  Collapse,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import MUItextField from "../HOC/MUI/MUItextField";
import {
  AccountCircleOutlined,
  AddCircleOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import MUIselect from "../HOC/MUI/MUIselect";
import { MenuDataHandler } from "../HOC/CustomFunctions/MenuDataHandler";
import { useTranslation } from "react-i18next";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import * as gqlb from "gql-query-builder";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { useSnackbar } from "notistack";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { Can } from "../HOC/CustomComponents/Secured";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { getFormatNumber, validNumber } from "../../helpers/asYouType";
import MuiPhone from "../HOC/MUI/MUIphoneNumber";

const PREFIX = 'Registration';

const classes = {
  main: `${PREFIX}-main`,
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  button: `${PREFIX}-button`,
  margin: `${PREFIX}-margin`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  login: `${PREFIX}-loginSpan`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.main}`]: {
    margin: "2.7% 0",
    zIndex: "1",
    "& .MuiFilledInput-adornedEnd": {
      padding: "0px !important",
    },
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.margin}`]: {
    padding: theme.spacing(0, 1),
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  [`& .${classes.login}`]: {
    marginLeft: theme.spacing(1),
    textTransform: 'capitalize'
  }
}));

///////////graphql/////////////
const NEW_USER = gql`
  mutation registration($input: RegistrationInput!) {
    register(input: $input)
  }
`;

const userWithToken = [
  "token",
  {
    user: [
      "username",
      "isSuper",
      {
        operation: "account",
        fields: [
          {
            operation: "...on Customer",
            fields: ["id", "name", "email"],
            variables: {},
          },
          {
            operation: "...on DeliveryAgent",
            fields: ["id", "name", "code"],
            variables: {},
          },
        ],
        variables: {},
      },
      {
        roles: [
          "id",
          "name",
          "code",
          {
            permissions: ["id", "name", "slug"],
          },
        ],
      },
    ],
  },
];
const VERIFY = gqlb.mutation({
  operation: "verifyRegistrationEmail",
  fields: userWithToken,
  variables: {
    input: {
      type: "VerifyRegistrationEmailInput",
      required: true,
    },
  },
});
const ZONE = gqlb.query({
  operation: "listZonesDropdown",
  fields: ["id", "name"],
  variables: {
    input: {
      type: "ListZonesFilterInput",
    },
  },
});

const COUNTRY = gqlb.query({
  operation: "listCountriesDropdown",
  fields: ["id", "name"],
  variables: {},
});

const BANK_ID = gqlb.query({
  operation: "listBanksDropdown",
  fields: ["id", "name"],
  variables: {},
});
const SHIPPING_SETTINGS = gqlb.query({
  operation: "shippingSettings",
  fields: ["forcePostalCode", "countryCode", "allowPhoneKey", "multiCountries"],
  variables: {},
});

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {"Copyright © "}
//       <Link
//         color="inherit"
//         href="https://www.facebook.com/AccurateInformationTechnology"
//       >
//         Accurate
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

//////////////////////Registration function////////////////////////

const Registration = (props) => {
  const controller = useMemo(() => new AbortController(), []);

  const { t } = useTranslation();

  ////////THEMES//////////
  const theme = useTheme();

  const dir = theme.direction;

  //////////STATES//////////
  const [dialog, setDialog] = useState(false);
  const [byBank, setByBank] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // const [open, setOpen] = React.useState(false);
  const [holdVerifay, setHoldVerifay] = useState(false);

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const [zoneName, setZoneName] = useState({
  //   zone: "",
  //   subzone: ""
  // });
  // const [location, setLocation] = useState({
  //   latitude: null,
  //   longitude: null,
  // });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    setError,
    formState,
    getValues,
    watch,
  } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  ////////VLAIDATION FORM/////////
  const { errors } = formState;
  // const multiCountry = config.app.multiCountry;
  const signal = controller.signal;
  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [controller]);



  //////////////////// graphql Function ///////////////////

  const [addUser, { loading: loadUser }] = useMutation(NEW_USER, {
    context: {
      fetchOptions: {
        signal,
      },
    },
    onError: ({ graphQLErrors, networkError }) => {
      console.log(networkError);
      setValidationError(graphQLErrors, setError);
    },
    fetchPolicy: "no-cache",
  });
  const [verify, { loading: verifayLoad }] = useMutation(gql`
    ${VERIFY.query}
  `);

  const { data: settings } = useQuery(
    gql`
      ${SHIPPING_SETTINGS.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",

      onError: (error) => console.log(error),
    }
  );
  const forcePostalCode = settings?.shippingSettings?.forcePostalCode;
  const countryCode = settings?.shippingSettings?.countryCode;
  const allowPhoneKey = settings?.shippingSettings?.allowPhoneKey;
  const multiCountries = settings?.shippingSettings?.multiCountries;
  useEffect(() => {
    if (multiCountries) {
      fetchCountries();
    } else {
      fetchZone();
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiCountries]);
  const [fetchCountries, { data: countriesData }] = useLazyQuery(
    gql`
      ${COUNTRY.query}
    `,
    {
      variables: {
        input: { active: true },
      },
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
    }
  );
  const [fetchZone, { data: zoneData }] = useLazyQuery(
    gql`
      ${ZONE.query}
    `,
    {
      variables: {
        input: { active: true, parentId: null },
      },
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
    }
  );

  const [refetchSubzone, { data: subzoneData }] = useLazyQuery(
    gql`
      ${ZONE.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onError: (error) => console.log(error),
    }
  );

  const [refetchBank, { data: bankId }] = useLazyQuery(
    gql`
      ${BANK_ID.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError: (error) => console.log(error),
    }
  );
  ////////SERVER VALIDATION/////////

  /////////DIALOG HANDLER//////////
  const closeDialog = () => {
    setDialog(false);
  };

  /////////SUBMIT HANDLER////////////
  const onSubmit = async (data) => {
    if (allowPhoneKey) {
      const checkMobile = validNumber(data.mobile, data.mobileCode, "notRequired")
      let names = [
        {
          name: "mobile",
          validate: checkMobile.valid,
          message: checkMobile.message,
        },
      ];
      if (!checkMobile.valid) {
        names.map((e) => !e.validate && setError(e.name, { type: 'custom', message: t(e.message) }))
        return
      }

      data.mobile = data.mobile && getFormatNumber(data.mobile, data.mobileCode)
    }
    await addUser({
      variables: {
        input: {
          name: data.name,
          businessName: data.businessName,
          email: data.email,
          ...(forcePostalCode && { postalCode: data.postalCode }),
          password: data.password,
          mobile: data.mobile,
          zoneId: data.zoneId,
          subzoneId: data.subzoneId,
          address: data.address,
          paymentMethodCode: data?.paymentMethodCode,
          customerTypeCode: "MERCHANT",
          ...(data?.vodafoneCash && { vodafoneCash: data?.vodafoneCash }),
          ...(byBank && { bankId: data?.bankId }),
          ...((watch("paymentMethodCode") === "INSTPY" || byBank) && { accountNumber: data?.accountNumber }),
          // ...(location.latitude && { latitude: location.latitude }),
          // ...(location.longitude && { longitude: location.longitude }),
        },
      },
    })
      .then((res) => {
        if (res.data.register !== null) {
          setDialog(true);
        }
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
      });
  };
  const verifyHandler = async (datas) => {
    await verify({
      variables: {
        input: {
          email: datas.email,
          code: datas.code,
        },
      },
    })
      .then((res) => {
        const verifyRegistrationEmail = res?.data?.verifyRegistrationEmail;
        if (verifyRegistrationEmail) {
          setDialog(false);
          const token = res.data.verifyRegistrationEmail.token;
          if (token) {
            setDialog(false);
            const verifyEmail = res.data.verifyRegistrationEmail;
            if (
              !verifyEmail.user.isSuper &&
              verifyEmail.user.roles.some((ele) => ele.code === "DLVRY")
            ) {
              enqueueSnackbar(t("loginProhibited"), {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
            } else {
              // setUser override userDataLoader component
              // Globals.setUser(new User(verifyEmail.user));

              const token = verifyEmail.token;
              localStorage.setItem("token", token);
              pushUrl(props, `/admin`);
            }
          } else {
            setHoldVerifay(true);
          }
        } else {
          setHoldVerifay(true);
        }
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        if (graphQLErrors[0].extensions.code === "INVALID_VERIFICATION_CODE") {
          setError("code", {
            type: "required",
            message: t("activationErrorMessage"),
            shouldFocus: true,
          });
        }
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <StyledContainer maxWidth="xs">
      <CssBaseline />

      {/* <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
            <Typography color="inherit" variant="h6" className={classes.title}>
              {t("map")}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              {t("save")}
            </Button>
          </Toolbar>
        </AppBar>
        <Maps
          zoneName={zoneName}
          location={(latitude, longitude) => {
            setLocation({
              ...location,
              latitude: latitude,
              longitude: longitude,
            });
          }}
        />
      </Dialog> */}

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        dir={dir}
        open={dialog}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
      >
        {holdVerifay ? (
          <>
            <DialogTitle id="form-dialog-title">
              {t("accountPendingApprovalMessage")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("accountPendingApprovalTitle")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                {t("close")}
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="form-dialog-title" color="text.primary">
              {t("activationCode")}
            </DialogTitle>
            <form onSubmit={handleSubmit(verifyHandler)}>
              <DialogContent>
                <DialogContentText>{t("codeSentToEmail")}</DialogContentText>
                <MUItextField
                  name={"code"}
                  label={t("activationCode")}
                  margin="normal"
                  size="medium"
                  register={register}
                  errors={errors}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog} color="primary">
                  {t("close")}
                </Button>
                <Button disabled={verifayLoad} type="submit" color="primary">
                  {verifayLoad ? (
                    <CustomSpinner name="BeatLoader" size={8} margin={2} />
                  ) : (
                    t("activateAccount")
                  )}
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Dialog>

      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleOutlined />
        </Avatar>
        <Typography component="h1" variant="h5" color={"text.primary"}>
          {t("createNewAccount")}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Grid container item alignItems="center" justifyContent="center">
            <Grid item className={classes.margin} sm={12} xs={12}>
              <MUItextField
                margin="dense"
                name={"businessName"}
                label={t("storeName")}
                register={register}
                errors={errors}
              />
            </Grid>
            <Grid item className={classes.margin} sm={12} xs={12}>
              <MUItextField
                margin="dense"
                name={"name"}
                label={t("name")}
                register={register}
                formType={"requireFalse"}
                errors={errors}
              />
            </Grid>
            <Grid item className={classes.margin} sm={12} xs={12}>
              <MUItextField
                margin="dense"
                name={"email"}
                label={t("email")}
                register={register}
                errors={errors}
                formType={"pattern"}
                formVal={{
                  value:
                    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t("emailIsInvalid"),
                }}
              />
            </Grid>
            <Grid item className={classes.margin} sm={12} xs={12}>
              <MUItextField
                margin="dense"
                name={"password"}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label={t("password")}
                register={register}
                errors={errors}
              />
            </Grid>

            {countryCode && <Grid item className={classes.margin} sm={12} xs={12}>
              <MuiPhone
                setValue={setValue}
                codeValue={getValues().mobileCode}
                margin="dense"
                control={control}
                errors={errors}
                name={"mobile"}
                selectName={"mobileCode"}
                settingPhoneKey={allowPhoneKey}
                countryCodeValue={countryCode}
                label={t("phone")}
                rules={{
                  required: t("fieldIsRequired"),
                }}
              />
            </Grid>}
            {multiCountries &&
              <Grid item className={classes.margin} xs={12}>
                <MUIselect
                  margin="dense"
                  control={control}
                  label={t("country")}
                  rules={{ required: t("fieldIsRequired") }}
                  name="countryId"
                  data={MenuDataHandler(countriesData?.listCountriesDropdown)}
                  onChanges={(e) => {
                    // setZoneName((prevState) => {
                    //   return {
                    //     ...prevState,
                    //     zone: "",
                    //     subzone: "",
                    //   };
                    // });
                    fetchZone({
                      variables: {
                        input: { countryId: e.target.value, parentId: null },
                      },
                    });
                    setValue("zoneId", "");
                    setValue("subzoneId", "");
                  }}
                />
              </Grid>}
            <Grid item className={classes.margin} sm={6} xs={12}>
              <MUIselect
                margin="dense"
                control={control}
                label={t("zone")}
                rules={{ required: t("fieldIsRequired") }}
                name="zoneId"
                data={MenuDataHandler(zoneData?.listZonesDropdown)}
                onChanges={(e) => {
                  // console.log(e.currentTarget);
                  // setZoneName((prevState) => {
                  //   return {
                  //     ...prevState,
                  //     zone: e.currentTarget.textContent,
                  //     subzone: "",
                  //   };
                  // });
                  e.target.value &&
                    refetchSubzone({
                      variables: {
                        input: { parentId: e.target.value, active: true },
                      },
                    });
                  setValue("subzoneId", "");
                }}
              />
            </Grid>
            <Grid item className={classes.margin} sm={6} xs={12}>
              <MUIselect
                margin="dense"
                control={control}
                label={t("subzone")}
                name="subzoneId"
                disabled={!watch("zoneId")}
                data={MenuDataHandler(subzoneData?.listZonesDropdown)}
                // onChanges={(e) => {
                //   setZoneName((prevState) => {
                //     return {
                //       ...prevState,
                //       subzone: e.currentTarget.textContent,
                //     };
                //   });
                // }}
                rules={{ required: t("fieldIsRequired") }}
              />
            </Grid>

            <Can permission={forcePostalCode} showException>
              <Grid item className={classes.margin} sm={12} xs={12}>
                <MUItextField
                  margin="dense"
                  name={"postalCode"}
                  label={t("postalCode")}
                  register={register}
                  errors={errors}
                />
              </Grid>
            </Can>

            <Grid item className={classes.margin} sm={12} xs={12}>
              <MUItextField
                margin="dense"
                // InputProps={{
                //   endAdornment: (
                //     <IconButton
                //       onClick={() => {
                //         setOpen(true);
                //       }}
                //       size="large"
                //     >
                //       <LocationOn />
                //     </IconButton>
                //   ),
                // }}
                name={"address"}
                label={t("address")}
                register={register}
                errors={errors}
              />
            </Grid>

            <Grid item className={classes.margin} sm={12} xs={12}>
              <LookupDropdown
                control={control}
                errors={errors}
                variables={{
                  input: { code: "SHP_PAYMENT_METHODS" },
                }}
                margin="dense"
                label={t("paymentType")}
                name="paymentMethodCode"
                onChanges={(e) => {
                  if (e.target.value === "BNK") {
                    setByBank(true);
                    refetchBank();
                  } else {
                    setByBank(false);
                    setValue("bankId", "");
                    setValue("vodafoneCash", "");
                    setValue("accountNumber", " ");
                    // unregister(["bankId","accountNumber"]);
                  }
                }}
                rules={{ required: t("fieldIsRequired") }}
              />
            </Grid>
            {watch("paymentMethodCode") === "VCSH" && (
              <Grid item className={classes.margin} sm={12} xs={12}>
                <MUItextField
                  margin="dense"
                  name={"vodafoneCash"}
                  label={t("walletNumber")}
                  register={register}
                  errors={errors}
                  formType={"minLength"}
                  formVal={{ value: 10, message: t("typeCorrectPhone") }}
                />
              </Grid>
            )}
            {byBank && (
              <Grid item className={classes.margin} sm={12} xs={12}>
                <MUIselect
                  margin="dense"
                  label={t("bank")}
                  name="bankId"
                  data={MenuDataHandler(bankId?.listBanksDropdown)}
                  control={control}
                  rules={{ required: t("fieldIsRequired") }}
                />
              </Grid>
            )}
            {(byBank || watch("paymentMethodCode") === "INSTPY") && (
              <Grid item className={classes.margin} sm={12} xs={12}>
                <MUItextField
                  margin="dense"
                  name={"accountNumber"}
                  label={t("accountNumber")}
                  register={byBank || watch("paymentMethodCode") === "INSTPY" ? register : undefined}
                  errors={errors}
                  formType={"maxLength"}
                  formVal={{
                    value: 50,
                    message: "Account number should be less than 50 character",
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                className={classes.button}
                disabled={loadUser}
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                startIcon={
                  loadUser ? (
                    <CustomSpinner size={24} name={"HashLoader"} />
                  ) : (
                    <AddCircleOutline />
                  )
                }
                type="submit"
              >
                {t("createNewAccount")}
              </Button>
            </Grid>
          </Grid>
          {/* <Divider /> */}
        </form>

        <Grid item container justifyContent="start" fontSize={16}>
          {t("alreadyhaveaccount")}
          <SpanLink className={classes.login} pathname={`/login`}>
            {t("login")}
          </SpanLink>
          {/* <Button
            fullWidth
            className={classes.button}
            size="large"
            onClick={() => {
              pushUrl(props, "/login");
            }}
            variant="contained"
            sx={{ backgroundColor: blueGrey[500], color: "#fff" }}
            type="submit"
            startIcon={<LockOutlined />}
          >
            {t("login")}
          </Button> */}
        </Grid>
      </div>
    </StyledContainer>
  );
};
export default Registration;
