import { Chip } from "@mui/material";
import * as colors from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import React from "react";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";

const StyledChip = styled(Chip)(({ theme, colorcode }) => ({
    color: theme.palette.getContrastText(colorcode),
    backgroundColor: colorcode + "!important",
}));

export const TicketStatusColor = (props) => {
    const color = {
        NEW: colors["amber"]["500"],
        INPROGRESS: colors["orange"]["500"],
        CLOSED: colors["red"]["500"],
        RESOLVED: colors["green"]["500"],
        REOPEND: colors["blue"]["500"],
    };

    return (
        <FixedTableCell className={props.className}>
            <StyledChip
                colorcode={color[props.code]}
                size="small"
                label={props.children}
            />
        </FixedTableCell>
    );
};
