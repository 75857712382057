import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { useManifestProductsContext } from "./ManifestProductsProvider";
import { useForm } from "react-hook-form";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import { LIST_WAREHOUSE_DROPDOWN } from "../../../GlobalsQuery/ListDropdown/ListDropdown";

export function WarehouseSectionTableCell(props) {
  const { productDetails } = props;

  const {
    manifestProductsContext: { updateSelectedProductByForm, branchId },
  } = useManifestProductsContext();

  const [autocompleteValues, setAutocompleteValues] = useState({
    warehouseSection: null,
  });
  const { t } = useTranslation(["translation", "validation"]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      sectionId: "",
    },
  });

  const handelAutocompleteDefaultValue = (
    data,
    value,
    fieldName,
    valueKey = "id"
  ) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i[valueKey] === parseInt(value)
    );
    if (defaultValue) {
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));

      onChangeWarehouseSection(defaultValue);
    }
  };

  const updateShipment = (name, value) => {
    /************* Validation Funcs *******************/
    let allField = watch();
    for (const key in allField) {
      if (key.startsWith(name)) {
        delete allField[key];
      }
    }
    /*************************************************/
    updateSelectedProductByForm({ id: productDetails.id, name, value });
  };

  const onChangeWarehouseSection = (e) => {
    updateShipment("section", e);
  };

  return (
    <FixedTableCell>
      <form style={{ width: 180 }} onSubmit={handleSubmit(() => { })}>
        <CustomAutocomplete
          onChangeValue={onChangeWarehouseSection}
          variant="outlined"
          control={control}
          errors={errors}
          name={"sectionId"}
          label={t("wareHouse")}
          query={LIST_WAREHOUSE_DROPDOWN.query}
          parseData={(data) => data}
          variables={{
            input: {
              branchId,
            },
          }}
          onCompleted={(data) =>
            handelAutocompleteDefaultValue(
              data,
              productDetails?.["warehouseSection"]?.id,
              "warehouseSection"
            )
          }
          defaultValue={autocompleteValues.warehouseSection}
        />
      </form>
    </FixedTableCell>
  );
}
