import React from "react";
import { useTranslation } from "react-i18next";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { useCollectionShipmentsContext } from "./CollectionShipmentsProvider";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField";

export function DeliveredAmountTableCell(props) {
  const { shipmentDetails } = props;
  const {
    collectionShipmentsContext: { updateSelectedShipmentByForm },
  } = useCollectionShipmentsContext();
  const { t } = useTranslation(["translation", "validation"]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      amount: shipmentDetails.amount,
    },
  });
  const CanWrite = shipmentDetails.amountEditable;
  const updateShipment = (name, value) => {
    /************* Validation Funcs *******************/
    let allField = watch();
    for (const key in allField) {
      if (key.startsWith(name)) {
        delete allField[key];
      }
    }
    const checkIsEmpty = value !== "";
    /*************************************************/
    updateSelectedShipmentByForm({ id: shipmentDetails.shipment?.id, checkIsEmpty, name, value });
  };

  const onChangeFees = (e) => {
    const value = parseFloat(e.target.value);
    updateShipment("amount", (value || value === 0) ? value : "");
  };

  return (
    <FixedTableCell>
      <form onSubmit={handleSubmit(() => { })}>
        <ControlMUItextField
          control={control}
          errors={errors}
          readOnly={!CanWrite}
          name={"amount"}
          variant="outlined"
          type="number"
          label={t("deliveredAmount")}
          onChange={onChangeFees}
          rules={{
            required: t("fieldIsRequired"),
          }}
        />
      </form>
    </FixedTableCell>
  );
}
export function DeliveredCommissionTableCell(props) {
  const { shipmentDetails } = props;
  const {
    collectionShipmentsContext: { updateSelectedShipmentByForm },
  } = useCollectionShipmentsContext();
  const { t } = useTranslation(["translation", "validation"]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      commission: shipmentDetails.commission,
    },
  });
  const CanWrite = shipmentDetails.commissionEditable;
  const updateShipment = (name, value) => {
    /************* Validation Funcs *******************/
    let allField = watch();
    for (const key in allField) {
      if (key.startsWith(name)) {
        delete allField[key];
      }
    }
    const checkIsEmpty = value !== "";
    /*************************************************/
    updateSelectedShipmentByForm({ id: shipmentDetails.shipment?.id, checkIsEmpty, name, value });
  };
  const onChangeFees = (e) => {
    const value = parseFloat(e.target.value);
    updateShipment("commission", (value || value === 0) ? value : "");
  };
  return (
    <FixedTableCell>
      <form onSubmit={handleSubmit(() => { })}>
        <ControlMUItextField
          control={control}
          errors={errors}
          readOnly={!CanWrite}
          name={"commission"}
          variant="outlined"
          type="number"
          label={t("commission")}
          onChange={onChangeFees}
          rules={{
            required: t("fieldIsRequired"),
          }}
        />
      </form>
    </FixedTableCell>
  );
}
