import React from 'react';
import CustomTab from '../../HOC/FunctionComponents/CustomTab';
import { useTranslation } from 'react-i18next';
import { Chip, Paper, Typography } from '@mui/material';
import { classesCollectionForm, classesCollectionView } from '../CollectionStyle';
import { ConciergeRequestTableForm, ShipmentTableForm } from './FormTables';
import clsx from 'clsx';

function FormTabs(props) {
    const {
        watch,
        disableForm,
        collectionType,
        collectionDetails,
        collectionData,
        clearanceCollection,
        ALLOWED_CONCIERGE,
        ALLOWED_SHIPMENT,

        conciergeRequestsData,
        setConciergeRequestsData,
        loadingConciergeData,
        refetchConciergeDataQuery,
        getConciergeData,

        shipmentData,
        setShipmentData,
        loadingShipmentData,
        refetchShipmentDataQuery,

        collectionConciergeRequests,
        conciergeRequestsDetails,
    } = props
    const { t } = useTranslation()

    const tapsArray = [];

    if (ALLOWED_SHIPMENT) {
        tapsArray.push(
            {
                tabHead:
                    <Typography
                        className={classesCollectionView.title}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {t("shipments")}
                        <Chip color="primary" size="small" label={shipmentData.total} sx={{ mx: 1 }} />
                    </Typography>,
                panel:
                    <Paper
                        className={clsx(classesCollectionForm.table, classesCollectionForm.paper)}
                    >
                        <ShipmentTableForm
                            watch={watch}
                            disableForm={disableForm}
                            collectionType={collectionType}
                            collectionDetails={collectionDetails}
                            clearanceCollection={clearanceCollection}
                            collectionData={collectionData}

                            shipmentData={shipmentData}
                            setShipmentData={setShipmentData}
                            loadingShipmentData={loadingShipmentData}
                            refetchShipmentDataQuery={refetchShipmentDataQuery}
                        />
                    </Paper>
                ,
            },
        )
    }

    if (collectionType === "DLVBY" && ALLOWED_CONCIERGE) {
        tapsArray.push(
            {
                tabHead:
                    <Typography
                        className={classesCollectionView.title}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {t("conciergeRequests")}
                        <Chip color="primary" size="small" label={conciergeRequestsData.total} sx={{ mx: 1 }} />
                    </Typography>,
                panel:
                    <Paper
                        className={clsx(classesCollectionForm.table, classesCollectionForm.paper)}
                    >
                        <ConciergeRequestTableForm
                            watch={watch}
                            collectionConciergeRequests={collectionConciergeRequests}
                            disableForm={disableForm}
                            collectionType={collectionType}

                            conciergeRequestsData={conciergeRequestsData}
                            setConciergeRequestsData={setConciergeRequestsData}
                            loadingConciergeData={loadingConciergeData}
                            refetchConciergeDataQuery={refetchConciergeDataQuery}
                            getConciergeData={getConciergeData}

                            collectionData={collectionData}
                            conciergeRequestsDetails={conciergeRequestsDetails}
                        />
                    </Paper>
            },
        )
    }

    return (
        <CustomTab tapDetails={tapsArray} />
    )
}

export default FormTabs