import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import { Paper, Collapse } from "@mui/material";
import { ROLE_ID, SAVE_ROLE } from "./Graphql";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import PermissionSection from "./PermissionSection";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";

const PREFIX = 'RoleForm';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  }
}));

const RoleForm = (props) => {

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, setValue, setError } = useForm();
  const { errors } = formState;
  const [saveRole, { loading: saveRoleLoading }] = useMutation(
    gql`
      ${SAVE_ROLE.query}
    `
  );
  const roleId = parseInt(props.match.params.id);
  const roleCopyId = parseInt(props?.match?.params?.copyId);

  // update
  const [allPermissions, setAllPermissions] = useState();
  const { loading, data: updateRole } = useQuery(
    gql`
      ${ROLE_ID.query}
    `,

    {
      ...(!roleId && !roleCopyId && { skip: true }),
      variables: { id: roleId | roleCopyId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const roleData = data.role;

        if (roleId) {
          setValue("id", roleData.id);
          setValue("code", roleData.code);
        }
        const nameValue = roleCopyId
          ? t("copy") + " " + roleData.name
          : roleData.name;
        setValue("name", nameValue);
      },
    }
  );

  const permission = updateRole?.role?.permissions;
  const id = updateRole?.role?.id;

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }

    saveRole({
      variables: {
        input: {
          ...data,
          permissions: allPermissions,
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/roles/${data?.data?.saveRole?.id}`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
      });
  };

  const permissionIds = (listPermissions) => {
    setAllPermissions(listPermissions);
  };
  // console.log("permissionsArray",allPermissions);
  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loading ? (
        <FullScreenLoading minHeight={30} />
      ) : (
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          className={clsx(classes.mainGrid)}
          spacing={2}
        >
          <Paper container component={Grid} className={clsx(classes.spacing)}>
            {/* <Grid container justifyContent="space-between" xs={12}>
              <Typography variant="h6">{t("role")}</Typography>
            </Grid> */}
            <Grid xs={12} sm={6} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"code"}
                label={t("code")}
              />
            </Grid>
            <Grid xs={12} sm={6} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"name"}
                label={t("name")}
                rules={{ required: t("fieldIsRequired") }}
              />
            </Grid>
          </Paper>
          <Grid container justifyContent="center" className={classes.spacing}>
            <PermissionSection
              permission={permission}
              id={id}
              permissionIds={permissionIds}
            />
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.spacing}>
            <FormButton disabled={saveRoleLoading}>
              {saveRoleLoading ? <ButtonLoading /> : t("save")}
            </FormButton>
          </Grid>
        </Grid>
      )}
    </form>
  );
  return <Root>
    {loading?<FullScreenLoading minHeight="25%" />:!updateRole&&roleId?<NotFound/>:(<>
    <TitleAppBar path={props.match.path} />
    {body}</>)}
  </Root>;
};

export default RoleForm;
