/* eslint-disable no-useless-concat */
import React, { Fragment, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Paper,
  Toolbar,
  Icon,
  Table,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Edit } from "@mui/icons-material";
import { BRANCH } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import Grid from "@mui/material/Unstable_Grid2";
import CheckCrossIcons from "../HOC/FunctionComponents/CheckCrossIcons";
import styled from "@emotion/styled";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import PhoneNumberActions from "../HOC/CustomComponents/PhoneNumberActions";
import SpanLink from "../HOC/CustomComponents/SpanLink";

const PREFIX = "BranchView";

const classes = {
  loading: `${PREFIX}-loading`,
  paper: `${PREFIX}-paper`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  iconButton: `${PREFIX}-iconButton`,
  field: `${PREFIX}-field`,
  iconColor: `${PREFIX}-iconColor`,
  toolbarTable: `${PREFIX}-toolbarTable`,
  table: `${PREFIX}-table`,
  title: `${PREFIX}-title`,
  main: `${PREFIX}-main`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.toolbarTable}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.table}`]: {
    display: "grid",
    minWidth: "100%",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const BranchView = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const branchId = props.id ?? props.match?.params?.id?.trim();
  const { t } = useTranslation();
  const { loading, data } = useQuery(
    gql`
      ${BRANCH.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(branchId) },
    }
  );

  const warehouseSections = data?.branch?.warehouseSections ?? [];

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/branches/${branchId}/edit`),
      icon: Edit,
      permission: "core.branch.update",
    },
  ]

  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading >
    : !data ? <NotFound /> : (
      <StyledGrid
        container
        justifyContent="center"
        sx={{ margin: "0", width: "100%" }}
      >
        <Fragment>
          <TitleAppBar path={props.match.path} >
            <LongMenu icons={icons} />
          </TitleAppBar>
        </Fragment>
        <Grid
          container
          justifyContent="center"
          sx={{ width: "100%", p: 2, gap: 2 }}
        >
          <Fragment>
            <Paper container xs={12} className={classes.paper} component={Grid}>
              <KeyValuePair title={t("code")} value={data?.branch.code} />
              <KeyValuePair title={t("name")} value={data?.branch.name} />
              <KeyValuePair
                title={t("active")}
                value={
                  data?.branch?.active ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
              <KeyValuePair
                title={t("main")}
                value={
                  data?.branch?.main ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
            </Paper>

            <Paper container xs={12} className={classes.paper} component={Grid}>
              <KeyValuePair
                title={t("country")}
                value={data?.branch.country?.name}
              />
              <KeyValuePair
                title={t("state")}
                value={data?.branch.state?.name}
              />
              <KeyValuePair title={t("city")} value={data?.branch.city?.name} />

              <KeyValuePair phoneStyle={true} title={t("phone")} valueStyle={{ "inlineSize": "max-content" }} value={<PhoneNumberActions phone={data?.branch.phone} />} />
              <KeyValuePair title={t("fax")} value={data?.branch.fax} />

              <KeyValuePair title={t("address")} value={data?.branch.address} />
              <KeyValuePair
                title={t("listPriceList")}
                value={
                  <SpanLink
                    pathname={`/admin/price-list/${data?.branch?.priceList?.id}`}
                  >
                    {data?.branch?.priceList?.name}
                  </SpanLink>}
              />
            </Paper>
            <Paper
              container
              sm={12}
              md={8}
              className={classes.table}
              component={Grid}
            >
              <Toolbar className={classes.toolbarTable} variant="dense">
                <Typography
                  className={classes.title}
                  color="inherit"
                  variant="h6"
                >
                  {t("WareHouseSections")}
                </Typography>
              </Toolbar>
              <TableFixedHeaderWraper component={Paper}>
                {loading || data?.branch.warehouseSections.length <= 0 ? (
                  <EmptyTableMessage
                    loading={loading}
                    message={t("noResult")}
                  />
                ) : (
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <FixedTableCell>{t("name")}</FixedTableCell>
                        <FixedTableCell>{t("active")}</FixedTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {warehouseSections &&
                        (rowsPerPage > 0
                          ? warehouseSections.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          : warehouseSections
                        )?.map((row, index) => (
                          <TableRow hover key={index}>
                            <CellLink pathname={`/admin/warehouse/${row?.id}`}>
                              {row?.name}
                            </CellLink>
                            <FixedTableCell>
                              <CheckCrossIcons active={row?.active} />
                            </FixedTableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
              </TableFixedHeaderWraper>
              <MUITablePagination
                count={warehouseSections.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </Paper>
          </Fragment>
        </Grid>
      </StyledGrid>
    );
};

export default BranchView;
