import * as gqlb from "gql-query-builder";

export const SAVE_FINANCIAL_SHEET = gqlb.mutation({
  operation: "saveFinancialSheet",
  fields: ["id", "code", "name"],
  variables: {
    input: {
      type: "FinancialSheetInput",
      required: true,
    },
  },
});

export const SAVE_FINANCIAL_SHEET_LINE = gqlb.mutation({
  operation: "saveFinancialSheetLine",
  fields: ["id", "number"],
  variables: {
    input: {
      type: "FinancialSheetLineInput",
      required: true,
    },
  },
});

export const FINANCIAL_SHEET = gqlb.query({
  operation: "financialSheet",
  fields: [
    "id",
    "name",
    "date",
    "code",
    "notes",
    "active",
    {
      branch: ["id", "code", "name"],
    },
    {
      lines: [
        "id",
        "description",
        "number",
        {
          display: ["id", "code", "name"],
        },
        {
          glAccount: ["id", "code", "name"],
        },
        {
          type: ["id", "code", "name"],
        },
        {
          details: [
            "id",
            "number",
            {
              glAccount: ["id", "code", "name"],
            },
          ],
        },
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const LIST_FINANCIAL_SHEETS_PAGED = gqlb.query({
  operation: "listFinancialSheets",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "name",
        "date",
        "code",
        "notes",
        "active",
        {
          branch: ["id", "code", "name"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListFinancialSheetsFilterInput",
    },
  },
});
export const SAVE_FINANCIAL_SHEET_DETAIL = gqlb.mutation({
  operation: "saveFinancialSheetLineDetail",
  fields: ["id", "number"],
  variables: {
    input: {
      type: "FinancialSheetLineDetailInput",
      required: true,
    },
  },
});
export const DELETE_FINANCIAL_SHEET_LINE = gqlb.mutation({
  operation: "deleteFinancialSheetLine",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DELETE_FINANCIAL_SHEET_DETAIL = gqlb.mutation({
  operation: "deleteFinancialSheetLineDetail",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
