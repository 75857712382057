
import * as gqlb from "gql-query-builder";
export const commonShipmentFields = (permission) => {
    return [
        "id",
        "code",
        "customerDue",
        "date",
        "createdAt",
        "description",
        "weight",
        "piecesCount",
        "price",
        "notes",
        {
            transactionType: [
                "id",
                "code",
                "name",
            ],
        },
        `adminNotes @include(if: ${permission.adminNotesPermission})`,

        "recipientName",
        "recipientAddress",
        "recipientPhone",
        "recipientMobile",
        "senderName",
        "senderPhone",
        "senderMobile",
        "senderAddress",
        "deliveredAmount",
        "collected",
        "inWarehouse",
        "cancelled",
        "cancellable",
        "unpacked",
        "paidToCustomer",
        "paidToDeliveryAgent",

        "returnedValue",
        "amount",
        "allDueFees",
        "returningDueFees",
        "deliveryFees",
        "extraWeightFees",
        "collectionFees",
        "collectedFees",
        "totalAmount",
        "refNumber",
        "tax",

        "postFees",
        "returnFees",
        "returnPiecesCount",
        "recipientPostalCode",
        "senderPostalCode",
        "deliveryDate",

        {
            images: ["path", "subjectCode"]
        },
        {
            size: ["length", "width", "height"],
        },

        {
            service: ["id", "name"],
        },
        {
            recipientZone: ["id", "name"],
        },
        {
            recipientSubzone: ["id", "name"],
        },
        {
            senderZone: ["id", "name"],
        },
        {
            deliveryType: ["code", "name"],
        },
        {
            senderSubzone: ["id", "name"],
        },
        {
            paymentType: ["code", "name"],
        },
        {
            priceType: ["code", "name"],
        },
        {
            type: ["code", "name"],
        },
        {
            openable: ["code", "name"],
        },
        {
            status: ["code", "name"],
        },
        {
            lastDeliveryAgent: ["id", "name", "phone"],
        },
        {
            branch: ["id", "name"],
        },
        {
            originBranch: ["id", "name"],
        },
        {
            returnStatus: ["code", "name"],
        },
        {
            operation: `warehouseSection @include(if:${permission.warehousePermission})`,
            fields: ["id", "name"],
            variables: {},
        },
    ];

}

export const SHIPMENT_HISTORY = (permission) =>
    gqlb.query([
        {
            operation: "shipment",
            fields: [
                {
                    operation: `history @include(if: ${permission.historyPermission})`,

                    fields: [
                        ...commonShipmentFields(permission),
                        "createdAt",
                        "updatedAt",
                        "deliveryCommission",
                        {
                            createdBy: ["id", "username"],
                        },
                        {
                            updatedBy: ["id", "username"],
                        },
                        {
                            customer: ["id", "name", "code"],
                        },
                        {
                            returnType: ["name", "code"],
                        },
                        {
                            pickup: ["id", "code"],
                        },
                        {
                            cancellationReason: ["name", "id"],
                        },
                    ],
                    variables: {},
                },
            ],
            variables: {
                id: {
                    type: "Int",
                    required: true,
                },
            },
        },
    ]);
export const SHIPMENT_TRANSACTIONS = (permission) =>
    gqlb.query([
        {
            operation: "shipment",
            fields: [
                {
                    transactions: [
                        "id",
                        "date",
                        "deliverdAmount",
                        "fees",
                        {
                            cancellationReason: ["name"],
                        },
                        {
                            branch: ["name"],
                        },
                        {
                            shipmentStatus: ["code", "name"],
                        },
                        {
                            deliveryType: ["name", "code"],
                        },
                        {
                            returnType: ["code", "name"],
                        },
                        {
                            deliveryAgent: ["id", "name"],
                        },
                        {
                            createdBy: ["id", "username"],
                        },
                        {
                            trxType: ["id", "name", { type: ["name"] }],
                        },
                        {
                            manifest: ["id", "code", { transactionType: [{ type: ["code"] }] }],
                        },
                        {
                            manifestEntry: ["notes"],
                        },
                    ],
                },
            ],
            variables: {
                id: {
                    type: "Int",
                    required: true,
                },
            },
        },
    ]);
export const SHIPMENT_DESCRIPTION = (permission) =>
    gqlb.query([
        {
            operation: "shipment",
            fields: [
                ...commonShipmentFields(permission),
                "unpacked",
                "deliveryOtp",
                "deliveryCommission",
                {
                    pickup: ["id", "code"],
                },
                {
                    customer: [
                        "id",
                        "name",
                        "code",
                        "address",
                        "phone",
                        "mobile",
                        {
                            zone: ["id", "name"],
                        },
                        {
                            subzone: ["id", "name"],
                        },
                        {
                            type: ["id", "name", "code"],
                        },
                    ],
                },
                {
                    shipmentProducts: [
                        "price",
                        "quantity",
                        "returned",
                        "delivered",
                        "type",
                        {
                            product: ["id", "name", "availableQuantity", "weight"],
                        },
                    ],
                },
            ],
            variables: {
                id: {
                    type: "Int",
                    required: true,
                },
            },
        },
    ]);
export const SHIPMENT_CAllS = (permission) =>
    gqlb.query([
        {
            operation: "shipment",
            fields: [
                {
                    operation: `deliveryCalls @include(if: ${permission.hasDeliveryCalls})`,
                    fields: [
                        "id",
                        "createdAt",
                        "duration",
                        "phoneNumber",
                        {
                            deliveryAgent: ["id", "name"],
                        },
                        {
                            type: ["name"],
                        },
                    ],
                    variables: {},
                },
            ],
            variables: {
                id: {
                    type: "Int",
                    required: true,
                },
            },
        },
    ]);
export const SHIPMENT_MESSAGES = (permission) =>
    gqlb.query([
        {
            operation: "shipment",
            fields: [
                {
                    messages: [
                        "id",
                        "body",
                        {
                            images: [
                                "path",
                                "subjectCode"
                            ]
                        },
                        "createdAt",
                        "hidden",

                        {
                            user: [
                                "username",
                                "id",
                                {
                                    operation: "account",
                                    fields: [
                                        {
                                            operation: "...on Customer",
                                            fields: ["customerId:id", "name"],
                                            variables: {},
                                        },
                                        {
                                            operation: "...on DeliveryAgent",
                                            fields: ["deliveryAgentId:id", "name"],
                                            variables: {},
                                        },
                                    ],
                                    variables: {},
                                },
                            ],
                        },
                    ],
                },


            ],
            variables: {
                id: {
                    type: "Int",
                    required: true,
                },
            },
        },
    ]);