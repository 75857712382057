import { Icon, IconButton, Toolbar, Typography } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { Can } from "../../HOC/CustomComponents/Secured";
import PrintComponent from "../../Shipments/PrintComponent";
import ExportFinanceShipments from "../ExportFinanceShipments";
import ExportPickupsList from "../ExportPickupsList";
import { AddCircleOutline, ViewColumn } from "@mui/icons-material";
import SelectColumView from "../../shipmentInview/shipmentColumView/SelectFieldColumView";
import { useTranslation } from "react-i18next";
import { shipmentFieldsFun } from "../listPaymentFields";
import { Globals } from "../../HOC/Classes/Globals";

export const EnhancedTableToolbarView = (props) => {
    const { selected, ids, paymentId, type, setLocalDataKey, typeCode, classes, localKeys, total } = props;
    const [shipmentViewColum, setOpenShipmentViewColum] = useState(false);
    const openColumView = () => {
        setOpenShipmentViewColum(true)
    };
    const { t } = useTranslation();
    const adminNotesPermission = Globals.user.hasPermission(
        "shipping.shipment.view_admin_note"
      );
    const data = {
        SHIPMENT:
            <Toolbar className={clsx(classes.root)}>
                <Can showException permission="shipping.shipment.print">
                    <PrintComponent shipmentsId={ids} queryName={"payment"} queryId={paymentId} lastPage={Math.ceil(total / 100)} />
                </Can>
                <ExportFinanceShipments type="payment" id={paymentId}  typeCode={typeCode}/>
                <IconButton
                    aria-label="print"
                    onClick={openColumView}>
                    <ViewColumn fontSize="inherit" />
                </IconButton>
                <SelectColumView
                    openViewColum={shipmentViewColum}
                    setOpenViewColum={setOpenShipmentViewColum}
                    localStorageName={`shipmentPayment${typeCode}`}
                    initialData={localKeys}
                    setLocalDataKey={setLocalDataKey}
                    shipmentFields={shipmentFieldsFun(typeCode,adminNotesPermission)}
                />
            </Toolbar>,
        PICKUP:
            <Toolbar className={clsx(classes.root)}>
                <ExportPickupsList id={paymentId} />
            </Toolbar>,
        CONCIERGE:
            <Toolbar className={clsx(classes.root)}>
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {t("totalRequests") + " " + selected}
                </Typography>
            </Toolbar>,
    }

    return data[type]
};


export const EnhancedTableToolbarForm = (props) => {
    const { addShipment, approved, barcode, classes } = props;

    return (
        <Toolbar className={clsx(classes.root)}>
            <IconButton
                aria-label="Add"
                className={classes.addIcon}
                onClick={addShipment}
                disabled={approved}
                size="large"
            >
                <AddCircleOutline />
            </IconButton>
            {barcode ? (
                <IconButton
                    aria-label="Add"
                    onClick={barcode}
                    disabled={approved}
                    size="large"
                >
                    <Icon>qr_code_scanner</Icon>
                </IconButton>
            ) : null}
        </Toolbar>
    );
};