import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { gql, useMutation } from "@apollo/client";
import { Button, Collapse, DialogActions } from "@mui/material";
import { useSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  DialogContent,
  IconButton,
  InputAdornment,
  Typography,
  Box,
} from "@mui/material";
import {
  CheckCircleOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import moment from "moment";
import { SAVE_SUBMIT_INVOICE_MUTATION } from "./InvoicesListQuary";
import MUItextField from "../HOC/MUI/MUItextField";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";

export default function SubmitInvoice(props) {
  const { invoicesId, etaDocument } = props;
  const dateFormatWithHours = (date) =>
    moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");
  const { enqueueSnackbar } = useSnackbar();
  const parseBool = (bool) => bool === "true";
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { register, setError, handleSubmit, control, formState } = useForm({
    defaultValues: {
      old: parseBool(localStorage.getItem("oldPin")) ?? false,
    },
  });
  const { errors } = formState;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [saveSubmitInvoiceMutation, { loading: saveSubmitInvoiceLoad }] =
    useMutation(
      gql`
        ${SAVE_SUBMIT_INVOICE_MUTATION.query}
      `,
      {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onCompleted: (data) => { },
      }
    );

  const onSubmit = (data) => {
    localStorage.setItem("oldPin", data.old);
    setIsLoading(true);
    const params = {
      invoice: JSON.parse(etaDocument?.document),
      pin: data.pin,
      old: data.old,
    };
    const requestOptions = {
      method: "POST", // specify the HTTP method
      headers: {
        "Content-Type": "application/json", // specify the content type
      },
      body: JSON.stringify(params), // convert params to JSON string and set as request body
      timeout: 10000, // Adjust the timeout value as needed
    };

    fetch("http://localhost:64004", requestOptions)
      .then((response) => {
        // Check if response is successful
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Parse response JSON
        return response.text();
      })
      .then((data) => {
        const responseData = JSON.parse(data);
        const signature = responseData.signature;
        saveSubmitInvoiceMutation({
          variables: {
            input: {
              issuedAt: dateFormatWithHours(etaDocument?.issuedAt),
              signature: signature,
              invoiceId: parseInt(invoicesId),
            },
          },
        })
          .then((data) => {
            enqueueSnackbar(t("saveSuccessful"), {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
            pushUrl(props, `/admin/invoices/${invoicesId}`);
            setIsLoading(false);
            props.handleClose();
          })
          .catch(({ graphQLErrors }) => {
            enqueueSnackbar(graphQLErrors[0].message, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
            setIsLoading(false);
            props.handleClose();
            console.log(graphQLErrors);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        setError("pin", {
          type: "custom",
          message: t("E-SealIsn’tConnectedOrWrongPINEntered"),
        });
        console.error("Fetch error:", error);
      });
  };

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      maxWidth="sm"
      fullWidth={true}
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogTitle color="inherit" id="alert-dialog-title">
        <Grid container justifyContent="space-between" alignItems={"center"}>
          <Typography variant="h6" color={"text.primary"}>
            {t("pin")}
          </Typography>
          <Box>
            <MuiSwitch
              edge="end"
              name="old"
              label={t("oldPin")}
              control={control}
            />
          </Box>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <MUItextField
              margin="dense"
              name={"pin"}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={t("pin")}
              register={register}
              errors={errors}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          {t("cancel")}
        </Button>
        <Button
          startIcon={
            saveSubmitInvoiceLoad || isLoading ? (
              <CustomSpinner size={24} name={"HashLoader"} />
            ) : (
              <CheckCircleOutline />
            )
          }
          disabled={saveSubmitInvoiceLoad || isLoading}
          color="primary"
          autoFocus
          variant="contained"
          type="submit"
        >
          {t("sent")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
