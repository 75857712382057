import * as gqlb from "gql-query-builder";
export const LIST_ROUTES_PAGED = gqlb.query({
  operation: "listRoutes",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "name",
        "code",
        "active",
        {
          driver: ["id", "name"],
        },
        {
          vehicle: [
            {
              series: [
                "id",
                "name",
                "code",
                {
                  model: [
                    "id",
                    "name",
                    "code",
                    {
                      make: ["id", "name"],
                    },
                  ],
                },
              ],
            },
            "plateNumber",
          ],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SAVE_ROUTE = gqlb.mutation({
  operation: "saveRoute",
  fields: ["id", "code"],
  variables: {
    input: {
      type: "RouteInput",
      required: true,
    },
  },
});

export const ROUTE_ID = gqlb.query({
  operation: "route",
  fields: [
    "id",
    "name",
    "active",
    "code",
    {
      driver: ["id", "name"],
    },
    {
      vehicle: [
        {
          series: [
            "id",
            "name",
            "code",
            {
              model: [
                "id",
                "name",
                "code",
                {
                  make: ["id", "name"],
                },
              ],
            },
          ],
        },
        "plateNumber",
      ],
    },
    {
      stops: [
        "order",
        {
          zone: ["id", "name"],
        },
        {
          subzone: ["id", "name"],
        },
      ],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
