import { createContext, useContext } from "react";

export const CollectionShipmentsContext = createContext({});

const CollectionShipmentsProvider = (props) => {
  const { value } = props;
  return (
    <CollectionShipmentsContext.Provider value={value}>
      {props.children}
    </CollectionShipmentsContext.Provider>
  );
};

export default CollectionShipmentsProvider;

export const useCollectionShipmentsContext = () => {
  const collectionShipmentsContext = useContext(CollectionShipmentsContext);

  return {
    collectionShipmentsContext,
  };
};
