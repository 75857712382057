import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import ManifestView from '../Manifests/ManifestView';
import FullScreenLoading from '../HOC/FunctionComponents/LoadingPages/FullScreenLoading';
import NotFound from '../../Error/NotFound';
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { changeKey, defaultQueryFields, initialManifest } from '../Manifests/listManifestFields';
import { MANIFEST_BY_ID_QUERY_BUILDER } from '../Manifests/ManifestGraphQl';
import { Globals } from '../HOC/Classes/Globals';
const PREFIX = "ManifestViewRoute";

const classes = {
    main: `${PREFIX}-main`,
};

const StyledLoading = styled(Grid)(({ theme }) => ({
    [`&.${classes.main}`]: {
        height: "calc(100vh - (40px + 64px))",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        margin: 0,
        [theme.breakpoints.down("sm")]: {
            height: "calc(100dvh - (40px + 56px))",
        },
    },
}));

export default function ViewRouteManifest(props) {
    const manifestId = props.match.params.id
    const user = Globals.user;
    const adminNotesPermission = user.hasPermission(
        "shipping.shipment.view_admin_note"
      );

    const [disableForm, setDisableForm] = useState(false);
    const [disableView, setDisableView] = useState(false);
    const [manifestType, setManifestType] = useState(null);
    const [localKeys, setKeys] = useState(null)

    const { data, loading: manifestByIdLoad } = useQuery(
        gql`
            ${MANIFEST_BY_ID_QUERY_BUILDER.query}
        `,
        {
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            notifyOnNetworkStatusChange: true,
            skip: !manifestId,
            variables: {
                id: parseInt(manifestId),
            },
            onCompleted: (data) => {
                const manifest = data?.manifest;
                setManifestType(manifest?.transactionType?.type.code);
                setDisableForm(true);
            },
            onError: ({ graphQLErrors }) => {
                const auth = graphQLErrors[0].extensions.category
                auth === 'authorization' && setDisableView(true)
            }
        }
    );
    useEffect(() => {
        if (data) {
            setKeys(localStorage.getItem(`shipmentManifest${data?.manifest?.transactionType?.type?.code}`) ? JSON.parse(localStorage.getItem(`shipmentManifest${data?.manifest?.transactionType?.type?.code}`)) : initialManifest[data?.manifest?.transactionType?.type?.code])
        }
        return () => { };
    }, [data]);
    return manifestByIdLoad ?
        <StyledLoading container item justifyContent="center" className={classes.main}>
            <FullScreenLoading height={"100%"} />
        </StyledLoading>
        : !data?.manifest ? <NotFound /> :
            localKeys && <ManifestView
                pageProps={props}
                data={data}
                disableForm={disableForm}
                disableView={disableView}
                manifestType={manifestType}
                manifestByIdLoad={manifestByIdLoad}
                manifestId={manifestId}
                setLocalDataKey={setKeys}
                shipmentListDetails={defaultQueryFields(changeKey(localKeys, `shipmentManifest${manifestType}`),adminNotesPermission)}
                localKeys={localKeys}
            />
}
