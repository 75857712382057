import * as gqlb from "gql-query-builder";

// export const receiveInWarehouseMutationBuilder = (ids) => {
//   const queryArray = [];
//   for (const id of ids) {
//     const initialQuery = {
//       operation: `shipment_${id}:receiveShipmentInWarehouse`,
//       fields: ["id", "code"],
//       variables: {
//         [`shipment_${id}`]: {
//           name: "id",
//           type: "Int",
//           required: true,
//           value: id,
//         },
//         sectionId: {
//           type: "Int",
//         },
//       },
//     };
//     queryArray.push(initialQuery);
//   }
//   return gqlb.mutation(queryArray);
// };

export const UNPACKED_SHIPMENT = gqlb.mutation({
  operation: "unpackShipments",
  fields: ["id"],
  variables: {
    input: {
      type: "UnpackShipmentInput",
      required: true,
    },
  },
});
