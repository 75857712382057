/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Icon,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { SAFE_GL_ACCOUNT, SAFE_ID } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import TreasurersTable from "./TreasurersTable";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import CustomTab from "../HOC/FunctionComponents/CustomTab";
import GlAccountTable from "../HOC/CustomComponents/GlAccountTable";

const PREFIX = "SafeView";

const classes = {
  paper: `${PREFIX}-paper`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const SafeView = (props) => {
  const { t } = useTranslation();
  const currency = Globals?.settings?.localCurrency?.name??"";;
  const user = Globals.user;
  const safeId = props.match.params.id?.trim();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [valueTab, setValueTab] = React.useState();

  const { loading, data } = useQuery(
    gql`
      ${SAFE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(safeId) },
    }
  );
  const { loading: glLoading, data: glData } = useQuery(
    gql`
      ${SAFE_GL_ACCOUNT.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(safeId),
        first: rowsPerPage,
        page: page + 1,
      },
    }
  );

  const glAccountData = glData?.safe?.glAccount;
  const branchId = data?.safe?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canEdit = user.hasPermission("cash.safe.update")
  const canShowAccountStatement = user.hasPermission("cash.safe.use_any")
    ? canAccessBranch
    : false;
  const paymentTypes = data?.safe?.paymentTypes
    ?.map((i) => i.name)
    .toString()
    .replace(/,/g, " , ");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setValueTab(1);
  };

  const handleChangeRowsPerPage = (event) => {
    setValueTab(1);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/safes/${safeId}/edit`),
      icon: Edit,
      permission: canEdit,
    },
  ]

  const tapsArray = [
    {
      tabHead: (
        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {t("treasurers")}
        </Typography>
      ),
      panel: <TreasurersTable treasurers={data?.safe?.treasurers} />,
      valueTab: 1,
    },
  ];
  const showAccountStatement = data?.safe?.treasurers.some(treasurer => treasurer?.user?.username === user?.username);
  if (showAccountStatement || canShowAccountStatement) {
    tapsArray.push({
      tabHead: (
        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {t("accountStatement")}
        </Typography>
      ),
      panel: (
        <GlAccountTable page={page}
          currency={currency}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          tableData={glAccountData?.entryRecords?.data}
          count={glAccountData?.entryRecords?.paginatorInfo?.total}
          data={glAccountData}
          loading={glLoading}
        />
      ),
    },)
  }

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classes.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.safe ? (
    <NotFound />
  ) : (
    <StyledGrid
      container
      justifyContent="center"
      className={classes.mainTracking}
      sx={{ margin: "0", width: "100%" }}
    >
      <Fragment>
        <TitleAppBar path={props.match.path}>
          <LongMenu icons={icons} />
        </TitleAppBar>
      </Fragment>

      <Grid
        container
        justifyContent="center"
        sx={{ width: "100%", p: 2, gap: 2 }}
      >
        <Paper container className={classes.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={data?.safe?.code} />
          <KeyValuePair title={t("name")} value={data?.safe?.name} />
          <KeyValuePair
            title={t("active")}
            value={
              data?.safe?.active ? (
                <Icon className={classes.iconColor}>check_circle_outline</Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("branch")}
            value={
              data?.safe?.branch ? (
                <SpanLink
                  pathname={`/admin/branches/${data?.safe?.branch?.id}`}
                >
                  {data?.safe?.branch?.name}
                </SpanLink>
              ) : (
                ""
              )
            }
          />
          <KeyValuePair
            title={t("glAccount")}
            value={data?.safe?.glAccount?.name}
          />
          <KeyValuePair title={t("paymentTypes")} value={paymentTypes} />
        </Paper>
        <CustomTab tapDetails={tapsArray} valueTab={valueTab} />
      </Grid>


      {/* {
        data?.safe?.treasurers && (
          <TreasurersTable treasurers={data.safe?.treasurers} />
        )
      } */}
    </StyledGrid>
  );
};

export default SafeView;
