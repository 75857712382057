import * as gqlb from "gql-query-builder";

export const manifestFields = [
    "id",
    "date",
    "code",
    "approved",
    "sacksCount",
    "notes",
    {
        createdBy: ["id", "username"],
      },
      {
        updatedBy: ["id", "username"],
      },
    { images: ["subjectCode", "path "] },
    {
        sumEntries: [
            "fees",
            "returnedValue",
            "returningDueFees",
            "allDueFees",
            "weight",
            "piecesCount",
            "price",
            "amount",
            "totalAmount",
            "deliveredAmount",
            "shipmentsCount",
            "pickupAmount"
        ]
    },
    {
        referenceBranch: ["id", "name"],
    },
    {
        referenceManifest: ["id", "code"],
    },
    {
        referredManifests: ["id", "code"],
    },
    {
        branch: ["id", "name"],
    },
    {
        transactionType: [
            "id",
            "code",
            "name",
            {
                type: ["code", "name"],
            },
        ],
    },
    {
        deliveryAgent: ["id", "name", "code"],
    },
    {
        route: ["id", "name"],
    },
    {
        customer: [
            "id",
            "name",
            "phone",
            "address",
            "code",
            {
                zone: ["name"],
            },
            {
                subzone: ["name"],
            },
            {
                operation: `isMobileHidden(code: MANIFEST)`,
                fields: [],
                variables: {
                },
            },
        ],
    },
    {
        operation: `entries`,
        fields: [
            {
                paginatorInfo: ["total"]
            },
        ],
        variables: {},
    },
];

export const MANIFEST_BY_ID_QUERY_BUILDER = gqlb.query({
    operation: "manifest",
    fields: manifestFields,
    variables: {
        id: {
            type: "Int",
            required: true,
        },
    },
});
export const MANIFEST_SHIPMENT_DATA_VIEW = (manifestShipmentData) => gqlb.query({
    operation: "manifest",
    fields: [
        "id",
        {
            operation: `entries`,
            fields: [
                {
                    operation: `paginatorInfo`,
                    fields: [
                        "count",
                        "currentPage",
                        "total",
                        "firstItem",
                        "hasMorePages",
                        "lastItem",
                        "lastPage",
                        "perPage"
                    ],
                    variables: {}
                },
                {
                    operation: `data`,
                    fields: [...manifestShipmentData],
                    variables: {}
                }
            ],
            variables: {
                first: {
                    type: "Int",
                },
                page: {
                    type: "Int",
                },
            },
        },
    ],
    variables: {
        id: {
            type: "Int",
            required: true,
        },
    },
});

export const SAVE_MANIFEST_ENTRIES_MUTATION = gqlb.mutation({
    operation: "createManifestEntry",
    fields: [{
        manifest: ["id", "code"]
    }],
    variables: {
        input: {
            type: "ManifestEntryInput",
            required: true,
        },
    },
});

export const DELETE_MANIFEST_ENTRIES_MUTATION = gqlb.mutation({
    operation: "deleteManifestEntries",
    fields: [],
    variables: {
        input: {
            type: "DeleteManifestEntryInput",
            required: true,
        },
    },
});


export const LIST_MANIFESTS_QUERY = (type) => {
    return gqlb.query({
        operation: "listManifests",
        fields: [
            {
                operation: "paginatorInfo",
                fields: [
                    "count",
                    "currentPage",
                    "total",
                    "firstItem",
                    "hasMorePages",
                    "lastItem",
                    "lastPage",
                    "perPage",
                ],
                variables: {},
            },
            {
                operation: "data",
                fields: [
                    {
                        sumEntries: [
                            "fees",
                            "returnedValue",
                            "returningDueFees",
                            "allDueFees",
                            "weight",
                            "piecesCount",
                            "price",
                            "amount",
                            "totalAmount",
                            "deliveredAmount",
                            "shipmentsCount",
                            "pickupAmount"
                        ]
                    },
                    {
                        operation: `entries`,
                        fields: [
                            {
                                paginatorInfo: ["total"]
                            },
                        ],
                        variables: {},
                    },
                    "code",
                    "id",
                    "date",
                    "approved",
                    {
                        branch: ["id", "name"],
                    },
                    {
                        transactionType: ["id", "name"],
                    },
                    ...(["bmt", "bmr", "pkm", "otr"].includes(type) ? [
                        {
                            referenceBranch: ["id", "name"],
                        },
                    ] : []),
                    ...(["bmt", "bmr", "pkm", "otr", "rits", "std", "otd", "htr", "rts", "rcv", "rtrn"].includes(type) ? [
                        {
                            deliveryAgent: ["id", "name"],
                        },
                    ] : []),
                    ...(["pkm", "rtrn", "otr"].includes(type) ? [
                        {
                            customer: ["id", "name"],
                        },
                    ] : []),
                ],
                variables: {},
            },
        ],
        variables: {
            input: {
                type: "ListManifestsFilterInput",
                required: true,
            },
            first: {
                type: "Int",
                required: true,
                value: 15,
            },
            page: {
                type: "Int",
                value: 1,
            },
        },
    })
};


export const SUM_MANIFEST_QUERY = (type) => {
    return gqlb.query({
        operation: "sumManifests",
        fields: ["returnedValue", "shipmentsCount"],
        variables: {
            input: {
                type: "ListManifestsFilterInput",
                required: true,
            },
        },
    })
};

export const SAVE_MANIFEST_MUTATION = gqlb.mutation({
    operation: "saveManifest",
    fields: manifestFields,
    variables: {
        input: {
            type: "ManifestInput",
            required: true,
        },
    },
});
export const manifestEntriesFieldsPrint = [
    "pickupAmount",
    "deliveryDate",
    "fees",
    "pickupAmount",
    "deliveredValue",
    "createdAt",
    "notes",
    {
        cancellationReason: ["id", "name"]
    },
    {
        deliveryType: ["id", "name"]
    },
    {
        returnType: ["id", "name"]
    },
    {
        warehouseSection: ["id", "name"]
    },
    {
        operation: `shipment`,
        fields: [
            "id",
            "code",
            "id",
            "code",
            "recipientName",
            "recipientAddress",
            "recipientMobile",
            "recipientPhone",
            "description",
            "weight",
            "price",
            "piecesCount",
            "totalAmount",
            "senderName",
            "senderAddress",
            "senderMobile",
            "senderPhone",
            "returningDueFees",
            "customerDue",
            "amount",
            "returnedValue",
            "allDueFees",
            "notes",
            "date",
            "deliveredOrReturnedDate",
            "deliveredAmount",
            "attempts",
            "deliveryDate",
            "refNumber",
            {
                transactions: [{ returnType: ["name"] }],
            },
            {
                warehouseSection: ["id", "name"],
            },
            {
                status: ["name"],
            },
            {
                paymentType: ["name", "code"],
            },
            {
                returnType: ["name"],
            },
            {
                type: ["name", "code"],
            },
            {
                openable: ["name", "code"],
            },
            {
                recipientZone: ["id", "name"],
            },
            {
                recipientSubzone: ["name"],
            },
            {
                senderZone: ["id", "name"],
            },
            {
                senderSubzone: ["name"],
            },
            {
                deliveryType: ["name"],
            },
            {
                cancellationReason: ["id", "name"],
            },
            {
                lastTransaction: ["date"]
            },
            {
                customer: [
                    "id",
                    "name",
                    "mobile",
                    { zone: ["id", "name"] },
                    { subzone: ["name"] },
                    {
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },
                ],
            },
        ],
        variables: {}
    }
]