import React, { useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import { Done, DoneAll } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { BARCODEBATCH, SAVE_BARCODE_BATCH } from "./Graphql";
import CustomButton from "../HOC/MUI/CustomButton";

import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import NotFound from "../../Error/NotFound";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { Can } from "../HOC/CustomComponents/Secured";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { selectedIdIsValid } from "../HOC/CustomFunctions/selectedIsValid";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import TitleAppBar from "../../Layout/TitleAppBar";

const PREFIX = "GenrateBarcode";

const classes = {
  button: `${PREFIX}-button`,
  main: `${PREFIX}-main`,
  overlay: `${PREFIX}-overlay`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.main}`]: {
    height: "calc(100vh - (48px + 40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (48px + 40px + 56px))",
    },
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: "#ffffffad",
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },
}));
//////////////////////////////////////////////////

export { };

const GenrateBarcode = (props) => {
  const GenrateBarcodeId = props.match.params.id;
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    transactionType: null,
  });

  const theme = useTheme();

  const [GenrateBarcodeData, setGenrateBarcodeData] = useState({});
  const [receivedInStoreDialog, setReceivedInStoreDialog] = useState(false);
  const [unEditable, setUnEditable] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
  } = useForm();
  const { t } = useTranslation(["translation", "validation"]);

  const { enqueueSnackbar } = useSnackbar();

  const [saveBatchMutation, { loading: saveBatchLoad }] = useMutation(
    gql`
      ${SAVE_BARCODE_BATCH.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setGenrateBarcodeData(data.saveBarcodeBatch);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      },
    }
  );

  const { data: GenrateBarcodeByIdQuery, loading: BatchByIdLoad } = useQuery(
    gql`
      ${BARCODEBATCH.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !GenrateBarcodeId,
      variables: {
        id: parseInt(GenrateBarcodeId),
      },
      onCompleted: (data) => {
        if (data?.barcodeBatch === null) return;
        const barcodeBatch = data?.barcodeBatch;

        setUnEditable(!barcodeBatch.editable);

        setGenrateBarcodeData(barcodeBatch);
        setValue("id", parseInt(barcodeBatch?.id));
        setAutocompleteValues({
          branch: barcodeBatch.branch,
          customer: barcodeBatch?.customer,
          transactionType: barcodeBatch.transactionType,
        });
      },
    }
  );

  const history = useHistory();

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === undefined || data[key] === "" || data[key] === null) {
        delete data[key];
      }
    }
    saveBatchMutation({
      variables: {
        input: {
          ...(data.id && { id: parseInt(data.id) }),
          branchId: data.branchId,
          customerId: data.customerId ?? null,
          transactionTypeId: data.transactionTypeId,
        },
      },
    })
      .then((data) => {
        setValue("id", parseInt(data?.data?.saveBarcodeBatch?.id));
        const url = history.createHref({
          pathname: `/admin/barcode-batches/${data?.data?.saveBarcodeBatch?.id}/edit`,
        });
        windowReplaceUrl(url);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const closeReceivedInStoreDialog = () => {
    setReceivedInStoreDialog(false);
  };
  const openReceivedInStoreDialog = () => {
    setReceivedInStoreDialog(true);
  };

  const updateBatchStatus = (data) => {
    const barcodes = data.barcodes
      .split(/[\n,,]/)
      .filter((i) => i.trim() !== "")
      .map((i) => i.trim());
    for (const key in data) {
      if (data[key] === undefined || data[key] === "" || data[key] === null) {
        delete data[key];
      }
    }
    saveBatchMutation({
      variables: {
        input: {
          ...data,
          ...(data.barcodesCount && {
            barcodesCount: parseInt(data.barcodesCount),
          }),
          // ...(data.barcodesNumber && {
          //   barcodesNumber: parseInt(data.barcodesNumber),
          // }),
          ...(data.barcodes && { barcodes: barcodes }),
        },
      },
    })
      .then((data) => {
        pushUrl(
          props,
          `/admin/barcode-batches/${data.data.saveBarcodeBatch.id}`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const parseData = (data) => {
    return data;
  };

  const onCustomerComplete = (data) => {
    const currentValue = watch("customerId");

    const isValueNotValid = !selectedIdIsValid(data, currentValue);
    if (isValueNotValid && currentValue) {
      setValue("customerId", "");
      setAutocompleteValues((prev) => ({
        ...prev,
        customer: null,
      }));
    }
  };

  let formBody = null;
  const statusButton = () => {
    return (
      GenrateBarcodeData.id && (
        <CustomButton
          className={classes.button}
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => openReceivedInStoreDialog()}
          name="updateStatus"
          customcolor={theme.palette.info.main}
          loading={saveBatchLoad}
          startIcon={!saveBatchLoad && <DoneAll />}
        >
          {t("genrate")}
        </CustomButton>
      )
    );
  };

  // const formCondition = GenrateBarcodeId ? !BatchByIdLoad : true;
  formBody = (
    !BatchByIdLoad ? (
      <Grid
        container
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        spacing={2}
        sx={{ padding: "24px", width: "100%", margin: "0" }}
      >
        <Paper container component={Grid} sx={{ padding: 1, flexGrow: 1 }}>
          <Grid container sm={12} xs={12} justifyContent="space-between"></Grid>
          <Grid container sx={{ position: "relative" }} sm={12} xs={12}>
            <Grid xs={12} sm={6} md={6}>
              <ListBranches
                control={control}
                errors={errors}
                name={"branchId"}
                rules={{ required: t("fieldIsRequired") }}
                defaultValue={autocompleteValues.branch}
                onChangeValue={(e) => {
                  setValue("deliveryAgentId", "");
                }}
                skipDefaultBranch={Boolean(GenrateBarcodeId)}
              />
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                selectFirst={true}
                name={"transactionTypeId"}
                label={t("transactionType")}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                variables={{
                  input: {
                    type: "PKR",
                  },
                }}
                defaultValue={autocompleteValues.transactionType}
              />
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <CustomAutocomplete
                control={control}
                name={"customerId"}
                label={t("customer")}
                // rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_CUSTOMERS_DROPDOWN.query}
                onCompleted={onCustomerComplete}
                disabled={!watch("branchId")}
                skip={!watch("branchId")}
                onChangeValue={(e) => {
                  setGenrateBarcodeData({ pickupProducts: [] });
                  setValue("barcodesCount", "");
                  setValue("barcodes", "");
                }}
                variables={{
                  input: {
                    branchId: {
                      value: watch("branchId"),
                      includeNull: true,
                    },
                    active: true,
                    typeCode: ["MERCHANT", "INDIVIDUAL"],
                  },
                }}
                defaultValue={autocompleteValues.customer}
              />
            </Grid>
          </Grid>

          <Grid sm={12} xs={12} container justifyContent="flex-end">
            <CustomButton
              customcolor={theme.palette.success.main}
              type="submit"
              className={classes.button}
              variant="contained"
              size="medium"
              loading={saveBatchLoad}
              disabled={saveBatchLoad}
              startIcon={!saveBatchLoad && <Done />}
            >
              {!saveBatchLoad && t("save")}
            </CustomButton>
            {statusButton()}
          </Grid>
        </Paper>
      </Grid>
    ) : (
      <Grid container item justifyContent="center" className={classes.main}>
        <FullScreenLoading height={"100%"} />
      </Grid>
    )
  );

  const genrateBarcodesNumber = (
    <Grid
      container
      item
      justifyContent="flex-start"
      alignContent="space-between"
      spacing={2}
    >
      <Grid container sx={{ position: "relative" }} sm={12} xs={12}>
        <Grid xs={12} md={12}>
          <Typography>{t("GenerateAutomatically")}</Typography>
        </Grid>
        <Grid xs={12} md={12}>
          <ControlMUItextField
            control={control}
            errors={errors}
            disabled={Boolean(watch("barcodes"))}
            type={"number"}
            name={"barcodesCount"}
            label={t("number")}
          />
        </Grid>
        <Grid xs={12} md={12}>
          <Divider sx={{ opacity: 0.7 }} />
        </Grid>
        <Grid xs={12} md={12}>
          <Typography>{t("SpecifyManually")}</Typography>
        </Grid>
        <Grid xs={12} md={12}>
          <ControlMUItextField
            control={control}
            errors={errors}
            disabled={Boolean(watch("barcodesCount"))}
            name={"barcodes"}
            label={t("codes")}
            rows={4}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return GenrateBarcodeByIdQuery?.barcodeBatch === null ? (
    <NotFound />
  ) : unEditable ? (
    <Can permission="uneEditable" />
  ) : (
    <Root>
      <TitleAppBar path={props.match.path} />
      {props.children}
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={receivedInStoreDialog}
        onClose={closeReceivedInStoreDialog}
        component={"form"}
        onSubmit={handleSubmit(updateBatchStatus)}
      >
        <DialogContent>{genrateBarcodesNumber}</DialogContent>
        <DialogActions>
          <Grid container item sm={12} justifyContent="flex-end">
            <Button
              className={classes.button}
              color={saveBatchLoad ? "inherit" : "primary"}
              variant="contained"
              type="submit"
              disabled={
                !(Boolean(watch("barcodesCount")) || Boolean(watch("barcodes")))
              }
            >
              {saveBatchLoad ? <ButtonLoading /> : t("save")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      {formBody}
    </Root>
  );
};

export default withRouter(GenrateBarcode);
