import { Checkbox, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import CellLink from "../../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { paymentPickupsCells } from "./PickupListDetails";
const PREFIX = "CustomTableRow";

const classes = {
  pickupTable_checkbox: `${PREFIX}-pickupTable_checkbox`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(TableRow)(({ theme }) => ({
  [`& .${classes.pickupTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    left: 0,
  },
}));

const CustomTableRow = (props) => {
  const { pickup, onChangeCheck, labelId, type } = props;

  const pickupDetails = pickup?.pickup;
  // useEffect(() => {
  //   const initialCheck = selectedObject.some((i) => i?.id === shipment.id);
  //   setCheck(initialCheck);
  //   return () => {};
  // }, [selectedObject]);

  // const handleChecked = (e) => {
  //   const checked = e.target.checked;
  //   setCheck(checked);
  //   setSelectedShipment(checked, shipment);
  // };

  return (
    <Root
      hover
      role="checkbox"
      aria-checked={pickup.select}
      tabIndex={-1}
      selected={pickup.select}
    >
      <FixedTableCell
        className={classes.pickupTable_checkbox}
        padding="checkbox"
      >
        <Checkbox
          onChange={(event) => onChangeCheck(event)}
          checked={pickup.select}
          inputProps={{ "aria-labelledby": labelId }}
        />
      </FixedTableCell>

      <CellLink
        component="th"
        scope="pickup"
        pathname={`/admin/pickups/${pickupDetails.id}`}
      >
        {pickupDetails?.code}
      </CellLink>
      {paymentPickupsCells(pickupDetails, type)}
    </Root>
  );
};

export default CustomTableRow;
