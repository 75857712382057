import * as gqlb from "gql-query-builder";
export const SAVE_INTEGRATION_DELIVERY_AGENT = gqlb.mutation({
  operation: "saveIntegration",
  fields: ["id", "active", { deliveryAgent: ["id", "code", "name"] }],
  variables: {
    input: {
      type: "IntegrationInput",
      required: true,
    },
  },
});
export const DELIVERY_AGENT_ID = gqlb.query({
  operation: "deliveryAgent",
  fields: [
    "id",
    {
      integration: ["id", "token", "integrationUrl", "active", "typeCode","useSenderData","useShipmentCode" ,
        { services: ["remoteId", { localSevice: ["name", "code", "id"] }] },
        { zones: ["remoteId", { localZone: ["name", "code", "id"] }, "parent"] },
        { cancellationReasons: ["remoteId", { localCancellationReason: ["name", "code", "id"] }] },
        { deliveryAgent: ["id", "code", "name"] }

      ]
    }
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});