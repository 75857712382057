import * as gqlb from "gql-query-builder";
export const UPDATE_ORGANIZATION = gqlb.mutation({
  operation: "updateOrganization",
  fields: ["name"],
  variables: {
    input: {
      type: "OrganizationInput",
      required: true,
    },
  },
});

export const ORGANIZATION =
  gqlb.query({
    operation: "organization",
    fields: [
      "name",
      "owner",
      "activity",
      "registrationNumber",
      "taxArea",
      "taxActivityCode",
      "taxClientId",
      "taxClientSecret",
      "taxNumber",
      "phone",
      "email",
      "website",
      "reportHeader",
      "reportFooter",
    ],
    variables: {},
  });