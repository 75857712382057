/* eslint-disable no-useless-concat */
import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Button, Collapse, Icon, Paper } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { gql, useMutation, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import {
  CLONE_COMMISSION_LIST,
  COMMISSION_LIST_ID,
  DELETE_COMMISSION_LIST,
} from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import DestinationsTable from "./DestinationsTable";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { Delete } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";

import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";

const PREFIX = "CommissionListView";

const classes = {
  box: `${PREFIX}-box`,
  main: `${PREFIX}-main`,
  iconColor: `${PREFIX}-iconColor`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const CommissionListView = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const commissionListId = props.match.params.id?.trim();

  const {
    control,
    watch,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm();
  const [openDialog, setOpenDialog] = useState(false);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const handleOpenCopyDialog = () => {
    setOpenCopyDialog(true);
  };

  const handleCloseCopyDialog = () => {
    setOpenCopyDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const { loading, data } = useQuery(
    gql`
      ${COMMISSION_LIST_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(commissionListId) },
      onCompleted: (data) => {
        const nameValue = t("copyFrom") + " " + data?.commissionList.name;
        setValue("name", nameValue);
      },
    }
  );

  const editURL = () => {
    pushUrl(props, `/admin/commission-list/${commissionListId}/edit`);
  };
  const [deleteCommissionListMutation, { loading: deleteLoad }] = useMutation(
    gql`
      ${DELETE_COMMISSION_LIST.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );
  const [copyCommissionListMutation, { loading: copyLoad }] = useMutation(
    gql`
      ${CLONE_COMMISSION_LIST.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const deleteCommissionList = () => {
    deleteCommissionListMutation({
      variables: {
        id: parseInt(commissionListId),
      },
    })
      .then(() => {
        handleCloseCopyDialog();
        pushUrl(props, `/admin/commission-list`);
        enqueueSnackbar(t("manifestDeleted"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch((error) => {
        handleCloseCopyDialog();
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const copyCommissionList = () => {
    copyCommissionListMutation({
      variables: {
        input: {
          id: parseInt(commissionListId),
          name: watch("name"),
        },
      },
    })
      .then((data) => {
        handleCloseCopyDialog();
        pushUrl(
          props,
          `/admin/commission-list/${data?.data?.cloneCommissionList?.id}/edit`
        );
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };
  const icons = [
    {
      id: "edit",
      title: "edit",
      action: editURL,
      icon: Edit,
      permission: "shipping.commission_list.update",
    },
    {
      id: "copy",
      title: "copy",
      action: handleOpenCopyDialog,
      icon: ContentCopyIcon,
      permission: "shipping.commission_list.create",
    },
    {
      id: "delete",
      title: "delete",
      action: handleOpenDialog,
      icon: Delete,
      permission: "shipping.commission_list.delete",
    },
  ];

  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classes.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.commissionList ? (
    <NotFound />
  ) : (
    <StyledGrid
      container
      justifyContent="center"
      className={classes.mainTracking}
      sx={{ margin: "0", width: "100%" }}
    >
      <CustomDialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="xs"
        // title={t("deleteRole")}
        content={t("confirmationStatus")}
        actions={
          <>
            <Button color="primary" onClick={handleCloseDialog}>
              {t("cancel")}
            </Button>
            <Button color="primary" onClick={deleteCommissionList}>
              {deleteLoad ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
      />
      <CustomDialog
        open={openCopyDialog}
        onClose={handleCloseCopyDialog}
        fullWidth
        maxWidth="xs"
        title={t("copyPriceList")}
        content={
          <ControlMUItextField
            control={control}
            errors={errors}
            name="name"
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
            onChange={() => {
              if (watch("name")) {
                clearErrors("name");
              }
            }}
          />
        }
        actions={
          <>
            <Button color="primary" onClick={handleCloseCopyDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                if (watch("name").trim()) {
                  copyCommissionList();
                } else {
                  setError("name", {
                    type: "required",
                    message: t("fieldIsRequired"),
                  });
                }
              }}
            >
              {copyLoad ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
      />
      <Fragment>
        <TitleAppBar path={props.match.path}>
          <LongMenu icons={icons} />
        </TitleAppBar>
      </Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "0", width: "100%", p: 2 }}
      >
        <Paper container className={classes.box} component={Grid}>
          <KeyValuePair title={t("name")} value={data?.commissionList?.name} />
          <KeyValuePair title={t("code")} value={data?.commissionList?.code} />
          <KeyValuePair
            title={t("description")}
            value={data?.commissionList?.description}
          />
          <KeyValuePair title={t("active")}>
            {data?.commissionList?.active ? (
              <Icon className={classes.iconColor}>check_circle_outline</Icon>
            ) : (
              <Icon color="error">highlight_off</Icon>
            )}
          </KeyValuePair>
        </Paper>
      </Grid>

      {data?.commissionList?.destinations && (
        <DestinationsTable destinations={data.commissionList?.destinations} />
      )}
    </StyledGrid>
  );
};

export default CommissionListView;
