import styled from "@emotion/styled";

/* --------------------------------- Style For Print Totals Per Zones  --------------------------------- */
const PREFIXPRINTTOTALPERZONES = "PrintTotalsPerZones";
export const classesPrintTotalsPerZones = {
    headerCells: `${PREFIXPRINTTOTALPERZONES}-headerCells`,
    title: `${PREFIXPRINTTOTALPERZONES}-title`,
    notes: `${PREFIXPRINTTOTALPERZONES}-notes`,
    noteCell: `${PREFIXPRINTTOTALPERZONES}-noteCell`,
    barcodeFont: `${PREFIXPRINTTOTALPERZONES}-barcodeFont`,
    cairoFont: `${PREFIXPRINTTOTALPERZONES}-cairoFont`,
    barcode: `${PREFIXPRINTTOTALPERZONES}-barcode`,
    headerTableCell: `${PREFIXPRINTTOTALPERZONES}-headerTableCell`,
    mainCellBorder: `${PREFIXPRINTTOTALPERZONES}-mainCellBorder`,
    signature: `${PREFIXPRINTTOTALPERZONES}-signature`,
    reportHeader: `${PREFIXPRINTTOTALPERZONES}-reportHeader`,
    hiddenFooter: `${PREFIXPRINTTOTALPERZONES}-hiddenFooter`,
    pageFooter: `${PREFIXPRINTTOTALPERZONES}-pageFooter`,
    logoSection: `${PREFIXPRINTTOTALPERZONES}-logoSection`,
    headerDate: `${PREFIXPRINTTOTALPERZONES}-headerDate`,
    whiteSpace: `${PREFIXPRINTTOTALPERZONES}-whiteSpace`,
    approve: `${PREFIXPRINTTOTALPERZONES}-approve`,
    rowWithoutBorder: `${PREFIXPRINTTOTALPERZONES}-rowWithoutBorder`,
    mainDetailsCell: `${PREFIXPRINTTOTALPERZONES}-mainDetailsCell`,
    tableHeadCell: `${PREFIXPRINTTOTALPERZONES}-tableHeadCell`,
    tableWidth: `${PREFIXPRINTTOTALPERZONES}-tableWidth`,
    shipmentsTable: `${PREFIXPRINTTOTALPERZONES}-shipmentsTable`,
    signatureBlock: `${PREFIXPRINTTOTALPERZONES}-signatureBlock`,
};
export const RootPrintTotalsPerZones = styled("div")(({ theme }) => ({
    // fontFamily: ['"Cairo"', "sans-serif"].join(","),
    [`& .${classesPrintTotalsPerZones.headerCells}`]: {
        paddingBottom: theme.spacing(1),
    },

    [`& .${classesPrintTotalsPerZones.title}`]: {
        color: theme.palette.info.main,
        // whiteS;pace: "break-spaces",
    },

    [`& .${classesPrintTotalsPerZones.notes}`]: {
        padding: theme.spacing(1),
        border: "1px solid " + theme.palette.action.disabled,
        wordBreak: "break-all",
        width: "100%",
        minHeight: 80,
    },

    [`& .${classesPrintTotalsPerZones.noteCell}`]: {
        width: "30%",
        overflow: "hidden",
    },

    [`& .${classesPrintTotalsPerZones.barcodeFont}`]: {
        fontFamily: '"Libre Barcode 39 Text"',
    },

    [`& .${classesPrintTotalsPerZones.cairoFont}`]: {
        // fontFamily: ['"Cairo"', "sans-serif"].join(","),
    },

    [`& .${classesPrintTotalsPerZones.barcode}`]: {
        fontSize: 40,
        textAlign: "center",
    },

    [`& .${classesPrintTotalsPerZones.headerTableCell}`]: {
        border: "none",
        padding: 0,
    },

    [`& .${classesPrintTotalsPerZones.mainCellBorder}`]: {
        border: "none",
        padding: 0,
    },

    [`& .${classesPrintTotalsPerZones.signature}`]: {
        textAlign: "center",
        padding: theme.spacing(2, 1, 1, 1),
        border: "none",
    },

    [`& .${classesPrintTotalsPerZones.reportHeader}`]: {
        whiteSpace: "pre-line",
        padding: theme.spacing(0, 2),
        lineHeight: "120%",
        fontSize: 12,
    },

    [`& .${classesPrintTotalsPerZones.hiddenFooter}`]: {
        visibility: "hidden",
    },

    [`& .${classesPrintTotalsPerZones.pageFooter}`]: {
        //   "&::after": {
        //    " content:  counter(page)  of  counter(pages)"
        //   },
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: "1px solid " + theme.palette.text.secondary,
        display: "flex",
        alignItems: "flex-end",
    },

    [`& .${classesPrintTotalsPerZones.logoSection}`]: {
        display: "flex",
        alignItems: "center",
    },

    [`& .${classesPrintTotalsPerZones.headerDate}`]: {
        textAlign: "end",
    },

    [`& .${classesPrintTotalsPerZones.whiteSpace}`]: {
        "& tr": {
            "& td": {
                whiteSpace: "initial",
            },
        },
    },

    [`& .${classesPrintTotalsPerZones.approve}`]: {
        textAlign: "end",
    },

    [`& .${classesPrintTotalsPerZones.rowWithoutBorder}`]: {
        "& td": {
            border: "none",
            padding: 0,
        },
    },

    [`& .${classesPrintTotalsPerZones.mainDetailsCell}`]: {
        "& td": { border: "none", verticalAlign: "top" },
        "& td:first-of-type": { paddingLeft: 0 },
        "& td:last-of-type": { paddingRight: 0 },

        border: "none",
        padding: theme.spacing(1, 0),
    },

    [`& .${classesPrintTotalsPerZones.tableHeadCell}`]: {
        padding: 0,
    },

    [`& .${classesPrintTotalsPerZones.tableWidth}`]: {
        width: "100vw",
    },

    [`& .${classesPrintTotalsPerZones.shipmentsTable}`]: {
        "& tr": {
            "&:hover": {
                backgroundColor: "#ffffff00 !important",
            },
        },
        "& td , & th": {
            padding: theme.spacing(1),
        },
    },

    [`& .${classesPrintTotalsPerZones.signatureBlock}`]: {
        breakInside: "avoid",
    },
}));
