import React, { useEffect } from "react";
import { Box, Button, Collapse, Grid, Typography } from "@mui/material";
import { SAVE_CANCELLATION_REASONS } from "./Graphql";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { useMutation, gql } from "@apollo/client";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { DialogActions } from "@mui/material";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import MuiSwitch from "../HOC/MUI/MUIswitch";

export default function CancellationReasonForm(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, setValue, setError } = useForm({
    defaultValues: {
      ...(!props.cancellationReasonObject?.id && { active: true })
    },
  });
  const { errors } = formState;

  const [saveCancellationReason, { loading: saveCancellationReasonLoading }] =
    useMutation(
      gql`
        ${SAVE_CANCELLATION_REASONS.query}
      `
    );

  useEffect(() => {
    if (props.cancellationReasonObject?.id) {
      const cancellationReasonParams = ["id", "code", "name", "active"];
      cancellationReasonParams.forEach((i) => {
        props.cancellationReasonObject[i] &&
          setValue(i, props.cancellationReasonObject[i]);
      });
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancellationReasonData = props.cancellationReasonObject;
  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const onSubmit = (data) => {
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    saveCancellationReason({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        props.handleClose();
        props.refetch();
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ControlMUItextField
        control={control}
        errors={errors}
        name={"code"}
        label={t("code")}
        margin="normal"
      />

      <ControlMUItextField
        control={control}
        errors={errors}
        name={"name"}
        label={t("name")}
        rules={{ required: t("fieldIsRequired") }}
        margin="normal"
      />

      <LookupDropdown
        control={control}
        errors={errors}
        name={"type"}
        label={t("type")}
        margin="normal"
        rules={{ required: t("fieldIsRequired") }}
        variables={{
          input: { code: "SHP_REASON_TYPE" },
        }}
        onCompleted={(data) =>
          lookupsComplete(data, cancellationReasonData?.type?.code, "type")
        }
      />
      <DialogActions>
        <Button disabled={saveCancellationReasonLoading} type="submit">
          {saveCancellationReasonLoading ? <ButtonLoading /> : t("save")}
        </Button>
        <Button
          disabled={saveCancellationReasonLoading}
          onClick={props.handleClose}
        >
          {t("cancel")}
        </Button>
      </DialogActions>
    </form>
  );
  const title = (
    <Grid container justifyContent="space-between" alignItems={"center"}>
      <Typography variant="h6" color={"text.primary"}>{
        props.cancellationReasonObject?.id
          ? t("updateCancellationReason")
          : t("createNewCancellationReason")}
      </Typography>
      <Box>
        <MuiSwitch
          edge="end"
          name="active"
          label={t("active")}
          control={control}
        />
      </Box>
    </Grid>
  );

  return (
    <div>
      {/* <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>
          {props.cancellationReasonObject?.id ? t("updateCancellationReason") : t("createNewCancellationReason") }
        </DialogTitle>
        <DialogContent>{body}</DialogContent>
      </Dialog> */}
      <CustomDialog
        open={props.open}
        onClose={props.handleClose}
        title={title}
        content={body}
      />
    </div>
  );
}
