import React, { useEffect, useState } from "react";

import { TableBody, TableHead, TableRow, Button, Icon } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { useForm } from "react-hook-form";
import { FilterList, Add, Search, Print } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import MUIselect from "../HOC/MUI/MUIselect";
import { LIST_COLLECTIONS_QUERY } from "./CollectionListQuary";
import moment from "moment";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import CellLink from "../HOC/CustomComponents/CellLink";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_SAFES_DROPDOWN,
  LIST_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import useWidth from "../../Hooks/useWidth";
import ExportCollectionList from "./ExportCollectionList";
import { classesCollectionList, RootCollectionList } from "./CollectionStyle";
import { GetCollectionPermissionSlug } from "../../helpers/getPermissionSlug";
import formatMoney from "../../helpers/numbersDot";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import { Globals } from "../HOC/Classes/Globals";

//*********Table Function*********
//collection
function EnhancedTableHead(props) {
  const { ALLOWED_CONCIERGE, ALLOWED_SHIPMENT, DLVBY } = props;
  const { t } = useTranslation();

  const headCells = [
    { id: "1", numeric: true, disablePadding: false, label: t("recordCode") },
    { id: "2", numeric: true, disablePadding: false, label: t("theDate") },
    {
      id: "3",
      numeric: true,
      disablePadding: false,
      label: t("shippingAgent"),
    },
    { id: "5", numeric: true, disablePadding: false, label: t("safe") },
    { id: "6", numeric: true, disablePadding: false, label: t("value") },
    { id: "9", numeric: true, disablePadding: false, label: t("approved") },

    { id: "10", numeric: true, disablePadding: false, label: t("glApproved") },
    { id: "13", numeric: true, disablePadding: false, label: t("createdBy") },
    { id: "12", numeric: true, disablePadding: false, label: t("updatedBy") },
  ];
  if (DLVBY) {
    const shipmentsNumber = {
      id: "11",
      numeric: true,
      disablePadding: false,
      label: t("shipments"),
    };
    const conciergeRequestsNumber = {
      id: "14",
      numeric: true,
      disablePadding: false,
      label: t("conciergeRequestsNumber"),
    };
    const commission = {
      id: "7",
      numeric: true,
      disablePadding: false,
      label: t("commission"),
    };
    const NetAmount = {
      id: "8",
      numeric: true,
      disablePadding: false,
      label: t("NetAmount"),
    };
    headCells.splice(5, 0, commission);
    headCells.splice(6, 0, NetAmount);
    if (ALLOWED_SHIPMENT) {
      headCells.splice(4, 0, shipmentsNumber);
    }
    if (ALLOWED_CONCIERGE) {
      headCells.splice(4, 0, conciergeRequestsNumber);
    }
  }
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (props.type === "custm" && headCell["id"] === "3") {
            headCell["label"] = t("customer");
          } else if (props.type === "dlvby" && headCell["id"] === "3") {
            headCell["label"] = t("shippingAgent");
          }
          return (
            <FixedTableCell
              className={classesCollectionList.headerTable}
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </FixedTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const initDate = moment(new Date()).locale("en").format("YYYY-MM-DD");

const CollectionList = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const screenWidth = useWidth();
  const listType = props.match.params.type;
  const history = useHistory();
  const { t } = useTranslation();
  const [refetch, setRefetch] = useState(true);
  const [noData, setNoData] = useState(true);
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const ALLOWED_CONCIERGE =
    Globals.settings.allowedCustomerTypes.includes("CONCIERGE");
  const ALLOWED_SHIPMENT =
    Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") ||
    Globals.settings.allowedCustomerTypes.includes("MERCHANT");
  const DLVBY = listType === "dlvby";

  const validUrlParameters =
    Object.keys(urlQuery).length !== 0 &&
    !Object.keys(urlQuery).includes("fromDate", "toDate");

  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : validUrlParameters
    ? null
    : initDate;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : validUrlParameters
    ? null
    : initDate;
  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);
  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [queryVar, setQueryVar] = useState(null);

  const [state, setState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [autocompleteValues, setAutocompleteValues] = useState({
    deliveryAgent: null,
    customer: null,
    toZone: null,
    toSubzone: null,
    fromZone: null,
    fromSubzone: null,
  });

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const resetDate = () => {
    setDateRange([null, null]);
  };
  const parseData = (data) => {
    return data;
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm();

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const pushUrlSearch = (param) => {
    const params = searchParams(param);

    const url = history.createHref({
      pathname: `/admin/collections/${listType}`,
      search: "?" + params,
    });
    windowUrl(url);
  };

  useEffect(() => {
    const { first, page, ...input } = urlQuery;
    if (input["code"]) input["code"] = input["code"].toString();
    setQueryVar((prev) => ({
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
      ...prev,
      ...input,
      typeCode: listType.toUpperCase(),
    }));
    urlQuery["code"] && setValue("code", urlQuery["code"].toString());
    urlQuery["approved"] !== undefined &&
      setValue("approved", urlQuery["approved"]);
    urlQuery["glApproved"] !== undefined &&
      setValue("glApproved", urlQuery["glApproved"]);
    urlQuery["first"] && setRowsPerPage(parseInt(urlQuery["first"]));
    urlQuery["page"] && setPage(parseInt(urlQuery["page"] - 1));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pushUrlSearch({ ...urlQuery, page: newPage + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    pushUrlSearch({ ...urlQuery, page: 1, first: +event.target.value });
  };
  const onSubmit = (data) => {
    setRefetch((prev) => !prev);
    data["fromDate"] = dateRange[0] && fromDate;
    data["toDate"] = dateRange[1] && toDate;
    filterAnchor === "bottom" &&
      setState((prev) => ({ ...prev, [filterAnchor]: false }));
    for (const key in data) {
      if (data[key] === "" || data[key] === null) {
        delete data[key];
      }
    }
    pushUrlSearch({
      ...data,
      first: rowsPerPage,
      page: 1,
    });
    setQueryVar({
      ...data,
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      typeCode: listType.toUpperCase(),
    });
    setPage(0);
  };

  // List Query
  const listQuery = LIST_COLLECTIONS_QUERY(ALLOWED_CONCIERGE, ALLOWED_SHIPMENT);
  const { data, loading } = useQuery(
    gql`
      ${listQuery.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !queryVar,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...queryVar,
        },
      },
      onCompleted: (data) => {
        setNoData(false);
      },
    }
  );

  const collectionData =
    data?.listCollections?.data !== null ? data?.listCollections?.data : null;
  const sumCollections = data?.sumCollections;

  const emptyListCollections = data?.listCollections?.data?.length === 0;

  const onPrint = () => {
    const params = searchParams(queryVar);
    const domain = `${window.location.origin}`;
    window.open(`${domain}/collections/print?${params}`);
  };

  const addURL = () => {
    pushUrl(props, `/admin/collections/create/${listType}`);
  };

  const [openExport, setOpenExport] = useState(false);
  const openExportDialog = () => {
    setOpenExport(true);
  };

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !state[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: addURL,
      icon: Add,
      permission: GetCollectionPermissionSlug(listType, "create"),
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: !data || Boolean(emptyListCollections),
    },
    {
      id: "print",
      title: "print",
      action: onPrint,
      icon: Print,
      disabled: emptyListCollections,
    },
  ];

  const customerField = (type) => {
    if (type === "custm") {
      return (
        <Grid item sm={12} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"customerId"}
            label={t("customer")}
            query={LIST_CUSTOMERS_DROPDOWN.query}
            skip={listType !== "custm" || !queryVar || noData}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                urlQuery["customerId"],
                "customer"
              )
            }
            parseData={(data) => parseData(data)}
            variables={{
              input: {
                active: true,
                typeCode: ["MERCHANT"],
              },
            }}
            defaultValue={autocompleteValues.customer}
          />
        </Grid>
      );
    } else if (type === "dlvby") {
      return (
        <Grid item sm={12} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"deliveryAgentId"}
            label={t("shippingAgent")}
            skip={listType !== "dlvby" || !queryVar || noData}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                urlQuery["deliveryAgentId"],
                "deliveryAgent"
              )
            }
            parseData={(data) => parseData(data)}
            query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
            variables={{
              input: {
                active: true,
              },
            }}
            defaultValue={autocompleteValues.deliveryAgent}
          />
        </Grid>
      );
    }
  };

  return (
    <RootCollectionList>
      {props.children}
      <ExportCollectionList
        filters={queryVar}
        openExport={openExport}
        setOpenExport={setOpenExport}
      />
      <ListWrapper
        drawerState={state[filterAnchor]}
        icons={icons}
        path={props.match.path}
        type={listType}
        empty={emptyListCollections}
        loading={!queryVar || loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classesCollectionList.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classesCollectionList.filterField}
            >
              <Grid item sm={12} xs={12}>
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  resetDate={resetDate}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12}>
                <CustomAutocomplete
                  name={"branchId"}
                  label={t("currentBranch")}
                  control={control}
                  errors={errors}
                  skip={!queryVar || noData}
                  parseData={(data) => parseData(data)}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <MUIselect
                  control={control}
                  errors={errors}
                  name={"approved"}
                  label={t("status")}
                  data={[
                    { key: t("approved"), value: true },
                    { key: t("notApproved"), value: false },
                  ]}
                  defaultValue={urlQuery?.approved}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <MUIselect
                  control={control}
                  errors={errors}
                  name={"glApproved"}
                  label={t("glApprove")}
                  data={[
                    { key: t("glApproved"), value: true },
                    { key: t("notGlApproved"), value: false },
                  ]}
                  defaultValue={urlQuery?.approved}
                />
              </Grid>
              {customerField(listType)}
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={!queryVar || noData}
                  name={"safeId"}
                  label={t("safe")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["safeId"],
                      "safe"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_SAFES_DROPDOWN.query}
                  defaultValue={autocompleteValues.safe}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={!queryVar || noData}
                  name={"fromZoneId"}
                  label={t("fromZone")}
                  onChangeValue={(e) => {
                    setValue("fromSubzoneId", "");
                    delete urlQuery["fromSubzoneId"];
                    pushUrlSearch({ ...urlQuery });
                  }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["fromZoneId"],
                      "fromZone"
                    )
                  }
                  variables={{ input: { parentId: null } }}
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.fromZone}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"fromSubzoneId"}
                  label={t("fromSubzone")}
                  skip={!watch("fromZoneId")}
                  disabled={!watch("fromZoneId")}
                  variables={{ input: { parentId: watch("fromZoneId") } }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["fromSubzoneId"],
                      "fromSubzone"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.fromSubzone}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"toZoneId"}
                  skip={!queryVar || noData}
                  label={t("toZone")}
                  onChangeValue={(e) => {
                    delete urlQuery["toSubzoneId"];
                    setValue("toSubzoneId", "");
                    pushUrlSearch({ ...urlQuery });
                  }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["toZoneId"],
                      "toZone"
                    )
                  }
                  variables={{ input: { parentId: null } }}
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.toZone}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"toSubzoneId"}
                  label={t("toSubzone")}
                  skip={!watch("toZoneId")}
                  disabled={!watch("toZoneId")}
                  variables={{ input: { parentId: watch("toZoneId") } }}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["toSubzoneId"],
                      "toSubzone"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_ZONES_DROPDOWN.query}
                  defaultValue={autocompleteValues.toSubzone}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classesCollectionList.button}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          <EnhancedTableHead
            ALLOWED_CONCIERGE={ALLOWED_CONCIERGE}
            ALLOWED_SHIPMENT={ALLOWED_SHIPMENT}
            DLVBY={DLVBY}
          />
        }
        tableBody={
          <TableBody>
            {collectionData &&
              collectionData?.map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  <CellLink
                    pathname={`/admin/collections/${row?.id}`}
                    component="th"
                    scope="row"
                  >
                    {row.code}
                  </CellLink>
                  <FixedTableCell>
                    {moment(row?.date).format("DD-MM-YYYY")}
                  </FixedTableCell>
                  {row?.deliveryAgent || row?.customer ? (
                    <CellLink
                      pathname={
                        DLVBY
                          ? `/admin/delivery-agents/${row?.deliveryAgent?.id}`
                          : `/admin/customers/${row?.customer?.id}`
                      }
                    >
                      {DLVBY ? row?.deliveryAgent?.name : row?.customer?.name}
                    </CellLink>
                  ) : (
                    <FixedTableCell> </FixedTableCell>
                  )}
                  {row?.safe ? (
                    <CellLink pathname={`/admin/safes/${row?.safe?.id}`}>
                      {row?.safe?.name}
                    </CellLink>
                  ) : (
                    <FixedTableCell> </FixedTableCell>
                  )}
                  {DLVBY && (
                    <>
                      {ALLOWED_CONCIERGE && (
                        <FixedTableCell>
                          {row?.entriesConcierge?.paginatorInfo?.total}
                        </FixedTableCell>
                      )}
                      {ALLOWED_SHIPMENT && (
                        <FixedTableCell>
                          {row?.entriesShipment?.paginatorInfo?.total}
                        </FixedTableCell>
                      )}
                    </>
                  )}
                  <FixedTableCell>
                    {formatMoney(row?.sumEntries?.collectedAmount)}
                  </FixedTableCell>
                  {DLVBY && (
                    <FixedTableCell>
                      {formatMoney(row?.sumEntries?.commissionAmount)}
                    </FixedTableCell>
                  )}
                  {DLVBY && sumCollections && (
                    <FixedTableCell>
                      {formatMoney(
                        (
                          row?.sumEntries?.collectedAmount -
                          row?.sumEntries?.commissionAmount
                        ).toFixed(2)
                      )}
                    </FixedTableCell>
                  )}
                  <FixedTableCell>
                    {row?.approved ? (
                      <Icon className={classesCollectionList.iconColor}>
                        check_circle_outline
                      </Icon>
                    ) : (
                      <Icon color="error">highlight_off</Icon>
                    )}
                  </FixedTableCell>
                  <FixedTableCell>
                    {row?.glApproved ? (
                      <Icon className={classesCollectionList.iconColor}>
                        check_circle_outline
                      </Icon>
                    ) : (
                      <Icon color="error">highlight_off</Icon>
                    )}
                  </FixedTableCell>
                  <CellLink
                    align="left"
                    pathname={`/admin/users/${row?.createdBy?.id}`}
                  >
                    {row?.createdBy?.username}
                  </CellLink>
                  <CellLink
                    align="left"
                    pathname={`/admin/users/${row?.updatedBy?.id}`}
                  >
                    {row?.updatedBy?.username}
                  </CellLink>
                </TableRow>
              ))}
            <TableRow>
              <FixedTableCell colSpan={3} />
              <FixedTableCell variant="head">{t("total")}</FixedTableCell>
              {DLVBY && ALLOWED_CONCIERGE && (
                <FixedTableCell variant="head">
                  {sumCollections?.conciergeRequestCount}
                </FixedTableCell>
              )}
              {DLVBY && ALLOWED_SHIPMENT && (
                <FixedTableCell variant="head">
                  {sumCollections?.shipmentCount}
                </FixedTableCell>
              )}
              <FixedTableCell variant="head">
                {formatMoney(sumCollections?.collectedAmount)}
              </FixedTableCell>
              {DLVBY && (
                <FixedTableCell variant="head">
                  {formatMoney(sumCollections?.commissionAmount)}
                </FixedTableCell>
              )}
              {DLVBY && sumCollections && (
                <FixedTableCell variant="head">
                  {formatMoney(
                    (
                      sumCollections?.collectedAmount -
                      sumCollections?.commissionAmount
                    ).toFixed(2)
                  )}
                </FixedTableCell>
              )}
              <FixedTableCell colSpan={4}> </FixedTableCell>
            </TableRow>
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listCollections?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listCollections ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </RootCollectionList>
  );
};

export default CollectionList;
