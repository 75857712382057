import styled from "@emotion/styled";
import Grid from "@mui/material/Unstable_Grid2";

/* --------------------------------- Style For Collection Form --------------------------------- */
const PREFIXCOLLECTIONFORM = "CollectionForm";
export const classesCollectionForm = {
  track: `${PREFIXCOLLECTIONFORM}-track`,
  typo: `${PREFIXCOLLECTIONFORM}-typo`,
  mainTracking: `${PREFIXCOLLECTIONFORM}-mainTracking`,
  paper: `${PREFIXCOLLECTIONFORM}-paper`,
  appBar: `${PREFIXCOLLECTIONFORM}-appBar`,
  title: `${PREFIXCOLLECTIONFORM}-title`,
  textAlign: `${PREFIXCOLLECTIONFORM}-textAlign`,
  divider: `${PREFIXCOLLECTIONFORM}-divider`,
  table: `${PREFIXCOLLECTIONFORM}-table`,
  filterAnchor: `${PREFIXCOLLECTIONFORM}-filterAnchor`,
  shipmentList: `${PREFIXCOLLECTIONFORM}-shipmentList`,

  button: `${PREFIXCOLLECTIONFORM}-button`,
  glApprovedButton: `${PREFIXCOLLECTIONFORM}-glApprovedButton`,
  toolbar: `${PREFIXCOLLECTIONFORM}-toolbar`,
  selectPadding: `${PREFIXCOLLECTIONFORM}-selectPadding`,
  margin: `${PREFIXCOLLECTIONFORM}-margin`,
  titles: `${PREFIXCOLLECTIONFORM}-titles`,
  root: `${PREFIXCOLLECTIONFORM}-root`,
  addIcon: `${PREFIXCOLLECTIONFORM}-addIcon`,
  errorColor: `${PREFIXCOLLECTIONFORM}-errorColor`,
  overlay: `${PREFIXCOLLECTIONFORM}-overlay`,
  pagination: `${PREFIXCOLLECTIONFORM}-pagination`,
  formBody: `${PREFIXCOLLECTIONFORM}-formBody`,
  main: `${PREFIXCOLLECTIONFORM}-main`,
  customHeight: `${PREFIXCOLLECTIONFORM}-customHeight`,
};
// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
export const RootCollectionForm = styled("div")(({ theme }) => ({
  [`& .${classesCollectionForm.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classesCollectionForm.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classesCollectionForm.mainTracking}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classesCollectionForm.paper}`]: {
    marginTop: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classesCollectionForm.appBar}`]: {
    position: "relative",
  },

  [`& .${classesCollectionForm.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classesCollectionForm.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classesCollectionForm.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classesCollectionForm.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classesCollectionForm.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classesCollectionForm.shipmentList}`]: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  [`& .${classesCollectionForm.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classesCollectionForm.glApprovedButton}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },

  [`& .${classesCollectionForm.toolbar}`]: {
    backgroundColor: "#fff",
    border: "solid 1px #eee",
    zIndex: "1300",
    justify: "space-between",
  },

  [`& .${classesCollectionForm.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classesCollectionForm.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classesCollectionForm.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classesCollectionForm.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    justifyContent: "flex-end"
  },

  [`& .${classesCollectionForm.addIcon}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classesCollectionForm.errorColor}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classesCollectionForm.overlay}`]: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.7,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classesCollectionForm.pagination}`]: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  [`& .${classesCollectionForm.formBody}`]: {
    // padding: theme.spacing(1.6),
    // marginTop: theme.spacing(1)
  },
}));
/* --------------------------------- Style For Collection View --------------------------------- */
const PREFIXCOLLECTIONVIEW = "CollectionView";
export const classesCollectionView = {
  mainTracking: `${PREFIXCOLLECTIONVIEW}-mainTracking`,
  paper: `${PREFIXCOLLECTIONVIEW}-paper`,
  viewPaper: `${PREFIXCOLLECTIONVIEW}-viewPaper`,
  title: `${PREFIXCOLLECTIONVIEW}-title`,
  textAlign: `${PREFIXCOLLECTIONVIEW}-textAlign`,
  divider: `${PREFIXCOLLECTIONVIEW}-divider`,
  table: `${PREFIXCOLLECTIONVIEW}-table`,
  filterAnchor: `${PREFIXCOLLECTIONVIEW}-filterAnchor`,
  picker: `${PREFIXCOLLECTIONVIEW}-picker`,
  button: `${PREFIXCOLLECTIONVIEW}-button`,
  toolbar: `${PREFIXCOLLECTIONVIEW}-toolbar`,
  selectPadding: `${PREFIXCOLLECTIONVIEW}-selectPadding`,
  margin: `${PREFIXCOLLECTIONVIEW}-margin`,
  overlay: `${PREFIXCOLLECTIONVIEW}-overlay`,
  field: `${PREFIXCOLLECTIONVIEW}-field`,
  toolbarIcons: `${PREFIXCOLLECTIONVIEW}-toolbarIcons`,
  iconColor: `${PREFIXCOLLECTIONVIEW}-iconColor`,
  main: `${PREFIXCOLLECTIONVIEW}-main`,
  root: `${PREFIXCOLLECTIONVIEW}-root`,
  totalTitle: `${PREFIXCOLLECTIONVIEW}-totalTitle`,
};
// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
export const RootCollectionView = styled("div")(({ theme }) => ({
  [`& .${classesCollectionView.root}`]: {
    justifyContent: "flex-end"
  },
  [`& .${classesCollectionView.mainTracking}`]: {
    // margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classesCollectionView.paper}`]: {
    marginTop: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classesCollectionView.viewPaper}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classesCollectionView.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classesCollectionView.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classesCollectionView.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classesCollectionView.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classesCollectionView.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classesCollectionView.picker}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },

      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classesCollectionView.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classesCollectionView.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classesCollectionView.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classesCollectionView.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classesCollectionView.overlay}`]: {
    backgroundColor: "#ffffffad",
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classesCollectionView.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classesCollectionView.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classesCollectionView.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classesCollectionView.totalTitle}`]: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    // fontSize:
  },
}));
export const RootCollectionLoadingStyle = styled(Grid)(({ theme }) => ({
  [`&.${classesCollectionView.main}`]: {
    height: "calc(100vh - (48px + 40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (48px + 40px + 56px))",
    },
  },
  [`&.${classesCollectionForm.main}`]: {
    height: "calc(100vh - (48px + 40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (48px + 40px + 56px))",
    },
  },
  [`&.${classesCollectionForm.customHeight}`]: {
    height: "200px !important",
  },
}));
/* --------------------------------- Style For Collection List --------------------------------- */
const PREFIXCOLLECTIONLIST = "CollectionList";
export const classesCollectionList = {
  track: `${PREFIXCOLLECTIONLIST}-track`,
  typo: `${PREFIXCOLLECTIONLIST}-typo`,
  mainTracking: `${PREFIXCOLLECTIONLIST}-mainTracking`,
  appBar: `${PREFIXCOLLECTIONLIST}-appBar`,
  title: `${PREFIXCOLLECTIONLIST}-title`,
  textAlign: `${PREFIXCOLLECTIONLIST}-textAlign`,
  divider: `${PREFIXCOLLECTIONLIST}-divider`,
  paper: `${PREFIXCOLLECTIONLIST}-paper`,
  filterAnchor: `${PREFIXCOLLECTIONLIST}-filterAnchor`,
  picker: `${PREFIXCOLLECTIONLIST}-picker`,
  button: `${PREFIXCOLLECTIONLIST}-button`,
  toolbar: `${PREFIXCOLLECTIONLIST}-toolbar`,
  selectPadding: `${PREFIXCOLLECTIONLIST}-selectPadding`,
  margin: `${PREFIXCOLLECTIONLIST}-margin`,
  titles: `${PREFIXCOLLECTIONLIST}-titles`,
  filterForm: `${PREFIXCOLLECTIONLIST}-filterForm`,
  filterField: `${PREFIXCOLLECTIONLIST}-filterField`,
  tableIcon: `${PREFIXCOLLECTIONLIST}-tableIcon`,
  drawer: `${PREFIXCOLLECTIONLIST}-drawer`,
  drawerPaper: `${PREFIXCOLLECTIONLIST}-drawerPaper`,
  drawerHeader: `${PREFIXCOLLECTIONLIST}-drawerHeader`,
  content: `${PREFIXCOLLECTIONLIST}-content`,
  contentShift: `${PREFIXCOLLECTIONLIST}-contentShift`,
  iconColor: `${PREFIXCOLLECTIONLIST}-iconColor`,
  headerTable: `${PREFIXCOLLECTIONLIST}-headerTable`,
};
// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
export const RootCollectionList = styled("div")(({ theme }) => ({
  [`& .${classesCollectionList.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classesCollectionList.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classesCollectionList.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classesCollectionList.mainTracking}`]: {
    margin: theme.spacing(0, 0),
    width: "100%",
  },

  [`& .${classesCollectionList.appBar}`]: {
    position: "relative",
  },

  [`& .${classesCollectionList.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classesCollectionList.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classesCollectionList.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classesCollectionList.paper}`]: {
    display: "grid",
    width: "100%",
    marginBottom: theme.spacing(2),
    borderRadius: 0,
  },

  [`& .${classesCollectionList.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflowX: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        width: "30%",
      },
    },
  },

  [`& .${classesCollectionList.picker}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },

      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classesCollectionList.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    // backgroundColor: "#fff",
    marginTop: 10,
    padding: 7,
  },

  [`& .${classesCollectionList.toolbar}`]: {
    borderBottom: "solid 1px",
    borderBottomColor: theme.palette.divider,
    // zIndex: "1300",
    // justify: "space-between"
  },

  [`& .${classesCollectionList.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classesCollectionList.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classesCollectionList.titles}`]: {
    flex: "1 1 100%",
  },

  [`& .${classesCollectionList.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between"
  },

  [`& .${classesCollectionList.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },

  [`& .${classesCollectionList.tableIcon}`]: {
    margin: theme.spacing(0, 1),
  },

  /////////Drawer///////////

  [`& .${classesCollectionList.drawer}`]: {
    width: drawerWidthCollectionList,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // height: "100%",
    },
  },

  [`& .${classesCollectionList.drawerPaper}`]: {
    width: drawerWidthCollectionList,

    zIndex: "250",

    left: "initial",
    right: "initial",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },

  [`& .${classesCollectionList.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  [`& .${classesCollectionList.content}`]: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classesCollectionList.contentShift}`]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "auto",
    width: "calc(100% - 240px)",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "auto",
    },
  },

  [`& .${classesCollectionList.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));
const drawerWidthCollectionList = 240;
/* --------------------------------- Style For Print Collection List --------------------------------- */
const PREFIXPRINTCOLLECTIONLIST = "CollectionPrint";
export const classesPrintCollectionList = {
  headerCells: `${PREFIXPRINTCOLLECTIONLIST}-headerCells`,
  title: `${PREFIXPRINTCOLLECTIONLIST}-title`,
  notes: `${PREFIXPRINTCOLLECTIONLIST}-notes`,
  noteCell: `${PREFIXPRINTCOLLECTIONLIST}-noteCell`,
  barcodeFont: `${PREFIXPRINTCOLLECTIONLIST}-barcodeFont`,
  cairoFont: `${PREFIXPRINTCOLLECTIONLIST}-cairoFont`,
  barcode: `${PREFIXPRINTCOLLECTIONLIST}-barcode`,
  headerTableCell: `${PREFIXPRINTCOLLECTIONLIST}-headerTableCell`,
  mainCellBorder: `${PREFIXPRINTCOLLECTIONLIST}-mainCellBorder`,
  signature: `${PREFIXPRINTCOLLECTIONLIST}-signature`,
  reportHeader: `${PREFIXPRINTCOLLECTIONLIST}-reportHeader`,
  hiddenFooter: `${PREFIXPRINTCOLLECTIONLIST}-hiddenFooter`,
  pageFooter: `${PREFIXPRINTCOLLECTIONLIST}-pageFooter`,
  logoSection: `${PREFIXPRINTCOLLECTIONLIST}-logoSection`,
  headerDate: `${PREFIXPRINTCOLLECTIONLIST}-headerDate`,
  whiteSpace: `${PREFIXPRINTCOLLECTIONLIST}-whiteSpace`,
  approve: `${PREFIXPRINTCOLLECTIONLIST}-approve`,
  rowWithoutBorder: `${PREFIXPRINTCOLLECTIONLIST}-rowWithoutBorder`,
  mainDetailsCell: `${PREFIXPRINTCOLLECTIONLIST}-mainDetailsCell`,
  tableHeadCell: `${PREFIXPRINTCOLLECTIONLIST}-tableHeadCell`,
  tableWidth: `${PREFIXPRINTCOLLECTIONLIST}-tableWidth`,
  shipmentsTable: `${PREFIXPRINTCOLLECTIONLIST}-shipmentsTable`,
  signatureBlock: `${PREFIXPRINTCOLLECTIONLIST}-signatureBlock`,
  iconColor: `${PREFIXPRINTCOLLECTIONLIST}-iconColor`,
};
export const RootPrintCollectionList = styled("div")(({ theme }) => ({
  // fontFamily: ['"Cairo"', "sans-serif"].join(","),
  [`& .${classesPrintCollectionList.headerCells}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classesPrintCollectionList.title}`]: {
    color: theme.palette.info.main,
    // whiteS;pace: "break-spaces",
  },

  [`& .${classesPrintCollectionList.notes}`]: {
    padding: theme.spacing(1),
    border: "1px solid " + theme.palette.action.disabled,
    wordBreak: "break-all",
    width: "100%",
    minHeight: 80,
  },

  [`& .${classesPrintCollectionList.noteCell}`]: {
    width: "30%",
    overflow: "hidden",
  },

  [`& .${classesPrintCollectionList.barcodeFont}`]: {
    fontFamily: '"Libre Barcode 39 Text"',
  },

  [`& .${classesPrintCollectionList.cairoFont}`]: {
    // fontFamily: ['"Cairo"', "sans-serif"].join(","),
  },

  [`& .${classesPrintCollectionList.barcode}`]: {
    fontSize: 40,
    textAlign: "center",
  },

  [`& .${classesPrintCollectionList.headerTableCell}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classesPrintCollectionList.mainCellBorder}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classesPrintCollectionList.signature}`]: {
    textAlign: "center",
    padding: theme.spacing(2, 1, 1, 1),
    border: "none",
  },

  [`& .${classesPrintCollectionList.reportHeader}`]: {
    whiteSpace: "pre-line",
    padding: theme.spacing(0, 2),
    lineHeight: "120%",
    fontSize: 12,
  },

  [`& .${classesPrintCollectionList.hiddenFooter}`]: {
    visibility: "hidden",
  },

  [`& .${classesPrintCollectionList.pageFooter}`]: {
    //   "&::after": {
    //    " content:  counter(page)  of  counter(pages)"
    //   },
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid " + theme.palette.text.secondary,
    display: "flex",
    alignItems: "flex-end",
  },

  [`& .${classesPrintCollectionList.logoSection}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classesPrintCollectionList.headerDate}`]: {
    textAlign: "end",
  },

  [`& .${classesPrintCollectionList.whiteSpace}`]: {
    "& tr": {
      "& td": {
        whiteSpace: "initial",
      },
    },
  },

  [`& .${classesPrintCollectionList.approve}`]: {
    textAlign: "end",
  },

  [`& .${classesPrintCollectionList.rowWithoutBorder}`]: {
    "& td": {
      border: "none",
      padding: 0,
    },
  },

  [`& .${classesPrintCollectionList.mainDetailsCell}`]: {
    "& td": { border: "none", verticalAlign: "top" },
    "& td:first-of-type": { paddingLeft: 0 },
    "& td:last-of-type": { paddingRight: 0 },

    border: "none",
    padding: theme.spacing(1, 0),
  },

  [`& .${classesPrintCollectionList.tableHeadCell}`]: {
    padding: 0,
  },

  [`& .${classesPrintCollectionList.tableWidth}`]: {
    width: "100vw",
  },

  [`& .${classesPrintCollectionList.shipmentsTable}`]: {
    "& tr": {
      "&:hover": {
        backgroundColor: "#ffffff00 !important",
      },
    },
    "& td , & th": {
      padding: theme.spacing(1),
    },
  },

  [`& .${classesPrintCollectionList.signatureBlock}`]: {
    breakInside: "avoid",
  },
  [`& .${classesPrintCollectionList.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));
