import * as gqlb from "gql-query-builder";

export const SHIPMENT_QUERY = gqlb.query({
  operation: "shipment",
  fields: [
    "id",
    "allDueFees",
    "returnFees",
    "returnPiecesCount",
    "totalAmount",
    "code",
    {
      type: ["name"],
    },
  ],
  variables: {
    id: {
      type: "Int",
    },
  },
});

export const updateShipmentStatusMutationBuilder = (ids) => {
  const queryArray = [];
  for (const id of ids) {
    const initialQuery = {
      operation: `shipment_${id}:updateShipmentStatus`,
      fields: ["id"],
      variables: {
        ["input" + id]: {
          name: "input",
          type: "UpdateShipmentStatusInput",
          required: true,
        },
      },
    };
    queryArray.push(initialQuery);
  }
  return gqlb.mutation(queryArray);
};
