import { Box } from "@mui/material";
import formatMoney from "../../helpers/numbersDot";
import { dateFormatLL } from "../../helpers/dateFunctions";
export const defaultQueryFieldsCollection = (keys, permission) => {
    const defaultKeys = []
    const shipmentFields = []
    const collectionEntriesShipment = (manifestShipmentData) => {
        return {
            operation: `shipment`,
            fields: [
                "id",
                ...manifestShipmentData
            ],
            variables: {}
        }


    };
    const queriesEntries = {
        collectedAmount: {
            queryName: "collectedAmount"
        },
        ...(permission && {
            commission: {
                queryName: "commission"
            }
        }),
        ...(permission && {
            NetAmount: {
                queryName: ["collectedAmount", "commission"]
            }
        }),


    }
    const queriesShipment = {
        code: {
            queryName: "code"
        },
        description: {
            queryName: "description"
        },
        totalAmount: {
            queryName: "totalAmount"
        },
        senderAddress: {
            queryName: "senderAddress"
        },
        date: {
            queryName: "date"
        },
        deliveredOrReturnedDate: {
            queryName: "deliveredOrReturnedDate"
        },
        recipientAddress: {
            queryName: "recipientAddress"
        },
        refNumber: {
            queryName: "refNumber"
        },
        piecesCount: {
            queryName: "piecesCount"
        },
        price: {
            queryName: "price"
        },
        weight: {
            queryName: "weight"
        },
        deliveredAmount: {
            queryName: "deliveredAmount"
        },
        amount: {
            queryName: "amount"
        },
        attempts: {
            queryName: "attempts"
        },
        status: {
            queryName: {
                status: ["name", "code"],
            }
        },
        lastTransactionDate: {
            queryName: {
                lastTransaction: ["date"],
            }
        },
        transactions: {
            queryName: {

                transactions: [{ returnType: ["name"] }],

            }
        },
        recipientZone: {
            queryName: [
                {
                    recipientZone: ["id", "name"],
                },
                {
                    recipientSubzone: ["name"]
                }
            ]
        },
        senderZone: {
            queryName: [
                {
                    senderZone: ["id", "name"],
                },
                {
                    senderSubzone: ["id", "name"],
                }
            ]
        },
        type: {
            queryName: {
                type: ["name"],
            }
        },
        paymentType: {
            queryName: {
                paymentType: ["name"],
            }
        },
        openable: {
            queryName: {
                openable: ["name", "code"],
            }
        },
        returnType: {
            queryName: {
                returnType: ["name"],
            }
        },
        deliveryType: {
            queryName: [{
                deliveryType: ["name"],

            },
            { status: ["name", "code"] }
            ]
        },
        warehouseSection: {
            queryName: {
                warehouseSection: ["name"],
            }
        },
        note: {
            queryName: "notes"
        },
        pendingCollectionAmount: {
            queryName: "pendingCollectionAmount"
        },
        deliveryCommission: {
            queryName: "deliveryCommission"
        },
        senderName: {
            queryName: "senderName"
        },
        recipientName: {
            queryName: "recipientName"
        },

        sender: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "senderName",
                "senderMobile",
                "senderPhone"
            ]
        },
        recipient: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "recipientName",
                "recipientMobile",
                "recipientPhone"
            ]
        },
        recipientPhone: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "recipientMobile",
                "recipientPhone"
            ]
        },
        senderPhone: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "senderMobile",
                "senderPhone"
            ]
        },
        returningDueFees: {
            queryName: "returningDueFees"
        },
        deliveryDate: {
            queryName: "deliveryDate"
        },
        returnedValue: {
            queryName: "returnedValue"
        },
        cancellationReason: {
            queryName: { cancellationReason: ["name"] }
        },
        customer: {
            queryName: { customer: ["id", "name", "code"], }
        },
        barcode: {
            queryName: "code"
        },
        originBranch: {
            queryName: { originBranch: ["id", "name"], }
        },
        currentBranch: {
            queryName: {
                branch: ["id", "name"],
            },
        },
        collectedFees: {
            queryName: "collectedFees"
        },

    }
    keys.map((keyName) => {
        if (queriesEntries?.[keyName]) {
            if (Array.isArray(queriesEntries[keyName].queryName)) {
                queriesEntries[keyName].queryName.map((i) => defaultKeys.push(i))
            } else {
                defaultKeys.push(queriesEntries[keyName].queryName)
            }
        }
        if (queriesShipment?.[keyName]) {
            if (Array.isArray(queriesShipment[keyName].queryName)) {
                queriesShipment[keyName].queryName.map((i) => shipmentFields.push(i))
            }
            else {
                shipmentFields.push(queriesShipment[keyName].queryName)
            }
        }
        return ''
    })
    let uniqueArray = shipmentFields.filter((item, index, self) => {
        if (typeof item === 'object') {
            // For objects, check if it's not a duplicate by comparing JSON strings
            return self.findIndex(other => JSON.stringify(other) === JSON.stringify(item)) === index;
        } else {
            // For non-objects, remove duplicates as before
            return self.indexOf(item) === index;
        }
    });
    defaultKeys.push(collectionEntriesShipment(uniqueArray))
    return defaultKeys
};

export const shipmentFieldsFun = (collectionType, permission) => {
    const shipmentFields = [
        {
            key: "code",
            label: "code",
            type: "link",
            path: "shipments"
        },

        {
            key: "description",
            label: "packageDescription",
            type: ""
        },
        {
            key: "barcode",
            label: "barcode",
            type: "barcode"
        },
        {
            key: "recipientName",
            label: "recipient",
            type: ""
        },
        {
            key: "recipientPhone",
            label: "recipientPhone",
            type: "phone",
        },
        {
            key: "senderName",
            label: "senderName",
            type: ""
        },
        {
            key: "senderPhone",
            label: "senderPhone",
            type: "phone",
        },
        {
            key: "note",
            label: "notes",
            type: "",
        },
        {
            key: "recipientZone",
            label: "destination",
            type: "zone"
        },
        {
            key: "senderZone",
            label: "source",
            type: "zone"
        },
        {
            key: "collectedAmount",
            label: "collectedAmount",
            type: "",
            ifSum: "collectedAmount"
        },

        {
            key: "type",
            label: "packageType",
            type: ""
        },
    ]
    const shipmentCollection = {
        DLVBY: [
            {
                key: "date",
                label: "date",
                type: ""
            },
            {
                key: "originBranch",
                label: "originBranch",
                type: "link",
                path: "shipments"
            },
            {
                key: "currentBranch",
                label: "currentBranch",
                type: "link",
                path: "shipments"
            },
            {
                key: "returningDueFees",
                label: "returningDueFees",
                type: "",
                ifSum: "returningDueFees"
            },
            {
                key: "collectedFees",
                label: "collectedFees",
                type: "",
                ifSum: "collectedFees"
            },
            {
                key: "totalAmount",
                label: "totalCost",
                type: "",
                ifSum: "totalAmount"
            },
            {
                key: "deliveredOrReturnedDate",
                label: "deliveryCanclingDate",
                type: ""
            },
            {
                key: "status",
                label: "shipmentStatus",
                type: ""
            },
            {
                key: "deliveryType",
                label: "deliveryType",
                type: ""
            },
            {
                key: "deliveredAmount",
                label: "deliveredAmount",
                type: "",
                ifSum: "deliveredAmount"
            },


        ].concat(shipmentFields),
        CUSTM: [
            {
                key: "piecesCount",
                label: "pieceCount",
                type: "",
                ifSum: "piecesCount"
            },
            {
                key: "weight",
                label: "weight",
                type: "",
                ifSum: "weight"
            },
            {
                key: "senderAddress",
                label: "senderAddress",
                type: ""
            },
            {
                key: "recipientAddress",
                label: "recipientAddress",
                type: ""
            },

        ].concat(shipmentFields),
    }
    permission && collectionType === "DLVBY" && shipmentCollection[collectionType]?.push({ key: "NetAmount", label: "NetAmount", type: "", ifSum: "NetAmount" })
    permission && collectionType === "DLVBY" && shipmentCollection[collectionType]?.push({ key: "commission", label: "commission", type: "", ifSum: "commission" })
    return shipmentCollection[collectionType]
}
export const getListShipmentTable = (collectionShipment, key) => {
    if (key === undefined) {
        return { value: "noVal" }
    }
    const ListShipmentDetails = {
        customer: {
            pathname: `/admin/customers/${collectionShipment?.shipment?.customer?.id}`,
            value: <>
                <Box component="span" fontWeight="bold">{` (${collectionShipment?.shipment?.customer?.code}) `}</Box>
                <Box component="span">  {collectionShipment?.shipment?.customer?.name}</Box>
            </>
        },
        code: {
            pathname: `/admin/shipments/${collectionShipment?.shipment?.id}`,
            value: collectionShipment?.shipment?.code,
            ifCode: "code"
        },
        date: {
            value: dateFormatLL(collectionShipment?.shipment?.date)
        },
        entriesDeliveryDate: {
            value: dateFormatLL(collectionShipment?.deliveryDate)
        },
        deliveryDate: {
            value: dateFormatLL(collectionShipment?.shipment?.deliveryDate)
        },
        deliveredOrReturnedDate: {
            value: dateFormatLL(collectionShipment?.shipment?.deliveredOrReturnedDate)
        },
        description: {
            value: collectionShipment?.shipment?.description,
            condition: "description",
        },
        senderZone: {
            pathname: `/admin/zones/${collectionShipment?.shipment?.senderZone?.id}`,
            zone: collectionShipment?.shipment?.senderZone?.name,
            subzone: collectionShipment?.shipment?.senderSubzone?.name
        },
        recipientZone: {
            pathname: `/admin/zones/${collectionShipment?.shipment?.recipientZone?.id}`,
            zone: collectionShipment?.shipment?.recipientZone?.name,
            subzone: collectionShipment?.shipment?.recipientSubzone?.name
        },
        price: {
            value: formatMoney(collectionShipment.shipment?.price),
        },
        senderAddress: {
            value: collectionShipment.shipment?.senderAddress,
        },
        deliveredAmount: {
            value: formatMoney(collectionShipment.shipment?.deliveredAmount),
        },
        amount: {
            value: formatMoney(collectionShipment?.shipment?.amount)
        },
        totalAmount: {
            value: formatMoney(collectionShipment?.shipment?.totalAmount),
        },
        returningDueFees: {
            value: formatMoney(collectionShipment?.shipment?.returningDueFees)
        },
        entriesPickupAmount: {
            value: formatMoney(collectionShipment?.pickupAmount)
        },
        status: {
            value: collectionShipment.shipment?.status?.name,
        },
        attempts: {
            value: collectionShipment.shipment?.attempts,
        },
        lastTransactionDate: {
            value: dateFormatLL(collectionShipment?.shipment?.lastTransaction?.date)
        },
        transactions: {
            value: collectionShipment?.shipment?.transactions?.find((i) => i.returnType !== null)?.returnType?.name,
        },
        piecesCount: {
            value: formatMoney(collectionShipment?.shipment?.piecesCount)
        },
        commission: {
            value: formatMoney(collectionShipment?.commission)
        },
        collectedAmount: {
            value: formatMoney(collectionShipment?.collectedAmount)
        },
        NetAmount: {
            value: formatMoney(collectionShipment?.collectedAmount - collectionShipment?.commission)
        },
        weight: {
            value: collectionShipment?.shipment?.weight
        },
        type: {
            value: collectionShipment?.shipment?.type?.name
        },
        paymentType: {
            value: collectionShipment?.shipment?.paymentType?.name
        },
        openable: {
            value: collectionShipment?.shipment?.openable?.code === "Y" ? true : false
        },
        entriesCancellationReason: {
            value: collectionShipment.cancellationReason?.name
        },
        cancellationReason: {
            value: collectionShipment.shipment?.cancellationReason?.name
        },
        returnType: {
            value: collectionShipment?.shipment?.returnType?.name
        },
        entriesReturnType: {
            value: collectionShipment?.returnType?.name
        },
        deliveryType: {
            value: collectionShipment.shipment?.deliveryType?.name ?? collectionShipment.shipment?.status?.name
        },
        entriesWarehouseSection: {
            pathname: `/admin/shipments/${collectionShipment?.warehouseSection?.id}`,
            value: collectionShipment?.warehouseSection?.name
        },
        warehouseSection: {
            pathname: `/admin/shipments/${collectionShipment?.shipment?.warehouseSection?.id}`,
            value: collectionShipment?.shipment?.warehouseSection?.name
        },
        originBranch: {
            pathname: `/admin/branches/${collectionShipment.shipment?.originBranch?.id}`,
            value: collectionShipment.shipment?.originBranch?.name
        },
        currentBranch: {
            pathname: `/admin/branches/${collectionShipment.shipment?.currentBranch?.id}`,
            value: collectionShipment.shipment?.branch?.name
        },
        barcode: {
            value: collectionShipment?.shipment?.code
        },
        senderName: {
            value: collectionShipment?.shipment?.senderName
        },
        sender: {
            name: collectionShipment?.shipment?.senderName,

            mobile: collectionShipment?.shipment?.senderMobile,
            phone: collectionShipment?.shipment?.senderPhone
        },
        note: {
            value: collectionShipment?.shipment?.notes
        },
        entriesNotes: {
            value: collectionShipment?.notes
        },
        refNumber: {
            value: collectionShipment?.shipment?.refNumber
        },
        recipientName: {
            value: collectionShipment?.shipment?.recipientName
        },

        recipientAddress: {
            value: collectionShipment?.shipment?.recipientAddress
        },
        senderPhone: {
            mobile: collectionShipment?.shipment?.senderMobile,
            phone: collectionShipment?.shipment?.senderPhone
        },
        recipient: {
            name: collectionShipment?.shipment?.recipientName,
            mobile: collectionShipment?.shipment?.recipientMobile,
            phone: collectionShipment?.shipment?.recipientPhone
        },
        recipientPhone: {
            mobile: collectionShipment?.shipment?.recipientMobile,
            phone: collectionShipment?.shipment?.recipientPhone
        },
        entriesFees: {
            value: formatMoney(collectionShipment?.fees)
        },
        returnedValue: {
            value: formatMoney(collectionShipment?.shipment?.returnedValue)
        },
        collectedFees: {
            value: formatMoney(collectionShipment?.shipment?.collectedFees)
        },
    }
    return Boolean(ListShipmentDetails[key]) ? ListShipmentDetails[key] : { value: collectionShipment[key] }
}

export const getShipmentsTableHeader = (keys, collectionType, permission) => {
    let tableHeader = []
    keys.map((keyName) =>
        shipmentFieldsFun(collectionType, permission).map((i) =>
            keyName === i.key && tableHeader.push(i.label)
        )
    )
    return tableHeader
}


export const getShipmentsSumQuery = (TotalData, key) => {
    if (key === undefined) {
        return { value: "noVal" }
    }
    const ListShipmentDetails = {
        weight: { value: TotalData?.sumEntriesShipment?.weight },
        piecesCount: { value: TotalData?.sumEntriesShipment?.piecesCount },
        totalAmount: { value: TotalData?.sumEntriesShipment?.totalAmount },


        returningDueFees: { value: TotalData?.sumEntriesShipment?.returningDueFees },

        price: { value: TotalData?.sumEntriesShipment?.price, },

        deliveredAmount: { value: TotalData?.sumEntriesShipment?.deliveredAmount },

        entriesPickupAmount: { value: TotalData?.sumEntriesShipment?.pickupAmount },

        amount: { value: TotalData?.sumEntriesShipment?.amount, },

        entriesFees: { value: TotalData?.sumEntriesShipment?.fees, },

        returnedValue: { value: TotalData?.sumEntriesShipment?.returnedValue, },
        commission: {
            value: TotalData?.sumEntriesShipment?.commissionAmount
        },
        collectedAmount: {
            value: TotalData?.sumEntriesShipment?.collectedAmount
        },
        NetAmount: {
            value: TotalData?.sumEntriesShipment?.collectedAmount - TotalData?.sumEntriesShipment?.commissionAmount
        },
        collectedFees: {
            value: TotalData?.sumEntriesShipment?.collectedFees
        },
    }
    return Boolean(ListShipmentDetails[key]) ? ListShipmentDetails[key] : { value: undefined }
}

export const initialData = {
    DLVBY: [
        "code",
        "originBranch",
        "recipientName",
        "senderName",
        "description",
        "status",
        "deliveryType",
        "totalAmount",
        "deliveredAmount",
        "collectedFees",
        "returningDueFees",
        // "collectedAmount",
        // "commission",
        "NetAmount"
    ],
    CUSTM: [
        "code",
        "recipientName",
        "recipientZone",
        "recipientAddress",
        "description",
        // "collectedAmount",
    ],
}