import { useTranslation } from "react-i18next";
import { Chip, Paper, Typography } from "@mui/material";
import CustomTab from "../../HOC/FunctionComponents/CustomTab";
import { ProductTableForm } from "./FormTables";


function FormTabs(props) {
    const {
        classes,
        productsSelected,
        setChange,
        setChangeIN,
        EmptyTextFields,
        EmptyTextFieldsIN,
        setPickedProduct,
        CRDTShipment,
        setChangeDes,
        unEditable,
        errors,
        control,
        productsSelectedIN,
        setPickedProductIN,
        productDrawerHandler,
        productDrawerHandlerIN,
        clearErrors,
        showProductInOut,
        showProductOut,
        showProductIn,
    } = props
    const { t } = useTranslation()
    const tapsArray = [];

    if (showProductInOut || showProductOut) {
        let productOut = (
            <ProductTableForm
                classes={classes}
                productsSelected={productsSelected}
                setChange={setChange}
                EmptyTextFields={EmptyTextFields}
                setPickedProduct={setPickedProduct}
                unEditable={unEditable}
                errors={errors}
                control={control}
                CRDTShipment={CRDTShipment}
                setChangeDes={setChangeDes}
                productDrawerHandler={productDrawerHandler}
                clearErrors={clearErrors}
            />
        )

        tapsArray.push({
            tabHead:
                <Typography color="inherit" variant="subtitle1" component="div">
                    {t("deliveryProduct")}
                    <Chip color="primary" size="small" label={productsSelected.length} sx={{ mx: 1 }} />
                </Typography>,
            panel: <Paper>  {productOut}</Paper>
            ,
        })
    }

    if (showProductInOut || showProductIn) {
        let productIn = (
            <ProductTableForm
                classes={classes}
                productsSelected={productsSelectedIN}
                setChange={setChangeIN}
                EmptyTextFields={EmptyTextFieldsIN}
                setPickedProduct={setPickedProductIN}
                unEditable={unEditable}
                errors={errors}
                control={control}
                filter={true}
                productIN={true}
                CRDTShipment={CRDTShipment}
                setChangeDes={setChangeDes}
                productDrawerHandler={productDrawerHandlerIN}
                clearErrors={clearErrors}
            />
        )

        tapsArray.push({
            tabHead:
                <Typography color="inherit" variant="subtitle1" component="div">
                    {t("returnProduct")}
                    <Chip color="primary" size="small" label={productsSelectedIN.length} sx={{ mx: 1 }} />
                </Typography>,
            panel: <Paper>{productIn}</Paper>,
        });
    }



    return <CustomTab tapDetails={tapsArray} settings={true} />
}
export default FormTabs