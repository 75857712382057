import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { dateFormatLL } from "../../helpers/dateFunctions";
import { zoom } from "./ManifestPrintFunc";
import "./print.css";
import usePagesCount from "./usePagesCount";
import useTotalTableDetails from "./useTotalTable";
import ProductsTable from "../HOC/CustomComponents/ProductsTable";
import { GetStatus } from "../../helpers/manifestStatus";
import { useState } from "react";
import LogoImg from "../../Layout/LogoImg";

const PREFIX = "ManifestPrint";

const classes = {
  headerCells: `${PREFIX}-headerCells`,
  title: `${PREFIX}-title`,
  notes: `${PREFIX}-notes`,
  noteCell: `${PREFIX}-noteCell`,
  barcodeFont: `${PREFIX}-barcodeFont`,
  cairoFont: `${PREFIX}-cairoFont`,
  barcode: `${PREFIX}-barcode`,
  headerTableCell: `${PREFIX}-headerTableCell`,
  mainCellBorder: `${PREFIX}-mainCellBorder`,
  signature: `${PREFIX}-signature`,
  reportHeader: `${PREFIX}-reportHeader`,
  hiddenFooter: `${PREFIX}-hiddenFooter`,
  pageFooter: `${PREFIX}-pageFooter`,
  logoSection: `${PREFIX}-logoSection`,
  headerDate: `${PREFIX}-headerDate`,
  whiteSpace: `${PREFIX}-whiteSpace`,
  approve: `${PREFIX}-approve`,
  rowWithoutBorder: `${PREFIX}-rowWithoutBorder`,
  mainDetailsCell: `${PREFIX}-mainDetailsCell`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
  tableWidth: `${PREFIX}-tableWidth`,
  shipmentsTable: `${PREFIX}-shipmentsTable`,
  signatureBlock: `${PREFIX}-signatureBlock`,
};

const Root = styled("div")(({ theme }) => ({
  // fontFamily: ['"Cairo"', "sans-serif"].join(","),

  [`& .${classes.cairoFont}`]: {
    // fontFamily: ['"Cairo"', "sans-serif"].join(","),
  },

  [`& .${classes.headerCells}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.title}`]: {
    color: theme.palette.info.main,
    // whiteS;pace: "break-spaces",
  },

  [`& .${classes.notes}`]: {
    padding: theme.spacing(1),
    border: "1px solid " + theme.palette.action.disabled,
    wordBreak: "break-all",
    width: "100%",
    minHeight: 66,
  },

  [`& .${classes.noteCell}`]: {
    width: "30%",
    overflow: "hidden",
  },

  [`& .${classes.barcodeFont}`]: {
    fontFamily: '"Libre Barcode 39 Text"',
  },

  [`& .${classes.barcode}`]: {
    fontSize: 40,
    textAlign: "center",
  },

  [`& .${classes.headerTableCell}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.mainCellBorder}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.signature}`]: {
    textAlign: "center",
    padding: theme.spacing(2, 1, 1, 1),
    border: "none",
  },

  [`&.${classes.reportHeader}`]: {
    whiteSpace: "pre-line",
    paddingLeft: theme.spacing(2),
    lineHeight: "120%",
    fontSize: 12,
  },

  [`& .${classes.hiddenFooter}`]: {
    visibility: "hidden",
  },

  [`& .${classes.pageFooter}`]: {
    //   "&::after": {
    //    " content:  counter(page)  of  counter(pages)"
    //   },
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid " + theme.palette.text.secondary,
    display: "flex",
    alignItems: "flex-end",
  },

  [`& .${classes.logoSection}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.headerDate}`]: {
    textAlign: "end",
  },

  [`& .${classes.whiteSpace}`]: {
    "& tr": {
      "& td": {
        whiteSpace: "initial",
      },
    },
  },

  [`& .${classes.approve}`]: {
    textAlign: "end",
  },

  [`& .${classes.rowWithoutBorder}`]: {
    "& td": {
      border: "none",
      padding: "2px",
    },
  },

  [`& .${classes.mainDetailsCell}`]: {
    "& td": { border: "none", verticalAlign: "top" },
    "& td:first-of-type": { paddingLeft: 0 },
    "& td:last-of-type": { paddingRight: "2px" },

    border: "none",
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.tableHeadCell}`]: {
    padding: 0,
  },

  [`& .${classes.tableWidth}`]: {
    width: "100vw",
  },

  [`& .${classes.shipmentsTable}`]: {
    "& tr": {
      "&:hover": {
        backgroundColor: "#ffffff00 !important",
      },
    },
    "& th": {
      padding: theme.spacing(1),
    },
    "& td": {
      padding: theme.spacing(0, 1),
    },
  },

  [`& .${classes.signatureBlock}`]: {
    breakInside: "avoid",
  },
}));

const ManifestPrint = (props) => {
  const manifestId = +props.match.params.id;
  const printType = props.match.params.type;
  const transfer = props.match.params.transfer;
  const manifestTransactionType = props.match.params?.trxType;


  const { reportDetails, loading, organization, printTableDetails, printTablePickupsDetails, printTableConciergeDetails, operationName, allowedConcierge } =
    usePagesCount(props);

  const receivedShipment = []
  transfer && reportDetails?.referredManifests?.map((e) => receivedShipment.push(...e.manifestShipments))
  const referredManifestData = Boolean(transfer)
  const { details: tableDetails } = printTableDetails({
    type: manifestTransactionType,
    typeData: reportDetails,
    receivedShipment,
    referredManifestData,
    print:true
  });

  const { details: tableConciergeDetails } = (printType === "payment" || printType === "collection") && printTableConciergeDetails({
    type: manifestTransactionType,
    typeData: reportDetails,
  });

  const { details: tablePickupDetails } = printType === "payment" && printTablePickupsDetails({
    type: manifestTransactionType,
    typeData: reportDetails,
  });

  const handelHeaderFooterHeight = (width) => {
    const elementPercentage = (selector) => {
      const element = document.querySelector(selector);
      const rect = element.getBoundingClientRect();
      const pxToCm = rect.height;
      element.style.height = `${pxToCm}px`;
      return pxToCm;
    };
    document.getElementById("page-footer-space").style.width = width + "px"

    const pageFooter = elementPercentage(".page-footer-space");
    const pageHeader = elementPercentage(".page-header-space");
    document.querySelector(".page-footer").style.height = pageFooter + "px";
    document.querySelector(".page-header").style.height = pageHeader + "px";
  };

  const [zoomed, setZoomed] = useState(false)
  useEffect(() => {
    if (reportDetails) {
      if (reportDetails === null) return;
      if (!zoomed) {
        const zoomDetails = zoom();
        handelHeaderFooterHeight(zoomDetails.width);
        setZoomed(true)
        let newLink = document.createElement("link");
        newLink.rel = "stylesheet";
        newLink.type = "text/css";
        newLink.href = process.env.PUBLIC_URL + "/" + zoomDetails?.css;

        // document
        //   .querySelectorAll("#manifestPrintTable")
        //   .forEach((i) => (i.style.zoom = zoomDetails.percent));

        document.querySelectorAll("#shipmentsTableContainer").forEach((i) => {
          i.style.minWidth = "100%";
          i.style.width = "100vw";
        });
        // handelHeaderFooterHeight();
        newLink.onload = () => {
          // document.fonts.onloadingdone = function (fontFaceSetEvent) {
          window.print();
          newLink.onload = null;
        };

        document.getElementById("maniContainer").appendChild(newLink);
      }
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePickupDetails, tableDetails, tableConciergeDetails]);

  return (
    <Root id="maniContainer">
      {loading ? (
        <>
          <FullScreenLoading minHeight="20%" />
          {/* this spans for download font before print */}
          <span className={clsx(classes.barcodeFont)} />
          <span style={{ opacity: 0 }} className={classes.cairoFont}>
            lا
          </span>
        </>
      ) : reportDetails === null ? (
        <NotFound />
      ) : (
        <>
          {manifestId ? (
            <PrintDetails
              reportDetails={reportDetails}
              organization={organization}
              loading={loading}
              printType={printType}
              tableDetails={tableDetails}
              tablePickupDetails={tablePickupDetails}
              tableConciergeDetails={tableConciergeDetails}
              manifestTransactionType={manifestTransactionType}
              operationName={operationName}
              allowedConcierge={allowedConcierge}
            />
          ) : (
            reportDetails?.map((i, index) => (
              <PrintDetails
                arrayIndex={index + 1}
                key={i.id}
                reportDetails={i}
                organization={organization}
                loading={loading}
                printType={printType}
                tableDetails={tableDetails}
                tablePickupDetails={tablePickupDetails}
                tableConciergeDetails={tableConciergeDetails}
                manifestTransactionType={manifestTransactionType}
                operationName={operationName}
                allowedConcierge={allowedConcierge}
              />
            ))
          )}
          <div className="page-footer">{organization?.reportFooter}</div>
        </>
      )}
    </Root>
  );
};

export default ManifestPrint;

const PrintDetails = (props) => {
  const { reportDetails,
    organization,
    loading,
    printType,
    tableDetails,
    tablePickupDetails,
    tableConciergeDetails,
    manifestTransactionType,
    operationName,
    arrayIndex, allowedConcierge
  } =
    props;
  const { t } = useTranslation();
  const { custmTableTotal: totalTable } = useTotalTableDetails({
    reportDetails,
    printType,
    manifestTransactionType
  })

  const typeCheck = (type) => type === printType;

  let firstSignature = t("accountant");
  let middleSignature = t("operationManager");
  let lastSignature = t("shippingAgent");
  let entriesDetails
  // const reportDetails = reportData?.[printType];
  if (reportDetails?.[`${operationName}_0`]) {
    let concatData = [];
    for (const key in reportDetails) {
      if (key.startsWith(`${operationName}_`)) {
        const listData = reportDetails[key].data;
        concatData = concatData.concat(listData);
      }
    }
    entriesDetails = concatData;
  }

  const tableData = entriesDetails ?? reportDetails?.allEntries

  let paymentshipments = null, paymentPickups = null, paymentConciergeRequest = null
  if (typeCheck("payment") || typeCheck("collection")) {
    paymentshipments = tableData.filter((i) => i.shipment !== null)
    paymentPickups = tableData.filter((i) => i.pickup !== null)
    paymentConciergeRequest = tableData.filter((i) => i.conciergeRequest !== null)
  }
  const manifestType = reportDetails?.transactionType?.type?.code;
  const reportType = reportDetails?.type?.code;

  const paymentCustm = (typeCheck("payment") && reportType === "CUSTM")
  const paymentDLVBY = (typeCheck("payment") && reportType === "DLVBY")
  const collectionDLVBY = (typeCheck("collection") && reportType === "DLVBY")
  const paymentAndInvoice = paymentCustm || typeCheck("invoice");

  if (paymentCustm) {
    firstSignature = t("paymentCustmSign1");
    middleSignature = t("paymentCustmSign2");
    lastSignature = t("paymentCustmSign3");
  }
  if (paymentDLVBY) {
    firstSignature = t("paymentDlvbySign1");
    middleSignature = t("paymentDlvbySign2");
    lastSignature = t("paymentDlvbySign3");
  }
  if (collectionDLVBY) {
    firstSignature = t("collectionDlvbySign1");
    middleSignature = t("collectionDlvbySign2");
    lastSignature = t("collectionDlvbySign3");
  }

  if (typeCheck("invoice")) {
    lastSignature = t("customer");
  }

  if (typeCheck('warehousemanifest')) {
    lastSignature = t("storekeeper");
  }

  const pkmType = manifestType === "PKM";
  const otrType = manifestType === "OTR";
  const otdType = manifestType === "OTD";
  const ritsType = manifestType === "RITS";
  const rtsType = manifestType === "RTS";
  const rtrnType = manifestType === "RTRN";

  if (otrType) {
    firstSignature = t("otrSign1");
    middleSignature = t("otrSign2");
    lastSignature = t("otrSign3");
  }
  if (otdType) {
    firstSignature = t("otdSign1");
    middleSignature = t("otdSign2");
    lastSignature = t("otdSign3");
  }
  if (ritsType) {
    firstSignature = t("ritsSign1");
    middleSignature = t("ritsSign2");
    lastSignature = t("ritsSign3");
  }
  if (rtsType) {
    firstSignature = t("rtsSign1");
    middleSignature = t("rtsSign2");
    lastSignature = t("rtsSign3");
  }
  if (rtrnType) {
    lastSignature = t("customer");
  }

  const customerPhones = [
    reportDetails?.customer?.phone,
    reportDetails?.customer?.mobile,
  ].join(" - ");

  const header = !loading && reportDetails !== null && (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell sx={{ padding: 0 }}>
            <Table>
              <TableBody>
                <TableRow className={classes.rowWithoutBorder}>
                  <TableCell>
                    <Box className={classes.logoSection}>
                      <LogoImg height={30} forceLight={true} />
                      <Typography
                        sx={{
                          whiteSpace: "pre-line !important",
                          paddingLeft: '10px',
                          lineHeight: "120%",
                          fontSize: 12,
                        }}
                        className={clsx(classes.reportHeader, "landscape-zoom")}
                      >
                        {organization.reportHeader}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell className={clsx(classes.headerDate)}>
                    <Typography variant="h6">
                      {moment(new Date()).locale("en").format("ll")}
                    </Typography>
                    <TypographyKeyValue
                      title={t("registrationNumber")}
                      value={organization?.registrationNumber}
                    />
                    <TypographyKeyValue
                      title={t("taxNumber")}
                      value={organization?.taxNumber}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.headerTableCell}>
            <Table className={classes.whiteSpace}>
              <TableBody>
                <TableRow className={classes.rowWithoutBorder}>
                  <TableCell>
                    <Typography variant="h5">
                      {reportDetails?.transactionType?.type?.name ??
                        reportDetails?.type?.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={clsx(classes.barcode, classes.barcodeFont)}
                    >
                      *{reportDetails.code}*
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TypographyKeyValue
                      variant="h6"
                      className={clsx(classes.keyValue, classes.approve)}
                      title={t("status")}
                      value={
                        reportDetails?.transferType ?
                          t(GetStatus(reportDetails?.approved, reportDetails?.shipmentsCount, reportDetails?.referredManifests))
                          : reportDetails?.approved
                            ? t("approved")
                            : t("notApproved")
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell
            className={clsx(classes.mainDetailsCell, "landscape-zoom")}
          >
            <Table className={classes.whiteSpace}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TypographyKeyValue
                      title={t("theDate")}
                      value={dateFormatLL(reportDetails.date)}
                    />
                    <TypographyKeyValue
                      title={t("branch")}
                      value={reportDetails.branch?.name}
                    />
                    <TypographyKeyValue
                      title={t("shippingAgent")}
                      value={
                        reportDetails.deliveryAgent?.code &&
                        `(${reportDetails.deliveryAgent?.code}) ${reportDetails.deliveryAgent?.name}`
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <TypographyKeyValue
                      title={t("customer")}
                      value={
                        reportDetails.customer?.name &&
                        `(${reportDetails.customer?.code}) ${reportDetails.customer?.name}`
                      }
                      hidden={pkmType || otrType}
                    />
                    <TypographyKeyValue
                      title={t("location")}
                      value={
                        reportDetails.customer?.zone?.name &&
                        reportDetails.customer?.zone?.name +
                        " - " +
                        reportDetails.customer?.subzone?.name
                      }
                      hidden={pkmType || otrType}
                    />
                    <TypographyKeyValue
                      title={t("address")}
                      value={reportDetails.customer?.address}
                      hidden={reportType !== "CUSTM" && !typeCheck("invoice")}
                    />
                  </TableCell>

                  <TableCell>
                    <TypographyKeyValue
                      title={t("transactionType")}
                      value={reportDetails.transactionType?.name}
                    />
                    <TypographyKeyValue
                      title={t("safe")}
                      value={reportDetails.safe?.name}
                    />
                    <TypographyKeyValue
                      title={t("totalDeservedAmount")}
                      value={reportDetails?.paymentAmount}
                      hidden={reportType === "CUSTM"}
                    />
                    <TypographyKeyValue
                      title={
                        manifestType === "BMT" ? t("toBranch") : t("fromBranch")
                      }
                      value={reportDetails.referenceBranch?.name}
                    />
                    {["BMT", "BMR"].includes(manifestType) && <TypographyKeyValue
                      title={
                        manifestType === "BMT" ? t("referredManifests") : t("referenceManifest")
                      }
                      value={manifestType === "BMT" ?
                        reportDetails.referredManifests[0]?.code :
                        reportDetails.referenceManifest?.code
                      }
                    />}
                    {["BMT", "BMR"].includes(manifestType) && <TypographyKeyValue
                      title={t("transferType")}
                      value={reportDetails.transferType?.name}
                    />}
                    {manifestType === "BMT" && <TypographyKeyValue
                      title={t("sacksCount")}
                      value={reportDetails.sacksCount}
                    />}
                    <TypographyKeyValue
                      title={t("phone")}
                      value={customerPhones}
                      hidden={!paymentAndInvoice}
                    />
                  </TableCell>

                  <TableCell className={classes.noteCell}>
                    <Box component="div" className={classes.notes}>
                      <Typography variant="body2">
                        {reportDetails.notes}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
  return (
    <div id="printManifestContainer">
      <div className="page-header">{header}</div>

      <table>
        <thead>
          <tr>
            <td>
              <div className="page-header-space">{header}</div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div
                className={clsx(classes.shipmentsTable, {
                  // [classes.tableWidth]: tableZoom,
                })}
                id="shipmentsTableContainer"
              >
                {tableDetails && !typeCheck('warehousemanifest') && (
                  <ShipmentsTable
                    id="manifestPrintTable"
                    className="page-content"
                    data={typeCheck('collection') || typeCheck('payment') ? paymentshipments : tableData}
                    headCells={tableDetails.tableHead}
                    parseBodyCell={tableDetails.tableBody}
                    sumRow={(tableData.length !== 0) ? totalTable ? totalTable : tableDetails.tableTotal : null}
                  />
                )}
                {/* {tableDetails && !typeCheck('warehousemanifest') && !(typeCheck('invoice') || typeCheck('manifest')||typeCheck('collection')) && (
                  <ShipmentsOperationTable
                    id="manifestPrintTable"
                    className="page-content"
                    data={paymentshipments}
                    headCells={tableDetails.tableHead}
                    parseBodyCell={tableDetails.tableBody}
                    total={(paymentCustm || paymentDLVBY) ? totalTable : tableDetails.tableTotal}
                  />
                )} */}
                {tableConciergeDetails && allowedConcierge?.shippingSettings?.allowedCustomerTypes.includes("CONCIERGE") && (
                  <ShipmentsTable
                    id="manifestPrintTable"
                    className="page-content"
                    data={paymentConciergeRequest}
                    headCells={tableConciergeDetails.tableHead}
                    parseBodyCell={tableConciergeDetails.tableBody}
                    total={arrayIndex ? tableConciergeDetails.tableTotal[arrayIndex - 1] : tableConciergeDetails.tableTotal}
                    sumRow={tableConciergeDetails?.sum}
                    sumTableNumber={tableConciergeDetails?.sumTableNumber}
                  />
                )}
                {tablePickupDetails && paymentPickups.length !== 0 && (
                  <ShipmentsTable
                    id="manifestPrintTable"
                    className="page-content"
                    data={typeCheck("payment") ? paymentPickups : tableData}
                    headCells={tablePickupDetails.tableHead}
                    parseBodyCell={tablePickupDetails.tableBody}
                    total={arrayIndex ? tablePickupDetails.tableTotal[arrayIndex - 1] : tablePickupDetails.tableTotal}
                    sumTableNumber={tablePickupDetails?.sumTableNumber}
                    sumRow={tablePickupDetails?.sum}
                    sum={totalTable && true}
                  />
                )}
                {tableDetails && typeCheck('warehousemanifest') && (
                  <ProductsTable
                    id="manifestPrintTable"
                    className="page-content"
                    data={tableData}
                    headCells={tableDetails.tableHead}
                    parseBodyCell={tableDetails.tableBody}
                    total={totalTable ? totalTable : tableDetails.tableTotal}
                    sumTableNumber={tableDetails?.sumTableNumber}
                    sumRow={tableDetails?.sum}
                  />
                )}
              </div>
              <div className={classes.signatureBlock}>
                {!typeCheck("invoice") && (
                  <Table className={classes.whiteSpace}>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.signature}>
                          <Typography color="primary">
                            {firstSignature}
                          </Typography>
                          <Typography>____________________</Typography>
                        </TableCell>
                        <TableCell className={classes.signature}>
                          <Typography color="primary">
                            {middleSignature}
                          </Typography>
                          <Typography>____________________</Typography>
                        </TableCell>
                        <TableCell className={classes.signature}>
                          <Typography color="primary">
                            {lastSignature}
                          </Typography>
                          <Typography>____________________</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot id="page-footer-space" style={{ display: "flex", width: "100px" }}>
          <tr>
            <td>
              <div className="page-footer-space">
                {organization.reportFooter}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

function TypographyKeyValue(props) {
  const { title, value, hidden, ...restProps } = props;
  return hidden || !value ? null : (
    <Typography
      variant="body2"
      className={clsx(classes.keyValue)}
      {...restProps}
    >
      <span className={classes.title}>{title}:</span> <span>{value}</span>
    </Typography>
  );
}
