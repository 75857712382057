import { Box } from "@mui/material";
import formatMoney from "../../helpers/numbersDot";
import { dateFormatLL } from "../../helpers/dateFunctions";
export const defaultQueryFieldsInvoice = (keys) => {
    const defaultKeys = []
    const shipmentFields = []
    const invoiceEntriesShipment = (paymentShipmentData) => {
        return {
            operation: `shipment`,
            fields: [
                "id",
                ...paymentShipmentData
            ],
            variables: {}
        }


    };
    const queriesEntries = {
        deliveryFees: {
            queryName: "deliveryFees"
        },
        postFees: {
            queryName: "postFees"
        },
        tax: {
            queryName: "tax"
        },
        allFees: {
            queryName: "allFees"
        },

    }
    const queriesShipment = {
        code: {
            queryName: "code"
        },
        description: {
            queryName: "description"
        },
        totalAmount: {
            queryName: "totalAmount"
        },
        senderAddress: {
            queryName: "senderAddress"
        },
        date: {
            queryName: "date"
        },
        deliveredOrReturnedDate: {
            queryName: "deliveredOrReturnedDate"
        },
        recipientAddress: {
            queryName: "recipientAddress"
        },
        refNumber: {
            queryName: "refNumber"
        },
        piecesCount: {
            queryName: "piecesCount"
        },
        price: {
            queryName: "price"
        },
        weight: {
            queryName: "weight"
        },
        deliveredAmount: {
            queryName: "deliveredAmount"
        },
        amount: {
            queryName: "amount"
        },
        attempts: {
            queryName: "attempts"
        },
        status: {
            queryName: {
                status: ["name", "code"],
            }
        },
        lastTransactionDate: {
            queryName: {
                lastTransaction: ["date"],
            }
        },
        transactions: {
            queryName: {

                transactions: [{ returnType: ["name"] }],

            }
        },
        recipientZone: {
            queryName: [
                {
                    recipientZone: ["id", "name"],
                },
                {
                    recipientSubzone: ["name"]
                }
            ]
        },
        senderZone: {
            queryName: [
                {
                    senderZone: ["id", "name"],
                },
                {
                    senderSubzone: ["id", "name"],
                }
            ]
        },
        type: {
            queryName: {
                type: ["name"],
            }
        },
        paymentType: {
            queryName: {
                paymentType: ["name"],
            }
        },
        openable: {
            queryName: {
                openable: ["name", "code"],
            }
        },
        returnType: {
            queryName: {
                returnType: ["name"],
            }
        },
        deliveryType: {
            queryName: [{
                deliveryType: ["name"],

            },
            { status: ["name", "code"] }
            ]
        },
        warehouseSection: {
            queryName: {
                warehouseSection: ["name"],
            }
        },
        note: {
            queryName: "notes"
        },
        pendingCollectionAmount: {
            queryName: "pendingCollectionAmount"
        },
        deliveryCommission: {
            queryName: "deliveryCommission"
        },
        senderName: {
            queryName: "senderName"
        },
        recipientName: {
            queryName: "recipientName"
        },

        sender: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "senderName",
                "senderMobile",
                "senderPhone"
            ]
        },
        recipient: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "recipientName",
                "recipientMobile",
                "recipientPhone"
            ]
        },
        recipientPhone: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "recipientMobile",
                "recipientPhone"
            ]
        },
        senderPhone: {
            queryName: [
                {
                    customer: [{
                        operation: `isMobileHidden(code: MANIFEST)`,
                        fields: [],
                        variables: {
                        },
                    },],
                },
                "senderMobile",
                "senderPhone"
            ]
        },
        returningDueFees: {
            queryName: "returningDueFees"
        },
        deliveryDate: {
            queryName: "deliveryDate"
        },
        returnedValue: {
            queryName: "returnedValue"
        },
        cancellationReason: {
            queryName: { cancellationReason: ["name"] }
        },
        customer: {
            queryName: { customer: ["id", "name", "code"], }
        },
        barcode: {
            queryName: "code"
        },
        originBranch: {
            queryName: { originBranch: ["id", "name"], }
        },
        currentBranch: {
            queryName: {
                branch: ["id", "name"],
            },
        },
        collectedFees: {
            queryName: "collectedFees"
        },

    }
    keys.map((keyName) => {
        if (queriesEntries?.[keyName]) {
            if (Array.isArray(queriesEntries[keyName].queryName)) {
                queriesEntries[keyName].queryName.map((i) => defaultKeys.push(i))
            } else {
                defaultKeys.push(queriesEntries[keyName].queryName)
            }
        }
        if (queriesShipment?.[keyName]) {
            if (Array.isArray(queriesShipment[keyName].queryName)) {
                queriesShipment[keyName].queryName.map((i) => shipmentFields.push(i))
            }
            else {
                shipmentFields.push(queriesShipment[keyName].queryName)
            }
        }
        return ''
    })
    let uniqueArray = shipmentFields.filter((item, index, self) => {
        if (typeof item === 'object') {
            // For objects, check if it's not a duplicate by comparing JSON strings
            return self.findIndex(other => JSON.stringify(other) === JSON.stringify(item)) === index;
        } else {
            // For non-objects, remove duplicates as before
            return self.indexOf(item) === index;
        }
    });
    defaultKeys.push(invoiceEntriesShipment(uniqueArray))
    return defaultKeys
};

export const shipmentFieldsFun = () => {
    const shipmentFields = [
        {
            key: "code",
            label: "code",
            type: "link",
            path: "shipments"
        },

        {
            key: "description",
            label: "packageDescription",
            type: ""
        },
        {
            key: "barcode",
            label: "barcode",
            type: "barcode"
        },
        {
            key: "recipient",
            label: "recipient",
            type: "infoGroup"
        },
        {
            key: "sender",
            label: "senderName",
            type: "infoGroup"
        },
        {
            key: "note",
            label: "notes",
            type: "",
        },
        {
            key: "recipientZone",
            label: "destination",
            type: "zone"
        },
        {
            key: "deliveredOrReturnedDate",
            label: "deliveryCanclingDate",
            type: ""
        },

        {
            key: "date",
            label: "date",
            type: ""
        },
        {
            key: "piecesCount",
            label: "pieceCount",
            type: "",
            ifSum: "piecesCount"
        },
        {
            key: "weight",
            label: "weight",
            type: "",
            ifSum: "weight"
        },
        {
            key: "senderAddress",
            label: "senderAddress",
            type: ""
        },
        {
            key: "senderZone",
            label: "source",
            type: "zone"
        },
        {
            key: "recipientAddress",
            label: "recipientAddress",
            type: ""
        },
        {
            key: "allFees",
            label: "returningDueFees",
            type: "",
            ifSum: "allFees"
        },
        {
            key: "tax",
            label: "tax",
            type: "",
            ifSum: "tax"
        },
        {
            key: "postFees",
            label: "postFees",
            type: "",
            ifSum: "postFees"
        },
        {
            key: "deliveryFees",
            label: "deliveryFees",
            type: "",
            ifSum: "deliveryFees"
        },
        {
            key: "originBranch",
            label: "originBranch",
            type: "link",
            path: "shipments"
        },
        {
            key: "currentBranch",
            label: "currentBranch",
            type: "link",
            path: "shipments"
        },
    ]
    return shipmentFields
}

export const getListShipmentTable = (invoiceShipment, key) => {
    if (key === undefined) {
        return { value: "noVal" }
    }
    const ListShipmentDetails = {
        customer: {
            pathname: `/admin/customers/${invoiceShipment?.shipment?.customer?.id}`,
            value: <>
                <Box component="span" fontWeight="bold">{` (${invoiceShipment?.shipment?.customer?.code}) `}</Box>
                <Box component="span">  {invoiceShipment?.shipment?.customer?.name}</Box>
            </>
        },
        code: {
            pathname: `/admin/shipments/${invoiceShipment?.shipment?.id}`,
            value: invoiceShipment?.shipment?.code,
            ifCode: "code"
        },
        date: {
            value: dateFormatLL(invoiceShipment?.shipment?.date)
        },
        entriesDeliveryDate: {
            value: dateFormatLL(invoiceShipment?.deliveryDate)
        },
        deliveryDate: {
            value: dateFormatLL(invoiceShipment?.shipment?.deliveryDate)
        },
        deliveredOrReturnedDate: {
            value: dateFormatLL(invoiceShipment?.shipment?.deliveredOrReturnedDate)
        },
        description: {
            value: invoiceShipment?.shipment?.description,
            condition: "description",
        },
        senderZone: {
            pathname: `/admin/zones/${invoiceShipment?.shipment?.senderZone?.id}`,
            zone: invoiceShipment?.shipment?.senderZone?.name,
            subzone: invoiceShipment?.shipment?.senderSubzone?.name
        },
        recipientZone: {
            pathname: `/admin/zones/${invoiceShipment?.shipment?.recipientZone?.id}`,
            zone: invoiceShipment?.shipment?.recipientZone?.name,
            subzone: invoiceShipment?.shipment?.recipientSubzone?.name
        },
        price: {
            value: formatMoney(invoiceShipment.shipment?.price),
        },
        senderAddress: {
            value: invoiceShipment.shipment?.senderAddress,
        },
        deliveredAmount: {
            value: formatMoney(invoiceShipment.shipment?.deliveredAmount),
        },
        amount: {
            value: formatMoney(invoiceShipment?.shipment?.amount)
        },
        totalAmount: {
            value: formatMoney(invoiceShipment?.shipment?.totalAmount),
        },
        returningDueFees: {
            value: formatMoney(invoiceShipment?.shipment?.returningDueFees)
        },
        entriesPickupAmount: {
            value: formatMoney(invoiceShipment?.pickupAmount)
        },
        status: {
            value: invoiceShipment.shipment?.status?.name,
        },
        attempts: {
            value: invoiceShipment.shipment?.attempts,
        },
        lastTransactionDate: {
            value: dateFormatLL(invoiceShipment?.shipment?.lastTransaction?.date)
        },
        transactions: {
            value: invoiceShipment?.shipment?.transactions?.find((i) => i.returnType !== null)?.returnType?.name,
        },
        piecesCount: {
            value: formatMoney(invoiceShipment?.shipment?.piecesCount)
        },
        commission: {
            value: formatMoney(invoiceShipment?.commission)
        },
        collectedAmount: {
            value: formatMoney(invoiceShipment?.collectedAmount)
        },
        NetAmount: {
            value: formatMoney(invoiceShipment?.collectedAmount - invoiceShipment?.commission)
        },
        weight: {
            value: invoiceShipment?.shipment?.weight
        },
        type: {
            value: invoiceShipment?.shipment?.type?.name
        },
        paymentType: {
            value: invoiceShipment?.shipment?.paymentType?.name
        },
        openable: {
            value: invoiceShipment?.shipment?.openable?.code === "Y" ? true : false
        },
        entriesCancellationReason: {
            value: invoiceShipment.cancellationReason?.name
        },
        cancellationReason: {
            value: invoiceShipment.shipment?.cancellationReason?.name
        },
        returnType: {
            value: invoiceShipment?.shipment?.returnType?.name
        },
        entriesReturnType: {
            value: invoiceShipment?.returnType?.name
        },
        deliveryType: {
            value: invoiceShipment.shipment?.deliveryType?.name ?? invoiceShipment.shipment?.status?.name
        },
        entriesWarehouseSection: {
            pathname: `/admin/shipments/${invoiceShipment?.warehouseSection?.id}`,
            value: invoiceShipment?.warehouseSection?.name
        },
        warehouseSection: {
            pathname: `/admin/shipments/${invoiceShipment?.shipment?.warehouseSection?.id}`,
            value: invoiceShipment?.shipment?.warehouseSection?.name
        },
        originBranch: {
            pathname: `/admin/branches/${invoiceShipment.shipment?.originBranch?.id}`,
            value: invoiceShipment.shipment?.originBranch?.name
        },
        currentBranch: {
            pathname: `/admin/branches/${invoiceShipment.shipment?.currentBranch?.id}`,
            value: invoiceShipment.shipment?.branch?.name
        },
        barcode: {
            value: invoiceShipment?.shipment?.code
        },
        senderName: {
            value: invoiceShipment?.shipment?.senderName
        },
        sender: {
            name: invoiceShipment?.shipment?.senderName,

            mobile: invoiceShipment?.shipment?.senderMobile,
            phone: invoiceShipment?.shipment?.senderPhone
        },
        note: {
            value: invoiceShipment?.shipment?.notes
        },
        entriesNotes: {
            value: invoiceShipment?.notes
        },
        refNumber: {
            value: invoiceShipment?.shipment?.refNumber
        },
        recipientName: {
            value: invoiceShipment?.shipment?.recipientName
        },

        recipientAddress: {
            value: invoiceShipment?.shipment?.recipientAddress
        },
        senderPhone: {
            mobile: invoiceShipment?.shipment?.senderMobile,
            phone: invoiceShipment?.shipment?.senderPhone
        },
        recipient: {
            name: invoiceShipment?.shipment?.recipientName,
            mobile: invoiceShipment?.shipment?.recipientMobile,
            phone: invoiceShipment?.shipment?.recipientPhone
        },
        recipientPhone: {
            mobile: invoiceShipment?.shipment?.recipientMobile,
            phone: invoiceShipment?.shipment?.recipientPhone
        },
        entriesFees: {
            value: formatMoney(invoiceShipment?.fees)
        },
        returnedValue: {
            value: formatMoney(invoiceShipment?.shipment?.returnedValue)
        },
        collectedFees: {
            value: formatMoney(invoiceShipment?.shipment?.collectedFees)
        },
        paidAmount: {
            value: formatMoney(invoiceShipment?.paidAmount)
        },
        deliveryFees: {
            value: formatMoney(invoiceShipment?.deliveryFees)
        },
        postFees: {
            value: formatMoney(invoiceShipment?.postFees)
        },
        allFees: {
            value: formatMoney(invoiceShipment?.allFees)
        },
        tax: {
            value: formatMoney(invoiceShipment?.tax)
        },


    }
    return Boolean(ListShipmentDetails[key]) ? ListShipmentDetails[key] : { value: invoiceShipment[key] }
}

export const getShipmentsTableHeader = (keys) => {
    let tableHeader = []
    keys.map((keyName) =>
        shipmentFieldsFun().map((i) =>
            keyName === i.key && tableHeader.push(i.label)
        )
    )
    return tableHeader
}


export const getShipmentsSumQuery = (TotalData, key) => {
    if (key === undefined) {
        return { value: "noVal" }
    }
    const ListShipmentDetails = {
        weight: { value: TotalData?.sumEntries?.weight },
        piecesCount: { value: TotalData?.sumEntries?.pieces },
        totalAmount: { value: TotalData?.sumEntries?.totalAmount },


        returningDueFees: { value: TotalData?.sumEntries?.dueFees },

        price: { value: TotalData?.sumEntries?.price, },

        deliveredAmount: { value: TotalData?.sumEntries?.deliveredAmount },

        entriesPickupAmount: { value: TotalData?.sumEntries?.pickupAmount },

        amount: { value: TotalData?.sumEntries?.amount, },

        entriesFees: { value: TotalData?.sumEntries?.fees, },

        returnedValue: { value: TotalData?.sumEntries?.returnedValue, },
        commission: {
            value: TotalData?.sumEntries?.commission
        },
        collectedAmount: {
            value: TotalData?.sumEntries?.collectedAmount
        },
        NetAmount: {
            value: TotalData?.sumEntries?.collectedAmount - TotalData?.sumEntries?.commission
        },
        collectedFees: {
            value: TotalData?.sumEntries?.collectedFees
        },
        paidAmount: {
            value: TotalData?.sumEntries?.paymentAmount
        },
        deliveryFees: {
            value: TotalData?.sumEntries?.deliveryFees
        },
        postFees: {
            value: TotalData?.sumEntries?.postFees
        },
        allFees: {
            value: TotalData?.sumEntries?.allFees
        },
        tax: {
            value: TotalData?.sumEntries?.tax
        },
    }
    return Boolean(ListShipmentDetails[key]) ? ListShipmentDetails[key] : { value: undefined }
}

export const invoice = [
    "code",
    "date",
    "recipient",
    "recipientZone",
    "sender",
    "senderZone",
    "weight",
    "piecesCount",
    "deliveryFees",
    "postFees",
    "tax",
    "allFees",
];
