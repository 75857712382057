import * as gqlb from "gql-query-builder";

export const LIST_SUPPORT_CATEGORIES = gqlb.query({
  operation: "listSupportCategories",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "lastPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "name",
        "active",
        "ticketCount",
        "deletable",
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListSupportCategoriesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SUPPORT_CATEGORIES_ID = gqlb.query({
  operation: "supportCategory",
  fields: [
    "id",
    "name",
    "active",
    "code",
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_CATEGORY = gqlb.mutation({
  operation: "saveSupportCategory",
  fields: ["id"],
  variables: {
    input: {
      type: "SupportCategoryInput",
      required: true,
    },
  },
});

export const DELETE_CATEGORY = gqlb.mutation({
  operation: "deleteSupportCategory",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});