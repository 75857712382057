import React, { useEffect, useMemo, useState } from "react";

import { styled } from "@mui/material/styles";

import { gql, useQuery } from "@apollo/client";
import { Add, FilterList, Print, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Icon,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
// import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  // LIST_PRICE_LIST_DROPDOWN,
  // LSIT_ZONES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
// import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUIselect from "../HOC/MUI/MUIselect";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_PRODUCTS } from "./Graphql";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import Grid from '@mui/material/Unstable_Grid2';
import config from "../../config.json";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";

const PREFIX = "ProductList";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },


  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },
}));
// const drawerWidth = 240;

const ProductList = (props) => {
  const { t } = useTranslation(["translation", "validation"]);
  // const theme = useTheme();
  const [refetch, setRefetch] = useState(true);
  const urlQuery = urlParameters(window.location.search);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const [showBranch, setShowBranch] = useState(null);

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    zone: null,
    subzone: null,
    customer: null
  });
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    // watch,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);

  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
    };
    delete urlQuery["rowsPerPage"];
    delete urlQuery["page"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["active"] !== undefined && setValue("active", urlQuery["active"]);
    urlQuery["withAvailableStock"] !== undefined && setValue("withAvailableStock", urlQuery["withAvailableStock"]);
    urlQuery["withAvailableStock"] !== undefined && setShowBranch(urlQuery["withAvailableStock"]);
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/products",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    setRefetch((prev) => !prev)
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    let handledData = {
      active: data.active,
      withAvailableStock: data.withAvailableStock,
      branchId: data.branchId,
      customerId: data.customerId,
      name: data.name,
      code: data.code,
    };
    data["active"] === "" && delete handledData["active"];
    data["withAvailableStock"] === "" && delete handledData["withAvailableStock"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const parseData = (data) => {
    return data;
  };


  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });
    setPage(newPage)
    setSearch((prev) => ({ ...prev }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });
    setPage(0)
    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev }));
  };

  const customer = Globals.user.account;

  const { data, loading } = useQuery(
    gql`
      ${LIST_PRODUCTS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        ...(search.branchId && {
          branchId: search.branchId,
        }),
        input: {
          active: search.active,
          withAvailableStock: search.withAvailableStock,
          ...(search.code && { code: search.code }),
          ...(search.name && { name: search.name }),
          ...(search.customerId && { customerId: search.customerId, }),
          ...(search.branchId && {
            branchId: search.branchId,
          }),
        },
      },
      onCompleted: (data) => { },
    }
  );
  const productList =
    data?.listProducts?.data !== null ? data?.listProducts?.data : null;

  const [checkedIds, setCheckedIds] = useState([]);

  // print products

  const { handleSubmit: handleSubmitSize, control: controlSize, formState: { errors: errorsSize }, reset } =
    useForm();

  const [printDialog, setPrintDialog] = useState(false);

  const closeConfirmationDialog = () => {
    setPrintDialog(false)
    reset()
  }
  const printFunc = (data) => {
    window.open(
      `${window.location.origin}/product/sticky-print/${parseInt(data.number)}/${checkedIds.toString()}`
    );
    setPrintDialog(false)
    clearCheckBox()
  }

  // table checkbox 

  const clearCheckBox = () => setCheckedIds([]);

  const currentShipmentIds = productList?.map((i) => i.id);
  const shipmentsCount = currentShipmentIds?.length;

  const fieldSelected = currentShipmentIds?.filter((i) =>
    checkedIds.includes(i)
  )?.length;

  const indeterminate = () =>
    fieldSelected > 0 && fieldSelected < shipmentsCount;

  const selectAllChecked = () =>
    shipmentsCount > 0 && fieldSelected === shipmentsCount;

  const onCheckAll = (e) => {
    let ids = [];
    if (e.target.checked) {
      const checkedAll = new Set([...checkedIds, ...currentShipmentIds]);
      ids = [...checkedAll];
    } else {
      ids = checkedIds.filter((i) => !currentShipmentIds.includes(i));
    }
    setCheckedIds(ids);
  };

  const isChecked = (id) => {
    return checkedIds.includes(id);
  };

  const toggleCheck = (e, id) => {
    const checked = e.target.checked;
    let updateCheckedIds = [...checkedIds];
    if (checked) {
      updateCheckedIds.push(id);
    } else {
      updateCheckedIds = updateCheckedIds.filter((i) => i !== id);
    }
    setCheckedIds(updateCheckedIds);
  };

  const defaultTableBody = (row, index) => (
    <TableRow key={index} className={classes.tableBodyRow}>
      <FixedTableCell className={classes.bodyCheckbox}>
        <Checkbox
          edge="start"
          checked={isChecked(row?.id)}
          onChange={(e) => toggleCheck(e, row?.id)}
          disableRipple
        />
      </FixedTableCell>
      <CellLink pathname={`/admin/products/${row?.id}`}>
        {row?.name}
      </CellLink>
      <FixedTableCell>{row.code}</FixedTableCell>
      <FixedTableCell>{row.availableQuantity}</FixedTableCell>
      <FixedTableCell>{row.weight}</FixedTableCell>
      <FixedTableCell>{row.price}</FixedTableCell>
      {!customer && (<CellLink pathname={`/admin/customers/${row?.customer?.id}`}>
        <Box
          component="span"
          fontWeight="bold"
        >{` (${row?.customer?.code}) `}</Box>
        <Box component="span">{row?.customer?.name}</Box>
      </CellLink>)}
      <FixedTableCell>
        {row?.active ? (
          <Icon className={classes.iconColor}>
            check_circle_outline
          </Icon>
        ) : (
          <Icon color="error">highlight_off</Icon>
        )}
      </FixedTableCell>
    </TableRow>
  );

  const memorizedTableBody = useMemo(
    () => defaultTableBody,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isChecked, toggleCheck]
  );

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: () => pushUrl(props, "/admin/products/create"),
      icon: Add,
      permission: "shipping.product.create",
    },
    {
      id: "print",
      title: "print",
      action: () => setPrintDialog(true),
      icon: Print,
      disabled: checkedIds.length === 0
    },
  ]

  return (
    <Root>
      <CustomDialog
        title={t("numberOfBarcode")}
        fullWidth
        maxWidth="xs"
        onClose={closeConfirmationDialog}
        content={
          <Box component={"form"} onSubmit={handleSubmitSize(printFunc)}>
            <Box>
              <ControlMUItextField
                control={controlSize}
                errors={errorsSize}
                type={"number"}
                name={"number"}
                label={t("number")}
                rules={{
                  required: t("fieldIsRequired"),
                  min: {
                    value: 1,
                    message: t("validation:min", {
                      field: t("number"),
                      number: "1",
                    }),
                  },
                }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
              <Button color="primary" type="submit">
                {t("confirm")}
              </Button>
            </Box>
          </Box>
        }
        open={printDialog}
      />
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={productList?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"name"}
                  label={t("name")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"active"}
                  label={t("active")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notActive"), value: false },
                    { key: t("active"), value: true },
                  ]}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"withAvailableStock"}
                  label={t("withAvailableStock")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notAvailable"), value: false },
                    { key: t("available"), value: true },
                  ]}
                  onChanges={(e) => {
                    const value = e.target.value === '' ? null : e.target.value
                    e.target.value === '' && setValue("branchId", "")
                    setShowBranch(value)
                  }}
                />
              </Grid>
              {showBranch !== null && (<Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  // skip={loading && filterLoad}
                  name={"branchId"}
                  label={t("branch")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>)}
              {!customer && (
                <Grid item sm={12} alignItems="flex-start">
                  <CustomAutocomplete
                    name={"customerId"}
                    label={t("customer")}
                    // skip={loading && filterLoad}
                    control={control}
                    errors={errors}
                    parseData={(data) => parseData(data)}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["customerId"],
                        "customer"
                      )
                    }
                    query={LIST_CUSTOMERS_DROPDOWN.query}
                    variables={{
                      input: {
                        active: true,
                        typeCode: ["MERCHANT"],
                      },
                    }}
                    defaultValue={autocompleteValues.customer}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          <TableHead>
            <TableRow className={classes.tableBodyRow}>
              <FixedTableCell className={classes.checkbox}>
                <Checkbox
                  edge="start"
                  id="check-all-shipment"
                  indeterminate={indeterminate()}
                  checked={selectAllChecked()}
                  inputProps={{ "aria-label": "select all desserts" }}
                  tabIndex={-1}
                  onChange={(e) => onCheckAll(e)}
                  disableRipple
                />
              </FixedTableCell>
              <FixedTableCell >
                {t("name")}
              </FixedTableCell>
              <FixedTableCell >
                {t("code")}
              </FixedTableCell>
              <FixedTableCell >
                {t("quantity")}
              </FixedTableCell>
              <FixedTableCell >
                {t("weight")}
              </FixedTableCell>
              <FixedTableCell >
                {t("price")}
              </FixedTableCell>
              {!customer && <FixedTableCell >
                {t("customer")}
              </FixedTableCell>}
              <FixedTableCell >
                {t("active")}
              </FixedTableCell>

            </TableRow>
          </TableHead>
        }
        tableBody={
          <TableBody>
            {productList &&
              productList?.map((row, index) => {
                return (
                  memorizedTableBody(row, index)
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listProducts?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listProducts ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default ProductList;
