import { AsYouType } from 'libphonenumber-js'
import countries from '../phoneNumber.json'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Globals } from '../Component/HOC/Classes/Globals';

const countryCodes = [
  'DZ', 'AR', 'BD', 'BF', 'CF', 'TD', 'CD', 'EG', 'GA', 'GH', 'GN', 'IN', 'ID', 'IR', 'IQ', 'CI', 'JO', 'KE',
  'KW', 'LB', 'LY', 'MG', 'MY', 'ML', 'MR', 'MA', 'MZ', 'NP', 'NE', 'NG', 'PK', 'PS', 'QA', 'SA', 'SN', 'SO',
  'ZA', 'SD', 'SY', 'TZ', 'TN', 'TR', 'UG', 'AE', 'YE', 'ZM', 'ZW'
];

function getMobileData(mobile, countryCode) {
  if (!mobile || mobile === "0" || !(/^[0-9+]+$/.test(mobile.replace(/\s/g, "")))) return null
  const numbersAndPlusOnly = mobile.replace(/[^\d+]/g, "");

  const allowPhoneKey = Globals.settings.allowPhoneKey
  const fullNumber = numbersAndPlusOnly.startsWith("+") ? numbersAndPlusOnly : countryCode + numbersAndPlusOnly
  const asYouType = new AsYouType()
  asYouType.input(fullNumber)
  return asYouType.getNumber().country ? {
    asYouType: asYouType,
    nationalNumber: allowPhoneKey ?
      asYouType.getNumber().nationalNumber :
      countryCodes.includes(asYouType.getNumber().country) ?
        `0${asYouType.getNumber().nationalNumber}` :
        asYouType.getNumber().nationalNumber,
    country: asYouType.getNumber().country,
    countryCallingCode: asYouType.getNumber().countryCallingCode,
  } : null
}
export default getMobileData;

export function getFormatNumber(mobile, code) {
  const country = countries.filter((c) => c.code === code.toUpperCase())
  const mob = mobile.toString()[0] === '0' ? mobile.toString().slice(1) : mobile
  const formatMobile = "+" + country[0].phone + mob
  return formatMobile
}

export function validNumber(mobile, code, notRequired) {
  if (mobile.length < 3 && notRequired) {
    return {
      valid: true,
    }
  }
  if (mobile.length < 3) {
    return {
      valid: false,
      message: "phoneNumberIsInvalid"
    }
  }
  const fullNumber = getFormatNumber(mobile, code)
  const parsedPhoneNumber = parsePhoneNumberFromString(fullNumber, code.toUpperCase());
  const country = countries.filter((c) => c.code === code.toUpperCase())
  if (parsedPhoneNumber === null || !parsedPhoneNumber.isValid()) {
    return {
      valid: false,
      message: "phoneNumberIsInvalid"
    }
  } else {
    if (country.length > 0 && country[0].exceptions) {
      const strippedPhoneNumber = fullNumber.slice(country[0].regex);
      const ifMobile = country[0].exceptions.startsWith.some(prefix => strippedPhoneNumber.startsWith(prefix))
      if (ifMobile) {
        const valid = strippedPhoneNumber.length === country[0].exceptions.length
        return {
          valid: valid,
          message: !valid && "phoneNumberIsInvalid"
        }
      } else {
        const valid = strippedPhoneNumber.length >= country[0].length && strippedPhoneNumber.length <= country[0].maxLength
        return {
          valid: valid,
          message: !valid && "phoneNumberIsInvalid"
        }
      }
    }
    return {
      valid: true
    }
  }
}
