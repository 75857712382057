import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  SwipeableDrawer,
  Typography,
  useTheme,
} from "@mui/material";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Done, DoneAll } from "@mui/icons-material";
import { Alert } from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import {
  APPROVE_MANIFEST_MUTATION,
  MANIFEST_BY_ID_QUERY,
  MANIFEST_VIEW_PRODUCT_DATA,
  SAVE_MANIFEST_MUTATION,
} from "./Graphql";
import ProductList from "./ManifestProducts/ProductList";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import NotFound from "../../Error/NotFound";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
  LIST_WAREHOUSE_MANIFEST_DROPDOWN
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CustomButton from "../HOC/MUI/CustomButton";
import BarcodeSelector from "./ManifestProducts/BarcodeSelector";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import useWarehouseManifestDetails from "./WarehouseManifestDetails";
import ProductsTable from "../HOC/CustomComponents/ProductsTable";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import TitleAppBar from "../../Layout/TitleAppBar";
import config from "../../config.json";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { selectedIdIsValid } from "../HOC/CustomFunctions/selectedIsValid";

const PREFIX = "ManifestForm";

const classes = {
  track: `${PREFIX}-track`,
  typo: `${PREFIX}-typo`,
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  productList: `${PREFIX}-productList`,
  barcode: `${PREFIX}-barcode`,
  button: `${PREFIX}-button`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  margin: `${PREFIX}-margin`,

  errorColor: `${PREFIX}-errorColor`,
  overlay: `${PREFIX}-overlay`,
  pagination: `${PREFIX}-pagination`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.productList}`]: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.toolbar}`]: {
    backgroundColor: "#fff",
    border: "solid 1px #eee",
    zIndex: "1300",
    justify: "space-between",
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.errorColor}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.pagination}`]: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const ManifestForm = (props) => {
  const formType = props.match.params.type?.toUpperCase();
  const [pathURL, setPathURL] = useState(props.match.path)

  const { t } = useTranslation();

  const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

  const [manifestType, setManifestType] = useState(formType ? formType : null);
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    deliveryAgent: null,
    transactionType: null,
    cancellationReason: null,
    referenceBranch: null,
    route: null,
    fromManifest: null,
  });

  const theme = useTheme();
  const [disableForm, setDisableForm] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    open: false,
    edit: false,
    approve: false,
  });
  const [manifestData, setManifestData] = useState();

  const manifestId = props.match.params.id;

  const [fieldsState, setFieldsState] = useState({
    createdAt: new Date(),
  });
  const { enqueueSnackbar } = useSnackbar();
  // const typeCode = [
  //   "DEX",
  //   "PKD",
  //   "RTS",
  //   "RJCT",
  //   "RTRN",
  //   "HTR",
  //   "DTR",
  //   "PKR",
  // ].find((i) => i === formType);

  // const defaultTransactionField = `default${typeCode}TransactionType`;

  // const SETTINGS = gqlb.query({
  //   operation: "shippingSettings",
  //   fields: [{ [defaultTransactionField]: ["id", "name"] }],
  //   variables: {},
  // });

  // const chooseCustomerPermission = Globals.user.hasPermission(
  //   "shipping.shipment.choose_customer"
  // );
  // const { data: settings } = useQuery(
  //   gql`
  //     ${SETTINGS.query}
  //   `,
  //   {
  //     fetchPolicy: "no-cache",
  //     skip: !chooseCustomerPermission || !typeCode,
  //     onCompleted: (data) => {
  //       const defaultTransactionType =
  //         data?.shippingSettings?.[defaultTransactionField];
  //       setAutocompleteValues((prev) => ({
  //         ...prev,
  //         transactionType: defaultTransactionType,
  //       }));
  //     },
  //   }
  // );

  const [saveManifetMutation, { loading: saveManifestLoad }] = useMutation(
    gql`
      ${SAVE_MANIFEST_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {


        setManifestData(data["saveWarehouseManifest"]);
      },
    }
  );


  // const sendWhatsappMessageStorage = localStorage.getItem(
  //   "sendWhatsappMessageStorage"
  // );
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
  } = useForm();

  /******************************************* Start Pagination Functions ********************************************/

  const [productData, setProductData] = useState({
    page: 0,
    total: 0,
    rowsPerPage: config.app.pageSize,
    pickedProduct: [],
    noData: true,
  });


  const handleChangePage = (event, newPage) => {
    setProductData((prev) => {
      return {
        ...prev,
        page: newPage,
      };
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setProductData((prev) => {
      return {
        ...prev,
        page: 0,
        rowsPerPage: event.target.value,
      };
    });
  };


  /******************************************* End Pagination Functions ********************************************/

  /******************************************* Start Mutations ********************************************/
  const [approveManifetMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_MANIFEST_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setManifestData(data["approveWarehouseManifest"]),
    }
  );

  const onCompleteFromManifest = (data, name) => {
    const currentValue = watch(`${name}`);

    if (typeof currentValue === "number") {
      const isValueValid = selectedIdIsValid(data, currentValue);

      if (!isValueValid) {
        setValue(`${name}`, "");
      }
    }
  };


  /******************************************* End Mutations ********************************************/

  /******************************************* Start Manifest By Id ********************************************/

  const supportedTransactionType = [
    "PADD",
    "PMBT",
    "PRTRN",
    "PMBR",
  ];
  const { data: manifestByIdQuery, loading: manifestByIdLoad, refetch: manifestRefetch } = useQuery(
    gql`
      ${MANIFEST_BY_ID_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !manifestId,
      variables: {
        id: manifestId ? parseInt(manifestId) : watch("id"),
      },
      onCompleted: (data) => {
        if (
          data?.warehouseManifest === null ||
          !supportedTransactionType.some(
            (i) => i === data?.warehouseManifest?.transactionType?.type?.code
          )
        ) {
        } else {
          const warehouseManifest = data?.warehouseManifest;
          setManifestType(warehouseManifest?.transactionType?.type.code);

          setManifestData(warehouseManifest);
          setValue("id", parseInt(warehouseManifest?.id));
          setValue("code", warehouseManifest?.code);
          setValue("date", warehouseManifest?.date);

          warehouseManifest?.notes && setValue("notes", warehouseManifest?.notes);

          warehouseManifest?.transactionType?.code &&
            setFieldsState((prev) => ({
              ...prev,
              createdAt: warehouseManifest?.date,
            }));
          productData.noData && getProductData()
          setAutocompleteValues({
            branch: warehouseManifest.branch,
            ...(warehouseManifest?.customer && {
              customer: warehouseManifest?.customer,
            }),
            ...(warehouseManifest?.referenceBranch && {
              referenceBranch: warehouseManifest?.referenceBranch,
            }),
            ...(warehouseManifest?.fromManifest && {
              fromManifest: warehouseManifest?.fromManifest,
            }),
            transactionType: warehouseManifest.transactionType,
          });
          setDisableForm(true);
        }
      },
    }
  );

  const [getProductData, { loading: loadingProductData, refetch: refetchProductDataQuery }] = useLazyQuery(
    gql`
        ${MANIFEST_VIEW_PRODUCT_DATA().query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: manifestId ? parseInt(manifestId) : watch("id"),
        page: productData.page + 1,
        first: productData.rowsPerPage,
      },
      onCompleted: (data) => {

        if (data?.warehouseManifest === null) return;
        const warehouseManifest = data?.warehouseManifest;
        const product = warehouseManifest.manifestProducts.data
        setProductData(prev => {
          return {
            ...prev,
            pickedProduct: product,
            total: warehouseManifest.manifestProducts.paginatorInfo.total,
            noData: false,
          }
        })
        manifestRefetch()
      },
    }
  );


  const { details: manifestDetails } = useWarehouseManifestDetails({
    approved: manifestData?.approved,
    typeData: manifestData,
    type: manifestType,
    saveManifestLoad,
    setProductData,
    refetchProductDataQuery,
  });
  const manifestStatus = manifestDetails;
  const [state, setState] = useState({
    productList: {
      left: false,
      bottom: false,
      right: false,
    },
    manifestList: {
      left: false,
      bottom: false,
      right: false,
    },
  });
  const [barcodeDarwerState, setBarcodeDarwerState] = useState({
    left: false,
    bottom: false,
    right: false,
  });

  const productDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      productList: { ...prev.productList, [anchor]: open },
    }));
  };

  const barcodeDrawerHandler = (anchor, open) => {
    setBarcodeDarwerState((prev) => ({
      ...prev,
      [anchor]: open,
    }));
  };

  const screenWidth = useWidth();

  const anchor = screenWidth === "xs" ? "bottom" : "right";

  const history = useHistory();
  ////////////////////END DRAWER////////////////

  /******************************************* Start OnSubmit ********************************************/
  const isFormValuesChange = (data) => {
    if (manifestData) {
      if (manifestType === "PADD") {
        return data?.customerId !== manifestData?.customer?.id
      }
      if (manifestType === "PRTRN") {
        return data?.customerId !== manifestData?.customer?.id || data?.branchId !== manifestData?.branch?.id
      }
      if (manifestType === "PMBR") {
        return (data?.branchId || data?.fromManifestId || data?.referenceBranchId)
          && (data?.branchId !== manifestData?.branch?.id ||
            data?.fromManifestId !== manifestData?.fromManifest?.id ||
            data?.referenceBranchId !== manifestData?.referenceBranch?.id)
      }
      if (manifestType === "PMBT") {
        return (data?.branchId || data?.fromManifestId) && (data?.branchId !== manifestData?.branch?.id || data?.fromManifestId !== manifestData?.fromManifest?.id)
      }
    }
  };

  const onSubmit = (data) => {
    data["date"] = dateFormat(data["date"]);
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    if (productData.pickedProduct.length > 0 && isFormValuesChange(data)) {
      setOpenDialog((prev) => ({ ...prev, open: true, edit: true, data: data }));
      return;
    }

    const newManifest = {
      ...data,
    };

    saveProductMutationFun(newManifest)
  };

  const saveProductMutationFun = (data, manifestRefetch) => {
    saveManifetMutation({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        setDisableForm(true);
        setValue("code", data?.data?.saveWarehouseManifest?.code);
        setValue("id", parseInt(data?.data?.saveWarehouseManifest?.id));
        // if (!props.match.params.id) {
        //   data?.data?.saveManifest.customer?.id && setValue("customerId", "");
        //   data?.data?.saveManifest.deliveryAgent?.id &&
        //     setValue("deliveryAgentId", "");
        //   setValue("transactionTypeId", "");
        //   setValue("branchId", "");
        // }
        const url = history.createHref({
          pathname: `/admin/warehouse-manifests/${data?.data?.saveWarehouseManifest?.id}/edit`,
        });
        setPathURL(url)
        windowReplaceUrl(url);
        manifestRefetch && manifestRefetch()
        manifestRefetch && refetchProductDataQuery()

        // props.history.replace(
        //   `/admin/manifests//edit`
        // );
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  }
  /******************************************* End OnSubmit ********************************************/

  const handleDialogClose = () => {
    setOpenDialog({ edit: false, open: false, approve: false });
  };

  // const whatsappValidation =
  //   (manifestType === "RITS" || manifestType === "OTD") && openDialog.approve;

  const shipLength = productData?.pickedProduct?.length === 0;

  const approveManifest = () => {
    approveManifetMutation({
      variables: {
        id: parseInt(watch("id")),
        // ...(whatsappValidation && {
        //   sendWhatsappMessage: watch("sendWhatsappMessage"),
        // }),
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("recordConfirmationSucceededMessage"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        pushUrl(props, `/admin/warehouse-manifests/${watch("id")}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, approve: true }));
  };

  const parseData = (data) => {
    return data;
  };

  /******************************************* Start Fields By Type ********************************************/

  const customerField = (type) => {
    if (type) {
      return (
        <Grid
          sm={manifestType === "PADD" || manifestType === "PRTRN" ? 6 : 4}
          xs={12}
        >
          <CustomAutocomplete
            control={control}
            errors={errors}
            rules={{
              required: t("fieldIsRequired"),
            }}
            name={"customerId"}
            label={t("customer")}
            disabled={!watch("branchId")}
            parseData={(data) => parseData(data)}
            query={LIST_CUSTOMERS_DROPDOWN.query}
            skip={!watch("branchId")}
            variables={{
              input: {
                active: true,
                warehousing: true,
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            defaultValue={autocompleteValues.customer}
          />
        </Grid>
      );
    }
  };

  const branchTransferField = (type) => {
    const branchData = (data) => {
      const parsed = data?.filter((i) => i.id !== watch("branchId"));
      return parsed;
    };
    if (type === "PMBT" || type === "PMBR") {
      return (
        <Grid sm={manifestType === "PMBT" ? 6 : 4} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"referenceBranchId"}
            label={type === "PMBT" ? t("toBranch") : t("fromBranch")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => branchData(data)}
            query={LIST_BRANCHES_DROPDOWN.query}
            variables={{
              input: {
                all: true
              },
            }}
            defaultValue={autocompleteValues.referenceBranch}
          />
        </Grid>
      );
    }
  };

  const referencetransactionTypeCode = (type) => {
    const parseData = (data) => {
      const parsed = data?.filter((i) => i.name = i.code);
      return parsed;
    };
    if (autocompleteValues.fromManifest) {
      autocompleteValues.fromManifest.name = autocompleteValues.fromManifest.code;
    }
    if (type === "PMBR") {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            hideCode={true}
            name={"fromManifestId"}
            label={t("fromManifestId")}
            disabled={!watch("referenceBranchId") || !watch("branchId")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            skip={!watch("branchId") || !watch("referenceBranchId")}
            variables={{
              input: {
                transactionTypeCode: ['PMBT'],
                withPendingReceiveInBranchId: watch("branchId"),
                branchId: watch("referenceBranchId")
              },
            }}
            onCompleted={(data) => {
              let filterData = data
              if (manifestId && watch("branchId") === autocompleteValues.branch.id && autocompleteValues.referenceBranch.id === watch("referenceBranchId")) {
                const checker = data?.listWarehouseManifestsDropdown?.filter(manifest => manifest.id === autocompleteValues?.fromManifest.id)
                if (!checker.length) {
                  filterData.listWarehouseManifestsDropdown.push(autocompleteValues?.fromManifest)
                }
              }
              onCompleteFromManifest(filterData, "fromManifestId")
            }}
            query={LIST_WAREHOUSE_MANIFEST_DROPDOWN.query}
            defaultValue={autocompleteValues.fromManifest}
          />
        </Grid>
      );
    }
  };

  // const whatsappSwitch = (
  //   <MuiSwitch
  //     sx={{
  //       flex: "100% 1 1",
  //       padding: (theme) => theme.spacing(0, 1),
  //     }}
  //     edge="end"
  //     name="sendWhatsappMessage"
  //     label={t("sendWhatsappMessage")}
  //     control={control}
  //     onChange={(event) => {
  //       localStorage.setItem(
  //         "sendWhatsappMessageStorage",
  //         event.target.checked
  //       );
  //     }}
  //   />
  // );

  /******************************************* End Fields By Type ********************************************/

  let formBody = null;
  let tableBody = null;

  tableBody = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          classes={classes}
          selectedProduct={productData.total}
          manifestType={manifestType}
          addProduct={() => productDrawerHandler(anchor, true)}
          barcode={() => barcodeDrawerHandler(anchor, true)}
          approved={manifestData?.approved}
        />

        {!loadingProductData && manifestStatus && (
          <TableFixedHeaderWraper>
            <ProductsTable
              data={productData?.pickedProduct}
              headCells={manifestStatus?.tableHead}
              parseBodyCell={manifestStatus?.tableBody}
              total={manifestStatus.tableTotal}
              sumRow={manifestStatus?.sum}
              sumTableNumber={manifestStatus?.sumTableNumber}
            />
          </TableFixedHeaderWraper>
        )}
        {productData?.total !== 0 && (
          <MUITablePagination
            count={productData.total}
            rowsPerPage={productData?.rowsPerPage}
            page={productData?.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        {(!watch("id") || !disableForm) && (
          <Box className={classes.overlay}>
            <Typography color="textPrimary" variant="body1">
              {t("saveWarehouseManifestRequired")}
            </Typography>
          </Box>
        )}
        <Grid container justifyContent="center" alignItems="center">
          {loadingProductData ? (
            <FullScreenLoading height={"327px"} />
          ) : (
            productData?.total === 0 && watch("id") && disableForm && (
              <EmptyTableMessage
                message={t("noResult")}
              />
            ))}
        </Grid>
      </Paper>
    </Grid>
  );

  const formCondition = !formType
    ? Boolean(watch("id")) && !manifestByIdLoad
    : !manifestByIdLoad;

  // const handleOptionRemoval = () => {
  //   setAutocompleteValues((prevValues) => ({
  //     ...prevValues,
  //     branch: null, // Set branch to null
  //     // You can set other fields to null or update them as needed
  //   }));
  // };

  formBody = (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
      sx={{ padding: "24px", width: "100%", margin: "0" }}
    >
      {formCondition && manifestType ? (
        <Paper container component={Grid} sx={{ padding: 1, flexGrow: 1 }}>
          {watch("id") && disableForm && (
            <Grid sm={12} xs={12}>
              <Alert
                severity="warning"
                action={
                  !manifestData?.approved && (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setDisableForm(false);
                        // fireMutaion([])
                      }}
                    >
                      {t("update")}
                    </Button>
                  )
                }
              >
                {manifestData?.approved
                  ? t("updateRecordsForbidden")
                  : t("updateRecord")}
              </Alert>
            </Grid>
          )}

          <Grid
            spacing={2}
            container
            sx={{ position: "relative", flexGrow: 1 }}
          >
            {(manifestData?.approved || disableForm) && (
              <Box
                className={classes.overlay}
              >
                {/* <Typography color="textPrimary" variant="body1" >لا يمكن التعديل</Typography> */}
              </Box>
            )}
            <Grid sm={4} xs={12}>
              <ControlMUItextField
                name="code"
                control={control}
                label={t("manifestCode")}
                rules={{ required: watch("id") ? t("fieldIsRequired") : false }}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <MUIDateTime
                name="date"
                label={t("theDate")}
                control={control}
                defaultValue={manifestByIdQuery?.manifest?.date}
                value={fieldsState.createdAt}
                onChange={(e) =>
                  setFieldsState((prev) => ({ ...prev, createdAt: e }))
                }
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <ListBranches
                control={control}
                errors={errors}
                name={"branchId"}
                rules={{ required: t("fieldIsRequired") }}
                skip={manifestType === null}
                defaultValue={autocompleteValues.branch}
                skipDefaultBranch={Boolean(manifestId)}
                onChangeValue={(e) => {
                  manifestType === "PMBR" && setValue("fromManifestId", "");
                  (manifestType === "PMBR" || manifestType === "PMBT" || manifestType === "PADD" || manifestType === "PRTRN") &&
                    setValue("transactionTypeId", "");
                  (manifestType === "PMBR" || manifestType === "PMBT") &&
                    watch("referenceBranchId") === e.id && setValue("referenceBranchId", "");
                }}
              />
            </Grid>
            <Grid
              sm={
                manifestType === "PADD" ||
                  manifestType === "PMBT" ||
                  manifestType === "PRTRN"
                  ? 6
                  : 4
              }
              xs={12}
            >
              <CustomAutocomplete
                control={control}
                errors={errors}
                selectFirst={true}
                name={"transactionTypeId"}
                label={t("transactionType")}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                variables={{
                  input: {
                    type: manifestType,
                    ...(watch("branchId") && {
                      branchId: {
                        value: watch("branchId"),
                        includeNull: true,
                      },
                    }),
                  },
                }}
                defaultValue={autocompleteValues.transactionType}
              />
            </Grid>
            {/* {deliveryAgentField(manifestType)}
            {routeField(manifestType)} */}
            {customerField(manifestStatus?.customer)}
            {branchTransferField(manifestType)}
            {referencetransactionTypeCode(manifestType)}
            <Grid sm={12} xs={12}>
              <ControlMUItextField
                name="notes"
                control={control}
                label={t("notes")}
                rows={2}
              />
            </Grid>
          </Grid>

          <Grid sm={12} xs={12} container justifyContent="flex-end">
            {!disableForm && (
              <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                className={classes.button}
                disabled={
                  manifestData?.approved || disableForm || saveManifestLoad
                }
                variant="contained"
                size="medium"
                loading={saveManifestLoad}
                // className={classes.button}
                startIcon={!saveManifestLoad && <Done />}
              >
                {!saveManifestLoad && t("save")}
              </CustomButton>
            )}
            {disableForm && (
              <Button
                disabled={
                  shipLength || manifestData?.approved || saveManifestLoad
                }
                className={classes.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={onApproved}
                name="approved"
                startIcon={<DoneAll />}
              >
                {t("approve")}
              </Button>
            )}
          </Grid>
        </Paper>
      ) : (
        <FullScreenLoading />
      )}
    </Grid>
  );

  // const validCondetion =
  //   manifestByIdQuery?.warehouseManifest !== undefined &&
  //   !supportedTransactionType.some(
  //     (i) => i === manifestByIdQuery?.warehouseManifest?.transactionType?.type?.code
  //   );

  return manifestByIdLoad ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !manifestByIdQuery && manifestId ? (
      <NotFound />
    ) : // <Can permission={pagePermission}>
      (

        <Root>
          <TitleAppBar path={pathURL} type={manifestType} />
          {props.children}

          {formBody}

          <Dialog
            open={openDialog.open}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"xs"}
          >
            <DialogTitle id="alert-dialog-title" color="text.secondary">
              {openDialog.edit && t("updateRecord")}
              {openDialog.approve && t("approveRecord")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {openDialog.edit && t("updateWarehouseManifestWarningMessage")}
                {openDialog.approve && t("approveRecordConfirmationMessage")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* {whatsappValidation && whatsappSwitch} */}

              <Button onClick={handleDialogClose} color="primary">
                {t("cancel")}
              </Button>
              <Button
                disabled={approveLoad}
                onClick={() => {
                  openDialog.edit && handleDialogClose();
                  openDialog.edit && setDisableForm(true);
                  openDialog.edit && saveProductMutationFun(openDialog.data, manifestRefetch);
                  openDialog.approve && approveManifest();
                }}
                color="primary"
                autoFocus
              >
                {openDialog.edit && t("update")}
                {openDialog.approve && !approveLoad && t("approve")}
                {approveLoad && <ButtonLoading />}
              </Button>
            </DialogActions>
          </Dialog>

          <SwipeableDrawer
            variant="persistent"
            disableDiscovery={true}
            disableSwipeToOpen={true}
            anchor={anchor}
            open={state.productList[anchor]}
            onClose={() => false}
            onOpen={() => productDrawerHandler(anchor, true)}
            classes={{ paper: classes.productList }}
            dir={theme.direction}
          >
            {state.productList[anchor] && (
              <ProductList
                refetchProductDataQuery={refetchProductDataQuery}
                type={manifestType}
                done={() => productDrawerHandler(anchor, false)}
                variables={watch()}
              />
            )}
          </SwipeableDrawer>

          <SwipeableDrawer
            disableDiscovery={true}
            disableSwipeToOpen={true}
            anchor={anchor}
            open={barcodeDarwerState[anchor]}
            onClose={() => false}
            onOpen={() => barcodeDrawerHandler(anchor, true)}
            sx={{
              width: "auto",
              "& .MuiDrawer-paper": {
                width: { md: "50%" },

                height: "100%",
                overflow: "hidden",
              },
            }}
            dir={theme.direction}
          >
            {barcodeDarwerState[anchor] && <BarcodeSelector
              type={manifestType}
              done={() => barcodeDrawerHandler(anchor, false)}
              variables={watch()}
            />}
          </SwipeableDrawer>

          <Grid
            container
            spacing={3}
            justifyContent="center"
            className={classes.mainTracking}
          >
            {formCondition && tableBody}
          </Grid>
        </Root>
      );
};

export default ManifestForm;
