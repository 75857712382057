import React, { useState } from "react";

import { styled } from '@mui/material/styles';

import { useQuery, gql } from "@apollo/client";
import {
  Grid,
  IconButton,
  Tooltip,
  TableBody,
  TableRow,
  Icon,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_ROLES } from "./Graphql";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";

const PREFIX = 'ListRoles';

const classes = {
  title: `${PREFIX}-title`,
  paper: `${PREFIX}-paper`,
  main: `${PREFIX}-main`,
  mainContainer: `${PREFIX}-mainContainer`,
  iconColor: `${PREFIX}-iconColor`,
  spanStyle: `${PREFIX}-spanStyle`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.paper}`]: {
    display: "grid",
    width: "100%",
    borderRadius: 0,
  },

  [`& .${classes.main}`]: {
    marginTop: theme.spacing(2),
  },

  [`&.${classes.mainContainer}`]: {
    width: "100%",
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.spanStyle}`]: {
    display: "inline-block",
    margin: "5px",
  }
}));

const ListRoles = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const { data, loading } = useQuery(
    gql`
      ${LIST_ROLES.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
      },
      onCompleted: (data) => { },
    }
  );
  const listRoles =
    data?.listRoles?.data !== null ? data?.listRoles?.data : null;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const icons = [
    {
      id: "add",
      title: "createNew",
      action: () => pushUrl(props, `/admin/roles/create`),
      icon: Add,
      permission: "core.role.create",
    },
  ]

  return <StyledGrid>
    <ListWrapper
      drawerState={false}
      icons={icons}
      path={props.match.path}
      empty={listRoles?.length === 0}
      loading={loading}
      filters={false}
      tableHeaders={
        [
          {
            name: "name"
          },
          {
            name: "code"
          },
          {
            name: "users"
          },
          {
            name: ""
          },
        ]
      }
      tableBody={
        <TableBody>
          {listRoles &&
            listRoles?.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <CellLink pathname={`/admin/roles/${row?.id}`}>
                    {row?.name}
                  </CellLink>
                  <FixedTableCell>{row?.code}</FixedTableCell>
                  <FixedTableCell>{row?.usersCount}</FixedTableCell>
                  <FixedTableCell>
                    <SecuredNavLink
                      permission="core.role.create"
                      to={{
                        pathname: `roles/${row.id}/copy`,
                      }}
                    >
                      <Tooltip title="copy role">
                        <IconButton aria-label="copy role" size="large">
                          <Icon>content_copy</Icon>
                        </IconButton>
                      </Tooltip>
                    </SecuredNavLink>
                  </FixedTableCell>
                </TableRow>
              );
            })}
        </TableBody>
      }
      pagination={
        <MUITablePagination
          count={data?.listRoles?.paginatorInfo?.total}
          rowsPerPage={rowsPerPage}
          page={!data?.listRoles ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    />
  </StyledGrid>;
};

export default ListRoles;
