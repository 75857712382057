import * as gqlb from "gql-query-builder";

export const LIST_COLLECTIONS_QUERY = (ALLOWED_CONCIERGE, ALLOWED_SHIPMENT) => gqlb.query([
  {
    operation: "listCollections",
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
          "count",
          "currentPage",
          "total",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage",
        ],
        variables: {},
      },
      {
        operation: "data",
        fields: [
          "code",
          "id",
          "date",
          "approved",
          "glApproved",
          "notes",
          {
            operation: "transactionType",
            fields: ["id", "name"],
            variables: {},
          },
          {
            operation: "safe",
            fields: ["id", "name"],
            variables: {},
          },
          {
            operation: "deliveryAgent",
            fields: ["name", "id"],
            variables: {},
          },
          {
            customer: ["name", "id"],
          },
          {
            updatedBy: ["username", "id"],
          },
          {
            createdBy: ["username", "id"],
          },
          {
            operation: `sumEntries`,
            fields: [
              "collectedAmount",
              "collectedFees",
              "commissionAmount",
              "deliveredAmount",
              "returningDueFees",
              "totalAmount",
            ],
            variables: {},
          },
          {
            operation: `entriesShipment:entries(typeCode: SHIPMENT) @include(if: ${ALLOWED_SHIPMENT})`,
            fields: [
              {
                paginatorInfo: ["total"]
              }
            ],
            variables: {},
          },
          {
            operation: `entriesConcierge:entries(typeCode: CONCIERGE_REQUEST) @include(if: ${ALLOWED_CONCIERGE})`,
            fields: [
              {
                paginatorInfo: ["total"]
              }
            ],
            variables: {},
          },
        ],
        variables: {},
      },
    ],
    variables: {
      input: {
        type: "ListCollectionFilterInput",
        required: true,
      },
      first: {
        type: "Int",
        required: true,
        value: 15,
      },
      page: {
        type: "Int",
        value: 1,
      },
    },
  },
  {
    operation: "sumCollections",
    fields: ["collectedAmount", "shipmentCount", "commissionAmount", "conciergeRequestCount"],
    variables: {
      input: {
        type: "ListCollectionFilterInput",
        required: true,
      },
    },
  },
]);

export const collectionFields = [
  "id",
  "date",
  "code",
  "approved",
  "glApproved",
  "notes",
  {
    type: ["code"],
  },
  {
    createdBy: ["username", "id"],
  },
  {
    updatedBy: ["username", "id"],
  },
  {
    customer: ["id", "name", "code", "address"],
  },
  {
    operation: "branch",
    fields: ["id", "name"],
    variables: {},
  },
  {
    transactionType: ["id", "code", "name", { type: ["name", "code"] }],
  },
  {
    operation: "deliveryAgent",
    fields: ["id", "name", "code", "autoPayCommission"],
    variables: {},
  },
  {
    operation: "safe",
    fields: ["id", "name"],
    variables: {},
  },
  {
    operation: `sumEntries`,
    fields: [
      "collectedAmount",
      "commissionAmount",
    ],
    variables: {},
  },
  {
    operation: `sumEntriesShipment:sumEntries(typeCode: SHIPMENT)`,
    fields: [
      "collectedAmount",
      "commissionAmount",
      "returningDueFees",
      "collectedFees",
      "totalAmount",
      "deliveredAmount",
      "piecesCount",
      "weight",
    ],
    variables: {},
  },
  {
    operation: `sumEntriesConcierge:sumEntries(typeCode: CONCIERGE_REQUEST)`,
    fields: [
      "collectedAmount",
      "commissionAmount",
      "returningDueFees",
      "collectedFees",
      "totalAmount",
      "deliveredAmount",
    ],
    variables: {},
  },
];
 export const conciergeRequest =[
  "collectedAmount",
  "commission",
  {
    operation: "conciergeRequest",
    fields: [
      "id",
      "code",
      "accountNumber",
      {
        branch: ["id", "name"]
      },
      {
        customer: ["id", "name"]
      },
    ],
    variables: {}
  },
 ]
export const COLLECTION_BY_ID_QUERY = (ALLOWED_CONCIERGE, ALLOWED_SHIPMENT) => gqlb.query({
  operation: "collection",
  fields: [
    "id",
    "date",
    "code",
    "approved",
    "glApproved",
    "notes",
    {
      type: ["code"],
    },
    {
      customer: ["id", "name", "code", "address"],
    },
    {
      operation: "branch",
      fields: ["id", "name"],
      variables: {},
    },
    {
      transactionType: ["id", "code", "name", { type: ["name", "code"] }],
    },
    {
      operation: "deliveryAgent",
      fields: ["id", "name", "code", "autoPayCommission"],
      variables: {},
    },
    {
      operation: "safe",
      fields: ["id", "name"],
      variables: {},
    },

    {
      operation: `entriesConcierge:entries(typeCode: CONCIERGE_REQUEST) @include(if: ${ALLOWED_CONCIERGE})`,
      fields: [
        {
          paginatorInfo: ["total"]
        },
      ],
      variables: {},
    },
    {
      operation: `entriesShipment:entries(typeCode: SHIPMENT) @include(if: ${ALLOWED_SHIPMENT})`,
      fields: [
        {
          paginatorInfo: ["total"]
        },
      ],
      variables: {},
    },
    {
      operation: `sumEntriesShipment:sumEntries(typeCode:SHIPMENT) @include(if: ${ALLOWED_SHIPMENT})`,
      fields: [
        "collectedAmount",
        "commissionAmount",
        "returningDueFees",
        "collectedFees",
        "totalAmount",
        "deliveredAmount",
        "piecesCount",
        "weight"
      ],
      variables: {},
    },
    {
      operation: `sumEntriesConcierge:sumEntries(typeCode:CONCIERGE_REQUEST) @include(if: ${ALLOWED_CONCIERGE})`,
      fields: [
        "collectedAmount",
        "commissionAmount",
        "returningDueFees",
        "collectedFees",
        "totalAmount",
        "deliveredAmount",
      ],
      variables: {},
    },
    {
      operation: `sumEntries`,
      fields: [
        "collectedAmount",
        "commissionAmount",
      ],
      variables: {},
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
  
 
export const GET_COLLECTION_TYPE = gqlb.query({
  operation: "collection",
  fields: [{
    type: ["code"],
  },],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});



export const COLLECTION_VIEW = (permission, ALLOWED_CONCIERGE, ALLOWED_SHIPMENT) =>
  gqlb.query([
    {
      operation: "collection",
      fields: [
        "id",
        "date",
        "code",
        "approved",
        "glApproved",
        "notes",
        {
          type: ["code"],
        },
        {
          createdBy: ["username", "id"],
        },
        {
          operation: `journalEntry @include(if: ${permission})`,
          fields: ["code", "id"],
          variables: {},
        },
        {
          updatedBy: ["username", "id"],
        },
        {
          customer: ["id", "name", "code", "address"],
        },
        {
          operation: "branch",
          fields: ["id", "name"],
          variables: {},
        },
        {
          transactionType: ["id", "code", "name", { type: ["name", "code"] }],
        },
        {
          operation: "deliveryAgent",
          fields: ["id", "name", "code", "autoPayCommission"],
          variables: {},
        },
        {
          operation: "safe",
          fields: ["id", "name"],
          variables: {},
        },

        {
          operation: `sumEntries`,
          fields: [
            "collectedAmount",
            "collectedFees",
            "commissionAmount",
            "deliveredAmount",
            "returningDueFees",
            "totalAmount",
          ],
          variables: {},
        },
        {
          operation: `entriesConcierge:entries(typeCode: CONCIERGE_REQUEST) @include(if: ${ALLOWED_CONCIERGE})`,
          fields: [
            {
              paginatorInfo: ["total"]
            },
          ],
          variables: {},
        },
        {
          operation: `entriesShipment:entries(typeCode: SHIPMENT) @include(if: ${ALLOWED_SHIPMENT})`,
          fields: [
            {
              paginatorInfo: ["total"]
            },
          ],
          variables: {},
        },
        {
          operation: `sumEntriesShipment:sumEntries(typeCode:SHIPMENT) @include(if: ${ALLOWED_SHIPMENT})`,
          fields: [
            "collectedAmount",
            "commissionAmount",
            "returningDueFees",
            "collectedFees",
            "totalAmount",
            "deliveredAmount",
            "piecesCount",
            "weight"
          ],
          variables: {},
        },
        {
          operation: `sumEntriesConcierge:sumEntries(typeCode:CONCIERGE_REQUEST) @include(if: ${ALLOWED_CONCIERGE})`,
          fields: [
            "collectedAmount",
            "commissionAmount",
            "returningDueFees",
            "collectedFees",
            "totalAmount",
            "deliveredAmount",
          ],
          variables: {},
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  ]);

export const COLLECTION_VIEW_CONCIERGE_DATA =
  gqlb.query(
    {
      operation: "collection",
      fields: [
        {
          operation: "entries",
          fields: [
            {
              operation: "paginatorInfo",
              fields: [
                "count",
                "currentPage",
                "total",
                "firstItem",
                "hasMorePages",
                "lastItem",
                "lastPage",
                "perPage",
              ],
              variables: {},
            },
            {
              operation: "data",
              fields: [
                "commission",
                "collectedAmount",
                {
                  operation: "conciergeRequest",
                  fields: [
                    "id",
                    "code",
                    "accountNumber",
                    {
                      branch: ["id", "name"]
                    },
                    {
                      customer: ["id", "name"]
                    },
                  ],
                  variables: {}
                }
              ],
              variables: {},
            },
          ],
          variables: {
            typeCode: {
              type: "CollectionEntryTypeCode",
            },
            first: {
              type: "Int",
            },
            page: {
              type: "Int",
            },
          },
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  );

export const COLLECTION_VIEW_SHIPMENT_DATA = (shipmentQueryFields) =>
  gqlb.query([
    {
      operation: "collection",
      fields: [
        {
          operation: `entries`,
          fields: [
            {
              operation: `paginatorInfo`,
              fields: [
                "count",
                "currentPage",
                "total",
                "firstItem",
                "hasMorePages",
                "lastItem",
                "lastPage",
                "perPage"
              ],
              variables: {}
            },
            {
              operation: `data`,
              fields:[...shipmentQueryFields],
              variables: {}
            }
          ],
          variables: {
            typeCode: {
              type: "CollectionEntryTypeCode",
            },
            first: {
              type: "Int",
            },
            page: {
              type: "Int",
            },
          },
        },
      ],
      variables: {
        id: {
          type: "Int",
          required: true,
        },
      },
    },
  ]);

export const SAVE_COLLECTION_MUTATION = gqlb.mutation({
  operation: "saveCollection",
  fields: [...collectionFields],
  variables: {
    input: {
      type: "CollectionInput",
      required: true,
    },
  },
});

export const SAVE_COLLECTION_ENTRIES_MUTATION = gqlb.mutation({
  operation: "createCollectionEntries",
  fields: [{
    collection: ["id"]
  }],
  variables: {
    input: {
      type: "CollectionEntriesInput",
      required: true,
    },
  },
});

export const DELETE_COLLECTIONS_MUTATION = gqlb.mutation({
  operation: "deleteCollection",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const DELETE_COLLECTION_ENTRIES_MUTATION = gqlb.mutation({
  operation: "deleteCollectionEntries",
  fields: [],
  variables: {
    input: {
      type: "DeleteCollectionEntriesInput",
      required: true,
    },
  },
});

export const APPROVE_COLLECTION_MUTATION = gqlb.mutation({
  operation: "approveCollection",
  fields: collectionFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DISAPPROVE_COLLECTION_MUTATION = gqlb.mutation({
  operation: "disapproveCollection",
  fields: ["id"],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const GL_APPROVE_COLLECTION_MUTATION = gqlb.mutation({
  operation: "glApproveCollection",
  fields: collectionFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const collectionEntriesFieldsPrint = [
  "collectedAmount",
  "commission",
  {
    operation: "conciergeRequest",
    fields: [
      "id",
      "code",
      "accountNumber",
      {
        branch: ["id", "name"]
      },
      {
        customer: ["id", "name"]
      },
    ],
    variables: {}
  },
  {
    operation: `shipment`,
    fields: [
      "id",
      "code",
      "notes",
      "recipientName",
      "recipientMobile",
      "recipientPhone",
      "recipientAddress",
      "senderAddress",
      "senderName",
      "piecesCount",
      "weight",
      "senderPhone",
      "senderMobile",
      "deliveredOrReturnedDate",
      "date",
      "description",
      "totalAmount",
      "deliveredAmount",
      "collectedFees",
      "returningDueFees",
      "returnFees",
      {
        returnType: ["code"],
      },
      {
        recipientZone: ["id", "name"],
      },
      {
        originBranch: ["id", "name"],
      },
      {
        deliveryType: ["name"],
      },
      {
        recipientSubzone: ["name"],
      },
      {
        customer: ["name"],
      },
      {
        status: ["name"],
      },
      {
        branch: ["id", "name"],
      },
      {
        senderZone: ["id", "name"],
      },
      {
        senderSubzone: ["name"],
      },
      {
        type: ["name", "code"],
      },
      {
        paymentType: ["name", "code"],
      }
    ],
    variables: {}
  }
]
