import * as gqlb from "gql-query-builder";
export const LIST_ROLES = gqlb.query({
  operation: "listRoles",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "name",
        "code",
        "usersCount",
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const ROLE_ID = gqlb.query({
  operation: "role",
  fields: [
    "id",
    "code",
    "name",
    "usersCount",
    {
      permissions: ["id", "name", "slug"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_ROLE = gqlb.mutation({
  operation: "saveRole",
  fields: ["id", "name"],
  variables: {
    input: {
      type: "RoleInput",
      required: true,
    },
  },
});
export const DELETE_ROLE = gqlb.mutation({
  operation: "deleteRole",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const PERMISSIONS = gqlb.query({
  operation: "listPermissions",
  fields: ["id", "name", "slug"],
  variables: {},
});
