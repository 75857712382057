import React from 'react'
import PropTypes from "prop-types";
import Grid from '@mui/material/Unstable_Grid2';
import { CustomAutocomplete } from '../HOC/MUI/CustomAutocomplete';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LIST_CANCELLATION_REASONS_DROPDOWN, LIST_DELIVERY_AGENTS_DROPDOWN, LIST_LOOKUP_ENTRIES_DROPDOWN } from '../../GlobalsQuery/ListDropdown/ListDropdown';
import { styled } from '@mui/material/styles';
import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import { Button, Collapse } from '@mui/material';
import ButtonLoading from '../HOC/FunctionComponents/LoadingPages/ButtonLoading';
import { CONCIERGE_REQUESTS_LIST, updateConciergeRequestStatusMutationBuilder } from './Graphql';
import * as gqlb from "gql-query-builder";
import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { setValidationError } from '../HOC/CustomFunctions/setValidationError';
import { Globals } from '../HOC/Classes/Globals';

const PREFIX = 'DeliveryActionForm';

const classes = {
    paper: `${PREFIX}-paper`,
    button: `${PREFIX}-button`,
    container: `${PREFIX}-container`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.paper}`]: {
        padding: theme.spacing(3),
    },

    [`& .${classes.button}`]: {
        marginBottom: theme.spacing(1),
    },
    [`& .${classes.container}`]: {
        margin: theme.spacing(1),
    },
}));

const updateStatusQuery = {
    operation: "updateConciergeRequestStatus",
    fields: ["id"],
    variables: {
        input: {
            type: "UpdateConciergeRequestStatusInput",
            required: true,
        },
    },
};
const UPDATE_ONE_CONCIERGE_STATUS = gqlb.mutation(updateStatusQuery);
const getStatusData = (code, data, listType, user) => {
    let allowedData = []
    if (listType === "top") {
        switch (true) {
            case ["NEW", "ASSIGNED", "RESCHEDULED"].includes(code):
                allowedData.push("CANCELLED")
                !user && allowedData.push("ASSIGNED")
                break;
            case code === "ONWAY":
                !user && allowedData.push("COLLECTED", "RESCHEDULED", "CANCELLED")
                user && allowedData.push("CANCELLED")
                break;
            case code === "COLLECTED":
                allowedData.push("RECHARGED")
                break;
            case code === "RECHARGED":
                user && allowedData.push("CONFIRMED")
                break;
            default:
                break;
        }
    }
    if (listType === "wdr") {
        switch (true) {
            case code === "NEW":
                allowedData.push("CANCELLED")
                allowedData.push("TRANSFERRED")
                break;
            case code === "TRANSFERRED":
                allowedData.push("REVIEWD")
                break;
            case ["REVIEWD", "ASSIGNED", "RESCHEDULED"].includes(code):
                allowedData.push("ASSIGNED")
                break;
            case code === "ONWAY":
                allowedData.push("RESCHEDULED", "PAID")
                break;
            case code === "PAID":
                user && allowedData.push("CONFIRMED")
                break;
            default:
                break;
        }
    }

    return allowedData
}

function UpdateConciergeStatus(props) {
    const user = Globals.user.account

    const {
        conciergeRequestsIds,
        listType,
        conciergeRequests,
        conciergeRequest,
        updateMultiple,
        clearCheckBox
    } = props
    const { enqueueSnackbar } = useSnackbar();

    const {
        handleSubmit: submit,
        control,
        formState,
        setError,
        watch
    } = useForm();

    const { t } = useTranslation();
    const { errors } = formState;

    const parseData = (data) => {
        return data;
    };

    const UPDATE_SHIPMENT_STATUS = updateMultiple ?
        updateConciergeRequestStatusMutationBuilder(conciergeRequestsIds) :
        UPDATE_ONE_CONCIERGE_STATUS

    const [updateShipmentStatusMutation, { loading: updateStatusLoad, client }] =
        useMutation(
            gql`
        ${UPDATE_SHIPMENT_STATUS.query}
        `,
            {
                fetchPolicy: "no-cache",
                nextFetchPolicy: "no-cache",
            }
        );

    const selectDefaultValue = (data) => {
        let allowedData
        if (data) {
            if (!updateMultiple) {
                allowedData = data?.filter((e) => getStatusData(conciergeRequest.status.code, allowedData, listType, user).includes(e.code))
            } else {
                let concatData = []
                conciergeRequests.map((e) => {
                    let getArr = getStatusData(e.status.code, allowedData, listType, user)
                    getArr.map(e => concatData.push(e))
                    return ''
                })
                const filterData = [...new Set(concatData)];
                allowedData = data?.filter((e) => filterData.includes(e.code))
            }
        }
        return allowedData;
    };

    const onSubmits = (data) => {
        for (const key in data) {
            if (data[key] === "") {
                delete data[key];
            }
        }
        let variables = {
            input: {
                id: conciergeRequest?.id,
                statusCode: data.statusCode,
                ...data
            },
        };

        if (updateMultiple) {
            let queryInput = variables.input;
            for (const conciergeRequest of conciergeRequests) {
                variables["input" + conciergeRequest.id] = {
                    ...queryInput,
                    id: conciergeRequest.id,
                };
            }
            delete variables["input"];
        }

        updateShipmentStatusMutation({
            variables,
        })
            .then((res) => {
                props.onClose();
                clearCheckBox()
                client.refetchQueries({
                    include: [
                        gql`
                    ${CONCIERGE_REQUESTS_LIST.query}
                `,
                    ],
                });
                enqueueSnackbar(t("saveSuccessful"), {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    TransitionComponent: Collapse,
                });
            })
            .catch(({ graphQLErrors }) => {
                setValidationError(graphQLErrors, setError);
                console.log(graphQLErrors);
                client.refetchQueries({ include: [gql`${CONCIERGE_REQUESTS_LIST.query}`] });
                enqueueSnackbar(graphQLErrors[0].message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    TransitionComponent: Collapse,
                });
            });
    }

    return (
        <Root>
            <Grid
                container
                spacing={2}
                className={classes.container}
                component={"form"}
                onSubmit={submit(onSubmits)}
            >
                <Grid xs={12} >
                    <CustomAutocomplete
                        valueKey="code"
                        control={control}
                        errors={errors}
                        rules={{ required: t("fieldIsRequired") }}
                        name={"statusCode"}
                        label={t("status")}
                        parseData={(data) => selectDefaultValue(data)}
                        variables={{
                            input: {
                                code: "CONCIERGE_REQUEST_STATUS",
                            }
                        }}
                        query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                    />
                </Grid>
                {watch("statusCode") === "ASSIGNED" &&
                    <Grid xs={12} >
                        <CustomAutocomplete
                            control={control}
                            errors={errors}
                            name={"deliveryAgentId"}
                            label={t("shippingAgent")}
                            rules={{ required: t("fieldIsRequired") }}
                            parseData={(data) => parseData(data)}
                            query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                        />
                    </Grid>}
                {["RECHARGED", "TRANSFERRED"].includes(watch("statusCode")) &&
                    <Grid xs={12} >
                        <ControlMUItextField
                            control={control}
                            errors={errors}
                            rules={{ required: t("fieldIsRequired") }}
                            name={"referenceNumber"}
                            label={t("referenceNumber")}
                        />
                    </Grid>}
                {["CANCELLED", "RESCHEDULED"].includes(watch("statusCode")) && !user &&
                    <Grid sm={12} >
                        <CustomAutocomplete
                            control={control}
                            errors={errors}
                            name={"cancellationReasonId"}
                            label={t("reasonName")}
                            query={LIST_CANCELLATION_REASONS_DROPDOWN.query}
                            parseData={(data) => parseData(data)}
                            variables={{
                                input: {
                                    type: "DEX"
                                }
                            }}
                        />
                    </Grid>
                }
                <Grid xs={12} >
                    <ControlMUItextField
                        name="notes"
                        control={control}
                        label={t("notes")}
                        rows={2}
                    />
                </Grid>
                <Grid xs={12} justifyContent="flex-end" display={"flex"}>
                    <Button
                        className={classes.button}
                        color={updateStatusLoad ? "inherit" : "primary"}
                        variant="contained"
                        type="submit"
                        disabled={updateStatusLoad}
                    >
                        {updateStatusLoad ? <ButtonLoading /> : t("save")}
                    </Button>
                </Grid>
            </Grid>
        </Root>
    )
}

UpdateConciergeStatus.propTypes = {
    statusCode: PropTypes.any,
};

export default UpdateConciergeStatus