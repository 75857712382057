import React, { Fragment, useMemo, useState } from "react";
import {
  List,
  ListItem,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  Typography,
  IconButton,
} from "@mui/material";
import { Print } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Globals } from "../HOC/Classes/Globals";
import RemoveIcon from '@mui/icons-material/Remove';
import { printLink } from "../../helpers/defaultPrintList";
import { waybills } from "../../utils/waybills";

const PrintComponent = (props) => {
  const {
    shipmentsId,
    // hidden = [],
    oneShipment,
    batch,
    printType,
    afterSaveFun,
    setOpenPrint,
    openPrint,
    onClosePrintDialog,
    queryName,
    queryId,
  } = props;
  const { t } = useTranslation();
  const defaultPrint = Globals.settings.waybillCode;

  const icon = !Boolean(setOpenPrint)
  const [openPrintDialog, setOpenPrintDialog] = useState(false)

  const handleClosePrint = () => {
    onClosePrintDialog && onClosePrintDialog()
    printType === 'R&P' && afterSaveFun()
    icon ? setOpenPrintDialog(false) : setOpenPrint(false);
  };

  const handleOpenPrint = () => {
    defaultPrint ? printLink(defaultPrint, false, shipmentsId) : setOpenPrintDialog(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formattedData = useMemo(() => waybills(t), [t]);

  return (
    <Fragment>
      {icon &&
        <IconButton
          aria-label="print"
          onClick={handleOpenPrint}
          disabled={shipmentsId?.length === 0}>
          <Print fontSize="inherit" />
        </IconButton>
      }

      <Dialog
        open={icon ? openPrintDialog : openPrint}
        onClose={handleClosePrint}
      >
        <DialogTitle color={'text.primary'} sx={{ display: "flex", alignItems: "center", pb: 1 }}>
          <ListItemIcon sx={{ minWidth: 35 }}>
            <Print fontSize="medium" />
          </ListItemIcon>
          {t("print")}
        </DialogTitle>
        <DialogContent sx={{ p: 0, pb: 2 }}>
          <List>
            {
              formattedData.map((type, index) => {
                if (oneShipment && ["SHEET_DIFF_2", "SHEET_DIFF_3"].includes(type.printLink)) {
                  return null
                }
                return (
                  <ListItem
                    sx={{ px: 5 }}
                    key={index}
                    button
                    onClick={() => {
                      printLink(type.printLink, batch, queryId ?? shipmentsId, undefined, queryName)
                      handleClosePrint();
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <RemoveIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body1">
                      {type.name}
                    </Typography>
                  </ListItem>
                )
              })
            }
          </List>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default PrintComponent;