import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Collapse,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import * as gqlb from "gql-query-builder";
import { Fragment, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
export const EXPOERT_ConciergeRequest = gqlb.mutation({
  operation: "exportConciergeRequests",
  fields: [],
  variables: {
    input: {
      type: "ExportConciergeRequestsInput",
      required: true,
    },
  },
});

export default function ExportConciergeRequests(props) {
  const { filters, disabled, openExport, setOpenExport} = props;
  const { enqueueSnackbar } = useSnackbar();
  const [conciergeFieldsState, setConciergeFieldsState] = useState([]);
  const [dialogState, setDialogState] = useState(false);
  const { t } = useTranslation();
  const icon = !Boolean(setOpenExport)

  const conciergeFields = [
    { name: "CODE", label: t("code") },
    { name: "DATE", label: t("theDate") },
    { name: "STATUS", label: t("status") },
    { name: "BRANCH", label: t("branch") },
    { name: "PROVIDER", label: t("serviceProvider") },
    {
      name: "SERVICE",
      label: t("service"),
    },
    { name: "ZONE", label: t("zone") },
    { name: "SUBZONE", label: t("subzone") },
    {
      name: "ADDRESS",
      label: t("address"),
    },
    {
      name: "ACCOUNT_NUMBER",
      label: t("accountNumber"),
    },
    {
      name: "REFERENCE_NUMBER",
      label: t("refNumber"),
    },
    { name: "PHONE", label: t("phone") },
    { name: "CURRENCY", label: t("currency") },
    { name: "EXCHANGE_RATE", label: t("exchangeRate") },
    { name: "AMOUNT", label: t("amount") },
    { name: "LOCAL_AMOUNT", label: t("localAmount") },
    {
      name: "COLLECTED",
      label: t("collected"),
    },
    {
      name: "DELIVERY_FEES",
      label: t("deliveryFees"),
    },
    {
      name: "COLLECTION_FEES",
      label: t("collectionFees"),
    },
    {
      name: "COLLECTION_CODE",
      label: t("collection"),
    },
    { name: "CUSTOMER", label: t("customer") },
    {
      name: "TYPE",
      label: t("packageType"),
    },
    {
      name: "COMMISSION_AMOUNT",
      label: t("commission"),
    },
    {
      name: "PENDING_COLLECTION_AMOUNT",
      label: t("deservedAmount"),
    },
    {
      name: "NET_COLLECTION",
      label: t("netCollection"),
    },
  ];

  useEffect(() => {
    if (!conciergeFields[0].id)
      conciergeFields.forEach((ele, index) => {
        ele["id"] = index + 1;
        ele["checked"] = true;
      });

    const localStorageExportConcierge = localStorage.getItem("export-concierge");
    const parsedExportConcierge = localStorageExportConcierge
      ? JSON.parse(localStorageExportConcierge)
      : null;

    const notCheckedFields = conciergeFields.filter((i) => {
      if (
        !parsedExportConcierge?.includes(i.name) &&
        parsedExportConcierge !== null
      ) {
        i.checked = false;
        return true;
      }
      return false;
    });

    const checkedFields = conciergeFields.filter((i) => {
      if (parsedExportConcierge?.includes(i.name)) {
        i.checked = true;
        return true;
      }
      return false;
    });

    const sortedFields = checkedFields.sort((a, b) => {
      return parsedExportConcierge?.indexOf(a.name) >
        parsedExportConcierge?.indexOf(b.name)
        ? 1
        : -1;
    });

    setConciergeFieldsState(
      localStorageExportConcierge
        ? [...sortedFields, ...notCheckedFields]
        : conciergeFields
    );
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const fieldCount = conciergeFieldsState.length;
  const fieldSelected = conciergeFieldsState.filter((i) => i.checked).length;
  const onSelectAllClick = (e) => {
    const fileds = [...conciergeFieldsState];
    fileds.forEach((i) => {
      if (e.target.checked) {
        i.checked = true;
      } else {
        i.checked = false;
      }
    });
    setConciergeFieldsState(fileds);
  };

  const onCloseDialog = () => {
    icon ? setDialogState(false) : setOpenExport(false)
  };

  const onOpenDialog = () => {
    setDialogState(true);
  };

  const [exportConcierge, { loading }] = useMutation(
    gql`
      ${EXPOERT_ConciergeRequest.query}
    `,
    {
      onCompleted: (data) => {
        onCloseDialog();
        if (data.exportConciergeRequests) {
          enqueueSnackbar(t("successExport"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        } else {
          enqueueSnackbar(t("errorExport"), {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(conciergeFieldsState);
    const [reorderedItem] = items.splice(result.source.index, 1);
    const isNotChecked = items[result.destination.index - 1]?.checked;
    if (isNotChecked === false) return;
    items.splice(result.destination.index, 0, reorderedItem);
    setConciergeFieldsState(items);
  }
  const handelExportShipment = () => {
    const updatedFields = conciergeFieldsState.reduce((previous, current) => {
      if (current.checked) {
        previous.push(current.name);
      }
      return previous;
    }, []);
    localStorage.setItem("export-concierge", JSON.stringify(updatedFields));
    const fields = conciergeFieldsState
      .filter((i) => i.checked)
      .map((i) => ({ label: i.label, name: i.name }));
    exportConcierge({
      variables: {
        input: {
          filters,
          fields,
        },
      },
    });
  };

  return (
    <>
      {icon && <Tooltip title={t("exportExcel")}>
        <Box component="span">
          <IconButton
            disabled={disabled}
            onClick={() => onOpenDialog()}
          >
            <Icon>sim_card_download</Icon>
          </IconButton>
        </Box>
      </Tooltip>}
      <CustomDialog
        fullWidth
        maxWidth="xs"
        open={icon ? dialogState : openExport}
        onClose={onCloseDialog}
        title={
          <Fragment>
            <Checkbox
              edge="start"
              indeterminate={fieldSelected > 0 && fieldSelected < fieldCount}
              checked={fieldCount > 0 && fieldSelected === fieldCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
            {t("exportExcel")}
          </Fragment>
        }
        actions={
          <>
            <Button color="primary" onClick={onCloseDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={loading || fieldSelected === 0}
              onClick={handelExportShipment}
            >
              {loading ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
        content={
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  component="ul"
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {conciergeFieldsState.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.id}`;
                    return (
                      <Draggable
                        isDragDisabled={!value.checked}
                        key={value.id}
                        draggableId={value.name}
                        index={index}
                      >
                        {(provided) => (
                          <CustomeItemList
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            itemDetails={value}
                            labelId={labelId}
                            state={conciergeFieldsState}
                            setState={setConciergeFieldsState}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.label}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        }
      />
    </>
  );
}

const CustomeItemList = (props) => {
  const { itemDetails, labelId, state, setState, innerRef, ...restProps } =
    props;
  const { label, checked } = itemDetails;
  const handleToggle = (e) => {
    const checked = e.target.checked;
    const update = [...state];
    const checkedItemLength = update.filter((i) => i.checked).length;
    const index = update.findIndex((i) => i.name === itemDetails.name);
    const [sortItem] = update.splice(index, 1);

    sortItem["checked"] = checked;
    update.splice(checkedItemLength, 0, sortItem);

    setState(update);
  };
  return (
    <ListItem {...restProps} ref={innerRef} dense button>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          onChange={handleToggle}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={label} />
    </ListItem>
  );
};
