import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Checkbox, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import ShipmentRegion from "../../HOC/CustomComponents/ShipmentRegion";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import formatMoney from "../../../helpers/numbersDot";
import { DeliveredAmountTableCell, DeliveredCommissionTableCell } from "./CommissionAmountTableCell";

const PREFIX = "ShipmentListDetails";

const classes = {
  shipmentTable_checkbox: `${PREFIX}-shipmentTable_checkbox`,
};

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [`& .${classes.shipmentTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    left: 0,
  },
}));

export const collectionShipmentsCells = (shipmentDetails, type, autoPayCommission) => {
  const cellsByType = {
    DLVBY: (
      <>
        <DeliveredAmountTableCell shipmentDetails={shipmentDetails} />
        {autoPayCommission && <DeliveredCommissionTableCell shipmentDetails={shipmentDetails} />}
        <FixedTableCell>{shipmentDetails?.shipment?.status?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.shipment?.type?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.shipment?.date}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.shipment?.deliveredOrReturnedDate}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.shipment?.customer?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.shipment?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.shipment?.recipientZone?.id}`}
          zone={shipmentDetails?.shipment?.recipientZone?.name}
          subzone={shipmentDetails?.shipment?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.shipment?.deliveryType?.name}</FixedTableCell>
        {/* <FixedTableCell>{formatMoney(shipmentDetails?.shipment?.deliveredAmount)}</FixedTableCell> */}

        <FixedTableCell>{formatMoney(shipmentDetails?.shipment?.collectedFees)}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.shipment?.description}</FixedTableCell>
        <FixedTableCell>
          {formatMoney(shipmentDetails?.amount)}
        </FixedTableCell>

      </>
    ),
    CUSTM: (
      <>
        <FixedTableCell>{shipmentDetails?.shipment?.status?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.shipment?.type.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.shipment?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.shipment?.recipientZone?.id}`}
          zone={shipmentDetails?.shipment?.recipientZone?.name}
          subzone={shipmentDetails?.shipment?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.shipment?.description}</FixedTableCell>
        <FixedTableCell>
          {formatMoney(shipmentDetails?.amount)}
        </FixedTableCell>
      </>
    ),
  };

  return cellsByType[type];
};

export function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, type , autoPayCommission} = props;
  const { t } = useTranslation();
  
  const headCells = {
    DLVBY: [
      t("requestCode"),
      t("deliveredAmount"),
      // t("commission"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("theDate"),
      t("deliveryCanclingDate"),
      t("customer"),
      t("recipient"),
      t("destination"),
      t("deliveryType"),
      t("collectedFees"),
      t("description"),
      t("collectedAmount"),
    ],
    CUSTM: [
      t("requestCode"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("recipient"),
      t("destination"),
      t("description"),
      t("collectedAmount"),
    ],
  };
  autoPayCommission&&headCells.DLVBY.splice(2, 0, t("commission"));
  return (
    <StyledTableHead>
      <TableRow>
        <FixedTableCell
          className={classes.shipmentTable_checkbox}
          padding="checkbox"
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </FixedTableCell>
        {headCells[type].map((headCell, index) => (
          <FixedTableCell
            key={index}
          // align={headCell.numeric ? 'right' : 'left'}
          >
            {headCell}
          </FixedTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
