import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { Checkbox, TableRow } from "@mui/material";
import CellLink from "../../HOC/CustomComponents/CellLink";
import { ManifestProductsCells } from "./ProductListDetails";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { QuantityTableCell } from "./QuantityTableCell";
import { WarehouseSectionTableCell } from "./WarehouseSectionTableCell";

const PREFIX = "CustomTableRow";

const classes = {
  productTable_checkbox: `${PREFIX}-productTable_checkbox`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(TableRow)(({ theme }) => ({
  [`& .${classes.productTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    left: 0,
  },
}));

const CustomTableRow = (props) => {
  const { product, onChangeCheck, labelId, type } = props;

  const productDetails = product?.product;

  return (
    <Fragment>
      <Root
        hover
        role="checkbox"
        aria-checked={product.select}
        tabIndex={-1}
        selected={product.select}
      >
        <FixedTableCell
          className={classes.productTable_checkbox}
          padding="checkbox"
        >
          <Checkbox
            onChange={(event) => onChangeCheck(event)}
            checked={product.select}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </FixedTableCell>

        <CellLink
          component="th"
          scope="product"
          pathname={`/admin/products/${productDetails.id}`}
        >
          {productDetails?.code}
        </CellLink>
        <FixedTableCell>{productDetails?.name}</FixedTableCell>
        {type !== "PMBR" && (<QuantityTableCell productDetails={productDetails} product={product} checked={product.select && true} type={type} />)}
        {(type === "PMBR" || type === "PADD") && <WarehouseSectionTableCell productDetails={productDetails} />}
        <ManifestProductsCells productDetails={productDetails} type={type} />
      </Root>

    </Fragment>
  );
};

export default CustomTableRow;
