import React, { memo, useEffect } from "react";

import { gql, useQuery } from "@apollo/client";
import Grid from "@mui/material/Grid";
import * as gqlb from "gql-query-builder";
import { useTranslation } from "react-i18next";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";


export const STATISTICS = gqlb.query([
    {
        operation: "pendingPickup:listShipments",
        fields: [
            {
                operation: "paginatorInfo",
                fields: ["total"],
                variables: {},
            },
        ],
        variables: {
            first: {
                type: "Int",
            },
            pendingPickupInput: {
                name: "input",
                type: "ListShipmentsFilterInput",
            },
        },
    },
]);

const PREFIX = "PieChart";

const classes = {
    textColor: `${PREFIX}-textColor`,
};


const StyledCard = styled(Card)(({ theme }) => ({
    width: "100%",
    // height: "100%",
    minHeight: "350px",
    display: "flex",
    alignItems: "stretch",
    [`& .${classes.textColor}`]: {
        color: theme.palette.text.primary,
    },

}));
const Root = styled('div')(({ theme }) => ({
    display: "grid",
    height: "100%",
    alignContent: "space-between",
    [`& .${classes.textColor}`]: {
        [`& text`]: {
            fill: `${theme.palette.text.primary} !important`,
            // color: 
        },
    },
}));

const SEARCH = (dataFields) => {
    return gqlb.query({
        operation: "listShipments",
        fields: [
            {
                operation: "data",
                fields: [...dataFields],
                variables: {},
            },
            {
                operation: "paginatorInfo",
                fields: ["total"],
                variables: {},
            },
        ],
        variables: {
            input: {
                type: "ListShipmentsFilterInput",
            },
            first: {
                type: "Int",
            },
            page: {
                type: "Int",
            },
        },
    });
};

const ListShipments = (props) => {
    const {
        className,
        onPageChange,
        page,
        queryVariables,
        rowsPerPage,
        brife,
        tableHeadCell,
        tableBody,
        queryFields,
    } = props;
    const { t } = useTranslation();

    const input = {
        ...queryVariables,
        ...(queryVariables?.toDate && { date:{toDate: queryVariables?.toDate} }),
    };

    const { data, loading } = useQuery(
        gql`
          ${SEARCH(queryFields).query}
        `,
        {
            notifyOnNetworkStatusChange: true,
            variables: {
                input,
                first: rowsPerPage,
                page: page + 1,
            },

            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            onCompleted: () => {

            },
            onError: (error) => {
                console.log(error);
            },
        }
    );

    useEffect(() => {
        const height = document.getElementById("StyledCard").offsetHeight
        document.getElementById("StyledCard").style.minHeight = height + "px"
        return () => {

        }
    }, [data])

    let Dom = null;
    Dom = (
        <Root>
            <TableFixedHeaderWraper>
                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                    <TableHead>
                        <TableRow>
                            {tableHeadCell.map((cell, index) =>
                                <FixedTableCell key={index} className={
                                    `${(cell === "code" && className.shipmentTable_code) || (cell === "shipmentStatus" && className.shipmentTable_shipmentStatus)}`
                                }>{t(cell)}</FixedTableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.listShipments.data.map((shipment, index) =>
                            tableBody(shipment, index)
                        )}
                    </TableBody>
                </Table>
            </TableFixedHeaderWraper>
            <MUITablePagination
                count={data?.listShipments?.paginatorInfo?.total}
                rowsPerPage={rowsPerPage}
                page={!data?.listShipments ? 0 : page}
                onPageChange={onPageChange}
                onRowsPerPageChange={null}
                rowsPerPageOptions={brife && !false ? [20] : null}
            />
        </Root>
    );
    return (
        <StyledCard id="StyledCard">
            {loading || data?.listShipments.data.length === 0 ? (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                >
                    {loading ? <Grid item display={"flex"} alignItems={"center"}>
                        <CustomSpinner name="PulseLoader" size={12} margin={2} />
                    </Grid> :
                        <EmptyTableMessage
                            message={t("noResult")}
                        />
                    }
                </Grid>
            ) :
                <Grid
                    container
                    alignItems="flex-start"
                    justifyContent="center"
                >
                    <Grid item xs={12} height={"100%"}>{Dom}</Grid>
                </Grid>
            }
        </StyledCard>
    );
};

export default memo(ListShipments);
