import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  // Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { createContext, useEffect, useState } from "react";

import { Add, FilterList, Inventory, Print, ViewColumn } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import SearchTable, {
  SEARCH,
} from "../ShipmentSearch/SearchTable";
import useWidth from "../../Hooks/useWidth";
import CreateShipmentInDialog from "./CreateShipmentInDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useForm } from "react-hook-form";
import { RECEIVE_SHIPMENTS_IN_WAREHOUSE } from "./Graphql";
import { gql, useMutation, useQuery } from "@apollo/client";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { LIST_WAREHOUSE_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { useSnackbar } from "notistack";
import { Globals } from "../HOC/Classes/Globals";
import PrintComponent from "./PrintComponent";
import { printLink } from "../../helpers/defaultPrintList";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import ShipmentColumView from "../ShipmentSearch/shipmentColumView";
import ProductList from "./ProductsList/ProductList";
import { defaultQueryFields, initialData, shipmentFieldsFun } from "../ShipmentSearch/ListShipmentTableCells";
import ExportShipments from "./ExportShipments";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import config from "../../config.json";
import { LIST_FILTER_GROUPS } from "../ShipmentsQuery/IdleGraphQl";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import SaveFilterGroupDialog from "./Component/SaveFilterGroupDialog";
import moment from "moment";
import { DELETE_FILTER_GROUP } from "./GraphQl/FilterGraphQl";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";

export const SavedFilterContext = createContext();

const PREFIX = "Shipments";
const classes = {
  customFilters: `${PREFIX}-customFilters`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.customFilters}`]: {
    // display: "grid",
    // gridAutoFlow: "column",
    // padding: theme.spacing(2),
    // gridGap: theme.spacing(1),
    // justifyItems: "start",
    // justifyContent: "start",
    width: "100%",
    borderBottom: `1px solid ${theme.palette.divider}`
  },
}));

//*********Table Function*********
const Shipments = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const defaultPageInput = {}

  const [keys, setKeys] = useState(localStorage.getItem("shipments") ? JSON.parse(localStorage.getItem("shipments")) : initialData.shipments)
  const user = Globals.user;
  const { handleSubmit, control, formState, setValue } = useForm();
  const { errors } = formState;

  // ? ////// Permissions //////////
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );
  // ? ////// End Permissions //////////


  // ? ///////// Create New Shipment ///////////
  const [openShipmentDialog, setOpenShipmentDialog] = useState(false);
  const openShipmentDialogHandler = () => {
    setOpenShipmentDialog(true);
  };

  const closeShipmentDialogHandler = () => {
    setOpenShipmentDialog(false);
  };
  // ? ///////// End Create New Shipment ///////////

  const openColumView = () => {
    setOpenShipmentViewColum(true)
  };
  const [pageFilter, setPageFilter] = useState({})
  const [filtersByFilterId, setFiltersByFilterId] = useState();

  const [printListDisabled, setPrintListDisabled] = useState(true)
  const [shipmentViewColum, setOpenShipmentViewColum] = useState(false);

  const [checkedIds, setCheckedIds] = useState([]);
  const [checkedShipments, setCheckedShipments] = useState([]);
  const clearCheckBox = () => setCheckedIds([]);

  const selectDefaultValue = (data) => {
    return data;
  };

  const [branchId, setBranchId] = useState()
  const shipmentsSelected = checkedShipments.filter((i) =>
    checkedIds.includes(i.id)
  );

  useEffect(() => {
    const canReceive =
      shipmentsSelected.every((e) => ["PKR", "PRP", "PRPD"].includes(e.status.code) && !e.cancelled) &&
      shipmentsSelected.length > 0;
    const canPrepare =
      shipmentsSelected.every((e) => ["PKR", "PRP"].includes(e.status.code) && e.hasProducts && !e.cancelled && e.type.code !== "RTS") &&
      shipmentsSelected.length > 0;
    setReceiveInWarehousePrint(!canReceive);
    setPrepareProduct(!canPrepare);

    setBranchId(shipmentsSelected[0]?.branch?.id)
    const validReceiveInWarehouse = shipmentsSelected.every(
      ({ branch }) => branch.id === shipmentsSelected[0]?.branch?.id
    );
    setReceiveInWarehouse(validReceiveInWarehouse)
  }, [shipmentsSelected]);

  // ? ///////// Receive Shipment In Warehouse ///////////
  const defaultPrint = Globals.settings.waybillCode;
  const [receiveInWarehouse, setReceiveInWarehouse] = useState(false)
  const [disableInWarehousePrint, setReceiveInWarehousePrint] = useState(true);
  const [dialogState, setDialogState] = useState(false);
  const [autocompleteValues, setAutocompleteValues] = useState({
    section: null,
  });
  const [openPrint, setOpenPrint] = useState(false);
  const [printType, setPrintType] = useState('');

  const handelShipmentInWarehouse = () => {
    setDialogState(true);
  };

  const afterSaveFun = () => {
    handleDialogClose();
    client.refetchQueries({
      include: [
        gql`
          ${SEARCH(defaultQueryFields(keys, adminNotesPermission)).query}
        `,
      ],
    });
    setCheckedIds([]);
  };

  const openPrintDialog = (type) => {
    defaultPrint ?
      printLink(defaultPrint, false, checkedIds, afterSaveFun) :
      setOpenPrint(true)
    setPrintType(type)
  };

  const handleDialogClose = () => {
    setDialogState(false);
    setAutocompleteValues({
      section: null,
    });
    setValue("sectionId", "");
  };

  const [
    receiveShipmentsInWarehouse,
    { loading: receiveShipmentsInWarehouseLoading, client },
  ] = useMutation(
    gql`
      ${RECEIVE_SHIPMENTS_IN_WAREHOUSE.query}
    `
  );

  const submitData = (data) => {
    receiveShipmentsInWarehouse({
      variables: {
        input: {
          id: checkedIds,
          ...(data.sectionId && { sectionId: data.sectionId }),
        },
      },
    })
      .then((res) => {
        openPrintDialog("R&P")
        handleDialogClose()
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        const shipmentsSelect = checkedShipments.filter((i) =>
          checkedIds.includes(i.id)
        );
        const shipmentsErrorId = Object.entries(
          graphQLErrors[0].extensions.validation
        ).map((shipment) => parseInt(shipment[0].split(".")[2]));
        const shipmentsError = shipmentsSelect.filter((i, index) =>
          shipmentsErrorId.includes(index)
        );
        const Errors = Object.entries(
          graphQLErrors[0].extensions.validation
        ).map((shipment) => shipment[1]);
        const errorMsg = (
          <List
            sx={{
              width: "100%",
              "& ul": { padding: 0 },
            }}
            subheader={<li />}
          >
            {shipmentsError.slice(0, 3).map((shipment, index) => (
              <li
                key={shipment.id}
                style={{
                  marginBottom: index === shipmentsError.length - 1 ? 0 : 16,
                }}
              >
                <ul>
                  <ListItem sx={{ p: 0, fontSize: 15 }}>
                    {`${t("shipment")} ${shipment.code}`}
                  </ListItem>
                  {Errors[index].map((item, index) => (
                    <ListItem sx={{ p: 0, fontSize: 12 }} key={index}>
                      <ListItemAvatar sx={{ minWidth: "20px" }}>
                        -
                      </ListItemAvatar>
                      <ListItemText primary={`${item}`} />
                    </ListItem>
                  ))}
                </ul>
              </li>
            ))}
            {shipmentsError.length > 3 && (
              <ListItem sx={{ p: 0, fontSize: 15 }}>
                {t("moreShipmentErrors")}
              </ListItem>
            )}
          </List>
        );
        clearCheckBox([]);

        handleDialogClose();
        enqueueSnackbar(errorMsg, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  // ? ///////// End Receive Shipment In Warehouse ///////////

  // ? ////////////// Prepare Functions ////////////////////
  const [prepareProduct, setPrepareProduct] = useState(true);
  const [openProductDialog, setOpenProductDialog] = useState(false)
  const onClosePrepareDialog = () => {
    setOpenProductDialog(false)
    client.refetchQueries({
      include: [
        gql`
          ${SEARCH(defaultQueryFields(keys, adminNotesPermission)).query}
        `,
      ],
    });
    setCheckedIds([]);
  }
  // ? ////////////// End Prepare Functions ////////////////////

  // ? ////// on Submit Function //////////
  const screenWidth = useWidth();
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";
  const onSubmitFunc = () => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
  };
  // ? ////// End On Submit Function //////////


  // ? ////// Export Function //////////
  const [openExport, setOpenExport] = useState(false);

  const openExportDialog = () => {
    setOpenExport(true)
  };
  // ? ////// End Export Function //////////

  // ? ////// Print Function //////////
  const searchParams = (param) => {
    const queryParams = [];

    for (const i in param) {
      if (i === "date") {
        for (const y in param[i]) {
          queryParams.push(encodeURIComponent(y) + "=" + encodeURIComponent(param[i][y]))
        }
      }
      else if (i === "lastTransactionDate") {
        for (const y in param[i]) {
          queryParams.push(encodeURIComponent("lastTransaction" + y) + "=" + encodeURIComponent(param[i][y]))
        }
      }
      else {
        encodeURIComponent(param[i]) && queryParams.push(encodeURIComponent(i) + "=" + encodeURIComponent(param[i]))
      }
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const onPrint = () => {
    const params = searchParams(Object.keys(pageFilter).length !== 0 ? pageFilter : filtersByFilterId);
    const domain = `${window.location.origin}`;
    window.open(`${domain}/shipment-list/print?listType=shipments&${params}`);
  };
  // ? ////// End Print Function //////////
  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: openShipmentDialogHandler,
      icon: Add,
      permission: "shipping.shipment.create",
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: checkedIds.length > 0
    },
    {
      id: "print",
      title: "printWaybill",
      action: () => openPrintDialog("p"),
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: checkedIds.length === 0
    },
    {
      id: "prepare",
      title: "prepare",
      action: () => setOpenProductDialog(true),
      icon: Inventory,
      permission: "shipping.shipment.update_status",
      disabled: prepareProduct
    },
    {
      id: "printList",
      title: "printList",
      action: onPrint,
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: printListDisabled
    },
    {
      id: "receiveAndPrint",
      title: "receiveAndPrint",
      action: handelShipmentInWarehouse,
      permission: "shipping.shipment.receive_in_warehouse",
      icon: Print,
      disabled: disableInWarehousePrint
    },
    {
      id: "ViewColumn",
      title: "viewColumns",
      action: openColumView,
      icon: ViewColumn,
    },
  ]

  const urlQuery = urlParameters(window.location.search);
  const [filterData, setFilterData] = useState({})
  const [changing, setChanging] = useState(true)
  const [filterGroupDialogState, setFilterGroupDialogState] = useState(false)
  const closeFilterGroupDialog = () => {
    setFilterGroupDialogState(false)
  }


  const { data, loading: listFilterLoading, refetch, } = useQuery(
    gql`
      ${LIST_FILTER_GROUPS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-first",
      nextFetchPolicy: "cache-first",
      variables: {
        input: {
          type: "SHIPMENT"
        },
      },
      onCompleted: ((data) => {
        if (urlQuery?.filterId) {
          const filter = data.listFilterGroups?.filter(filter => filter.id === parseInt(urlQuery?.filterId))
          const filtersParsed = filter.length > 0 && JSON.parse(filter[0].filters)
          setFiltersByFilterId(filtersParsed)
          setPageFilter({})
          if (filter.length === 0) {
            const url = history.createHref({
              pathname: "/admin/shipments",
            });
            windowUrl(url);
          }
          setChanging(prev => !prev)
          if (filtersParsed.date && filtersParsed.date.fromDate) {
            filtersParsed.date.fromDate = moment(filtersParsed.date.fromDate).locale("en").format("YYYY-MM-DD");
            filtersParsed.date.toDate = moment(filtersParsed.date.toDate).locale("en").format("YYYY-MM-DD");
          }
          if (filtersParsed.lastTransactionDate && filtersParsed.lastTransactionDate.fromDate) {
            filtersParsed.lastTransactionDate.fromDate = moment(filtersParsed.lastTransactionDate.fromDate).locale("en").format("YYYY-MM-DD");
            filtersParsed.lastTransactionDate.toDate = moment(filtersParsed.lastTransactionDate.toDate).locale("en").format("YYYY-MM-DD");
          }
          setFilterData(filtersParsed ?? {})
        }
      })
    }
  );

  const filterGroupData = data?.listFilterGroups

  const history = useHistory();
  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/shipments",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const pushUrlSearchWithFilterId = (id) => {
    pushUrlSearch({
      filterId: id,
      rowsPerPage: urlQuery["rowsPerPage"] ?? config.app.pageSize,
      page: 0,
    })
  }

  const handleChangeFilterData = (filter) => {
    const filtersParsed = JSON.parse(filter.filters)
    setFiltersByFilterId(filtersParsed)
    setPageFilter({})
    if (filtersParsed.date && filtersParsed.date.fromDate) {
      filtersParsed.date.fromDate = moment(filtersParsed.date.fromDate).locale("en").format("YYYY-MM-DD");
      filtersParsed.date.toDate = moment(filtersParsed.date.toDate).locale("en").format("YYYY-MM-DD");
    }
    if (filtersParsed.lastTransactionDate && filtersParsed.lastTransactionDate.fromDate) {
      filtersParsed.lastTransactionDate.fromDate = moment(filtersParsed.lastTransactionDate.fromDate).locale("en").format("YYYY-MM-DD");
      filtersParsed.lastTransactionDate.toDate = moment(filtersParsed.lastTransactionDate.toDate).locale("en").format("YYYY-MM-DD");
    }
    setChanging(prev => !prev)
    setFilterData(filtersParsed)
    pushUrlSearchWithFilterId(filter.id)
    clearCheckBox()
  }

  const [openDeleteFilter, setOpenDeleteFilter] = useState(false);
  const [filterGroupId, setFilterGroupId] = useState(null);

  const onDeleteFilter = (id) => {
    setFilterGroupId(id)
    setOpenDeleteFilter(true)
  }

  const canDeleteGlobal = Globals.user.hasPermission("core.filter_group.delete_public")

  const customFilters = (
    <Stack className={classes.customFilters} direction={"row"} flexWrap={"wrap"} gap={1} p={1}>
      {
        filterGroupData?.map((filter, index) => {
          const deletePermission = filter.user?.id === Globals.user.id || (!filter.user && canDeleteGlobal)
          return <Chip
            sx={{ minWidth: "70px", justifyContent: "space-between" }}
            color="primary"
            key={index}
            variant={filter.id === parseInt(urlQuery?.filterId) ?? 0 ? "filled" : "outlined"}
            label={filter.name}
            onDelete={deletePermission ? () => {
              onDeleteFilter(filter.id)
            } : null}
            onClick={() => handleChangeFilterData(filter)}
          />
        }
        )
        // :
        // [1, 2, 3].map(e =>
        //   <Chip
        //     key={e}
        //     color="primary"
        //     label={<Skeleton animation="wave" width={"70px"} height={"100%"} />}
        //     sx={{ "span": { display: "flex" } }}
        //     variant="outlined"
        //   />
        // )
      }
      <Chip
        color="primary"
        label={<Add fontSize="small" />}
        sx={{ "span": { display: "flex" } }}
        variant="filled"
        disabled={
          Object.keys(pageFilter).length === 0 || !!urlQuery.filterId
        }
        onClick={() => setFilterGroupDialogState(true)}
      />
    </Stack>
  )

  return (
    <Root>
      <MutationWithDialog
        mutaion={DELETE_FILTER_GROUP.query}
        setOpenDelete={setOpenDeleteFilter}
        openDelete={openDeleteFilter}
        dialogTitle={t("deleteRecord")}
        dialogContent={t("deleteRecordMessage")}
        mutaionProps={{
          variables: { id: parseInt(filterGroupId) },
        }}
        onCompleted={() => { refetch() }}
        onCompleteMessage={t("successfullyDeletedRecord")}
      />
      {filterGroupDialogState &&
        <SaveFilterGroupDialog
          filterGroupDialogState={filterGroupDialogState}
          closeFilterGroupDialog={closeFilterGroupDialog}
          listFilterInput={pageFilter}
          refetch={refetch}
          pushUrlSearchWithFilterId={pushUrlSearchWithFilterId}
        />
      }
      <Dialog
        fullWidth
        maxWidth="md"
        open={openProductDialog}
        onClose={() => onClosePrepareDialog()}
      >
        <DialogContent style={{ overflow: "hidden", padding: 0 }}>
          <ProductList
            onClosePrepareDialog={onClosePrepareDialog}
            shipmentId={checkedIds}
            prepareShipment={true}
            type="PRE"
          />
        </DialogContent>
      </Dialog>
      <PrintComponent shipmentsId={checkedIds} openPrint={openPrint} setOpenPrint={setOpenPrint} afterSaveFun={afterSaveFun} printType={printType} />
      <ExportShipments
        filters={Object.keys(pageFilter).length !== 0 ? pageFilter : filtersByFilterId}
        openExport={openExport}
        setOpenExport={setOpenExport}
      />
      <ShipmentColumView
        openViewColum={shipmentViewColum}
        setOpenViewColum={setOpenShipmentViewColum}
        localStorageName={"shipments"}
        initialData={keys}
        setKeys={setKeys}
        shipmentFields={shipmentFieldsFun(adminNotesPermission)}
      />
      <Dialog
        open={dialogState}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <form onSubmit={handleSubmit(submitData)}>
          <DialogTitle id="alert-dialog-title" color={"text.primary"}>
            {t("receiveShipmentInWarehouse")}
          </DialogTitle>
          <DialogContent>
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"sectionId"}
              skip={!receiveInWarehouse}
              disabled={!receiveInWarehouse}
              label={t("wareHouse")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_WAREHOUSE_DROPDOWN.query}
              defaultValue={autocompleteValues.section}
              variables={{
                input: {
                  branchId: branchId,
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              autoFocus
              disabled={receiveShipmentsInWarehouseLoading}
              type="submit"
            >
              {receiveShipmentsInWarehouseLoading ? (
                <ButtonLoading />
              ) : (
                t("confirmAndPrint")
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <CreateShipmentInDialog
        open={openShipmentDialog}
        onClose={closeShipmentDialogHandler}
      />
      <SavedFilterContext.Provider value={{ filterData, setFilterData, changing }}>
        <SearchTable
          setCheckedIds={(ids) => setCheckedIds(ids)}
          setCheckedShipments={(shipments) => setCheckedShipments(shipments)}
          checkedIds={checkedIds}
          queryFields={defaultQueryFields(keys, adminNotesPermission)}
          keys={keys}
          withCheckAll
          setPrintListDisabled={setPrintListDisabled}
          icons={icons}
          path={props.match.path}

          customFilters={customFilters}
          listFilterLoading={urlQuery?.filterId && listFilterLoading}

          drawerState={drawerState[filterAnchor]}
          onSubmitFunc={onSubmitFunc}
          defaultPageInput={defaultPageInput}
          clearCheckBox={clearCheckBox}
          setPageFilter={setPageFilter}
          filtersAllowed={[
            "date",
            "dlvAtemp",
            "status",
            "packageType",
            "new",
            "branch",
            "originBranch",
            "customerType",
            "custm",
            "dlvAgent",
            "createdBy",
            "zoneSender",
            "subZoneSender",
            "zone",
            "subZone",
            "route",
            "refNumber",
            "service",
            "deliveryType",
            "returnType",
            "paymentType",
            "collection",
            "paid",
            "cancelled",
            "hasProducts",
          ]}
          pathname={"shipments"}
        />
      </SavedFilterContext.Provider>
    </Root>
  );
};
export default Shipments;
