/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";

import {
  CircularProgress,
  Grid,
  IconButton,
  SwipeableDrawer,
  Table,
  TableBody,
  Toolbar,
  Typography,
  useTheme,
  Collapse,
} from "@mui/material";
import useWidth, { isWidthDown } from "../../../Hooks/useWidth";

import { gql, useMutation, useQuery } from "@apollo/client";
import { Cancel, CheckCircle, FilterList } from "@mui/icons-material";
import clsx from "clsx";
import * as gqlb from "gql-query-builder";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination";
import CustomTableRow from "./CustomTableRow";
import { EnhancedTableHead } from "./ConciergeRequestListDetails";
import ConciergeRequestFilter from "../../ConciergeRequest/ConciergeRequestFilter";
import { SAVE_COLLECTION_ENTRIES_MUTATION } from "../CollectionListQuary";
import { useSnackbar } from "notistack";
import Backdrop from '@mui/material/Backdrop';

const TOOLBAR_PREFIX = "styledToolbar";

const toolbarClasses = {
  highlight: `${TOOLBAR_PREFIX}-highlight`,
  title: `${TOOLBAR_PREFIX}-title`,
  filterButton: `${TOOLBAR_PREFIX}-filterButton`,
};

const StyledToolbar = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1,

  [`& .${toolbarClasses.highlight}`]:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[300],
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[800],
      },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 100%",
  },

  [`& .${toolbarClasses.filterButton}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const PREFIX = "ShipmentList";

const classes = {
  track: `${PREFIX}-track`,
  paper: `${PREFIX}-paper`,
  filters: `${PREFIX}-filters`,
};

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  width: "100%",
  backgroundColor: theme.palette.background.default,

  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowX: "auto",
    maxHeight: `calc(100vh - ${theme.mixins.toolbar["minHeight"]}px)`,
  },

  [`& .${classes.filters}`]: {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 72px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },

    height: "100%",
    overflow: "hidden",
  },
}));

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { numSelected, done, clear, disabled, toggleFilterDrawer } = props;
  return (
    <StyledToolbar>
      <Toolbar
        className={clsx({
          [toolbarClasses.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={toolbarClasses.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t("conciergeRequestsCount", { count: numSelected })}
          </Typography>
        ) : (
          <Typography
            className={toolbarClasses.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("conciergeRequests")}
          </Typography>
        )}
        {/* <Tooltip title="تم" > */}
        <IconButton
          onClick={toggleFilterDrawer}
          className={toolbarClasses.filterButton}
          size="large"
        >
          <FilterList />
        </IconButton>

        <IconButton
          aria-label="done"
          disabled={disabled}
          onClick={done}
          sx={{ color: "success.main" }}
          size="large"
        >
          <CheckCircle />
        </IconButton>
        {/* </Tooltip> */}

        <IconButton
          aria-label="close"
          onClick={() => clear()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
      </Toolbar>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  done: PropTypes.func.isRequired,
};

//*********Table Function*********

const CONCIERGE_REQUESTS_QUERY = gqlb.query({
  operation: "listConciergeRequests",
  fields: [
    {
      operation: "data",
      fields: [
        "date",
        "code",
        "id",
        "phone",
        "address",
        "accountNumber",
        "amount",
        { branch: ["id", "name"] },
        { customer: ["id", "name"] },
        { status: ["code", "name"] },
        "collectionFees",
        "pendingCollectionAmount"
      ],
      variables: {},
    },
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
      required: true,
    },
    page: {
      type: "Int",
    },
    input: {
      type: "ListConciergeRequestsFilterInput",
    },
  },
});

const ConciergeRequestsList = (props) => {
  const { type, variables, refetchConciergeDataQuery } = props;

  const collectionId = parseInt(variables.id)
  const [conciergeRequests, setConciergeRequests] = useState([]);
  const [selectedConciergeRequests, setSelectedConciergeRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [drawerState, setDrawerState] = useState(false);
  const width = useWidth();
  const isScreenSmall = isWidthDown("xs", width);
  const [noData, setNoData] = useState(true);
  const [searchQuery, setSearchQuery] = useState()
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const theme = useTheme();

  const { data, loading } = useQuery(
    gql`
      ${CONCIERGE_REQUESTS_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !collectionId,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
        id: collectionId,
        input: {
          forCollectionId: collectionId,
          ...searchQuery,
        },
      },
      onCompleted: (data) => {
        setNoData(false)
        const handledData =
          data?.listConciergeRequests?.data !== null ? data?.listConciergeRequests?.data : [];

        let updatedDate = handledData.map((i) => {
          const selectedShipment = selectedConciergeRequests.find(
            (ele) => ele.conciergeRequest.id === i.id
          );
          if (selectedShipment) {
            return selectedShipment;
          } else {
            return {
              conciergeRequest: i,
              select: false,
            };
          }
        });

        setConciergeRequests(updatedDate);
      },
    }
  );

  const isConciergeRequestSelected = (addConciergeRequests, deleteConciergeRequests) => {
    const mainConciergeRequests = addConciergeRequests
      ? [...addConciergeRequests]
      : [...selectedConciergeRequests];

    const filterConciergeRequests = deleteConciergeRequests
      ? [...deleteConciergeRequests]
      : [...selectedConciergeRequests];

    return mainConciergeRequests.filter((newConciergeRequest) => {
      return !filterConciergeRequests.some(
        (current) => current.conciergeRequest.id === newConciergeRequest.conciergeRequest.id
      );
    });
  };
  const handleSelectAllClick = (event, rows) => {
    const selected = conciergeRequests?.map((i) => {
      if (event.target.checked) {
        return {
          ...i,
          select: true,
        };
      } else {
        return {
          ...i,
          select: false,
        };
      }
    });

    if (event.target.checked) {
      const newConciergeRequests = isConciergeRequestSelected(selected);
      setSelectedConciergeRequests((prev) => [...prev, ...newConciergeRequests]);
    } else {
      const filteredShipments = isConciergeRequestSelected(null, selected);
      setSelectedConciergeRequests(filteredShipments);
    }
    setConciergeRequests(selected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const resetPage = () => setPage(0);

  const toggleFilterDrawer = () => setDrawerState(!drawerState);

  const [refetch, setRefetch] = useState(true);

  const filtersForm = (
    <ConciergeRequestFilter
      {...{ rowsPerPage, page, noData, resetPage, setSearchQuery, refetch, setRefetch }}
      preventPushUrl
      onSubmitFunc={() => setDrawerState(false)}
      filtersAllowed={[
        "date",
        "code",
        "status",
        "custm",
        "zone",
        "subZone",
        "account",
      ]}
    />
  );
  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const selectedConciergeRequestsLength = selectedConciergeRequests.length;

  let tableBody = null;

  let progress = (
    <Grid container item justifyContent="center" className={classes.track}>
      <CircularProgress />
    </Grid>
  );

  const selectedShipmentParPage = () => {
    return conciergeRequests.filter((i) => i.select === true).length;
  };
  const validShipments = () => {
    return selectedConciergeRequestsLength <= 0;
  };
  const [saveCollectionEntriesMutation, { loading: saveCollectionEntriesMutationLoad }] = useMutation(
    gql`
      ${SAVE_COLLECTION_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const onSaveEntries = (data) => {
    const conciergeRequests = data.map(item => item.conciergeRequest.id);
    conciergeRequests.length > 0 && saveCollectionEntriesMutation({
      variables: {
        input: {
          collectionId: collectionId,
          conciergeRequestIds: conciergeRequests
        }
      }
    }).then(() => {
      enqueueSnackbar(t("saveSuccessful"), {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
      refetchConciergeDataQuery()
      props.done()
    }).catch((error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
    })
  }

  if (conciergeRequests && !loading) {
    tableBody = (
      <Grid container justifyContent="center" sx={{ width: "100%" }}>
        {/* *******Table******* */}
        <div className={classes.paper}>
          <TableFixedHeaderWraper containerScroll>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <EnhancedTableHead
                numSelected={selectedShipmentParPage()}
                onSelectAllClick={(e) => handleSelectAllClick(e, conciergeRequests)}
                rowCount={conciergeRequests?.length}
                type={type}
              />
              <TableBody>
                {conciergeRequests?.map((conciergeRequest, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <CustomTableRow
                      key={conciergeRequest.conciergeRequest.id}
                      conciergeRequest={conciergeRequest}
                      type={type}
                      onChangeCheck={(check) => {
                        setConciergeRequests((prev) => {
                          let updated = [...prev];
                          updated[index] = {
                            ...updated[index],
                            select: !updated[index].select,
                          };
                          if (check.target.checked) {
                            setSelectedConciergeRequests((prev) => [
                              ...prev,
                              updated[index],
                            ]);
                          } else {
                            setSelectedConciergeRequests((prev) => {
                              return prev.filter(
                                (i) => i.conciergeRequest.id !== conciergeRequest.conciergeRequest.id
                              );
                            });
                          }
                          return updated;
                        });
                      }}
                      labelId={labelId}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={data?.listConciergeRequests?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Grid>
    );
  }
  return (
    <Root container justifyContent="center">
      <Backdrop
        sx={{ color: (theme) => theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={saveCollectionEntriesMutationLoad}
        onClick={() => { }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {isScreenSmall ? (
        <SwipeableDrawer
          disableDiscovery={true}
          disableSwipeToOpen={true}
          anchor="left"
          open={drawerState}
          onClose={() => setDrawerState(false)}
          onOpen={() => setDrawerState(true)}
          classes={{ paper: classes.filters }}
          dir={theme.direction}
        >
          {filtersForm}
        </SwipeableDrawer>
      ) : (
        <Grid item sm={2}>
          {filtersForm}
        </Grid>
      )}
      <Grid item container sm={10} alignContent="flex-start">
        <EnhancedTableToolbar
          numSelected={selectedConciergeRequestsLength}
          clear={props.done}
          toggleFilterDrawer={toggleFilterDrawer}
          done={() => {
            onSaveEntries(selectedConciergeRequests)
          }}
          disabled={validShipments()}
        />

        {loading
          ? progress
          : conciergeRequests && conciergeRequests?.length !== 0
            ? tableBody
            : !loading && (
              <EmptyTableMessage loading={false} message={t("noResult")} />
            )}
      </Grid>
    </Root>
  );
};

export default ConciergeRequestsList;
