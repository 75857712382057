import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import {
  Paper,
  Typography,
  Switch,
  FormControlLabel,
  Collapse,
  DialogTitle,
  DialogContent,
  FormHelperText,
  Button,
  DialogActions,
  Dialog,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { SAVE_USER, USER_ID } from "./Graphql";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete"
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_ROLES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { Globals } from "../HOC/Classes/Globals";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import BranchesTable from "./BranchesTable";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";

const PREFIX = 'UserForm';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  main: `${PREFIX}-main`,
};

const Root = styled('form')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  }
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const UserForm = (props) => {
  const [autocompleteValues, setAutocompleteValues] = useState({
    account: null,
    roles: [],
  });

  const user = Globals.user;

  const rolePermission = user.hasPermission(
    "core.role.assign_any"
  );

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, control, formState, setValue, setError, clearErrors } =
    useForm();
  const { errors } = formState;
  const [activeUser, setActiveUser] = useState(true);
  const [rolesId, setRolesId] = useState([]);

  const [branchesList, setBranchesList] = useState([]);
  const [branchError, setBranchError] = useState({});

  const [branchesIndex, setBranchesIndex] = useState({
    index: 0,
    update: false,
  });
  const [dialog, setDialog] = useState(false);
  const [branchesErrorMessage, setBranchesErrorMessage] = useState(false);
  const [selectedNames, setSelectedNames] = useState({
    branch: "",
  });

  useEffect(() => {
    if (errors.branches) {
      clearErrors("branches");
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchesList]);

  const roleDataHandler = (data) => {
    return data && data.filter((i) => i.id === 2 || i.id === 3);
  };

  const parseData = (data) => {
    return data;
  };

  const handelCloseDialog = () => {
    setDialog(false);
    setBranchesErrorMessage(false);
    setBranchesIndex((prev) => ({ ...prev, update: false }));
  };

  const addBranchesDialog = (index) => {
    branchesReset();
    if (index || index === 0) {
      setBranchesIndex({
        index,
        update: true,
      });
    } else {
      setBranchesIndex({
        index,
        update: false,
      });
    }
    setDialog(true);
  };

  const onChangeNames = (e, parameter, remove) => {
    const name = e.name;
    setSelectedNames((prev) => ({
      ...prev,
      [parameter]: name,
      ...(remove && { [remove]: "" }),
    }));
    setBranchesErrorMessage(false);
  };

  const {
    control: branchesControl,
    formState: { errors: branchesErrors },
    handleSubmit: branchesHandleSubmit,
    setValue: branchesSetValue,
    reset: branchesReset,
  } = useForm({
    defaultValues: {
      brabchId: "",
      isDefault: false,
    },
  });
  const onSubmitBranches = (data) => {
    const newBranches = {
      branch: {
        id: data.branchId,
        name: selectedNames.branch,
      },
      isDefault: data.isDefault,
    };
    const updateBranches = [...branchesList];

    const invalidBranches = updateBranches.some((i, index) =>
      branchesIndex.update && index === branchesIndex.index
        ? false
        : i.branch.id === newBranches.branch.id
    );

    if (invalidBranches) {
      setBranchesErrorMessage(true);
    } else {
      if (data.isDefault) {
        updateBranches.forEach((i) => {
          i.isDefault = false;
        });
      }
      if (branchesIndex.update) {
        updateBranches[branchesIndex.index] = {
          ...updateBranches[branchesIndex.index],
          ...newBranches,
        };
        setBranchesIndex({ index: branchesIndex.index, update: false });
      } else {
        updateBranches.push(newBranches);
        setBranchesIndex({
          index: 0,
          update: false,
        });
      }
      setBranchesList(updateBranches);
      handelCloseDialog();
    }
  };

  const [saveUser, { loading: saveUserLoading }] = useMutation(
    gql`
      ${SAVE_USER.query}
    `
  );

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveUser(active);
  };

  // update
  const userId = parseInt(props.match.params.id);
  const { loading, data: updateUser } = useQuery(
    gql`
      ${USER_ID.query}
    `,

    {
      skip: !userId,
      variables: { id: userId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const userData = data.user;
        setActiveUser(userData.active);
        const userBranches = userData.userBranches.map((i) => ({
          ...i,
          isDefault: i.default,
        }));
        setBranchesList(userBranches);
        const saveUsersParams = ["id", "password", "username", "active"];
        saveUsersParams.forEach((i) => {
          userData[i] && setValue(i, userData[i]);
        });
        // setRolesId(userData?.roles?.id);
        const rolesIds = []
        userData?.roles.map(ele => rolesIds.push(ele.id))
        setRolesId(userData?.roles);
        setAutocompleteValues({
          account: userData?.account,
          roles: rolesIds,
        });
      },
    }
  );

  const onSubmit = (data) => {
    if (data["password"] === "") {
      delete data["password"];
    }
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    const branches = branchesList.map((i) => {
      const branches = {
        branchId: i.branch.id,
        isDefault: i.isDefault,
      };
      return branches;
    });
    saveUser({
      variables: {
        input: {
          ...data,
          active: activeUser,
          branches: branches,
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/users/${data?.data?.saveUser?.id}`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        setBranchError(graphQLErrors[0]?.extensions?.validation)
        if (!graphQLErrors[0]["extensions"]?.validation) {
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      });
  };

  const branchesForm = (
    <BranchesForm
      handleSubmit={branchesHandleSubmit}
      onSubmitBranches={onSubmitBranches}
      control={branchesControl}
      errors={branchesErrors}
      setValue={branchesSetValue}
      parseData={parseData}
      onChangeNames={onChangeNames}
      setSelectedNames={setSelectedNames}
      branchesIndex={branchesIndex}
      branchesList={branchesList}
      branchesErrorMessage={branchesErrorMessage}
      handelCloseDialog={handelCloseDialog}
    />
  );

  const body = (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <TitleAppBar path={props.match.path} />
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid container justifyContent="end" xs={12}>
            {/* <Typography variant="h6">{t("users")}</Typography> */}
            <FormControlLabel
              checked={activeUser}
              control={<Switch color="primary" />}
              label={t("active")}
              labelPlacement="start"
              onChange={handelActivate}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"username"}
              label={t("username")}
              rules={{ required: t("fieldIsRequired") }}
              disabled={userId ? true : false}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"password"}
              label={t("password")}
              disabled={
                !Globals.user.isSuper &&
                updateUser?.user?.roles?.some(el => el.code === "ADMN")
              }
              rules={!userId && { required: t("fieldIsRequired") }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge={"end"}
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} alignItems="flex-start">
            {/* <CustomAutocomplete
                control={control}
                errors={errors}
                name={"roles"}
                label={t("role")}
                onChangeValue={(e) => {
                  setRoleId(e.id);
                  setValue("accountId", "");
                  setAutocompleteValues((prev) => ({
                    ...prev,
                    account: null,
                  }));
                }}
                parseData={(data) => rolePermission ? parseData(data) : roleDataHandler(data)}
                query={LIST_ROLES_DROPDOWN.query}
                defaultValue={autocompleteValues.role}
                rules={{ required: t("fieldIsRequired") }}
              /> */}
            <MultipleAutocomplete
              multiple
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"roles"}
              label={t("role")}
              skip={false}
              parseData={(data) => rolePermission ? parseData(data) : roleDataHandler(data)}
              query={LIST_ROLES_DROPDOWN.query}
              getOptionDisabled={(option) =>
                (['CSTMR', 'DLVRY'].includes(rolesId[0]?.code) || rolesId.length > 0) && ['CSTMR', 'DLVRY'].includes(option?.code)
              }
              onChangeValue={(e) => {

                if (e === "") {
                  setRolesId([]);
                } else {
                  setRolesId(e);
                }
                setValue("accountId", "");
                setValue("roles", "");
                setAutocompleteValues((prev) => ({
                  ...prev,
                  account: null,
                  roles: [],
                }));
              }}
              defaultValue={autocompleteValues.roles}

              valueKey="id"
            />
          </Grid>

          {(rolesId.some(el => el.id === 3) || rolesId.some(el => el.id === 2)) && (
            <Grid xs={12} sm={6} alignItems="flex-start">
              {rolesId.some(el => el.id === 3) ? (
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"accountId"}
                  label={t("account")}
                  parseData={(data) => parseData(data)}
                  query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                  variables={{
                    input: {
                      active: true,
                    },
                  }}
                  defaultValue={autocompleteValues.account}
                />
              ) : (
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"accountId"}
                  label={t("account")}
                  parseData={(data) => parseData(data)}
                  query={LIST_CUSTOMERS_DROPDOWN.query}
                  variables={{
                    input: {
                      active: true,
                    },
                  }}
                  defaultValue={autocompleteValues.account}
                />
              )}
            </Grid>
          )}
        </Paper>
        <Grid container justifyContent="flex-end" className={classes.spacing}>
          <FormButton disabled={saveUserLoading}>
            {saveUserLoading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </Root>
  );
  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !updateUser && userId ? (
      <NotFound />
    ) : (
      <>
        <Dialog fullWidth maxWidth="xs" open={dialog} onClose={handelCloseDialog}>
          {branchesForm}
        </Dialog>
        {body}
        <BranchesTable
          branchError={branchError}
          setBranchError={setBranchError}
          branches={branchesList}
          addBranches={addBranchesDialog}
          setBranchesList={setBranchesList}
        /></>
    );
};

export default UserForm;

function BranchesForm({
  handleSubmit,
  onSubmitBranches,
  control,
  errors,
  parseData,
  onChangeNames,
  branchesIndex,
  branchesList,
  setValue,
  setSelectedNames,
  branchesErrorMessage,
  handelCloseDialog,
}) {
  const { t } = useTranslation();
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
  });

  useEffect(() => {
    if (branchesIndex.update) {
      const update = branchesList[branchesIndex.index];
      setSelectedNames({ branch: update?.branch?.name });
      setValue("isDefault", update?.isDefault);
      setAutocompleteValues({
        branch: { id: update?.branch?.id, name: update?.branch?.name },
      });
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitBranches)}>
      <DialogTitle>
        <Grid container justifyContent="space-between" sm={12}>
          <Typography variant="h6" color={"text.primary"}>
            {branchesIndex.update ? t("updateBranch") : t("addBranch")}
          </Typography>
          <MuiSwitch
            edge="end"
            name="isDefault"
            label={t("default")}
            control={control}
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ListBranches
          skipDefaultBranch
          control={control}
          errors={errors}
          rules={{ required: t("fieldIsRequired") }}
          name={"branchId"}
          onChangeValue={(e) => {
            onChangeNames(e, "branch");
          }}
          defaultValue={autocompleteValues.branch}
        />
        {branchesErrorMessage && (
          <FormHelperText error>{t("thisBranchAlreadyExists")}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handelCloseDialog}>
          {t("cancel")}
        </Button>
        <Button color="primary" type="submit">
          {t("confirm")}
        </Button>
      </DialogActions>
    </form>
  );
}
