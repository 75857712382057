import { Chip } from "@mui/material";
import * as colors from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import React from "react";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";

const StyledChip = styled(Chip)(({ theme, colorcode }) => ({
  color: theme.palette.getContrastText(colorcode),
  backgroundColor: colorcode + "!important",
}));

export const PickupStatusColor = (props) => {
  const color = {
    NEW: colors["amber"]["500"],
    WRK: colors["orange"]["500"],
    CXL: colors["red"]["500"],
    DON: colors["green"]["500"],
    PKD: colors["blue"]["500"],
  };

  return (
    <FixedTableCell className={props.className}>
      <StyledChip
        colorcode={color[props.code]}
        size="small"
        label={props.children}
      />
    </FixedTableCell>
  );
};
