import React from "react";

const formatNumber = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
}).format;

function calculateTotal(pieChart) {
  return formatNumber(
    pieChart
      .getAllSeries()[0]
      ?.getVisiblePoints()
      ?.reduce((s, p) => s + p.originalValue, 0)

  );
}

export default function TooltipTemplate(total, lang) {
  return (pieChart) => {
    return (
      <svg>
        {/* <circle cx="100" cy="100" r={pieChart.getInnerRadius() - 6} fill="#eee"></circle> */}
        {/* <image href={allInbox} x="70" y="58" width="60" height={pieChart.getInnerRadius() - ((pieChart.getInnerRadius()/100)*60)} /> */}
        <text
          textAnchor="middle"
          x="100"
          y="95"
          style={{ fontSize: 20, fill: "#494949" }}
        >
          <tspan x="100" style={{ fontWeight: 600 }}>
            {calculateTotal(pieChart)}
          </tspan>
          <tspan x="100" dy="25px" fontFamily={lang.fontFamily}>
            {total}
          </tspan>
        </text>
      </svg>
    );
  };
}
