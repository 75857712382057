import * as gqlb from "gql-query-builder";

export const LIST_GL_ACCOUNTS = gqlb.query({
  operation: "listGLAccounts",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      data: [
        "id",
        "name",
        "code",
        "fullCode",
        "active",
        "deletable ",
        { type: ["code", "name"] },
        { nature: ["code", "name"] },
        { class: ["code", "name"] },
        { branch: ["id", "name"] },
        { closingAccount: ["id", "name"] },
        { parent: ["id", "name"] },
      ],
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
    input: {
      type: "ListGLAccountsFilterInput",
    },
  },
});

export const LIST_GL_ALL_ACCOUNTS = gqlb.query({
  operation: "listAllGLAccounts",
  fields: ["id", "name", { type: ["code"] }, { parent: ["id"] }],
  variables: {
    input: {
      type: "ListGLAccountsFilterInput",
    },
  },
});
const glAccountField = [
  "id",
  "name",
  "code",
  "fullCode",
  "active",
  { type: ["code", "name"] },
  { nature: ["code", "name"] },
  { class: ["code", "name"] },
  { branch: ["id", "name"] },
  { closingAccount: ["id", "name"] },
  { parent: ["id", "name"] },
  // { children: glAccountField },
];
export const SAVE_GL_ACCOUNT = gqlb.mutation({
  operation: "saveGLAccount",
  fields: glAccountField,
  variables: {
    input: {
      type: "GLAccountInput",
      required: true,
    },
  },
});

export const GL_ACCOUNT = gqlb.query({
  operation: "glAccount",
  fields: glAccountField,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const DELETE_GLACCOUNT = gqlb.mutation({
  operation: "deleteGlAccount",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
