import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Icon,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import * as gqlb from "gql-query-builder";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import "../ManifestPrint/print.css";
import LogoImg from "../../Layout/LogoImg";

const PREFIX = "InvoicePrint";
const classes = {
  title: `${PREFIX}-title`,
  barcodeFont: `${PREFIX}-barcodeFont`,
  cairoFont: `${PREFIX}-cairoFont`,
  headerTableCell: `${PREFIX}-headerTableCell`,
  reportHeader: `${PREFIX}-reportHeader`,
  logoSection: `${PREFIX}-logoSection`,
  headerDate: `${PREFIX}-headerDate`,
  whiteSpace: `${PREFIX}-whiteSpace`,
  rowWithoutBorder: `${PREFIX}-rowWithoutBorder`,
  tableWidth: `${PREFIX}-tableWidth`,
  shipmentsTable: `${PREFIX}-shipmentsTable`,
  iconColor: `${PREFIX}-iconColor`,
};

const Root = styled("div")(({ theme }) => ({
  // fontFamily: ['"Cairo"', "sans-serif"].join(","),

  [`& .${classes.title}`]: {
    color: theme.palette.info.main,
    // whiteS;pace: "break-spaces",
  },
  [`& .${classes.barcodeFont}`]: {
    fontFamily: '"Libre Barcode 39 Text"',
  },
  [`& .${classes.cairoFont}`]: {
    // fontFamily: ['"Cairo"', "sans-serif"].join(","),
  },

  [`& .${classes.headerTableCell}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.reportHeader}`]: {
    whiteSpace: "pre-line",
    padding: theme.spacing(0, 2),
    lineHeight: "120%",
    fontSize: 12,
  },
  [`& .${classes.logoSection}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.headerDate}`]: {
    textAlign: "end",
  },

  [`& .${classes.whiteSpace}`]: {
    "& tr": {
      "& td": {
        whiteSpace: "initial",
      },
    },
  },
  [`& .${classes.rowWithoutBorder}`]: {
    "& td": {
      border: "none",
      padding: 0,
    },
  },

  [`& .${classes.tableWidth}`]: {
    width: "100vw",
  },

  [`& .${classes.shipmentsTable}`]: {
    "& tr": {
      "&:hover": {
        backgroundColor: "#ffffff00 !important",
      },
    },
    "& td , & th": {
      padding: theme.spacing(1),
    },
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const org = {
  operation: "organization",
  fields: [
    "name",
    "phone",
    "email",
    "website",
    "reportHeader",
    "reportFooter",
    "registrationNumber",
    "taxNumber",
  ],
  variables: {},
};

const invoicesSum = {
  operation: "sumInvoices",
  fields: [
    "shipmentsCount",
    "piecesCount",
    "weight",
    "deliveryFees",
    "postFees",
    "tax",
    "allFees",
  ],
  variables: {
    input: {
      type: "ListInvoicesFilterInput",
      required: true,
    },
  },
};

const invoicesListFields = [
  {
    data: [
      "code",
      "id",
      "date",
      "approved",
      "glApproved",
      {
        operation: "sumEntries",
        fields: [
          "weight",
          "pieces",
          "deliveryFees",
          "postFees",
          "tax",
          "allFees",
        ],
        variables: {},
      },
      {
        operation: "entries",
        fields: [
          {
            operation: "paginatorInfo",
            fields: ["total"],
            variables: {},
          },
        ],
        variables: {},
      },
      "notes",
      {
        operation: "transactionType",
        fields: ["id", "name"],
        variables: {},
      },

      {
        customer: ["id", "name", "code"],
      },
    ],
  },
];

const PAGE_COUNT = gqlb.query({
  operation: "listInvoices",
  fields: [{ paginatorInfo: ["lastPage"] }],
  variables: {
    input: {
      type: "ListInvoicesFilterInput",
      required: true,
    },
    first: {
      type: "Int",
    },
  },
});

const InvoicePrint = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const { t } = useTranslation();

  const stringValues = ["code", "fromCode", "toCode"];
  const floatValues = ["fromWeight", "toWeight"];

  stringValues.forEach((i) => {
    if (urlQuery[i]) urlQuery[i] = String(urlQuery[i]);
  });
  floatValues.forEach((i) => {
    if (urlQuery[i]) urlQuery[i] = parseFloat(urlQuery[i]);
  });

  const { data: pageCount, loading: pageLoad } = useQuery(
    gql`
      ${PAGE_COUNT.query}
    `,
    {
      first: 100,
      variables: {
        input: urlQuery,
        first: 100,
      },
    }
  );
  const pages = pageCount?.listInvoices?.paginatorInfo?.lastPage;

  const queryBuilder = () => {
    const queryArray = [org, invoicesSum];
    for (let index = 0; index < pages; index++) {
      const initialQuery = {
        operation: `listInvoices${index}:listInvoices`,
        fields: invoicesListFields,
        variables: {
          input: {
            type: "ListInvoicesFilterInput",
            required: true,
          },
          first: {
            type: "Int",
          },
          ["page" + index]: {
            type: "Int",
            name: "page",
          },
        },
      };
      queryArray.push(initialQuery);
    }
    return queryArray;
  };

  const queryBody = queryBuilder();
  const INVOICES_PRINT = gqlb.query(queryBody);

  let variables = {
    input: urlQuery,
    first: 100,
  };
  for (let index = 0; index < pages; index++) {
    variables["page" + index] = index + 1;
  }

  const { data: queryData, loading } = useQuery(
    gql`
      ${INVOICES_PRINT.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !pages,
      variables,
      onError: () => {
        return;
      },
    }
  );

  const reportDetails =
    queryData?.listInvoices?.data !== null
      ? queryData?.listInvoices?.data
      : null;
  const sumInvoices =
    queryData?.sumInvoices !== null ? queryData?.sumInvoices : null;

  let parsedData = [];
  if (queryData?.listInvoices0) {
    let concatData = [];
    for (const key in queryData) {
      if (key.startsWith("list")) {
        const listData = queryData[key].data;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }

  useEffect(() => {
    if (parsedData.length > 0) {
      window.print();
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const header = !loading && !pageLoad && reportDetails !== null && (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell padding="none">
            <Table>
              <TableBody>
                <TableRow className={classes.rowWithoutBorder}>
                  <TableCell>
                    <Box className={classes.logoSection}>
                      <LogoImg height={30} forceLight={true} />
                      <Typography
                        className={clsx(classes.reportHeader, "landscape-zoom")}
                      >
                        {queryData.organization.reportHeader}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell className={clsx(classes.headerDate)}>
                    <Typography variant="h6">
                      {moment(new Date()).format("ll")}
                    </Typography>
                    <TypographyKeyValue
                      title={t("registrationNumber")}
                      value={queryData.organization?.registrationNumber}
                      hidden={!queryData.organization?.registrationNumber}
                    />
                    <TypographyKeyValue
                      title={t("taxNumber")}
                      value={queryData.organization?.taxNumber}
                      hidden={!queryData.organization?.taxNumber}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.headerTableCell}>
                    <Table className={classes.whiteSpace}>
                      <TableBody>
                        <TableRow className={classes.rowWithoutBorder}>
                          <TableCell>
                            <Typography variant="h5">
                              {t("invoiceList")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <TypographyKeyValue
                              title={t("fromDate")}
                              value={
                                urlQuery?.fromDate &&
                                moment(urlQuery?.fromDate).format("ll")
                              }
                            />
                            <TypographyKeyValue
                              title={t("toDate")}
                              value={
                                urlQuery?.toDate &&
                                moment(urlQuery?.toDate).format("ll")
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <TypographyKeyValue
                              title={t("fromWeight")}
                              value={urlQuery?.fromWeight}
                            />
                            <TypographyKeyValue
                              title={t("toWeight")}
                              value={urlQuery?.toWeight}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  const table = !loading && !pageLoad && reportDetails !== null && (
    <Table id="manifestPrintTable">
      <EnhancedTableHead />
      <TableBody>
        {parsedData.length > 0 &&
          parsedData?.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                <FixedTableCell
                  pathname={`/admin/invoices/${row?.id}`}
                  component="th"
                  scope="row"
                >
                  {row.code}
                </FixedTableCell>
                <FixedTableCell>
                  {moment(row?.date).format("ll")}
                </FixedTableCell>

                <FixedTableCell>
                  <Box
                    component="span"
                    fontWeight="bold"
                  >{` (${row.customer.code}) `}</Box>
                  <Box component="span">{row.customer.name}</Box>
                </FixedTableCell>

                <FixedTableCell>
                  {row?.entries?.paginatorInfo.total}
                </FixedTableCell>
                <FixedTableCell>{row?.sumEntries?.pieces}</FixedTableCell>
                <FixedTableCell>{row?.sumEntries?.weight}</FixedTableCell>
                <FixedTableCell>{row?.sumEntries?.deliveryFees}</FixedTableCell>
                <FixedTableCell>{row?.sumEntries?.postFees}</FixedTableCell>
                <FixedTableCell>{row?.sumEntries?.tax}</FixedTableCell>
                <FixedTableCell>{row?.sumEntries?.allFees}</FixedTableCell>
                <FixedTableCell>
                  {row?.approved ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )}
                </FixedTableCell>
                <FixedTableCell>
                  {row?.glApproved ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )}
                </FixedTableCell>
              </TableRow>
            );
          })}
        <TableRow>
          <FixedTableCell colSpan={2} />
          <FixedTableCell variant="head">{t("total")}</FixedTableCell>
          <FixedTableCell variant="head">
            {sumInvoices?.shipmentsCount}
          </FixedTableCell>
          <FixedTableCell variant="head">
            {sumInvoices?.piecesCount}
          </FixedTableCell>
          <FixedTableCell variant="head">{sumInvoices?.weight}</FixedTableCell>
          <FixedTableCell variant="head">
            {sumInvoices?.deliveryFees}
          </FixedTableCell>
          <FixedTableCell variant="head">
            {sumInvoices?.postFees}
          </FixedTableCell>
          <FixedTableCell variant="head">{sumInvoices?.tax}</FixedTableCell>
          <FixedTableCell variant="head">{sumInvoices?.allFees}</FixedTableCell>
          <FixedTableCell colSpan={3}> </FixedTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <Root>
      {loading || pageLoad ? (
        <>
          <FullScreenLoading minHeight="20%" />
          {/* this spans for download font before print */}
          <span className={clsx(classes.barcodeFont)} />
          <span style={{ opacity: 0 }} className={classes.cairoFont}>
            lا
          </span>
        </>
      ) : reportDetails === null ? (
        <NotFound />
      ) : (
        <>
          <div>
            <div className="page-header">{header}</div>

            <div className="page-footer">
              {queryData.organization.reportFooter}
            </div>

            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td>
                    <div className="page-header-space">{header}</div>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <div className={clsx(classes.shipmentsTable)}>{table}</div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <div className="page-footer-space">
                      {queryData.organization.reportFooter}
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </>
      )}
    </Root>
  );
};
export default InvoicePrint;

function TypographyKeyValue(props) {
  const { title, value, hidden, ...restProps } = props;
  return hidden ? null : (
    <Typography variant="body1" paddingBottom={1} {...restProps}>
      <span className={classes.title}>{title}:</span> <span>{value}</span>
    </Typography>
  );
}

function EnhancedTableHead() {
  //*********Table Function*********
  const { t } = useTranslation();
  const headCells = [
    { numeric: true, disablePadding: false, label: t("recordCode") },
    { numeric: true, disablePadding: false, label: t("theDate") },
    {
      numeric: true,
      disablePadding: false,
      label: t("customer"),
    },
    { numeric: true, disablePadding: false, label: t("shipmentsNumber") },
    { numeric: true, disablePadding: false, label: t("pieceCount") },
    { numeric: true, disablePadding: false, label: t("weight") },
    { numeric: true, disablePadding: false, label: t("deliveryFees") },
    { numeric: true, disablePadding: false, label: t("postFees") },
    { numeric: true, disablePadding: false, label: t("tax") },
    { numeric: true, disablePadding: false, label: t("value") },
    { numeric: true, disablePadding: false, label: t("approved") },
    { numeric: true, disablePadding: false, label: t("glApproved") },
  ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          return (
            <FixedTableCell
              key={index}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </FixedTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
