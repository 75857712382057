function formatMoney(number) {
  return number
    ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : number;
}
export default formatMoney;


// const DEFAULT_FRACTION = 2;

// export function formatNumberAsCurrency(value, fraction) {
//   const currencyFormat = new Intl.NumberFormat('de-DE', {
//     minimumFractionDigits: fraction?.minimum ?? DEFAULT_FRACTION,
//     maximumFractionDigits: fraction?.maximum ?? DEFAULT_FRACTION,
//   });
//   return value != null ? currencyFormat.format(value) : '';
// }