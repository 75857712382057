import React from "react";
import { styled } from "@mui/material/styles";
import Grid from '@mui/material/Unstable_Grid2';
// import ThumbsUpDownOutlinedIcon from '@mui/icons-material/ThumbsUpDownOutlined';
import { SlLike, SlDislike } from "react-icons/sl";
import * as gqlb from "gql-query-builder";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Rating } from 'react-simple-star-rating'
import { useState } from "react";
import { Button, Typography } from "@mui/material";
// import { Box } from "@mui/system";
// import useWidth from "../../Hooks/useWidth";
import { useTranslation } from "react-i18next";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
// import { LockOutlined } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import useWidth, { isWidthDown } from "../../Hooks/useWidth";

const PREFIX = "Review";

const classes = {
    rateError: `${PREFIX}-rateError`,

};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
    [`& .${classes.rateError}`]: {
        color: theme.palette.error.main
    }
}));

export const VALIDATE_DELIVERY_AGENT_TOKEN = gqlb.query({
    operation: "validateDeliveryAgentReviewToken",
    variables: {
        token: {
            type: "String",
            required: true,
        },
    },
});

export const REVIEW_DELIVERY_AGENT = gqlb.mutation({
    operation: "reviewDeliveryAgent",
    fields: ["id"],
    variables: {
        input: {
            type: "ReviewDeliveryAgentInput",
            required: true,
        },
    },
});

export default function Review(props) {
    const token = props.match.params.token?.trim();
    const { t, i18n } = useTranslation();

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm();
    const screenWidth = useWidth();
    const isScreenSmall = isWidthDown("sm", screenWidth);

    const [saveReview, { loading: saveReviewLoading }] = useMutation(
        gql`
            ${REVIEW_DELIVERY_AGENT.query}
        `
    );

    const { loading, data } = useQuery(
        gql`
            ${VALIDATE_DELIVERY_AGENT_TOKEN.query}
        `,
        {
            fetchPolicy: "network-only",
            variables: { token: token },
        }
    );

    const [ratingValue, setRatingValue] = useState(0)
    const [rateError, setRateError] = useState(false)
    const [rateDone, setRateDone] = useState(false)

    const handleRating = (rate) => {
        setRatingValue(rate)
    }

    const onSubmit = (data) => {
        !ratingValue && setRateError(true)
        ratingValue && saveReview({
            variables: {
                input: {
                    rate: ratingValue,
                    token: token,
                    notes: data.notes ? data.notes : null
                },
            }
        })
            .then((data) => {
                data.data.reviewDeliveryAgent.id && setRateDone(true)
            })
            .catch(({ graphQLErrors }) => {

            });
    };

    return (
        <Root>
            {loading ? <FullScreenLoading height={"100%"} /> :
                data ?
                    !rateDone ? <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid Grid container spacing={5} justifyContent={"center"} width={"100%"} m={0}>
                            <Grid item xs={12} textAlign={"center"} sx={{ direction: i18n.language === "en" ? "rtl" : "" }}>
                                <SlDislike size={isScreenSmall ? 50 : 80} color="gray" style={{ margin: "0 5px" }} />
                                <SlLike size={isScreenSmall ? 50 : 80} color="gray" style={{ margin: "0 5px" }} />
                            </Grid>
                            <Grid item xs={12} textAlign={"center"}>
                                <Typography variant="h5" color={"text.primary"} sx={{ textTransform: "capitalize" }}>{t("deliveryAgentReview")}</Typography>
                            </Grid>
                            <Grid item xs={12} textAlign={"center"}>
                                <Rating
                                    onClick={handleRating}
                                    ratingValue={ratingValue}
                                    size={isScreenSmall ? 30 : 60}
                                    label
                                    transition
                                    fillColor='orange'
                                    emptyColor='gray'
                                    className='foo'
                                    allowFraction
                                    onPointerLeave={() => {
                                        ratingValue && setRateError(false)
                                    }}
                                />
                                {rateError && <Typography variant="body1" className={classes.rateError}>{t("PleaseSelectRate")}</Typography>}
                            </Grid>
                            <Grid item xs={12} sm={8} md={4} textAlign={"center"} >
                                <ControlMUItextField
                                    name={"notes"}
                                    label={t("notes")}
                                    errors={errors}
                                    control={control}
                                    margin="normal"
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={12} textAlign={"center"} p={0}>
                            </Grid>
                            <Grid item xs={12} sm={8} md={4} textAlign={"center"} >
                                <Button
                                    fullWidth
                                    className={classes.button}
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={saveReviewLoading}
                                    startIcon={
                                        saveReviewLoading ? (
                                            <CustomSpinner
                                                size={8}
                                                css={{ fontSize: "10px !important" }}
                                                name={"PulseLoader"}

                                            />
                                        ) : (
                                            <SlLike style={{ margin: "0 8px" }} />
                                        )
                                    }
                                    sx={{ color: "white" }}
                                >
                                    {t("rate")}
                                </Button>
                            </Grid>
                        </Grid>
                    </form> :
                        <Grid container spacing={2} justifyContent={"center"} width={"100%"} m={0}>
                            <Grid item xs={12} textAlign={"center"}>
                                <SlLike size={isScreenSmall ? 50 : 80} color="gray" style={{ margin: "0 5px" }} />
                            </Grid>
                            <Grid item xs={12} textAlign={"center"}>
                                <Typography variant="h4" color={"text.primary"}>{t("ThankYouForYourReview")}</Typography>
                            </Grid>
                        </Grid>
                    :
                    <Grid container spacing={2} justifyContent={"center"} width={"100%"} m={0}>
                        <Grid xs={12} textAlign={"center"}>
                            "INVALED_TOKEN"
                        </Grid>
                    </Grid>
            }
        </Root>
    );
}
