import { IconButton, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { dateFormatLL } from "../../helpers/dateFunctions";
import { useSnackbar } from "notistack";
import Collapse from "@mui/material/Collapse";
import { DELETE_PAYMENT_ENTRIES_MUTATION } from "./PaymentListQuary";
import { gql, useMutation } from "@apollo/client";

const usePaymentPickupsDetails = ({
  type,
  approved,
  typeData,
  refetchPickupDataQuery,
  setPickupData: removePickup,

}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deletePickupEntries, { loading: deletePickupEntriesLoading }] = useMutation(
    gql`
      ${DELETE_PAYMENT_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const deletePickup = (paymentPickup) => (
    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={!approved || paymentPickup.pickup?.pendingDelete}
        onClick={() => {
          removePickup(prev => {
            const pickups = prev.pickedPickup.map(e => ({
              ...e,
              pickup: {
                ...e.pickup,
                pendingDelete: e.pickup.id === paymentPickup.pickup.id ? true : e.pickup.pendingDelete
              }
            }));
            return {
              ...prev,
              pickedPickup: pickups
            }
          })
          deletePickupEntries({
            variables: {
              input: {
                paymentId: typeData?.id,
                pickupIds: [paymentPickup.pickup.id]
              }
            }
          }).then(() => {
            refetchPickupDataQuery()
            enqueueSnackbar(t("successfullyDeletedRecord"), {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          }).catch((error) => {
            enqueueSnackbar(error.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          })
        }}
      >
        {deletePickupEntriesLoading && paymentPickup.pickup?.pendingDelete ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );
  /* --------------------------------- DLVBY --------------------------------- */
  const pickupTableHead = [
    "#",
    "requestCode",
    "date",
    "customer",
    "notes",
    "deservedAmount",
    removePickup ? "" : null,
  ];
  const pickupTableBody = (paymentPickup, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink pathname={`/admin/pickups/${paymentPickup?.pickup?.id}`}>
          {paymentPickup?.pickup?.code}
        </CellLink>
        <FixedTableCell>
          {dateFormatLL(paymentPickup?.pickup?.date)}
        </FixedTableCell>
        <CellLink pathname={`/admin/customers/${paymentPickup?.pickup?.customer?.id}`}>
          {paymentPickup?.pickup?.customer?.name}
        </CellLink>
        <FixedTableCell>
          {paymentPickup?.pickup?.notes}
        </FixedTableCell>
        <FixedTableCell>{paymentPickup?.paidAmount}</FixedTableCell>
        {removePickup && deletePickup(paymentPickup)}
      </TableRow>
    );
  };

  const paymentSum = (typeData) => ({
    paidAmount: typeData?.sumEntriesPickup?.paymentAmount,
  })

  const pickupTableTotal = paymentSum(typeData);

  const sumTableNumber = (pickup, tableTotal) => {
    // Object.entries(tableTotal).forEach(([k, v]) => {
    //   k === "paidAmount"
    //     ? (tableTotal[k] += pickup[k] ?? 0)
    //     : (tableTotal[k] += pickup.pickup[k] ?? 0);
    // });
  };
  /* --------------------------------- Manifests Object --------------------------------- */

  const paymentsObject = {
    DLVBY: {
      id: "2",
      customer: true,
      label: t("manifestDetails"),
      tableHead: pickupTableHead,
      tableBody: pickupTableBody,
      tableTotal: Array.isArray(typeData) ? typeData.map(item => paymentSum(item)) : pickupTableTotal,
      sumTableNumber,
    },
  };

  return {
    details: paymentsObject[type],
  };
};

export default usePaymentPickupsDetails;
