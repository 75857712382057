import React, { useState, useEffect, Fragment, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Collapse,
  Paper,
  InputAdornment,
  Box,
  IconButton,
  Dialog,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Slide
} from "@mui/material";
import { gql, useMutation, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { LocationOn, Close } from "@mui/icons-material";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Can } from "../HOC/CustomComponents/Secured";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import {
  LIST_CURRENCIES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_SHIPPING_SERVICES_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
  LIST_ZONES_DROPDOWN,
  LIST_CONCIERGE_PROVIDERS_DROPDOWN
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import TitleAppBar from "../../Layout/TitleAppBar";
import { CALCULATE_FEES_QUERY, CONCIERGE_REQUEST_BY_ID_QUERY, SAVE_CONCIERGE_REQUEST_MUTATION, CUSTOMER_ID } from "./Graphql";
import { Globals } from "../HOC/Classes/Globals";
import { selectedIdIsValid } from "../HOC/CustomFunctions/selectedIsValid";
import getMobileData, { getFormatNumber, validNumber } from "../../helpers/asYouType";
import MuiPhone from "../HOC/MUI/MUIphoneNumber";
import MapComponent from "../HOC/GoogleMap/PickpUpMap";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FormButton from "../CustomComponents/Buttons/FormButton";
import MUIDateTime from "../HOC/MUI/MUIDateTime";

const PREFIX = "conciergeForm";

const classes = {
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  shipmentList: `${PREFIX}-shipmentList`,
  overlay: `${PREFIX}-overlay`,
  button: `${PREFIX}-button`,
  main: `${PREFIX}-main`,
  customHeight: `${PREFIX}-customHeight`,
  loadingFooter: `${PREFIX}-loadingFooter`,
  footer: `${PREFIX}-footer`,
  calcValue: `${PREFIX}-calcValue`,
  calcTitle: `${PREFIX}-calcTitle`,
  totalCalc: `${PREFIX}-totalCalc`,
  spacing: `${PREFIX}-spacing`,
  feesField: `${PREFIX}-feesField`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    height: "10px",
    margin: theme.spacing(2, 0, 0, 0),
  },
  [`& .${classes.feesField}`]: {
    padding: theme.spacing(0, 5),
    marginTop: theme.spacing(1),
    "& input": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      textAlign: "center",
      fontSize: 15,
    },
  },
  [`& .${classes.main}`]: {
    height: "calc(100vh - (48px + 40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (48px + 40px + 56px))",
    },
  },

  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.shipmentList}`]: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.7,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },
  [`& .${classes.loadingFooter}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    top: 0,
    left: 0,
    "& .MuiGrid-container": {
      height: "100%",
    },
  },
  [`& .${classes.footer}`]: {
    width: "100%",
    fontSize: "15px",
    padding: theme.spacing(1),
    zIndex: 10,
    bottom: 0,
    textAlign: "center",
    position: "sticky",
    backgroundColor: theme.palette.background.paper,
    borderWidth: "2px 0 0",
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
  [`& .${classes.calcValue}`]: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    fontSize: 15
  },

  [`& .${classes.calcTitle}`]: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
    fontSize: 13,
    color: theme.palette.text.primary,
  },

  [`& .${classes.totalCalc}`]: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      marginTop: theme.spacing(2),
    },
  },

}));
const GridLoadingStyle = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (48px + 40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (48px + 40px + 56px))",
    },
  },
  [`&.${classes.customHeight}`]: {
    height: "200px !important",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ConciergeRequestForm(props) {
  const { t } = useTranslation();
  const formType = props.match.params.type?.toUpperCase();
  const conciergeId = props.match.params.id;
  const urlUpdateShipmentId = props?.match?.params?.id;
  const [pathURL] = useState(props.match.path)
  const [conciergeType, setconciergeType] = useState(formType ? formType : null);
  const [editable, setEditable] = useState(true);
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    service: null,
    providerCode: null,
    zone: null,
    subzone: null,
    transactionType: null,
    currencyId: null,
    status: "NEW"
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    getValues
  } = useForm({
  });
  const watchFees = (name) =>
    watch(`${name}`) || watch(`${name}`) === 0;
  const validFees =
    watchFees("zoneId") ||
    watchFees("subzoneId") ||
    watchFees("amount") ||
    watchFees("currencyId") ||
    watchFees("customerId");
  const user = Globals.user;
  const countryCode = Globals.settings.countryCode
  const overwriteFees = user.hasPermission("shipping.concierge_request.overwrite_fees");
  const transactionTypePermission = user.hasPermission(
    "shipping.concierge_request.choose_transaction_type"
  );
  const feesPermission = overwriteFees ? !validFees : true;
  const [calcFeesState, setCalcFeesState] = useState({
    serviceId: null,
    date: null,
    zoneId: null,
    subzoneId: null,
    currencyId: null,
    amount: null,
    customerId: null,
    render: false,

  });
  const [customerType, setCustomerType] = useState("");
  const firstCalcUpdate = useRef(true);
  const [calcFeesValue, setCalcFeesValue] = useState({
    total: 0,
    localAmount: 0,
    exchangeRate: 0
  });
  // const icons = [
  //   {
  //     id: "print",
  //     title: "print",
  //     action: () =>
  //       window.open(
  //         `${window.location.origin}/report/print/manifest/${watch(
  //           "id"
  //         )}/${conciergeType}`
  //       ),
  //     icon: Print,
  //     disabled: true,
  //   },
  // ]
  const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD");
  const dateFormatWithHours = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");
  const [fieldsState, setFieldsState] = useState({
    createdAt: null,
  });
  const { enqueueSnackbar } = useSnackbar();


  const [saveConciergeRequest, { loading: saveConciergeRequestLoad }] = useMutation(
    gql`
          ${SAVE_CONCIERGE_REQUEST_MUTATION.query}
        `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {

      },
    }
  );
  const settingsPermission = user.hasPermission(
    "shipping.concierge_request.choose_transaction_type"
  );


  const onSubmit = (data) => {
    data["amount"] = parseInt(data["amount"]);
    data["date"] = data["date"] && dateFormatWithHours(data["date"]);
    data["deliveryFees"] = parseFloat(data["deliveryFees"]);
    data["collectionFees"] = parseFloat(data["collectionFees"]);
    if (Globals.settings.allowPhoneKey) {
      const checkPhone = validNumber(data.phone, data.phoneCode)
      let names = [
        {
          name: "phone",
          validate: checkPhone.valid,
          message: checkPhone.message,
        },
      ];

      if (!checkPhone.valid) {
        names.map((e) => !e.validate && setError(e.name, { type: 'custom', message: t(e.message) }))
        return
      }

      data.phone = getFormatNumber(data.phone, data.phoneCode)
    }
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
      if (
        key === "phoneCode" ||
        (!customerPermission && key === "deliveryFees") ||
        (!customerPermission && key === "collectionFees") ||
        (Boolean(autocompleteValues.status !== "NEW") && key === "branchId")
      ) {
        delete data[key];
      }
    }
    saveConciergeRequest({
      variables: {
        input: {
          ...data,
          ...(!transactionTypePermission && { typeCode: conciergeType }),
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/concierge-request/${data?.data?.saveConciergeRequest?.id}`,)
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log("error", graphQLErrors);
        if (!graphQLErrors[0]["extensions"]?.validation)
          enqueueSnackbar(graphQLErrors[0].message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
      });

  }
  const resetFeesValue = () => {
    setValue("deliveryFees", "");
    setValue("collectionFees", "");
    setCalcFeesValue((prev) => ({
      ...prev,
      //this spreed object to render field when change customer
      total: 0,
      localAmount: 0,
      exchangeRate: 0,
    }));
  };
  const calcFeesHandler = ({ value, name, feesAvailable }) => {
    let deleteFees = {
      deliveryFees: null,
      collectionFees: null,
    };

    if (feesAvailable) {
      deleteFees = {};
    } else {
      resetFeesValue();
    }
    let fieldValue = {
      // eslint-disable-next-line eqeqeq
      [name]: value || value === 0 ? value : null,
    };
    firstCalcUpdate.current = false;
    setCalcFeesState((prev) => ({
      ...prev,
      ...deleteFees,
      ...fieldValue,
      render: !calcFeesState.render,
    }));
  };

  const parseData = (data) => {
    return data;
  };

  const customerPermission = user.hasPermission(
    "shipping.concierge_request.choose_customer"
  );
  const customerId = watch("customerId") ?? user?.account?.id;
  const [calculationChecker, setCalculationChecker] = useState({});
  const validateCalcFeesField = (name) => calcFeesState[name] !== null;
  const canCalculate = () =>
    validateCalcFeesField("amount") &&
    calculationChecker?.["currencyId"] &&
    calculationChecker?.["zoneId"] &&
    calculationChecker?.["subzoneId"] &&
    calculationChecker?.["serviceId"]
  // (customerPermission && validateCalcFeesField("customerId"));

  const SETTINGS = gqlb.query({
    operation: "shippingSettings",
    fields: settingsPermission
      ? [
        { defaultShippingService: ["id", "name"] },
        { defaultTransactionType: ["id", "name"] },
        // { defaultPaymentType: ["code", "name"] },
        "forcePostalCode",
        "multiCountries",
      ]
      : [
        { defaultShippingService: ["id", "name"] },
        "forcePostalCode",
        "multiCountries",
      ],
    variables: {},
  });

  const { data: settings } = useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        // if (settingsPermission) {
        //   if (!urlUpdateShipmentId) {

        //     setAutocompleteValues((prev) => ({
        //       ...prev,
        //       transactionType: data.shippingSettings.defaultTransactionType,
        //     }));
        //   }
        // }
      },
    }
  );
  const currencyCode = Globals?.settings?.localCurrency?.name??"";;
  const { data: conciergeByIdQuery, loading: conciergeByIdLoad } = useQuery(
    gql`
      ${CONCIERGE_REQUEST_BY_ID_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !conciergeId,
      variables: {
        id: parseInt(conciergeId),
      },
      onCompleted: (data) => {
        const concierge = data?.conciergeRequest
        setCustomerType(concierge.customer.type.code);
        const numbersObj = [
          {
            name: "phone",
            codeName: "phoneCode",
            value: getMobileData(concierge.phone, countryCode)
          },
        ]
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        setFieldsState((prev) => ({ ...prev, createdAt: concierge?.date }))
        setconciergeType(concierge?.transactionType?.type?.code)
        setValue("date", concierge?.date);
        setValue("id", parseInt(conciergeId));
        setValue("address", concierge?.address);
        setValue("amount", concierge?.amount);
        setValue("accountNumber", concierge?.accountNumber);
        setAutocompleteValues({
          branch: concierge?.branch,
          customer: concierge?.customer,
          service: concierge?.service,
          providerCode: concierge?.provider,
          zone: concierge?.zone,
          subzone: concierge?.subzone,
          transactionType: concierge?.transactionType,
          currencyId: concierge?.currency,
          status: concierge?.status.code,

        })
        setEditable(concierge?.editable);

        setValue(
          "deliveryFees",
          parseFloat(concierge.deliveryFees?.toFixed(2))
        );
        setValue(
          "collectionFees",
          parseFloat(concierge.collectionFees?.toFixed(2))
        );
        setCalcFeesValue((prev) => ({
          ...prev,
          //this spreed object to render field when change customer
          total: (concierge.deliveryFees + concierge.collectionFees + concierge.localAmount),
          localAmount: concierge.localAmount,
          exchangeRate: concierge.exchangeRate,
        }));
        setCalcFeesState((prev) => ({
          ...prev,
          serviceId: parseInt(concierge.service.id),
          zoneId: parseInt(concierge.zone.id),
          subzoneId: parseInt(concierge.subzone.id),
          currencyId: parseInt(concierge?.currency.id),
          amount: parseInt(concierge.amount),
          deliveryFees: concierge.deliveryFees,
          collectionFees: concierge.collectionFees,
          date: concierge?.date,
          ...(customerPermission && {
            customerId: parseInt(concierge?.customer?.id),
          }),
        }));
        setCalculationChecker((prev) => ({
          customerId: true,
          serviceId: true,
          zoneId: true,
          subzoneId: true,
          currencyId: true,
        }));
      },
      onError: ({ graphQLErrors }) => {
        console.log(graphQLErrors);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      }
    },
  );


  const firstUpdate = useRef(true);

  const updateShipmentDetails = conciergeByIdQuery?.conciergeRequest;

  const invalidCustomer =
    urlUpdateShipmentId &&
    (customerPermission
      ? false
      : updateShipmentDetails?.customer.id !== customerId);
  const skipCustomerQuery = customerPermission
    ? urlUpdateShipmentId
      ? !watch("customerId") || firstUpdate.current
      : !watch("customerId")
    : urlUpdateShipmentId;
  const { data: customerData } = useQuery(
    gql`
      ${CUSTOMER_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: skipCustomerQuery,
      variables: {
        id: customerPermission
          ? parseInt(watch("customerId"))
          : customerId,
        input: {
          ...(!user.account && {
            customerId: customerPermission
              ? parseInt(watch("customerId"))
              : customerId,
          }),
          active: true,
        },
      },
      onCompleted: (data) => {
        const customer = data.customer;
        setCustomerType(customer.type.code);
        const numbersObj = [

          {
            name: "phone",
            codeName: "phoneCode",
            value: getMobileData(customer?.phone, countryCode)
          },
        ]
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase(), {
              shouldValidate: true,
            });
            setValue(i.name, i.value.nationalNumber, {
              shouldValidate: true,
            });
          }
        });

        customer.address &&
          setValue("address", customer.address, {
            shouldValidate: true,
          });


      },
    }
  );
  const { loading: claculateFeesLoading } = useQuery(
    gql`
          ${CALCULATE_FEES_QUERY.query}
        `,
    {
      nextFetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: calcFeesState.render,
      fetchPolicy: "no-cache",
      skip:
        !canCalculate()
        || firstCalcUpdate.current
        || invalidCustomer ||
        !calcFeesState?.serviceId
        || !calcFeesState?.subzoneId
        || !calcFeesState?.currencyId,



      variables: {
        input: {
          serviceId: calcFeesState?.serviceId,
          zoneId: calcFeesState?.zoneId,
          subzoneId: calcFeesState?.subzoneId,
          currencyId: calcFeesState?.currencyId,
          amount: calcFeesState?.amount,
          ...(calcFeesState?.deliveryFees !== null && {
            deliveryFees: calcFeesState?.deliveryFees,
          }),
          ...(calcFeesState?.collectionFees !== null && {
            collectionFees: calcFeesState?.collectionFees,
          }),
          ...(calcFeesState?.date !== null && {
            date: dateFormat(calcFeesState?.date),
          }),
          ...(customerPermission && { customerId: calcFeesState?.customerId }),
        },
      },
      onCompleted: (data) => {
        const shippingFees = data.calculateConciergeRequestFees;
        setValue(
          "deliveryFees",
          parseFloat(shippingFees.delivery?.toFixed(2))
        );
        setValue(
          "collectionFees",
          parseFloat(shippingFees.collection?.toFixed(2))
        );
        setCalcFeesValue((prev) => ({
          ...prev,
          //this spreed object to render field when change customer
          total: shippingFees.total,
          localAmount: shippingFees.localAmount,
          exchangeRate: shippingFees.exchangeRate,
        }));
      },
      onError: ({ graphQLErrors }) => {
        console.log(graphQLErrors);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      }
    }
  );
  const countryId =
    customerData?.customer?.zone?.branch?.country?.id
  const customerChange = (e) => {
    const value = e?.id;
    firstUpdate.current = false;
    setCalculationChecker((prev) => ({
      currencyId: prev.currencyId,
      customerId: true,
    }));
    calcFeesHandler({
      value: value,
      name: "customerId",
    });
    // allowWarehousing && EmptyTextFields();
    setValue("address", " ");
    setValue("zoneId", "");
    setValue("subzoneId", "");
  };

  function currentValueMatchingNewValues(data, fieldName) {
    const currentValue = watch(`${fieldName}`);
    if (typeof currentValue === "number") {
      const isValueValid = selectedIdIsValid(data, currentValue);
      if (isValueValid) {
        setCalculationChecker((prev) => ({ ...prev, [fieldName]: true }));
      } else {
        calcFeesHandler({ value: null, name: fieldName });
        setValue(`${fieldName}`, "");
      }
    }
  }

  const onCompleteService = (data, name) => {
    currentValueMatchingNewValues(data, "serviceId");
    const defaultService =
      settings?.shippingSettings?.["defaultShippingService"] ??
      data.listShippingServicesDropdown[0];

    const valid = selectedIdIsValid(data, defaultService?.id);
    if (valid && !updateShipmentDetails) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        service: { ...defaultService },
      }));

      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        currencyId: prev.currencyId,
        serviceId: true,
      }));

      calcFeesHandler({ value: defaultService?.id, name: "serviceId" });
    }

  };

  const onCompleteZones = (data, name) => {
    currentValueMatchingNewValues(data, "zoneId");
    const defaultZone = customerData?.customer?.zone;

    const valid = selectedIdIsValid(data, defaultZone?.id);
    if (valid && !updateShipmentDetails) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        zone: { ...defaultZone },
      }));

      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        currencyId: prev.currencyId,
        zoneId: true,
      }));
      calcFeesHandler({ value: defaultZone?.id, name: "zoneId" });
    }

  };
  const onCompleteCurrencyId = (data, name) => {
    currentValueMatchingNewValues(data, "currencyId");
    const valid = selectedIdIsValid(data, autocompleteValues.currencyId);
    if (valid && !updateShipmentDetails) {
      setAutocompleteValues((prev) => ({
        ...prev,
        currencyId: { ...autocompleteValues.currencyId },
      }));
      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        zoneId: prev.zoneId,
        subzoneId: prev.subzoneId,
        currencyId: true,
      }));
      calcFeesHandler({ value: autocompleteValues.currencyId, name: "currencyId" });
    }

  };
  const onCompleteSubzones = (data, name) => {
    currentValueMatchingNewValues(data, "subzoneId");

    const defaultSubZone = customerData?.customer?.subzone;
    const valid = selectedIdIsValid(data, defaultSubZone?.id);
    if (valid && !updateShipmentDetails) {
      setAutocompleteValues((prev) => ({
        ...prev,
        //this spreed object to render field when change customer
        subzone: { ...defaultSubZone },
      }));
      setCalculationChecker((prev) => ({
        customerId: prev.customerId,
        serviceId: prev.serviceId,
        zoneId: prev.zoneId,
        currencyId: prev.currencyId,
        subzoneId: true,
      }));
      calcFeesHandler({ value: defaultSubZone?.id, name: "subzoneId" });
    }
  };

  const onChangeSenderSubzone = (e) => {
    const value = e?.id;
    setCalculationChecker((prev) => ({
      customerId: prev.customerId,
      serviceId: prev.serviceId,
      currencyId: prev.currencyId,
      zoneId: prev.zoneId,
      subzoneId: true,
    }));
    calcFeesHandler({ value: value, name: "subzoneId" });
  };
  const [openMapDialogRecipient, setOpenMapDialogRecipient] = useState(false);
  const [openMapDialogSender, setOpenMapDialogSender] = useState(false);
  const [locationValue, setLocationValue] = useState();

  const handleClickOpenMapSender = () => {
    setOpenMapDialogSender(true);
  };

  const handleCloseMap = () => {
    setOpenMapDialogSender(false);
    setOpenMapDialogRecipient(false);
  };
  const addAddress = () => {
    // Convert coordinates to address using OpenCage Geocoding API
    if (locationValue) {
      const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${locationValue.lat}+${locationValue.lng}&key=65120718cf98432497c7792b14d5260e`;

      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          if (data.results && data.results.length > 0) {
            const formattedAddress = data.results[0].formatted;
            if (openMapDialogSender) {
              setValue("address", formattedAddress);
            }
            else {
              setValue("address", formattedAddress)
            }
          } else {
            console.error('Geocoding failed');
          }
        })
        .catch(error => console.error('Error fetching geocoding data:', error));
    }
  };
  return (conciergeByIdLoad ?
    <GridLoadingStyle container item justifyContent="center" className={clsx(classes.main)}>
      <FullScreenLoading height={"100%"} />
    </GridLoadingStyle>
    : !editable ? (
      <Can permission="uneEditable" />
    ) :
      <Root >
        <Dialog
          fullScreen
          open={openMapDialogRecipient || openMapDialogSender}
          onClose={handleCloseMap}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseMap}
                aria-label="close"
                size="large"
              >
                <Close />
              </IconButton>
              <Typography
                color="inherit"
                variant="h6"
                className={classes.title}
              >
                {t("map")}
              </Typography>
              <Button autoFocus color="inherit" onClick={() => {
                addAddress();
                handleCloseMap();
              }} >
                {t("save")}
              </Button>
            </Toolbar>
          </AppBar>
          <MapComponent setLocationValue={setLocationValue} heightValue={"100vh"} />
        </Dialog>

        <TitleAppBar path={pathURL} type={conciergeType}>
          {/* <LongMenu icons={icons} /> */}
        </TitleAppBar>
        <Grid
          container
          sx={{ display: "flex", alignContent: "space-between", height: "calc(100vh - (48px + 40px + 64px))", overflow: "auto", }}

        >
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            spacing={2}
            sx={{ padding: "30px", width: "100%" }}
          >
            <Paper container component={Grid} sx={{ padding: 1 }}>

              <Grid
                spacing={2}
                container

              >

                <Grid sm={4} xs={12}>
                  <MUIDateTime
                    name="date"
                    label={t("theDate")}
                    control={control}
                    value={fieldsState.createdAt}
                    onChange={(e) => {
                      if (dateFormat(fieldsState.createdAt) !== dateFormat(e)) {
                        setCalculationChecker((prev) => ({
                          customerId: prev.customerId,
                          serviceId: prev.serviceId,
                          zoneId: prev.zoneId,
                          subzoneId: prev.subzoneId,
                          currencyId: false,
                        }));
                        calcFeesHandler({
                          value: e,
                          name: "date",
                        });
                      }
                      setFieldsState((prev) => ({ ...prev, createdAt: e }))
                    }}
                    rules={{ required: t("fieldIsRequired") }}
                  />
                </Grid>
                <Can showException permission="shipping.concierge_request.choose_branch">
                  <Grid sm={4} xs={12}>
                    <ListBranches
                      defaultValue={autocompleteValues.branch}
                      control={control}
                      errors={errors}
                      disabled={
                        Boolean(autocompleteValues.status !== "NEW")
                      }
                      rules={{ required: t("fieldIsRequired") }}
                      name={"branchId"}
                      onChangeValue={(e) => {
                        setCalculationChecker((prev) => ({
                          customerId: false,
                          serviceId: false,
                          zoneId: false,
                          subzoneId: false,
                          currencyId: true

                        }));
                      }}
                    />
                  </Grid>
                </Can>
                <Can showException permission={transactionTypePermission}>
                  <Grid sm={4} xs={12}>
                    <CustomAutocomplete
                      defaultValue={autocompleteValues.transactionType}
                      control={control}
                      errors={errors}
                      disabled={
                        Boolean(!watch("branchId"))
                      }
                      selectFirst={true}
                      parseData={(data) => parseData(data)}
                      name={"transactionTypeId"}
                      label={t("transactionType")}
                      rules={{ required: t("fieldIsRequired") }}
                      query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                      skip={
                        (urlUpdateShipmentId &&
                          !updateShipmentDetails?.id) ||
                        invalidCustomer ||
                        Boolean(!watch("branchId"))
                      }
                      variables={{
                        input: {
                          type: conciergeType?.toUpperCase(),
                          branchId: {
                            value: watch("branchId"),
                            includeNull: true,
                          },
                        },
                      }}

                    />
                  </Grid>
                </Can>
                <Can showException permission="shipping.concierge_request.choose_customer">
                  <Grid sm={4} xs={12}>
                    <CustomAutocomplete
                      defaultValue={autocompleteValues.customer}
                      control={control}
                      errors={errors}
                      rules={{ required: t("fieldIsRequired") }}
                      name={"customerId"}
                      label={t("customer")}
                      disabled={
                        !watch("branchId")
                      }
                      parseData={(data) => parseData(data)}
                      query={LIST_CUSTOMERS_DROPDOWN.query}
                      skip={
                        (urlUpdateShipmentId &&
                          !updateShipmentDetails?.id) ||
                        invalidCustomer
                        || !watch("branchId")
                      }
                      variables={{
                        input: {
                          branchId: {
                            value: watch("branchId"),
                            includeNull: true,
                          },
                          active: true,
                          typeCode: "CONCIERGE"

                        },
                      }}
                      onCompleted={(data) => {
                        currentValueMatchingNewValues(
                          data,
                          "customerId"
                        );
                      }}
                      onChangeValue={(e) => {
                        customerChange(e);
                      }}
                    />

                  </Grid></Can>
                <Grid sm={4} xs={12}>
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    rules={{ required: t("fieldIsRequired") }}
                    name={"serviceId"}
                    label={t("service")}
                    onCompleted={onCompleteService}
                    onChangeValue={(e) => {
                      const value = e?.id
                      setCalculationChecker((prev) => ({
                        currencyId: prev.currencyId,
                        customerId: prev.customerId,
                        serviceId: true,
                      }));
                      calcFeesHandler({
                        value: value,
                        name: "serviceId",
                      });
                      setValue("zoneId", "");
                      setValue("subzoneId", "");
                    }}
                    query={LIST_SHIPPING_SERVICES_DROPDOWN.query}
                    parseData={(data) => parseData(data)}
                    skip={
                      (customerPermission
                        ? !calculationChecker?.["customerId"] ||
                        !watch("customerId")
                        : false) ||
                      (urlUpdateShipmentId &&
                        !updateShipmentDetails?.id) ||
                      invalidCustomer ||
                      !customerType
                    }
                    variables={{
                      input: {
                        ...(!user.account && {
                          customerId: watch("customerId"),
                        }),
                        active: true,
                        customerTypeCode: customerType,
                      },
                    }}
                    defaultValue={autocompleteValues.service}
                  />
                </Grid>

                <Grid sm={4} xs={12}>
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    rules={{ required: t("fieldIsRequired") }}
                    name={"providerId"}
                    label={t("serviceProvider")}
                    parseData={(data) => parseData(data)}
                    query={LIST_CONCIERGE_PROVIDERS_DROPDOWN.query}
                    defaultValue={autocompleteValues.providerCode}

                  />
                </Grid>

                <Grid sm={4} xs={12}>
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    rules={{ required: t("fieldIsRequired") }}
                    disabled={
                      Boolean(!watch("serviceId"))
                    }
                    label={t("zone")}
                    name={"zoneId"}
                    onCompleted={(data) =>
                      onCompleteZones(data, "zoneId")
                    }
                    onChangeValue={(e) => {
                      const value = e?.id;
                      setCalculationChecker((prev) => ({
                        currencyId: prev.currencyId,
                        customerId: prev.customerId,
                        serviceId: prev.serviceId,
                        zoneId: true,
                      }));

                      calcFeesHandler({
                        value: value,
                        name: "zoneId",
                      });
                    }}
                    query={LIST_ZONES_DROPDOWN.query}
                    parseData={(data) => parseData(data)}
                    skip={
                      !watch("serviceId") ||
                      !calculationChecker?.["serviceId"] ||
                      !customerId ||
                      (urlUpdateShipmentId &&
                        !updateShipmentDetails?.id) ||
                      invalidCustomer
                    }
                    variables={{
                      input: {
                        active: true,
                        parentId: null,
                        ...(countryId && { countryId }),
                        service: {
                          serviceId: watch("serviceId"),
                          ...(!user.account && {
                            customerId: customerId,
                          }),
                        },
                      },
                    }}
                    defaultValue={autocompleteValues.zone}
                  />
                </Grid>
                <Grid sm={4} xs={12}>
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    rules={{ required: t("fieldIsRequired") }}
                    disabled={

                      Boolean(!watch("zoneId"))
                    }
                    name={"subzoneId"}
                    label={t("subzone")}
                    onCompleted={onCompleteSubzones}
                    onChangeValue={onChangeSenderSubzone}
                    query={LIST_ZONES_DROPDOWN.query}
                    parseData={(data) => parseData(data)}
                    skip={
                      !watch("serviceId") ||
                      !calculationChecker?.["serviceId"] ||
                      !watch("zoneId") ||
                      !customerId ||
                      (urlUpdateShipmentId &&
                        !updateShipmentDetails?.id) ||
                      invalidCustomer
                    }
                    variables={{
                      input: {
                        active: true,
                        parentId: watch("zoneId"),
                        service: {
                          serviceId: watch("serviceId"),
                          ...(!user.account && {
                            customerId: customerId,
                          }),
                        },
                      },
                    }}
                    defaultValue={autocompleteValues.subzone}
                  />
                </Grid>
                <Grid sm={4} xs={12}>
                  <MuiPhone
                    setValue={setValue}
                    codeValue={getValues().phoneCode}
                    control={control}
                    errors={errors}
                    name={"phone"}
                    selectName={"phoneCode"}
                    label={t("phone")}
                    rules={{
                      required: t("fieldIsRequired"),
                    }}

                  />
                </Grid>
                <Grid sm={4} xs={12}>
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    rules={{
                      required: t("fieldIsRequired"),
                    }}
                    name={"address"}
                    label={t("address")}
                    InputProps={{
                      endAdornment: (
                        <IconButton

                          onClick={() => {
                            handleClickOpenMapSender();
                          }}
                          size="large"
                        >
                          <LocationOn />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
                <Grid sm={4} xs={12}>
                  <ControlMUItextField
                    rules={{
                      required: t("fieldIsRequired"),
                    }}
                    control={control}
                    errors={errors}
                    name={"amount"}
                    label={t("amount")}
                    type="number"
                    onChange={(e) => {
                      calcFeesHandler({
                        value: parseInt(e.target.value),
                        name: "amount",
                      });
                    }}
                  />
                </Grid>
                <Grid sm={4} xs={12}>
                  <ControlMUItextField
                    rules={{ required: t("fieldIsRequired") }}
                    name="accountNumber"
                    control={control}
                    label={t("accountNumber")}

                  />
                </Grid>
                <Grid sm={4} xs={12}>
                  <CustomAutocomplete
                    rules={{ required: t("fieldIsRequired") }}
                    control={control}
                    errors={errors}
                    name={"currencyId"}
                    label={t("currency")}
                    onCompleted={(data) =>
                      onCompleteCurrencyId(data, "currencyId")
                    }
                    onChangeValue={(e) => {
                      const value = e?.id;
                      setCalculationChecker((prev) => ({
                        ...prev,
                        currencyId: true,
                      }));
                      calcFeesHandler({
                        value: value,
                        name: "currencyId",
                      });
                    }}
                    parseData={(data) => parseData(data)}
                    query={LIST_CURRENCIES_DROPDOWN.query}
                    variables={{
                      input: {
                        hasExchangeRate: {
                          ...(fieldsState.createdAt !== null && {
                            inDate: dateFormat(fieldsState.createdAt),
                          }),
                        },
                      },
                    }}
                    defaultValue={autocompleteValues.currencyId}
                  />
                </Grid>

              </Grid>
            </Paper>
            <Grid container justifyContent="flex-end" className={classes.spacing}>
              <FormButton disabled={saveConciergeRequestLoad}>
                {saveConciergeRequestLoad ? <ButtonLoading /> : t("save")}
              </FormButton>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.footer}
            justifyContent="space-around"

          >
            {claculateFeesLoading && (
              <Box className={classes.loadingFooter}>
                <CustomSpinner name="PulseLoader" size={10} margin={3} />
              </Box>
            )}
            <Grid container component="span" xs={12} sm={6} >
              <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center" xs={12}>
                <Grid component="span" sm={5} xs={6}  >
                  <CalculationResult
                    title={t("deliveryFees")}
                    currencyCode={currencyCode}
                    control={control}
                    calcFeesHandler={calcFeesHandler}
                    name={"deliveryFees"}
                    readOnly={feesPermission || claculateFeesLoading}
                  />
                </Grid>
                <Grid component="span" sm={5} xs={6}  >
                  <CalculationResult
                    title={t("collectionFees")}
                    currencyCode={currencyCode}
                    control={control}
                    calcFeesHandler={calcFeesHandler}

                    name={"collectionFees"}
                    readOnly={feesPermission || claculateFeesLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid component="span" xs={4} sm={2}>
              <CalculationResult
                title={t("localAmount")}
                currencyCode={currencyCode}
                valuesx={classes.totalCalc}
                value={calcFeesValue.localAmount}
              />
            </Grid>
            <Grid component="span" xs={4} sm={2}>
              <CalculationResult
                title={t("exchangeRate")}
                currencyCode={currencyCode}
                valuesx={classes.exchangeRate}
                value={calcFeesValue.exchangeRate}
              />
            </Grid>
            <Grid component="span" xs={4} sm={2}>
              <CalculationResult
                title={t("totalCost")}
                currencyCode={currencyCode}
                valuesx={classes.totalCalc}
                value={calcFeesValue.total}
              />
            </Grid>

          </Grid>
        </Grid>
      </Root>
  )
}
const CalculationResult = (props) => {
  const {
    title,
    valueStyle,
    value,
    control,
    editable,
    name,
    calcFeesHandler,
    readOnly,
    currencyCode,
  } = props;

  useEffect(() => {
    return () => {
      clearTimeout(timeout[name]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let timeout = {};
  const feesField = control && (
    <ControlMUItextField
      control={control}
      name={name}
      InputProps={{
        ...(currencyCode && {
          endAdornment: (
            <InputAdornment position="end">{currencyCode}</InputAdornment>
          ),
        }),
      }}
      onChange={(e) => {
        const fieldValue = e.target.value;

        clearTimeout(timeout[name]);

        timeout[name] = setTimeout(() => {
          calcFeesHandler({
            value: parseFloat(fieldValue),
            name: name,
            feesAvailable: true,
          });
        }, 1000);
      }}
      disabled={editable}
      className={classes.feesField}
      variant="standard"
      readOnly={readOnly}
    />
  );
  return (
    <Fragment>
      <p className={classes.calcTitle}>{title}</p>
      {!control && (
        <p className={clsx(classes.calcValue, valueStyle)}>
          {value?.toFixed(2) ?? "--"}
          {" " + currencyCode}
        </p>
      )}
      {feesField}
    </Fragment>
  );
};