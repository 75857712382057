import React from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import {
  Paper,
  Typography,
  Collapse,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Slide,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { SAVE_CUSTOMER, CUSTOMERID, SETTINGS, GetPaymentCycleDays } from "./Graphql";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { useMutation, gql, useQuery } from "@apollo/client";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { Basic, MultipleAutocomplete } from "../HOC/MUI/MultipleAutocomplete";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import {
  LIST_BANK_DROPDOWN,
  LIST_COUNTRIES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_PAYMENT_TYPES_DROPDOWN,
  LIST_PRICE_LIST_DROPDOWN,
  LIST_ZONES_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import MUIselect from "../HOC/MUI/MUIselect";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { AddCircleOutline, Close } from "@mui/icons-material";
import PriceListForm from "../PriceList/CreatePriceList";
import { Can } from "../HOC/CustomComponents/Secured";
import Grid from "@mui/material/Unstable_Grid2";
import { selectedIdIsValid } from "../HOC/CustomFunctions/selectedIsValid";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { GetPermissionSlug } from "../../helpers/getPermissionSlug";
import { Globals } from "../HOC/Classes/Globals";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";

import getMobileData, { getFormatNumber, validNumber } from "../../helpers/asYouType";
import MuiPhone from "../HOC/MUI/MUIphoneNumber";

const PREFIX = "CustomerForm";

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  appBar: `${PREFIX}-appBar`,
  priceList: `${PREFIX}-priceList`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.priceList}`]: {
    flexWrap: "nowrap",
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CustomerForm = (props) => {
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    glAccount: null,
    zone: null,
    subzone: null,
    priceList: null,
    bank: null,
    country: null,
    state: null,
    city: null,
    area: null,
    paymentCycleDays: [],
    hideMobileFromCode: []
  });
  const [priceListDialogState, setPriceListDialogState] = useState(false);

  const GlobalSettings = Globals.settings;

  const { t } = useTranslation();
  // const formName = {
  //   B2C: t("createCustomer"),
  //   C2C: t("createIndividual"),
  // };

  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      active: true,
      specifyShipmentCode: false,
      taxable: false,
      shareCollectedFees: true,
      payUncollected: false,
      isMobileHidden: false,
      sendDeliveryOtp: false,
    },
  });
  const { errors } = formState;

  const selectDefaultValue = (data) => {
    return data;
  };

  const closePriceListDialog = () => {
    setPriceListDialogState(false);
  };
  const customerId = parseInt(props.match.params.id);
  const formType = props?.match?.params?.type?.toUpperCase();
  const [customerType, setCustomerType] = useState(formType ? formType : null);

  useQuery(
    gql`
      ${LIST_PAYMENT_TYPES_DROPDOWN.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      variables: {},
      skip: customerId,
      onCompleted: (data) => {
        const paymentTypes = data?.listPaymentTypesDropdown;
        setPaymentTypes(paymentTypes);
        paymentTypes.forEach((row) => {
          customerId
            ? setValue(`${row.code}`, false)
            : setValue(`${row.code}`, true);
        });
      },
    }
  );

  const countryCode = Globals.settings.countryCode
  const eInvoicing = Globals.settings.eInvoicing

  const { data: settings } = useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { data: updateCustomer, loading } = useQuery(
    gql`
      ${CUSTOMERID.query}
    `,
    {
      skip: !customerId,
      notifyOnNetworkStatusChange: true,
      variables: { id: customerId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const customerData = data.customer;

        // const hideMobileFromData = customerData?.hideMobileFrom?.map((ele) => ele.id);
        setCustomerType(customerData.type.code);
        const customerPaymentTypes = data?.customer?.paymentTypes;
        const paymentCode = [];
        customerPaymentTypes.map((ele) => paymentCode.push(ele.code));
        const notSelect = data.listPaymentTypesDropdown.filter(
          (ele) => !paymentCode.includes(ele.code)
        );
        setPaymentTypes([...customerPaymentTypes, ...notSelect]);
        customerPaymentTypes.forEach((row) => {
          setValue(`${row.code}`, true);
        });

        const customerParams = [
          "id",
          "code",
          "name",
          "address",
          // "phone",
          "callbackUrl",
          // "mobile",
          "postalCode",
          "mailBox",
          "email",
          "vodafoneCash",
          "idNumber",
          "taxCardNumber",
          "accountNumber",
          "representative",
          "activityName",
        ];
        customerParams.forEach((i) => {
          customerData[i] && setValue(i, customerData[i]);
        });

        // number quey conditions
        const numbersObj = [
          {
            name: "mobile",
            codeName: "mobileCode",
            value: getMobileData(customerData.mobile, countryCode)
          },
          {
            name: "phone",
            codeName: "phoneCode",
            value: getMobileData(customerData.phone, countryCode)
          },
        ]
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        // end

        let paymentCycleSelected = customerData?.paymentCycle?.code;
        const selectedPaymentCycle = GetPaymentCycleDays(t, paymentCycleSelected)?.filter(day => customerData?.paymentCycleDays.includes(day.id));

        setValue("active", customerData["active"]);
        setValue("shareCollectedFees", customerData["shareCollectedFees"]);
        setValue("payUncollected", customerData["payUncollected"]);
        setValue("hideWaybillMobile", customerData["hideWaybillMobile"]);
        setValue("specifyShipmentCode", customerData["specifyShipmentCode"]);
        setValue("warehousing", customerData["warehousing"]);
        setValue("sendDeliveryOtp", customerData["sendDeliveryOtp"]);
        customerData["taxable"] !== null &&
          setValue("taxable", customerData["taxable"]);
        customerData["hideMobileFrom"] === null &&
          setValue("isMobileHidden", true);
        const hideMobileFromCodes = []
        customerData?.hideMobileFrom?.map(ele => hideMobileFromCodes.push(ele.code))
        setAutocompleteValues({
          branch: customerData?.branch,
          glAccount: customerData?.glAccount,
          zone: customerData.zone,
          subzone: customerData.subzone,
          priceList: customerData.priceList,
          bank: customerData?.bank,
          country: customerData?.country,
          state: customerData?.state,
          city: customerData?.city,
          area: customerData?.area,
          paymentCycleDays: selectedPaymentCycle,
          hideMobileFromCode: hideMobileFromCodes,
        });
      },
    }
  );

  const settingWarehouse = settings?.shippingSettings?.warehousing;

  const customerData = updateCustomer?.customer;

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const onGlAccountComplete = (data) => {
    const currentValue = watch("glAccountId");

    const isValueNotValid = !selectedIdIsValid(data, currentValue);
    if (isValueNotValid && currentValue) {
      setValue("glAccountId", "");
      setAutocompleteValues((prev) => ({
        ...prev,
        glAccount: null,
      }));
    }
  };

  const [saveCustomer, { loading: saveCustomerLoading }] = useMutation(
    gql`
      ${SAVE_CUSTOMER.query}
    `
  );

  const onSubmit = (data) => {
    const bankId = data.bankId
    const accountNumber = data.accountNumber
    const vodafoneCash = data.vodafoneCash
    const taxCardNumber = data.taxCardNumber
    const idNumber = data.idNumber
    if (data.isMobileHidden) {
      delete data["isMobileHidden"]
      data["hideMobileFromCode"] = null
    } else {
      delete data["isMobileHidden"]
      if (!data.hideMobileFromCode) {
        data.hideMobileFromCode = []
      }
    }
    let codes = [];
    // mobile on save
    if (Globals.settings.allowPhoneKey) {
      const checkMobiles = validNumber(data.mobile, data.mobileCode)
      const checkPhone = validNumber(data.phone, data.phoneCode)
      let names = [
        {
          name: "mobile",
          validate: checkMobiles.valid,
          message: checkMobiles.message,
        },
        {
          name: "phone",
          validate: checkPhone.valid,
          message: checkPhone.message,
        },
      ];
      if (!checkMobiles.valid || !checkPhone.valid) {
        names.map((e) => !e.validate && setError(e.name, { type: 'custom', message: t(e.message) }))
        return
      }
      data.mobile = getFormatNumber(data.mobile, data.mobileCode)
      data.phone = getFormatNumber(data.phone, data.phoneCode)
    }



    //  end

    let paymentTypesSelect = [];
    for (var i = 0; i < paymentTypes.length; ++i) {
      codes.push(paymentTypes[i].code);
      data[paymentTypes[i].code] === true &&
        paymentTypesSelect.push(paymentTypes[i].code);
    }
    data["paymentCycleDays"] = data["paymentCycleCode"]
      ? data["paymentCycleDaysWEEKLY"] || data["paymentCycleDaysMONTHLY"]
      : null;
    for (const key in data) {
      codes.includes(key) && delete data[key];
      if (data[key] === "") {
        data[key] = null;
      }
      if (
        (!settingWarehouse && key === "warehousing") ||
        key === "paymentCycleDaysWEEKLY" ||
        key === "phoneCode" ||
        key === "mobileCode" ||
        key === "bankId" ||
        key === "accountNumber" ||
        key === "paymentCycleDaysMONTHLY" ||
        key === "vodafoneCash" ||
        key === "taxCardNumber" ||
        key === "idNumber"
      ) {
        delete data[key];
      }
    }

    const conciergeData = {
      id: data.id,
      active: data.active,
      code: data.code,
      name: data.name,
      branchId: data.branchId,
      glAccountId: data.glAccountId,
      priceListId: data.priceListId,
      phone: data.phone,
      mobile: data.mobile,
      address: data.address,
      email: data.email,
    }
    saveCustomer({
      variables: {
        input: {
          ...(customerType !== "CONCIERGE" ?
            {
              ...(settingWarehouse && { warehousing: data.warehousing }),
              ...(data.paymentMethodCode === "BNK" && { bankId: bankId ? bankId : null }),
              ...((data.paymentMethodCode === "BNK" || data.paymentMethodCode === "INSTPY") && { accountNumber: accountNumber }),
              ...(data.paymentMethodCode === "VCSH" && { vodafoneCash: vodafoneCash }),
              ...(eInvoicing && data.identificationTypeCode === "BUSINESS" && data.identificationTypeCode && { taxCardNumber: taxCardNumber }),
              ...(eInvoicing && data.identificationTypeCode !== "BUSINESS" && data.identificationTypeCode && { idNumber: idNumber }),
              paymentTypes: paymentTypesSelect,
              typeCode: customerType.toUpperCase(),
              ...data,
            } : {
              typeCode: customerType.toUpperCase(),
              ...conciergeData
            })
        }
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/customers/${data?.data?.saveCustomer?.id}`);

        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {


        if (graphQLErrors[0].extensions?.validation["input.paymentTypes"]) {
          enqueueSnackbar(t("paymentTypesMsg"), {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          })
        } else { setValidationError(graphQLErrors, setError); }
        console.log(graphQLErrors);
      });
  };

  const [paymentTypes, setPaymentTypes] = useState();
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(
      paymentTypes,
      result.source.index,
      result.destination.index
    );
    setPaymentTypes(reorderedItems);
  };

  const [priceListIdOnSave, setPriceListIdOnSave] = useState()
  const parseData = (data) => {
    return data;
  };

  const [refetchTrigger, setRefetchTrigger] = useState(true);

  console.log(watch());
  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid
            justifyContent="end"
            xs={12}
            sx={{ display: "flex", flexWrap: "wrap" }}
          >
            {/* <Typography variant="h6">
              {formName[customerType?.toUpperCase()]}
            </Typography> */}
            <Box>
              <MuiSwitch
                edge="end"
                name="active"
                label={t("active")}
                control={control}
              />
            </Box>
          </Grid>

          <Grid sm={2} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("code")}
            />
          </Grid>

          <Grid sm={4} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"name"}
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>

          {customerType === "MERCHANT" && (
            <Grid sm={3} xs={12} md={3} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"representative"}
                label={t("representative")}
              />
            </Grid>
          )}

          <Grid
            sm={customerType === "MERCHANT" ? 3 : 6}
            xs={12}
            alignItems="flex-start"
          >
            <ListBranches
              control={control}
              errors={errors}
              name={"branchId"}
              defaultValue={autocompleteValues.branch}
              skipDefaultBranch={customerId}
            />
          </Grid>
          <Grid sm={customerType === "CONCIERGE" ? 6 : 3} xs={12} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"glAccountId"}
              label={t("glAccount")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_GL_ACCOUNTS_DROPDOWN.query}
              onCompleted={onGlAccountComplete}
              variables={{
                input: {
                  typeCode: "SUB",
                  ...(watch("branchId") && {
                    branchId: { value: watch("branchId"), includeNull: true },
                  }),
                  ...(customerType === "MERCHANT" && {
                    parentId:
                      settings?.shippingSettings?.mainCustomerGLAccount?.id,
                  }),
                  ...(customerType === "INDIVIDUAL" && {
                    parentId:
                      settings?.shippingSettings?.mainConsigneeGLAccount?.id,
                  }),
                },
              }}
              defaultValue={autocompleteValues.glAccount}
            />
          </Grid>
          {customerType !== "CONCIERGE" && (
            <Grid sm={3} xs={12} md={3} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"activityName"}
                label={t("activity")}
              />
            </Grid>)}

          {customerType !== "CONCIERGE" && (<Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"defaultPriceTypeCode"}
              label={t("priceType")}
              variables={{
                input: { code: "SHP_PRICE_TYPE" },
              }}
              readOnly={Boolean(
                watch("defaultPaymentTypeCode") === "CASH" ||
                watch("defaultPaymentTypeCode") === "CRDT"
              )}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  customerData?.defaultPriceType?.code,
                  "defaultPriceTypeCode"
                )
              }
            />
          </Grid>)}

          {customerType !== "CONCIERGE" && (<Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"defaultShipmentTypeCode"}
              label={t("packageType")}
              variables={{
                input: { code: "SHP_REQUEST_TYPE" },
              }}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  customerData?.defaultShipmentType?.code,
                  "defaultShipmentTypeCode"
                )
              }
            />
          </Grid>)}

          {customerType !== "CONCIERGE" && (<Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"defaultOpenableCode"}
              label={t("packageOpenPossibility")}
              variables={{
                input: { code: "SHP_OPEN_PACKAGE" },
              }}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  customerData?.defaultOpenable?.code,
                  "defaultOpenableCode"
                )
              }
            />
          </Grid>)}
          {customerType !== "CONCIERGE" && (<Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"categoryCode"}
              label={t("customerCategories")}
              variables={{
                input: { code: "SHP_CUSTOMER_CATGORIES" },
              }}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  customerData?.category?.code,
                  "categoryCode"
                )
              }
            />
          </Grid>)}
          <Grid
            sm={6}
            xs={12}
            md={6}
            alignItems="flex-start"
            className={classes.priceList}
            sx={{ display: "flex" }}
          >
            <CustomAutocomplete
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              refetchQuery={refetchTrigger}
              name={"priceListId"}
              label={t("listPriceList")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_PRICE_LIST_DROPDOWN.query}
              defaultValue={autocompleteValues.priceList}
              variables={{
                input: {
                  customerTypeCode: customerType?.toUpperCase(),
                },
              }}
            />
            <Can
              showException
              permission={
                customerType &&
                GetPermissionSlug(
                  "shipping",
                  "price_list",
                  customerType,
                  "create"
                )
              }
            >
              <IconButton
                onClick={() => {
                  setPriceListDialogState(true)
                  setPriceListIdOnSave(NaN)
                }}
                size="large"
              >
                <AddCircleOutline />
              </IconButton>
            </Can>
          </Grid>

          {customerType !== "CONCIERGE" && (<Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <MUIselect
              name={"taxable"}
              label={t("tax")}
              control={control}
              errors={errors}
              rules={{
                validate: {
                  lessThan: (value) => value !== "" || t("fieldIsRequired"),
                },
              }}
              data={[
                { key: t("taxable"), value: true },
                { key: t("notTaxable"), value: false },
              ]}
            />
          </Grid>)}
          {/* //////////PAYMENT/////////// */}
          {customerType !== "CONCIERGE" && (<Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"paymentMethodCode"}
              label={t("paymentMethod")}
              variables={{
                input: { code: "SHP_PAYMENT_METHODS" },
              }}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  customerData?.paymentMethod?.code,
                  "paymentMethodCode"
                )
              }
              onChanges={(e) => {
                setValue("bankId", "");
              }}
            />
          </Grid>)}
          {watch("paymentMethodCode") === "BNK" && (
            <Grid sm={6} xs={12} md={3} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"bankId"}
                label={t("bank")}
                parseData={(data) => selectDefaultValue(data)}
                query={LIST_BANK_DROPDOWN.query}
                defaultValue={autocompleteValues.bank}
              />
            </Grid>
          )}
          {(watch("paymentMethodCode") === "BNK" || watch("paymentMethodCode") === "INSTPY") && (
            <Grid sm={6} xs={12} md={watch("paymentMethodCode") === "INSTPY" ? 6 : 3} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"accountNumber"}
                label={t("accountNumber")}
              />
            </Grid>
          )}
          {watch("paymentMethodCode") === "VCSH" && (
            <Grid sm={6} xs={12} md={6} alignItems="flex-start">
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"vodafoneCash"}
                label={t("walletNumber")}
                rules={{
                  minLength: {
                    value: 10,
                    message: t("typeCorrectPhone"),
                  },
                }}
              />
            </Grid>
          )}
          {/* //////////Cyclecode/////////// */}
          {customerType !== "CONCIERGE" && (<Grid sm={6} xs={12} md={Boolean(watch("paymentCycleCode")) ? 3 : 6} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"paymentCycleCode"}
              label={t("paymentCycle")}
              variables={{
                input: { code: "PAYMENT_SCHEDULE_TYPE" },
              }}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  customerData?.paymentCycle?.code,
                  "paymentCycleCode"
                )
              }
              onChanges={(e) => {
                setValue("paymentCycleDaysWEEKLY", "");
                setValue("paymentCycleDaysMONTHLY", "");
                setAutocompleteValues((prev) => ({
                  ...prev,
                  paymentCycleDays: [],
                }));
              }}
            />
          </Grid>)}
          {watch("paymentCycleCode") && (
            <Grid sm={6} xs={12} md={3} alignItems="flex-start">
              <Basic
                multiple
                name="paymentCycleDaysWEEKLY"
                label={t("paymentDay")}
                control={control}
                errors={errors}
                hideCode={true}
                valueKey={"id"}
                data={GetPaymentCycleDays(t, watch("paymentCycleCode"))}
                loading={loading}
                defaultValue={autocompleteValues.paymentCycleDays}
                rules={{ required: t("fieldIsRequired") }}
              />
            </Grid>
          )}
          {customerType !== "CONCIERGE" && (
            <Grid container xs={12}
              sx={{ display: "flex", flexWrap: "wrap" }} alignItems="center">
              <Grid sm={6} xs={12} md={6} alignItems="flex-start" sx={{ paddingLeft: "24px" }} >
                <MuiSwitch
                  edge="end"
                  name="isMobileHidden"
                  label={t("isMobileHidden")}
                  control={control}
                />
              </Grid>
              {!watch("isMobileHidden") &&
                <Grid sm={6} xs={12} md={6} alignItems="flex-start">
                  <MultipleAutocomplete
                    name={"hideMobileFromCode"}
                    label={t("hideMobileFromCode")}
                    // skip={filterLoad && filterLoading}
                    control={control}
                    errors={errors}
                    variables={{ input: { code: "HIDE_SENDER_MOBILE" } }}
                    query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                    parseData={(data) =>
                      parseData(data)
                    }
                    multiple
                    valueKey="code"
                    defaultValue={autocompleteValues.hideMobileFromCode}
                  />
                </Grid>}
            </Grid>)}
          {customerType !== "CONCIERGE" && (<Grid sm={6} xs={12} md={6} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"callbackUrl"}
              label={t("callbackUrl")}
            />
          </Grid>)}


          {customerType !== "CONCIERGE" && (<Grid
            sm={12}
            xs={12}
            md={12}
            container
            justifyContent="flex-start"
            alignItems="center"
            className={clsx(classes.mainGrid)}
            spacing={2}
          >
            <Grid sm={6} xs={12} md={3} alignItems="flex-start">
              <MuiSwitch
                edge="end"
                name="specifyShipmentCode"
                label={t("enterShipmentCode")}
                control={control}
              />
            </Grid>
            <Grid sm={6} xs={12} md={3} alignItems="flex-start">
              <MuiSwitch
                edge="end"
                name="shareCollectedFees"
                label={t("shareCollectedFees")}
                control={control}
              />
            </Grid>
            <Grid sm={6} xs={12} md={3} alignItems="flex-start">
              <MuiSwitch
                edge="end"
                name="payUncollected"
                label={t("payUncollected")}
                control={control}
              />
            </Grid>

            {customerType === "MERCHANT" && (
              <Grid sm={6} xs={12} md={3} alignItems="flex-start">
                <Can showException permission={settingWarehouse}>
                  <MuiSwitch
                    edge="end"
                    name="warehousing"
                    label={t("warehousing")}
                    control={control}
                  />
                </Can>
              </Grid>
            )}
            <Grid sm={6} xs={12} md={3} alignItems="flex-start">
              <MuiSwitch
                edge="end"
                name="sendDeliveryOtp"
                label={t("deliveryOtp")}
                control={control}
              />
            </Grid>
          </Grid>)}
        </Paper>

        {eInvoicing && customerType === "MERCHANT" &&
          <Paper container component={Grid} className={clsx(classes.spacing)}>
            <Grid sm={6} xs={12} md={6} alignItems="flex-start">
              <LookupDropdown
                control={control}
                errors={errors}
                name={"identificationTypeCode"}
                label={t("identificationType")}
                rules={{ required: watch("taxable") ? t("fieldIsRequired") : false }}
                variables={{
                  input: { code: "IDENTIFICATION_TYPES" },
                }}
                onCompleted={(data) =>
                  lookupsComplete(
                    data,
                    customerData?.identificationType?.code,
                    "identificationTypeCode"
                  )
                }
              />
            </Grid>
            {watch("identificationTypeCode") !== "BUSINESS" && watch("identificationTypeCode") !== "" &&
              <Grid sm={6} xs={12} md={6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  rules={{ required: t("fieldIsRequired") }}
                  name={"idNumber"}
                  label={t("idNumber")}
                />
              </Grid>}
            {watch("identificationTypeCode") === "BUSINESS" && watch("identificationTypeCode") !== "" &&
              <Grid sm={6} xs={12} md={6} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  rules={{ required: t("fieldIsRequired") }}
                  name={"taxCardNumber"}
                  label={t("taxCardNumber")}
                />
              </Grid>}
          </Paper>}
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          {GlobalSettings.multiCountries && (
            <Grid sm={6} xs={12} md={4} alignItems="flex-start">
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"countryId"}
                label={t("country")}
                parseData={(data) => selectDefaultValue(data)}
                query={LIST_COUNTRIES_DROPDOWN.query}
                defaultValue={autocompleteValues.country}
              />
            </Grid>
          )}
          {customerType !== "CONCIERGE" && (<Grid
            sm={6}
            xs={12}
            md={GlobalSettings.multiCountries ? 4 : 6}
            alignItems="flex-start"
          >
            <CustomAutocomplete
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              name={"zoneId"}
              label={t("zone")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_ZONES_DROPDOWN.query}
              variables={{
                input: {
                  ...(watch("countryId") && { countryId: watch("countryId") }),
                  parentId: null,
                },
              }}
              onChangeValue={(e) => {
                setValue("subzoneId", "");
              }}
              defaultValue={autocompleteValues.zone}
            />
          </Grid>)}
          {customerType !== "CONCIERGE" && (<Grid
            sm={6}
            xs={12}
            md={GlobalSettings.multiCountries ? 4 : 6}
            alignItems="flex-start"
          >
            <CustomAutocomplete
              control={control}
              errors={errors}
              rules={{ required: t("fieldIsRequired") }}
              disabled={!watch("zoneId")}
              skip={!watch("zoneId")}
              name={"subzoneId"}
              label={t("subzone")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_ZONES_DROPDOWN.query}
              variables={{
                input: { parentId: watch("zoneId"), active: true },
              }}
              defaultValue={autocompleteValues.subzone}
            />
          </Grid>)}
          <Grid sm={6} xs={12} md={customerType === "CONCIERGE" ? 6 : 4} alignItems="flex-start">
            <MuiPhone
              setValue={setValue}
              codeValue={getValues().phoneCode}
              control={control}
              errors={errors}
              name={"phone"}
              selectName={"phoneCode"}
              label={t("phone")}
              rules={{
                required: t("fieldIsRequired"),
              }}
              onBlur={(e) => {
                if (Boolean(!getValues().mobile)) {
                  setValue("mobile", getValues().phone, {
                    shouldDirty: true,
                  });
                  setValue("mobileCode", getValues().phoneCode, {
                    shouldDirty: true,
                  });
                }
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} md={customerType === "CONCIERGE" ? 6 : 4} alignItems="flex-start">
            <MuiPhone
              setValue={setValue}
              codeValue={watch("mobileCode")}
              control={control}
              errors={errors}
              name={"mobile"}
              selectName={"mobileCode"}
              label={t("mobile")}
              rules={{
                required: t("fieldIsRequired"),
              }}
            />
          </Grid>
          {customerType !== "CONCIERGE" && (<Grid sm={6} xs={12} md={4} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"postalCode"}
              label={t("postalCode")}
            />
          </Grid>)}
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"address"}
              label={t("address")}
              rules={{
                required: t("fieldIsRequired"),
              }}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"email"}
              label={t("email")}
              rules={{
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t("emailIsInvalid"),
                },
              }}
            />
          </Grid>
        </Paper>
        {customerType !== "CONCIERGE" && (<Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid sm={12} xs={12} alignItems="flex-start" display={"grid"}>
            <Toolbar className={classes.toolbarTable} variant="dense">
              <Typography
                className={classes.title}
                color="inherit"
                variant="h6"
              >
                {t("paymentTypes")}
              </Typography>
            </Toolbar>
            <TableFixedHeaderWraper>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell> </FixedTableCell>
                    <FixedTableCell>{t("code")}</FixedTableCell>
                    <FixedTableCell>{t("name")}</FixedTableCell>
                    <FixedTableCell> </FixedTableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(provided, snapshot) => (
                      <TableBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {paymentTypes &&
                          paymentTypes?.map((row, index) => (
                            <Draggable
                              key={row.id}
                              draggableId={row?.code}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <FixedTableCell>
                                    <DragHandleIcon />
                                  </FixedTableCell>
                                  <FixedTableCell>{row?.code}</FixedTableCell>
                                  <FixedTableCell>{row?.name}</FixedTableCell>
                                  <FixedTableCell>
                                    <MuiSwitch
                                      edge="end"
                                      name={`${row.code}`}
                                      control={control}
                                    />
                                  </FixedTableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableFixedHeaderWraper>
          </Grid>
        </Paper>)}

        <Grid container justifyContent="flex-end" className={classes.spacing}>
          <FormButton disabled={saveCustomerLoading}>
            {saveCustomerLoading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </form>
  );
  let DOM;

  if (customerId) {
    DOM = updateCustomer ? body :
      <StyledLoading container item justifyContent="center" className={classes.main}>
        <FullScreenLoading height={"100%"} />
      </StyledLoading>;
  } else {
    DOM = body;
  }

  // const pagePermission = customerType &&
  //   GetCustomerPermissionSlug("shipping", "customer", customerType, "create")

  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !updateCustomer && customerId ? (
      <NotFound />
    ) :
      // <Can permission={pagePermission}>
      (
        <Root>
          <TitleAppBar path={props.match.path} type={customerType} />
          <CustomDialog
            open={priceListDialogState}
            onClose={closePriceListDialog}
            TransitionComponent={Transition}
            fullWidth
            fullScreen
            content={
              <PriceListForm
                key={Math.random()}
                customerName={watch("name") && t("priceList") + " " + watch("name")}
                customerType={customerType}
                id={priceListIdOnSave}
                onSave={(data) => {
                  const priceList = data.data.savePriceList;
                  setPriceListIdOnSave(priceList.id)
                  setAutocompleteValues((prev) => ({
                    ...prev,
                    priceList: {
                      id: priceList.id,
                      name: priceList.name,
                    },
                  }));
                  (refetchTrigger) ? setRefetchTrigger(false) : setRefetchTrigger(true)
                  // closePriceListDialog();
                }}
              />
            }
            actions={<Button onClick={closePriceListDialog}>{t("cancel")}</Button>}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={closePriceListDialog}
                  aria-label="close"
                  size="large"
                >
                  <Close />
                </IconButton>
                <Typography color="inherit" variant="h6">
                  {t("priceList")}
                </Typography>
              </Toolbar>
            </AppBar>
          </CustomDialog>
          {DOM}
        </Root>
        // </Can>
      );
};

export default CustomerForm;
