import { createContext, useContext } from "react";

export const PaymentShipmentsContext = createContext({});

const PaymentShipmentsProvider = (props) => {
  const { value } = props;
  return (
    <PaymentShipmentsContext.Provider value={value}>
      {props.children}
    </PaymentShipmentsContext.Provider>
  );
};

export default PaymentShipmentsProvider;

export const usePaymentShipmentsContext = () => {
  const paymentShipmentsContext = useContext(PaymentShipmentsContext);

  return {
    paymentShipmentsContext,
  };
};
