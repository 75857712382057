import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import {
  Paper,
  Collapse,
  Button,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { gql, useMutation, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { DELETE_ROLE, ROLE_ID } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import PermissionSection from "./PermissionSection";
import { useSnackbar } from "notistack";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";

const PREFIX = 'RoleView';

const classes = {
  loading: `${PREFIX}-loading`,
  paper: `${PREFIX}-paper`,
  mainTracking: `${PREFIX}-mainTracking`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  field: `${PREFIX}-field`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.loading}`]: {},

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`&.${classes.mainTracking}`]: {
    margin: "0",
    width: "100%",
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: "#f5f7f9",
    width: "100%",
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.field}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  }
}));

const RoleView = (props) => {

  const { t } = useTranslation();
  const roleId = props.match.params.id?.trim();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const { loading, data } = useQuery(
    gql`
      ${ROLE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,

      variables: { id: parseInt(roleId) },
    }
  );

  const [deleteRoleMutation, { loading: deleteLoad }] = useMutation(
    gql`
      ${DELETE_ROLE.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const deleteRole = () => {
    deleteRoleMutation({
      variables: {
        id: parseInt(roleId),
      },
    })
      .then((data) => {
        handleCloseDialog();
        // props.history.goBack();
        pushUrl(props, `/admin/roles`)
        enqueueSnackbar(t("manifestDeleted"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  const permission = data?.role?.permissions;
  const id = data?.role?.id;

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/roles/${roleId}/edit`),
      icon: Edit,
      permission: (Globals.user.isSuper || data?.role?.code !== "ADMN") && "core.role.update",
    },
    {
      id: "copy",
      title: "copy",
      action: () => pushUrl(props, `/admin/roles/${roleId}/copy`),
      icon: ContentCopyIcon,
      permission: "core.role.create",
    },
    {
      id: "delete",
      title: "delete",
      action: handleOpenDialog,
      icon: Delete,
      permission: data?.role.usersCount === 0
    },
  ]

  return (
    <StyledGrid
      container
      spacing={3}
      justifyContent="center"
      className={classes.mainTracking}
    > {loading ? <FullScreenLoading minHeight="25%" /> : !data?.role ? (
      <NotFound />
    ) : (
      <>
        <CustomDialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="sm"
          title={t("deleteRole")}
          content={t("deleteRoleConfirmation")}
          actions={
            <>
              <Button color="primary" onClick={handleCloseDialog}>
                {t("cancel")}
              </Button>
              <Button color="primary" onClick={deleteRole}>
                {deleteLoad ? <ButtonLoading /> : t("confirm")}
              </Button>
            </>
          }
        />
        <Fragment>
          <TitleAppBar path={props.match.path}>
            <LongMenu icons={icons} />
          </TitleAppBar>
        </Fragment>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ margin: "0", width: "100%" }}
        >
          <Grid
            xs={12}
            justifyContent="center"
            sx={{ width: "100%", p: 2, gap: 2 }}
          >
            <Paper container className={classes.paper} component={Grid}>
              <KeyValuePair title={t("code")} value={data?.role?.code} />
              <KeyValuePair title={t("name")} value={data?.role?.name} />
            </Paper>
          </Grid>

          {permission && <Grid xs={12} justifyContent="center" className={classes.spacing}>
            <PermissionSection permission={permission} id={id} />
          </Grid>}

        </Grid>   </>
    )}
    </StyledGrid>
  );
};

export default RoleView;
