import React from "react";
import {
  FeatureGroup,
  MapContainer,
  Polyline,
  TileLayer,
  useMap,
} from "react-leaflet";
import { withRouter } from "react-router";
import { MarkerPopup } from "./Marker";

const DeliveryAgentMap = (props) => {
  const { positions } = props;
  const points = positions?.map((p) => [p.latitude, p.longitude]);
  const center = points
    ? { lat: points?.[0]?.[0], lng: points?.[0]?.[1] }
    : { lat: 0, lng: 0 };
  const zoom = 2;
  return (
    <div style={{ height: "100%" }}>
      <MapContainer
        whenCreated={(map) => {
          setInterval(function () {
            map.invalidateSize();
          }, 100);
        }}
        zoom={zoom}
        center={center}
        style={{ height: "100%" }}
      >
        <FitBounds points={points} />

        <TileLayer
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
          url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
        />

        {points && (
          <FeatureGroup>
            <Polyline positions={points} />
            {positions.map((marker, index) => {
              return <MarkerPopup key={index} id={index} position={marker} />;
            })}
          </FeatureGroup>
        )}
      </MapContainer>
    </div>
  );
};

export default withRouter(DeliveryAgentMap);

function FitBounds({ center, zoom, points }) {
  const map = useMap();
  // map.setView(center, zoom, {
  //   animate: true,
  //   duration: 2,
  //   easeLinearity: 5,
  //   noMoveStart: false,
  // });
  if (points) {
    map.fitBounds(points, {
      animate: true,
      duration: 5,
      easeLinearity: 5,
      // noMoveStart: false,
    });
  }
  return null;
}
