import { useQuery, gql, useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { Paper, Icon, Button, Collapse } from "@mui/material";
import { Edit } from "@mui/icons-material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import {
  CLONE_PRICE_LIST_PICKUP,
  DELETE_PRICE_LIST,
  PRICE_LIST,
} from "./Graphql";
import PriceListTable from "./PriceListTables";
import Grid from "@mui/material/Unstable_Grid2";
import { GetPermissionSlug } from "../../helpers/getPermissionSlug";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { Delete } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Globals } from "../HOC/Classes/Globals";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import TitleAppBar from "../../Layout/TitleAppBar";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LongMenu from "../../Layout/MenuAppBar";
import { useForm } from "react-hook-form";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import SpanLink from "../HOC/CustomComponents/SpanLink";

const PREFIX = "PriceListView";

const classes = {
  box: `${PREFIX}-box`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const PriceListView = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openCopyDialog, setOpenCopyDialog] = React.useState(false);
  const priceListId = parseInt(props.match.params.id);

  const {
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenCopyDialog = () => {
    setOpenCopyDialog(true);
  };

  const handleCloseCopyDialog = () => {
    setOpenCopyDialog(false);
  };

  const { data, loading } = useQuery(
    gql`
      ${PRICE_LIST.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: { id: priceListId },
      onCompleted: (data) => {
        const nameValue = t("copyFrom") + " " + data?.priceList.name;
        setValue("name", nameValue);
      },
    }
  );

  const [deletePriceMutation, { loading: deleteLoad }] = useMutation(
    gql`
      ${DELETE_PRICE_LIST.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );
  const [copyPriceListMutation, { loading: copyLoad }] = useMutation(
    gql`
      ${CLONE_PRICE_LIST_PICKUP.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const deletePriceList = () => {
    deletePriceMutation({
      variables: {
        id: parseInt(priceListId),
      },
    })
      .then(() => {
        handleCloseDialog();
        pushUrl(props, `/admin/price-list/${viewType?.toLowerCase()}`);
        enqueueSnackbar(t("manifestDeleted"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch((error) => {
        handleCloseDialog();
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const copyPriceList = () => {
    copyPriceListMutation({
      variables: {
        input: {
          id: parseInt(priceListId),
          name: watch("name"),
        },
      },
    })
      .then((data) => {
        handleCloseCopyDialog();
        pushUrl(props, `/admin/price-list/${data.data.clonePriceList.id}/edit`);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch((error) => {
        handleCloseCopyDialog();
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const user = Globals.user;
  const viewType = data?.priceList?.customerType.code;

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/price-list/${priceListId}/edit`),
      icon: Edit,
      permission:
        viewType &&
        user.hasPermission(
          GetPermissionSlug("shipping", "price_list", viewType, "update")
        ),
    },
    {
      id: "copy",
      title: "copy",
      action: handleOpenCopyDialog,
      icon: ContentCopyIcon,
      permission:
        viewType &&
        user.hasPermission(
          GetPermissionSlug("shipping", "price_list", viewType, "create")
        ),
    },
    {
      id: "delete",
      title: "delete",
      action: handleOpenDialog,
      icon: Delete,
      permission: GetPermissionSlug(
        "shipping",
        "price_list",
        viewType,
        "delete"
      ),
    },
  ];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classes.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data?.priceList ? (
    <NotFound />
  ) : (
    <Root>
      <CustomDialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="xs"
        // title={t("deleteRole")}
        content={t("confirmationStatus")}
        actions={
          <>
            <Button color="primary" onClick={handleCloseDialog}>
              {t("cancel")}
            </Button>
            <Button color="primary" onClick={deletePriceList}>
              {deleteLoad ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
      />
      <CustomDialog
        open={openCopyDialog}
        onClose={handleCloseCopyDialog}
        fullWidth
        maxWidth="xs"
        title={t("copyPriceList")}
        content={
          <ControlMUItextField
            control={control}
            errors={errors}
            name="name"
            label={t("name")}
            rules={{ required: t("fieldIsRequired") }}
            onChange={() => {
              if (watch("name")) {
                clearErrors("name");
              }
            }}
          />
        }
        actions={
          <>
            <Button color="primary" onClick={handleCloseCopyDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                if (watch("name").trim()) {
                  copyPriceList();
                } else {
                  setError("name", {
                    type: "required",
                    message: t("fieldIsRequired"),
                  });
                }
              }}
            >
              {copyLoad ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
      />
      <Grid
        container
        justifyContent="center"
        sx={{ margin: "0", width: "100%" }}
      >
        <TitleAppBar path={props.match.path} type={viewType}>
          <LongMenu icons={icons} />
        </TitleAppBar>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ p: 2, width: "100%" }}
        >
          {loading ? (
            <FullScreenLoading minHeight="15%" />
          ) : (
            <>
              <Paper container className={classes.box} component={Grid}>
                <KeyValuePair
                  md={6}
                  title={t("code")}
                  value={data?.priceList?.code}
                />
                <KeyValuePair
                  md={6}
                  title={t("name")}
                  value={data?.priceList.name}
                />
                <KeyValuePair
                  md={6}
                  title={t("listDescription")}
                  value={data?.priceList?.description}
                />
                <KeyValuePair
                  md={6}
                  title={t("active")}
                  value={
                    data?.priceList?.active ? (
                      <Icon className={classes.iconColor}>
                        check_circle_outline
                      </Icon>
                    ) : (
                      <Icon color="error">highlight_off</Icon>
                    )
                  }
                />
                <KeyValuePair
                  md={6}
                  title={t("default")}
                  value={
                    data?.priceList?.default ? (
                      <Icon className={classes.iconColor}>
                        check_circle_outline
                      </Icon>
                    ) : (
                      <Icon color="error">highlight_off</Icon>
                    )
                  }
                />
                <KeyValuePair
                  title={t("branches")}
                  value={
                    data?.priceList?.branches &&
                    data?.priceList?.branches?.map((branch, index) => (
                      <SpanLink
                        key={branch.id}
                        className={classes.span}
                        pathname={`/admin/branches/${branch.id}`}
                      >
                        {branch.name}{" "}
                        {!(data?.priceList?.branches.length - 1 === index) &&
                          " , "}
                      </SpanLink>
                    ))
                  }
                />
              </Paper>
            </>
          )}
        </Grid>
        {data?.priceList?.pickups && (
          <PriceListTable
            pickups={data.priceList?.pickups}
            priceListType={viewType}
          />
        )}
      </Grid>
    </Root>
  );
};

export default PriceListView;
