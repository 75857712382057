import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import {
  Paper,
  Box,
  Alert,
  useTheme,
  Collapse
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_DELIVERY_AGENTS_DROPDOWN, LIST_SAFES_DROPDOWN, LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";

import Button from '@mui/material/Button';
import { useHistory } from "react-router";

import { gql, useMutation, useQuery } from "@apollo/client";
import { DELIVERY_AGENT_CLEARANCE_ID, PROGRESS_DELIVERY_AGENT_CLEARANCE, SAVE_DELIVERY_AGENT_CLEARANCE } from "./Graphql";
import ManifestForm from "../Manifests/ManifestForm";
import { windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import CustomButton from "../HOC/MUI/CustomButton";
import { Done, RadioButtonUnchecked } from "@mui/icons-material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import CollectionForm from "../Collection/CollectionForm";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { useSnackbar } from "notistack";

const PREFIX = 'DeliveryAgentClearanceForm';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  main: `${PREFIX}-main`,
  overlay: `${PREFIX}-overlay`,
  button: `${PREFIX}-button`,
  alert: `${PREFIX}-alert`,
  alertDisabled: `${PREFIX}-alertDisabled`,
  stepWrapper: `${PREFIX}-stepWrapper`,
  activeAlert: `${PREFIX}-activeAlert`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.7,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.alert}`]: {
    padding: theme.spacing(0.5, 3),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "70px",
    boxShadow: theme.shadows[2],
    border: "none",
    alignItems: "center",
    [`& .MuiAlert-message`]: {
      flexGrow: 1
    },
    [`& .muirtl-1ytlwq5-MuiAlert-icon`]: {
      fontSize: 28,
      marginRight: theme.spacing(1)
    },
    cursor: "pointer",
  },
  [`& .${classes.activeAlert}`]: {
    backgroundColor: "rgba(245, 105, 107, 0.16)",
    boxShadow: theme.shadows[1],
  },
  [`& .${classes.stepWrapper}`]: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
  },
}));


const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));


const DeliveryAgentClearanceForm = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const clearanceId = props.match.params.id
  const [pathURL, setPathURL] = useState(props.match.path)
  const { handleSubmit, control, formState, watch, setValue, setError } = useForm();
  const { errors } = formState;
  const history = useHistory();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [progressStep, setProgressStep] = useState(0);

  const [disableForm, setDisableForm] = useState(Boolean(clearanceId));
  const [canEdit, setCanEdit] = useState(true);
  const [finished, setFinished] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const dialogTitle = [
    t("clearanceDialogNext"),
    t("clearanceDialogNext"),
    t("clearanceDialogFinish"),
  ]

  // console.log(theme);

  const [stepData, setStepData] = useState({
    ritsManifest: null,
    rcvManifest: null,
    collection: null,
  });

  const steps = [
    {
      name: t('pickedUpShipments'),
      type: "manifests",
      code: stepData?.ritsManifest?.code,
      id: stepData?.ritsManifest?.id,
      approved: stepData?.ritsManifest?.approved,
      component: <ManifestForm clearanceManifest={stepData?.ritsManifest?.id}{...props} />
    },
    {
      name: t('undeliveredShipments'),
      type: "manifests",
      id: stepData?.rcvManifest?.id,
      code: stepData?.rcvManifest?.code,
      approved: stepData?.rcvManifest?.approved,
      component: <ManifestForm clearanceManifest={stepData?.rcvManifest?.id} {...props} />
    },
    {
      name: t('deliveryAgentsCollection'),
      type: "collections",
      id: stepData?.collection?.id,
      code: stepData?.collection?.code,
      approved: finished ? finished : stepData?.collection?.approved,
      component: <CollectionForm clearanceCollection={{ id: stepData?.collection?.id, finished: finished }} {...props} />
    },
  ];

  const handleClickOpen = () => {
    setConfirmDialog(true);
  };

  const handleClose = () => {
    setConfirmDialog(false);
  };

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    deliveryAgent: null,
    safe: null,
    ritsTransactionType: null,
    rcvTransactionType: null,
    collectionTransactionType: null,
  });

  const afterGetData = (data) => {
    setFinished(data.finished)
    setStepData({
      collection: data.collection,
      rcvManifest: data.rcvManifest,
      ritsManifest: data.ritsManifest,
    })

       
    if (data.collection || data.ritsManifest || data.rcvManifest) {
      if (data.ritsManifest && !data.ritsManifest.approved) {
        setProgressStep(0)
      }
      if (data.rcvManifest && (!data.rcvManifest.approved||!data.collection)) {
        setProgressStep(1)
      }
      if (data.collection && !data.collection.approved) {
        setProgressStep(2)
      }
    }

    if (data.collection) {
      setActiveStep(2)
    } else if (data.rcvManifest) {
      setActiveStep(1)
    } else {
      setActiveStep(0)
    }
  }

  const { loading: clearanceByIdLoad } = useQuery(
    gql`
      ${DELIVERY_AGENT_CLEARANCE_ID.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !clearanceId,
      variables: {
        id: parseInt(clearanceId),
      },
      onCompleted: (data) => {
        const deliveryAgentClearance = data?.deliveryAgentClearance;
        setValue("id", deliveryAgentClearance.id)
        if (deliveryAgentClearance.collection || deliveryAgentClearance.ritsManifest || deliveryAgentClearance.rcvManifest) {
          setCanEdit(false)
        }

        afterGetData(deliveryAgentClearance)

        setAutocompleteValues({
          branch: deliveryAgentClearance.branch,
          deliveryAgent: deliveryAgentClearance.deliveryAgent,
          safe: deliveryAgentClearance.safe,
          ritsTransactionType: deliveryAgentClearance.ritsTransactionType,
          rcvTransactionType: deliveryAgentClearance.rcvTransactionType,
          collectionTransactionType: deliveryAgentClearance.collectionTransactionType,
        });
      },
      onError: ({ graphQLErrors }) => {
        console.log(graphQLErrors);
        // const auth = graphQLErrors[0].extensions.category
        // auth === 'authorization' && setDisableView(true)
      }
    }
  );

  const [saveDeliveryAgentClearance, { loading: clearanceLoading }] = useMutation(
    gql`
      ${SAVE_DELIVERY_AGENT_CLEARANCE.query}
    `, {
    onCompleted: () => {
    }
  }
  );

  const [progressDeliveryAgentClearance, { loading: progressLoading }] = useMutation(
    gql`
      ${PROGRESS_DELIVERY_AGENT_CLEARANCE.query}
    `, {
    onCompleted: () => {
    }
  }
  );

  const progress = () => {
    progressDeliveryAgentClearance({
      variables: {
        id: parseInt(watch("id"))
      }
    }).then((data) => {
      const progressDeliveryAgentClearance = data.data.progressDeliveryAgentClearance;
      setCanEdit(false)
      afterGetData(progressDeliveryAgentClearance)
      setConfirmDialog(false)
    })
      .catch(({ graphQLErrors }) => {
        setConfirmDialog(false)
        console.log(graphQLErrors);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  }

  const onSubmit = (data) => {
    saveDeliveryAgentClearance({
      variables: {
        input: {
          ...data
        },
      },
    })
      .then((data) => {
        setDisableForm(true);
        setValue("id", parseInt(data?.data?.saveDeliveryAgentClearance?.id));
        const url = history.createHref({
          pathname: `/admin/delivery-agent-clearance/${data?.data?.saveDeliveryAgentClearance?.id}`,
        });
        setPathURL(url)
        windowReplaceUrl(url);
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const parseData = (data) => {
    return data;
  };

  const body = (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          justifyContent="flex-start"
          aligns="center"
          className={clsx(classes.mainGrid)}
          spacing={2}
        >
          <Paper container component={Grid} className={clsx(classes.spacing)}>
            {disableForm && canEdit && (
              <Grid sm={12} xs={12}>
                <Alert
                  severity="warning"
                  action={
                    (
                      <Button
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setDisableForm(false);
                          // fireMutaion([])
                        }}
                      >
                        {t("update")}
                      </Button>
                    )
                  }
                >
                  {/* {clearanceId
                    ? t("updateRecordsForbidden")
                    : t("updateRecord")} */}
                  {t("updateRecord")}
                </Alert>
              </Grid>
            )}
            <Grid
              container
              spacing={2}
              sx={{ position: "relative", flexGrow: 1 }}
            >
              {disableForm && (
                <Box
                  className={classes.overlay}
                  sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
                ></Box>
              )}
              <Grid xs={12} sm={4} aligns="flex-start">
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  rules={{ required: t("fieldIsRequired") }}
                  defaultValue={autocompleteValues.branch}
                  onChangeValue={(e) => {
                    setValue("deliveryAgentId", "");
                    setValue("safeId", "");
                    setValue("ritsTransactionTypeId", "");
                    setValue("rcvTransactionTypeId", "");
                    setValue("collectionTransactionTypeId", "");
                  }}
                  skipDefaultBranch={Boolean(clearanceId)}
                />
              </Grid>
              <Grid xs={12} sm={4} aligns="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"deliveryAgentId"}
                  label={t("shippingAgent")}
                  rules={{ required: t("fieldIsRequired") }}
                  disabled={!watch("branchId")}
                  parseData={(data) => parseData(data)}
                  query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                  skip={!watch("branchId")}
                  variables={{
                    input: {
                      active: true,
                      branchId: {
                        value: watch("branchId"),
                        includeNull: true,
                      },
                    },
                  }}
                  defaultValue={autocompleteValues.deliveryAgent}
                />
              </Grid>
              <Grid xs={12} sm={4} aligns="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"safeId"}
                  disabled={!watch("branchId")}
                  label={t("safe")}
                  rules={{ required: t("fieldIsRequired") }}
                  parseData={(data) => parseData(data)}
                  query={LIST_SAFES_DROPDOWN.query}
                  skip={!watch("branchId")}
                  variables={{
                    input: {
                      branchId: watch("branchId"),
                    },
                  }}
                  defaultValue={autocompleteValues.safe}
                />
              </Grid>

              <Grid xs={12} sm={4} aligns="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  selectFirst={true}
                  disabled={!watch("branchId")}
                  name={"ritsTransactionTypeId"}
                  label={t("receiveInWarehouseLists")}
                  rules={{ required: t("fieldIsRequired") }}
                  parseData={(data) => parseData(data)}
                  query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                  skip={!watch("branchId")}
                  variables={{
                    input: {
                      type: "RITS",
                      ...(watch("branchId") && {
                        branchId: {
                          value: watch("branchId"),
                          includeNull: true,
                        },
                      }),
                    },
                  }}
                  defaultValue={autocompleteValues.ritsTransactionType}
                />
              </Grid>
              <Grid xs={12} sm={4} aligns="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  disabled={!watch("branchId")}
                  selectFirst={true}
                  name={"rcvTransactionTypeId"}
                  label={t("receivingUndeliveredList")}
                  rules={{ required: t("fieldIsRequired") }}
                  parseData={(data) => parseData(data)}
                  query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                  skip={!watch("branchId")}
                  variables={{
                    input: {
                      type: "RCV",
                      ...(watch("branchId") && {
                        branchId: {
                          value: watch("branchId"),
                          includeNull: true,
                        },
                      }),
                    },
                  }}
                  defaultValue={autocompleteValues.rcvTransactionType}
                />
              </Grid>
              <Grid xs={12} sm={4} aligns="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"collectionTransactionTypeId"}
                  label={t("collectFromDeliveryAgents")}
                  disabled={!watch("branchId")}
                  selectFirst={true}
                  rules={{ required: t("fieldIsRequired") }}
                  parseData={(data) => parseData(data)}
                  query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                  skip={!watch("branchId")}
                  variables={{
                    input: {
                      type: "CSHC",
                      ...(watch("branchId") && {
                        branchId: {
                          value: watch("branchId"),
                          includeNull: true,
                        },
                      }),
                    },
                  }}
                  defaultValue={autocompleteValues.collectionTransactionType}
                />
              </Grid>
            </Grid>
            {canEdit &&
              <Grid container justifyContent="flex-end" className={classes.spacing}>
                {disableForm ?
                  <CustomButton
                    customcolor={theme.palette.error.main}
                    onClick={handleClickOpen}
                    className={classes.button}
                    variant="contained"
                    size="medium"
                    loading={progressLoading}
                    startIcon={!progressLoading && <Done />}
                  >
                    {!progressLoading && t("start")}
                  </CustomButton> :
                  <CustomButton
                    customcolor={theme.palette.success.main}
                    type="submit"
                    className={classes.button}
                    disabled={
                      clearanceLoading
                    }
                    variant="contained"
                    size="medium"
                    loading={clearanceLoading}
                    startIcon={!clearanceLoading && <Done />}
                  >
                    {!clearanceLoading && t("save")}
                  </CustomButton>
                }
              </Grid>
            }
          </Paper>
        </Grid>
      </form>
    </>
  );

  return clearanceByIdLoad ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : (
      <Root>
        <CustomDialog
          open={confirmDialog}
          title={""}
          content={canEdit ? t('clearanceDialogStart') : dialogTitle[progressStep]}
          fullWidth
          onClose={handleClose}
          maxWidth="xs"
          actions={

            <>
              <Button onClick={handleClose} autoFocus>
                {t('cancel')}
              </Button>
              <Button onClick={progress} autoFocus>
                {t('confirm')}
              </Button>
            </>

          }
        />
        <TitleAppBar path={pathURL} />
        {body}
        {
          !Boolean(canEdit) &&
          <Grid
            container
            justifyContent="flex-start"
            aligns="center"
            className={clsx(classes.mainGrid)}
            spacing={2}
          >
            <Grid container sx={{ width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
              {steps.map((step, index) => {
                return (
                  <Grid key={index} className={classes.stepWrapper}>
                    <StepStatus
                      id={step.id}
                      code={step.code}
                      type={step.type}
                      approved={step.approved}
                      index={index}
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      progressStep={progressStep}
                      finished={finished}
                    >
                      {step.name}
                    </StepStatus>
                  </Grid>
                )
              })}
            </Grid>
            <Paper container component={Grid} className={clsx(classes.spacing)}>
              <Box sx={{ width: '100%', display: "grid" }}>
                {steps[activeStep].component}
              </Box>
              {!finished && <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%", justifyContent: "flex-end" }}>
                <CustomButton
                  customcolor={theme.palette.error.main}
                  onClick={handleClickOpen}
                  className={classes.button}
                  variant="contained"
                  size="medium"
                  disabled={progressLoading ||clearanceLoading||clearanceByIdLoad||activeStep !== progressStep}
                  loading={progressLoading}
                  startIcon={!progressLoading && <Done />}
                >
                  {!progressLoading && progressStep === 2 ? t("finish") : t("next")}
                </CustomButton>
              </Box>}
            </Paper>
          </Grid>
        }
      </Root>
    )
};

const StepStatus = (props) => {
  const { approved, className, code, id, type, index, activeStep, setActiveStep, progressStep, finished } = props;

  const alert = (
    <Alert
      variant="outlined"
      icon={!approved ? <RadioButtonUnchecked color={"disabled"} /> : undefined}
      className={clsx(
        classes.alert,
        {
          [classes.alertDisabled]: !approved,
        },
        {
          [classes.activeAlert]: index === activeStep,
        },
        className && className
      )}
      onClick={() => (index <= progressStep || finished) && setActiveStep(index)}
    >
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ mb: 0.8 }}>
          {props.children}
        </Box>
        {code ? <SpanLink
          pathname={`/admin/${type}/${id}`}
        >
          {code}
        </SpanLink> : "-"}

      </Box>
    </Alert>
  );
  return (alert

  );
};


export default DeliveryAgentClearanceForm;