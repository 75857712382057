import React from 'react'
import CustomDialog from '../../HOC/CustomComponents/CustomDialog'
import { SAVE_SHIPMENT_FILTER_GROUP } from '../GraphQl/FilterGraphQl';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ControlMUItextField from '../../HOC/MUI/ControlMUItextField';
import MuiSwitch from '../../HOC/MUI/MUIswitch';
import { Globals } from '../../HOC/Classes/Globals';
import ButtonLoading from '../../HOC/FunctionComponents/LoadingPages/ButtonLoading';
import { setValidationError } from '../../HOC/CustomFunctions/setValidationError';
import { LIST_USERS_DROPDOWN } from '../../../GlobalsQuery/ListDropdown/ListDropdown';
import { CustomAutocomplete } from '../../HOC/MUI/CustomAutocomplete';
import { Typography } from '@mui/material';

const SaveFilterGroupDialog = (props) => {
    const {
        filterGroupDialogState,
        closeFilterGroupDialog,
        listFilterInput,
        refetch,
        pushUrlSearchWithFilterId
    } = props

    const {
        handleSubmit,
        formState,
        control,
        setError,
        watch
    } = useForm();

    const { t } = useTranslation();
    const { errors } = formState;
    const [saveShipmentFilterGroup, { loading: saveShipmentFilterGroupLoading }] = useMutation(
        gql`
            ${SAVE_SHIPMENT_FILTER_GROUP.query}
        `
    );
    const canCreateGlobal = Globals.user.hasPermission("core.filter_group.create_public")
    const onSubmit = (data) => {
        saveShipmentFilterGroup({
            variables: {
                input: {
                    groupInput: {
                        name: data.groupInput.name,
                        public: data.groupInput.public,
                        ...((!data.groupInput.public && canCreateGlobal && data.groupInput.userId) && { userId: parseInt(data.groupInput.userId) }),

                    },
                    listInput: {
                        ...listFilterInput
                    }
                }
            }
        }).then((data) => {
            pushUrlSearchWithFilterId(data.data.saveShipmentFilterGroup.id)
            refetch()
            closeFilterGroupDialog()

        }).catch(({ graphQLErrors }) => {
            setValidationError(graphQLErrors, setError, "groupInput.");
        });
    };



    const parseData = (data) => {
        return data;
    };
    return (
        <div>
            <CustomDialog
                fullWidth
                maxWidth="xs"
                open={filterGroupDialogState}
                onClose={closeFilterGroupDialog}
                content={
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack my={1} spacing={2}>
                            <Stack justifyContent={"space-between"} direction={"row"} spacing={1}>
                                <Stack justifyContent={"flex-end"} direction={"row"} spacing={1}>
                                    <Typography sx={{ margin: "auto" }}>{t("addNew")}</Typography>
                                </Stack>
                                {canCreateGlobal && <MuiSwitch
                                    edge="end"
                                    name="groupInput.public"
                                    label={t("forAllUser")}
                                    control={control}
                                />}
                            </Stack>
                            <Stack>
                                <ControlMUItextField
                                    control={control}
                                    errors={errors}
                                    name={"groupInput.name"}
                                    label={t("name")}
                                    rules={{ required: t("fieldIsRequired") }}
                                />
                            </Stack>
                            <Stack>
                                {(!watch("groupInput.public") && canCreateGlobal) && <CustomAutocomplete
                                    name={"groupInput.userId"}
                                    label={t("user")}
                                    control={control}
                                    errors={errors}
                                    parseData={(data) => parseData(data)}
                                    query={LIST_USERS_DROPDOWN.query}
                                    variables={{
                                        input: {
                                            accountId: null,
                                        }
                                    }}
                                />}
                            </Stack>
                            <Stack justifyContent={"flex-end"} direction={"row"} spacing={1}>
                                <Stack justifyContent={"flex-end"} direction={"row"} spacing={1}>
                                    <Button variant="text" color='primary' type='submit'>
                                        {saveShipmentFilterGroupLoading ? <ButtonLoading /> : t("save")}
                                    </Button>
                                    <Button variant="text" color='primary' onClick={closeFilterGroupDialog}>{t("cancel")}</Button>
                                </Stack>
                            </Stack>

                        </Stack>
                    </form>
                }
            />
        </div>
    )
}

export default SaveFilterGroupDialog
