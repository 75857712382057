import {
  Box,
  Button,
  Icon,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import React, { Fragment, useState } from "react";

import { DeleteOutline, Edit, Print,Image } from "@mui/icons-material";
import clsx from "clsx";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { DELETE_MANIFEST_MUTATION } from "./Graphql";

import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { Can } from "../HOC/CustomComponents/Secured";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import useManifestDetails from "./ManifestDetails";

import { Globals } from "../HOC/Classes/Globals";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { dateFormatLL } from "../../helpers/dateFunctions";
import { GetManifestPermissionSlug } from "../../helpers/getPermissionSlug";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { gql, useQuery } from "@apollo/client";
import { MANIFEST_SHIPMENT_DATA_VIEW } from "./ManifestGraphQl";
import config from "../../config.json"
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
const PREFIX = "ManifestView";

const classes = {
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  viewPaper: `${PREFIX}-viewPaper`,
  table: `${PREFIX}-table`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  overlay: `${PREFIX}-overlay`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
};

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(2, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
    alignContent: "space-between"
  },

  [`& .${classes.viewPaper}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

export { };

const ManifestView = (props) => {
  const { t } = useTranslation();
  const { disableForm, disableView, manifestType, data, manifestByIdLoad, manifestId, pageProps, setLocalDataKey, shipmentListDetails, localKeys } = props

  const { details: manifestDetails } = useManifestDetails({
    type: manifestType,
    typeData: data.manifest
  });
  const manifestStatus = manifestDetails;
  /******************************************* Start Pagination Functions ********************************************/

  const [shipmentData, setShipmentData] = useState({
    page: 0,
    total: 0,
    rowsPerPage: config.app.pageSize,
    pickedShipment: []
  });

  const handleChangePage = (event, newPage) => {
    setShipmentData(prev => {
      return {
        ...prev,
        page: newPage
      }
    })
  };

  const handleChangeRowsPerPage = (event) => {
    setShipmentData(prev => {
      return {
        ...prev,
        page: 0,
        rowsPerPage: event.target.value
      }
    })
  };

  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
  });
  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };
  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };
  const openSignatureDialog = (imgPath) => {
    setDialogDetails((prev) => ({
      state: true,
      title: null,
      content: (
        <Box sx={{ background: "#fff", width: "100%" }} component="img" alt="signature" src={getBackendUri(imgPath.path)} />
      ),
      confirmAction: false,
      function: () => { },
    }));
  };
 
  const MANIFEST_SHIPMENT_DATA_VIEW_BUILDER = MANIFEST_SHIPMENT_DATA_VIEW(shipmentListDetails ? shipmentListDetails : [])
  const { loading: loadingShipmentData } = useQuery(
    gql`
        ${MANIFEST_SHIPMENT_DATA_VIEW_BUILDER.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(manifestId),
        page: shipmentData.page + 1,
        first: shipmentData.rowsPerPage
      },
      onCompleted: (data) => {
        if (data?.manifest === null) return;
        const manifest = data?.manifest;
        const shipments = manifest.entries.data
        setShipmentData(prev => {
          return {
            ...prev,
            pickedShipment: shipments,
            total: manifest.entries.paginatorInfo.total
          }
        })
      },
    }
  );

  const user = Globals.user;
  const branchId = data?.safe?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canEdit =
    user.hasPermission(GetManifestPermissionSlug(manifestType?.toLowerCase(), 'update')) &&
    canAccessBranch &&
    !data?.manifest?.approved &&
    !manifestByIdLoad;
  const canDelete =
    user.hasPermission(GetManifestPermissionSlug(manifestType?.toLowerCase(), 'delete')) &&
    canAccessBranch &&
    !data?.manifest?.approved &&
    !manifestByIdLoad;

  /******************************************* End Manifest List ********************************************/

  let tableBody = null;
  let viewBody = null;
  tableBody = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          selectedShipment={shipmentData.pickedShipment}
          manifestType={manifestType}
          setLocalDataKey={setLocalDataKey}
          total={shipmentData.total}
          localKeys={localKeys}
        />

        {!loadingShipmentData && (
          <TableFixedHeaderWraper>
            <ShipmentsTable
              data={shipmentData.pickedShipment}
              headCells={manifestStatus.tableHead}
              shipmentNumber={(index) => shipmentData.page * shipmentData.rowsPerPage + index + 1}
              parseBodyCell={manifestStatus.tableBody}
              sumRow={shipmentData.total > 0 ? manifestStatus.tableTotal : null}
            />
          </TableFixedHeaderWraper>
        )}

        <Grid container justifyContent="center" alignItems="center">
          {loadingShipmentData ? <FullScreenLoading minHeight={"100px"} /> : shipmentData.total === 0 && manifestId && disableForm && (
            <EmptyTableMessage
              message={t("noResult")}
            />
          )}
        </Grid>

        {shipmentData.total > 0 && (
          <MUITablePagination
            count={shipmentData.total}
            rowsPerPage={shipmentData.rowsPerPage}
            page={shipmentData.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        {(!manifestId || !disableForm) && (
          <Box className={classes.overlay}>
            <Typography color="textPrimary" variant="body1">
              {t("saveManifestRequired")}
            </Typography>
          </Box>
        )}

      </Paper>
    </Grid>
  );

  const [openDelete, setOpenDelete] = useState(false);

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(pageProps, `/admin/manifests/${manifestId}/edit`),
      icon: Edit,
      permission: canEdit,
    },
    {
      id: "print",
      title: "print",
      action: () =>
        window.open(
          `${window.location.origin}/report/print/manifest/${manifestId}/${manifestType}`
        ),
      icon: Print,
      disabled: manifestByIdLoad
    },
    {
      id: "delete",
      title: "delete",
      action: () => setOpenDelete(true),
      icon: DeleteOutline,
      permission: Boolean(canDelete),
    },
  ]

  viewBody = (
    // true ? (
    //   <Grid container item justifyContent="center" className={classes.main}>
    //     <FullScreenLoading height={"100%"} />
    //   </Grid>
    // ) : (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      sx={{ margin: "0", width: "100%" }}
    >
      <Fragment>
      <CustomDialog
        title={dialogDetails.title}
        fullWidth
        maxWidth="xs"
        onClose={closeConfirmationDialog}
        content={dialogDetails.content}
        open={dialogDetails.state}
        actions={
          dialogDetails.function !== null && (
            <>
              <Button color="primary" onClick={closeConfirmationDialog}>
                {dialogDetails.confirmAction ? t("cancel") : t("close")}
              </Button>
          
            </>
          )
        }
      >
        {dialogDetails.function === null && " "}
      </CustomDialog>
        <MutationWithDialog
          mutaion={DELETE_MANIFEST_MUTATION.query}
          setOpenDelete={setOpenDelete}
          openDelete={openDelete}
          dialogTitle={t("deleteManifest")}
          dialogContent={t("deleteManifestConfirmation")}
          mutaionProps={{ variables: { id: parseInt(manifestId) } }}
          onCompleted={() =>
            pushUrl(pageProps, `/admin/manifests/${manifestType.toLowerCase()}`)
          }
          onCompleteMessage={t("manifestDeleted")}
        />
        <TitleAppBar path={props.pageProps.match.path} type={manifestType}>
          <LongMenu icons={icons} />
        </TitleAppBar>
      </Fragment>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "0", width: "100%" }}
      >
        <Paper
          container
          className={classes.viewPaper}
          sx={{ width: "100%", mt: 1 }}
          component={Grid}
        >
          <KeyValuePair
            title={t("manifestCode")}
            value={data?.manifest?.code}
          />
          <KeyValuePair
            title={t("theDate")}
            value={dateFormatLL(data?.manifest?.date)}
          />
          <KeyValuePair
            title={t("branch")}
            value={
              data?.manifest?.branch?.name && (
                <SpanLink
                  pathname={`/admin/branches/${data?.manifest?.branch?.id}`}
                >
                  {data?.manifest?.branch?.name}
                </SpanLink>
              )
            }
          />
          {data?.manifest?.deliveryAgent ? (
            <KeyValuePair
              title={t("shippingAgent")}
              value={
                data?.manifest?.deliveryAgent?.name && (
                  <SpanLink
                    pathname={`/admin/delivery-agents/${data?.manifest?.deliveryAgent?.id}`}
                  >
                    {data?.manifest?.deliveryAgent?.name}
                  </SpanLink>
                )
              }
            />
          ) : null}
          {data?.manifest?.route ? (
            <KeyValuePair
              title={t("route")}
              value={
                data?.manifest?.route?.name && (
                  <SpanLink
                    pathname={`/admin/routes/${data?.manifest?.route?.id}`}
                  >
                    {data?.manifest?.route?.name}
                  </SpanLink>
                )
              }
            />
          ) : null}
          <KeyValuePair
            title={t("transactionType")}
            value={
              data?.manifest?.transactionType?.name && (
                <SpanLink
                  pathname={`/admin/transaction-types/${data?.manifest?.transactionType?.id}`}
                >
                  {data?.manifest?.transactionType?.name}
                </SpanLink>
              )
            }
          />
          {data?.manifest?.customer ? (
            <KeyValuePair
              title={t("customer")}
              value={
                data?.manifest?.customer?.name && (
                  <SpanLink
                    pathname={`/admin/customers/${data?.manifest?.customer?.id}`}
                  >
                    {data?.manifest?.customer?.name}
                  </SpanLink>
                )
              }
            />
          ) : null}
          {data?.manifest?.referenceBranch ? (
            <KeyValuePair
              title={
                data?.manifest?.transactionType?.type?.code === "BMT"
                  ? t("toBranch")
                  : t("fromBranch")
              }
              value={
                data?.manifest?.referenceBranch?.name && (
                  <SpanLink
                    pathname={`/admin/branches/${data?.manifest?.referenceBranch?.id}`}
                  >
                    {data?.manifest?.referenceBranch?.name}
                  </SpanLink>
                )
              }
            />
          ) : null}
          <KeyValuePair title={t("notes")} value={data?.manifest?.notes} />
          <KeyValuePair
            title={t("approved")}
            value={
              data?.manifest?.approved ? (
                <Icon className={classes.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("createdBy")}
            value={
              data?.manifest?.createdBy?.username && (
                <SpanLink
                  pathname={`/admin/users/${data?.manifest?.createdBy?.id}`}
                >
                  {data?.manifest?.createdBy?.username}
                </SpanLink>
              )
            }
          />
          <KeyValuePair
            title={t("updatedBy")}
            value={
              data?.manifest?.updatedBy?.username && (
                <SpanLink
                  pathname={`/admin/users/${data?.manifest?.updatedBy?.id}`}
                >
                  {data?.manifest?.updatedBy?.username}
                </SpanLink>
              )
            }
          />
          {data?.manifest?.referenceManifest ? (
            <KeyValuePair
              title={t("fromManifest")}
              value={
                data?.manifest?.referenceManifest?.code && (
                  <SpanLink
                    pathname={`/admin/manifests/${data?.manifest?.referenceManifest?.id}`}
                  >
                    {data?.manifest?.referenceManifest?.code}
                  </SpanLink>
                )
              }
            />
          ) : null}


          {manifestType === 'RTRN' &&
            (data?.manifest?.images.length > 0 ?
              (<KeyValuePair
                title={t("image")}
                value={
                  <IconButton
                    size="small"
                    onClick={() => openSignatureDialog(data?.manifest?.images[0])}
                  >
                    <Image fontSize="small" />
                  </IconButton>
                }
              />) :
              (<KeyValuePair title={t("image")}>
              </KeyValuePair>))}
        </Paper>
      </Grid>
    </Grid>
    // )
  );

  const tableCondition = Boolean(manifestId) && !manifestByIdLoad;
  return manifestByIdLoad ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading> :
    data?.manifest === null ? (
      <NotFound />
    ) : disableView ? (
      <Can permission="uneEditable" />
    ) : (
      <Root>
        {/* {props.children} */}

        {viewBody}

        {!manifestByIdLoad && tableCondition && <Grid
          container
          spacing={3}
          justifyContent="center"
          className={classes.mainTracking}
        >
          {tableBody}
        </Grid>}
      </Root>
    );
};

export default ManifestView;

