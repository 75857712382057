import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useQuery, gql } from "@apollo/client";
import {
  TableBody,
  TableRow,
  Icon,
  Button,
} from "@mui/material";
import { Add, FilterList, Search } from "@mui/icons-material";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_JOURNAL_ENTRY } from "./Graphql";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUIselect from "../HOC/MUI/MUIselect";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_JOURNAL_TYPES_DROPDOWN,
  LIST_SUBSIDIARIES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import moment from "moment";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import Grid from '@mui/material/Unstable_Grid2';
import config from "../../config.json";
import { dateFormat } from "../../helpers/dateFunctions";


const PREFIX = "ListJournalEntry";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },


  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },
}));

const ListJournalEntry = (props) => {
  const { t } = useTranslation();
  const [refetch, setrefetch] = useState(true);
  const [filterLoad, setFilterLoad] = useState(true);
  const urlQuery = urlParameters(window.location.search);
  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : moment(new Date())
      .locale("en")
      .subtract(1, "month")
      .add(1, "day")
      .format("YYYY-MM-DD");
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : moment(new Date()).locale("en").format("YYYY-MM-DD");
  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    type: null,
    glAccount: null,
    subsidiary: null,
  });
  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: props.width === "xs" ? false : true,
    bottom: props.width === "xs" ? false : true,
    right: props.width === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      fromDate: dateRange[0],
      toDate: dateRange[1],
      // typeId: 1
    };
    delete urlQuery["rowsPerPage"];
    if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();

    if (validUrlParameters) {
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["code"] && setValue("code", urlQuery["code"].toString());

    urlQuery["approved"] !== undefined &&
      setValue("approved", urlQuery["approved"]);

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/finance/journal-entry",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = props.width === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    setFilterLoad(false)
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
    (refetch) ? setrefetch(false) : setrefetch(true)
    let handledData = {
      fromDate: moment(dateRange[0]).locale("en").format("YYYY-MM-DD"),
      toDate: moment(dateRange[1]).locale("en").format("YYYY-MM-DD"),
      approved: data.approved,
      code: data.code,
      branchId: data.branchId,
      typeId: data.typeId,
      glAccountId: data.glAccountId,
      subsidiaryId: data.subsidiaryId,
      page: 0,
    };
    data["approved"] === "" && delete handledData["approved"];

    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {

    // if(fieldName==="type"&&!urlQuery["typeId"]){ 
    //   id=1 
    // }
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const parseData = (data) => {
    return data;
  };

  const { data, loading } = useQuery(
    gql`
      ${LIST_JOURNAL_ENTRY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          fromDate: search.fromDate,
          toDate: search.toDate,
          ...(search.approved !== null && { approved: search.approved }),
          ...(search.branchId && { branchId: search.branchId }),
          ...(search.typeId && { typeId: search.typeId }),
          ...(search.glAccountId && { glAccountId: search.glAccountId }),
          ...(search.subsidiaryId && { subsidiaryId: search.subsidiaryId }),
          ...(search.code && { code: search.code }),
        },
      },
      onCompleted: (data) => { },
    }
  );

  const listJournalEntries =
    data?.listJournalEntries?.data !== null
      ? data?.listJournalEntries?.data
      : null;


  const addURL = () => {
    pushUrl(props, `/admin/finance/journal-entry/create`);
  }
  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: addURL,
      icon: Add,
      permission: "accounting.journal_entry.create",
    },
  ]
  return (
    <Root>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={listJournalEntries?.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <ControlMUItextField
                  control={control}
                  errors={errors}
                  name={"code"}
                  label={t("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIselect
                  name={"approved"}
                  label={t("approved")}
                  control={control}
                  errors={errors}
                  data={[
                    { key: t("notApproved"), value: false },
                    { key: t("approved"), value: true },
                  ]}
                />
              </Grid>

              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading && filterLoad}
                  name={"branchId"}
                  label={t("branch")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["branchId"],
                      "branch"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_BRANCHES_DROPDOWN.query}
                  defaultValue={autocompleteValues.branch}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading && filterLoad}
                  name={"typeId"}
                  label={t("journalType")}
                  parseData={(data) => parseData(data)}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["typeId"],
                      "type"
                    )
                  }
                  query={LIST_JOURNAL_TYPES_DROPDOWN.query}
                  defaultValue={autocompleteValues.type}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading && filterLoad}
                  name={"glAccountId"}
                  label={t("glAccount")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["glAccountId"],
                      "glAccount"
                    )
                  }
                  variables={{
                    input: {
                      typeCode: "SUB",
                    },
                  }}
                  parseData={(data) => parseData(data)}
                  query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                  defaultValue={autocompleteValues.glAccount}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  skip={loading && filterLoad}
                  name={"subsidiaryId"}
                  label={t("subsidiary")}
                  onCompleted={(data) =>
                    handelAutocompleteDefaultValue(
                      data,
                      urlQuery["subsidiaryId"],
                      "subsidiary"
                    )
                  }
                  parseData={(data) => parseData(data)}
                  query={LIST_SUBSIDIARIES_DROPDOWN.query}
                  defaultValue={autocompleteValues.subsidiary}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          [
            {
              name: "code"
            },
            {
              name: "date"
            },
            {
              name: "branch"
            },
            {
              name: "type"
            },
            {
              name: "subsidiary"
            },
            {
              name: "description"
            },
            {
              name: "approved"
            },
            {
              name: "createdBy"
            },
            {
              name: "updatedBy"
            },


          ]
        }
        tableBody={
          <TableBody>
            {listJournalEntries &&
              listJournalEntries?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <CellLink
                      pathname={`/admin/finance/journal-entry/${row?.id}`}
                    >
                      {row?.code}
                    </CellLink>
                    <FixedTableCell>{dateFormat(row?.date)}</FixedTableCell>
                    <FixedTableCell>{row?.branch.name}</FixedTableCell>
                    <FixedTableCell>{row?.type.name}</FixedTableCell>
                    <FixedTableCell>
                      {row?.subsidiary?.name ?? t("placeholder")}
                    </FixedTableCell>
                    <FixedTableCell>{row?.description}</FixedTableCell>
                    <FixedTableCell>
                      {row?.approved ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                    <CellLink
                      align="left"
                      pathname={`/admin/users/${row?.createdBy?.id}`}
                    >
                      {row?.createdBy?.username}
                    </CellLink>
                    <CellLink
                      align="left"
                      pathname={`/admin/users/${row?.updatedBy?.id}`}
                    >
                      {row?.updatedBy?.username}
                    </CellLink>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listJournalEntries?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listJournalEntries ? 0 : search.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default ListJournalEntry;
