function importAll(r) {
    return r.keys()
        .filter(key => key.includes('Sticker_')) // Filter files starting with "Temp_"
        .map(key => {
            const module = r(key);
            const fileName = key.replace('./Sticker_', ''); // Remove "Temp_" prefix from the filename

            return {
                fileName: fileName,
                module: module.default || module
            };
        });
}

const context = require.context('../Component/Shipments/Waybill', false, /\.js$/);
const files = importAll(context);

export const fileNames = (t) => files.map(file => {
    const fileName = file.fileName.replace('./', '').replace('.js', '')

    return {
        printLink: fileName,
        code: fileName,
        name: t("sticky", { template: fileName })
    }
});