/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  Toolbar,
  IconButton,
  Collapse,
  InputAdornment,
  CircularProgress
} from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";
import clsx from "clsx";
import MUItextField from "../../HOC/MUI/MUItextField";
import PropTypes from "prop-types";
import { gql, useMutation, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { useTranslation } from "react-i18next";
import CustomTableRow from "./CustomTableRow";
import { EnhancedTableHead } from "./PickupListDetails";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import CustomSpinner from "../../HOC/FunctionComponents/CustomSpinner";
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import { SAVE_PAYMENT_ENTRIES_MUTATION } from "../PaymentListQuary";
import Backdrop from '@mui/material/Backdrop';
const TOOLBAR_PREFIX = "styledToolbar";

const toolbarClasses = {
  highlight: `${TOOLBAR_PREFIX}-highlight`,
  title: `${TOOLBAR_PREFIX}-title`,
  filterButton: `${TOOLBAR_PREFIX}-filterButton`,
};

const StyledToolbar = styled("div")(({ theme }) => ({
  width: "100%",
  zIndex: 1,

  [`& .${toolbarClasses.highlight}`]:
    theme.palette.mode === "light"
      ? {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[300],
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[800],
      },

  [`& .${toolbarClasses.title}`]: {
    flex: "1 1 100%",
  },

  [`& .${toolbarClasses.filterButton}`]: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const PREFIX = "BarcodeSelector";

const classes = {
  track: `${PREFIX}-track`,
  paper: `${PREFIX}-paper`,
  filters: `${PREFIX}-filters`,
};

const Root = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(0, 0),
  width: "100%",
  backgroundColor: theme.palette.background.paper,

  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowX: "auto",
    maxHeight: `calc(100vh - ${theme.mixins.toolbar["minHeight"]}px)`,
  },

  [`& .${classes.filters}`]: {
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 72px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },

    height: "100%",
    overflow: "hidden",
  },
}));
const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { numSelected, done, clear, disabled } = props;
  return (
    <StyledToolbar>
      <Toolbar
        className={clsx({
          [toolbarClasses.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={toolbarClasses.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {t("pickupCount")} {numSelected}
          </Typography>
        ) : (
          <Typography
            className={toolbarClasses.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("pickupRequests")}
          </Typography>
        )}
        {/* <Tooltip title="تم" > */}
        <IconButton
          aria-label="done"
          disabled={disabled}
          onClick={done}
          sx={{ color: "success.main" }}
          size="large"
        >
          <CheckCircle />
        </IconButton>
        {/* </Tooltip> */}

        <IconButton
          aria-label="close"
          onClick={() => clear()}
          color={"primary"}
          size="large"
        >
          <Cancel />
        </IconButton>
      </Toolbar>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  done: PropTypes.func.isRequired,
};

//*********Table Function*********

const PICKUP_QUERY = gqlb.query({
  operation: "listPickups",
  fields: [
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "date",
        "notes",
        "deliveryCommission",
        {
          customer: [
            "id",
            "name"
          ]
        },
      ],
      variables: {},
    },
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListPickupsFilterInput",
    },
  },
});

const BarcodePickupSelector = (props) => {
  const { type, variables, done, refetchPickupDataQuery } = props;

  const [pickups, setPickups] = useState([]);

  const [pickupCode, setPickupCode] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { register, setValue } = useForm();

  const { loading } = useQuery(
    gql`
      ${PICKUP_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !variables.id || !pickupCode,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: variables.id,
        input: {
          forPaymentId: variables.id,
          code: pickupCode,
        },
      },
      onCompleted: (data) => {
        const handledData =
          data?.listPickups?.data !== null ? data?.listPickups?.data : [];
        if (handledData.length > 0) {
          const enhancedData = handledData.map((i) => ({
            pickup: i,
            select: true,
          }));

          setPickups((prev) => [...prev, ...enhancedData]);
          setValue("barCode", "");
          setPickupCode(null);
        } else {
          enqueueSnackbar(t("noPickupWithCode"), {
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      },
    }
  );

  const handleSelectAllClick = (event, rows) => {
    const selected = pickups?.map((i) => {
      if (event.target.checked) {
        return {
          ...i,
          select: true,
        };
      } else {
        return {
          ...i,
          select: false,
        };
      }
    });
    setPickups(selected);
  };

  const [savePaymentEntriesMutation, { loading: savePaymentEntriesMutationLoad }] = useMutation(
    gql`
      ${SAVE_PAYMENT_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const onSaveEntries = (data) => {
    const pickups = data.map(item => item.pickup.id);
    pickups.length > 0 && savePaymentEntriesMutation({
      variables: {
        input: {
          paymentId: variables.id,
          pickupIds: pickups
        }
      }
    }).then(() => {
      enqueueSnackbar(t("saveSuccessful"), {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
      // collectionRefetch()
      refetchPickupDataQuery()
      props.done()
    }).catch((error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
    })
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const selectedPickup = pickups.filter((i) => i.select === true);
  const selectedPickupLength = selectedPickup.length;
  const selectedValidPickup = selectedPickup;

  let tableBody = null;
  const handleOnClose = () => {
    setPickups([])
  }
  const validPickups = () => {
    return selectedPickupLength <= 0;
  };
  if (pickups) {
    // if (true) {
    tableBody = (
      <Grid container justifyContent="center" sx={{ width: "100%" }}>
        {/* *******Table******* */}
        <Grid container item>
          <div className={classes.paper}>
            <TableFixedHeaderWraper containerScroll>
              <Table
                // className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  numSelected={selectedPickupLength}
                  onSelectAllClick={(e) => handleSelectAllClick(e, pickups)}
                  rowCount={pickups?.length}
                  type={type}
                />
                <TableBody>
                  {
                    // stableSort(, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    pickups?.map((pickup, index) => {
                      // const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <CustomTableRow
                          key={index}
                          pickup={pickup}
                          type={type}
                          onChangeCheck={(check, selectedPickup) => {
                            setPickups((prev) => {
                              let updated = [...prev];
                              updated[index] = {
                                ...updated[index],
                                select: !updated[index].select,
                              };

                              return updated;
                            });
                          }}
                          labelId={labelId}
                        />
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          </div>
        </Grid>
      </Grid>
    );
  }
  return (
    <Root container justifyContent="center">
      <Backdrop
        sx={{ color: (theme) => theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={savePaymentEntriesMutationLoad}
        onClick={() => { }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <EnhancedTableToolbar
        numSelected={selectedPickupLength}
        clear={() => {
          handleOnClose();
          done();
          setValue("barCode", '')
        }}
        done={() => {
          setPickups([]);
          done();
          // setSelected(selectedValidPickup);
          onSaveEntries(selectedValidPickup)
        }}
        disabled={validPickups()}
      />

      <Grid container item sx={{ p: 3 }} justifyContent="center" xs={12}>
        <MUItextField
          label={t("barcode")}
          autoFocus
          name={"barCode"}
          register={register}
          inputProps={{
            dir: "ltr",
          }}
          readOnly={loading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loading && (
                  <CustomSpinner name="PulseLoader" size={5} margin={2} />
                )}
              </InputAdornment>
            ),
          }}
          defaultValue=""
          onKeyPress={(e) => {
            const value = e.target.value;
            const duplicatedCode = pickups.findIndex(
              (i) => i.pickup.code.toLowerCase() === value.toLowerCase()
            );
            if (duplicatedCode !== -1) {
              enqueueSnackbar(t("thisPickupIsRepeated"), {
                variant: "info",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
              setValue("barCode", "");
              return;
            }
            if (e.key === "Enter") {
              setPickupCode(value);
            }
          }}
        />
      </Grid>
      {pickups && pickups?.length !== 0 ? (
        tableBody
      ) : (
        <EmptyTableMessage
          message={t("noResult")}
        />
      )}
    </Root>
  );
};

export default BarcodePickupSelector;
