import { TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FixedTableCell } from "./FixedTableCell";

const ShipmentsTableHeader = (props) => {
    const {
        checkAll,
        headCells,
        classes
    } = props;

    const { t } = useTranslation();

    const getClassName = (headCell) => {
        if (headCell === "code") {
            return classes?.[`shipmentTable_code`]
        }
        if (headCell === "update") {
            return classes?.[`update`]
        }
        return classes?.tableHeadFixed
    }

    return (
        <TableHead className={classes?.tableHead}>
            <TableRow>
                {checkAll && checkAll}
                {headCells.map((headCell, index) => {
                    return headCell !== null ? (
                        <FixedTableCell
                            className={getClassName(headCell)}
                            key={index}
                        >
                            {headCell === "update" ? '' : t(headCell)}
                        </FixedTableCell>
                    ) : null;
                })}
            </TableRow>
        </TableHead>
    )
}

ShipmentsTableHeader.propTypes = {
};

export default ShipmentsTableHeader;