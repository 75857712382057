import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FixedTableCell } from "./FixedTableCell";

const ProductsTable = (props) => {
  const {
    page,
    rowsPerPage,
    headCells,
    data,
    parseBodyCell,
    total,
    sumRow,
    classes,
    sumTableNumber,
    checkAll,
    invoiceQ,
    sum,

    ...restProps
  } = props;
  const { t } = useTranslation();
  const tableTotal = { ...total };

  data &&
    data.forEach((product) => {
      return sumTableNumber
        ? sumTableNumber(product, tableTotal)
        : Object.entries(tableTotal).forEach(([k, v]) => {
          tableTotal[k] += product.quantity[k] ?? 0;
        });
    });


  const tableData =
    (rowsPerPage
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : data) ?? [];

  return (
    <Table
      {...restProps}
      aria-labelledby="tableTitle"
      aria-label="enhanced table"
    >
      <TableHead className={classes?.tableHead}>
        <TableRow>
          {checkAll && checkAll}
          {headCells.map((headCell, index) => {
            return headCell !== null ? (
              <FixedTableCell
                className={classes?.[`productTable_${headCell}`]}
                key={index}
              >
                {t(headCell)}
              </FixedTableCell>
            ) : null;
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData.map(parseBodyCell)}
        {sumRow ??
          (data.length > 0 && (
            <TableRow role="checkbox" tabIndex={-1}>
              <FixedTableCell
                colSpan={
                  headCells.length - 2 - Object.entries(tableTotal).length
                }
              > </FixedTableCell>
              <FixedTableCell variant="head">{t("total")}</FixedTableCell>
              {Object.entries(tableTotal).map(([k, v], index) => {
                return (
                  <FixedTableCell variant="head" key={index}>
                    {v}
                  </FixedTableCell>
                );
              })}
              {!headCells.some((i) => i === null) && (
                <FixedTableCell> </FixedTableCell>
              )}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
ProductsTable.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  headCells: PropTypes.array.isRequired,
  data: PropTypes.array,
  parseBodyCell: PropTypes.func.isRequired,
  total: PropTypes.object,
  sumRow: PropTypes.any,
  classes: PropTypes.any,
};
export default ProductsTable;
