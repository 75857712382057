import { TableBody } from "@mui/material";
import PropTypes from "prop-types";

const ShipmentsTableBody = (props) => {
    const {
        data,
        parseBodyCell,
        sumShipments
    } = props;
    const tableData = data ?? []
    return (
        <TableBody>
            {tableData.map(parseBodyCell)}
            {sumShipments}
        </TableBody>
    )
}

ShipmentsTableBody.propTypes = {
    data: PropTypes.array.isRequired,
    parseBodyCell: PropTypes.func.isRequired,
};

export default ShipmentsTableBody;