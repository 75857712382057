import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { paymentAllEntries, paymentEntriesFieldsPrint, paymentFields } from "../Payment/PaymentListQuary";
import { invoiceAllEntries, invoiceEntriesFieldsPrint, invoiceFields } from "../Invoices/InvoicesListQuary";
import { conciergeRequest, collectionFields } from "../Collection/CollectionListQuary";
import useManifestDetails from "../Manifests/ManifestDetails";
import usePaymentsDetails from "../Payment/paymentDetails";
import usePaymentPickupsDetails from "../Payment/paymentPickupsDetails";
import useInvoicesDetails from "../Invoices/InvoicesDetails";
import useCollectionsDetails from "../Collection/CollectionDetails";
import useWarehouseManifestDetails from "../ManifestsProducts/WarehouseManifestDetails";
import { warehouseManifestFields, warehouseManifestFieldsPrint } from "../ManifestsProducts/Graphql";
import useConciergeRequestsPaymentDetails from "../Payment/ConciergeRequestsDetails";
import useConciergeRequestsCollectionDetails from "../Collection/ConciergeRequestsDetails";
import { manifestFields } from "../Manifests/ManifestGraphQl";
import { changeKey, defaultQueryFields, initialManifest } from "../Manifests/listManifestFields";
import { defaultQueryFieldsCollection, initialData } from "../Collection/listCollectionFields";
import { defaultQueryFieldsPayment, initialPaymentData } from "../Payment/listPaymentFields";
import { defaultQueryFieldsInvoice, invoice } from "../Invoices/listInvoicesFields";
const ALLOWED_CONCIERGE = gqlb.query({
  operation: "shippingSettings",
  fields: [
    "allowedCustomerTypes"
  ], variables: {},
});
const usePagesCount = (props) => {
  const printType = props.match.params.type;
  const manifestId = +props.match.params.id;
  const urlQuery = urlParameters(window.location.search);
  const trxType = props.match.params?.trxType;
  const floatValues = ["fromWeight", "toWeight"];

  floatValues.forEach((i) => {
    if (urlQuery[i]) urlQuery[i] = parseFloat(urlQuery[i]);
  });
  const keys = localStorage.getItem(`shipmentManifest${trxType}` ? `shipmentManifest${trxType}` : initialManifest[trxType])
  const adminNotesPermission = keys && keys?.includes("adminNotes")
  const keysPayment = localStorage.getItem(`shipmentPayment${trxType}` ? `shipmentPayment${trxType}` : initialManifest[trxType])
  const adminNotesPermissionPayment = keysPayment && keysPayment?.includes("adminNotes")
  const queriesDetails = {
    manifest: {
      queryFields: manifestFields,
      queryEntriesFields: printType === "manifest" && defaultQueryFields(localStorage.getItem(`shipmentManifest${trxType}`) ? changeKey(JSON.parse(localStorage.getItem(`shipmentManifest${trxType}`)), `shipmentManifest${trxType}`) : initialManifest[trxType], adminNotesPermission),
      operationName: "entries",
      withId: "manifest",
      withList: "listManifests",
      listFilters: "ListManifestFilterInput",
      variableType: "transactionTypeCode",
      printTableDetails: useManifestDetails,
    },
    payment: {
      queryFields: paymentFields,
      allEntries: printType === "payment" && paymentAllEntries(defaultQueryFieldsPayment(localStorage.getItem(`shipmentPayment${trxType}`) ? JSON.parse(localStorage.getItem(`shipmentPayment${trxType}`)) : initialPaymentData[trxType], adminNotesPermissionPayment)),
      queryEntriesFields: printType === "payment" && paymentEntriesFieldsPrint(defaultQueryFieldsPayment(localStorage.getItem(`shipmentPayment${trxType}`) ? JSON.parse(localStorage.getItem(`shipmentPayment${trxType}`)) : initialPaymentData[trxType], adminNotesPermissionPayment)),
      operationName: "entries",
      withId: "payment",
      withList: "listPayments",
      listFilters: "ListPaymentFilterInput",
      variableType: "typeCode",
      printTableDetails: usePaymentsDetails,
      printTablePickupsDetails: usePaymentPickupsDetails,
      printTableConciergeDetails: useConciergeRequestsPaymentDetails,
    },
    invoice: {
      queryFields: invoiceFields,
      allEntries: printType === "invoice" && invoiceAllEntries(defaultQueryFieldsInvoice(localStorage.getItem(`shipmentInvoice`) ? JSON.parse(localStorage.getItem(`shipmentInvoice`)) : invoice)),
      queryEntriesFields: printType === "invoice" && invoiceEntriesFieldsPrint(defaultQueryFieldsInvoice(localStorage.getItem(`shipmentInvoice`) ? JSON.parse(localStorage.getItem(`shipmentInvoice`)) : invoice)),
      operationName: "entries",
      withId: "invoice",
      withList: "listInvoices",
      listFilters: "ListInvoicesFilterInput",
      variableType: "type",
      printTableDetails: useInvoicesDetails,
    },
    collection: {
      queryFields: collectionFields,
      queryEntriesFields: printType === "collection" && defaultQueryFieldsCollection(localStorage.getItem(`shipmentcollection${trxType}`) ? JSON.parse(localStorage.getItem(`shipmentcollection${trxType}`)) : initialData[trxType]).concat(conciergeRequest),
      operationName: "entries",
      withId: "collection",
      withList: "listCollections",
      listFilters: "ListCollectionFilterInput",
      variableType: null,
      printTableDetails: useCollectionsDetails,
      printTableConciergeDetails: useConciergeRequestsCollectionDetails,
    },
    warehousemanifest: {
      queryFields: warehouseManifestFields,
      queryEntriesFields: warehouseManifestFieldsPrint,
      operationName: "manifestProducts",
      withId: "warehouseManifest",
      withList: "listWarehouseManifests",
      listFilters: "ListWarehouseManifestFilterInput",
      variableType: "type",
      printTableDetails: useWarehouseManifestDetails,
    },
  };

  const listCondition = !manifestId;
  const queryType = listCondition ? "withList" : "withId";

  const {
    printTableDetails,
    printTablePickupsDetails,
    printTableConciergeDetails,
    queryFields,
    queryEntriesFields,
    operationName,
    listFilters,
    variableType,
    allEntries,
    [queryType]: queryName,
  } = queriesDetails[printType];
  const PAGE_QUERY = gqlb.query({
    operation: queryName,
    fields: [
      {
        operation: "paginatorInfo",
        fields: ["lastPage"],
        variables: {},
      },
    ],
    variables: {
      first: {
        type: "Int",
      },
      input: {
        type: listFilters,
        required: true,
      },
    },
  });

  const { data: lastPage, loading: loadingLastPage } = useQuery(
    gql`
      ${PAGE_QUERY.query}
    `,
    {
      skip: !listCondition,
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      variables: {
        first: 100,
        input: {
          ...urlQuery,
          ...(urlQuery.code && { code: urlQuery.code.toString() }),
          ...(variableType && { [variableType]: trxType }),
        },
      },
    }
  );

  const pagesCount = lastPage?.[queryName]?.paginatorInfo?.lastPage ?? 1;

  let manifestBody;

  if (listCondition) {
    const queryArray = [];
    for (let i = 0; i < pagesCount; i++) {
      const initialQuery = {
        operation: `${queryName}_${i}:${queryName}`,
        fields: [{
          data: [
            ...queryFields,
            allEntries
          ]
        }],
        variables: {
          [`page_${i + 1}`]: {
            name: "page",
            type: "Int",
            value: i + 1,
          },
          input: {
            type: listFilters,
            required: true,
          },
          first: {
            type: "Int",
          },
        },
      };
      queryArray.push(initialQuery);
    }

    manifestBody = queryArray;
  }


  const PAGE_QUERY_ENTRIES = gqlb.query({
    operation: queryName,
    fields: [
      {
        operation: operationName,
        fields: [
          {
            operation: "paginatorInfo",
            fields: ["lastPage"],
            variables: {},
          }
        ],
        variables: {
          first: {
            type: "Int",
          },
        },
      }
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  });

  const { data: entriesLastPage, loading: loadingEntriesLastPage } = useQuery(
    gql`
      ${PAGE_QUERY_ENTRIES.query}
    `,
    {
      skip: listCondition,
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      variables: {
        id: manifestId,
        first: 100
      },
    }
  );

  const entriesLastPageCount = entriesLastPage?.[queryName]?.entries?.paginatorInfo?.lastPage ?? 1;

  if (!listCondition) {
    manifestBody = [
      {
        operation: queryName,
        fields: [
          ...queryFields,
        ],
        variables: {
          id: {
            type: "Int",
            required: true,
          },
        },
      },
    ];
    for (let i = 0; i < entriesLastPageCount; i++) {
      const initialQuery = {
        operation: `${operationName}_${i}:${operationName}`,
        fields: [{ data: queryEntriesFields }],
        variables: {
          [`page_${i + 1}`]: {
            name: "page",
            type: "Int",
            value: i + 1,
          },
          first: {
            type: "Int",
          },
        },
      };
      manifestBody[0].fields.push(initialQuery);
    }
  }

  const MANIFEST_QUERY = gqlb.query([
    ...manifestBody,
    {
      operation: "organization",
      fields: [
        "name",
        "phone",
        "email",
        "website",
        "reportHeader",
        "reportFooter",
      ],
      variables: {},
    },
  ]);

  const { data, loading } = useQuery(
    gql`
      ${MANIFEST_QUERY.query}
    `,
    {
      skip: (listCondition && loadingLastPage) || loadingEntriesLastPage,
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      variables: {
        ...(listCondition && {
          ...MANIFEST_QUERY.variables,
          first: 100,
          input: {
            ...urlQuery,
            ...(urlQuery.code && { code: urlQuery.code.toString() }),
            ...(variableType && { [variableType]: trxType }),
          },
        }),
        ...(!listCondition && {
          ...MANIFEST_QUERY.variables,
          first: 100,
          id: manifestId,
        }),
      },
    }
  );

  const organization = data?.organization;
  let reportDetails;

  if (listCondition) {
    if (data?.[`${queryName}_0`]) {
      let concatData = [];
      for (const key in data) {
        if (key.startsWith(queryName)) {
          const listData = data[key].data;
          concatData = concatData.concat(listData);
        }
      }
      reportDetails = concatData;
    }
  } else {
    reportDetails = data?.[queryName];
  }

  const { data: allowedConcierge, loading: loadingConcierge } = useQuery(
    gql`
      ${ALLOWED_CONCIERGE.query}
    `,
    {
      // notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        console.log(data);
      },
    }
  );
  return {
    loading: loading || loadingLastPage || loadingEntriesLastPage || loadingConcierge,
    allowedConcierge,
    reportDetails,
    organization,
    printTableDetails,
    printTablePickupsDetails,
    printTableConciergeDetails,
    operationName
  };
};

export default usePagesCount;
