import { useTranslation } from "react-i18next";
import { Chip, Paper, Typography } from "@mui/material";
import CustomTab from "../../HOC/FunctionComponents/CustomTab";
import { ProductTableForm } from "./formTablesView";
import Grid from "@mui/material/Unstable_Grid2";


function FormTabsView(props) {
    const {
        classes,
        trackingData,
        inProducts,
        outProducts
    } = props
    const { t } = useTranslation()
    let shipments = (
        <ProductTableForm
            classes={classes}
            trackingData={trackingData}
            productList={outProducts}
        />
    )

    const tapsArray = [];

    if (trackingData?.type?.code === "PTP" || trackingData?.type?.code === "FDP") {
        tapsArray.push({
            tabHead:
                <Typography
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {t("deliveryProduct")}
                    <Chip color="primary" size="small" label={outProducts.length} sx={{ mx: 1 }} />
                </Typography>,
            panel:
                <Paper>{shipments}</Paper>
            ,
        })
    }

    if (trackingData?.type?.code === "PTP" || trackingData?.type?.code === "RTS") {

        let product = (
            <ProductTableForm
                classes={classes}
                trackingData={trackingData}
                productList={inProducts}
                inProduct={true}
                shipmentTypeCode={trackingData?.type?.code}
            />
        )

        tapsArray.push(
            {
                tabHead:
                    <Typography
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {t("returnProduct")}
                        <Chip color="primary" size="small" label={inProducts.length} sx={{ mx: 1 }} />
                    </Typography>,
                panel: <Paper>{product}</Paper>
                ,
            },
        );
    }
    return (
        <Grid xs={12} >
            <CustomTab tapDetails={tapsArray} />
        </Grid>
    )

}
export default FormTabsView