import React, { Fragment, useEffect } from "react";
// import "./ShipmentPrint.css";
import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { withRouter } from "react-router";
import clsx from "clsx";

import { urlParameters } from "../../HOC/CustomFunctions/urlParameters";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

// const SHIPMENT_QUERY = gqlb.query([
//   {
//     operation: "listShipments",
//     fields: [
//       {
//         data:,
//     variables: {
//       input: {
//         type: "ListShipmentsFilterInput",
//       },
//     },
//   },
// ]);

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
  printSticky: `${PREFIX}-printSticky`,
  stickyCode: `${PREFIX}-stickyCode`,
  stickyRecipientName: `${PREFIX}-stickyRecipientName`,
  stickyDestination: `${PREFIX}-stickyDestination`,
  stickyRecipientAddress: `${PREFIX}-stickyRecipientAddress`,
  centerContent: `${PREFIX}-centerContent`,
  barcode: `${PREFIX}-barcode`,
};
export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.printSticky}`]: {
    pageBreakAfter: "always",
    pageBreakInside: "avoid",
    maxWidth: "5cm",
    maxHeight: "2cm",
    overflow: "hidden",
    fontSize: 11,
    color: "black",
  },

  [`& .${classes.stickyCode}`]: {
    height: "100%",
    overflow: "hidden",
    fontSize: "30px !important",
  },

  [`& .${classes.stickyRecipientName}`]: {
    height: "calc(100% * (0.75/4))",
    overflow: "hidden",
    whiteSpace: "pre",
  },

  [`& .${classes.stickyDestination}`]: {
    height: "calc(100% * (0.75/4))",
    overflow: "hidden",
    whiteSpace: "pre",
  },

  [`& .${classes.stickyRecipientAddress}`]: {
    height: "calc(100% * (1.5/4))",
    overflow: "hidden",
  },
  [`& .${classes.centerContent}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 48,
  },
}));

const listShipmentsFields = [
  {
    data: [
      "code",
      "recipientName",
      "recipientAddress",
      {
        recipientZone: ["name"],
      },
      {
        recipientSubzone: ["name"],
      },
    ],
  },
];
const barcodeBatch = [
  "id",
  { barcodes: ["id", "code"] }
];
const queryBuilder = (ids) => {
  const queryNumber = Math.ceil(ids.length / 100);
  const queryArray = [];
  for (let index = 0; index < queryNumber; index++) {
    const id = ids.slice(100 * index, 100 * (index + 1));
    const initialQuery = {
      operation: `list${index}:listShipments(first:100,input:{id:[${id}]})`,
      fields: listShipmentsFields,
      variables: {},
    };
    queryArray.push(initialQuery);
  }
  return queryArray;
};
const queryBuilderCode = (ids) => {
  const queryArray = [];
  const initialQuery = {
    operation: `listCode:barcodeBatch(id:${ids[0]})`,
    fields: barcodeBatch,
    variables: {},
  };
  queryArray.push(initialQuery);
  return queryArray;
};

const ShipmentStickyPrint2X5 = (props) => {
  const id = props.match.params.id.split(",").map(Number);
  const isBatch = props.location.pathname.startsWith('/batch');
  const urlQuery = urlParameters(window.location.search);

  const { t, i18n } = useTranslation();

  const queryBody = isBatch ? queryBuilderCode(id) : queryBuilder(id);

  const SHIPMENT_QUERY = gqlb.query(queryBody);

  const token = urlQuery["token"];
  const lang = urlQuery["lang"];

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
    return () => { };
  }, [i18n, lang]);

  const { data, loading } = useQuery(
    gql`
      ${SHIPMENT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !id,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        input: {
          id,
        },
      },
      ...(token && {
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            ...(lang && { "Content-Language": lang }),
          },
        },
      }),
      onCompleted: () => {
        document.fonts.onloadingdone = function (fontFaceSetEvent) {
          window.print();
          document.fonts.onloadingdone = null;
        };
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  let parsedData = [];
  if (data?.list0) {
    let concatData = [];
    for (const key in data) {
      if (key.startsWith("list")) {
        const listData = data[key].data;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }
  if (data?.listCode) {
    let concatData = [];
    for (const key in data) {
      if (key.startsWith("list")) {
        const listData = data[key].barcodes;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }
  const validData = parsedData.length > 0;

  return (
    <Fragment>
      <link
        rel="stylesheet"
        type="text/css"
        href={process.env.PUBLIC_URL + "/print-sticky2X5.css"}
      />
      {loading || (!loading && !validData) ? (
        <>
          <EmptyTableMessage
            loading={loading}
            message={t("noShipmentWithCode")}
          />

          {/* <FullScreenLoading minHeight="25%" /> */}
        </>
      ) : (
        parsedData.map((shipment, index) => (
          <Root key={index}>
            <div
              className={clsx({
                [classes.printSticky]: !loading && validData,
              })}
              style={{ height: "100%", lineHeight: "1.4" }}
            >
              <div
                className={clsx(
                  classes.barcode,
                  classes.centerContent,
                  classes.stickyCode
                )}
              >
                *{shipment.code}*
              </div>
              {/* <div className={classes.stickyRecipientName}>
                - {shipment?.recipientName}
              </div>
              <div className={classes.stickyDestination}>
                -{!isBatch && " " + 
                shipment?.recipientZone?.name +
                  " - " +
                  shipment?.recipientSubzone?.name }
              </div>
              <div
                className={clsx(
                  classes.stickyRecipientAddress,
                  "recipient-address"
                )}
              >
                - {shipment.recipientAddress}
              </div> */}
            </div>
          </Root>
        ))
      )}
    </Fragment>
  );
};

export default withRouter(ShipmentStickyPrint2X5);
