import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";

import { withRouter } from "react-router";
import { Globals } from "../HOC/Classes/Globals";
import PaymentFormBody from "./PaymentFormBody";
import { PAYMENT_VIEW } from "./PaymentListQuary";




/////////////////DIALOG///////////////
const PaymentForm = (props) => {
  const formType = props.match.params.type?.toUpperCase();

  const [paymentType, setPaymentType] = useState(formType ? formType : null);
  const [paymentData, setPaymentData] = useState();
  const [watchId, setWatchId] = useState();
  const paymentId = props.match.params.id;
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const user = Globals.user;
  const journalEntryPermission = user.hasPermission(
    "accounting.journal_entry.list"
  );
  const ALLOWED_CONCIERGE =  Globals.settings.allowedCustomerTypes.includes("CONCIERGE")
  const ALLOWED_PICKUP = (Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") || Globals.settings.allowedCustomerTypes.includes("MERCHANT"))
  const ALLOWED_SHIPMENT = Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") || Globals.settings.allowedCustomerTypes.includes("MERCHANT")
  const paymentQueryBody = PAYMENT_VIEW(journalEntryPermission, ALLOWED_CONCIERGE, ALLOWED_SHIPMENT, ALLOWED_PICKUP);
  const { data: paymentByIdQuery, loading: paymentByIdLoad, refetch: paymentRefetch } = useQuery(
    gql`
      ${paymentQueryBody.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !paymentId,
      variables: {
        id: paymentId ?parseInt(paymentId):watchId ,
      },
      onCompleted: (data) => {
        if (data?.payment === null) return;
        const payment = data?.payment;
        setPaymentType(payment?.type?.code);
        setPaymentData(payment);
      },
    }
  );

 

 


  return (
    paymentType&&<PaymentFormBody
      {...props}
      setWatchId={setWatchId}
      paymentRefetch={paymentRefetch}
      paymentByIdLoad={paymentByIdLoad}
      paymentByIdQuery={paymentByIdQuery}
      paymentData={paymentData}
      paymentId={paymentId}
      paymentType={paymentType}
      setPaymentData={setPaymentData}

    />
  )
};

export default withRouter(PaymentForm);



