import { createContext, useContext } from "react";

export const ManifestShipmentsContext = createContext({});

const ManifestShipmentsProvider = (props) => {
  const { value } = props;
  return (
    <ManifestShipmentsContext.Provider value={value}>
      {props.children}
    </ManifestShipmentsContext.Provider>
  );
};

export default ManifestShipmentsProvider;

export const useManifestShipmentsContext = () => {
  const manifestShipmentsContext = useContext(ManifestShipmentsContext);

  return {
    manifestShipmentsContext,
  };
};
