import { gql, useQuery } from "@apollo/client";
import { Search } from "@mui/icons-material";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as gqlb from "gql-query-builder";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LIST_DELIVERY_AGENTS_DROPDOWN } from "../../../GlobalsQuery/ListDropdown/ListDropdown";
import { setValidationError } from "../../HOC/CustomFunctions/setValidationError";
import { CustomAutocomplete } from "../../HOC/MUI/CustomAutocomplete";
import MUIDateTime from "../../HOC/MUI/MUIDateTime";
import DeliveryAgentMap from "./DeliveryAgentMap";

const LIST_DELIVERY_AGENT_POSITIONS = gqlb.query({
  operation: "listDeliveryAgentPositions",
  fields: [
    "id",
    "latitude",
    "longitude",
    "createdAt",
    { deliveryAgent: ["id", "name"] },
    {
      transaction: [
        {
          operation: "...on ShipmentTransaction",
          fields: [
            "id",
            "code",
            { shipment: ["id", "code"] },
            { shipmentStatus: ["name"] },
          ],
          variables: {},
        },
        {
          operation: "...on ConciergeTransaction",
          fields: ["id", { request: ["id", "code", { type: ["code"] }] }, { status: ["name"] }],
          variables: {},
        },
      ],
    },
  ],
  variables: {
    input: {
      type: "ListDeliveryAgentPositionFilterInput",
    },
  },
});

const dateFormat = (date) =>
  moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

const TrackDeliveryAgent = (props) => {
  const { t } = useTranslation(["translation", "validation"]);

  const initFromDate = moment(new Date())
    .subtract(1, "day")
    .set({ hour: 24, minute: 0, second: 0 })
    .locale("en");

  const initToDate = moment(new Date())
    .set({ hour: 23, minute: 59, second: 59 })
    .locale("en");

  const [fieldsState, setFieldsState] = useState({
    fromDate: initFromDate,
    toDate: initToDate,
  });
  const [queryVariables, setQueryVariables] = useState();
  const [positions, setPositions] = useState();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    clearErrors,
    setError,
  } = useForm();
  const { loading } = useQuery(
    gql`
      ${LIST_DELIVERY_AGENT_POSITIONS.query}
    `,
    {
      skip: !queryVariables,
      variables: { input: queryVariables },
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const listDeliveryAgentPositions = data.listDeliveryAgentPositions;
        listDeliveryAgentPositions.length > 0
          ? setPositions(listDeliveryAgentPositions)
          : setPositions(null);
      },
      onError: ({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      },
    }
  );

  const onSubmit = (data) => {
    let handledData = {
      fromDate: dateFormat(data.fromDate),
      toDate: dateFormat(data.toDate),
      deliveryAgentId: data.deliveryAgentId,
    };
    setQueryVariables(handledData);
  };

  return (
    <Fragment>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        alignItems="flex-start"
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
        sx={{ margin: 0, width: "100%" }}
        spacing={2}
      >
        <Grid sm={3} alignItems="flex-start">
          <MUIDateTime
            name="fromDate"
            label={t("fromDate")}
            control={control}
            value={fieldsState.fromDate}
            onChange={(e) => {
              clearErrors(["toDate", "fromDate"]);
              setFieldsState((prev) => ({ ...prev, fromDate: e }));
            }}
          />
        </Grid>
        <Grid sm={3} alignItems="flex-start">
          <MUIDateTime
            name="toDate"
            label={t("toDate")}
            control={control}
            value={fieldsState.toDate}
            rules={{
              validate: {
                max: (value) => {
                  return value < new Date(watch("fromDate"))
                    ? t("validation:mt", {
                      field1: t("toDate"),
                      field2: t("fromDate"),
                    })
                    : true;
                },
              },
            }}
            onChange={(e) => {
              clearErrors(["toDate", "fromDate"]);
              setFieldsState((prev) => ({ ...prev, toDate: e }));
            }}
          />
        </Grid>

        <Grid sm={3} alignItems="flex-start">
          <CustomAutocomplete
            name={"deliveryAgentId"}
            label={t("shippingAgent")}
            control={control}
            errors={errors}
            parseData={(data) => data}
            query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
            rules={{ required: t("fieldIsRequired") }}
            variables={{
              input: {
                active: true,
              },
            }}
          />
        </Grid>
        <Grid
          sm={3}
          alignItems="center"
          alignContent="flex-end"
          justifyContent="flex-end"
        >
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            startIcon={<Search />}
            disabled={loading}
          >
            {t("search")}
          </Button>
        </Grid>
      </Grid>
      <DeliveryAgentMap positions={positions} />
    </Fragment>
  );
};

export default TrackDeliveryAgent;
