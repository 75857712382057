export const printLink = (code, batch, shipmentsId, afterSaveFun, operation) => {
    const basePrintUrl = `${window.location.origin}/waybill/${batch ? 'batch' : 'shipment'}`;
    const shipmentsIdStr = shipmentsId.toString();

    const printUrls = {
        'SHEET_SAME_1': `${basePrintUrl}/print/${shipmentsIdStr}/1c${operation ? `/${operation}` : ''}`,
        'copy': `${basePrintUrl}/print/${shipmentsIdStr}/2d${operation ? `/${operation}` : ''}`,
        'SHEET_SAME_2': `${basePrintUrl}/print/${shipmentsIdStr}/2c${operation ? `/${operation}` : ''}`,
        'SHEET_SAME_3': `${basePrintUrl}/print/${shipmentsIdStr}/3c${operation ? `/${operation}` : ''}`,
        'SHEET_DIFF_2': `${basePrintUrl}/print/${shipmentsIdStr}/2d${operation ? `/${operation}` : ''}`,
        'SHEET_DIFF_3': `${basePrintUrl}/print/${shipmentsIdStr}/3d${operation ? `/${operation}` : ''}`,
    };

    // Handle the sticker cases dynamically
    if (code.includes('X')) {
        window.open(`${basePrintUrl}/sticky-print/${code}/${shipmentsId}${operation ? `/${operation}` : ''}`);
    } else if (printUrls[code]) {
        window.open(printUrls[code])
    } else {
        return
    }

    if (typeof afterSaveFun === 'function') {
        afterSaveFun();
    }
};
