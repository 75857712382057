import {
  Button,
  TableBody,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

import { gql, useQuery } from "@apollo/client";
import { Add, FilterList, Search } from "@mui/icons-material";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { Can } from "../HOC/CustomComponents/Secured";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import { pushUrl, windowUrl } from "../HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUIDateRangeCustom from "../HOC/MUI/MUIDateRangeCustom";
import MUItextField from "../HOC/MUI/MUItextField";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { LIST_PICKUPS } from "./Graphql";
import { PickupStatusColor } from "./PickupStatusColor";
import { dateFormatLL } from "../../helpers/dateFunctions";
import Grid from '@mui/material/Unstable_Grid2';
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import config from "../../config.json";

const PREFIX = "ListWarehousePickups";

const classes = {
  button: `${PREFIX}-button`,
  table_status: `${PREFIX}-table_status`,
  table_code: `${PREFIX}-table_code`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
  headerTable: `${PREFIX}-headerTable`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  //////////////////////Drawer///////////////////

  [`& .${classes.table_status}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },

  [`& .${classes.table_code}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      left: "0",
    },
  },

  [`& .${classes.tableBodyRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: theme.palette.background.hover,
      },
    },
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },
  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },

}));

const ListWarehousePickups = (props) => {
  const { t } = useTranslation();
  const urlQuery = urlParameters(window.location.search);

  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : moment(new Date())
      .locale("en")
      .subtract(1, "month")
      .add(1, "day")
      .format("YYYY-MM-DD");
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : moment(new Date()).locale("en").format("YYYY-MM-DD");
  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);

  const [autocompleteValues, setAutocompleteValues] = useState({
    customer: null,
    deliveryAgent: null,
    branch: null,
  });
  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    register,
  } = useForm();
  const [filterLoad, setFilterLoad] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const initSearch = () => {
    let searchParameters = {
      page: 0,
      refetch: true,
      fromDate: dateRange[0],
      toDate: dateRange[1],
    };

    delete urlQuery["rowsPerPage"];
    if (validUrlParameters) {
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"]?.toString();
      searchParameters = urlQuery;
    }
    return {
      ...searchParameters,
    };
  };
  const [search, setSearch] = useState(initSearch());

  useEffect(() => {
    urlQuery["code"] && setValue("code", urlQuery["code"].toString());

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const pushUrlSearch = (param) => {
    const params = searchParams(param);

    const url = history.createHref({
      pathname: "/admin/warehouse-pickups",
      search: "?" + params,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmit = (data) => {
    setFilterLoad(false)
    filterAnchor === "bottom" &&
      setDrawerState((prev) => ({ ...prev, [filterAnchor]: false }));

    let handledData = {
      fromDate: moment(dateRange[0]).locale("en").format("YYYY-MM-DD"),
      toDate: moment(dateRange[1]).locale("en").format("YYYY-MM-DD"),
      branchId: data.branchId,
      statusCode: data.statusCode,
      customerId: data.customerId,
      deliveryAgentId: data.deliveryAgentId,
      page: 0,
    };
    for (const key in handledData) {
      if (
        handledData[key] === undefined ||
        handledData[key] === "" ||
        handledData[key] === null
      ) {
        delete handledData[key];
      }
    }
    if (data.code) {
      handledData = {
        code: data.code.toString(),
        page: 0,
      };
    }
    setSearch((prev) => ({
      ...handledData,
      refetch: !prev.refetch,
    }));
    for (const key in handledData) {
      if (handledData[key] === null) {
        delete handledData[key];
      }
    }
    pushUrlSearch({
      ...handledData,
      rowsPerPage: rowsPerPage,
    });
  };
  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };
  const parseData = (data) => {
    return data;
  };

  const lookupComplete = (data, name) => {
    const validData = lookupCodeIsValid(data, urlQuery[name]);
    if (validData) {
      setValue(name, urlQuery[name]);
    }
  };

  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,

      page: newPage,
      rowsPerPage: rowsPerPage,
    });

    setSearch((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    setSearch((prev) => ({ ...prev, page: 0 }));
  };
  const { data, loading } = useQuery(
    gql`
      ${LIST_PICKUPS.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: search?.page + 1,
        input: {
          ...(search.fromDate && { fromDate: search.fromDate }),
          ...(search.toDate && { toDate: search.toDate }),
          ...(search.statusCode && { statusCode: search.statusCode }),
          ...(search.customerId && { customerId: search.customerId }),
          ...(search.branchId && { branchId: search.branchId }),
          ...(search.code && { code: search.code }),
          ...(search.deliveryAgentId !== "" && {
            deliveryAgentId: search.deliveryAgentId,
          }),
        },
      },
      onCompleted: (data) => { },
    }
  );
  const listWarehousePickups = data?.listWarehousePickups?.data;
  const codeValidation = Boolean(watch("code"));

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: () => pushUrl(props, "/admin/warehouse-pickups/create"),
      icon: Add,
      permission: "shipping.warehouse_pickup.create",
    },
  ]

  ////////////////////totalShipmentsCount/////////////////
  return (
    <Root>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={data?.listWarehousePickups?.data.length === 0}
        loading={loading}
        filters={
          <Grid
            container
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            className={classes.filterForm}
          >
            <Grid
              container
              item
              sm={12}
              justifyContent="flex-start"
              spacing={1}
              className={classes.filterField}
            >
              <Grid item sm={12} alignItems="flex-start">
                <MUItextField
                  label={t("code")}
                  name={"code"}
                  inputProps={{
                    dir: "ltr",
                  }}
                  formType={"requireFalse"}
                  register={register}
                  errors={errors}
                  watch={watch("code")}
                />
              </Grid>
              <Grid item sm={12} alignItems="flex-start">
                <MUIDateRangeCustom
                  value={dateRange}
                  onChangeValue={(value) => setDateRange(value)}
                  disabled={codeValidation}
                />
              </Grid>
              <Can showException permission="shipping.warehouse_pickup.filter_by_branch">
                <Grid item sm={12} alignItems="flex-start">
                  <CustomAutocomplete
                    control={control}
                    errors={errors}
                    skip={loading && filterLoad}
                    name={"branchId"}
                    label={t("branch")}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["branchId"],
                        "branch"
                      )
                    }
                    parseData={(data) => parseData(data)}
                    query={LIST_BRANCHES_DROPDOWN.query}
                    defaultValue={autocompleteValues.branch}
                    disabled={codeValidation}
                  />
                </Grid>
              </Can>
              <Can
                showException
                permission="shipping.warehouse_pickup.filter_by_delivery_agent"
              >
                <Grid item sm={12} alignItems="flex-start">
                  <CustomAutocomplete
                    name={"deliveryAgentId"}
                    label={t("shippingAgent")}
                    control={control}
                    errors={errors}
                    skip={loading && filterLoad}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["deliveryAgentId"],
                        "deliveryAgent"
                      )
                    }
                    parseData={(data) => parseData(data)}
                    query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                    variables={{
                      input: {
                        active: true,
                      },
                    }}
                    defaultValue={autocompleteValues.deliveryAgent}
                    disabled={codeValidation}
                  />
                </Grid>
              </Can>
              <Can showException permission="shipping.warehouse_pickup.filter_by_customer">
                <Grid item sm={12} alignItems="flex-start">
                  <CustomAutocomplete
                    name={"customerId"}
                    label={t("customer")}
                    control={control}
                    errors={errors}
                    skip={loading && filterLoad}
                    onCompleted={(data) =>
                      handelAutocompleteDefaultValue(
                        data,
                        urlQuery["customerId"],
                        "customer"
                      )
                    }
                    parseData={(data) => parseData(data)}
                    query={LIST_CUSTOMERS_DROPDOWN.query}
                    variables={{
                      input: {
                        typeCode: ["MERCHANT"],
                      },
                    }}
                    defaultValue={autocompleteValues.customer}
                    disabled={codeValidation}
                  />
                </Grid>
              </Can>

              <Grid item sm={12} alignItems="flex-start">
                <LookupDropdown
                  name={"statusCode"}
                  label={t("shipmentStatus")}
                  control={control}
                  errors={errors}
                  skip={loading && filterLoad}
                  variables={{ input: { code: "SHP_PICKUP_STATUS" } }}
                  onCompleted={(data) => lookupComplete(data, "statusCode")}
                  disabled={codeValidation}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              className={classes.button}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                startIcon={<Search />}
              >
                {t("search")}
              </Button>
            </Grid>
          </Grid>
        }
        tableHeaders={
          [{ name: "requestCode", className: "FH" },
          { name: "date" },
          { name: "branch" },
          { name: "shippingAgent" },
          { name: "transactionTypeName" },
          { name: "customer" },
          { name: "productsNumber" },
          { name: "notes" },
          { name: "deliveryAgentNotes" },
          { name: "status", className: "LH" },

          ]
        }
        tableBody={
          <TableBody>
            {listWarehousePickups &&
              listWarehousePickups?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    className={classes.tableBodyRow}
                  >
                    <CellLink
                      pathname={`/admin/warehouse-pickups/${row?.id}`}
                      className={classes.table_code}
                    >
                      {row?.code}
                    </CellLink>
                    <FixedTableCell>
                      {dateFormatLL(row?.date)}
                    </FixedTableCell>
                    {row?.branch ? (
                      <CellLink
                        pathname={`/admin/branches/${row?.branch?.id}`}
                      >
                        {row?.branch?.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell> </FixedTableCell>
                    )}
                    {row?.deliveryAgent ? (
                      <CellLink
                        pathname={`/admin/delivery-agents/${row?.deliveryAgent?.id}`}
                      >
                        {row?.deliveryAgent?.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell> </FixedTableCell>
                    )}
                    {row?.transactionType ? (
                      <CellLink
                        pathname={`/admin/transaction-types/${row?.transactionType?.id}`}
                      >
                        {row?.transactionType?.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell> </FixedTableCell>
                    )}
                    {row?.customer ? (
                      <CellLink
                        pathname={`/admin/customers/${row?.customer?.id}`}
                      >
                        {row?.customer?.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell> </FixedTableCell>
                    )}
                    <FixedTableCell>{row?.productsCount}</FixedTableCell>
                    <FixedTableCell>{row?.notes}</FixedTableCell>
                    <FixedTableCell>
                      {row?.deliveryAgentNotes}
                    </FixedTableCell>
                    <PickupStatusColor
                      code={row.status?.code}
                      className={classes.table_status}
                    >
                      {row.status?.name}
                    </PickupStatusColor>
                  </TableRow>
                );
              })}
            {/* <TableRow>
            <FixedTableCell colSpan={5}> </FixedTableCell>
            <FixedTableCell variant="head">{t("total")}</FixedTableCell>
            <FixedTableCell variant="head">
              {sumPickups?.shipmentsCount}
            </FixedTableCell>
            <FixedTableCell variant="head">
              {sumPickups?.pickedCount}
            </FixedTableCell>
            <FixedTableCell colSpan={2}> </FixedTableCell>
            <FixedTableCell className={classes.table_status}>
              {" "}
            </FixedTableCell>
          </TableRow> */}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listWarehousePickups?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listWarehousePickups ? 0 : search?.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />

    </Root>
  );
};

export default ListWarehousePickups;
