import React, { Fragment, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import EmptyTableMessage from '../../HOC/FunctionComponents/EmptyTableMessage';

export const batchFields = [
    "id",
    { barcodes: ["id", "code"] }
];

export const shipmentFields = [
    {
        data: [
            "code",
            "recipientName",
            "recipientAddress",
            {
                recipientZone: ["name"],
            },
            {
                recipientSubzone: ["name"],
            },
        ],
    },
];

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
    printSticky: `${PREFIX}-printSticky`,
    stickyCode: `${PREFIX}-stickyCode`,
    stickyRecipientName: `${PREFIX}-stickyRecipientName`,
    stickyDestination: `${PREFIX}-stickyDestination`,
    stickyRecipientAddress: `${PREFIX}-stickyRecipientAddress`,
    centerContent: `${PREFIX}-centerContent`,
    barcode: `${PREFIX}-barcode`,
};
export const Root = styled("div")(({ theme }) => ({
    [`& .${classes.printSticky}`]: {
        pageBreakAfter: "always",
        pageBreakInside: "avoid",
        maxWidth: "5cm",
        maxHeight: "2cm",
        overflow: "hidden",
        fontSize: 11,
        color: "black",
    },

    [`& .${classes.stickyCode}`]: {
        height: "100%",
        overflow: "hidden",
        fontSize: "30px !important",
    },

    [`& .${classes.stickyRecipientName}`]: {
        height: "calc(100% * (0.75/4))",
        overflow: "hidden",
        whiteSpace: "pre",
    },

    [`& .${classes.stickyDestination}`]: {
        height: "calc(100% * (0.75/4))",
        overflow: "hidden",
        whiteSpace: "pre",
    },

    [`& .${classes.stickyRecipientAddress}`]: {
        height: "calc(100% * (1.5/4))",
        overflow: "hidden",
    },
    [`& .${classes.centerContent}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    [`& .${classes.barcode}`]: {
        fontFamily: "'Libre Barcode 39 Text'",
        fontSize: 48,
    },
}));

function createDynamicStyles() {
    const style = document.createElement('style');
    style.type = 'text/css';

    const styles = `
    @page {
        size: 5cm 2cm; 
        margin: 1mm !important;
        overflow: hidden;
    }
    * {
            color: black;
    }
    body {
            background: #fff !important
    }
    `;

    if (style.styleSheet) {
        style.styleSheet.cssText = styles;
    } else {
        style.appendChild(document.createTextNode(styles));
    }

    document.head.appendChild(style);
}

const Template5X2 = (props) => {
    const {
        loading,
        validData,
        parsedData,
    } = props

    const { t } = useTranslation()

    useEffect(() => {
        createDynamicStyles()

    }, [])

    return (
        <Fragment>
            {loading || (!loading && !validData) ? (
                <>
                    <EmptyTableMessage
                        loading={loading}
                        message={t("noShipmentWithCode")}
                    />
                </>
            ) : (
                parsedData.map((shipment, index) => (
                    <Root key={index}>
                        <div
                            className={clsx({
                                [classes.printSticky]: !loading && validData,
                            })}
                            style={{ height: "100%", lineHeight: "1.4" }}
                        >
                            <div
                                className={clsx(
                                    classes.barcode,
                                    classes.centerContent,
                                    classes.stickyCode
                                )}
                            >
                                *{shipment.code}*
                            </div>
                        </div>
                    </Root>
                ))
            )}
        </Fragment>
    )
}

export default Template5X2