import { Box, Grid, Paper, styled, TableRow, useTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { TicketStatusColor } from "./TicketStatusColor";

const PREFIX = "HistoryTable";

const classes = {
    table: `${PREFIX}-table`,
    shipmentTable_code: `shipmentTable_code`,
    shipmentTable_bodyCode: `shipmentTable_bodyCode`,
    tableBodyRow: `${PREFIX}-tableBodyRow`,
    tablePaper: `${PREFIX}-tablePaper`,
    tableHead: `${PREFIX}-tableHead`,
    mainTableClass: `${PREFIX}-mainTableClass`,
    shipmentTable_updatedBy: `${PREFIX}-shipmentTable_updatedBy`,
    shipmentTable_bodyUpdatedBy: `${PREFIX}-shipmentTable_bodyUpdatedBy`,
    shipmentTable_bodyUpdatedAt: `${PREFIX}-shipmentTable_bodyUpdatedAt`,
    shipmentTable_updatedAt: `${PREFIX}-shipmentTable_updatedAt`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`& .${classes.table}`]: {
        display: "grid",
        minHeight: "250px",
    },

    [`& .${classes.shipmentTable_updatedBy}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "101",
            right: "0",
        },
    },
    [`& .${classes.shipmentTable_updatedAt}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "101",
            left: "0",
        },
    },

    [`& .${classes.shipmentTable_bodyUpdatedBy}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: theme.palette.background.paper,
            position: "sticky",
            top: "0",
            right: "0",
        },
    },
    [`& .${classes.shipmentTable_bodyUpdatedAt}`]: {
        [theme.breakpoints.up("sm")]: {
            zIndex: "100",
            backgroundColor: theme.palette.background.paper,
            position: "sticky",
            top: "0",
            left: "0",
        },
    },

    [`&.${classes.tablePaper}`]: {
        position: "relative",
        width: "100%",
        marginTop: theme.spacing(2),
    },

    [`& .${classes.tableHead}`]: {
        "& .MuiTableCell-head": { fontWeight: 600 },
    },

    [`& .${classes.mainTableClass}`]: {
        "& td": {
            maxWidth: 250,
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    },
}));

const TicketHistory = (props) => {
    const { tickets } = props;

    const shipmentsLength = tickets.length;
    const { t } = useTranslation();
    const theme = useTheme()
    const tableHeadCell = [
        "updatedAt",
        "code",
        "title",
        "description",
        "customer",
        "category",
        "assignee",
        "status",
        "createdBy",
        "updatedBy",
    ];

    const compareValues = (val_1, val_2, index) => {
        if (val_1 === val_2 || index === tickets.length - 1) {
            return {};
        } else {
            return { backgroundColor: theme.palette.mode === "dark" ? "#651e1c !important" : red[50] + "!important" };
        }
    };

    const tableBody = (ticket, index) => {
        let prevShipment = tickets[index + 1];
        const updatedBy =
            index === tickets.length - 1 ? ticket.createdBy : ticket.updatedBy;
        const createdBy =
            index === tickets.length - 1 ? ticket.createdBy : ticket.createdBy;
        const updatedAt =
            index === tickets.length - 1 ? ticket.createdAt : ticket.updatedAt;
        return (
            <TableRow key={index} className={classes.tableBodyRow}>
                <FixedTableCell
                    className={classes.shipmentTable_bodyUpdatedAt}
                    sx={compareValues(updatedAt, prevShipment?.updatedAt, index)}
                >
                    {updatedAt}
                </FixedTableCell>
                <FixedTableCell
                    sx={compareValues(ticket.code, prevShipment?.code, index)}
                >
                    {ticket.code}
                </FixedTableCell>

                <FixedTableCell
                    sx={compareValues(
                        ticket.title,
                        prevShipment?.title,
                        index
                    )}
                >
                    {ticket.title}
                </FixedTableCell>

                <FixedTableCell
                    sx={compareValues(
                        ticket.body,
                        prevShipment?.body,
                        index
                    )}
                >
                    {ticket.body}
                </FixedTableCell>

                <CellLink
                    sx={compareValues(
                        ticket.customer.id,
                        prevShipment?.customer?.id,
                        index
                    )}
                    pathname={`/admin/customers/${ticket.customer.id}`}
                >
                    <Box
                        component="span"
                        fontWeight="bold"
                    >{` (${ticket.customer.code}) `}</Box>
                    <Box component="span">{ticket.customer.name}</Box>
                </CellLink>


                <CellLink
                    sx={compareValues(
                        ticket.category.id,
                        prevShipment?.category?.id,
                        index
                    )}
                    pathname={`/admin/crm/categories`}
                    search={`code=${ticket.category.id}`}
                >
                    <Box
                        component="span"
                        fontWeight="bold"
                    >{` (${ticket.category.code}) `}</Box>
                    <Box component="span">{ticket.category.name}</Box>
                </CellLink>
                <CellLink
                    sx={compareValues(
                        ticket?.assignee?.id,
                        prevShipment?.assignee?.id,
                        index
                    )}
                    pathname={`/admin/users/${ticket?.assignee?.id}`}
                >

                    {ticket?.assignee?.username}
                </CellLink>

                <TicketStatusColor code={ticket.status.code}>
                    {ticket.status.name}
                </TicketStatusColor>

                <CellLink
                    className={classes.shipmentTable_bodyUpdatedBy}
                    sx={compareValues(createdBy?.id, prevShipment?.createdBy?.id, index)}
                    pathname={`/admin/users/${createdBy?.id}`}
                >
                    {createdBy?.username}
                </CellLink>

                <CellLink
                    className={classes.shipmentTable_bodyUpdatedBy}
                    pathname={`/admin/users/${updatedBy?.id}`}
                >
                    {updatedBy?.username}
                </CellLink>
            </TableRow>
        );
    };

    return (
        <StyledPaper className={classes.tablePaper}>
            <Grid container item alignItems="flex-start" className={classes.table}>
                {shipmentsLength === 0 ? (
                    <EmptyTableMessage loading={false} message={t("noReplies")} />
                ) : (
                    <TableFixedHeaderWraper>
                        <ShipmentsTable
                            stickyHeader
                            className={classes.mainTableClass}
                            classes={classes}
                            data={tickets ?? []}
                            headCells={tableHeadCell}
                            parseBodyCell={tableBody}
                            sumRow={false}
                        />
                    </TableFixedHeaderWraper>
                )}
            </Grid>
        </StyledPaper>
    );
};

export default TicketHistory;
