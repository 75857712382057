import * as gqlb from "gql-query-builder";

// const manifestSumFiled = {
//   bmt: [
//     "shipmentsCount"
//   ],
//   bmr: [
//     "shipmentsCount"
//   ],
//   pkm: [
//     "shipmentsCount"
//   ],
//   prp: [
//     "shipmentsCount"
//   ],
//   rits: [
//     "shipmentsCount"
//   ],
//   std:
//     [
//       "shipmentsCount"
//     ],
//   otd:
//     [
//       "shipmentsCount"
//     ],
//   htr:
//     [
//       "shipmentsCount"
//     ],
//   rts:
//     [
//       "shipmentsCount"
//     ],
//   rcv:
//     [
//       "shipmentsCount"
//     ],
//   otr:
//     [
//       "shipmentsCount"
//     ],
//   rtrn:
//     [
//       "shipmentsCount",
//       "returnedValue"
//     ],


// };


const manifestListFiled = {
  bmt: [
    "code",
    "id",
    "date",
    "approved",
    // "shipmentsCount",
    {
      branch: ["id", "name"],
    },
    {
      referenceBranch: ["id", "name"],
    },
    {
      transactionType: ["id", "name"],
    },
    {
      deliveryAgent: ["name", "id"],
    },
  ],
  bmr: [
    "code",
    "id",
    "date",
    "approved",
    // "shipmentsCount",
    {
      branch: ["id", "name"],
    },
    {
      referenceBranch: ["id", "name"],
    },
    {
      transactionType: ["id", "name"],
    },
    {
      deliveryAgent: ["name", "id"],
    },
  ],
  pkm: [
    "code",
    "id",
    "date",
    "approved",
    // "shipmentsCount",
    {
      branch: ["id", "name"],
    },
    {
      transactionType: ["id", "name"],
    },
    {
      deliveryAgent: ["name", "id"],
    },
    {
      customer: ["name", "id"],
    },
  ],
  prp: [
    "code",
    "id",
    "date",
    "approved",
    // "shipmentsCount",
    {
      branch: ["id", "name"],
    },
    {
      transactionType: ["id", "name"],
    },
  ],
  rits: [
    "code",
    "id",
    "date",
    "approved",
    // "shipmentsCount",
    {
      branch: ["id", "name"],
    },
    {
      deliveryAgent: ["name", "id"],
    },
    {
      transactionType: ["id", "name"],
    },
  ],
  std:
    [
      "code",
      "id",
      "date",
      "approved",
      "shipmentsCount",
      {
        deliveryAgent: ["name", "id"],
      },
      {
        branch: ["id", "name"],
      },
      {
        transactionType: ["id", "name"],
      },
    ],
  otd:
    [
      "code",
      "id",
      "date",
      "approved",
      "shipmentsCount",
      {
        deliveryAgent: ["name", "id"],
      },
      {
        branch: ["id", "name"],
      },
      {
        transactionType: ["id", "name"],
      },
    ],
  htr:
    [
      "code",
      "id",
      "date",
      "approved",
      "shipmentsCount",
      {
        deliveryAgent: ["name", "id"],
      },
      {
        branch: ["id", "name"],
      },
      {
        transactionType: ["id", "name"],
      },
    ],
  rts:
    [
      "code",
      "id",
      "date",
      "approved",
      "shipmentsCount",
      {
        deliveryAgent: ["name", "id"],
      },
      {
        branch: ["id", "name"],
      },
      {
        transactionType: ["id", "name"],
      },
    ],
  rcv:
    [
      "code",
      "id",
      "date",
      "approved",
      "shipmentsCount",

      {
        deliveryAgent: ["name", "id"],
      },
      {
        branch: ["id", "name"],
      },
      {
        transactionType: ["id", "name"],
      },
    ],
  otr:
    [
      "code",
      "id",
      "date",
      "approved",
      "shipmentsCount",
      {
        deliveryAgent: ["name", "id"],
      },
      {
        branch: ["id", "name"],
      },
      {
        transactionType: ["id", "name"],
      },
      {
        customer: ["name", "id"],
      },
    ],
  rtrn:
    [
      "code",
      "id",
      "date",
      "approved",
      // "shipmentsCount",
      "returnedValue",
      {
        deliveryAgent: ["name", "id"],
      },
      {
        branch: ["id", "name"],
      },
      {
        transactionType: ["id", "name"],
      },
      {
        customer: ["name", "id"],
      },
    ],


};
export const LISTMANIFESTS_QUERY = (type) => {
  return gqlb.query({
    operation: "listManifests",
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
          "count",
          "currentPage",
          "total",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage",
        ],
        variables: {},
      },
      {
        operation: "data",
        fields: [
          {
            operation: `entries`,
            fields: [
              {
                paginatorInfo: ["total"]
              },
            ],
            variables: {},
          },
          ...manifestListFiled[type]
        ],
        variables: {},
      },
    ],
    variables: {
      input: {
        type: "ListManifestsFilterInput",
        required: true,
      },
      first: {
        type: "Int",
        required: true,
        value: 15,
      },
      page: {
        type: "Int",
        value: 1,
      },
    },
  })
};

export const manifestFields = [
  "id",
  "date",
  "code",
  "approved",
  "sacksCount",
  "notes",
  {
    referenceBranch: ["id", "name"],
  },
  {
    referenceManifest: ["id", "code"],
  },
  {
    referredManifests: ["id", "code"],
  },
  {
    branch: ["id", "name"],
  },
  {
    updatedBy: ["id", "username"],
  },
  {
    createdBy: ["id", "username"],
  },
  {
    transactionType: [
      "id",
      "code",
      "name",
      {
        type: ["code", "name"],
      },
    ],
  },
  {
    deliveryAgent: ["id", "name", "code"],
  },
  {
    route: ["id", "name"],
  },
  {
    customer: [
      "id",
      "name",
      "phone",
      "address",
      "code",
      {
        zone: ["name"],
      },
      {
        subzone: ["name"],
      },
      {
        operation: `isMobileHidden(code: MANIFEST)`,
        fields: [],
        variables: {
        },
      },
    ],
  },
];
export const MANIFEST_BY_ID_QUERY = gqlb.query({
  operation: "manifest",
  fields: manifestFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const MANIFEST_BY_ID_AND_PRINT = gqlb.query([
  {
    operation: "manifest",
    fields: manifestFields,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "organization",
    fields: [
      "name",
      "phone",
      "email",
      "website",
      "reportHeader",
      "reportFooter",
    ],
    variables: {},
  },
]);

export const SAVE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "saveManifest",
  fields: ["id", "code"],
  variables: {
    input: {
      type: "ManifestInput",
      required: true,
    },
  },
});
export const DELETE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "deleteManifest",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const APPROVE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "approveManifest",
  fields: manifestFields,
  variables: {
    input: {
      type: "ApproveManifestInput",
      required: true,
    },
  },
});

export const COMPLETE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "completeManifest",
  fields: manifestFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const DELIVERY_AGENT_ID = gqlb.query({
  operation: "deliveryAgent",
  fields: [
    {
      route: ["name", "id"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
