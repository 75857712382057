/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import { DeleteOutline, Edit, LockOpenOutlined } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import {
  DELETE_JOURNAL_ENTRY,
  DISAPPROVE_JOURNAL_ENTRY_MUTATION,
  JOURNAL_ENTRY,
} from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import RecordsTable from "./RecordsTable";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { useState } from "react";
import LongMenu from "../../Layout/MenuAppBar";
// import { dateFormat } from "../../helpers/dateFunctions";


const PREFIX = 'JournalEntryView';

const classes = {
  box: `${PREFIX}-box`,
  main: `${PREFIX}-main`,
  iconColor: `${PREFIX}-iconColor`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({


  [`& .${classes.box}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },


  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  }
}));
const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const JournalEntryView = (props) => {

  const { t } = useTranslation();
  const journalEntryId = props.match.params.id?.trim();

  const { loading, data, refetch } = useQuery(
    gql`
      ${JOURNAL_ENTRY.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(journalEntryId) },
    }
  );

  const user = Globals.user;
  const branchId = data?.journalEntry?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canDisapprove =
    !loading &&
    data?.journalEntry?.approved &&
    data?.journalEntry?.sourceType?.code === "MANUAL" &&
    canAccessBranch &&
    user.hasPermission("accounting.journal_entry.disapprove");

  const canDelete =
    !loading &&
    user.hasPermission("accounting.journal_entry.delete") &&
    canAccessBranch &&
    !data?.journalEntry?.approved;

  const canEdit =
    !loading &&
    user.hasPermission("accounting.journal_entry.update") &&
    canAccessBranch &&
    !data?.journalEntry?.approved;

  const [openDelete, setOpenDelete] = useState(false);
  const [openDisapprove, setOpenDisapprove] = useState(false);

  const openDeleteDialog = (type) => {
    setOpenDelete(true)
  };
  const openDisapproveDialog = (type) => {
    setOpenDisapprove(true)
  };

  const editURL = () => {
    pushUrl(props, `/admin/finance/journal-entry/${journalEntryId}/edit`);
  }

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: editURL,
      icon: Edit,
      permission: canEdit,
    },
    {
      id: "delete",
      title: "delete",
      action: openDeleteDialog,
      icon: DeleteOutline,
      permission: Boolean(canDelete),
    },
    {
      id: "disapprove",
      title: "disapprove",
      action: openDisapproveDialog,
      icon: LockOpenOutlined,
      permission: Boolean(canDisapprove),
    },
  ]

  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !data?.journalEntry ? (
      <NotFound />) : (
      <StyledGrid
        container
        justifyContent="center"
        className={classes.mainTracking}
        sx={{ margin: "0", width: "100%" }}
      >
        <Fragment>
          <TitleAppBar path={props.match.path}>
            <LongMenu icons={icons} />
            <MutationWithDialog
              mutaion={DELETE_JOURNAL_ENTRY.query}
              setOpenDelete={setOpenDelete}
              openDelete={openDelete}
              dialogTitle={t("deleteJournalEntry")}
              dialogContent={t("deleteRecordMessage")}
              mutaionProps={{ variables: { id: parseInt(journalEntryId) } }}
              onCompleted={() =>
                pushUrl(props, "/admin/finance/journal-entry")
              }
              onCompleteMessage={t("deleteMessage")}
            />

            <MutationWithDialog
              mutaion={DISAPPROVE_JOURNAL_ENTRY_MUTATION.query}
              setOpenDelete={setOpenDisapprove}
              openDelete={openDisapprove}
              dialogTitle={t("disapprove")}
              dialogContent={t("disapprovedMessage")}
              mutaionProps={{ variables: { id: parseInt(journalEntryId) } }}
              onCompleted={() => refetch()}
              onCompleteMessage={t("successfullyDisapproved")}
            />
          </TitleAppBar>
        </Fragment>
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", p: 2, gap: 2 }}
          >
            <Paper container className={classes.box} component={Grid}>
              <KeyValuePair
                title={t("theDate")}
                value={data?.journalEntry?.date}
              />
              <KeyValuePair
                title={t("code")}
                value={data?.journalEntry?.code}
              />
              <KeyValuePair
                title={t("branch")}
                value={data?.journalEntry.branch.name}
              />
              <KeyValuePair
                title={t("type")}
                value={data?.journalEntry.type.name}
              />
              <KeyValuePair
                title={t("subsidiary")}
                value={data?.journalEntry?.subsidiary?.name}
              />
              <KeyValuePair
                title={t("description")}
                value={data?.journalEntry?.description}
              />

              <KeyValuePair
                title={t("createdBy")}
                value={
                  data?.journalEntry && (
                    <SpanLink
                      pathname={`/admin/users/${data?.journalEntry?.createdBy?.id}`}
                    >
                      {data?.journalEntry?.createdBy?.username}
                    </SpanLink>
                  )
                }
              />
              <KeyValuePair
                title={t("updatedBy")}
                value={
                  data?.journalEntry && (
                    <SpanLink
                      pathname={`/admin/users/${data?.journalEntry?.updatedBy?.id}`}
                    >
                      {data?.journalEntry?.updatedBy?.username}
                    </SpanLink>
                  )
                }
              />

              <KeyValuePair
                title={t("approved")}
                value={
                  data?.journalEntry?.approved ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )
                }
              />
            </Paper>
          </Grid>
          <RecordsTable records={data?.journalEntry?.records} />
        </>
      </StyledGrid>
    );
};

export default JournalEntryView;
