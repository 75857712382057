import React, { Fragment, useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Alert,
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  SwipeableDrawer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import {
  CancelOutlined,
  Clear,
  Delete,
  Done,
  DoneAll,
} from "@mui/icons-material";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import MUItextField from "../HOC/MUI/MUItextField";
import { PICKUP, SAVE_PICKUP_MUTATION, UPDATE_PICKUP_STATUS } from "./Graphql";
// import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import CustomButton from "../HOC/MUI/CustomButton";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";

import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import Grid from "@mui/material/Unstable_Grid2";
import * as gqlb from "gql-query-builder";
import { useSnackbar } from "notistack";
import NotFound from "../../Error/NotFound";
import {
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import CellLink from "../HOC/CustomComponents/CellLink";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { Can } from "../HOC/CustomComponents/Secured";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import MUITimePicker from "../HOC/MUI/MUITimePicker";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import ShipmentList from "./PickupShipments/ShipmentList";
import BarcodeSelector from "./PickupShipments/BarcodeSelector";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import TitleAppBar from "../../Layout/TitleAppBar";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";

const PREFIX = "PickupForm";

const classes = {
  main: `${PREFIX}-main`,
  mainTable: `${PREFIX}-mainTable`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  shipmentList: `${PREFIX}-shipmentList`,
  button: `${PREFIX}-button`,
  overlay: `${PREFIX}-overlay`,
  pagination: `${PREFIX}-pagination`,
};

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.mainTable}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.shipmentList}`]: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.pagination}`]: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
}));
//////////////////////////////////////////////////

export { };

/////////////////DIALOG///////////////

const timeFormat = (date) => moment(date).locale("en").format("HH:mm");
const stringFormatToDate = (date) => moment(date, "HH:mm").toDate();
const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

const PickupForm = (props) => {
  const pickupId = props.match.params.id;
  const [pathURL, setPathURL] = useState(props.match.path)


  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    deliveryAgent: null,
    transactionType: null,
  });

  const theme = useTheme();

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [pickupData, setPickupData] = useState({ entries: [] });
  const [pickupDialog, setPickupDialog] = useState({
    open: false,
    edit: false,
    cancel: false,
  });
  const [infoInProgress, setInfoInProgress] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [disableForm, setDisableForm] = useState();
  const [drawerState, setDrawerState] = useState({
    left: false,
    bottom: false,
    right: false,
  });

  const [date, setDate] = useState({
    date: new Date(),
    timeFrom: null,
    timeTo: null,
  });

  const pickupStatus = pickupData?.status?.code;
  useEffect(() => {
    if (pickupStatus) {
      pickupStatus !== "NEW" && setDisableForm(true)
    }
  }, [pickupStatus])

  const pickupShipments = pickupData?.entries;

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    clearErrors,
    setError,
  } = useForm();
  const { t } = useTranslation(["translation", "validation"]);

  const { enqueueSnackbar } = useSnackbar();

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const SETTINGS = gqlb.query({
    operation: "shippingSettings",
    fields: [{ defaultPKBKTransactionType: ["id", "name"] }],
    variables: {},
  });

  const chooseCustomerPermission = Globals.user.hasPermission(
    "shipping.shipment.choose_customer"
  );
  const updatePickup = Globals.user.hasPermission(
    "shipping.pickup.update_status"
  );
  useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
      skip: !chooseCustomerPermission || pickupId,
      onCompleted: (data) => {
        const defaultTransactionType =
          data?.shippingSettings?.["defaultPKBKTransactionType"];
        setAutocompleteValues((prev) => ({
          ...prev,
          transactionType: defaultTransactionType,
        }));
      },
    }
  );

  const [savePickupMutation, { loading: savePickupLoad }] = useMutation(
    gql`
      ${SAVE_PICKUP_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setPickupData(data.savePickup)
        data?.savePickup?.status.code !== "NEW" && setDisableForm(true)
        setValue("code", data?.savePickup?.code);
        setValue("id", parseInt(data?.savePickup?.id));
        const url = history.createHref({
          pathname: `/admin/pickups/${data?.savePickup?.id}/edit`,
        });
        setPathURL(url)
        windowReplaceUrl(url);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      },
      onError: (error) => {
        error.graphQLErrors?.[0]?.extensions?.category !== "validation" && enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        setValidationError(error.graphQLErrors, setError);
      },
    }
  );

  const [updatePickupStatusMutation, { loading: updateStatusLoad }] =
    useMutation(
      gql`
        ${UPDATE_PICKUP_STATUS.query}
      `,
      {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
        onError: (error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        },
      }
    );

  const { data: pickupByIdQuery, loading: pickupByIdLoad } = useQuery(
    gql`
      ${PICKUP.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !pickupId,
      variables: {
        id: parseInt(pickupId),
      },
      onCompleted: (data) => {
        if (data?.pickup === null) return;
        const pickup = data?.pickup;
        setCanEdit(pickup.editable);
        setPickupData(pickup);
        setValue("id", parseInt(pickup?.id));
        setValue("code", pickup?.code);
        setValue("date", pickup?.date);
        pickup?.timeFrom && setValue("timeFrom", pickup?.timeFrom);
        pickup?.timeTo && setValue("timeTo", pickup?.timeTo);
        pickup?.notes && setValue("notes", pickup?.notes);
        pickup?.shipmentsCount &&
          setValue("shipmentsCount", pickup?.shipmentsCount);
        setDate((prev) => ({
          ...prev,
          date: pickup?.date,
          ...(pickup?.timeFrom && {
            timeFrom: stringFormatToDate(pickup?.timeFrom),
          }),
          ...(pickup?.timeTo && { timeTo: stringFormatToDate(pickup?.timeTo) }),
        }));

        setAutocompleteValues({
          branch: pickup.branch,
          customer: pickup?.customer,
          deliveryAgent: pickup?.deliveryAgent,
          transactionType: pickup.transactionType,
        });

        // pickedShipment["shipments"] = data.pickup.shipments;
      },
    }
  );

  ////////////////////END DRAWER////////////////

  /////////////// Start Barcode /////////////////

  const [barcodeDarwerState, setBarcodeDarwerState] = useState({
    left: false,
    bottom: false,
    right: false,
  });
  const barcodeDrawerHandler = (anchor, open) => {
    setBarcodeDarwerState((prev) => ({
      ...prev,
      [anchor]: open,
    }));
  };

  /////////////// End Barcode /////////////////

  const canChooseTime = Globals.user.hasPermission(
    "shipping.pickup.choose_time"
  );

  const history = useHistory();
  const handelTime = (data, name) => {
    if (data[name]) {
      data[name] =
        typeof data[name] === "string" ? data[name] : timeFormat(data[name]);
    }
  };

  const isFormValuesChange = (data) => {
    return (
      pickupData && (pickupData.branch.id !== data.branchId
        || pickupData.customer.id !== data.customerId
        || pickupData.transactionType.id !== data.transactionTypeId)
    )
  };

  const onSubmit = (data) => {
    if (pickupShipments.length > 0 && isFormValuesChange(data)) {
      setPickupDialog((prev) => ({ ...prev, open: true, edit: true }));
      return;
    }

    const shipments = pickupShipments;
    let handledShipments = [];
    handledShipments = shipments.length > 0 && shipments.map((i) => ({
      id: i.id,
      type: i.__typename.toUpperCase(),
      ...(i?.warehouseSection?.id && {
        sectionId: i?.warehouseSection?.id,
      }),
    }));
    data["entries"] = handledShipments ? handledShipments : [];
    for (const key in data) {
      if (key === "cancelPickupReasone") {

        delete data[key]
      }
      if (data[key] === "") {
        data[key] = null;
      }
      if (!canChooseTime) {
        delete data["timeFrom"]
        delete data["timeTo"]
      }
    }


    handelTime(data, "timeFrom");
    handelTime(data, "timeTo");
    data["date"] = dateFormat(data["date"]);

    if (data["shipmentsCount"])
      data["shipmentsCount"] = parseInt(data["shipmentsCount"]);

    savePickupMutation({
      variables: {
        input: {
          ...data,
        },
      },
    })
  };
  const handelPickupData = () => {
    let pickupDetails = watch();

    handelTime(pickupDetails, "timeFrom");
    handelTime(pickupDetails, "timeTo");

    pickupDetails["date"] = dateFormat(pickupDetails["date"]);
    for (const key in pickupDetails) {
      if (pickupDetails[key] === "") {
        delete pickupDetails[key];
      }
    }
    if (pickupDetails["shipmentsCount"])
      pickupDetails["shipmentsCount"] = parseInt(
        pickupDetails["shipmentsCount"]
      );

    return pickupDetails;
  };

  const updatePickupDate = () => {
    const data = watch()
    data["entries"] = [];
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
      if (!canChooseTime) {
        delete data["timeFrom"]
        delete data["timeTo"]
      }
    }



    handelTime(data, "timeFrom");
    handelTime(data, "timeTo");
    data["date"] = dateFormat(data["date"]);

    if (data["shipmentsCount"])
      data["shipmentsCount"] = parseInt(data["shipmentsCount"]);

    savePickupMutation({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        closePickupDialog()
        setDisableForm(true)
        setValue("code", data?.data?.savePickup?.code);
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  }

  const updateShipments = (newShipments, remove) => {
    const pickupDetails = handelPickupData();
    let handledShipments = [];
    if (remove) {
      pickupShipments
        .filter((i) => i.id !== newShipments.id)
        .map((i) =>
          handledShipments.push({ 'id': i.id, 'type': i.__typename.toUpperCase() })
        );
    } else {
      handledShipments = pickupShipments.map((i) => ({
        id: i.id,
        type: i.__typename.toUpperCase(),
        ...(i?.warehouseSection?.id && {
          sectionId: i?.warehouseSection?.id,
        }),
      }));
      let updatedShipments = newShipments.map((i) => ({
        id: i.shipment.id,
        type: i.__typename.toUpperCase(),
        ...(i?.warehouseSection?.id && {
          sectionId: i?.warehouseSection?.id,
        }),
      }));
      handledShipments = handledShipments.concat(updatedShipments);
    }

    savePickupMutation({
      variables: {
        input: {
          ...pickupDetails,
          entries: handledShipments,
        },
      },
    })
      .then((data) => {
        if (remove) {
          setPickupData(data.data.savePickup);
        }
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const closePickupDialog = () => {
    setPickupDialog({ edit: false, open: false, approve: false });
  };

  const openPickupDialog = () => {
    setPickupDialog((prev) => ({ ...prev, open: true, cancel: true }))
  };

  const updatePickupStatus = (status) => {
    updatePickupStatusMutation({
      variables: {
        input: {
          id: parseInt(watch("id")),
          statusCode: status,
          notes: watch("cancelPickupReasone"),
        },
      },
    })
      .then((data) => {
        setPickupData(data.data.updatePickupStatus);
        closePickupDialog();
        status === "DON" && pushUrl(props, `/admin/pickups/${watch("id")}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const informDeliveryAgent = (status) => {
    setInfoInProgress(false);
    const pickupDetails = handelPickupData();

    savePickupMutation({
      variables: {
        input: {
          ...pickupDetails,
        },
      },
    })
      .then((data) => {
        updatePickupStatusMutation({
          variables: {
            input: {
              id: parseInt(watch("id")),
              statusCode: "WRK",
            },
          },
        })
          .then((data) => {
            setPickupData(data.data.updatePickupStatus);
          })
          .catch((error) => {
            setInfoInProgress(false);
            console.log(error);
          });
      })
      .catch(({ graphQLErrors }) => {
        setInfoInProgress(false);
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const parseData = (data) => {
    return data;
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const addShipmentToManifest = (data) => {
    const shipments = data.map((i) => i.shipment);
    setPickupData((prev) => ({
      ...prev,
      entries: [...prev.entries, ...shipments],
    }));
    updateShipments(shipments);
  };

  let formBody = null;
  let tableBody = null;
  /////////////////////// Customer Shipments Table /////////////////////////////

  const customerTableHead = [
    "requestCode",
    "recipient",
    "destination",
    "recipientAddress",
    "description",
    "pieceCount",
    "value",
    "returningDueFees",
    "totalCost",
    "",
  ];

  const customerTableBody = (shipment, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        {shipment.__typename === "Barcode" ? <FixedTableCell>{shipment?.code}</FixedTableCell>
          : <CellLink pathname={`/admin/shipments/${shipment.id}`}>
            {shipment.code}
          </CellLink>}
        <FixedTableCell>{shipment?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipment?.recipientZone?.id}`}
          zone={shipment?.recipientZone?.name}
          subzone={shipment?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipment?.recipientAddress}</FixedTableCell>

        <FixedTableCell>{shipment?.description}</FixedTableCell>
        <FixedTableCell>{shipment?.piecesCount}</FixedTableCell>
        <FixedTableCell>{shipment?.amount}</FixedTableCell>
        <FixedTableCell>{shipment?.allDueFees}</FixedTableCell>
        <FixedTableCell>{shipment?.totalAmount}</FixedTableCell>
        <FixedTableCell component="th" scope="row">
          <IconButton
            size="small"
            disabled={savePickupLoad || pickupStatus === "DON"}
            onClick={() => {
              pickupShipments.forEach((i) => {
                if (i.id === shipment.id) {
                  i["pendingDelete"] = true;
                }
              });
              setPickupData((prev) => ({
                ...prev,
                entries: pickupShipments,
              }));
              updateShipments(shipment, true);
              // fireMutaion(filtered)
            }}
          >
            {shipment["pendingDelete"] ? (
              <CustomSpinner name="ClockLoader" size={20} />
            ) : (
              <Delete />
            )}
          </IconButton>
        </FixedTableCell>
      </TableRow>
    );
  };

  const total = {
    amount: 0,
    allDueFees: 0,
    totalAmount: 0,
  };

  const sumTableNumber = (shipment, tableTotal) => {
    Object.entries(tableTotal).forEach(([k, v]) => {
      tableTotal[k] += shipment[k] ?? 0;
    });
  };

  tableBody = (pickupStatus === "WRK" ||
    pickupStatus === "PKD" ||
    pickupStatus === "DON") && (
      <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
        {/* *******Table******* */}
        <Paper className={clsx(classes.table, classes.paper)}>
          <EnhancedTableToolbar
            {...props}
            classes={classes}
            addShipment={() => shipmentDrawerHandler(true)}
            barcode={() => barcodeDrawerHandler(anchor, true)}
            disabledAddIcon={pickupStatus === "CXL" || pickupStatus === "DON"}
            selectedShipment={pickupShipments}
          />

          <TableFixedHeaderWraper>
            <ShipmentsTable
              page={page}
              rowsPerPage={rowsPerPage}
              data={pickupShipments?.sort((a, b) => b.id - a.id)}
              headCells={customerTableHead}
              parseBodyCell={customerTableBody}
              // sumRow={true}
              total={total}
              sumTableNumber={sumTableNumber}
            />
          </TableFixedHeaderWraper>

          {pickupShipments.length > 4 && (
            <MUITablePagination
              // classeName={classes.pagination}
              count={pickupShipments.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}

          {(!watch("id") || !disableForm) && (
            <Box className={classes.overlay}>
              <Typography color="textPrimary" variant="body1">
                {t("savePickupRequired")}
              </Typography>
            </Box>
          )}
          <Grid container justifyContent="center" alignItems="center">
            {pickupShipments.length === 0 && (
              <EmptyTableMessage
                message={t("noResult")}
              />
            )}
          </Grid>
        </Paper>
      </Grid>
    );

    // (console.log(statusCode === "DON" && !disableForm))
    // (console.log(statusCode === "WRK" && disableButton))
  const statusButton = (status) => {
    const statusCode = status === "NEW" ? "WRK" : "DON";
    const buttonCondition = status && status !== "CXL" && status !== "DON";
    const informDeliveryAgentLoading = infoInProgress && savePickupLoad;
    const data = watch()
    let disableButton = false
  
    for (const key in data) {
      if ((data[key] === "" || data[key] === undefined) && !["notes", "vehicleCode", "timeFrom", "timeTo", "shipmentsCount", "cancelPickupReasone"].includes(key)) {
        disableButton = true
      }
    }

    return (
      buttonCondition &&
      pickupData["deliveryAgent"] && (
        <CustomButton
          className={classes.button}
          disabled={updateStatusLoad || informDeliveryAgentLoading || (statusCode === "DON" && !disableForm) || (statusCode === "WRK" && disableButton)}
          variant="contained"
          size="medium"
          color="primary"
          onClick={() =>
            status === "NEW"
              ? informDeliveryAgent()
              : updatePickupStatus(statusCode)
          }
          name="updateStatus"
          customcolor={theme.palette.info.main}
          loading={updateStatusLoad || informDeliveryAgentLoading}
          startIcon={
            !updateStatusLoad && pickupShipments.length > 0 && <DoneAll />
          }
        >
          {status === "NEW" ? t("informDeliveryAgent") : t("receivedInStore")}
        </CustomButton>
      )
    );
  };
  const screenWidth = useWidth();

  const anchor = screenWidth === "xs" ? "bottom" : "right";
  const shipmentDrawerHandler = (open) => {
    setDrawerState((prev) => ({
      ...prev,
      [anchor]: open,
    }));
  };
  const formCondition = pickupId ? !pickupByIdLoad : true;
  formBody = (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
      sx={{ padding: "24px", width: "100%", margin: "0" }}
    >
      {formCondition ? (
        <Paper container component={Grid} sx={{ padding: 1, flexGrow: 1 }}>
          <Grid container sm={12} xs={12} justifyContent="space-between">
            {disableForm && (
              <Grid sm={12} xs={12}>
                <Alert
                  severity="warning"
                  action={
                    pickupStatus !== "CXL" && (
                      <Button
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setDisableForm(false)
                        }}
                      >
                        {t("update")}
                      </Button>
                    )
                  }
                >
                  {pickupStatus === "CXL"
                    ? t("updatePickupForbidden")
                    : t("updatePickup")}
                </Alert>
              </Grid>
            )}
          </Grid>

          <Grid container sx={{ position: "relative" }} sm={12} xs={12}>
            {disableForm && (
              <Box
                className={classes.overlay}
                sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
              ></Box>
            )}

            <Grid xs={12} sm={6} md={4}>
              <ControlMUItextField
                name="code"
                control={control}
                label={t("recordCode")}
                rules={{ required: watch("id") ? t("fieldIsRequired") : false }}

              // defaultValue={queryVariables?.pickup?.code}
              // disabled={pickupByIdQuery?.pickup?.approved || disableForm}
              />
            </Grid>
            <Grid xs={12} sm={6} md={4}>
              <MUIDateTime
                name="date"
                label={t("theDate")}
                control={control}
                value={date.date}
                onChange={(e) => setDate((prev) => ({ ...prev, date: e }))}
              />
            </Grid>

            <Can showException permission="shipping.pickup.choose_branch">
              <Grid xs={12} sm={6} md={4}>
                <ListBranches
                  control={control}
                  errors={errors}
                  name={"branchId"}
                  rules={{ required: t("fieldIsRequired") }}
                  defaultValue={autocompleteValues.branch}
                  onChangeValue={(e) => {
                    setValue("deliveryAgentId", "");
                    setValue("customerId", "");
                    setValue("transactionTypeId", "");
                    // setPickedShipment({ shipments: [] })
                  }}
                  skipDefaultBranch={Boolean(pickupId)}
                />
              </Grid>
            </Can>
            <Can
              showException
              permission="shipping.pickup.choose_transaction_type"
            >
              <Grid xs={12} sm={6} md={4}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"transactionTypeId"}
                  label={t("transactionType")}
                  rules={{ required: t("fieldIsRequired") }}
                  parseData={(data) => parseData(data)}
                  query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                  skip={!watch("branchId")}
                  variables={{
                    input: {
                      type: "PKBK",
                      ...(watch("branchId") && {
                        branchId: {
                          value: watch("branchId"),
                          includeNull: true,
                        },
                      }),
                    },
                  }}
                  defaultValue={autocompleteValues.transactionType}
                />
              </Grid>
            </Can>
            <Can showException permission="shipping.pickup.choose_customer">
              <Grid xs={12} sm={6} md={4}>
                <CustomAutocomplete
                  control={control}
                  name={"customerId"}
                  label={t("customer")}
                  rules={{ required: t("fieldIsRequired") }}
                  parseData={(data) => parseData(data)}
                  query={LIST_CUSTOMERS_DROPDOWN.query}
                  skip={!watch("branchId")}
                  variables={{
                    input: {
                      branchId: {
                        value: watch("branchId"),
                        includeNull: true,
                      },
                      active: true,
                      typeCode: ["MERCHANT"],
                    },
                  }}
                  defaultValue={autocompleteValues.customer}
                />
              </Grid>
            </Can>
            <Can
              showException
              permission="shipping.pickup.choose_delivery_agent"
            >
              <Grid xs={12} sm={6} md={4}>
                <CustomAutocomplete
                  control={control}
                  errors={errors}
                  name={"deliveryAgentId"}
                  label={t("shippingAgent")}
                  rules={{ ...((pickupId || watch("id")) && pickupStatus !== "NEW" && { required: t("fieldIsRequired") }) }}
                  disabled={!watch("branchId")}
                  onChangeValue={(e) => {
                    if (e?.id !== "") {
                      clearErrors("deliveryAgentId");
                    }
                  }}
                  parseData={(data) => parseData(data)}
                  query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                  skip={!watch("branchId")}
                  variables={{
                    input: {
                      active: true,
                      branchId: {
                        value: watch("branchId"),
                        includeNull: true,
                      },
                    },
                  }}
                  defaultValue={autocompleteValues.deliveryAgent}
                />
              </Grid>
            </Can>
            <Grid xs={12} sm={6} md={4}>
              <LookupDropdown
                control={control}
                errors={errors}
                name={"vehicleCode"}
                label={t("vehicle")}
                variables={{ input: { code: "SHP_VEHICLES_TYPE" } }}
                onCompleted={(data) =>
                  lookupsComplete(
                    data,
                    pickupByIdQuery?.pickup?.vehicle?.code,
                    "vehicleCode"
                  )
                }
              />
            </Grid>
            <Can showException permission="shipping.pickup.choose_time">
              <Grid xs={12} sm={6} md={4}>
                <MUITimePicker
                  rules={{
                    validate: {
                      max: (value) =>
                        value > stringFormatToDate(watch("timeTo"))
                          ? t("validation:lt", {
                            field1: t("fromHour"),
                            field2: t("toHour"),
                          })
                          : true,
                    },
                  }}
                  InputProps={{
                    ...(date.timeFrom && {
                      endAdornment: (
                        <IconButton
                          size="small"
                          key={"timeFrom"}
                          onClick={() => {
                            setDate((prev) => ({ ...prev, timeFrom: null }));
                            setValue("timeFrom", "");
                          }}
                        >
                          <Clear size="small" />
                        </IconButton>
                      ),
                    }),
                  }}
                  name="timeFrom"
                  label={t("fromHour")}
                  control={control}
                  value={date.timeFrom}
                  onChange={(e) =>
                    setDate((prev) => ({ ...prev, timeFrom: e }))
                  }
                />
              </Grid>
            </Can>
            <Can showException permission="shipping.pickup.choose_time">
              <Grid xs={12} sm={6} md={4}>
                <MUITimePicker
                  rules={{
                    validate: {
                      max: (value) =>
                        value < stringFormatToDate(watch("timeFrom"))
                          ? t("validation:mt", {
                            field1: t("toHour"),
                            field2: t("fromHour"),
                          })
                          : true,
                    },
                  }}
                  InputProps={{
                    ...(date.timeTo && {
                      endAdornment: (
                        <IconButton
                          size="small"
                          key={"timeTo"}
                          onClick={() => {
                            setDate((prev) => ({ ...prev, timeTo: null }));
                            setValue("timeTo", "");
                          }}
                        >
                          <Clear size="small" />
                        </IconButton>
                      ),
                    }),
                  }}
                  name="timeTo"
                  label={t("toHour")}
                  control={control}
                  value={date.timeTo}
                  onChange={(e) => setDate((prev) => ({ ...prev, timeTo: e }))}
                />
              </Grid>
            </Can>

            <Grid xs={12} sm={6} md={4}>
              <ControlMUItextField
                name="shipmentsCount"
                control={control}
                label={t("shipmentsNumber")}
              />
            </Grid>
            <Grid xs={12} sm={6} md={4}>
              <MUItextField
                name="notes"
                errors={errors}
                label={t("notes")}
                register={register}
                formType="requireFalse"
              />
            </Grid>
          </Grid>

          <Grid sm={12} xs={12} container justifyContent="flex-end">
            {!infoInProgress && !disableForm && (
              <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                className={classes.button}
                variant="contained"
                size="medium"
                loading={savePickupLoad}
                // className={classes.button}
                disabled={savePickupLoad}
                startIcon={!savePickupLoad && <Done />}
              >
                {!savePickupLoad && t("save")}
              </CustomButton>
            )}
            {updatePickup && !Globals.user.account && statusButton(pickupStatus)}
            {updatePickup && pickupStatus === "NEW" && pickupShipments.length <= 0 && (
              <CustomButton
                className={classes.button}
                customcolor={theme.palette.error.main}
                variant="contained"
                size="medium"
                color="inherit"
                onClick={openPickupDialog}
                name="cancel"
                disabled={updateStatusLoad}
                loading={updateStatusLoad}
                startIcon={!updateStatusLoad && <CancelOutlined />}
              >
                {t("cancel")}
              </CustomButton>
            )}
          </Grid>
        </Paper>
      ) : (
        <FullScreenLoading />
      )}
    </Grid>
  );

  const cancelPickup = (
    <Box component="form" onSubmit={handleSubmit(() => { })}>
      {pickupDialog.cancel && <ControlMUItextField
        name="cancelPickupReasone"
        control={control}
        label={t("notes")}
        margin="normal"
        onChange={() => clearErrors("cancelPickupReasone")}
      />}
    </Box>
  );

  return pickupByIdLoad ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading >
    : !pickupByIdQuery?.pickup && pickupId ? (
      <NotFound />
    ) : !canEdit ? (
      <Can permission="uneEditable" />
    ) : (
      <Root>
        <TitleAppBar path={pathURL} />
        {props.children}
        <SwipeableDrawer
          variant="persistent"
          disableDiscovery={true}
          disableSwipeToOpen={true}
          anchor={anchor}
          open={drawerState[anchor]}
          onClose={() => false}
          onOpen={() => shipmentDrawerHandler(anchor, true)}
          classes={{ paper: classes.shipmentList }}
          dir={theme.direction}
        >
          {drawerState[anchor] && (
            <ShipmentList
              headCell={customerTableHead}
              bodyRow={customerTableBody}
              pickupShipments={pickupShipments}
              done={() => shipmentDrawerHandler(false)}
              setSelected={addShipmentToManifest}
              variables={watch()}
            />
          )}
        </SwipeableDrawer>

        <SwipeableDrawer
          disableDiscovery={true}
          disableSwipeToOpen={true}
          anchor={anchor}
          open={barcodeDarwerState[anchor]}
          onClose={() => false}
          onOpen={() => barcodeDrawerHandler(anchor, true)}
          sx={{
            width: "auto",
            "& .MuiDrawer-paper": {
              width: { md: "50%" },

              height: "100%",
              overflow: "hidden",
            },
          }}
          dir={theme.direction}
        >
          {barcodeDarwerState[anchor] && <BarcodeSelector
            setSelected={addShipmentToManifest}
            done={() => barcodeDrawerHandler(anchor, false)}
            variables={watch()}
          />}
        </SwipeableDrawer>

        <CustomDialog
          open={pickupDialog.open}
          title={pickupDialog.cancel ? t("cancelPickup") : t("updatePickup")}
          content={Globals.user.account ? t("confirmationMessage") : pickupDialog.cancel ? cancelPickup : t("updatePickupWarningMessage")}
          fullWidth
          maxWidth="xs"
          actions={
            <>
              <Button color="primary" onClick={closePickupDialog}>
                {t("cancel")}
              </Button>
              <Button
                color="primary"
                disabled={updateStatusLoad}
                type="submit"
                onClick={() => {
                  if (pickupDialog.cancel) {
                    if (watch("cancelPickupReasone") || Globals.user.account) {
                      updatePickupStatus("CXL");
                    } else {
                      setError("cancelPickupReasone", {
                        type: "required",
                        message: t("fieldIsRequired"),
                      });
                    }
                  }
                  pickupDialog.edit && updatePickupDate()
                }}
              >
                {pickupDialog.edit && t("update")}
                {pickupDialog.cancel && t("confirm")}
                {updateStatusLoad && <ButtonLoading />}
              </Button>
            </>
          }
        />

        {formBody}

        <Grid
          container
          spacing={3}
          justifyContent="center"
          className={classes.mainTable}
        >
          {formCondition && tableBody}
        </Grid>
      </Root>
    );
};

export default withRouter(PickupForm);
