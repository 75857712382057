import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Add, Delete, Edit } from "@mui/icons-material";
import clsx from "clsx";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { sumArrayOfObjectValue } from "../../helpers/helpersFunction";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import formatMoney from "../../helpers/numbersDot";

const PREFIX = "RecordsTable";

const classes = {
  p: `${PREFIX}-p`,
  tap: `${PREFIX}-tap`,
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  destinations: `${PREFIX}-destinations`,
  deleteIcon: `${PREFIX}-deleteIcon`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.p}`]: {
    fontSize: "14px",
    margin: "2px 1px",
    width: 100,
  },

  [`& .${classes.tap}`]: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    // flexDirection: "column",
    textTransform: "capitalize",
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.destinations}`]: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.deleteIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const RecordsTable = (props) => {
  const {
    records = [],
    addRecord,
    setRecordsList,
    notApproved,
    branchId,
  } = props;
  const { t } = useTranslation();
  const [recordIndex, setRecordIndex] = useState(0);
  const [recordsPage, setRecordPage] = useState(0);
  const [recordRowsPerPage, setrecordRowsPerPage] = useState(20);

  const handleRecordsPage = (event, newPage) => {
    setRecordPage(newPage);
  };
  const handleRecordsRowsPerPage = (event) => {
    setrecordRowsPerPage(parseInt(event.target.value));
    setRecordPage(0);
  };

  const creditSum = sumArrayOfObjectValue(records, "credit").toFixed(2);
  const debitSum = sumArrayOfObjectValue(records, "debit").toFixed(2);
  const editable = notApproved && addRecord;

  return (
    <StyledGrid container alignItems="flex-start">
      <Grid container item sm={12} component={Paper}>
        <Toolbar className={classes.toolbar} variant="dense">
          <Typography className={classes.title} color="inherit" variant="h6">
            {t("records")}
          </Typography>

          {editable ? (
            <IconButton
              color="primary"
              disabled={!branchId}
              onClick={() => {
                addRecord();
                setRecordIndex(0);
              }}
              aria-label="Add record"
              size="large"
            >
              <Add />
            </IconButton>
          ) : null}
        </Toolbar>
        <Grid container item className={classes.table}>
          {records.length <= 0 ? (
            <EmptyTableMessage loading={false} message={t("searchNoResult")} />
          ) : (
            <TableFixedHeaderWraper className={classes.tableContainer}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("debit")}</FixedTableCell>
                    <FixedTableCell>{t("credit")}</FixedTableCell>
                    <FixedTableCell>{t("glAccount")}</FixedTableCell>
                    <FixedTableCell>{t("description")}</FixedTableCell>
                    {editable ? <FixedTableCell></FixedTableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records.length > 0 &&
                    (recordRowsPerPage > 0
                      ? records.slice(
                        recordsPage * recordRowsPerPage,
                        recordsPage * recordRowsPerPage + recordRowsPerPage
                      )
                      : records
                    ).map((row, index) => (
                      <TableRow
                        className={clsx({
                          [classes.focus]:
                            recordsPage * recordRowsPerPage + index ===
                            recordIndex,
                        })}
                        hover
                        key={index}
                        onClick={() => {
                          setRecordPage(0);
                          setRecordIndex(
                            recordsPage * recordRowsPerPage + index
                          );
                        }}
                      >
                        <FixedTableCell>{formatMoney(row.debit)}</FixedTableCell>
                        <FixedTableCell>{formatMoney(row.credit)}</FixedTableCell>
                        <FixedTableCell>{row.glAccount.name}</FixedTableCell>
                        <FixedTableCell>{row.description}</FixedTableCell>

                        {editable ? (
                          <FixedTableCell>
                            <IconButton
                              size="small"
                              onClick={() =>
                                addRecord(
                                  recordsPage * recordRowsPerPage + index
                                )
                              }
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              size="small"
                              className={classes.deleteIcon}
                              onClick={() =>
                                setRecordsList((prev) => {
                                  const filterd = prev.filter(
                                    (i, deleteIndex) => deleteIndex !== index
                                  );
                                  return filterd;
                                })
                              }
                            >
                              <Delete />
                            </IconButton>
                          </FixedTableCell>
                        ) : null}
                      </TableRow>
                    ))}
                  <TableRow>
                    <FixedTableCell>
                      <Typography component="span" variant="h6">
                        {formatMoney(Math.abs(debitSum))}
                      </Typography>
                    </FixedTableCell>
                    <FixedTableCell>
                      <Typography component="span" variant="h6">
                        {formatMoney(Math.abs(creditSum))}
                      </Typography>
                    </FixedTableCell>
                    <FixedTableCell>
                      {t("deviation")}
                      <Typography component="span" variant="h6">
                        {" ( " + formatMoney(Math.abs(creditSum - debitSum)) + " ) "}
                      </Typography>

                      {creditSum - debitSum !== 0 && (
                        <Typography
                          component="span"
                          variant="caption"
                          color="error"
                        >
                          {t("deviationValidation")}
                        </Typography>
                      )}
                    </FixedTableCell>
                    <FixedTableCell colSpan={2} />
                  </TableRow>
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          )}
          <MUITablePagination
            count={records?.length ?? 0}
            rowsPerPage={recordRowsPerPage}
            page={recordsPage}
            onPageChange={handleRecordsPage}
            onRowsPerPageChange={handleRecordsRowsPerPage}
          />
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

export default RecordsTable;
