import { Table, TableBody, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { FixedTableCell } from "./FixedTableCell";
// import TableFixedHeaderWraper from "./TableWithFixedHeader";
import formatMoney from "../../../helpers/numbersDot";
import clsx from "clsx";
// import { useEffect } from "react";
// import useWidth from "../../../Hooks/useWidth";

const ShipmentHeader = (props) => {
  const {
    headCells,
    classes,
    checkAll,
  } = props.props;
  const { t } = useTranslation();
  return (
    <TableHead className={classes?.tableHead}>
      <TableRow>
        {checkAll && checkAll}
        {headCells.map((headCell, index) => {
          return headCell !== null ? (
            <FixedTableCell
              className={[
                (headCell === "status" && !headCells.includes("code")) ? classes?.[`shipmentTable_${"code"}`]
                  : (headCell === "status" || headCell === "code") ? classes?.[`shipmentTable_${headCell}`]
                    : headCell === " " ? classes?.[`update`]
                      : clsx(classes?.[`shipmentTable_${headCell}`], classes?.tableHeadFixed)
              ]}
              key={index}
            >
              {t(headCell)}
            </FixedTableCell>
          ) : null;
        })}
      </TableRow>
    </TableHead>
  )
}
const ShipmentBody = (props) => {
  const {
    headCells,
    data,
    parseBodyCell,
    sumRow,
    sum,
    ranking,
  } = props.props;
  const { t } = useTranslation();
  const { shipmentNumber } = props.props;
  return (
    <TableBody>
      {props.tableData.map((item, index) => parseBodyCell(item, index, shipmentNumber))} 
      {sumRow ??
        (data?.length > 0 && (
          ranking ? <TableRow role="checkbox" tabIndex={-1}>
            <FixedTableCell variant="head"> </FixedTableCell>
            {Object.entries(props.tableTotal).map(([k, v], index) => {
              return (
                <FixedTableCell variant="head" key={index}>
                  {typeof v !== 'string' ? <Typography color="inherit" variant="h6" >
                    {sum ? formatMoney(v) : formatMoney(v?.toFixed(2))}
                  </Typography> :
                    <Typography> </Typography>}
                </FixedTableCell>
              );
            })}
            {!headCells.some((i) => i === null) && (
              <FixedTableCell> </FixedTableCell>
            )}
          </TableRow> :
            <TableRow role="checkbox" tabIndex={-1}>
              <FixedTableCell
                colSpan={
                  headCells?.length - 2 - Object.entries(props?.tableTotal).length
                }
              >
                {" "}
              </FixedTableCell>
              <FixedTableCell variant="head">{t("total")}</FixedTableCell>
              {Object.entries(props.tableTotal).map(([k, v], index) => {
                return (
                  <FixedTableCell variant="head" key={index}>
                    {sum ? formatMoney(v) : formatMoney(v?.toFixed(2))}
                  </FixedTableCell>
                );
              })}
              {!headCells.some((i) => i === null) && (
                <FixedTableCell> </FixedTableCell>
              )}
            </TableRow>
        ))}
    </TableBody>
  )
}


const ShipmentsTable = (props) => {
  const {
    page,
    rowsPerPage,
    shipmentNumber,
    headCells,
    data,
    parseBodyCell,
    total,
    sumRow,
    classes,
    sumTableNumber,
    checkAll,
    invoiceQ,
    sum,
    getHeader,
    getBody,
    ranking,

    ...restProps
  } = props;

  const tableTotal = { ...total };
  if (!sum) {
    data &&
      data.forEach((shipment) => {
        return sumTableNumber
          ? sumTableNumber(shipment, tableTotal)
          : Object.entries(tableTotal).forEach(([k, v]) => {
            tableTotal[k] += shipment.shipment[k] ?? " ";
          });
      });
  }

  const tableData =
    (rowsPerPage
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : data) ?? [];

  return getHeader ? <ShipmentHeader props={props} /> : getBody ? <ShipmentBody props={props} tableData={tableData} tableTotal={tableTotal} /> :

    <Table
      {...restProps}
      aria-labelledby="tableTitle"
      aria-label="enhanced table"
    >
      {<ShipmentHeader props={props} />}
      <ShipmentBody props={props} tableData={tableData} tableTotal={tableTotal} />
    </Table>

};

ShipmentsTable.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  headCells: PropTypes.array,
  data: PropTypes.array.isRequired,
  parseBodyCell: PropTypes.func.isRequired,
  total: PropTypes.any,
  sumRow: PropTypes.any,
  classes: PropTypes.any,
};
export default ShipmentsTable;
