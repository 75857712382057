import { IconButton, Toolbar, Typography } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";

export const EnhancedTableToolbarForm = (props) => {
  const { classes, addShipmentProduct } = props;
  return (
    <Toolbar
      variant="dense"
      className={classes.toolbar}
    >
      <Typography
        className={classes.tableTitle}
        variant="h6"
        color={"text.primary"}
      >
      </Typography>
      <IconButton
        aria-label="Add"
        className={classes.addIcon}
        onClick={addShipmentProduct}
        size="large"
      >
        <AddCircleOutline />
      </IconButton>
    </Toolbar>
  );
};
