import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Checkbox, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import ShipmentRegion from "../../HOC/CustomComponents/ShipmentRegion";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { WarehouseSectionTableCell } from "../../Manifests/ManifestShipments/WarehouseSectionTableCell";

const PREFIX = "ShipmentListDetails";

const classes = {
  shipmentTable_checkbox: `${PREFIX}-shipmentTable_checkbox`,
};

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [`& .${classes.shipmentTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    left: 0,
  },
}));

export const invoiceShipmentsCells = (shipment) => {
  const cells = (
    <>
      <WarehouseSectionTableCell shipmentDetails={shipment?.shipment} />

      <FixedTableCell>{shipment?.shipment?.recipientName}</FixedTableCell>
      <ShipmentRegion
        pathname={`/admin/zones/${shipment?.shipment?.recipientZone?.id}`}
        zone={shipment?.shipment?.recipientZone?.name}
        subzone={shipment?.shipment?.recipientSubzone?.name}
      />
      <FixedTableCell>{shipment?.shipment?.recipientAddress}</FixedTableCell>
      <FixedTableCell>{shipment?.shipment?.amount}</FixedTableCell>
      <FixedTableCell>{shipment?.shipment?.allDueFees}</FixedTableCell>
      <FixedTableCell>{shipment?.shipment.totalAmount}</FixedTableCell>
      <FixedTableCell>{shipment?.shipment?.description}</FixedTableCell>
      <FixedTableCell>{shipment?.shipment?.piecesCount}</FixedTableCell>
    </>
  );

  return cells;
};
export function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  const { t } = useTranslation();

  const headCells = [
    t("requestCode"),
    t("wareHouse"),
    t("recipient"),
    t("destination"),
    t("recipientAddress"),
    t("value"),
    t("returningDueFees"),
    t("totalCost"),
    t("description"),
    t("pieceCount"),
  ];

  return (
    <StyledTableHead>
      <TableRow>
        <FixedTableCell
          className={classes.shipmentTable_checkbox}
          padding="checkbox"
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </FixedTableCell>
        {headCells.map((headCell, index) => (
          <FixedTableCell key={index}>{headCell}</FixedTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
