import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import {

  COLLECTION_VIEW,

} from "./CollectionListQuary";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import { defaultQueryFieldsCollection,initialData } from "./listCollectionFields";
import CollectionViewBody from "./CollectionViewBody";
import { styled } from "@mui/material/styles";
const PREFIX = "collectionView";
const classes = {
  main: `${PREFIX}-main`,
};

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));


const CollectionView = (props) => {

  // const [collectionType, setCollectionType] = useState(null);
  const collectionId = props.match.params.id?.trim();

  const [collectionsData, setCollectionsData] = useState();
  const [localKeys, setKeys] = useState(null)

  /******************************************* Start collection By Id ********************************************/
  const user = Globals.user;
  const journalEntryPermission = user.hasPermission(
    "accounting.journal_entry.list"
  );
  const ALLOWED_CONCIERGE = Globals.settings.allowedCustomerTypes.includes("CONCIERGE")
  const ALLOWED_SHIPMENT = Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") || Globals.settings.allowedCustomerTypes.includes("MERCHANT")
  const collectionQueryBody = COLLECTION_VIEW(journalEntryPermission, ALLOWED_CONCIERGE, ALLOWED_SHIPMENT);
  const {
    data,
    loading: collectionByIdLoad,
    refetch,
  } = useQuery(
    gql`
      ${collectionQueryBody.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !collectionId,
      variables: {
        id: parseInt(collectionId),
      },
      onCompleted: (data) => {
        if (data?.collection === null) return;
        // setCollectionType(data.collection?.type?.code)
        const collection = data?.collection;
        setCollectionsData(collection);
      },
    }
  );

  useEffect(() => {
    if (data) {
      setKeys(localStorage.getItem(`shipmentcollection${data.collection?.type?.code}`) ? JSON.parse(localStorage.getItem(`shipmentcollection${data.collection?.type?.code}`)) : initialData[data.collection?.type?.code])
    }
    return () => { };
  }, [data]);
  return (collectionByIdLoad || !localKeys || !data ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !data?.collection ? <NotFound /> :
      <CollectionViewBody
        {...props}
        data={data}
        collectionType={data.collection?.type?.code}
        collectionByIdLoad={collectionByIdLoad}
        refetch={refetch}
        collectionId={collectionId}
        setKeys={setKeys}
        shipmentListDetails={defaultQueryFieldsCollection(localKeys,collectionsData?.deliveryAgent?.autoPayCommission)}
        localKeys={localKeys}
        ALLOWED_SHIPMENT={ALLOWED_SHIPMENT}
        ALLOWED_CONCIERGE={ALLOWED_CONCIERGE}
        collectionsData={collectionsData}
      />
  )
};

export default CollectionView;
