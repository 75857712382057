import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Clear, Search } from "@mui/icons-material";
import clsx from "clsx";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Can } from "../Component/HOC/CustomComponents/Secured";
import { windowUrl } from "../Component/HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../Component/HOC/CustomFunctions/urlParameters";
import ControlMUItextField from "../Component/HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../Component/HOC/MUI/CustomAutocomplete";
import MUItextField from "../Component/HOC/MUI/MUItextField";
import {
  LIST_CUSTOMERS_DROPDOWN,
} from "../GlobalsQuery/ListDropdown/ListDropdown";

const PREFIX = "shipmentListFilters";

const classes = {
  button: `${PREFIX}-button`,
  btnMargin: `${PREFIX}-btnMargin`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  overlay: `${PREFIX}-overlay`,
  searchPadding: `${PREFIX}-searchPadding`,
};

const Root = styled(Grid)(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`&.${classes.searchForm}`]: {
    overflowY: "scroll",
    width: "100%",
    margin: 0,
    height: "100vh",
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.overlay}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    zIndex: 2,
  },

  //////////////////////Drawer///////////////////
  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },
}));

// const useListShipmentsFilters = ({ onSubmitFunc, rowsPerPage, resetPage }) => {
const FiltersProductForm = ({
  loading,
  resetPage,
  onSubmitFunc,
  dateValue,
  queryVariables,
  rowsPerPage,
  preventPushUrl,
  initData,
  pathname,
  removeFiltersFields,
  addFiltersFields,
  custody,
  clearCheckBox,
  statusDataHandler,
  initDlvrBefore,
  inWarehouse,
}) => {
  const { handleSubmit, register, errors, watch, control, setValue } =
    useForm();

  const history = useHistory();

  const urlQuery = urlParameters(window.location.search);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const removeField = (name) => {
    return removeFiltersFields ? !removeFiltersFields.includes(name) : true;
  };
  // const allowField = (name) => {
  //   return addFiltersFields ? addFiltersFields.includes(name) : false;
  // };

  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : initData?.["fromDate"] ?? null;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : initData?.["toDate"] ?? null;

  const [dateRange] = useState([initFromDate, initToDate]);

  // const [deliveryBeforeData, setDeliveryBefore] = useState(
  //   dateFormat(initDlvrBefore) ?? new Date()
  // );

  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");

  const initSearch = () => {
    let searchParameters = {
      refetch: true,
    };
    if (validUrlParameters) {
      if (urlQuery["search"]) {
        urlQuery["search"] = urlQuery["search"].toString().trim();
      }
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"].toString();
      if (urlQuery["name"]) urlQuery["name"] = urlQuery["name"].toString();

      const searchParams = { ...urlQuery };
      delete searchParams["rowsPerPage"];

      if (searchParams["search"])
        searchParams["search"] = searchParams["search"]
          .split(",")
          .filter((i) => i.trim() !== "")
          .map((i) => i.trim());

      searchParameters = searchParams;
    }
    return searchParameters;
  };
  const [search, setSearch] = useState(initSearch());

  const pushUrlSearch = (param) => {
    if (preventPushUrl) return;
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: `/admin/${pathname}`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const dateValueWithCode = () => {
    return {
      ...(Boolean(!watch("search")) && {
        fromDate,
        toDate,
      }),
    };
  };

  useEffect(() => {
    dateValue && dateValue(dateValueWithCode());
    queryVariables(search);
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onSubmit = (data) => {
    onSubmitFunc && onSubmitFunc(data);

    // const recipientZoneId = data.recipientZoneId.length > 0 &&
    //   data.recipientZoneId.map(function (str) {
    //     return parseInt(str);
    //   })
    // const recipientSubzoneId = data.recipientSubzoneId.length > 0 && data.recipientSubzoneId.map(function (str) {
    //   return parseInt(str);
    // })

    clearCheckBox && clearCheckBox();

    // moment.locale("en");
    let handledData = {
      customerId: data.customerId,
      code: data.code,
      name: data.name,
      // ...(dateRange[0] && {
      //   fromDate,
      // }),
      // ...(dateRange[1] && {
      //   toDate,
      // }),
      // ...(lastTransactionDate[0] && {
      //   lastTransactionFromDate: lastTransactionFrom,
      // }),
      // ...(lastTransactionDate[1] && {
      //   lastTransactionToDate: lastTransactionTo,
      // }),
      // statusCode: data.statusCode,
      // deliveryTypeCode: data.deliveryTypeCode,
      // returnTypeCode: data.returnTypeCode,
      // branchId: data.branchId,
      // originBranchId: data.originBranchId,
      // sectionId: data.sectionId,
      // ...(custody
      //   ? {
      //     inDeliveryAgentCustody: {
      //       ...(data.lastDeliveryAgentId && {
      //         deliveryAgentId: data.lastDeliveryAgentId,
      //       }),
      //     },
      //   }
      //   : { lastDeliveryAgentId: data.lastDeliveryAgentId }),
      // recipientZoneId: recipientZoneId && recipientZoneId,
      // recipientSubzoneId: recipientSubzoneId,
      // search: data.search.toString().trim(),
      // refNumber: data.refNumber.toString(),
      // typeCode: data.typeCode,
      // paymentTypeCode: data.paymentTypeCode,
      // collected: data.collected,
      // ...(initDlvrBefore &&
      //   data.deliveryBefore !== null && {
      //   deliveryBefore: dateFormat(data.deliveryBefore),
      // }),
    };


    for (const key in handledData) {
      if ([undefined, "", null].includes(handledData[key])) {
        delete handledData[key];
      }
    }
    if (data.search) {
      handledData = {
        search: data.search.toString(),
      };
    }

    resetPage();
    const newUrlParameters = { ...handledData };
    // if (newUrlParameters?.inDeliveryAgentCustody?.deliveryAgentId) {
    //   newUrlParameters["inDeliveryAgentCustody"] =
    //     newUrlParameters.inDeliveryAgentCustody.deliveryAgentId;
    // } else {
    //   delete newUrlParameters["inDeliveryAgentCustody"];
    // }

    pushUrlSearch({
      ...newUrlParameters,
      rowsPerPage,
      page: 0,
    });

    if (handledData.search) {
      handledData.search = handledData.search
        .split(",")
        .filter((i) => i.trim() !== "")
        .map((i) => i.trim());
    }

    // if (handledData.refNumber) {
    //   handledData.refNumber = handledData.refNumber
    //     .split(",")
    //     .filter((i) => i.trim() !== "")
    //     .map((i) => i.trim());
    // }

    setSearch((prev) => ({
      // ...prev,
      ...handledData,
      refetch: !prev.refetch,
    }));
  };

  // const resetLastTransactionDate = () => {
  //   setLastTransactionDate([null, null]);
  // };

  const clearCode = () => {
    setValue("search", "");
    pushUrlSearch({});
    resetPage();
    // resetLastTransactionDate();
    setSearch((prev) => ({
      // ...(dateRange[0] && { fromDate }),
      // ...(dateRange[1] && { toDate }),
      refetch: !prev.refetch,
    }));
  };

  const [autocompleteValues, setAutocompleteValues] = useState({
    customer: null,
  });

  // const lookupComplete = (data, name) => {
  //   const validData = lookupCodeIsValid(data, urlQuery[name]);
  //   if (validData) {
  //     setValue(name, urlQuery[name]);
  //   }
  // };

  useEffect(() => {
    urlQuery["search"] && setValue("search", urlQuery["search"].toString());
    urlQuery["name"] && setValue("name", urlQuery["name"]);
    urlQuery["code"] && setValue("code", urlQuery["code"]);
    // urlQuery["refNumber"] &&
    //   setValue("refNumber", urlQuery["refNumber"].toString());
    // urlQuery["collected"] !== undefined &&
    //   setValue("collected", urlQuery["collected"]);

    // setAutocompleteValues((prev) => ({
    //   ...prev,
    //   ...(urlQuery["statusCode"] && {
    //     statusCode: urlQuery["statusCode"].split(","),
    //   }),
    // }));


    // let recipientZoneId;
    // if (urlQuery["recipientZoneId"]) {
    //   if (urlQuery["recipientZoneId"].length > 1) {
    //     recipientZoneId = urlQuery["recipientZoneId"].split(",").map(ele => {
    //       return parseInt(ele)
    //     });
    //   } else {
    //     recipientZoneId = [urlQuery["recipientZoneId"]]
    //   }
    // }


    // let recipientSubzoneId;
    // if (urlQuery["recipientSubzoneId"]) {
    //   if (urlQuery["recipientSubzoneId"].length > 1) {
    //     recipientSubzoneId = urlQuery["recipientSubzoneId"].split(",").map(ele => {
    //       return parseInt(ele)
    //     });
    //   } else {
    //     recipientSubzoneId = [urlQuery["recipientSubzoneId"]]
    //   }
    // }

    // setAutocompleteValues((prev) => ({
    //   ...prev,
    //   ...(urlQuery["recipientZoneId"] && {
    //     recipientZone: recipientZoneId
    //   }),
    // }));

    // setAutocompleteValues((prev) => ({
    //   ...prev,
    //   ...(urlQuery["recipientSubzoneId"] && {
    //     recipientSubzone: recipientSubzoneId
    //   }),
    // }));

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();
  const parseData = (data) => {
    return data;
  };

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  // const inputSubzone = watch("recipientZoneId") ?? watch("recipientZoneId")

  return (
    <Root
      container
      item
      justifyContent="center"
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
      className={classes.searchForm}
      alignContent="flex-start"
    >
      <Grid
        container
        item
        sm={12}
        justifyContent="flex-start"
        spacing={1}
        className={classes.searchPadding}
      >
        <Grid container item sm={12} alignItems="flex-start">
          <MUItextField
            label={t("search")}
            name={"search"}
            inputProps={{
              dir: "ltr",
            }}
            onPaste={(e) => {
              e.preventDefault();
              const input = e.target;
              const inputValue = e.clipboardData
                .getData("Text")
                .replace(/\n/g, ",");

              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                "value"
              ).set;
              nativeInputValueSetter.call(input, input.value + inputValue);

              input.dispatchEvent(new Event("input", { bubbles: true }));
            }}
            onKeyPress={(e) => {
              const value = e.target.value.trim();
              if (value.slice(-1) !== ',' && value !== '') {
                if (e.key === "Enter") {
                  // e.preventDefault();
                  setValue("search", `${value},`);
                }
              }
            }}
            formType={"requireFalse"}
            register={register}
            errors={errors}
            InputProps={{
              endAdornment: Boolean(watch("search")) && (
                <InputAdornment position="end">
                  <IconButton
                    disabled={loading}
                    aria-label="code"
                    onClick={clearCode}
                    edge="end"
                    size="large"
                  >
                    <Clear color="error" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            watch={watch("search")}
          />
        </Grid>
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <Grid
        container
        item
        sm={12}
        justifyContent="flex-start"
        spacing={1}
        className={classes.searchField}
      >
        {watch("search") && <Grid item className={classes.overlay}></Grid>}
        <Grid container item sm={12} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"code"}
            label={t("code")}
          />
        </Grid>
        <Grid container item sm={12} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"name"}
            label={t("name")}
          />
        </Grid>
        {/* <Grid container item sm={12} alignItems="flex-start">
          <MUIDateRangeCustom
            value={dateRange}
            onChangeValue={(value) => setDateRange(value)}
          />
        </Grid> */}

        {/* <Can permission={removeField("dlvAtemp")} showException>
          <Grid container item sm={12} alignItems="flex-start">
            <MUIDateRangeCustom
              startText={t("lastTransactionDate")}
              value={lastTransactionDate}
              onChangeValue={(value) => setLastTransactionDate(value)}
              resetDate={resetLastTransactionDate}
            />
          </Grid>
        </Can> */}

        {/* <Can permission={allowField("dlvryBefore")} showException>
          <Grid container item sm={12} alignItems="flex-start">
            <MUIDate
              name="deliveryBefore"
              label={t("redeliveryDate")}
              control={control}
              value={deliveryBeforeData}
              onChange={(date) => setDeliveryBefore(date)}
              readOnly={false}
              onError={(resone, value) => {
                setError("deliveryBefore", { message: resone });
              }}
            />
          </Grid>
        </Can> */}
        {/* 
        <Can permission={removeField("status")} showException>
          <Grid container item sm={12} alignItems="flex-start">
            <MultipleAutocomplete
              name={"statusCode"}
              label={t("shipmentStatus")}
              control={control}
              errors={errors}
              inWarehouse={inWarehouse}
              variables={{ input: { code: "SHP_REQUEST_STATUS" } }}
              query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
              parseData={(data) =>
                statusDataHandler ? statusDataHandler(data) : parseData(data)
              }
              multiple
              valueKey="code"
              defaultValue={autocompleteValues.statusCode}
            />
          </Grid>
        </Can> */}
        {/* <Can permission="shipping.shipment.filter_by_branch" showException>
          {removeField("branch") && (
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                name={"branchId"}
                label={t("currentBranch")}
                control={control}
                errors={errors}
                parseData={(data) => parseData(data)}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["branchId"],
                    "branch"
                  )
                }
                query={LIST_BRANCHES_DROPDOWN.query}
                defaultValue={autocompleteValues.branch}
              />
            </Grid>
          )}
          {removeField("orgnBranch") && (
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                name={"originBranchId"}
                label={t("originBranch")}
                control={control}
                errors={errors}
                parseData={(data) => parseData(data)}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["originBranchId"],
                    "originBranch"
                  )
                }
                defaultValue={autocompleteValues.originBranch}
                query={LIST_BRANCHES_DROPDOWN.query}
              />
            </Grid>
          )}
        </Can> */}
        {/* {allowField("wareHouseSection") && (
          <Grid container item sm={12} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"sectionId"}
              label={t("wareHouse")}
              parseData={(data) => parseData(data)}
              query={LIST_WAREHOUSE_DROPDOWN.query}
              onCompleted={(data) =>
                handelAutocompleteDefaultValue(
                  data,
                  urlQuery["sectionId"],
                  "wareHouseSection"
                )
              }
              defaultValue={autocompleteValues.wareHouseSection}
            />
          </Grid>
        )} */}
        {/* <Grid container item sm={12} alignItems="flex-start">
          <CustomAutocomplete
            name={"serviceId"}
            label={t("service")}
            control={control}
            errors={errors}
            parseData={(data) => parseData(data)}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                urlQuery["serviceId"],
                "service"
              )
            }
            query={LIST_SHIPPING_SERVICES_DROPDOWN.query}
            defaultValue={autocompleteValues.service}
          />
        </Grid> */}

        {/* <Grid container item sm={12} alignItems="flex-start">
          <MultipleAutocomplete
            name={"recipientZoneId"}
            label={t("toZone")}
            control={control}
            errors={errors}
            variables={{ input: { parentId: null } }}
            query={LSIT_ZONES_DROPDOWN.query}
            parseData={(data) => parseData(data)}
            // onCompleted={(data) =>
            //   handelAutocompleteDefaultValue(
            //     data,
            //     urlQuery["recipientZoneId"],
            //     "recipientZone"
            //   )
            // }
            multiple
            valueKey="id"
            defaultValue={autocompleteValues.recipientZone}
            onChangeValue={(e) => {
              setValue("recipientSubzoneId", "");
            }}
          />
        </Grid> */}
        {/* <Grid container item sm={12} alignItems="flex-start">
          <MultipleAutocomplete
            name={"recipientSubzoneId"}
            label={t("toSubzone")}
            control={control}
            errors={errors}
            variables={{ input: { parentId: inputSubzone && inputSubzone[0] } }}
            query={LSIT_ZONES_DROPDOWN.query}
            parseData={(data) => parseData(data)}
            // onCompleted={(data) =>
            //   handelAutocompleteDefaultValue(
            //     data,
            //     urlQuery["recipientZoneId"],
            //     "recipientZone"
            //   )
            // }
            skip={!watch("recipientZoneId")}
            disabled={(!watch("recipientZoneId") || watch("recipientZoneId").length > 1 || watch("recipientZoneId").length === 0)}
            multiple
            valueKey="id"
            defaultValue={autocompleteValues.recipientSubzone}
          />
        </Grid> */}

        {/* <Grid container item sm={12} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"recipientSubzoneId"}
            label={t("toSubzone")}
            parseData={(data) => parseData(data)}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                urlQuery["recipientSubzoneId"],
                "recipientSubzone"
              )
            }
            query={LSIT_ZONES_DROPDOWN.query}
            variables={{ input: { parentId: watch("recipientZoneId") } }}
            skip={!watch("recipientZoneId")}
            disabled={!watch("recipientZoneId")}
            defaultValue={autocompleteValues.recipientSubzone}
          />
        </Grid> */}

        {/* <Grid container item sm={12} alignItems="flex-start">
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"recipientZoneId"}
            label={t("toZone")}
            parseData={(data) => parseData(data)}
            onCompleted={(data) =>
              handelAutocompleteDefaultValue(
                data,
                urlQuery["recipientZoneId"],
                "recipientZone"
              )
            }
            variables={{ input: { parentId: null } }}
            query={LSIT_ZONES_DROPDOWN.query}
            defaultValue={autocompleteValues.recipientZone}
            onChangeValue={(e) => {
              setValue("recipientSubzoneId", "");
            }}
          />
        </Grid> */}

        {/* <Can permission={removeField("dlvType")} showException>
          <Grid container item sm={12} alignItems="flex-start">
            <LookupDropdown
              name={"deliveryTypeCode"}
              label={t("deliveryType")}
              control={control}
              errors={errors}
              variables={{ input: { code: "SHP_DELIVERY_TYPE" } }}
              onCompleted={(data) => lookupComplete(data, "deliveryTypeCode")}
            />
          </Grid>
        </Can> */}

        {removeField("custm") && (
          <Can showException permission="shipping.shipment.filter_by_customer">
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                name={"customerId"}
                label={t("customer")}
                control={control}
                errors={errors}
                parseData={(data) => parseData(data)}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery["customerId"],
                    "customer"
                  )
                }
                query={LIST_CUSTOMERS_DROPDOWN.query}
                variables={{
                  input: {
                    active: true,
                    typeCode: ["MERCHANT"],
                  },
                }}
                defaultValue={autocompleteValues.customer}
              />
            </Grid>
          </Can>
        )}

        {/* {removeField("dlvAgent") && (
          <Can
            showException
            permission="shipping.shipment.filter_by_delivery_agent"
          >
            <Grid container item sm={12} alignItems="flex-start">
              <CustomAutocomplete
                name={"lastDeliveryAgentId"}
                label={t("shippingAgent")}
                control={control}
                errors={errors}
                parseData={(data) => parseData(data)}
                onCompleted={(data) =>
                  handelAutocompleteDefaultValue(
                    data,
                    urlQuery[
                    `${custody
                      ? "inDeliveryAgentCustody"
                      : "lastDeliveryAgentId"
                    }`
                    ],
                    "deliveryAgent"
                  )
                }
                query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
                variables={{
                  input: {
                    active: true,
                  },
                }}
                defaultValue={autocompleteValues.deliveryAgent}
              />
            </Grid>
          </Can>
        )} */}

        {/* <Can permission={removeField("rtnType")} showException>
          <Grid container item sm={12} alignItems="flex-start">
            <LookupDropdown
              name={"returnTypeCode"}
              label={t("returnType")}
              control={control}
              errors={errors}
              variables={{ input: { code: "SHP_RETURNING_TYPE" } }}
              onCompleted={(data) => lookupComplete(data, "returnTypeCode")}
            />
          </Grid>
        </Can> */}

        {/* <Grid container item sm={12} alignItems="flex-start">
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"refNumber"}
            label={t("refNumber")}
            onPaste={(e) => {
              e.preventDefault();
              const input = e.target;
              const inputValue = e.clipboardData
                .getData("Text")
                .replace(/\n/g, ",");

              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                "value"
              ).set;
              nativeInputValueSetter.call(input, input.value + inputValue);

              input.dispatchEvent(new Event("input", { bubbles: true }));
            }}
          />
        </Grid> */}
        {/* <Grid container item sm={12} alignItems="flex-start">
          <LookupDropdown
            name={"typeCode"}
            label={t("packageType")}
            control={control}
            errors={errors}
            variables={{ input: { code: "SHP_REQUEST_TYPE" } }}
            onCompleted={(data) => lookupComplete(data, "typeCode")}
          />
        </Grid>
        <Can permission={removeField("payType")} showException>
          <Grid container item sm={12} alignItems="flex-start">
            <LookupDropdown
              name={"paymentTypeCode"}
              label={t("paymentType")}
              control={control}
              errors={errors}
              variables={{ input: { code: "SHP_PAYMENT_TYPE" } }}
              onCompleted={(data) => lookupComplete(data, "paymentTypeCode")}
            />
          </Grid>
        </Can>
        <Can permission={allowField("collect")} showException>
          <Grid container item sm={12} alignItems="flex-start">
            <MUIselect
              name={"collected"}
              label={t("collection")}
              control={control}
              errors={errors}
              data={[
                { key: t("notCollected"), value: false },
                { key: t("collected"), value: true },
              ]}
            />
          </Grid>
        </Can> */}
      </Grid>
      <Grid
        container
        item
        sm={12}
        className={clsx(classes.searchPadding, classes.button)}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          className={clsx(classes.btnMargin)}
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          startIcon={<Search />}
          disabled={loading}
        >
          {t("search")}
        </Button>
      </Grid>
    </Root>
  );
};

export default memo(
  FiltersProductForm,
  (prevProps, nextProps) =>
    prevProps.loading === nextProps.loading &&
    prevProps.rowsPerPage === nextProps.rowsPerPage
);
