import React, { Fragment, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { MdOutlineDone } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import EmptyTableMessage from '../../HOC/FunctionComponents/EmptyTableMessage';

export const batchFields = [
    "id",
    { barcodes: ["id", "code"] },
    {
        customer: [
            "code",
            {
                operation: `isMobileHidden(code: WAYBILL)`,
                fields: [],
                variables: {
                },
            },],
    },
];

export const shipmentFields = [
    {
        data: [
            "code",
            "recipientName",
            "recipientAddress",
            "recipientMobile",
            "recipientPhone",
            "description",
            "weight",
            "piecesCount",
            "totalAmount",
            "senderName",
            "senderPhone",
            "senderMobile",
            "refNumber",
            "notes",
            "date",
            {
                type: ["name", "code"],
            },
            {
                originBranch: ["name"],
            },
            {
                customer: [
                    "code",
                    {
                        operation: `isMobileHidden(code: WAYBILL)`,
                        fields: [],
                        variables: {
                        },
                    },
                ],
            },
            {
                openable: ["code"],
            },

            {
                recipientZone: ["name"],
            },
            {
                recipientSubzone: ["name"],
            },
            {
                senderZone: ["name"],
            },
            {
                senderSubzone: ["name"],
            },
        ],
    },
];

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
    flex: `${PREFIX}-flex`,
    printSticky: `${PREFIX}-printSticky`,
    stickyCode: `${PREFIX}-stickyCode`,
    centerContent: `${PREFIX}-centerContent`,
    barcode: `${PREFIX}-barcode`,
    line: `${PREFIX}-line`,
    lineDots: `${PREFIX}-lineDots`,
    tableData: `${PREFIX}-tableData`,
    width50: `${PREFIX}-width50`,
    borderRight: `${PREFIX}-borderRight`,
    padding3: `${PREFIX}-padding3`,
    padding1: `${PREFIX}-padding1`,
    hideSenderPhone: `${PREFIX}-hideSenderPhone`,
    notes: `${PREFIX}-notes`,
    recipient: `${PREFIX}-recipient`,
    recipientAddress: `${PREFIX}-recipientAddress`,
    recipientHeight: `${PREFIX}-recipientHeight`,
    senderHeight: `${PREFIX}-senderHeight`,

};
export const Root = styled("div")(({ theme }) => ({
    [`& .${classes.hideSenderPhone}`]: {
        display: "none !important",
    },
    [`& .${classes.printSticky}`]: {
        pageBreakAfter: "always",
        pageBreakInside: "avoid",
        maxWidth: "7cm",
        maxHeight: "9cm",
        overflow: "hidden",
        fontSize: 11,
        color: "black",
    },
    [`& .${classes.line}`]: {
        borderBottom: "1px solid #0000002b",
    },
    [`& .${classes.lineDots}`]: {
        borderBottom: "1px dotted #0000002b",
    },
    [`& .${classes.borderRight}`]: {
        borderRight: "1px solid #0000002b",
    },
    [`& .${classes.flex}`]: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
    },
    [`& .${classes.width50}`]: {
        width: "50%"
    },
    [`& .${classes.stickyCode}`]: {
        height: "calc(100% * (1/4))",
        overflow: "hidden",
        // fontSize: "30px !important",
    },
    [`& .${classes.tableData}`]: {
        width: "calc(100% * (1/4))",
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        textAlign: "center",
        padding: "2px 0",
        height: "31px",
        justifyContent: 'space-between'
    },
    [`& .${classes.padding3}`]: {
        padding: "3px 0"
    },
    [`& .${classes.padding1}`]: {
        padding: "1px 0"
    },

    [`& .${classes.stickyDestination}`]: {
        height: "calc(100% * (0.75/4))",
        overflow: "hidden",
        whiteSpace: "pre",
    },

    [`& .${classes.centerContent}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    [`& .${classes.barcode}`]: {
        fontFamily: "'Libre Barcode 39 Text'",
        fontSize: 48,
    },
    [`& .${classes.notes}`]: {
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        display: "-webkit-box !important",
        paddingBottom: "2px",
        lineHeight: 1,
        minHeight: "25px",
    },
    [`& .${classes.recipient}`]: {
        maxHeight: "90px",
        overflow: "hidden",
    },
    [`& .${classes.recipientAddress}`]: {
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        display: "-webkit-box !important"
    },
    [`& .${classes.recipientHeight}`]: {
        minHeight: "80px",
    },
    [`& .${classes.senderHeight}`]: {
        minHeight: "45px",
    },
}));

function createDynamicStyles() {
    const style = document.createElement('style');
    style.type = 'text/css';

    const styles = `
    @page {
        size: 7cm 9cm; 
        margin: 1mm !important;
        overflow: hidden;
    }
    * {
            color: black;
    }
    body {
            background: #fff !important
    }
    `;

    if (style.styleSheet) {
        style.styleSheet.cssText = styles;
    } else {
        style.appendChild(document.createTextNode(styles));
    }

    document.head.appendChild(style);
}

const Template7X9 = (props) => {
    const {
        loading,
        validData,
        settingsLoad,
        parsedData,
        isBatch,
        footer,
        currency,
        LogoImg
    } = props

    const { t } = useTranslation()

    useEffect(() => {
        createDynamicStyles()

    }, [])

    return (
        <Fragment>
            {loading || (!loading && !validData) || settingsLoad ? (
                <>
                    <EmptyTableMessage
                        loading={loading}
                        message={t("noShipmentWithCode")}
                    />

                    {/* <FullScreenLoading minHeight="25%" /> */}
                </>
            ) : (
                parsedData.map((shipment, index) => {
                    const rtsShipment = shipment?.type?.code === "RTS";
                    return (
                        <Root key={index}>
                            <div
                                className={clsx({
                                    [classes.printSticky]: !loading && validData,
                                })}
                                style={{ height: "100%", lineHeight: "1.2" }}
                            >
                                <div className={clsx(
                                    classes.flex,
                                    classes.line
                                )}>
                                    <div className={clsx(classes.width50)}>
                                        <LogoImg forceLight={true} className={classes.logoImg} />
                                    </div>
                                    <div className={clsx(classes.width50, classes.flex)}>
                                        <div className={clsx(classes.padding3)} style={{
                                            width: "auto",
                                        }}>{shipment?.type?.name}</div>
                                    </div>
                                </div>
                                <div
                                    className={clsx(
                                        classes.barcode,
                                        classes.centerContent,
                                        classes.stickyCode,
                                        classes.line
                                    )}
                                >
                                    *{shipment.code}*
                                </div>
                                <div className={clsx(
                                    classes.flex,
                                    classes.line,
                                )}>
                                    <div className={clsx(classes.tableData, classes.borderRight)}>
                                        <Box component="span" >
                                            {t("weight")}
                                        </Box>
                                        <div>{shipment?.weight}</div>
                                    </div>
                                    <div className={clsx(classes.tableData, classes.borderRight)}>
                                        <Box component="span" >
                                            {t("collection")}
                                        </Box>
                                        <div>
                                            <Box component="span">
                                                {rtsShipment ? 0 : shipment?.totalAmount}
                                            </Box>
                                            <Box component="span" pl="2px">
                                                {!isBatch && currency}
                                            </Box>
                                        </div>
                                    </div>
                                    <div className={clsx(classes.tableData, classes.borderRight)}>
                                        <Box component="span">
                                            {t("open")}
                                        </Box>
                                        <div>
                                            {!isBatch && (shipment?.openable?.code === "Y" ? (<MdOutlineDone />) : (<AiOutlineClose />))}
                                            {/* <CheckCrossIcons
                        active={shipment?.openable?.code === "Y"}
                      /> */}
                                        </div>
                                    </div>
                                    <div className={clsx(classes.tableData)}>
                                        <Box component="span" >
                                            {t("piece")}
                                        </Box>
                                        <div>{shipment?.piecesCount}</div>
                                    </div>
                                </div>
                                <div className={clsx(
                                    classes.line,
                                    classes.padding3,
                                    classes.recipient,
                                    isBatch && classes.recipientHeight
                                )}>
                                    <div><span style={{ fontWeight: "bolder" }}>{t("recipient") + ": "}</span>{shipment?.recipientName}</div>
                                    <div
                                        className={clsx(classes.padding1)}
                                    >
                                        {isBatch ? ' ' : (shipment?.recipientMobile &&
                                            shipment?.recipientPhone
                                            ? `${shipment?.recipientMobile} - ${shipment?.recipientPhone}`
                                            : shipment?.recipientMobile ??
                                            shipment?.recipientPhone)}
                                    </div>
                                    <div
                                        className={clsx(classes.padding1, classes.recipientAddress)}
                                    >
                                        {shipment?.recipientAddress}
                                    </div>
                                    <div
                                        className={clsx(classes.padding1
                                        )}
                                    >
                                        {!isBatch && (shipment?.recipientZone?.name +
                                            " - " +
                                            shipment?.recipientSubzone?.name)}
                                    </div>
                                </div>
                                <div className={clsx(
                                    classes.line,
                                    classes.padding3,
                                    isBatch && classes.senderHeight
                                )}>
                                    <div><span style={{ fontWeight: "bolder" }}>{t("sender") + ": "}</span> {shipment?.senderName}</div>
                                    <div
                                        className={clsx(classes.padding1, {
                                            [classes.hideSenderPhone]: shipment?.customer?.isMobileHidden,
                                        }
                                        )}
                                    >
                                        {shipment?.senderMobile && shipment?.senderPhone
                                            ? `${shipment?.senderMobile} - ${shipment?.senderPhone}`
                                            : shipment?.senderMobile ??
                                            shipment?.senderPhone}
                                    </div>
                                    <div
                                        className={clsx(classes.padding1
                                        )}
                                    >
                                        {!isBatch && (shipment?.senderZone?.name +
                                            " - " +
                                            shipment?.senderSubzone?.name)}
                                    </div>
                                </div>

                                <div className={clsx(
                                    classes.lineDots,
                                    classes.padding3
                                )}>
                                    <div><span style={{ fontWeight: "bolder" }}>{t("refNumber") + ": "}</span>{shipment?.refNumber}</div>
                                </div>
                                <div className={clsx(
                                    classes.line,
                                    classes.notes,
                                )}>
                                    <Box component="span" pt="1px">
                                        <span style={{ fontWeight: "bolder" }}>{t("notes") + ": "}</span>{shipment?.notes}
                                    </Box>
                                </div>
                                <Box component="span"
                                    sx={{ display: "flex", justifyContent: 'center' }}>
                                    <Box component="span" pl="2px">
                                        {footer}
                                    </Box>
                                </Box>
                            </div>
                        </Root>
                    )
                })
            )}
        </Fragment>
    )
}

export default Template7X9