import React from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import {
  Paper,
  Switch,
  FormControlLabel,
  Collapse,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { DELIVERY_AGENT_ID, SAVE_DELIVERY_AGENT, SETTINGS } from "./Graphql";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import LookupDropdown from "../HOC/CustomComponents/LookupDropdown";
import { useMutation, gql, useQuery } from "@apollo/client";
import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import CommissionListForm from "../CommissionList/CommissionListForm";
import { AddCircleOutline } from "@mui/icons-material";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import {
  LIST_AREAS_DROPDOWN,
  LIST_CITIES_DROPDOWN,
  LIST_COMMISSION_LSIT_DROPDOWN,
  LIST_COUNTRIES_DROPDOWN,
  LIST_GL_ACCOUNTS_DROPDOWN,
  LIST_ROUTES_DROPDOWN,
  LIST_STATES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { Can } from "../HOC/CustomComponents/Secured";
import Grid from "@mui/material/Unstable_Grid2";
import { selectedIdIsValid } from "../HOC/CustomFunctions/selectedIsValid";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import getMobileData, { getFormatNumber, validNumber } from "../../helpers/asYouType";
import MuiPhone from "../HOC/MUI/MUIphoneNumber";
import { Globals } from "../HOC/Classes/Globals";
import MUIselect from "../HOC/MUI/MUIselect";
const PREFIX = 'DeliveryAgent';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  commissionList: `${PREFIX}-commissionList`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  },

  [`& .${classes.commissionList}`]: {
    flexWrap: "nowrap",
  }
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));
const valueMap = {
  null: "",
  false: "f",
  true: "t",
  f: false,
  t: true,
};
const DeliveryAgent = (props) => {

  const [commissionDialogState, setCommissionDialogState] = useState(false);
  const [autocompleteValues, setAutocompleteValues] = useState({
    commissionList: null,
    custodyAccount: null,
    glAccount: null,
    branch: null,
    route: null,
    country: null,
    state: null,
    city: null,
    area: null,
  });

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
  });
  const { errors } = formState;
  const countryCode = Globals.settings.countryCode
  const { data: settings } = useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
    }
  );

  const selectDefaultValue = (data) => {
    return data;
  };

  const [saveDelivaryAgent, { loading: saveDelivaryAgentLoading }] =
    useMutation(
      gql`
        ${SAVE_DELIVERY_AGENT.query}
      `
    );

  //update
  const deliveryAgentId = parseInt(props.match.params.id);

  const { data: updateDeliveryAgent, loading: DelivaryAgentLoadingId } = useQuery(
    gql`
      ${DELIVERY_AGENT_ID.query}
    `,

    {
      skip: !deliveryAgentId,
      variables: { id: deliveryAgentId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const deliveryAgentData = data.deliveryAgent;
        setActiveUser(deliveryAgentData.active);
        setAutoPayCommission(deliveryAgentData.autoPayCommission);
        const deliveryAgentParams = [
          "id",
          "code",
          "name",
          "commission",
          "address",
          "postalCode",
          "mailBox",
          "email",
        ];
        // number quey conditions
        const numbersObj = [
          {
            name: "mobile",
            codeName: "mobileCode",
            value: getMobileData(deliveryAgentData.mobile, countryCode)
          },
          {
            name: "phone",
            codeName: "phoneCode",
            value: getMobileData(deliveryAgentData.phone, countryCode)
          },
        ]
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        deliveryAgentParams.forEach((i) => {
          (deliveryAgentData[i] || deliveryAgentData[i] === 0) &&
            setValue(i, deliveryAgentData[i]);
        });
        setValue("pickupCommission", valueMap[deliveryAgentData.pickupCommission]);
        setValue("active", deliveryAgentData["active"]);
        setValue("autoPayCommission", deliveryAgentData["autoPayCommission"]);

        setAutocompleteValues({
          area: deliveryAgentData?.area,
          branch: deliveryAgentData?.branch,
          city: deliveryAgentData?.city,
          country: deliveryAgentData?.country,
          custodyAccount: deliveryAgentData?.custodyAccount,
          glAccount: deliveryAgentData?.glAccount,
          route: deliveryAgentData.route,
          state: deliveryAgentData?.state,
          commissionList: deliveryAgentData?.commissionList,
        });
      },
    }
  );
  const [activeUser, setActiveUser] = useState(true);
  const [autoPayCommission, setAutoPayCommission] = useState(false);

  const deliveryAgentData = updateDeliveryAgent?.deliveryAgent;

  const handelActivate = (e) => {
    const active = e.target.checked;
    setActiveUser(active);
  };
  const handelAutoPayCommission = (e) => {
    const autoPayCommission = e.target.checked;
    setAutoPayCommission(autoPayCommission);
  };

  const lookupsComplete = (data, updateValue, name) => {
    if (updateValue && lookupCodeIsValid(data, updateValue)) {
      setValue(name, updateValue);
    }
  };

  const onGlAccountComplete = (data, fieldName) => {
    const currentValue = watch(fieldName);

    const isValueNotValid = !selectedIdIsValid(data, currentValue);
    if (isValueNotValid && currentValue) {
      setValue(fieldName, "");
      // setAutocompleteValues((prev) => ({
      //   ...prev,
      //   [fieldName]: null,
      // }));
    }
  };

  const closeCommissionDialog = () => {
    setCommissionDialogState(false);
  };

  const onSubmit = (data) => {
    // mobile on save
    if (Globals.settings.allowPhoneKey) {
      const checkMobiles = validNumber(data.mobile, data.mobileCode, "notRequired")
      const checkPhone = validNumber(data.phone, data.phoneCode, "notRequired")
      let names = [
        {
          name: "mobile",
          validate: checkMobiles.valid,
          message: checkMobiles.message,
        },
        {
          name: "phone",
          validate: checkPhone.valid,
          message: checkPhone.message,
        },
      ];
      if (!checkMobiles.valid || !checkPhone.valid) {
        names.map((e) => !e.validate && setError(e.name, { type: 'custom', message: t(e.message) }))
        return
      }
      data.mobile = data.mobile && getFormatNumber(data.mobile, data.mobileCode)
      data.phone = data.phone && getFormatNumber(data.phone, data.phoneCode)
    }
    //  end
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
      if (
        key === "phoneCode" ||
        key === "mobileCode"
      ) {
        delete data[key];
      }
    }
    saveDelivaryAgent({
      variables: {
        input: {
          ...data,
          ...(data?.commission && { commission: parseFloat(data?.commission) }),
          pickupCommission: data.pickupCommission ? valueMap[data.pickupCommission] : null,
          active: activeUser,
          autoPayCommission: autoPayCommission,
        },
      },
    })
      .then((data) => {
        pushUrl(
          props,
          `/admin/delivery-agents/${data?.data?.saveDeliveryAgent?.id}`
        );
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
      });
  };
  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid
            justifyContent="end"
            sx={{ display: "flex", flexWrap: "wrap" }}
            xs={12}
          >
            {/* <Typography variant="h6">{t("shippingAgent")}</Typography> */}
            <Box>
              <FormControlLabel
                checked={activeUser}
                control={<Switch color="primary" />}
                label={t("active")}
                labelPlacement="start"
                onChange={handelActivate}
              />
              <FormControlLabel
                checked={autoPayCommission}
                control={<Switch color="primary" />}
                label={t("autoPayCommission")}
                labelPlacement="start"
                onChange={handelAutoPayCommission}
              />
            </Box>
          </Grid>
          <Grid sm={2} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("code")}
            />
          </Grid>

          <Grid sm={4} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"name"}
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>

          <Grid sm={6} xs={12} alignItems="flex-start">
            <ListBranches
              label={t("branch")}
              control={control}
              errors={errors}
              name={"branchId"}
              defaultValue={autocompleteValues.branch}
              skipDefaultBranch={deliveryAgentId}
            />
          </Grid>

          <Grid sm={6} xs={12} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"glAccountId"}
              label={t("glAccount")}
              parseData={(data) => selectDefaultValue(data)}
              onCompleted={(data) => {
                onGlAccountComplete(data, "glAccountId");
              }}
              query={LIST_GL_ACCOUNTS_DROPDOWN.query}
              variables={{
                input: {
                  typeCode: "SUB",
                  ...(watch("branchId") && {
                    branchId: { value: watch("branchId"), includeNull: true },
                  }),
                  parentId:
                    settings?.shippingSettings?.mainDeliveryAgentGLAccount?.id,
                },
              }}
              defaultValue={autocompleteValues.glAccount}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"custodyAccountId"}
              label={t("custodyAccount")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_GL_ACCOUNTS_DROPDOWN.query}
              onCompleted={(data) => {
                onGlAccountComplete(data, "custodyAccountId");
              }}
              variables={{
                input: {
                  typeCode: "SUB",
                  ...(watch("branchId") && {
                    branchId: { value: watch("branchId"), includeNull: true },
                  }),
                  parentId:
                    settings?.shippingSettings?.mainCustodyGLAccount?.id,
                },
              }}
              defaultValue={autocompleteValues.custodyAccount}
            />
          </Grid>

          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"routeId"}
              label={t("route")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_ROUTES_DROPDOWN.query}
              defaultValue={autocompleteValues.route}
            />
          </Grid>
          <Grid
            sm={6}
            xs={12}
            md={3}
            alignItems="flex-start"
            className={classes.commissionList}
            sx={{ display: "flex" }}
          >
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"commissionListId"}
              label={t("commissionList")}
              rules={{
                // ...(!watch("commission") && {
                //   required: t("fieldIsRequired"),
                // }),
                validate: {
                  max: (value) =>
                    !watch("commission") &&
                      parseInt(watch("commission")) !== 0 &&
                      !value
                      ? t("fieldIsRequired")
                      : true,
                },
              }}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_COMMISSION_LSIT_DROPDOWN.query}
              defaultValue={autocompleteValues.commissionList}
            />
            <Can showException permission="shipping.commission_list.create">
              <IconButton
                onClick={() => setCommissionDialogState(true)}
                size="large"
              >
                <AddCircleOutline />
              </IconButton>
            </Can>
          </Grid>

          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <LookupDropdown
              control={control}
              errors={errors}
              name={"commissionTypeCode"}
              label={t("commissionType")}
              variables={{
                input: { code: "SHIPPING_COMMISION_TYPE" },
              }}
              rules={{
                validate: {
                  max: (value) =>
                    (watch("commission") ||
                      parseInt(watch("commission")) === 0) &&
                      !value
                      ? t("fieldIsRequired")
                      : true,
                },
              }}
              onCompleted={(data) =>
                lookupsComplete(
                  data,
                  deliveryAgentData?.commissionType?.code,
                  "commissionTypeCode"
                )
              }
            />
          </Grid>
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              rules={{
                // ...(!watch("commissionListId") && {
                //   required: t("fieldIsRequired"),
                // }),
                validate: {
                  max: (value) =>
                    !watch("commissionListId") &&
                      !value &&
                      parseFloat(value) !== 0
                      ? t("fieldIsRequired")
                      : true,
                },
              }}
              name={"commission"}
              label={t("commission")}
              type="number"
            />
          </Grid>
          <Grid xs={12} md={6} alignItems="flex-start">
            <MUIselect
              name={"pickupCommission"}
              label={t("pickupCommission")}
              control={control}
              errors={errors}
              data={[
                { key: t("yes"), value: "t" },
                { key: t("no"), value: "f" },
              ]}
            />
          </Grid>
        </Paper>

        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"countryId"}
              label={t("country")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_COUNTRIES_DROPDOWN.query}
              onChangeValue={(e) => {
                setValue("stateId", "");
                setValue("cityId", "");
                setValue("areaId", "");
              }}
              defaultValue={autocompleteValues.country}
            />
          </Grid>
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={!watch("countryId")}
              skip={!watch("countryId")}
              name={"stateId"}
              label={t("state")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_STATES_DROPDOWN.query}
              variables={{
                countryId: watch("countryId"),
              }}
              onChangeValue={(e) => {
                setValue("cityId", "");
                setValue("areaId", "");
              }}
              defaultValue={autocompleteValues.state}
            />
          </Grid>
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={!watch("stateId")}
              skip={!watch("stateId")}
              name={"cityId"}
              label={t("city")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_CITIES_DROPDOWN.query}
              variables={{
                stateId: watch("stateId"),
              }}
              onChangeValue={(e) => {
                setValue("areaId", "");
              }}
              defaultValue={autocompleteValues.city}
            />
          </Grid>
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={!watch("cityId")}
              skip={!watch("cityId")}
              name={"areaId"}
              label={t("area")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_AREAS_DROPDOWN.query}
              variables={{
                cityId: watch("cityId"),
              }}
              defaultValue={autocompleteValues.area}
            />
          </Grid>
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <MuiPhone
              setValue={setValue}
              codeValue={getValues().phoneCode}
              control={control}
              errors={errors}
              name={"phone"}
              selectName={"phoneCode"}
              label={t("phone")}
              onBlur={(e) => {
                if (Boolean(!getValues().mobile)) {
                  setValue("mobile", getValues().phone, {
                    shouldDirty: true,
                  });
                  setValue("mobileCode", getValues().phoneCode.toLowerCase(), {
                    shouldDirty: true,
                  });
                }
              }}
            />
          </Grid>
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <MuiPhone
              setValue={setValue}
              codeValue={watch("mobileCode")}
              control={control}
              errors={errors}
              name={"mobile"}
              selectName={"mobileCode"}
              label={t("mobile")}
            />
          </Grid>
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"mailBox"}
              label={t("mailBox")}
            />
          </Grid>
          <Grid sm={6} xs={12} md={3} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"postalCode"}
              label={t("postalCode")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"address"}
              label={t("address")}
            />
          </Grid>
          <Grid sm={6} xs={12} alignItems="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"email"}
              label={t("email")}
              rules={{
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t("emailIsInvalid"),
                },
              }}
            />
          </Grid>
        </Paper>

        <Grid container justifyContent="flex-end" className={classes.spacing}>
          <FormButton disabled={saveDelivaryAgentLoading}>
            {saveDelivaryAgentLoading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </form>
  );

  let DOM;

  if (deliveryAgentId) {
    DOM = updateDeliveryAgent ? body : <FullScreenLoading minHeight="10%" />;
  } else {
    DOM = body;
  }

  return DelivaryAgentLoadingId ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !updateDeliveryAgent && deliveryAgentId ? (
      <NotFound />
    ) : (
      <Root>
        <TitleAppBar path={props.match.path} />

        <CustomDialog
          open={commissionDialogState}
          onClose={closeCommissionDialog}
          fullWidth
          maxWidth="md"
          content={
            <CommissionListForm
              key={Math.random()}
              deliveryAgentName={
                watch("name") && t("commissionList") + " " + watch("name")
              }
              onSave={(data) => {
                const commission = data.data.saveCommissionList;
                setAutocompleteValues((prev) => ({
                  ...prev,
                  commissionList: {
                    id: commission.id,
                    name: commission.name,
                  },
                }));
                closeCommissionDialog();
              }}
            />
          }
          actions={<Button onClick={closeCommissionDialog}>{t("cancel")}</Button>}
        />
        {DOM}
      </Root>
    );
};

export default DeliveryAgent;
