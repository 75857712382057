import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from "react-i18next";
import {
  Collapse,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { gql, useMutation, useQuery } from '@apollo/client';
import { CURRENCY_ID, SAVE_CURRENCY } from './Graphql';
import { useForm } from 'react-hook-form';
import { styled } from "@mui/material/styles";
import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import FormButton from '../CustomComponents/Buttons/FormButton';
import ButtonLoading from '../HOC/FunctionComponents/LoadingPages/ButtonLoading';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { setValidationError } from '../HOC/CustomFunctions/setValidationError';
import { LIST_LOOKUP_ENTRIES_DROPDOWN } from '../../GlobalsQuery/ListDropdown/ListDropdown';
import { CustomAutocomplete } from '../HOC/MUI/CustomAutocomplete';
import FullScreenLoading from '../HOC/FunctionComponents/LoadingPages/FullScreenLoading';
import MuiSwitch from '../HOC/MUI/MUIswitch';


const PREFIX = "BranchList";

const classes = {
  mainGrid: `${PREFIX}-mainGrid`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  [`& .${classes.button}`]: {
    width: "100%",
    paddingRight: 0,
  }

}));

function CurrencyDialog(props) {
  const { id } = props;
  const {
    handleSubmit,
    setValue,
    control,
    setError,
    errors,
  } = useForm({
    defaultValues: {
      active: true,
      local: false,
    },
  });
  const { t } = useTranslation();

  const [autocompleteValues, setAutocompleteValues] = React.useState({
    genderCurrency: null,
    fractionGender: null,
  });

  const [disabledLocal, setDisabledLocal] = React.useState(false)

  const { loading } = useQuery(
    gql`
      ${CURRENCY_ID.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !id,
      variables: {
        id: parseInt(id),
      },
      onCompleted: (data) => {
        const currencyData = data.currency;
        const currencyParams = [
          "id",
          "code",
          "name",
          "pluralName",
          "fractionName",
          "coupleName",
          "fractionCoupleName",
          "fractionPluralName",
        ];
        currencyParams.forEach((i) => {
          currencyData[i] && setValue(i, currencyData[i]);
        });

        setValue("active", currencyData["active"]);
        setValue("local", currencyData["local"]);
        setValue("fractionParts", currencyData["fractionParts"]);
        setDisabledLocal(!currencyData.active)
        setAutocompleteValues({
          genderCurrency: currencyData?.gender,
          fractionGender: currencyData?.fractionGender,
        });
      }
    },
  );
  const { enqueueSnackbar } = useSnackbar();

  const [saveCurrency, { loading: saveCurrencyLoading }] = useMutation(
    gql`
      ${SAVE_CURRENCY.query}
    `
  );

  const onSubmit = (data) => {
    saveCurrency({
      variables: {
        input: {
          ...data,
          fractionParts: parseFloat(data.fractionParts)
        },
      },
    })
      .then((data) => {
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        props.handleClose()
        props.refetch()
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  const parseData = (data) => {
    return data;
  };

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      maxWidth={'xs'}
      fullWidth={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>

        <DialogTitle id="scroll-dialog-title">
          <Grid container justifyContent="space-between" xs={12}>
            <Typography variant="h6" color={"text.primary"} >
              {id ? t("updateCurrency") : t("createCurrency")}
            </Typography>
            <Stack direction={"row"} alignItems={"center"}>
              <Stack>
                <MuiSwitch
                  edge="end"
                  name="active"
                  label={t("active")}
                  control={control}
                  onChange={
                    (e) => {
                      if (e.target.checked) {
                        setDisabledLocal(false)
                      } else {
                        setDisabledLocal(true)
                        setValue("local", false)
                      }
                    }
                  }
                />
              </Stack>
              <Stack>
                <MuiSwitch
                  edge="end"
                  name="local"
                  label={t("local")}
                  disabled={disabledLocal}
                  control={control}
                />
              </Stack>
            </Stack>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Root>
            {loading ? <FullScreenLoading minHeight="100px" /> :
              <Grid
                container
                justifyContent="flex-start"
                aligns="center"
                className={clsx(classes.mainGrid)}
                spacing={2}
              >
                <Grid xs={6} aligns="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"code"}
                    label={t("code")}
                    rules={{ required: t("fieldIsRequired") }}
                  />
                </Grid>
                <Grid xs={6} aligns="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"fractionParts"}
                    label={t("fractionParts")}
                    type={"number"}
                    rules={{
                      required: t("fieldIsRequired"),
                      min: {
                        value: 0,
                        message: t("validation:min", {
                          field: t("width"),
                          number: "0",
                        }),
                      },
                    }}
                  />
                </Grid>
                <Grid xs={6} aligns="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"name"}
                    label={t("currencyName")}
                    rules={{ required: t("fieldIsRequired") }}
                  />
                </Grid>
                <Grid xs={6} aligns="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"fractionName"}
                    label={t("fractionName")}
                    rules={{ required: t("fieldIsRequired") }}
                  />
                </Grid>
                <Grid xs={6} aligns="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"pluralName"}
                    label={t("pluralName")}
                    rules={{ required: t("fieldIsRequired") }}
                  />
                </Grid>
                <Grid xs={6} aligns="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"fractionPluralName"}
                    label={t("fractionPluralName")}
                    rules={{ required: t("fieldIsRequired") }}
                  />
                </Grid>
                <Grid xs={6} aligns="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"coupleName"}
                    label={t("coupleName")}
                    rules={{ required: t("fieldIsRequired") }}
                  />
                </Grid>
                <Grid xs={6} aligns="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"fractionCoupleName"}
                    label={t("fractionCoupleName")}
                    rules={{ required: t("fieldIsRequired") }}
                  />
                </Grid>
                <Grid xs={6} alignItems="flex-start">
                  <CustomAutocomplete
                    valueKey="code"
                    control={control}
                    errors={errors}
                    rules={{ required: t("fieldIsRequired") }}
                    name={"gender"}
                    label={t("genderCurrency")}
                    parseData={(data) => parseData(data)}
                    variables={{ input: { code: "GENDER_TYPE" } }}
                    query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                    defaultValue={autocompleteValues.genderCurrency}
                  />
                </Grid>
                <Grid xs={6} alignItems="flex-start">
                  <CustomAutocomplete
                    valueKey="code"
                    control={control}
                    errors={errors}
                    rules={{ required: t("fieldIsRequired") }}
                    name={"fractionGender"}
                    label={t("fractionGender")}
                    parseData={(data) => parseData(data)}
                    variables={{ input: { code: "GENDER_TYPE" } }}
                    query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                    defaultValue={autocompleteValues.fractionGender}
                  />
                </Grid>

              </Grid>
            }
          </Root>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end" className={classes.button}>
            <FormButton disabled={loading || saveCurrencyLoading}>
              {saveCurrencyLoading ? <ButtonLoading /> : t("save")}
            </FormButton>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}

CurrencyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.any.isRequired,
}

export default CurrencyDialog