/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { Paper, Icon } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import {TRANSACTION_TYP_ID } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";

const PREFIX = 'TransactionTypeView';

const classes = {
  paper: `${PREFIX}-paper`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const TransactionTypeView = (props) => {

  const { t } = useTranslation();
  const TransactionTypeId = props.match.params.id?.trim();
  const { loading, data } = useQuery(
    gql`
      ${TRANSACTION_TYP_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(TransactionTypeId) },
    }
  );

  const user = Globals.user;
  const branchId = data?.transactionType?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canEdit =
    user.hasPermission("shipping.transaction_type.update") &&
    canAccessBranch;

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/transaction-types/${TransactionTypeId}/edit`),
      icon: Edit,
      permission: canEdit,
    },
  ]

  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading >
    : !data ?
      <NotFound />
      :
      <StyledGrid
        container
        justifyContent="center"
        sx={{ margin: "0", width: "100%" }}
      >

        <Fragment>
          <TitleAppBar path={props.match.path} >
            <LongMenu icons={icons} />
          </TitleAppBar>
        </Fragment>
        <Grid
          container
          justifyContent="center"
          sx={{ width: "100%", p: 2, gap: 2 }}
        >
          <Paper container xs={12} className={classes.paper} component={Grid}>
            <KeyValuePair
              title={t("code")}
              value={data?.transactionType?.code}
            />
            <KeyValuePair
              title={t("name")}
              value={data?.transactionType?.name}
            />
            <KeyValuePair
              title={t("active")}
              value={
                data?.transactionType?.active ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
            <KeyValuePair
              title={t("documentType")}
              value={data?.transactionType?.type?.name}
            />
            <KeyValuePair
              title={t("sequence")}
              value={data?.transactionType?.sequence?.name}
            />
          </Paper>

          <Paper container xs={12} className={classes.paper} component={Grid}>
            <KeyValuePair
              title={t("branch")}
              value={data?.transactionType?.branch?.name}
            />
            <KeyValuePair
              title={t("glAccount")}
              value={data?.transactionType?.glAccount?.name}
            />
            <KeyValuePair
              title={t("journalType")}
              value={data?.transactionType?.journalType?.name}
            />
            <KeyValuePair
              title={t("subsidiary")}
              value={data?.transactionType?.subsidiary?.name}
            />
          </Paper>
        </Grid>
      </StyledGrid >

};

export default TransactionTypeView;
