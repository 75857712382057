import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";

import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import * as gqlb from "gql-query-builder";
import "../ManifestPrint/print.css";
import { dateFormatLL } from "../../helpers/dateFunctions";
import LogoImg from "../../Layout/LogoImg";

const PREFIX = "VoucherPrint";

const classes = {
  headerCells: `${PREFIX}-headerCells`,
  title: `${PREFIX}-title`,
  notes: `${PREFIX}-notes`,
  noteCell: `${PREFIX}-noteCell`,
  barcodeFont: `${PREFIX}-barcodeFont`,
  cairoFont: `${PREFIX}-cairoFont`,
  barcode: `${PREFIX}-barcode`,
  headerTableCell: `${PREFIX}-headerTableCell`,
  mainCellBorder: `${PREFIX}-mainCellBorder`,
  signature: `${PREFIX}-signature`,
  reportHeader: `${PREFIX}-reportHeader`,
  hiddenFooter: `${PREFIX}-hiddenFooter`,
  pageFooter: `${PREFIX}-pageFooter`,
  logoSection: `${PREFIX}-logoSection`,
  headerDate: `${PREFIX}-headerDate`,
  whiteSpace: `${PREFIX}-whiteSpace`,
  approve: `${PREFIX}-approve`,
  rowWithoutBorder: `${PREFIX}-rowWithoutBorder`,
  mainDetailsCell: `${PREFIX}-mainDetailsCell`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
  tableWidth: `${PREFIX}-tableWidth`,
  shipmentsTable: `${PREFIX}-shipmentsTable`,
  signatureBlock: `${PREFIX}-signatureBlock`,
  dotsLineKeyField: `${PREFIX}-signatureField`,
};

const StyledPrint = styled("div")(({ theme }) => ({
  [`& .${classes.headerCells}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.title}`]: {
    color: theme.palette.info.main,
    // whiteS;pace: "break-spaces",
  },

  [`& .${classes.notes}`]: {
    padding: theme.spacing(1),
    border: "1px solid " + theme.palette.action.disabled,
    wordBreak: "break-all",
    width: "100%",
    minHeight: 80,
  },

  [`& .${classes.noteCell}`]: {
    width: "30%",
    overflow: "hidden",
  },

  [`& .${classes.barcodeFont}`]: {
    fontFamily: '"Libre Barcode 39 Text"',
  },

  [`& .${classes.cairoFont}`]: {
    // fontFamily: ['"Cairo"', "sans-serif"].join(","),
  },

  [`& .${classes.barcode}`]: {
    fontSize: 40,
    textAlign: "end",
  },

  [`& .${classes.headerTableCell}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.mainCellBorder}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.signature}`]: {
    textAlign: "center",
    padding: theme.spacing(2, 1, 1, 1),
    border: "none",
  },

  [`& .${classes.reportHeader}`]: {
    whiteSpace: "pre-line",
    padding: theme.spacing(0, 2),
    lineHeight: "120%",
    fontSize: 12,
  },

  [`& .${classes.hiddenFooter}`]: {
    visibility: "hidden",
  },

  [`& .${classes.pageFooter}`]: {
    //   "&::after": {
    //    " content:  counter(page)  of  counter(pages)"
    //   },
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid " + theme.palette.text.secondary,
    display: "flex",
    alignItems: "flex-end",
  },

  [`& .${classes.logoSection}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.headerDate}`]: {
    textAlign: "end",
    "& p": {
      margin: 0,
      padding: 0,
      lineHeight: "22px",
    },
  },
  [`& .${classes.whiteSpace}`]: {
    "& tr": {
      "& td": {
        whiteSpace: "initial",
      },
    },
  },

  [`& .${classes.approve}`]: {
    textAlign: "end",
  },

  [`& .${classes.rowWithoutBorder}`]: {
    "& td": {
      border: "none",
      padding: 0,
    },
  },

  [`& .${classes.mainDetailsCell}`]: {
    "& td": { border: "none", verticalAlign: "top" },
    "& td:first-of-type": { paddingLeft: 0 },
    "& td:last-of-type": { paddingRight: 0 },

    border: "none",
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.tableHeadCell}`]: {
    padding: 0,
  },

  [`& .${classes.tableWidth}`]: {
    width: "100vw",
  },

  [`& .${classes.shipmentsTable}`]: {
    "& tr": {
      "&:hover": {
        backgroundColor: "#ffffff00 !important",
      },
    },
    "& td , & th": {
      padding: theme.spacing(1),
    },
  },

  [`& .${classes.signatureBlock}`]: {
    breakInside: "avoid",
  },
  [`& .${classes.dotsLineKeyField}`]: {
    marginRight: theme.spacing(1),
  },
}));

const org = {
  operation: "organization",
  fields: [
    "name",
    "phone",
    "email",
    "website",
    "reportHeader",
    "reportFooter",
    "registrationNumber",
    "taxNumber",
  ],
  variables: {},
};

const vouchersListFields = [
  "code",
  "date",
  "notes",
  "amount",
  "amountText",
  "paymentMethod",

  {
    type: ["code", "name"],
  },

  {
    glAccount: ["id", "name"],
  },
  {
    safe: ["id", "name"],
  },
  {
    deliveryAgent: ["id", "name"],
  },
  {
    customer: ["id", "name"],
  },
];

const VOUCHER_PRINT = gqlb.query([
  org,
  {
    operation: "voucher",
    fields: vouchersListFields,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
]);

const VoucherPrint = (props) => {
  const { t } = useTranslation();

  const voucherId = props.match.params.id?.trim();
  const { data: queryData, loading } = useQuery(
    gql`
      ${VOUCHER_PRINT.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: { id: +voucherId },
      onError: () => {
        return;
      },
    }
  );
  const reportDetails = queryData?.voucher !== null ? queryData?.voucher : null;

  useEffect(() => {
    if (reportDetails) {
      // let logo = document.getElementById("brand-logo");

      // logo.onload = () => {
      window.print();
      // logo.onload = null;
      // };
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const payee =
    reportDetails?.customer?.name ??
    reportDetails?.glAccount?.name ??
    reportDetails?.deliveryAgent?.name;
  const PAYMENT = reportDetails?.type?.code === "PAYMENT";
  const header = !loading && reportDetails !== null && (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell padding="none">
            <Table>
              <TableBody>
                <TableRow className={classes.rowWithoutBorder}>
                  <TableCell>
                    <Box className={classes.logoSection}>
                      <LogoImg forceLight={true} height={30} />
                      {/* <img id="brand-logo" src={logo} alt="logo" height={30} /> */}
                      <Typography className={clsx(classes.reportHeader)}>
                        {queryData.organization.reportHeader}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell className={clsx(classes.headerDate)}>
                    <Typography variant="h6">
                      {dateFormatLL(new Date())}
                    </Typography>
                    <TypographyKeyValue
                      title={t("registrationNumber")}
                      value={queryData.organization?.registrationNumber}
                    />
                    <TypographyKeyValue
                      title={t("taxNumber")}
                      value={queryData.organization?.taxNumber}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.headerTableCell}>
            <Table className={classes.whiteSpace}>
              <TableBody>
                <TableRow className={classes.rowWithoutBorder}>
                  <TableCell>
                    <Typography variant="h5">
                      {reportDetails?.type?.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={clsx(classes.barcode, classes.barcodeFont)}
                    >
                      *{reportDetails?.code}*
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell
            className={clsx(classes.mainDetailsCell, "landscape-zoom")}
          >
            <Table className={classes.whiteSpace}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TypographyKeyValue
                      title={t("voucherDate")}
                      value={dateFormatLL(reportDetails?.date)}
                    />
                  </TableCell>
                  <TableCell>
                    <TypographyKeyValue
                      title={t("safe")}
                      value={reportDetails?.safe?.name}
                    />
                  </TableCell>
                  <TableCell>
                    <TypographyKeyValue
                      title={t("amount")}
                      value={reportDetails?.amount}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={3}>
                    <KeyWithDottedBorder
                      title={t(PAYMENT ? "payTo" : "receivedFrom")}
                      value={payee}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={3}>
                    <KeyWithDottedBorder
                      title={t("amount")}
                      value={reportDetails?.amountText}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={3}>
                    <KeyWithDottedBorder
                      title={t(PAYMENT ? "paidBy" : "receivedBy")}
                      value={reportDetails?.paymentMethod}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={3} className={classes.noteCell}>
                    <Box>
                      <span>{t("notes")}</span>
                      <Box component="div" className={classes.notes}>
                        <Typography variant="body2">
                          {reportDetails?.notes}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <StyledPrint className={classes.cairoFont}>
      {loading ? (
        <>
          <FullScreenLoading minHeight="20%" />
          {/* this spans for download font before print */}
          <span className={clsx(classes.barcodeFont)} />
          <span style={{ opacity: 0 }} className={classes.cairoFont}>
            lا
          </span>
        </>
      ) : reportDetails === null ? (
        <NotFound />
      ) : (
        <>
          <div id="printManifestContainer">
            {/* <div>{header}</div> */}

            <div className="page-footer">
              {queryData.organization.reportFooter}
            </div>

            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td>
                    <div>{header}</div>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <div
                      className={clsx(classes.shipmentsTable, {
                        // [classes.tableWidth]: tableZoom,
                      })}
                      id="shipmentsTableContainer"
                    ></div>
                    <div className={classes.signatureBlock}>
                      <Table className={classes.whiteSpace}>
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.signature}>
                              <KeyWithDottedBorder title={t("treasurer")} />
                            </TableCell>
                            <TableCell className={classes.signature}>
                              {PAYMENT && (
                                <KeyWithDottedBorder title={t("recipient")} />
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <div className="page-footer-space">
                      {queryData.organization.reportFooter}
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </>
      )}
    </StyledPrint>
  );

  function TypographyKeyValue(props) {
    const { title, value, hidden, ...restProps } = props;
    return hidden || !value ? null : (
      <Box component="p" paddingBottom={1} {...restProps}>
        <span className={classes.title}>{title}:</span> <span>{value}</span>
      </Box>
    );
  }
};

function KeyWithDottedBorder(props) {
  const { title, value, ...restProps } = props;
  return (
    <div style={{ display: "flex" }} {...restProps}>
      <Box component="span" sx={{ mr: 2 }} color="primary">
        {title + " :"}
      </Box>
      <Box
        component="span"
        sx={{ borderBottom: "1px dotted black", flex: 1, alignSelf: "center" }}
      >
        {value}
      </Box>
    </div>
  );
}

export default VoucherPrint;
