import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import clsx from "clsx";
import * as colors from "@mui/material/colors";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";

const StyledFixedTableCell = styled(Chip)(({ theme, colorcode }) => ({
  color: theme.palette.getContrastText(colorcode),
  backgroundColor: colorcode + "!important",
}));

export const TableCellColor = memo((props) => {
  const color = {
    NEW: colors["blueGrey"]["500"],
    CONFIRMED: colors["green"]["500"],
    RESCHEDULED: colors["orange"]["500"],
    COLLECTED: colors["lightGreen"]["500"],
    RECHARGED: colors["pink"]["500"],
    CANCELLED: colors["red"]["500"],
    ONWAY: colors["teal"]["500"],
    ASSIGNED: colors["lightBlue"]["500"],
    TRANSFERRED: colors["indigo"]["500"],
    REVIEWD: colors["amber"]["500"],
    PAID: colors["deepOrange"]["500"],
  };

  return (
    <FixedTableCell sx={props.style} className={clsx(props.className)}>
      <StyledFixedTableCell
        colorcode={color.hasOwnProperty(props.status.code) ? color[props.status.code] : colors["brown"]["500"]}
        size="small"
        label={props?.status.name}
      />
    </FixedTableCell>
  );
});
