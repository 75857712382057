import React, { useState } from 'react'
import {
  Paper,
  Button,
  Stack,
  Collapse,
  Box
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { AddCircleOutline, ArrowBack, ArrowForward, Delete, Edit, Percent } from "@mui/icons-material";
import ButtonLoading from '../../HOC/FunctionComponents/LoadingPages/ButtonLoading';
import CustomDialog from '../../HOC/CustomComponents/CustomDialog';
import { useForm } from 'react-hook-form';
import Grid from "@mui/material/Unstable_Grid2";
import { CustomAutocomplete } from '../../HOC/MUI/CustomAutocomplete';
import { LIST_GL_ACCOUNTS_DROPDOWN } from '../../../GlobalsQuery/ListDropdown/ListDropdown';
import ControlMUItextField from '../../HOC/MUI/ControlMUItextField';
import ListBranches from '../../HOC/ComponentWithSpecificQuery/ListBranches';
import { DELETE_SHIPPING_SERVICE_Branch, SAVE_SHIPPING_SERVICE_Branch } from '../Graphql';
import { useMutation, gql } from '@apollo/client';
import { KeyValuePair } from '../../HOC/CustomComponents/KeyValuePair';
import { setValidationError } from '../../HOC/CustomFunctions/setValidationError';
import { useSnackbar } from 'notistack';
import MutationWithDialog from '../../HOC/CustomComponents/MutationWithDialog';
import EmptyTableMessage from '../../HOC/FunctionComponents/EmptyTableMessage';
import InputAdornment from '@mui/material/InputAdornment';
import CustomAccordions from '../../HOC/CustomComponents/CustomAccordions';

function ServiceBranchesTable(props) {
  const { data, tableHeader, classes, shippingServiceId, refetch } = props;
  const [rowId, setRowId] = useState(null)
  const { t, i18n } = useTranslation(["translation", "validation"])
  const { enqueueSnackbar } = useSnackbar();
  const dir = i18n.language === "ku" ? "rtl" : i18n.dir()

  const { handleSubmit, control, formState, setValue, setError, watch, reset } =
    useForm();
  const { errors } = formState;

  const ORIGIN_BRANCH_EQUAL_ACTION_BRANCH = watch("originBranchId") === watch("actionBranchId")
  const [autocompleteValues, setAutocompleteValues] = useState({
    actionBranch: null,
    actionCollectionAccount: null,
    actionCommissionAccount: null,
    actionDeliveryAccount: null,
    originBranch: null,
    originCollectionAccount: null,
    originCommissionAccount: null,
    originDeliveryAccount: null,
  });

  const [serviceBranchesDialog, setServiceBranchesDialog] = useState(false)

  const EnhancedTableToolbar = (
    <Paper component={Stack} direction={"row"} mb={2} px={2} py={1} justifyContent={"space-between"} alignItems={"center"}>
      <Typography>
        {t("branchesIncomeRatio")}
      </Typography>
      {tableHeader && <IconButton
        aria-label="Add"
        className={classes.addIcon}
        onClick={() => setServiceBranchesDialog(true)}
        disabled={!data}
        size="small"
      >
        <AddCircleOutline />
      </IconButton>}
    </Paper>
  )

  const closeServiceBranchesDialog = () => {
    reset()
    const customerParams = [
      "actionCollectionShare",
      "actionCommissionShare",
      "actionDeliveryShare",
      "originCollectionShare",
      "originCommissionShare",
      "originDeliveryShare",
    ];
    customerParams.forEach((i) => {
      setValue(i, "");
    });

    setAutocompleteValues({
      actionBranch: null,
      actionCollectionAccount: null,
      actionCommissionAccount: null,
      actionDeliveryAccount: null,
      originBranch: null,
      originCollectionAccount: null,
      originCommissionAccount: null,
      originDeliveryAccount: null,
    });
    setRowId(null)
    setServiceBranchesDialog(false)
  }

  const [saveShippingServiceBranch, { loading: saveShippingServiceBranchLoading }] =
    useMutation(gql`${SAVE_SHIPPING_SERVICE_Branch.query}`);

  const updateQueryFunction = () => {
    refetch()
    closeServiceBranchesDialog()
  };

  const onSubmit = (data) => {
    saveShippingServiceBranch({
      variables: {
        input: {
          ...(rowId && { id: rowId }),
          serviceId: shippingServiceId,
          actionBranchId: data.actionBranchId,
          actionCollectionAccountId: data.actionCollectionAccountId,
          actionCommissionAccountId: data.actionCommissionAccountId,
          actionDeliveryAccountId: data.actionDeliveryAccountId,
          originBranchId: data.originBranchId,
          originCollectionAccountId: data.originCollectionAccountId,
          originCommissionAccountId: data.originCommissionAccountId,
          originDeliveryAccountId: data.originDeliveryAccountId,
          originCollectionShare: parseFloat(data.originCollectionShare),
          originCommissionShare: parseFloat(data.originCommissionShare),
          originDeliveryShare: parseFloat(data.originDeliveryShare),
        }
      }
    }).then((data) => {
      updateQueryFunction()
      enqueueSnackbar(t("saveSuccessful"), {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        TransitionComponent: Collapse,
      });
    }).catch(({ graphQLErrors }) => {
      setValidationError(graphQLErrors, setError);
    })
  }

  const updateRecord = (row) => {
    const customerParams = [
      "actionCollectionShare",
      "actionCommissionShare",
      "actionDeliveryShare",
      "originCollectionShare",
      "originCommissionShare",
      "originDeliveryShare",
    ];

    customerParams.forEach((i) => {
      setValue(i, row[i]);
    });

    setAutocompleteValues({
      actionBranch: row.actionBranch,
      actionCollectionAccount: row.actionCollectionAccount,
      actionCommissionAccount: row.actionCommissionAccount,
      actionDeliveryAccount: row.actionDeliveryAccount,
      originBranch: row.originBranch,
      originCollectionAccount: row.originCollectionAccount,
      originCommissionAccount: row.originCommissionAccount,
      originDeliveryAccount: row.originDeliveryAccount,
    });
    setRowId(row.id)
    setServiceBranchesDialog(true)
  }

  const selectDefaultValue = (data) => {
    return data;
  };

  const [openDelete, setOpenDelete] = useState(false);

  const handelDelete = (id) => {
    setRowId(id)
    setOpenDelete(true)
  }

  const contentDialog = tableHeader && (
    <Grid container spacing={1} m={0}>
      <Grid xs={12} md={6}>
        <Box width={"100%"}>
          <Typography>{t("originData")}</Typography>
          <Grid container spacing={2} m={0}>
            <Grid xs={12}>
              <ListBranches
                control={control}
                errors={errors}
                name={"originBranchId"}
                rules={{ required: t("fieldIsRequired") }}
                defaultValue={autocompleteValues.originBranch}
                // disabled={shippingServiceId ? true : false}
                onChangeValue={() => {
                  setValue("originDeliveryAccountId", "");
                  setValue("originCollectionAccountId", "");
                  setValue("originCommissionAccountId", "");
                }}
              // skipDefaultBranch={Boolean(shippingServiceId)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} m={0}>
            <Grid xs={12} sm={8}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"originDeliveryAccountId"}
                label={t("deliveryAccount")}
                onChangeValue={(e) => {
                  ORIGIN_BRANCH_EQUAL_ACTION_BRANCH && setAutocompleteValues(prev => ({
                    ...prev,
                    actionDeliveryAccount: e
                  }))
                }}
                rules={{ required: t("fieldIsRequired") }}
                // disabled={shippingServiceData?.deliveryAccount ? true : false}
                parseData={(data) => selectDefaultValue(data)}
                query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                variables={{
                  input: {
                    typeCode: "SUB",
                    ...(watch("originBranchId") && {
                      branchId: { value: watch("originBranchId"), includeNull: true },
                    }),
                  },
                }}
                defaultValue={autocompleteValues.originDeliveryAccount}
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <ControlMUItextField
                control={control}
                errors={errors}
                type={"number"}
                name={"originDeliveryShare"}
                label={t("ratio")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Percent fontSize='small' />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => (e.target.value <= 100 && e.target.value >= 0) && setValue("actionDeliveryShare", 100 - e.target.value)}
                rules={{
                  required: t("fieldIsRequired"),
                  min: {
                    value: 0,
                    message: t("validation:min", {
                      field: t("number"),
                      number: "0",
                    }),
                  },
                  max: {
                    value: 100,
                    message: t("validation:max", {
                      field: t("number"),
                      number: "100",
                    }),
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} m={0}>
            <Grid xs={12} sm={8}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"originCollectionAccountId"}
                label={t("collectionAccount")}
                rules={{ required: t("fieldIsRequired") }}
                onChangeValue={(e) => {
                  ORIGIN_BRANCH_EQUAL_ACTION_BRANCH && setAutocompleteValues(prev => ({
                    ...prev,
                    actionCollectionAccount: e
                  }))
                }}
                // disabled={shippingServiceData?.collectionAccount ? true : false}
                parseData={(data) => selectDefaultValue(data)}
                query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                variables={{
                  input: {
                    typeCode: "SUB",
                    ...(watch("originBranchId") && {
                      branchId: { value: watch("originBranchId"), includeNull: true },
                    }),
                  },
                }}
                defaultValue={autocompleteValues.originCollectionAccount}
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <ControlMUItextField
                control={control}
                errors={errors}
                name={"originCollectionShare"}
                label={t("ratio")}
                type={"number"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Percent fontSize='small' />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => (e.target.value <= 100 && e.target.value >= 0) && setValue("actionCollectionShare", 100 - e.target.value)}
                rules={{
                  required: t("fieldIsRequired"),
                  min: {
                    value: 0,
                    message: t("validation:min", {
                      field: t("number"),
                      number: "0",
                    }),
                  },
                  max: {
                    value: 100,
                    message: t("validation:max", {
                      field: t("number"),
                      number: "100",
                    }),
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} m={0}>
            <Grid xs={12} sm={8}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"originCommissionAccountId"}
                label={t("DeliveryAgentCommissionAccount")}
                rules={{ required: t("fieldIsRequired") }}
                onChangeValue={(e) => {
                  ORIGIN_BRANCH_EQUAL_ACTION_BRANCH && setAutocompleteValues(prev => ({
                    ...prev,
                    actionCommissionAccount: e
                  }))
                }}
                // disabled={shippingServiceData?.commissionAccount ? true : false}
                parseData={(data) => selectDefaultValue(data)}
                query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                variables={{
                  input: {
                    typeCode: "SUB",
                    ...(watch("originBranchId") && {
                      branchId: { value: watch("originBranchId"), includeNull: true },
                    }),
                  },
                }}
                defaultValue={autocompleteValues.originCommissionAccount}
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <ControlMUItextField
                control={control}
                errors={errors}
                type={"number"}
                name={"originCommissionShare"}
                label={t("ratio")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Percent fontSize='small' />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => (e.target.value <= 100 && e.target.value >= 0) && setValue("actionCommissionShare", 100 - e.target.value)}
                rules={{
                  required: t("fieldIsRequired"),
                  min: {
                    value: 0,
                    message: t("validation:min", {
                      field: t("number"),
                      number: "0",
                    }),
                  },
                  max: {
                    value: 100,
                    message: t("validation:max", {
                      field: t("number"),
                      number: "100",
                    }),
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid xs={12} md={6}>
        <Box width={"100%"}>
          <Typography>{t("actionData")}</Typography>
          <Grid container spacing={2} m={0}>
            <Grid xs={12}>
              <ListBranches
                control={control}
                errors={errors}
                name={"actionBranchId"}
                rules={{ required: t("fieldIsRequired") }}
                defaultValue={autocompleteValues.actionBranch}
                // disabled={shippingServiceId ? true : false}
                onChangeValue={() => {
                  setValue("actionDeliveryAccountId", "");
                  setValue("actionCollectionAccountId", "");
                  setValue("actionCommissionAccountId", "");
                }}
              // skipDefaultBranch={Boolean(shippingServiceId)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} m={0}>
            <Grid xs={12} sm={8}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                name={"actionDeliveryAccountId"}
                label={t("deliveryAccount")}
                disabled={ORIGIN_BRANCH_EQUAL_ACTION_BRANCH}
                rules={{ required: t("fieldIsRequired") }}
                // disabled={shippingServiceData?.deliveryAccount ? true : false}
                parseData={(data) => selectDefaultValue(data)}
                query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                variables={{
                  input: {
                    typeCode: "SUB",
                    ...(watch("actionBranchId") && {
                      branchId: { value: watch("actionBranchId"), includeNull: true },
                    }),
                  },
                }}
                defaultValue={autocompleteValues.actionDeliveryAccount}
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <ControlMUItextField
                control={control}
                errors={errors}
                type={"number"}
                name={"actionDeliveryShare"}
                label={t("ratio")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Percent fontSize='small' />
                    </InputAdornment>
                  ),
                }}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} m={0}>
            <Grid xs={12} sm={8}>
              <CustomAutocomplete
                control={control}
                errors={errors}
                disabled={ORIGIN_BRANCH_EQUAL_ACTION_BRANCH}
                name={"actionCollectionAccountId"}
                label={t("collectionAccount")}
                rules={{ required: t("fieldIsRequired") }}
                // disabled={shippingServiceData?.deliveryAccount ? true : false}
                parseData={(data) => selectDefaultValue(data)}
                query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                variables={{
                  input: {
                    typeCode: "SUB",
                    ...(watch("actionBranchId") && {
                      branchId: { value: watch("actionBranchId"), includeNull: true },
                    }),
                  },
                }}
                defaultValue={autocompleteValues.actionCollectionAccount}
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <ControlMUItextField
                control={control}
                errors={errors}
                type={"number"}
                name={"actionCollectionShare"}
                label={t("ratio")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Percent fontSize='small' />
                    </InputAdornment>
                  ),
                }}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} m={0}>
            <Grid xs={12} sm={8}>
              <CustomAutocomplete
                control={control}
                errors={errors}

                name={"actionCommissionAccountId"}
                label={t("DeliveryAgentCommissionAccount")}
                disabled={ORIGIN_BRANCH_EQUAL_ACTION_BRANCH}
                rules={{ required: t("fieldIsRequired") }}
                // disabled={shippingServiceData?.deliveryAccount ? true : false}
                parseData={(data) => selectDefaultValue(data)}
                query={LIST_GL_ACCOUNTS_DROPDOWN.query}
                variables={{
                  input: {
                    typeCode: "SUB",
                    ...(watch("actionBranchId") && {
                      branchId: { value: watch("actionBranchId"), includeNull: true },
                    }),
                  },
                }}
                defaultValue={autocompleteValues.actionCommissionAccount}
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <ControlMUItextField
                control={control}
                errors={errors}
                type={"number"}
                name={"actionCommissionShare"}
                label={t("ratio")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Percent fontSize='small' />
                    </InputAdornment>
                  ),
                }}
                disabled
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )

  return (
    <Box sx={{ display: "grid" }}>
      {EnhancedTableToolbar}
      {tableHeader && serviceBranchesDialog &&
        <CustomDialog
          title={rowId ? t("update") : t("addNew")}
          fullWidth
          maxWidth="md"
          onClose={closeServiceBranchesDialog}
          content={contentDialog}
          open={serviceBranchesDialog}
          PaperProps={{
            component: 'form',
            onSubmit: handleSubmit(onSubmit),
          }}
          actions={
            <>
              <Button color="primary" onClick={closeServiceBranchesDialog}>
                {t("close")}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={saveShippingServiceBranchLoading}
              >
                {saveShippingServiceBranchLoading ? (
                  <ButtonLoading />
                ) : (
                  t("confirm")
                )}
              </Button>
            </>
          }
        />
      }

      {openDelete &&
        <MutationWithDialog
          mutaion={DELETE_SHIPPING_SERVICE_Branch.query}
          setOpenDelete={setOpenDelete}
          openDelete={openDelete}
          dialogTitle={t("deleteManifest")}
          dialogContent={t("deleteManifestConfirmation")}
          mutaionProps={{ variables: { id: parseInt(rowId) } }}
          onCompleted={() => updateQueryFunction()}
          onCompleteMessage={t("manifestDeleted")}
        />
      }

      <Stack spacing={2}>
        {data && data.length > 0 ? data?.map((row, index) =>
          <CustomAccordions
            key={index}
            title={
              <Box
                elevation={7}
                className={classes.paperTitle}
                component={Stack} p={1.5} width={"100%"}
                direction={"row"}
                flexWrap={"wrap"}
              >
                <Stack direction={"row"} spacing={2} alignItems={"center"} flexGrow={1} justifyContent={"center"}>
                  <Stack>
                    <Typography variant="body2" gutterBottom color="textSecondary">
                      {t("originBranch")}
                    </Typography>
                    <Typography>
                      {row.originBranch?.name}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography>
                      {dir === "rtl" ? <ArrowBack /> : <ArrowForward />}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="body2" gutterBottom color="textSecondary">
                      {t("actionBranch")}
                    </Typography>
                    <Typography>
                      {row.actionBranch?.name}
                    </Typography>
                  </Stack>
                </Stack>

                {tableHeader &&
                  <Stack direction={"row"}>
                    <IconButton
                      aria-label="Edit"
                      onClick={() => updateRecord(row)}
                      size="medium"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => handelDelete(row.id)}
                      size="medium"
                    >
                      <Delete />
                    </IconButton>
                  </Stack>}
              </Box>
            }
            body={
              <Grid container spacing={1} m={0} p={2}>
                <Grid xs={12} md={6}>
                  <Box width={"100%"}>
                    <Grid container spacing={2} m={0}>
                      <KeyValuePair
                        xs={8} sm={8} md={8}
                        title={t("deliveryAccount")}
                        value={row.originDeliveryAccount?.name}
                      />
                      <KeyValuePair
                        xs={4} sm={4} md={4}
                        title={t("ratio")}
                        value={`${row.originDeliveryShare}%`}
                      />
                    </Grid>
                    <Grid container spacing={2} m={0}>
                      <KeyValuePair
                        xs={8} sm={8} md={8}
                        title={t("collectionAccount")}
                        value={row.originCollectionAccount?.name}
                      />
                      <KeyValuePair
                        xs={4} sm={4} md={4}
                        title={t("ratio")}
                        value={`${row.originCollectionShare}%`}
                      />
                    </Grid>
                    <Grid container spacing={2} m={0}>
                      <KeyValuePair
                        xs={8} sm={8} md={8}
                        title={t("DeliveryAgentCommissionAccount")}
                        value={row.originCommissionAccount?.name}
                      />
                      <KeyValuePair
                        xs={4} sm={4} md={4}
                        title={t("ratio")}
                        value={`${row.originCommissionShare}%`}
                      />
                    </Grid>
                  </Box>
                </Grid>
                <Grid xs={12} md={6}>
                  <Box width={"100%"}>
                    <Grid container spacing={2} m={0}>
                      <KeyValuePair
                        xs={8} sm={8} md={8}
                        title={t("deliveryAccount")}
                        value={row.actionDeliveryAccount?.name}
                      />
                      <KeyValuePair
                        xs={4} sm={4} md={4}
                        title={t("ratio")}
                        value={`${row.actionDeliveryShare}%`}
                      />
                    </Grid>
                    <Grid container spacing={2} m={0}>
                      <KeyValuePair
                        xs={8} sm={8} md={8}
                        title={t("collectionAccount")}
                        value={row.actionCollectionAccount?.name}
                      />
                      <KeyValuePair
                        xs={4} sm={4} md={4}
                        title={t("ratio")}
                        value={`${row.actionCollectionShare}%`}
                      />
                    </Grid>
                    <Grid container spacing={2} m={0}>
                      <KeyValuePair
                        xs={8} sm={8} md={8}
                        title={t("DeliveryAgentCommissionAccount")}
                        value={row.actionCommissionAccount?.name}
                      />
                      <KeyValuePair
                        xs={4} sm={4} md={4}
                        title={t("ratio")}
                        value={`${row.actionCommissionShare}%`}
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            }
          />
        )
          :
          <Paper>
            <EmptyTableMessage
              message={t("noResult")}
            />
          </Paper>
        }
      </Stack>

    </Box>
  )
}

ServiceBranchesTable.propTypes = {
  data: PropTypes.any,
  tableHeader: PropTypes.bool,
  classes: PropTypes.object.isRequired,
}


export default ServiceBranchesTable