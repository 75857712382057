import * as gqlb from "gql-query-builder";

export const LIST_LOOKUP = gqlb.query({
  operation: "listLookups",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "lastPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "name",
        "code",
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListLookupsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const LIST_LOOKUP_ID = gqlb.query({
  operation: "lookup",
  fields: [
    "id",
    "name",
    "code",
    {
      entries: [
        "id",
        "code",
        "name",
        "active",
        "weight",
        "mobileActive"
      ]
    }
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_LOOKUP_ENTRIES = gqlb.mutation({
  operation: "updateLookup",
  fields: ["id"],
  variables: {
    input: {
      type: "LookupInput",
      required: true,
    },
  },
});
