import { gql, useMutation } from "@apollo/client";
import {
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import * as gqlb from "gql-query-builder";
import { Fragment, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";

export const EXPOERT_PRODUCT_TRANSACTIONS = gqlb.mutation({
    operation: "exportProductTransactions",
    fields: [],
    variables: {
        input: {
            type: "ExportProductTransactionsInput",
            required: true,
        },
    },
});

export default function ExportProductsTransactions(props) {
    const { enqueueSnackbar } = useSnackbar();
    const { filters, openExport, setOpenExport } = props;
    const [productsFieldsState, setProductsFieldsState] = useState([]);
    const { t } = useTranslation();
    const productsFields = [
        { name: "PRODUCT", label: t("product") },
        { name: "QUANTITY", label: t("quantity") },
        { name: "BRANCH", label: t("branch") },
        { name: "SHIPMENT_CODE", label: t("theShipment") },
        // { name: "SHIPMENT_STATUS", label: t("shipmentStatus") },
        { name: "WAREHOUSE_MANIFEST_CODE", label: t("manifest") },
        { name: "TRANSACTION_TYPE", label: t("transactionType") },
        { name: "STATUS", label: t("status") },
        { name: "DATE", label: t("createdAt") },
    ];

    useEffect(() => {
        if (!productsFields[0].id)
            productsFields.forEach((ele, index) => {
                ele["id"] = index + 1;
                ele["checked"] = true;
            });

        const localStorageExportProducts = localStorage.getItem("export-products");
        const parsedExportProducts = localStorageExportProducts
            ? JSON.parse(localStorageExportProducts)
            : null;

        const notCheckedFields = productsFields.filter((i) => {
            if (
                !parsedExportProducts?.includes(i.name) &&
                parsedExportProducts !== null
            ) {
                i.checked = false;
                return true;
            }
            return false;
        });

        const checkedFields = productsFields.filter((i) => {
            if (parsedExportProducts?.includes(i.name)) {
                i.checked = true;
                return true;
            }
            return false;
        });

        const sortedFields = checkedFields.sort((a, b) => {
            return parsedExportProducts?.indexOf(a.name) >
                parsedExportProducts?.indexOf(b.name)
                ? 1
                : -1;
        });

        setProductsFieldsState(
            localStorageExportProducts
                ? [...sortedFields, ...notCheckedFields]
                : productsFields
        );
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    // const [dialogState, setDialogState] = useState(false);

    const fieldCount = productsFieldsState.length;
    const fieldSelected = productsFieldsState.filter((i) => i.checked).length;
    const onSelectAllClick = (e) => {
        const fileds = [...productsFieldsState];
        fileds.forEach((i) => {
            if (e.target.checked) {
                i.checked = true;
            } else {
                i.checked = false;
            }
        });
        setProductsFieldsState(fileds);
    };

    const onCloseDialog = () => {
        setOpenExport(false);
    };
    const [exportProducts, { loading }] = useMutation(
        gql`
      ${EXPOERT_PRODUCT_TRANSACTIONS.query}
    `,
        {
            onCompleted: (data) => {
                onCloseDialog();
                if (data.exportProductTransactions) {
                    enqueueSnackbar(t("successExport"), {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        TransitionComponent: Collapse,
                    });
                } else {
                    enqueueSnackbar(t("errorExport"), {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        TransitionComponent: Collapse,
                    });
                }
            },
            onError: (error) => {
                console.log(error);
            },
        }
    );

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(productsFieldsState);
        const [reorderedItem] = items.splice(result.source.index, 1);
        const isNotChecked = items[result.destination.index - 1]?.checked;
        if (isNotChecked === false) return;
        items.splice(result.destination.index, 0, reorderedItem);

        setProductsFieldsState(items);
    }

    const handelExportProducts = () => {
        const updatedFields = productsFieldsState.reduce((previous, current) => {
            if (current.checked) {
                previous.push(current.name);
            }
            return previous;
        }, []);
        localStorage.setItem("export-products", JSON.stringify(updatedFields));
        const fields = productsFieldsState
            .filter((i) => i.checked)
            .map((i) => ({ label: i.label, name: i.name }));
        exportProducts({
            variables: {
                input: {
                    filters,
                    fields,
                },
            },
        });
    };

    return (
        <>
            <CustomDialog
                fullWidth
                maxWidth="xs"
                open={openExport}
                onClose={onCloseDialog}
                title={
                    <Fragment>
                        <Checkbox
                            edge="start"
                            indeterminate={fieldSelected > 0 && fieldSelected < fieldCount}
                            checked={fieldCount > 0 && fieldSelected === fieldCount}
                            onChange={onSelectAllClick}
                            inputProps={{ "aria-label": "select all desserts" }}
                        />
                        {t("exportExcel")}
                    </Fragment>
                }
                actions={
                    <>
                        <Button color="primary" onClick={onCloseDialog}>
                            {t("cancel")}
                        </Button>
                        <Button
                            color="primary"
                            disabled={loading || fieldSelected === 0}
                            onClick={handelExportProducts}
                        >
                            {loading ? <ButtonLoading /> : t("confirm")}
                        </Button>
                    </>
                }
                content={
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="characters">
                            {(provided) => (
                                <List
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    component="ul"
                                    sx={{
                                        width: "100%",
                                        bgcolor: "background.paper",
                                    }}
                                >
                                    {productsFieldsState.map((value, index) => {
                                        const labelId = `checkbox-list-label-${value.id}`;
                                        return (
                                            <Draggable
                                                isDragDisabled={!value.checked}
                                                key={value.id}
                                                draggableId={value.name}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <CustomeItemList
                                                        innerRef={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        itemDetails={value}
                                                        labelId={labelId}
                                                        state={productsFieldsState}
                                                        setState={setProductsFieldsState}
                                                    />
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.label}
                                </List>
                            )}
                        </Droppable>
                    </DragDropContext>
                }
            />
        </>
    );
}

const CustomeItemList = (props) => {
    const { itemDetails, labelId, state, setState, innerRef, ...restProps } =
        props;
    const { label, checked } = itemDetails;
    const handleToggle = (e) => {
        const checked = e.target.checked;
        const update = [...state];
        const checkedItemLength = update.filter((i) => i.checked).length;
        const index = update.findIndex((i) => i.name === itemDetails.name);
        const [sortItem] = update.splice(index, 1);

        sortItem["checked"] = checked;
        update.splice(checkedItemLength, 0, sortItem);

        setState(update);
    };
    return (
        <ListItem {...restProps} ref={innerRef} dense button>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={checked}
                    tabIndex={-1}
                    onChange={handleToggle}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                />
            </ListItemIcon>
            <ListItemText id={labelId} primary={label} />
        </ListItem>
    );
};
