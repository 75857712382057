import { IconButton, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import { useSnackbar } from "notistack";
import Collapse from "@mui/material/Collapse";
import { DELETE_PAYMENT_ENTRIES_MUTATION} from "./PaymentListQuary";
import { gql, useMutation } from "@apollo/client";
const useConciergeRequestsPaymentDetails = ({
  typeData,
  type,
  approved,
  refetchConciergeDataQuery,
  setConciergeRequestsData: removeElement,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deletePaymentEntries, { loading: deletePaymentEntriesLoading }] = useMutation(
    gql`
      ${DELETE_PAYMENT_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const deleteElement = (request) => (
    <FixedTableCell component="th" scope="row">
      <IconButton
        size="small"
        disabled={!approved || request.conciergeRequest?.pendingDelete}
        onClick={() => {
          removeElement(prev => {
            const conciergeRequests = prev.pickedConciergeRequests.map(e => ({
              ...e,
              conciergeRequest: {
                ...e.conciergeRequest,
                pendingDelete: e.conciergeRequest.id === request.conciergeRequest.id ? true : e.conciergeRequest.pendingDelete
              }
            }));
            return {
              ...prev,
              pickedConciergeRequests: conciergeRequests
            }
          })
          deletePaymentEntries({
            variables: {
              input: {
                paymentId: typeData?.id,
                conciergeRequestIds: [request.conciergeRequest.id]
              }
            }
          }).then(() => {
            refetchConciergeDataQuery()
            enqueueSnackbar(t("successfullyDeletedRecord"), {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          }).catch((error) => {
            enqueueSnackbar(error.message, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              TransitionComponent: Collapse,
            });
          })
        }}
      >
        {deletePaymentEntriesLoading && request.conciergeRequest?.pendingDelete ? (
          <CustomSpinner name="ClockLoader" size={20} />
        ) : (
          <Delete />
        )}
      </IconButton>
    </FixedTableCell>
  );

  /* --------------------------------- DLVBY --------------------------------- */
  const conciergeRequestTableHead = [
    "#",
    "requestCode",
    "branch",
    "customer",
    "accountNumber",
    "deservedAmount",
    removeElement ? "" : null,
  ];
  const conciergeSum = (typeData) => ({
    paidAmount: typeData?.sumEntriesConcierge?.paymentAmount,
  })

  const conciergeRequestTableTotal = conciergeSum(typeData);
  const conciergeRequestTableBody = (collectionConciergeRequest, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink pathname={`/admin/concierge-request/${collectionConciergeRequest.conciergeRequest?.id}`}>
          {collectionConciergeRequest.conciergeRequest?.code}
        </CellLink>
        <CellLink pathname={`/admin/branches/${collectionConciergeRequest.conciergeRequest?.branch?.id}`}>
          {collectionConciergeRequest.conciergeRequest?.branch?.name}
        </CellLink>
        <CellLink pathname={`/admin/customers/${collectionConciergeRequest.conciergeRequest?.customer?.id}`}>
          {collectionConciergeRequest.conciergeRequest?.customer?.name}
        </CellLink>
        <FixedTableCell>
          {collectionConciergeRequest.conciergeRequest?.accountNumber}
        </FixedTableCell>
        <FixedTableCell>{collectionConciergeRequest.paidAmount}</FixedTableCell>
        {removeElement && deleteElement(collectionConciergeRequest)}
      </TableRow>
    );
  };

  const sumTableNumber = (conciergeRequest, tableTotal) => {
    // Object.entries(tableTotal).forEach(([k, v]) => {
    //   if (k === "paidAmount") {
    //     tableTotal[k] = conciergeRequest[k] ?? 0
    //   } else {
    //     tableTotal[k] += conciergeRequest.conciergeRequest[k] ?? 0
    //   }
    // });
  };

  /* --------------------------------- Manifests Object --------------------------------- */

  const paymentsObject = {
    DLVBY: {
      id: "2",
      customer: true,
      label: t("manifestDetails"),
      tableHead: conciergeRequestTableHead,
      tableBody: conciergeRequestTableBody,
      tableTotal: Array.isArray(typeData) ? typeData.map(item => conciergeSum(item)) : conciergeRequestTableTotal,
      sumTableNumber,
    },
  };

  return {
    details: paymentsObject[type],
  };
};

export default useConciergeRequestsPaymentDetails;
