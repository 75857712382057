import * as gqlb from "gql-query-builder";

const fields = [
  "id",
  "finished",
  {
    branch: ["id", "name"],
  },
  {
    deliveryAgent: ["id", "name"],
  },
  {
    ritsTransactionType: [
      "id",
      "code",
      "name",
      {
        type: ["code", "name"],
      },
    ],
  },
  {
    rcvTransactionType: [
      "id",
      "code",
      "name",
      {
        type: ["code", "name"],
      },
    ],
  },
  {
    collectionTransactionType: [
      "id",
      "code",
      "name",
      {
        type: ["code", "name"],
      },
    ],
  },
  {
    ritsManifest: [
      "id",
      "code",
      "approved",
    ],
  },
  {
    rcvManifest: [
      "id",
      "code",
      "approved",
    ],
  },
  {
    collection: [
      "id",
      "code",
      "approved",
    ],
  },
  {
    safe: ["id", "name"],
  },
]

export const LIST_DELIVERY_AGENT_CLEARANCE_QUERY = gqlb.query([
  {
    operation: "listDeliveryAgentClearances",
    fields: [
      {
        operation: "paginatorInfo",
        fields: [
          "count",
          "currentPage",
          "total",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage",
        ],
        variables: {},
      },
      {
        operation: "data",
        fields: [
          "id",
          "finished",
          "createdAt",
          {
            branch: ["id", "name"],
          },
          {
            safe: ["id", "name"],
          },
          {
            deliveryAgent: [
              "id",
              "name"
            ]
          },
          {
            ritsManifest: [
              "id",
              "code"
            ]
          },
          {
            rcvManifest: [
              "id",
              "code"
            ]
          },
          {
            collection: [
              "id",
              "code"
            ]
          },
          {
            createdBy: [
              "id",
              "username"
            ]
          },
          {
            updatedBy: [
              "id",
              "username"
            ]
          }
        ],
        variables: {},
      },
    ],
    variables: {
      input: {
        type: "ListDeliveryAgentClearancesFilterInput",
        required: true,
      },
      first: {
        type: "Int",
        required: true,
      },
      page: {
        type: "Int",
      },
    },
  },
]);

export const DELIVERY_AGENT_CLEARANCE_ID = gqlb.query({
  operation: "deliveryAgentClearance",
  fields: fields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_DELIVERY_AGENT_CLEARANCE = gqlb.mutation({
  operation: "saveDeliveryAgentClearance",
  fields: ["id"],
  variables: {
    input: {
      type: "DeliveryAgentClearanceInput",
      required: true,
    },
  },
});
export const PROGRESS_DELIVERY_AGENT_CLEARANCE = gqlb.mutation({
  operation: "progressDeliveryAgentClearance",
  fields: fields,
  variables: {

    id: {
      type: "Int",
      required: true,
    },

  },
});
