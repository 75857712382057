
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import NotFound from "../../Error/NotFound";
import { Globals } from "../HOC/Classes/Globals";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import Grid from "@mui/material/Unstable_Grid2";
import PaymentViewBody from "./PaymentViewBody";
import { defaultQueryFieldsPayment, initialPaymentData } from "./listPaymentFields";
import { PAYMENT_VIEW } from "./PaymentListQuary";

const PREFIX = "PaymentView";

const classes = {
  main: `${PREFIX}-main`,

};


const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const PaymentView = (props) => {

  // const [collectionType, setCollectionType] = useState(null);

  const paymentId = props.match.params.id?.trim();
 

  const [paymentsData, setPaymentsData] = useState();

  const [localKeys, setKeys] = useState(null)

  /******************************************* Start collection By Id ********************************************/
  const user = Globals.user;
  const journalEntryPermission = user.hasPermission(
    "accounting.journal_entry.list"
  );
  const ALLOWED_CONCIERGE =  Globals.settings.allowedCustomerTypes.includes("CONCIERGE")
  const ALLOWED_PICKUP = (Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") || Globals.settings.allowedCustomerTypes.includes("MERCHANT"))
  const ALLOWED_SHIPMENT = Globals.settings.allowedCustomerTypes.includes("INDIVIDUAL") || Globals.settings.allowedCustomerTypes.includes("MERCHANT")
  const paymentQueryBody = PAYMENT_VIEW(journalEntryPermission, ALLOWED_CONCIERGE, ALLOWED_SHIPMENT, ALLOWED_PICKUP);

  const {
    data,
    loading: paymentByIdLoad,
    refetch,
  } = useQuery(
    gql`
      ${paymentQueryBody.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !paymentId,
      variables: {
        id: parseInt(paymentId),
      },
      onCompleted: (data) => {
        if (data?.payment === null) return;
        const payment = data?.payment;
        setPaymentsData(payment);
       

      },
    }
  );

  useEffect(() => {
    if (data) {
      setKeys(localStorage.getItem(`shipmentPayment${data?.payment?.type?.code}`) ? JSON.parse(localStorage.getItem(`shipmentPayment${data?.payment?.type?.code}`)) : initialPaymentData[data?.payment?.type?.code])
    }
    return () => { };
  }, [data]);
  const adminNotesPermission = Globals.user.hasPermission(
    "shipping.shipment.view_admin_note"
  );

  return (paymentByIdLoad || !localKeys || !data ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !data?.payment ? <NotFound /> :
      <PaymentViewBody
        {...props}
        data={data}
        paymentType={data?.payment?.type?.code}
        paymentByIdLoad={paymentByIdLoad}
        refetch={refetch}
        paymentId={paymentId}
        setKeys={setKeys}
        shipmentListDetails={defaultQueryFieldsPayment(localKeys,adminNotesPermission)}
        localKeys={localKeys}
        ALLOWED_SHIPMENT={ALLOWED_SHIPMENT}
        ALLOWED_CONCIERGE={ALLOWED_CONCIERGE}
        ALLOWED_PICKUP={ALLOWED_PICKUP}
        paymentsData={paymentsData}
      />
  )
};

export default PaymentView;
