import * as gqlb from "gql-query-builder";

export const LIST_FINANCIAL_YEAR = gqlb.query({
  operation: "listFinancialYears",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "lastPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "name",
        "active",
        "endDate",
        "startDate",
        "closed",
        { periods: ["code", "name", "id"] }
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListFinancialYearsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const FINANCIAL_YEAR_ID = gqlb.query({
  operation: "financialYear",
  fields: [
    "id",
    "code",
    "name",
    "active",
    "endDate",
    "startDate",
    "closed",
    { periods: ["code", "name", "id", "endDate", "startDate", "active",
    {exchangeRates:["id","rate","updatable",{currency:["name","id","code"]}]}
  ] }
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_FINANCIAL_YEAR = gqlb.mutation({
  operation: "saveFinancialYear",
  fields: ["id", "code", "name"],
  variables: {
    input: {
      type: "FinancialYearInput",
      required: true,
    },
  },
});
export const SAVE_FINANCIAL_YEAR_PERIOD = gqlb.mutation({
  operation: "saveFinancialPeriod",
  fields: ["code", "name", "id", "endDate", "startDate", "active"],
  variables: {
    input: {
      type: "FinancialPeriodInput",
      required: true,
    },
  },
});
export const updateExchangeRatesMutationBuilder = (ids) => {
  const queryArray = [];
  for (const id of ids) {
    const initialQuery = {
      operation: `exchange_${id}:updateExchangeRates`,
      fields: ["id"],
      variables: {
        ["input" + id]: {
          name: "input",
          type: "ExchangeRateInput",
          required: true,
        },
      },
    };
    queryArray.push(initialQuery);
  }
  return gqlb.mutation(queryArray);
};