import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from "react-i18next";
import {
  Collapse,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Box,
  Badge,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { gql, useMutation, useQuery } from '@apollo/client';
import { CONCIERGE_PROVIDER_ID, SAVE_CONCIERGE_PROVIDER } from './Graphql';
import { useForm } from 'react-hook-form';
import { styled } from "@mui/material/styles";
// import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import FormButton from '../CustomComponents/Buttons/FormButton';
import ButtonLoading from '../HOC/FunctionComponents/LoadingPages/ButtonLoading';
// import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { setValidationError } from '../HOC/CustomFunctions/setValidationError';
import MuiSwitch from '../HOC/MUI/MUIswitch';
import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import FullScreenLoading from '../HOC/FunctionComponents/LoadingPages/FullScreenLoading';
import UploadFile from '../HOC/MUI/UploadFile';
import { Clear } from '@mui/icons-material';
import config from "../../config.json";


const PREFIX = "BranchList";

const classes = {
  mainGrid: `${PREFIX}-mainGrid`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  [`& .${classes.button}`]: {
    width: "100%",
    paddingRight: 0,
  }

}));

function FormDialog(props) {
  const { id } = props;
  const {
    handleSubmit,
    setValue,
    control,
    setError,
    errors,
  } = useForm({
    defaultValues: {
      active: true,
    },
  });
  const { t } = useTranslation();

  const [previewImage, setPreviewImage] = React.useState({
    src: null,
    trigger: null
  });

  const { loading } = useQuery(
    gql`
      ${CONCIERGE_PROVIDER_ID.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !id,
      variables: {
        id: parseInt(id),
      },
      onCompleted: (data) => {
        const queryData = data.conciergeProvider;
        const queryParams = [
          "id",
          "code",
          "name",
        ];
        queryParams.forEach((i) => {
          queryData[i] && setValue(i, queryData[i]);
        });
        queryData.images[0] && setPreviewImage({
          src: queryData.images[0].path,
          trigger: true
        })
        setValue("active", queryData["active"]);
      }
    },
  );
  const { enqueueSnackbar } = useSnackbar();

  const [saveConciergeProvider, { loading: saveConciergeProviderLoading }] = useMutation(
    gql`
      ${SAVE_CONCIERGE_PROVIDER.query}
    `
  );

  const onSubmit = (data) => {
    const image = data.images?.[0]
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }

      if (key === "fileName" || key === "images") {
        delete data[key];
      }
    }

    saveConciergeProvider({
      variables: {
        input: {
          ...data,
          ...(image && {
            images: [{ file: image }]
          }),
        },
      },
    })
      .then((data) => {
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        props.handleClose()
        props.refetch()
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };

  let getBackendUri = (imgPath, trigger) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return trigger ? `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}` : imgPath;
  };

  const setClear = () => {
    setPreviewImage({
      src: null,
      trigger: null
    });
    setValue("fileName", "")
    setValue("images", "")
  }

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      maxWidth={'xs'}
      fullWidth={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="scroll-dialog-title">
          <Grid container justifyContent="space-between" xs={12}>
            <Typography variant="h6" color={"text.primary"} >
              {id ? t("updateConciergeProviders") : t("createConciergeProviders")}
            </Typography>
            <Stack direction={"row"} alignItems={"center"}>
              <Stack>
                <MuiSwitch
                  edge="end"
                  name="active"
                  label={t("active")}
                  control={control}
                />
              </Stack>
            </Stack>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Root>
            {loading ? <FullScreenLoading minHeight="100px" /> :
              <Grid
                container
                justifyContent="flex-start"
                aligns="center"
                className={classes.mainGrid}
                spacing={2}
              >
                <Grid xs={12} aligns="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"code"}
                    label={t("code")}
                  />
                </Grid>
                <Grid xs={12} aligns="flex-start">
                  <ControlMUItextField
                    control={control}
                    errors={errors}
                    name={"name"}
                    label={t("name")}
                    rules={{ required: t("fieldIsRequired") }}
                  />
                </Grid>
                <Grid xs={12} sm={previewImage.src ? 8 : 12} alignItems="flex-start">
                  <UploadFile
                    control={control}
                    setValue={setValue}
                    name="images"
                    icon={"add_photo_alternate"}
                    label={t("uploadImage")}
                    accept=".png,.jpg"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        // setSelectedImage(file);
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          setPreviewImage({
                            src: reader.result,
                            trigger: false
                          });
                        };
                        reader.readAsDataURL(file);
                      } else {
                        // setSelectedImage(null);
                        setPreviewImage({
                          src: null,
                          trigger: null
                        });
                      }
                    }}
                  />
                </Grid>
                {previewImage.src &&
                  <Grid xs={12} sm={4} textAlign="center">
                    <Badge
                      onClick={setClear}
                      badgeContent={<Clear sx={{ fontSize: "10px" }} />}
                      color="error"
                    >
                      <Box
                        sx={{ width: "60px", height: "60px", borderRadius: "1%" }}
                        component="img" alt="logo"
                        src={getBackendUri(previewImage.src, previewImage.trigger)}
                        onClick={(event) => event.stopPropagation()}
                      />
                    </Badge>
                  </Grid>
                }
              </Grid>
            }
          </Root>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end" className={classes.button}>
            <FormButton disabled={loading || saveConciergeProviderLoading}>
              {saveConciergeProviderLoading ? <ButtonLoading /> : t("save")}
            </FormButton>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}

FormDialog.protoType = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.any.isRequired,
}

export default FormDialog