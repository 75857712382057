import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import MUItextField from "../HOC/MUI/MUItextField";
import { Button, Grid, IconButton, InputAdornment } from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  CheckCircleOutline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import * as gqlb from "gql-query-builder";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";

const PREFIX = 'ChangePassword';

const classes = {
  root: `${PREFIX}-root`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    marginBottom: theme.spacing(2),
  }
}));

const CHANGE_PASSWORD = gqlb.mutation({
  operation: "changePassword",
  fields: [],
  variables: {
    input: {
      type: "ChangePasswordInput",
      required: true,
    },
  },
});


export const ChangePassword = (props) => {

  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
  });
  const handleClickShowPassword = (type) => {
    setShowPassword((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const {
    handleSubmit,
    register,
    getValues,
    formState,
    setError,

  } = useForm();
  const { errors } = formState;
  const [changePasswords, { loading }] = useMutation(
    gql`
      ${CHANGE_PASSWORD.query}
    `
  );

  const onSubmit = async (datas) => {
    await changePasswords({
      variables: {
        input: {
          old: datas.old,
          new: datas.new,
        },
      },
    })
      .then((res) => {
        props.onComplete();
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);

        console.log(graphQLErrors);
      });
  };
  return (
    <StyledGrid
      item
      container
      justifyContent="flex-start"
      alignItems="center"
      className={classes.root}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <MUItextField
          errors={errors}
          register={register}
          name={"old"}
          label={t("oldPassword")}
          type={showPassword.old ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("old")}
                  edge={"end"}
                  size="large">
                  {showPassword.old ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          //   margin={"normal"}
        />
        <MUItextField
          errors={errors}
          register={register}
          name={"new"}
          label={t("newPassword")}
          type={showPassword.new ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("new")}
                  edge={"end"}
                  size="large">
                  {showPassword.new ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          margin={"normal"}
          formVal={(value) => value !== getValues().old || t("passwordIsSame")}
        />

        <Button
          fullWidth
          size="large"
          startIcon={
            loading ? (
              <CustomSpinner size={24} name={"HashLoader"} />
            ) : (
              <CheckCircleOutline />
            )
          }
          disabled={loading}
          variant="contained"
          color="primary"
          type="submit"
        >
          {t("save")}
        </Button>
      </form>
    </StyledGrid>
  );
};
