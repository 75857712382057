import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Checkbox, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import ShipmentRegion from "../../HOC/CustomComponents/ShipmentRegion";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { dateFormatLL } from "../../../helpers/dateFunctions";
import { PaymentAmountTableCell } from "./paymentAmountTableCell";

const PREFIX = "ShipmentListDetails";

const classes = {
  shipmentTable_checkbox: `${PREFIX}-shipmentTable_checkbox`,
};

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [`& .${classes.shipmentTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    left: 0,
  },
}));
export const PaymentShipmentsCells = ({ shipmentDetails, type }) => {
  const cellsByType = {
    DLVBY: (
      <>
      <PaymentAmountTableCell shipmentDetails={shipmentDetails} />
      <FixedTableCell>{shipmentDetails?.shipment?.status?.name}</FixedTableCell>
      <FixedTableCell>{shipmentDetails?.shipment?.type?.name}</FixedTableCell>
      <FixedTableCell>{shipmentDetails?.shipment?.recipientName}</FixedTableCell>
      <FixedTableCell>
        {shipmentDetails?.shipment?.deliveredOrReturnedDate}
      </FixedTableCell>
      <ShipmentRegion
        pathname={`/admin/zones/${shipmentDetails?.shipment?.recipientZone?.id}`}
        zone={shipmentDetails?.shipment?.recipientZone?.name}
        subzone={shipmentDetails?.shipment?.recipientSubzone?.name}
      />
      <FixedTableCell>{shipmentDetails?.shipment?.customer?.name}</FixedTableCell>
      <FixedTableCell>{shipmentDetails?.shipment?.deliveryType?.name}</FixedTableCell>
    </>
    ),
    CUSTM: (
      <>
        <FixedTableCell>{shipmentDetails?.shipment?.status?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.shipment?.type?.name}</FixedTableCell>
        <FixedTableCell>{dateFormatLL(shipmentDetails?.shipment?.date)}</FixedTableCell>
        <FixedTableCell>
          {shipmentDetails?.shipment?.deliveredOrReturnedDate}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.shipment?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.shipment?.recipientZone?.id}`}
          zone={shipmentDetails?.shipment?.recipientZone?.name}
          subzone={shipmentDetails?.shipment?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.shipment?.returnType?.name}</FixedTableCell>
        <FixedTableCell>
          {shipmentDetails?.amount}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.shipment?.cancellationReason?.name}</FixedTableCell>
      </>
    ),
  };

  return cellsByType[type];
};
export function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, type } = props;
  const { t } = useTranslation();

  const headCells = {
    DLVBY: [
      t("requestCode"), 
      t("deservedAmount"),
      t("shipmentType"),
      t("shipmentStatus"),
      t("recipient"),
      t("deliveryCanclingDate"),
      t("destination"),
      t("customer"),
      t("deliveryType"),
     
    ],
    CUSTM: [
      t("requestCode"),
      t("shipmentType"),
      t("shipmentStatus"),
      t("theDate"),
      t("deliveryCanclingDate"),
      t("recipient"),
      t("destination"),
      t("returnType"),
      t("customerDueAmount"),
      t("CancellationReasonName")
    ],
  };
  return (
    <StyledTableHead>
      <TableRow>
        <FixedTableCell
          className={classes.shipmentTable_checkbox}
          padding="checkbox"
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </FixedTableCell>
        {headCells[type].map((headCell, index) => (
          <FixedTableCell
            key={index}
            // align={headCell.numeric ? 'right' : 'left'}
          >
            {headCell}
          </FixedTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
