import { IconButton, TableRow } from "@mui/material";
import { Delete } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import CustomSpinner from "../HOC/FunctionComponents/CustomSpinner";
import formatMoney from "../../helpers/numbersDot";
import { useSnackbar } from "notistack";
import Collapse from "@mui/material/Collapse";
import { DELETE_COLLECTION_ENTRIES_MUTATION } from "./CollectionListQuary";
import { gql, useMutation } from "@apollo/client";

const useConciergeRequestsCollectionDetails = ({
  type,
  approved,
  typeData,

  refetchConciergeDataQuery,
  setConciergeRequestsData: removeElement,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteCollectionEntries, { loading: deleteCollectionEntriesLoading }] = useMutation(
    gql`
      ${DELETE_COLLECTION_ENTRIES_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
    }
  );

  const deleteElement = (request) => {
    return (
      <FixedTableCell component="th" scope="row">
        <IconButton
          size="small"
          disabled={!approved || request.conciergeRequest?.pendingDelete}
          onClick={() => {
            removeElement(prev => {
              const conciergeRequests = prev.pickedConciergeRequests.map(e => ({
                ...e,
                conciergeRequest: {
                  ...e.conciergeRequest,
                  pendingDelete: e.conciergeRequest.id === request.conciergeRequest.id ? true : e.conciergeRequest.pendingDelete
                }
              }));
              return {
                ...prev,
                pickedConciergeRequests: conciergeRequests
              }
            })
            deleteCollectionEntries({
              variables: {
                input: {
                  collectionId: typeData.id,
                  conciergeRequestIds: [request.conciergeRequest.id]
                }
              }
            }).then(() => {
              refetchConciergeDataQuery()
              enqueueSnackbar(t("successfullyDeletedRecord"), {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
            }).catch((error) => {
              enqueueSnackbar(error.message, {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                TransitionComponent: Collapse,
              });
            })
          }}
        >
          {deleteCollectionEntriesLoading && request.conciergeRequest?.pendingDelete ? (
            <CustomSpinner name="ClockLoader" size={20} />
          ) : (
            <Delete />
          )}
        </IconButton>
      </FixedTableCell>
    )
  };
  /* --------------------------------- DLVBY --------------------------------- */
  const conciergeRequestTableHead = [
    "#",
    "requestCode",
    "branch",
    "customer",
    "accountNumber",
    "collectedAmount",
    removeElement ? "" : null,
  ];

  const conciergeRequestTableTotal = {
    collectedAmount: typeData?.sumEntriesConcierge?.collectedAmount,
  };

  const autoPayCommissionDlvby = typeData?.deliveryAgent?.autoPayCommission

  if (autoPayCommissionDlvby) {
    conciergeRequestTableTotal.commission = typeData?.sumEntriesConcierge?.commissionAmount
    conciergeRequestTableTotal.NetAmount = 0
    conciergeRequestTableHead.splice(6, 0, "commission");
    conciergeRequestTableHead.splice(7, 0, "NetAmount");
  }
  const conciergeRequestTableBody = (collectionConciergeRequest, index) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
        <FixedTableCell>{index + 1}</FixedTableCell>
        <CellLink pathname={`/admin/concierge-request/${collectionConciergeRequest.conciergeRequest?.id}`}>
          {collectionConciergeRequest.conciergeRequest?.code}
        </CellLink>
        <CellLink pathname={`/admin/branches/${collectionConciergeRequest.conciergeRequest?.branch?.id}`}>
          {collectionConciergeRequest.conciergeRequest?.branch?.name}
        </CellLink>
        <CellLink pathname={`/admin/customers/${collectionConciergeRequest.conciergeRequest?.customer?.id}`}>
          {collectionConciergeRequest.conciergeRequest?.customer?.name}
        </CellLink>
        <FixedTableCell>
          {collectionConciergeRequest.conciergeRequest?.accountNumber}
        </FixedTableCell>
        <FixedTableCell>{collectionConciergeRequest.collectedAmount}</FixedTableCell>
        {autoPayCommissionDlvby && <FixedTableCell>{collectionConciergeRequest.commission}</FixedTableCell>}
        {autoPayCommissionDlvby && <FixedTableCell>{formatMoney((collectionConciergeRequest.collectedAmount - collectionConciergeRequest.commission).toFixed(2))}</FixedTableCell>}
        {removeElement && deleteElement(collectionConciergeRequest)}
      </TableRow>
    );
  };

  const sumTableNumber = (conciergeRequest, tableTotal) => {
    Object.entries(tableTotal).forEach(([k, v]) => {
      // if (k === "collectedAmount" || k === "commission" || k === "NetAmount") {
      //   if (k === "NetAmount") {
      //     tableTotal[k] += conciergeRequest["collectedAmount"] - conciergeRequest["commission"] ?? 0
      //   } else {
      //     tableTotal[k] += conciergeRequest[k] ?? 0
      //   }
      // } else {
      //   tableTotal[k] += conciergeRequest.conciergeRequest[k] ?? 0
      // }
      if (k === "NetAmount") {
        tableTotal[k] += conciergeRequest["collectedAmount"] - conciergeRequest["commission"] ?? 0
      }
    });
  };

  /* --------------------------------- Manifests Object --------------------------------- */

  const paymentsObject = {
    DLVBY: {
      id: "2",
      customer: true,
      label: t("manifestDetails"),
      tableHead: conciergeRequestTableHead,
      tableBody: conciergeRequestTableBody,
      tableTotal: conciergeRequestTableTotal,
      sumTableNumber,
    },
  };

  return {
    details: paymentsObject[type],
  };
};

export default useConciergeRequestsCollectionDetails;
