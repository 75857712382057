import * as gqlb from "gql-query-builder";

export const LIST_SHIPPING_SERVICES = gqlb.query({
  operation: "listShippingServices",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "name",
        "days",
        "active",
        {
          branch: ["id", "name"],
        },
        {
          commissionAccount: ["id", "name"],
        },
        {
          deliveryAccount: ["id", "name"],
        },
        {
          collectionAccount: ["id", "name"],
        },
        {
          shipmentTypes: ["code", "name"],
        },
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListShippingServicesFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SHIPPING_SERVICE_ID = gqlb.query({
  operation: "shippingService",
  fields: [
    "id",
    "code",
    "name",
    "days",
    "active",
    "egsUnitOfMeasure",
    "egsCode",
    {
      branch: ["id", "name"],
    },
    {
      commissionAccount: ["id", "name"],
    },
    {
      deliveryAccount: ["id", "name"],
    },
    {
      collectionAccount: ["id", "name"],
    },
    {
      shipmentTypes: ["code", "name"],
    },
    {
      serviceBranches: [
        "id",
        {
          service: ["id", "name"],
        },
        {
          originBranch: ["id", "name"],
        },
        {
          originDeliveryAccount: ["id", "name"],
        },
        {
          originCollectionAccount: ["id", "name"],
        },
        {
          originCommissionAccount: ["id", "name"],
        },
        {
          actionBranch: ["id", "name"],
        },
        {
          actionDeliveryAccount: ["id", "name"],
        },
        {
          actionCollectionAccount: ["id", "name"],
        },
        {
          actionCommissionAccount: ["id", "name"],
        },
        "originDeliveryShare",
        "originCollectionShare",
        "originCommissionShare",
        "actionDeliveryShare",
        "actionCollectionShare",
        "actionCommissionShare",
        "createdAt",
      ]
    }
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_SHIPPING_SERVICE = gqlb.mutation({
  operation: "saveShippingService",
  fields: [
    "id",
    "code",
    "name",
    "days",
    "active",
    {
      branch: ["id", "name"],
    },
    {
      commissionAccount: ["id", "name"],
    },
    {
      deliveryAccount: ["id", "name"],
    },
    {
      collectionAccount: ["id", "name"],
    },
  ],
  variables: {
    input: {
      type: "ShippingServiceInput",
      required: true,
    },
  },
});


export const LIST_EGS_CODE = gqlb.query({
  operation: "listEGSCode",
  fields: [
    "codeTypeName",
    "itemCode",
    "descriptionPrimaryLang",
    "descriptionSecondaryLang",
    {
      ownerTaxpayer: ["issuerId", "issuerName", "issuerNameAr"],
    },
  ],
  variables: {},
});


export const SAVE_SHIPPING_SERVICE_Branch = gqlb.mutation({
  operation: "saveShippingServiceBranch",
  fields: [
    "id",
    "code",
  ],
  variables: {
    input: {
      type: "ServiceBranchInput",
      required: true,
    },
  },
});

export const DELETE_SHIPPING_SERVICE_Branch = gqlb.mutation({
  operation: "deleteShippingServiceBranch",
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
