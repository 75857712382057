import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useQuery, gql } from "@apollo/client";
import { TableBody, TableRow, Icon } from "@mui/material";
import { Add } from "@mui/icons-material";
import { LIST_SUBSIDIARIES } from "./Graphql";
import SubsidiaryForm from "./SubsidiaryForm";
import CellLink from "../HOC/CustomComponents/CellLink";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";

const PREFIX = "SubsidiariesList";

const classes = {
  iconColor: `${PREFIX}-iconColor`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const SubsidiariesList = (props) => {
  const [open, setOpen] = React.useState(false);
  const [subsidiaryObject, setSubsidiaryObject] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSubsidiaryObject();
  };

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const { data, loading, refetch } = useQuery(
    gql`
      ${LIST_SUBSIDIARIES.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        first: rowsPerPage,
        page: page + 1,
      },
      onCompleted: (data) => { },
    }
  );

  const listSubsidiaries = data?.listSubsidiaries?.data;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const icons = [
    {
      id: "add",
      title: "createNew",
      action: () => handleClickOpen(),
      icon: Add,
    },
  ];

  return (
    <Root>
      {open && (
        <SubsidiaryForm
          open={open}
          handleClose={handleClose}
          refetch={refetch}
          subsidiaryObject={subsidiaryObject}
        />
      )}
      <ListWrapper
        drawerState={false}
        icons={icons}
        path={props.match.path}
        empty={listSubsidiaries?.length === 0}
        loading={loading}
        filters={false}
        tableHeaders={[
          {
            name: "name",
          },
          {
            name: "code",
          },
          {
            name: "active",
          },
        ]}
        tableBody={
          <TableBody>
            {listSubsidiaries &&
              listSubsidiaries?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <CellLink
                      onClick={() => {
                        handleClickOpen();
                        setSubsidiaryObject(row);
                      }}
                    >
                      {row?.name}
                    </CellLink>

                    <FixedTableCell>{row?.code}</FixedTableCell>
                    <FixedTableCell>
                      {row?.active ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.listSubsidiaries?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listSubsidiaries ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default SubsidiariesList;
