import * as gqlb from "gql-query-builder";

export const LIST_JOURNAL_TYPE = gqlb.query({
  operation: "listJournalTypes",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
        "total",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "code",
        "name",
        "active",
        { sequence: ["id", "name"] },
        { branch: ["id", "name"] },
        { reference: ["code", "name"] },
      ],
      variables: {},
    },
  ],
  variables: {
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});

export const SAVE_JOURNAL_TYPE = gqlb.mutation({
  operation: "saveJournalType",
  fields: ["id", "code", "name"],
  variables: {
    input: {
      type: "JournalTypeInput",
      required: true,
    },
  },
});
