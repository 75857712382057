import React, { useRef, useState } from "react";

import { styled } from "@mui/material/styles";

// import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CellLink from "../HOC/CustomComponents/CellLink";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { TableCellColor } from "../HOC/CustomComponents/TableCellColor";
// import SearchTable from "../ShipmentSearch/SearchTable";
import PieCharts from "./PieCharts";
// import Statistics from "./Statistics";
// import StatisticsFilterHeader from "./StatisticsFilterHeader";
import useWidth from "../../Hooks/useWidth";
// import { RiEBike2Line, RiMoneyDollarCircleLine } from "react-icons/ri";
import {
  CustomerServices,
  CustomerWidget,
  PendingApproval,
} from "./StatisticsCard";
import { Globals } from "../HOC/Classes/Globals";
// import Divider from "@mui/material/Divider";
// import { BiSupport } from "react-icons/bi";
import formatMoney from "../../helpers/numbersDot";
import { Alert, Button } from "@mui/material";
import RenewalDialog from "../Renewal/RenewalDialog";
import ShipmentList from "./ShipmentList";
import { Can } from "../HOC/CustomComponents/Secured";
import { GetPermissionSlug } from "../../helpers/getPermissionSlug";
// import { ListItem, ListItemIcon, ListItemText, List, Box } from "@mui/material";
// import { FiUsers } from "react-icons/fi";

const PREFIX = "Home";

const classes = {
  card: `${PREFIX}-card`,
  cardContent: `${PREFIX}-cardContent`,
  statistics: `${PREFIX}-statistics`,
  select: `${PREFIX}-select`,
  tableRow: `${PREFIX}-tableRow`,
  shipmentTable_code: `shipmentTable_code`,
  shipmentTable_shipmentStatus: `shipmentTable_shipmentStatus`,
  container: `${PREFIX}-container`,
  pieChart: `${PREFIX}-pieChart`,
  colorPrimary: `${PREFIX}-colorPrimary`,
  colorSuccess: `${PREFIX}-colorSuccess`,
  root: `${PREFIX}-root`,
  fontSize: `${PREFIX}-fontSize`,
  link: `${PREFIX}-link`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.card}`]: {
    width: "inherit",
    borderRadius: 12,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 5%), 0px 6px 10px 0px rgb(0 0 0 / 5%), 0px 1px 18px 0px rgb(0 0 0 / 5%)",
    minHeight: "150px",
  },

  // [`& .${classes.cardContent}`]: {
  //   padding: "3px 16px",
  // },

  [`& .${classes.statistics}`]: {
    flex: "1 1 100%",
    fontWeight: "600",
  },

  [`& .${classes.select}`]: {
    color: "#00000099",
  },

  [`& .${classes.tableRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: `${theme.palette.background.hover} !important`,
      },
    },
  },

  [`& .${classes.shipmentTable_code}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      left: "0 !important",
    },
  },

  [`& .${classes.shipmentTable_shipmentStatus}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0 !important",
    },
  },

  // [`& .${classes.container}`]: {
  //   marginBottom: theme.spacing(2),
  // },

  [`& .${classes.colorPrimary}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.colorSuccess}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.fontSize}`]: {
    fontWeight: 600,
    fontSize: theme.spacing(2.2),
    "& span::first-letter": {
      textTransform: "capitalize",
    },
  },
  [`& .${classes.link}`]: {
    backgroundColor: theme.palette.background.hover,
    justifyContent: "center",
    width: "80%",
    margin: "auto",
    borderRadius: "20px",
  },

  [`& .${classes.pieChart}`]: {
    [theme.breakpoints.down("330")]: {
      width: "200px",
      overflow: "auto",
    },
    "& .dxc-item": {
      "& g": {
        cursor: "pointer",
      },
    },
    "& .dxc-labels-group": {
      "& g g g text": {
        cursor: "pointer !important",
        // color: "#000000de !important",s
        fill: "#FFF !important"
      },
    },
    "& .dxc-series-group": {
      "& g g path": {
        cursor: "pointer !important",
      },
    },
  },
}));
// const StyledCard = styled(Card)(({ theme, ...props }) => ({
//   [`& span`]: {
//     width: "50%",
//   },
// }));

const formatDate = (date) => moment(date).locale("en").format("YYYY-MM-DD");
const initToDate = 30;

const Home = (props) => {
  const week = useRef(
    7
  );
  const month = useRef(
    30
  );

  // const [queryVariablesStatistics, setQueryVariablesStatistics] = useState({
  //   fromDate: month.current,
  //   toDate: moment(new Date()).locale("en").format("YYYY-MM-DD"),
  //   statusCode: null,
  // });
  const [queryVariablesPieCharts, setQueryVariablesPieCharts] = useState({
    date: {
      fromDays: month.current,
      // toDate: moment(new Date()).locale("en").format("YYYY-MM-DD")
    },
    statusCode: null,
  });
  const [page, setPage] = useState(0);
  // const [focused, setFocused] = useState({
  //   total: true,
  // });

  const { t } = useTranslation();

  const onDateRangeAccept = (date, closeDateRangeDialog, section) => {
    section === "pieChart" &&
      setQueryVariablesPieCharts((prev) => ({
        ...prev,
        date: {
          fromDate: formatDate(date[0]),
          toDate: formatDate(date[1])
        },
      }));
    // section === "Statistics" &&
    //   setQueryVariablesStatistics((prev) => ({
    //     ...prev,
    //     fromDate: formatDate(date[0]),
    //     toDate: formatDate(date[1]),
    //   }));
    setPage(0);
    closeDateRangeDialog();
  };

  const handleDateRange = (
    value,
    setSelectValue,
    setDateRangeDialog,
    section
  ) => {
    let defaultParamter = {
      fromDays: initToDate,
    };

    switch (value) {
      case "month":
        setSelectValue("month");
        defaultParamter.fromDays = month.current;
        break;
      case "week":
        setSelectValue("week");
        defaultParamter.fromDays = week.current;
        break;
      case "all":
        setSelectValue("all");
        defaultParamter = {}
        break;

      case "period":
      default:
        setSelectValue("period");

        return setDateRangeDialog(true);
    }

    section === "pieChart" &&
      setQueryVariablesPieCharts((prev) => ({
        statusCode: prev.statusCode,
        date: { ...defaultParamter, }
      }));
    // section === "Statistics" &&
    //   setQueryVariablesStatistics((prev) => ({
    //     statusCode: prev.statusCode,
    //     ...defaultParamter,
    //   }));
    setPage(0);
  };

  const handleStatus = (value, delivered) => {
    delete queryVariablesPieCharts.delivered;
    setQueryVariablesPieCharts((prev) => ({
      ...prev,
      statusCode: value,
    }));
    setPage(0);
  };

  //////////Table Function////////////

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  let DOM = null;
  /////////////////////////
  const screenWidth = useWidth();

  const width = Boolean(
    screenWidth === "md" || screenWidth === "xs" || screenWidth === "sm"
  );
  const queryFields = [
    "id",
    "code",
    "date",
    "recipientName",
    "price",
    "inWarehouse",
    {
      customer: ["id", "name"],
    },
    {
      recipientZone: ["id", "name"],
    },
    {
      recipientSubzone: ["name"],
    },
    {
      status: ["name", "code"],
    },
    {
      deliveryType: ["name", "code"],
    },
  ];
  const tableHeadCell = [
    "code",
    "date",
    "customer",
    "recipient",
    "destination",
    "packagePrice",
    "shipmentStatus",
  ];
  const tableBody = (shipment, index) => (
    <TableRow className={classes.tableRow} key={index}>
      <CellLink
        className={classes.shipmentTable_code}
        pathname={`/admin/shipments/${shipment.id}`}
      >
        {shipment.code}
      </CellLink>
      <FixedTableCell>{moment(shipment.date).format("ll")}</FixedTableCell>
      <CellLink pathname={`/admin/customers/${shipment.customer.id}`}>
        {shipment.customer.name}
      </CellLink>

      <FixedTableCell>{shipment.recipientName}</FixedTableCell>
      <ShipmentRegion
        pathname={`/admin/zones/${shipment?.recipientZone?.id}`}
        zone={shipment.recipientZone?.name}
        subzone={shipment.recipientSubzone?.name}
      />
      <FixedTableCell>{formatMoney(shipment.price)}</FixedTableCell>
      <TableCellColor
        className={classes.shipmentTable_shipmentStatus}
        sx={{ backgroundColor: "white" }}
        shipment={shipment}
      />
    </TableRow>
  );

  const user = Globals.user;
  const support = Globals.settings.support;

  const colBig = [];
  if (user.hasPermission("shipping.shipment.list")) {
    const newElement = {
      id: 0,
      order: 1,
      component: (
        <Can showException permission={user.hasPermission("shipping.shipment.list")}>
          <PieCharts
            onDateRangeAccept={onDateRangeAccept}
            handleDateRange={handleDateRange}
            dateRange={{ date: queryVariablesPieCharts?.date }}
            onFocus={(filterType, delivered) => {
              handleStatus(filterType, delivered);
            }}
            classNamePieChart={classes.pieChart}
          />
        </Can>
      ),
    };
    colBig.splice(0, 0, newElement);
  }

  if (user.hasPermission("shipping.shipment.list")) {
    const newElement = {
      id: 1,
      order: 5,
      component: (
        <ShipmentList
          className={classes}
          onPageChange={handleChangePage}
          page={page}
          queryVariables={queryVariablesPieCharts}
          rowsPerPage={10}
          brife={true}
          sum={false}
          tableHeadCell={tableHeadCell}
          tableBody={tableBody}
          queryFields={queryFields}
        />
      ),
    };
    colBig.splice(1, 0, newElement);
  }

  const colSmall = [];

  if (
    user.hasPermission(GetPermissionSlug("shipping", "registration", "MERCHANT", "list")) ||
    user.hasPermission("shipping.customer_merchant.list_with_new_shipments") ||
    user.hasPermission("shipping.shipment.list")
  ) {
    const newElement = {
      id: 0,
      order: 2,
      component:
        <PendingApproval {...props} />
    };
    colSmall.splice(0, 0, newElement);
  }
  if (support) {
    const newElement = {
      id: 1,
      order: 3,
      component: <CustomerServices {...props} />,
    };
    colSmall.splice(1, 1, newElement);
  }
  if (
    user.hasPermission("shipping.shipment.list_delivery_agent_custody") ||
    user.hasPermission("shipping.shipment.list_customer_not_paid")
  ) {
    const newElement = {
      id: 2,
      order: 4,
      component: <CustomerWidget {...props} />,
    };
    colSmall.splice(2, 0, newElement);
  }

  let arr = colBig.concat(colSmall);
  arr.sort((a, b) => a.order - b.order);

  const userCanSubscription = Globals.user.hasPermission('shipping.settings.renew_subscription')
  const [open, setOpen] = React.useState(userCanSubscription && localStorage.getItem('renewalDateMsg') === "true");
  const [openRenewalDialog, setOpenRenewalDialog] = useState(false);
  const closeShipmentDialogHandler = () => {
    setOpenRenewalDialog(false);
  };

  DOM = (
    (colBig.length !== 0 || colSmall.length !== 0) && <Grid
      container
      justifyContent={width ? "center" : "space-between"}
      alignContent="space-between"
      className={classes.container}
      spacing={2}
      my={2}
    >
      {open && <Grid
        item
        xs={12}
      >
        <Alert sx={{ width: "100%", background: "#f4bf4f", color: "black" }} variant="filled" severity="warning"
          action={
            <Button color="error" size="small" variant="contained" sx={{ boxShadow: "none" }}
              onClick={() => {
                setOpenRenewalDialog(true);
              }}
            >
              {t("renew")}
            </Button>
          }
        >
          {t("renewalMsg", { date: Globals.settings.renewalDate })}
        </Alert>
      </Grid>}
      {!["md", "sm", "xs"].includes(screenWidth) && (
        <Grid
          item
          container
          md={12}
          lg={9}
          alignItems="stretch"
          alignContent={"flex-start"}
        >
          {colBig.map((item) => (
            <Grid item xs={12} key={item.id} >
              {item.component && item.component}
            </Grid>
          ))}
        </Grid>
      )}
      {!["md", "sm", "xs"].includes(screenWidth) && (
        <Grid
          item
          container
          md={12}
          lg={3}
          alignItems="stretch"
          alignContent={"flex-start"}
        >
          {colSmall.map((item) => (
            <Grid item xs={12} key={item.id}>
              {item.component && item.component}
            </Grid>
          ))}
        </Grid>
      )}
      {["md", "sm", "xs"].includes(screenWidth) && (
        <Grid
          item
          container
          md={12}
          alignItems="stretch"
          alignContent={"flex-start"}
        >
          {arr.map((item, index) => (
            <Grid item xs={12} key={index}>
              {item.component && item.component}
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );

  return (
    <Root>
      <RenewalDialog
        open={openRenewalDialog}
        onClose={closeShipmentDialogHandler}
        removeMsg={(obj) => {
          setOpen(obj);
        }}
      />
      {props.children}
      <Container component="main" maxWidth="xl">
        {DOM}
      </Container>
    </Root>
  );
};

export default Home;
