import { styled } from "@mui/material/styles";
import React, { useState } from "react";

import { FilterList, Print, ViewColumn } from "@mui/icons-material";
import SearchTable from "../ShipmentSearch/SearchTable";
import useWidth from "../../Hooks/useWidth";
import ExportShipments from "../Shipments/ExportShipments";
import PrintComponent from "../Shipments/PrintComponent";
import { Globals } from "../HOC/Classes/Globals";
import { printLink } from "../../helpers/defaultPrintList";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import ShipmentColumView from "../ShipmentSearch/shipmentColumView"
import { defaultQueryFields, initialData, shipmentFieldsFun } from "../ShipmentSearch/ListShipmentTableCells";

// const PREFIX = "NotPaidToCustomer";

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
}));

const NotPaidToCustomer = (props) => {
  const [keys, setKeys] = useState(localStorage.getItem("not-paid-to-customer") ? JSON.parse(localStorage.getItem("not-paid-to-customer")) : initialData["not-paid-to-customer"])

  const user = Globals.user;
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );

  const defaultPageInput = {
    pendingCustomerAdjusting: true
  }
  const [pageFilter, setPageFilter] = useState({})

  const [shipmentViewColum, setOpenShipmentViewColum] = useState(false);
  const openColumView = () => {
    setOpenShipmentViewColum(true)
  };

  const screenWidth = useWidth();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [checkedIds, setCheckedIds] = useState([]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmitFunc = () => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
  };

  const clearCheckBox = () => setCheckedIds([]);

  const defaultPrint = Globals.settings.waybillCode;

  const [openPrint, setOpenPrint] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [printListDisabled, setPrintListDisabled] = useState(true)
  const openPrintDialog = () => {
    defaultPrint ?
      printLink(defaultPrint, false, checkedIds) :
      setOpenPrint(true)
  };
  const openExportDialog = () => {
    setOpenExport(true)
  };

  const searchParams = (param) => {
    const queryParams = [];

    for (const i in param) {
      if (i === "date") {
        for (const y in param[i]) {
          queryParams.push(encodeURIComponent(y) + "=" + encodeURIComponent(param[i][y]))
        }
      }
      else if (i === "lastTransactionDate") {
        for (const y in param[i]) {
          queryParams.push(encodeURIComponent("lastTransaction" + y) + "=" + encodeURIComponent(param[i][y]))
        }
      }
      else {
        encodeURIComponent(param[i]) && queryParams.push(encodeURIComponent(i) + "=" + encodeURIComponent(param[i]))
      }
    }
    const queryString = queryParams.join("&");
    return queryString;
  };


  const onPrint = () => {
    const params = searchParams({
      ...defaultPageInput,
      ...pageFilter,
    });
    const domain = `${window.location.origin}`;

    window.open(`${domain}/shipment-list/print?listType=not-paid-to-customer&${params}`);
    // window.open(`${domain}/shipment-list/print`);
  };
  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: checkedIds.length > 0,
    },
    {
      id: "print",
      title: "print",
      action: openPrintDialog,
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: checkedIds.length === 0
    },
    {
      id: "printList",
      title: "printList",
      action: onPrint,
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: printListDisabled
    },
    {
      id: "ViewColumn",
      title: "viewColumns",
      action: openColumView,
      icon: ViewColumn,
    },
  ]

  return (
    <Root>

      {/* *******Table******* */}
      <PrintComponent shipmentsId={checkedIds} openPrint={openPrint} setOpenPrint={setOpenPrint} />
      <ExportShipments
        filters={{
          ...defaultPageInput,
          ...pageFilter,
        }}
        openExport={openExport}
        setOpenExport={setOpenExport}
      />

      <ShipmentColumView
        openViewColum={shipmentViewColum}
        setOpenViewColum={setOpenShipmentViewColum}
        localStorageName={"not-paid-to-customer"}
        initialData={keys}
        setKeys={setKeys}
        shipmentFields={shipmentFieldsFun(adminNotesPermission)}
      />

      <SearchTable
        queryFields={defaultQueryFields(keys, adminNotesPermission)}
        setCheckedIds={(ids) => setCheckedIds(ids)}
        checkedIds={checkedIds}
        withCheckAll
        setPrintListDisabled={setPrintListDisabled}
        icons={icons}
        path={props.match.path}
        drawerState={drawerState[filterAnchor]}
        keys={keys}

        onSubmitFunc={onSubmitFunc}
        defaultPageInput={defaultPageInput}
        clearCheckBox={clearCheckBox}
        setPageFilter={setPageFilter}
        pathname="customers/not-paid"
        customerType={"MERCHANT"}
        filtersAllowed={[
          "date",
          "dlvAtemp",
          "collection",
          "status",
          "branch",
          "originBranch",
          "custm",
          "dlvAgent",
          "zoneSender",
          "subZoneSender",
          "zone",
          "subZone",
          "refNumber",
          "service",
          "deliveryType",
          "returnType",
          "packageType",
          "paymentType",
        ]}
      />

    </Root>
  );
};

export default NotPaidToCustomer;
