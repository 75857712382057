import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
// import abimg1 from "../images/about.png";

import "./style.css";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Grid from '@mui/material/Unstable_Grid2';
import config from "../../../config.json";
import { Container } from "@mui/material";

const PREFIX = "AboutSection";

const classes = {
  header: `${PREFIX}-header`,
  span: `${PREFIX}-span`,
  aboutIcon: `${PREFIX}-aboutIcon`,
  active: `${PREFIX}-active`,
  slideImg: `${PREFIX}-slideImg`,
  aboutImg: `${PREFIX}-aboutImg`,
};

const Root = styled("div")(({ theme }) => ({
  background: theme.palette.background.paper,

  [`& .${classes.header}`]: {
    marginTop: 0,
    "&::before": {
      content: "' '",
      position: "absolute",
      left: 0,
      bottom: 0,
      width: "50px",
      height: "5px",
      background: theme.palette.primary.main,
    },
    color: theme.palette.text.primary
  },

  [`& .${classes.span}`]: {
    display: "block",
    paddingLeft: "30px",
    paddingBottom: "20px",
    position: "relative",
    "&::before": {
      content: "' '",
      position: "absolute",
      left: 0,
      top: "5px",
      width: "15px",
      height: "15px",
      background: theme.palette.primary.main,
    },
  },

  [`& .${classes.aboutIcon}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${classes.slideImg}`]: {
    display: "none",
  },
  [`& .${classes.active}`]: {
    display: "block",
  },
  [`& .${classes.aboutImg}`]: {
    display: "flex",
    justifyContent: "center",
  },
}));

const AboutSection = () => {
  const { t } = useTranslation("home");
  const [count, setCount] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      config.aboutPictures.length > 1 &&
        setCount((prevCount) => (prevCount + 1) % config.aboutPictures.length);
    }, 3000);
    return () => clearInterval(timer);
  }, [count]);

  const points = t("home:aboutPoints", { returnObjects: true });

  return (
    <Root id="about" className="wpo-about-area">
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid xs={12} lg={6}>
            <div className="wpo-about-img">
              <div className={clsx(classes.aboutImg, "wpo-about-img")}>
                {config.aboutPictures.length > 1 &&
                  config.aboutPictures.map((img, i) => (
                    <img
                      key={img}
                      src={require(`../images/${img}`)}
                      className={clsx(
                        i === count && classes.active,
                        classes.slideImg,
                        "fade-in-image"
                      )}
                      alt=""
                    />
                  ))}
                {config.aboutPictures.length === 1 && (
                  <img
                    src={require(`../images/${config.aboutPictures[0]}`)}
                    alt=""
                  />
                )}
              </div>
            </div>
          </Grid>
          <Grid xs={12} lg={6}>
            <div className="wpo-about-content">
              <div className="wpo-about-icon">
                <i
                  className={clsx(classes.aboutIcon, "fi flaticon-travel")}
                ></i>
              </div>
              <h2 className={classes.header}>{t("home:aboutTitle")}</h2>
              <p>{t("home:aboutDescription")}</p>

              {typeof points === "object" && points.map((point, i) => (
                <span key={i} className={classes.span}>{point}</span>
              ))}
            </div>
          </Grid>

        </Grid>
      </Container>
      {/* <div className="container">
        <div className="row">
          <div className="col-lg-6">

          </div>
          <div className="col-lg-6 sec-p">

          </div>
        </div>
      </div> */}
    </Root>
  );
};

export default AboutSection;
