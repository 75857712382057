import * as gqlb from "gql-query-builder";

 export const SHIPMENT_QUERY = gqlb.query({
  operation: "listShipmentsForCollection",
  fields: [
    {
      operation: "data",
      fields:[
        "amount",
        "amountEditable",
        "commissionEditable",
        "commission",
        {
          operation: "shipment",
          fields: [
            "id",
            "code",
            "date",
            "deliveredOrReturnedDate",
            "deliveredAmount",
            "deliveryCommission",
            "collectedFees",
            "description",
            "recipientName",
            {
              status: ["name", "code"],
            },
            {
              type: ["name"],
            },
            {
              customer: [
                "name",
              ],
            },
            {
              recipientSubzone: ["name"],
            },
            {
              recipientZone: ["id", "name"],
            },
            {
              deliveryType: ["name"],
            },
       
          ],
          variables: {},
        },
      ],
      variables: {},
    },
    {
      operation: "paginatorInfo",
      fields: ["total"],
      variables: {},
    },
  ],
  variables: {
    first: {
      type: "Int",
      // required: true,
    },
    id: {
      type: "Int",
      required: true,
    },
    page: {
      type: "Int",
    },
    filter: {
      type: "ListShipmentsFilterInput",
    },
  },
});
