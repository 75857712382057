export const GetStatus = (bmtApprove, bmtShipmentLength, bmrData) => {
    const bmrApproved = bmrData.every((e) => e.approved === true)
    const bmrShipment = []
    bmrData.length > 0 && bmrData?.map((e) => bmrShipment.push(...e.manifestShipments))
    if (!bmtApprove) {
        return "preparing"
    }
    if (bmtApprove && bmrData.length === 0) {
        return "inTransit"
    }
    if (bmtApprove && bmrData.length > 0 && bmrShipment.length !== bmtShipmentLength) {
        return "receiving"
    }
    if (bmtApprove && bmrApproved && bmrShipment.length === bmtShipmentLength) {
        return "received"
    }
    return null
};
