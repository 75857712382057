import * as gqlb from "gql-query-builder";

export const LIST_DELIVERY_AGENT_REVIEWS = gqlb.query({
  operation: "listDeliveryAgentReviews",
  fields: [
    {
      operation: "paginatorInfo",
      fields: ["count", "currentPage", "lastPage", "perPage", "total"],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "id",
        "rate",
        "notes",
        "createdAt",
        {
          shipment: [
            "id",
            "code"
          ]
        },
        {
          deliveryAgent: [
            "id",
            "name"
          ]
        }
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListDeliveryAgentReviewsFilterInput",
    },
    page: {
      type: "Int",
    },
    first: {
      type: "Int",
    },
  },
});
