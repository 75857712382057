import React, { Fragment, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import QRCode from 'react-qr-code';
import { Box } from '@mui/material';
import EmptyTableMessage from '../../HOC/FunctionComponents/EmptyTableMessage';
import { dateFormat } from '../../../helpers/dateFunctions';

export const batchFields = [
    "id",
    { barcodes: ["id", "code"] },
    {
        customer: [
            "code",
            "name",
            {
                operation: `isMobileHidden(code: WAYBILL)`,
                fields: [],
                variables: {
                },
            },
            {
                zone: ["id", "code", "name"]
            },
            {
                subzone: ["id", "code", "name"]
            },
            "phone",
            "mobile"
        ],
    },
];

export const shipmentFields = [
    {
        data: [
            "code",
            "recipientName",
            "recipientAddress",
            "recipientMobile",
            "recipientPhone",
            "description",
            "weight",
            "piecesCount",
            "totalAmount",
            "senderName",
            "senderPhone",
            "senderMobile",
            "refNumber",
            "notes",
            "date",
            {
                type: ["name", "code"],
            },
            {
                originBranch: ["name"],
            },
            {
                customer: [
                    "code",
                    {
                        operation: `isMobileHidden(code: WAYBILL)`,
                        fields: [],
                        variables: {
                        },
                    },
                ],
            },
            {
                openable: ["code"],
            },

            {
                recipientZone: ["name"],
            },
            {
                recipientSubzone: ["name"],
            },
            {
                senderZone: ["name"],
            },
            {
                senderSubzone: ["name"],
            },
        ],
    },
];

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
    flex: `${PREFIX}-flex`,
    flexBetween: `${PREFIX}-flexBetween`,
    customHeight5: `${PREFIX}-customHeight5`,
    customHeight6: `${PREFIX}-customHeight6`,
    customHeight7: `${PREFIX}-customHeight7`,
    customHeight8: `${PREFIX}-customHeight8`,
    customHeight10: `${PREFIX}-customHeight10`,
    customHeight11: `${PREFIX}-customHeight11`,
    customHeight12: `${PREFIX}-customHeight12`,
    customHeight13: `${PREFIX}-customHeight13`,
    customHeight14: `${PREFIX}-customHeight14`,
    customHeight15: `${PREFIX}-customHeight15`,
    customHeight16: `${PREFIX}-customHeight16`,
    customHeight17: `${PREFIX}-customHeight17`,
    customHeight18: `${PREFIX}-customHeight18`,
    customHeight4: `${PREFIX}-customHeight4`,
    printSticky: `${PREFIX}-printSticky`,
    stickyCode: `${PREFIX}-stickyCode`,
    line: `${PREFIX}-line`,
    lineTop: `${PREFIX}-lineTop`,
    tableData: `${PREFIX}-tableData`,
    reMarks: `${PREFIX}-reMarks`,
    width50: `${PREFIX}-width50`,
    width60: `${PREFIX}-width60`,
    borderRight: `${PREFIX}-borderRight`,
    padding2: `${PREFIX}-padding2`,
    hideSenderPhone: `${PREFIX}-hideSenderPhone`,
    minHeight: `${PREFIX}-minHeight`,
    borderLeft: `${PREFIX}-borderLeft`,
    rotate: `${PREFIX}-rotate`,
    borderTop: `${PREFIX}-borderTop`,
    borderBottom: `${PREFIX}-borderBottom`,
    bold: `${PREFIX}-bold`,
    imgDiv: `${PREFIX}-imgDiv`,
    logoImg: `${PREFIX}-logoImg`,
    logoWrapper: `${PREFIX}-logoWrapper`,
    paddingX: `${PREFIX}-paddingX`,
    tableDataZones: `${PREFIX}-tableDataZones`,
    capitalize: `${PREFIX}-capitalize`,
    uppercase: `${PREFIX}-uppercase`,
    qrWrapper: `${PREFIX}-qrWrapper`,
    overflowHidden: `${PREFIX}-overflowHidden`,
    rotateWrapper: `${PREFIX}-rotateWrapper`,
    contentWrapper: `${PREFIX}-contentWrapper`,
    rotateHeight38: `${PREFIX}-rotateHeight38`,
    rotateHeight24: `${PREFIX}-rotateHeight24`,
    flexFooter: `${PREFIX}-flexFooter`,
};
export const Root = styled("div")(({ theme }) => ({
    [`& .${classes.customHeight18}`]: {
        height: "18%",
    },
    [`& .${classes.customHeight17}`]: {
        height: "17%",
    },
    [`& .${classes.customHeight16}`]: {
        height: "16%",
    },
    [`& .${classes.customHeight15}`]: {
        height: "15%",
    },
    [`& .${classes.customHeight14}`]: {
        height: "14%",
    },
    [`& .${classes.customHeight13}`]: {
        height: "13%"
    },
    [`& .${classes.customHeight12}`]: {
        height: "12%"
    },
    [`& .${classes.customHeight11}`]: {
        height: "11%"
    },
    [`& .${classes.customHeight10}`]: {
        height: "10%"
    },
    [`& .${classes.customHeight5}`]: {
        height: "5%"
    },
    [`& .${classes.customHeight6}`]: {
        height: "6%"
    },
    [`& .${classes.customHeight7}`]: {
        height: "7%"
    },

    [`& .${classes.customHeight8}`]: {
        height: "8%"
    },
    [`& .${classes.customHeight4}`]: {
        height: "4%"
    },
    [`& .${classes.hideSenderPhone}`]: {
        display: "none !important",
    },
    [`& .${classes.printSticky}`]: {
        border: "1px solid #000",
        pageBreakAfter: "always",
        position: "relative",
        pageBreakInside: "avoid",
        width: "calc(10cm - (2px + 1mm))",
        height: "calc(10cm - (2px + 2mm))",
        overflow: "hidden",
        fontSize: 11,
        color: "black",
        boxSizing: "border-box",
    },
    [`& .${classes.imgDiv}`]: {
        padding: theme.spacing(0.5, 0, 0, 0)
    },
    [`& .${classes.line}`]: {
        borderBottom: "1px solid #000",
    },
    [`& .${classes.lineTop}`]: {
        borderTop: "1px solid #000",
    },
    [`& .${classes.borderRight}`]: {
        borderRight: "1px solid #000",
    },
    [`& .${classes.borderLeft}`]: {
        borderLeft: "1px solid #000",
    },
    [`& .${classes.flexBetween}`]: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
    },
    [`& .${classes.flex}`]: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
    },
    [`& .${classes.flexFooter}`]: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "start",
    },
    [`& .${classes.width60}`]: {
        width: "60%"
    },
    [`& .${classes.stickyCode}`]: {
        fontFamily: "'Libre Barcode 39 Text'",
        // height: "100px",
        overflow: "hidden",
        fontSize: 50,
    },
    [`& .${classes.tableData}`]: {
        width: "calc(100% * (1/3))",
        display: "flex",
        // flexDirection: "column",
        alignItems: 'center',
        textAlign: "center",
        padding: "6px 0",
        height: "100%",
        fontSize: "13px",
        justifyContent: 'flex-start'
    },
    [`& .${classes.tableDataZones}`]: {
        width: "calc(100% * (1/2))",
        display: "flex",
        alignItems: 'center',
        textAlign: "center",
        padding: "6px 0",
        height: "100%",
        fontSize: "13px",
        justifyContent: 'center'
    },
    [`& .${classes.reMarks}`]: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
    },
    [`& .${classes.padding2}`]: {
        padding: "2px 0"
    },
    [`& .${classes.contentWrapper}`]: {
        padding: "2px 8px",
        width: "92%",
        height: "100%"
    },
    [`& .${classes.rotateWrapper}`]: {
        width: "8%",
        height: "100%",
        display: "flex"
    },
    [`& .${classes.rotate}`]: {
        rotate: "-90deg",
        fontSize: "16px",
        margin: "auto"
    },
    [`& .${classes.rotateHeight38}`]: {
        height: "38%"
    },
    [`& .${classes.rotateHeight24}`]: {
        height: "24%"
    },
    [`& .${classes.borderBottom}`]: {
        borderBottom: "1px solid #000",
    },
    [`& .${classes.borderTop}`]: {
        borderTop: "1px solid #000",
    },
    [`& .${classes.bold}`]: {
        fontWeight: 700,
    },
    [`& .${classes.logoImg}`]: {
        maxHeight: "65%",
        maxWidth: "fit-content"
    },
    [`& .${classes.logoWrapper}`]: {
        paddingTop: theme.spacing(1),
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    [`& .${classes.paddingX}`]: {
        padding: theme.spacing(0, 1),
    },
    [`& .${classes.capitalize}`]: {
        textTransform: "capitalize"
    },
    [`& .${classes.uppercase}`]: {
        textTransform: "uppercase"
    },
    [`& .${classes.qrWrapper}`]: {
        height: "100%",
        width: "25%",
        padding: theme.spacing(1),
    },
    [`& .${classes.overflowHidden}`]: {
        overflow: "hidden"
    },
}));


function createDynamicStyles() {
    const style = document.createElement('style');
    style.type = 'text/css';

    const styles = `
    @page {
        size: 10cm 10cm; 
        margin: 1mm !important;
        overflow: hidden;
    }
    * {
            color: black;
    }
    body {
            background: #fff !important
    }
    `;

    if (style.styleSheet) {
        style.styleSheet.cssText = styles;
    } else {
        style.appendChild(document.createTextNode(styles));
    }

    document.head.appendChild(style);
}

const Template10X10 = (props) => {
    const {
        loading,
        validData,
        settingsLoad,
        parsedData,
        isBatch,
        customer,
        footer,
        currency,
        LogoImg
    } = props

    const { t } = useTranslation()

    const getSenderMobiles = (shipment) => {
        return customer ? customer?.mobile && customer?.phone
            ? `${customer?.mobile} - ${customer?.phone}`
            : customer?.mobile ??
            customer?.phone :
            shipment?.senderMobile && shipment?.senderPhone
                ? `${shipment?.senderMobile} - ${shipment?.senderPhone}`
                : shipment?.senderMobile ??
                shipment?.senderPhone
    }

    useEffect(() => {
        createDynamicStyles()
    }, [])

    return (
        <Fragment>
            
            {loading || (!loading && !validData) || settingsLoad ? (
                <>
                    <EmptyTableMessage
                        loading={loading}
                        message={t("noShipmentWithCode")}
                    />

                    {/* <FullScreenLoading minHeight="25%" /> */}
                </>
            ) : (
                parsedData.map((shipment, index) => {
                    const rtsShipment = shipment?.type?.code === "RTS";
                    return (
                        <Root key={index}>
                            <div
                                className={clsx({
                                    [classes.printSticky]: !loading && validData,
                                })}
                                style={{ lineHeight: "1.2" }}
                            >
                                <div className={clsx(
                                    classes.flexBetween,
                                    classes.line,
                                    classes.customHeight18,
                                    classes.paddingX
                                )}>
                                    <div className={clsx(classes.bold, classes.width60, classes.logoWrapper)}>
                                        <LogoImg forceLight={true} className={classes.logoImg} />
                                        <div className={clsx(classes.imgDiv, classes.capitalize)}>
                                            {t("printDate")} : {dateFormat(shipment?.date)}
                                        </div>
                                    </div>
                                    <div className={clsx(classes.flex, classes.qrWrapper)}>
                                        <QRCode value={shipment.code} style={{ height: "100%" }} />
                                    </div>
                                </div>

                                <div className={clsx(
                                    classes.flex,
                                    classes.line,
                                    classes.customHeight8,
                                    classes.bold
                                )}>
                                    <div className={clsx(classes.tableDataZones, classes.borderRight)}>
                                        <Box component="span" className={classes.capitalize} sx={{ mx: 1 }}>
                                            {t("origin")}{" : "}
                                        </Box>
                                        <div>
                                            <Box component="span">
                                                {!isBatch && (shipment?.senderZone?.name)}
                                            </Box>
                                        </div>
                                    </div>
                                    <div className={clsx(classes.tableDataZones)}>
                                        <Box component="span" className={classes.capitalize} sx={{ mx: 1 }}>
                                            {t("destination")}{" : "}
                                        </Box>
                                        <div>
                                            {!isBatch && (shipment?.recipientZone?.name)}
                                        </div>
                                    </div>
                                </div>

                                <div className={clsx(
                                    classes.line,
                                    classes.flex,
                                    classes.customHeight14,
                                    classes.overflowHidden,
                                    classes.bold
                                )}>
                                    <div className={clsx(classes.rotateWrapper, classes.borderRight)}>
                                        <div className={clsx(classes.rotate, classes.capitalize, classes.rotateHeight38)}>{t("from")}</div>
                                    </div>
                                    <div className={classes.contentWrapper}>
                                        <div >{customer ? customer.name : shipment?.senderName}</div>
                                        <div
                                            className={clsx({
                                                [classes.hideSenderPhone]: customer ? customer.isMobileHidden : shipment?.customer?.isMobileHidden,
                                            }
                                            )}
                                        >
                                            {getSenderMobiles(shipment)}
                                        </div>
                                        <div>
                                            {isBatch ? customer ? (customer?.zone?.name +
                                                " - " +
                                                customer?.subzone?.name) : "" : (shipment?.senderZone?.name +
                                                    " - " +
                                                    shipment?.senderSubzone?.name)}
                                        </div>
                                    </div>
                                </div>
                                <div className={clsx(
                                    classes.line,
                                    classes.flex,
                                    classes.customHeight17,
                                    classes.overflowHidden,
                                    classes.bold
                                )}>
                                    <div className={clsx(classes.rotateWrapper, classes.borderRight)}>
                                        <div className={clsx(classes.rotate, classes.capitalize, classes.rotateHeight24)}>{t("to")}</div>
                                    </div>
                                    <div className={classes.contentWrapper}>
                                        <div>{shipment?.recipientName}</div>
                                        <div>
                                            {isBatch ? ' ' : (shipment?.recipientMobile &&
                                                shipment?.recipientPhone
                                                ? `${shipment?.recipientMobile} - ${shipment?.recipientPhone}`
                                                : shipment?.recipientMobile ??
                                                shipment?.recipientPhone)}
                                        </div>
                                        <div>
                                            {!isBatch && (shipment?.recipientZone?.name +
                                                " - " +
                                                shipment?.recipientSubzone?.name)}
                                        </div>
                                        <div
                                            className={clsx(classes.padding2)}
                                        >
                                            {shipment?.recipientAddress}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={clsx(
                                        classes.barcode,
                                        classes.flex,
                                        classes.stickyCode,
                                        classes.line,
                                        classes.customHeight13,
                                    )}
                                >
                                    *{shipment.code}*
                                </div>
                                <div className={clsx(
                                    classes.flex,
                                    classes.line,
                                    classes.customHeight8,
                                    classes.bold
                                )}>
                                    <div className={clsx(classes.tableData, classes.borderRight)}>
                                        <Box component="span" className={classes.uppercase} sx={{ mx: 0.5 }}>
                                            {t("cod")}{" : "}
                                        </Box>
                                        <div>
                                            <Box component="span">
                                                {rtsShipment ? 0 : shipment?.totalAmount}
                                            </Box>
                                            <Box component="span" pl="2px">
                                                {!isBatch && currency}
                                            </Box>
                                        </div>
                                    </div>
                                    <div className={clsx(classes.tableData, classes.borderRight)}>
                                        <Box component="span" className={classes.capitalize} sx={{ mx: 0.5 }}>
                                            {t("pieces")}{" : "}
                                        </Box>
                                        <div>{shipment?.piecesCount}</div>
                                    </div>
                                    <div className={clsx(classes.tableData)}>
                                        <Box component="span" className={classes.capitalize} sx={{ mx: 0.5 }}>
                                            {t("weight")}{" : "}
                                        </Box>
                                        <div>{shipment?.weight}</div>
                                    </div>
                                </div>
                                <div className={clsx(
                                    classes.line,
                                    classes.customHeight7,
                                    classes.bold,
                                    classes.paddingX,
                                    "pt-1"
                                )}>
                                    <div className={classes.capitalize}>{t("refNumber")}  {shipment?.refNumber && " : " + shipment?.refNumber}</div>
                                </div>
                                <div className={clsx(
                                    classes.reMarks,
                                    classes.customHeight11,
                                    classes.bold,
                                    classes.paddingX,
                                    classes.capitalize,
                                    "py-2"
                                )}>
                                    <Box
                                        component="span"
                                    >
                                        {t("notes")}
                                    </Box>
                                    <Box component="span" pt="1px">
                                        {shipment?.notes}
                                    </Box>
                                </div>
                                <Box component="div" className={clsx(
                                    classes.lineTop,
                                    classes.customHeight4,
                                    classes.bold,
                                    classes.flexFooter,
                                    classes.overflowHidden,
                                    "py-1"
                                )}
                                    sx={{ display: "flex", justifyContent: 'center', position: "absolute", bottom: 0, width: "100%" }}>
                                    <Box component="span" pl="2px">
                                        {footer}
                                    </Box>
                                </Box>
                            </div>
                        </Root>
                    )
                })
            )}
        </Fragment>
    )
}

export default Template10X10