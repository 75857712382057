/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Paper,
  Toolbar,
  Icon,
  Table,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { ZONE_ID } from "./Graphql";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { Globals } from "../HOC/Classes/Globals";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import Grid from "@mui/material/Unstable_Grid2";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import CellLink from "../HOC/CustomComponents/CellLink";
import TitleAppBar from "../../Layout/TitleAppBar";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";

const PREFIX = "ZoneView";

const classes = {
  paper: `${PREFIX}-paper`,
  toolbarTable: `${PREFIX}-toolbarTable`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
  table: `${PREFIX}-table`,
  title: `${PREFIX}-title`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    flexGrow: 1,
  },

  [`& .${classes.toolbarTable}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.table}`]: {
    display: "grid",
    minWidth: "100%",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const ZoneView = (props) => {
  const zoneId = props.match.params.id?.trim();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const { loading, data } = useQuery(
    gql`
      ${ZONE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(zoneId) },
    }
  );
  const user = Globals.user;
  const branchId = data?.zone?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canEdit = user.hasPermission("shipping.zone.update")
    ? canAccessBranch
    : false;

  const subzones = data?.zone?.children ?? [];

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/zones/${zoneId}/edit`),
      icon: Edit,
      permission: canEdit,
    },
  ];
  return loading ? (
    <StyledLoading
      container
      item
      justifyContent="center"
      className={classes.main}
    >
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
  ) : !data.zone ? (
    <NotFound />
  ) : (
    <Root>
      <Grid
        container
        justifyContent="center"
        sx={{ margin: "0", width: "100%" }}
      >
        <TitleAppBar path={props.match.path}>
          <LongMenu icons={icons} />
        </TitleAppBar>
        <Paper container className={classes.paper} component={Grid}>
          <KeyValuePair title={t("code")} value={data?.zone?.code} />
          <KeyValuePair title={t("name")} value={data?.zone?.name} />
          <KeyValuePair
            title={t("active")}
            value={
              data?.zone?.active ? (
                <Icon className={classes.iconColor}>check_circle_outline</Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("branch")}
            value={
              data?.zone?.branch ? (
                <SpanLink
                  pathname={`/admin/branches/${data?.zone?.branch?.id}`}
                >
                  {data?.zone?.branch?.name}
                </SpanLink>
              ) : (
                "--"
              )
            }
          />
        </Paper>

        <Paper
          container
          sm={12}
          md={8}
          className={classes.table}
          component={Grid}
        >
          <Toolbar className={classes.toolbarTable} variant="dense">
            <Typography className={classes.title} color="inherit" variant="h6">
              {t("subzone")}
            </Typography>
          </Toolbar>
          <TableFixedHeaderWraper component={Paper}>
            {loading || subzones.length <= 0 ? (
              <EmptyTableMessage
                loading={loading}
                message={t("searchNoResult")}
              />
            ) : (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("#")}</FixedTableCell>
                    <FixedTableCell>{t("subzoneCode")}</FixedTableCell>
                    <FixedTableCell>{t("subzoneName")}</FixedTableCell>
                    <FixedTableCell>{t("branch")}</FixedTableCell>
                    <FixedTableCell>{t("active")}</FixedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subzones &&
                    (rowsPerPage > 0
                      ? subzones.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : subzones
                    )?.map((row, index) => (
                      <TableRow hover key={index}>
                        <FixedTableCell>{row.id}</FixedTableCell>
                        <FixedTableCell>{row.code}</FixedTableCell>
                        <FixedTableCell>{row.name}</FixedTableCell>
                        <CellLink
                          pathname={`/admin/branches/${row?.branch?.id}`}
                        >
                          {row?.branch?.name}
                        </CellLink>
                        <FixedTableCell>
                          {row?.active ? (
                            <Icon className={classes.iconColor}>
                              check_circle_outline
                            </Icon>
                          ) : (
                            <Icon color="error">highlight_off</Icon>
                          )}
                        </FixedTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </TableFixedHeaderWraper>
          <MUITablePagination
            count={subzones.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePage}
            onRowsPerPageChange={handleRowsPerPage}
          />
        </Paper>
      </Grid>
    </Root>
  );
};

export default ZoneView;
