import React, { Fragment, useState, useEffect } from "react";
import { Paper, Icon, Stack, Typography } from "@mui/material";

import {
  DeleteOutline,
  Edit,
  LockOpenOutlined,
  Print,
} from "@mui/icons-material";
import { gql, useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import {
  DELETE_COLLECTIONS_MUTATION,
  DISAPPROVE_COLLECTION_MUTATION,
  COLLECTION_VIEW_CONCIERGE_DATA,
  COLLECTION_VIEW_SHIPMENT_DATA,
} from "./CollectionListQuary";
import { Globals } from "../HOC/Classes/Globals";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import Grid from "@mui/material/Unstable_Grid2";
import { RootCollectionView, classesCollectionView } from "./CollectionStyle";
import { GetCollectionPermissionSlug } from "../../helpers/getPermissionSlug";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";

import Tabs from "./Component/Tabs";
import formatMoney from "../../helpers/numbersDot";

import config from "../../config.json";

const CollectionViewBody = (props) => {
  const { t } = useTranslation();
  const {
    collectionType,
    data,
    refetch,
    collectionByIdLoad,
    ALLOWED_CONCIERGE,
    ALLOWED_SHIPMENT,
    collectionId,
    localKeys,
    shipmentListDetails,
    setKeys,
    collectionsData,
  } = props;

  const [shipmentData, setShipmentData] = useState({
    page: 0,
    total: 0,
    rowsPerPage: config.app.pageSize,
    pickedShipment: [],
  });
  const [conciergeRequestsData, setConciergeRequestsData] = useState({
    page: 0,
    total: 0,
    noData: true,
    rowsPerPage: config.app.pageSize,
    pickedConciergeRequests: [],
  });

  /******************************************* Start collection By Id ********************************************/
  const user = Globals.user;
  useEffect(() => {
    if (data?.collection === null) return;
    const collection = data?.collection;
    if (ALLOWED_SHIPMENT) {
      setShipmentData((prev) => ({
        ...prev,
        total: collection.entriesShipment.paginatorInfo.total,
      }));
      getShipmentData();
    }

    if (ALLOWED_CONCIERGE) {
      setConciergeRequestsData((prev) => ({
        ...prev,
        total: collection.entriesConcierge.paginatorInfo.total,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [getShipmentData, { loading: loadingShipmentData }] = useLazyQuery(
    gql`
      ${COLLECTION_VIEW_SHIPMENT_DATA(
        shipmentListDetails ? shipmentListDetails : []
      ).query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(collectionId),
        typeCode: "SHIPMENT",
        page: shipmentData.page + 1,
        first: shipmentData.rowsPerPage,
      },
      onCompleted: (data) => {
        if (data?.collection === null) return;
        const collection = data?.collection;
        const shipments = collection.entries.data;
        setShipmentData((prev) => {
          return {
            ...prev,
            pickedShipment: shipments,
            total: collection.entries.paginatorInfo.total,
          };
        });
      },
    }
  );

  const [getConciergeData, { loading: loadingConciergeData }] = useLazyQuery(
    gql`
      ${COLLECTION_VIEW_CONCIERGE_DATA.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(collectionId),
        typeCode: "CONCIERGE_REQUEST",
        page: conciergeRequestsData.page + 1,
        first: conciergeRequestsData.rowsPerPage,
      },
      onCompleted: (data) => {
        if (data?.collection === null) return;
        const collection = data?.collection;
        const conciergeRequests = collection.entries.data;
        setConciergeRequestsData((prev) => {
          return {
            ...prev,
            total: collection.entries.paginatorInfo.total,
            noData: false,
            pickedConciergeRequests: conciergeRequests,
          };
        });
      },
    }
  );

  const branchId = data?.collection?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;
  const canDelete =
    !collectionByIdLoad &&
    user.hasPermission(
      GetCollectionPermissionSlug(collectionType?.toLowerCase(), "delete")
    ) &&
    canAccessBranch &&
    !data?.collection?.approved;

  const canEdit =
    !collectionByIdLoad &&
    user.hasPermission(
      GetCollectionPermissionSlug(collectionType?.toLowerCase(), "update")
    ) &&
    canAccessBranch &&
    !data?.collection?.glApproved;

  const canDisapprove =
    !collectionByIdLoad &&
    user.hasPermission(
      GetCollectionPermissionSlug(collectionType?.toLowerCase(), "disapprove")
    ) &&
    canAccessBranch &&
    data?.collection?.approved;

  /******************************************* End collection List ********************************************/

  let viewBody = null;
  const [openDelete, setOpenDelete] = useState(false);
  const [openDisapprove, setOpenDisapprove] = useState(false);

  const openDeleteDialog = (type) => {
    setOpenDelete(true);
  };
  const openDisapproveDialog = (type) => {
    setOpenDisapprove(true);
  };

  const editURL = () => {
    pushUrl(props, `/admin/collections/${collectionId}/edit`);
  };

  const icons = [
    {
      id: "print",
      title: "print",
      action: () =>
        window.open(
          `${window.location.origin}/report/print/collection/${collectionId}/${collectionType}`
        ),
      icon: Print,
      disabled: collectionByIdLoad,
    },
    {
      id: "edit",
      title: "edit",
      action: editURL,
      icon: Edit,
      permission: canEdit,
    },
    {
      id: "delete",
      title: "delete",
      action: openDeleteDialog,
      icon: DeleteOutline,
      permission: Boolean(canDelete),
    },
    {
      id: "disapprove",
      title: "disapprove",
      action: openDisapproveDialog,
      icon: LockOpenOutlined,
      permission: Boolean(canDisapprove),
    },
  ];

  viewBody = (
    <Grid container justifyContent="center" sx={{ margin: "0", width: "100%" }}>
      <Fragment>
        <MutationWithDialog
          mutaion={DELETE_COLLECTIONS_MUTATION.query}
          setOpenDelete={setOpenDelete}
          openDelete={openDelete}
          dialogTitle={t("deleteRecord")}
          dialogContent={t("deleteRecordMessage")}
          mutaionProps={{
            variables: { id: parseInt(collectionId) },
          }}
          onCompleted={() =>
            pushUrl(props, `/admin/collections/${collectionType.toLowerCase()}`)
          }
          onCompleteMessage={t("successfullyDeletedRecord")}
        />

        <MutationWithDialog
          mutaion={DISAPPROVE_COLLECTION_MUTATION.query}
          setOpenDelete={setOpenDisapprove}
          openDelete={openDisapprove}
          dialogTitle={t("disapprove")}
          dialogContent={t("disapprovedMessage")}
          mutaionProps={{
            variables: { id: parseInt(collectionId) },
          }}
          onCompleted={() => refetch()}
          onCompleteMessage={t("successfullyDisapproved")}
        />
      </Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "0", width: "100%" }}
      >
        <Paper
          container
          className={classesCollectionView.viewPaper}
          component={Grid}
          sx={{ width: "100%" }}
        >
          <KeyValuePair
            title={t("recordCode")}
            value={data?.collection?.code}
          />
          <KeyValuePair title={t("theDate")} value={data?.collection?.date} />
          <KeyValuePair
            title={t("branch")}
            value={
              <SpanLink
                pathname={`/admin/branches/${data?.collection?.branch?.id}`}
              >
                {data?.collection?.branch?.name}
              </SpanLink>
            }
          />
          <KeyValuePair
            title={t("safe")}
            value={
              data?.collection?.safe?.name && (
                <SpanLink
                  pathname={`/admin/safes/${data?.collection?.safe?.id}`}
                >
                  {data?.collection?.safe?.name}
                </SpanLink>
              )
            }
          />
          {data?.collection?.deliveryAgent ? (
            <KeyValuePair
              title={t("shippingAgent")}
              value={
                data?.collection?.deliveryAgent?.name && (
                  <SpanLink
                    pathname={`/admin/delivery-agents/${data?.collection?.deliveryAgent?.id}`}
                  >
                    {data?.collection?.deliveryAgent?.name}
                  </SpanLink>
                )
              }
            />
          ) : null}
          <KeyValuePair
            title={t("transactionType")}
            value={
              <SpanLink
                pathname={`/admin/transaction-types/${data?.collection?.transactionType?.id}`}
              >
                {data?.collection?.transactionType?.name}
              </SpanLink>
            }
          />
          {data?.collection?.customer ? (
            <KeyValuePair
              title={t("customer")}
              value={
                data?.collection?.customer?.name && (
                  <SpanLink
                    pathname={`/admin/customers/${data?.collection?.customer?.id}`}
                  >
                    {data?.collection?.customer?.name}
                  </SpanLink>
                )
              }
            />
          ) : null}

          <KeyValuePair
            title={t("journalEntry")}
            value={
              data?.collection?.journalEntry?.code && (
                <SpanLink
                  pathname={`/admin/finance/journal-entry/${data?.collection?.journalEntry?.id}`}
                >
                  {data?.collection?.journalEntry?.code}
                </SpanLink>
              )
            }
          />

          <KeyValuePair title={t("notes")} value={data?.collection?.notes} />
          <KeyValuePair
            title={t("createdBy")}
            value={
              data?.collection && (
                <SpanLink
                  pathname={`/admin/users/${data?.collection?.createdBy?.id}`}
                >
                  {data?.collection?.createdBy?.username}
                </SpanLink>
              )
            }
          />
          <KeyValuePair
            title={t("updatedBy")}
            value={
              data?.collection && (
                <SpanLink
                  pathname={`/admin/users/${data?.collection?.updatedBy?.id}`}
                >
                  {data?.collection?.updatedBy?.username}
                </SpanLink>
              )
            }
          />
          <KeyValuePair
            title={t("approved")}
            value={
              data?.collection?.approved ? (
                <Icon className={classesCollectionView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
          <KeyValuePair
            title={t("glApproved")}
            value={
              data?.collection?.glApproved ? (
                <Icon className={classesCollectionView.iconColor}>
                  check_circle_outline
                </Icon>
              ) : (
                <Icon color="error">highlight_off</Icon>
              )
            }
          />
        </Paper>
      </Grid>
    </Grid>
  );

  return (
    <RootCollectionView>
      <TitleAppBar path={props.match.path} type={collectionType}>
        <LongMenu icons={icons} />
      </TitleAppBar>
      <Stack spacing={2} margin={2}>
        {props.children}
        {viewBody}
        {collectionType === "DLVBY" && (
          <Paper sx={{ py: 2 }}>
            <Stack
              direction={"row"}
              spacing={1}
              flexWrap={"wrap"}
              justifyContent={"center"}
            >
              <Typography
                className={classesCollectionView.totalTitle}
                color="inherit"
                variant="subtitle1"
                component="div"
                sx={{ textAlign: "end" }}
              >
                {t("total")}
              </Typography>
              <Typography
                color="inherit"
                variant="subtitle1"
                component="div"
                sx={{ textAlign: "start" }}
              >
                {formatMoney(
                  (
                    data?.collection?.sumEntries?.collectedAmount -
                    data?.collection?.sumEntries?.commissionAmount
                  ).toFixed(2)
                )}
              </Typography>
            </Stack>
          </Paper>
        )}
        <Tabs
          collectionId={collectionId}
          setLocalDataKey={setKeys}
          collectionType={collectionType}
          collectionsData={collectionsData}
          loadingShipmentData={loadingShipmentData}
          shipmentData={shipmentData}
          setShipmentData={setShipmentData}
          localKeys={localKeys}
          conciergeRequestsData={conciergeRequestsData}
          setConciergeRequestsData={setConciergeRequestsData}
          getConciergeData={getConciergeData}
          loadingConciergeData={loadingConciergeData}
        />
      </Stack>
    </RootCollectionView>
  );
};

export default CollectionViewBody;
