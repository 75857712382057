import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
  Collapse,
  Button,
  Icon,
  Stack
} from "@mui/material";
import useWidth, { isWidthDown } from "../../Hooks/useWidth";
import {
  BackspaceOutlined,
  Delete,
  Edit,
  Image,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, IconButton, Box } from "@mui/material";
import clsx from "clsx";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { Can, SecuredNavLink } from "../HOC/CustomComponents/Secured";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import CellLink from "../HOC/CustomComponents/CellLink";
import CustomTab from "../HOC/FunctionComponents/CustomTab";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { dateTimeFormatA } from "../../helpers/dateFunctions";
import LongMenu from "../../Layout/MenuAppBar";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useSnackbar } from "notistack";
import { TableCellColor } from './TableCellColor';
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { CONCIERGE_REQUEST_BY_ID_QUERY, DELETE_CONCIERGE_REQUEST_MUTATION, DELETE_LAST_CONCIERGE_REQUEST_ACTION } from "./Graphql";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import formatMoney from "../../helpers/numbersDot";
import config from "../../config.json";
import PhoneNumberActions from "../HOC/CustomComponents/PhoneNumberActions";

const PREFIX = "conciergeView";

const classes = {
  typo: `${PREFIX}-typo`,
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  tabpanel: `${PREFIX}-tabpanel`,
  alert: `${PREFIX}-alert`,
  alertDisabled: `${PREFIX}-alertDisabled`,
  input: `${PREFIX}-input`,
  iconButton: `${PREFIX}-iconButton`,
  searchBar: `${PREFIX}-searchBar`,
  toolbar: `${PREFIX}-toolbar`,
  codeSection: `${PREFIX}-codeSection`,
  codeTitle: `${PREFIX}-codeTitle`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  alertHover: `${PREFIX}-alertHover`,
  link: `${PREFIX}-link`,
  delivered: `${PREFIX}-delivered`,
  returned: `${PREFIX}-returned`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
  cellHidden: `${PREFIX}-cellHidden`,
};

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.mainTracking}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    width: "100%",
    margin: 0,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tabpanel}`]: {
    padding: theme.spacing(2, 0),
  },

  [`& .${classes.alert}`]: {
    color: "rgb(94 183 97)",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "64px",
    boxShadow: theme.shadows[1],
    border: "none",
  },

  [`& .${classes.alertDisabled}`]: {
    color: "rgb(181 181 181)",
  },

  [`& .${classes.input}`]: {
    margin: theme.spacing(0, 2, 0, 2),
    flex: 1,
  },

  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.searchBar}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },

  [`& .${classes.codeSection}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1),
  },

  [`& .${classes.codeTitle}`]: {
    float: "left",
    marginRight: theme.spacing(1),
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.alertHover}`]: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },

  [`& .${classes.link}`]: {
    textDecoration: "none",
    backgroundColor: theme.palette.action.hover,
  },
  [`& .${classes.delivered}`]: {
    color: "rgb(94 183 97)",
  },
  [`& .${classes.returned}`]: {
    color: "#f44336",
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.cellHidden}`]: {
    background: theme.palette.mode === "dark" ? "#651e1c !important" : "#ffebee !important",
  },
}));

const ConciergeRequestView = (props) => {
  const conciergeId = props?.match?.params?.id?.trim();
  const user = Globals.user;
  const { t } = useTranslation();
  const screenWidth = useWidth();
  const isWidthXs = isWidthDown("xs", screenWidth);
  const { enqueueSnackbar } = useSnackbar();
  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
  });

  const openDeleteDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("delete"),
      content: t("confirmationMsg"),
      confirmAction: true,
      function: deleteConciergeRequestMutation,
    }));
  };

  const openDeleteLastShipmentActionDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("deleteLastAction"),
      content: t("deleteLastActionMessage"),
      confirmAction: true,
      function: deleteLastConciergeActionMutation,
    }));
  };

  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };


  const {
    data,
    loading: conciergeByIdLoad,
    refetch
  } = useQuery(
    gql`
      ${CONCIERGE_REQUEST_BY_ID_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !conciergeId,
      variables: {
        id: parseInt(conciergeId),
      }
    });

  const trackingData = data?.conciergeRequest !== null ? data?.conciergeRequest : null;

  const type = data?.conciergeRequest?.transactionType?.type?.code.toLowerCase()
  const conciergeRequestId = trackingData?.id;

  const [deleteConciergeRequestMutation, { loading: loadDelete }] = useMutation(
    gql`
        ${DELETE_CONCIERGE_REQUEST_MUTATION.query}
      `,
    {
      variables: { id: conciergeRequestId },

      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        enqueueSnackbar(t("shipmentDeleted"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        closeConfirmationDialog();
        pushUrl(props, `/admin/concierge-request/${type}`);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const [deleteLastConciergeActionMutation, { loading: loadDeleteLastAction }] =
    useMutation(
      gql`
        ${DELETE_LAST_CONCIERGE_REQUEST_ACTION.query}
      `,
      {
        variables: { id: conciergeRequestId },

        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          refetch();
          enqueueSnackbar(t("successfullyDeleteLastAction"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          closeConfirmationDialog();
        },
        onError: (error) => {
          console.log(error);
          enqueueSnackbar(error.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          closeConfirmationDialog();
        },
      }
    );

  let trackingBody = null;
  const description =
    !conciergeByIdLoad && trackingData ? (
      <Grid spacing={2} container className={classes.tabpanel}>
        <Grid xs={12} className={classes.track}>
          <Paper
            container className={classes.paper} component={Grid}
          >
            <KeyValuePair
              title={t("implementationDate")}
              value={dateTimeFormatA(data?.conciergeRequest?.date)}
            />
            <KeyValuePair
              title={t("branch")}
              value={
                <SpanLink
                  pathname={`/admin/branches/${data?.conciergeRequest?.branch?.id}`}
                >
                  {data?.conciergeRequest?.branch?.name}
                </SpanLink>
              }
            />
            <KeyValuePair
              title={t("customer")}
              value={
                <SpanLink
                  pathname={`/admin/customers/${data?.conciergeRequest?.customer?.id}`}
                >
                  {data?.conciergeRequest?.customer?.name}
                </SpanLink>
              }
            />
            <KeyValuePair
              title={t("accountNumber")}
              value={data?.conciergeRequest?.accountNumber}
            />
            <KeyValuePair
              title={t("serviceProvider")}
              value={data?.conciergeRequest?.provider?.name}
            />
            <KeyValuePair
              title={t("phone")}
              valueStyle={{ "inlineSize": "max-content" }}
              phoneStyle={true} 
              value={ <PhoneNumberActions phone={data?.conciergeRequest?.phone} />}
            />
            <KeyValuePair
              title={t("zone")}
              value={
                <SpanLink
                  pathname={`/admin/zones/${data?.conciergeRequest?.zone?.id}`}
                >
                  {`${data?.conciergeRequest?.zone?.name} / ${data?.conciergeRequest?.subzone?.name}`}
                </SpanLink>
              }
            />
            <KeyValuePair
              title={t("address")}
              value={data?.conciergeRequest?.address}
            />
            <KeyValuePair
              title={t("service")}
              value={
                <SpanLink
                  pathname={`/admin/shipping-services/${data?.conciergeRequest?.branch?.id}`}
                >
                  {data?.conciergeRequest?.service?.name}
                </SpanLink>}
            />
            <KeyValuePair
              title={t("amount")}
              value={`${formatMoney(data?.conciergeRequest?.amount)} ${data?.conciergeRequest?.currency?.name} `}
            />
            <KeyValuePair
              title={t("localAmount")}
              value={formatMoney(data?.conciergeRequest?.localAmount)}
            />
            <KeyValuePair
              title={t("deliveryFees")}
              value={formatMoney(data?.conciergeRequest?.deliveryFees)}
            />
            <KeyValuePair
              title={t("collectionFees")}
              value={formatMoney(data?.conciergeRequest?.collectionFees)}
            />
            <KeyValuePair
              title={t("total")}
              value={formatMoney((data?.conciergeRequest?.localAmount + data?.conciergeRequest?.deliveryFees + data?.conciergeRequest?.collectionFees).toFixed(2))}
            />
            <KeyValuePair
              title={t("refNumber")}
              value={data?.conciergeRequest?.referenceNumber}
            />
            {data?.conciergeRequest?.transactions[0]?.deliveryAgent ?
              <KeyValuePair
                title={t("shippingAgent")}
                value={
                  <SpanLink
                    pathname={`/admin/delivery-agents/${data?.conciergeRequest?.transactions[0]?.deliveryAgent?.id}`}
                  >
                    {`${data?.conciergeRequest?.transactions[0]?.deliveryAgent?.name}`}
                  </SpanLink>
                }
              /> : <KeyValuePair
                title={t("shippingAgent")}
                value={""}
              />}
            <KeyValuePair
              title={t("createdAt")}
              value={data?.conciergeRequest?.createdAt}
            />
            <KeyValuePair
              title={t("updatedAt")}
              value={data?.conciergeRequest?.updatedAt}
            />
            <KeyValuePair
              title={t("createdBy")}
              value={data?.conciergeRequest?.createdBy?.username}
            />
            <KeyValuePair
              title={t("updatedBy")}
              value={data?.conciergeRequest?.updatedBy?.username}
            />
            <KeyValuePair
              title={t("latitude")}
              value={data?.conciergeRequest?.latitude}
            />
            <KeyValuePair
              title={t("longitude")}
              value={data?.conciergeRequest?.longitude}
            />
            {user.hasPermission(`shipping.concierge_request.choose_transaction_type`) &&
              <KeyValuePair
                title={t("transactionType")}
                value={<SpanLink
                  pathname={`/admin/transaction-Types/${data?.conciergeRequest?.transactionType?.id}`}
                >
                  {data?.conciergeRequest?.transactionType?.name}
                </SpanLink>
                }
              />
            }
          </Paper>
        </Grid>
      </Grid>
    ) : null;

  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };

  const openSignatureDialog = (imgPath) => {
    setDialogDetails((prev) => ({
      state: true,
      title: null,
      content: (
        <Box sx={{ width: "100%" }} component="img" alt="signature" src={getBackendUri(imgPath.path)} />
      ),
      confirmAction: false,
      function: () => { },
    }));
  };

  const transactionTable =
    !conciergeByIdLoad && trackingData && trackingData.transactions.length > 0 ? (
      <Grid
        container
        sx={{ m: 0 }}
        className={clsx(classes.table, classes.tabpanel)}
      >
        <TableFixedHeaderWraper component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <FixedTableCell>{t("createdAt")}</FixedTableCell>
                <FixedTableCell align="left">{t("status")}</FixedTableCell>
                <FixedTableCell align="left">
                  {t("shippingAgent")}
                </FixedTableCell>
                <FixedTableCell align="left">
                  {t("deliveryAgentAccepted")}
                </FixedTableCell>
                <FixedTableCell align="left">{t("notes")}</FixedTableCell>
                <FixedTableCell align="left">
                  {t("CancellationReasonName")}
                </FixedTableCell>
                <FixedTableCell align="left">
                  {t("image")}
                </FixedTableCell>
                <FixedTableCell align="left">{t("updatedAt")}</FixedTableCell>
                <Can
                  permission="shipping.concierge_request.choose_customer"
                  showException
                >
                  <FixedTableCell align="left">{t("updatedBy")}</FixedTableCell>
                </Can>
              </TableRow>
            </TableHead>
            <TableBody>
              {trackingData?.transactions.map((items, index) => {
                return (
                  <TableRow key={index}>
                    <FixedTableCell align="left">
                      {items?.createdAt}
                    </FixedTableCell>
                    <TableCellColor
                      status={items?.status}
                    />
                    {items?.deliveryAgent ? (
                      <CellLink
                        align="left"
                        pathname={`/admin/delivery-agents/${items?.deliveryAgent?.id}`}
                      >
                        {items?.deliveryAgent?.name}
                      </CellLink>
                    ) : (
                      <FixedTableCell align="left"></FixedTableCell>
                    )}
                    {items?.deliveryAgentAccepted === null ?
                      <FixedTableCell align="left">
                      </FixedTableCell> :
                      <FixedTableCell >
                        {items?.deliveryAgentAccepted ? (
                          <Icon className={classes.iconColor}>
                            check_circle_outline
                          </Icon>
                        ) : (
                          <Icon color="error">highlight_off</Icon>
                        )}
                      </FixedTableCell>
                    }
                    <FixedTableCell align="left">
                      {items?.note}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {items?.cancellationReason?.name}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {items.images.length > 0 ?
                        <IconButton
                          size="small"
                          onClick={() => openSignatureDialog(items?.images[0])}
                        >
                          <Image fontSize="small" />
                        </IconButton>
                        : null
                      }
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {items?.updatedAt}
                    </FixedTableCell>
                    <Can
                      permission="shipping.concierge_request.choose_customer"
                      showException
                    >
                      {items?.createdBy ? (
                        <CellLink
                          align="left"
                          pathname={`/admin/users/${items?.createdBy?.id}`}
                        >
                          {items?.createdBy?.username}
                        </CellLink>
                      ) : (
                        <FixedTableCell> </FixedTableCell>
                      )}
                    </Can>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableFixedHeaderWraper>
      </Grid>
    ) :
      <Grid
        container
        sx={{ m: 0 }}
        className={clsx(classes.table, classes.tabpanel)}
      >
        <Paper>
          <EmptyTableMessage
            message={t("noResult")}
          />
        </Paper>
      </Grid>;

  const tapsArray = [
    {
      tabHead: t("description"),
      panel: description,
    },
    {
      tabHead: t("transactions"),
      panel: transactionTable,
    },
  ];

  const hashKeys = ["description", "transactions"];

  const collection = trackingData?.collection;
  const paidToDeliveryAgent = trackingData?.payment;

  if (trackingData && !conciergeByIdLoad && true) {
    trackingBody = (
      <Stack
        spacing={2}
        justifyContent="center"
        p={2}
      >
        <Grid className={classes.codeSection}>
          <Typography
            className={classes.codeTitle}
            variant="h6"
            color="textSecondary"
          >
            {t("requestCode")}
          </Typography>
          <Typography variant="h5" color="primary" sx={{ ml: 1 }}>
            {trackingData?.code}
          </Typography>
        </Grid>

        <Stack justifyContent={"center"} direction={"row"} spacing={2} gap={2} flexWrap={"wrap"}>
          <RequestStatus isWidthXs={isWidthXs} active={true}>
            {trackingData?.status?.name}
          </RequestStatus>
          <RequestStatus
            className={classes.alertHover}
            isWidthXs={isWidthXs}
            active={trackingData?.collected}
            permission={Boolean(collection)}
            code={collection?.code}
            link={collection?.id && `/admin/collections/${collection?.id}`}
          >
            {t("collectedFrom")}{" "}{t("agent")}
          </RequestStatus>
          <RequestStatus
            className={classes.alertHover}
            isWidthXs={isWidthXs}
            active={trackingData?.commissionPaid}
            permission={Boolean(paidToDeliveryAgent)}
            code={paidToDeliveryAgent?.code}
            link={
              paidToDeliveryAgent?.id &&
              `/admin/payments/${paidToDeliveryAgent?.id}`
            }
          >
            {t("paidToDeliveryAgent")}
          </RequestStatus>
        </Stack>
        <CustomTab tapDetails={tapsArray} hashKeys={hashKeys} />
      </Stack>
    );
  }

  const branchId = trackingData?.originBranch?.id;
  const canAccessBranch = user?.account ? true : user.canAccessBranch(branchId);

  const canEdit =
    user.hasPermission(`shipping.concierge_request_${data?.conciergeRequest?.transactionType?.type?.code.toLowerCase()}.update`)
  const canDelete =
    user.hasPermission(`shipping.concierge_request_${data?.conciergeRequest?.transactionType?.type?.code.toLowerCase()}.delete`)

  const canDeleteLastAction =
    user.hasPermission(`shipping.concierge_request_${data?.conciergeRequest?.transactionType?.type?.code.toLowerCase()}.delete_last_action`) &&
    trackingData?.status?.code !== "NEW" &&
    canAccessBranch;

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/concierge-request/${conciergeId}/edit`),
      icon: Edit,
      permission: canEdit && data?.conciergeRequest?.editable
    },
    {
      id: "delete",
      title: "delete",
      action: openDeleteDialog,
      icon: Delete,
      permission: canDelete && data?.conciergeRequest?.deletable,
    },
    {
      id: "deleteLastAction",
      title: "deleteLastAction",
      action: openDeleteLastShipmentActionDialog,
      icon: BackspaceOutlined,
      permission: canDeleteLastAction,
    },
  ]

  return conciergeByIdLoad ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !trackingData ? (
      <NotFound />
    ) :
      !true ? (
        <Can permission="unEditable" />
      ) :
        (
          <Root>
            <CustomDialog
              title={dialogDetails.title}
              fullWidth
              maxWidth="xs"
              onClose={closeConfirmationDialog}
              content={dialogDetails.content}
              open={dialogDetails.state}
              actions={
                dialogDetails.function !== null && (
                  <>
                    <Button color="primary" onClick={closeConfirmationDialog}>
                      {dialogDetails.confirmAction ? t("cancel") : t("close")}
                    </Button>
                    {dialogDetails.confirmAction && (
                      <Button
                        color="primary"
                        disabled={loadDelete || loadDeleteLastAction}
                        onClick={dialogDetails.function}
                      >
                        {(loadDelete || loadDeleteLastAction) ? (
                          <ButtonLoading />
                        ) : (
                          t("confirm")
                        )}
                      </Button>
                    )}
                  </>
                )
              }
            >
            </CustomDialog>

            <TitleAppBar path={props.match.path} type={data?.conciergeRequest?.transactionType?.type?.code}>
              <LongMenu icons={icons} />
            </TitleAppBar>
            {trackingBody}

          </Root>
        );
};

export default ConciergeRequestView;

const RequestStatus = (props) => {
  const { isWidthXs, active, link, className, permission, code } = props;

  const alert = (
    <Alert
      variant="outlined"
      icon={!active ? <RadioButtonUnchecked color={"disabled"} /> : undefined}
      className={clsx(
        classes.alert,
        {
          [classes.alertDisabled]: !active,
        },
        className && className
      )}
    >
      {props.children}
    </Alert>
  );
  return (
    <Grid xs={isWidthXs && 12}>
      {link ? (
        <SecuredNavLink
          className={classes.link}
          show={permission}
          to={{
            pathname: link,
          }}
        >
          {code ? <Tooltip title={code}>{alert}</Tooltip> : alert}
        </SecuredNavLink>
      ) : (
        alert
      )}
    </Grid>
  );
};