import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Toolbar,
  Icon,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { Add, Delete, Edit } from "@mui/icons-material";
import clsx from "clsx";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";

const PREFIX = "DestinationsTable";

const classes = {
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  focus: `${PREFIX}-focus`,
  iconColor: `${PREFIX}-iconColor`,
  deleteIcon: `${PREFIX}-deleteIcon`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({


  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tableContainer}`]: {
    minHeight: "250px",
  },

  [`& .${classes.title}`]: {
    flex: 1,
  },

  [`& .${classes.toolbar}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
  },

  [`& .${classes.focus}`]: {
    backgroundColor: theme.palette.action.hover,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.deleteIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const DestinationsTable = (props) => {
  const { destinations = [], addDestination, setDestinationsList } = props;
  const { t } = useTranslation();
  const [destinationIndex, setDestinationIndex] = useState(0);
  const [destinationsPage, setDestinationPage] = useState(0);
  const [destinationRowsPerPage, setdestinationRowsPerPage] = useState(20);

  const handleDestinationsPage = (event, newPage) => {
    setDestinationPage(newPage);
  };
  const handleDestinationsRowsPerPage = (event) => {
    setdestinationRowsPerPage(parseInt(event.target.value));
    setDestinationPage(0);
  };

  return (
    <StyledGrid container alignItems="flex-start">
      <Grid container item sm={12} component={Paper}>
        <Toolbar className={classes.toolbar} variant="dense">
          <Typography className={classes.title} color="inherit" variant="h6">
            {t("destinations")}
          </Typography>

          {addDestination ? (
            <IconButton
              color="primary"
              onClick={() => {
                addDestination();
                setDestinationIndex(0);
              }}
              aria-label="Add destination"
              size="large"
            >
              <Add />
            </IconButton>
          ) : null}
        </Toolbar>
        <Grid container item className={classes.table}>
          {destinations.length === 0 ? (
            <EmptyTableMessage loading={false} message={t("noResult")} />
          ) : (
            <TableFixedHeaderWraper className={classes.tableContainer}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("destination")}</FixedTableCell>
                    <FixedTableCell>{t("commissionType")}</FixedTableCell>
                    <FixedTableCell>{t("commission")}</FixedTableCell>
                    <FixedTableCell>{t("active")}</FixedTableCell>
                    {addDestination ? <FixedTableCell></FixedTableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {destinations.length > 0 &&
                    (destinationRowsPerPage > 0
                      ? destinations.slice(
                          destinationsPage * destinationRowsPerPage,
                          destinationsPage * destinationRowsPerPage +
                            destinationRowsPerPage
                        )
                      : destinations
                    ).map((row, index) => (
                      <TableRow
                        className={clsx({
                          [classes.focus]:
                            destinationsPage * destinationRowsPerPage +
                              index ===
                            destinationIndex,
                        })}
                        hover
                        key={index}
                        onClick={() => {
                          setDestinationPage(0);
                          setDestinationIndex(
                            destinationsPage * destinationRowsPerPage + index
                          );
                        }}
                      >
                        <ShipmentRegion
                          pathname={`/admin/zones/${row?.zone?.id}`}
                          zone={row?.zone?.name}
                          subzone={row?.subzone?.name}
                        />
                        <FixedTableCell>
                          {row.commissionType.name}
                        </FixedTableCell>
                        <FixedTableCell>{row.commission}</FixedTableCell>
                        <FixedTableCell>
                          {row?.active ? (
                            <Icon className={classes.iconColor}>
                              check_circle_outline
                            </Icon>
                          ) : (
                            <Icon color="error">highlight_off</Icon>
                          )}
                        </FixedTableCell>
                        {addDestination ? (
                          <>
                            <FixedTableCell>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  addDestination(
                                    destinationsPage * destinationRowsPerPage +
                                      index
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                className={classes.deleteIcon}
                                onClick={() =>
                                  setDestinationsList((prev) => {
                                    const filterd = prev.filter(
                                      (i, deleteIndex) => deleteIndex !== index
                                    );
                                    return filterd;
                                  })
                                }
                              >
                                <Delete />
                              </IconButton>
                            </FixedTableCell>
                          </>
                        ) : null}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableFixedHeaderWraper>
          )}
          <MUITablePagination
            count={destinations?.length ?? 0}
            rowsPerPage={destinationRowsPerPage}
            page={destinationsPage}
            onPageChange={handleDestinationsPage}
            onRowsPerPageChange={handleDestinationsRowsPerPage}
          />
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

export default DestinationsTable;
